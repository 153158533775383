
/*Wrapper*/
.hk-wrapper {
    position: relative;
}

.hk-pg-wrapper {
    padding: 65px 0 60px;
    position: relative;
    min-height: 100vh;
}


/*Hk Section*/
.hk-section {
    margin-bottom: 3.125rem;
}

.hk-section .hk-sec-title {
    margin-bottom: 1.25rem;
}


/*Page Header*/
.hk-pg-header {
    padding: 1.25rem 0;
    /* background: #fff; */
    border-bottom: 1px solid #eaeaea;
}

.hk-pg-header .pg-title {
    font-size: 1.75rem;
    font-weight: 700;
    margin-bottom: .3rem;
}

.hk-pg-header .pg-title-sm {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: .25rem;
}

.hk-pg-header .pg-title-xs {
    font-size: 1.25rem;
    font-weight: 700;
    margin-bottom: .25rem;
}

.hk-pg-header .breadcrumb {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 0.5rem;
}

.hk-pg-header .pg-subtitle {
    font-size: 0.75rem;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: .3rem;
}

.hk-pg-header .pg-stats-group {
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.hk-pg-header .pg-stats-group .stats-symbol {
    display: -ms-flexbox;
    display: flex;
    padding-right: .25rem;
}

.hk-pg-header .pg-stats-group .stats-symbol .feather-icon svg,
.hk-pg-header .pg-stats-group .stats-symbol .svg-icon svg {
    height: 13px;
    width: 13px;
}

.hk-pg-header .pg-stats-group .stats-title {
    font-size: 0.875rem;
}

.hk-pg-header .pg-stats-group .stats-dot-sep {
    padding: 0 .625rem;
    color: #d8d8d8;
}

.hk-pg-header .pg-stats-group .stats-line-sep {
    padding: 0 .625rem;
    color: #d8d8d8;
}

.hk-pg-header .pg-header-action-wrap {
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.hk-pg-header.pg-header-wth-tab {
    padding-bottom: 0;
}

.hk-pg-header.pg-header-wth-tab .nav-tabs {
    margin-top: 1.875rem;
    border-bottom: none;
}

.hk-pg-header.pg-header-wth-img {
    position: relative;
    /* background-image: url(../../public/assets/dist/img/cropper.jpg); */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.hk-pg-header.pg-header-wth-img:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #262a2e;
    opacity: .6;
}

.hk-pg-header.pg-header-wth-img>div,
.hk-pg-header.pg-header-wth-img>.nav-tabs {
    position: relative;
    z-index: 2;
}

.hk-pg-header.pg-header-wth-bg {
    background: #f7f7f7;
    border-bottom: none;
}

.hk-pg-header.pg-header-wth-bg .nav-tabs .nav-item .nav-link,
.hk-pg-header.pg-header-wth-bg .nav-tabs .nav-item .nav-link.active {
    border: none;
}

.hk-pg-header.pg-header-wth-bg .nav-tabs .nav-item.show .nav-link {
    border: none;
}

.hk-pg-header.pg-header-dark .pg-title,
.hk-pg-header.pg-header-dark .pg-title-sm,
.hk-pg-header.pg-header-dark .pg-title-xs {
    color: #fff;
}

.hk-pg-header.pg-header-dark .pg-subtitle,
.hk-pg-header.pg-header-dark p {
    color: rgba(255, 255, 255, 0.8);
}

.hk-pg-header.pg-header-dark .pg-stats-group .stats-symbol,
.hk-pg-header.pg-header-dark .pg-stats-group .stats-title {
    color: rgba(255, 255, 255, 0.8);
}

.hk-pg-header.pg-header-dark .pg-stats-group .stats-line-sep,
.hk-pg-header.pg-header-dark .pg-stats-group .stats-dot-sep {
    color: rgba(255, 255, 255, 0.3);
}

.hk-pg-header.pg-header-dark .hk-vertical-separator-full:after {
    background: rgba(255, 255, 255, 0.2);
}

@media (max-width: 767px) {
    .hk-pg-header .pg-title {
        font-size: 1.625rem;
    }
}

/*Page Body*/
.hk-pg-body {
    padding: 2rem 0;
    padding-bottom: 10%;
}


/*Wizards*/
.hk-wizard {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-size: 0.875rem;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
    display: flex;
    width: 100%;
    font-size: 0;
    text-align: initial;
}

.hk-wizard .hk-wizard-item:not(.hk-wizard-item-active)>.hk-wizard-item-container[role=button] {
    cursor: pointer;
}

.hk-wizard .hk-wizard-item:not(.hk-wizard-item-active)>.hk-wizard-item-container[role=button] .hk-wizard-item-title .hk-wizard-icon,
.hk-wizard .hk-wizard-item:not(.hk-wizard-item-active)>.hk-wizard-item-container[role=button] .hk-wizard-item-subtitle .hk-wizard-icon,
.hk-wizard .hk-wizard-item:not(.hk-wizard-item-active)>.hk-wizard-item-container[role=button] .hk-wizard-item-description .hk-wizard-icon,
.hk-wizard .hk-wizard-item:not(.hk-wizard-item-active)>.hk-wizard-item-container[role=button] .hk-wizard-item-icon .hk-wizard-icon {
    -webkit-transition: color 0.3s;
    -moz-transition: color 0.3s;
    transition: color 0.3s;
}

.hk-wizard .hk-wizard-item:not(.hk-wizard-item-active)>.hk-wizard-item-container[role=button]:hover .hk-wizard-item-title,
.hk-wizard .hk-wizard-item:not(.hk-wizard-item-active)>.hk-wizard-item-container[role=button]:hover .hk-wizard-item-subtitle,
.hk-wizard .hk-wizard-item:not(.hk-wizard-item-active)>.hk-wizard-item-container[role=button]:hover .hk-wizard-item-description {
    color: #09769F;
}

.hk-wizard .hk-wizard-item:not(.hk-wizard-item-active):not(.hk-wizard-item-process)>.hk-wizard-item-container[role=button]:hover .hk-wizard-item-icon {
    border-color: #09769F;
}

.hk-wizard .hk-wizard-item:not(.hk-wizard-item-active):not(.hk-wizard-item-process)>.hk-wizard-item-container[role=button]:hover .hk-wizard-item-icon .hk-wizard-icon {
    color: #09769F;
}

.hk-wizard:not(.hk-wizard-vertical) .hk-wizard-item-custom .hk-wizard-item-icon {
    width: auto;
    background: 0 0;
}

.hk-wizard-item {
    position: relative;
    display: inline-block;
    flex: 1;
    overflow: hidden;
    vertical-align: top;
}

.hk-wizard-item:last-child {
    flex: none;
}

.hk-wizard-item:last-child>.hk-wizard-item-container>.hk-wizard-item-tail {
    display: none;
}

.hk-wizard-item:last-child>.hk-wizard-item-container>.hk-wizard-item-content>.hk-wizard-item-title:after {
    display: none;
}

.hk-wizard-item-container {
    outline: none;
}

.hk-wizard-item-icon {
    display: inline-block;
    vertical-align: top;
    width: 32px;
    height: 32px;
    margin: 0 .5rem 0 0;
    font-size: 1rem;
    line-height: 32px;
    text-align: center;
    border: 1px solid #c7c7c7;
    border-radius: 2rem;
    transition: background-color .3s, border-color .3s;
}

.hk-wizard-item-icon .hk-wizard-icon {
    position: relative;
    top: -.5px;
    color: #09769F;
}

.hk-wizard-item-content {
    display: inline-block;
    vertical-align: top;
}

.hk-wizard-item-tail {
    position: absolute;
    top: 12px;
    left: 0;
    width: 100%;
    padding: 0 .625rem;
}

.hk-wizard-item-tail:after {
    display: inline-block;
    width: 100%;
    height: 1px;
    background: #d8d8d8;
    border-radius: 1px;
    -webkit-transition: background 0.3s;
    -moz-transition: background 0.3s;
    transition: background 0.3s;
    content: "";
}

.hk-wizard-item-title {
    position: relative;
    display: inline-block;
    padding-right: 1rem;
    font-size: 1rem;
    line-height: 32px;
    color: #262a2e;
}

.hk-wizard-item-title:after {
    position: absolute;
    top: 16px;
    left: 100%;
    display: block;
    width: 9999px;
    height: 1px;
    background: #d8d8d8;
    content: "";
}

.hk-wizard-item-subtitle {
    display: inline;
    margin-left: .5rem;
    font-weight: 400;
    font-size: 0.875rem;
}

.hk-wizard-item-description {
    font-size: 0.875rem;
}

.hk-wizard-item-wait>.hk-wizard-item-container>.hk-wizard-item-content>.hk-wizard-item-title {
    color: #6f6f6f;
}

.hk-wizard-item-wait .hk-wizard-item-icon {
    background-color: #fff;
    border-color: #c7c7c7;
}

.hk-wizard-item-wait .hk-wizard-item-icon>.hk-wizard-icon {
    color: #c7c7c7;
}

.hk-wizard-item-wait .hk-wizard-item-icon>.hk-wizard-icon .hk-wizard-icon-dot {
    background: #c7c7c7;
}

.hk-wizard-item-wait>.hk-wizard-item-container>.hk-wizard-item-content>.hk-wizard-item-title:after {
    background-color: #d8d8d8;
}

.hk-wizard-item-wait>.hk-wizard-item-container>.hk-wizard-item-tail:after {
    background-color: #d8d8d8;
}

.hk-wizard-item-process .hk-wizard-item-icon {
    background-color: #fff;
    border-color: #09769F;
}

.hk-wizard-item-process .hk-wizard-item-icon>.hk-wizard-icon {
    color: #09769F;
}

.hk-wizard-item-process .hk-wizard-item-icon>.hk-wizard-icon .hk-wizard-icon-dot {
    background: #09769F;
}

.hk-wizard-item-process>.hk-wizard-item-container>.hk-wizard-item-content>.hk-wizard-item-title {
    color: #262a2e;
}

.hk-wizard-item-process>.hk-wizard-item-container>.hk-wizard-item-content>.hk-wizard-item-title:after {
    background-color: #d8d8d8;
}

.hk-wizard-item-process>.hk-wizard-item-container>.hk-wizard-item-content>.hk-wizard-item-description {
    color: #262a2e;
}

.hk-wizard-item-process>.hk-wizard-item-container>.hk-wizard-item-tail:after {
    background-color: #d8d8d8;
}

.hk-wizard-item-process>.hk-wizard-item-container>.hk-wizard-item-icon {
    background: #09769F;
}

.hk-wizard-item-process>.hk-wizard-item-container>.hk-wizard-item-icon .hk-wizard-icon {
    color: #fff;
}

.hk-wizard-item-process>.hk-wizard-item-container>.hk-wizard-item-title {
    font-weight: 500;
}

.hk-wizard-item-finish .hk-wizard-item-icon {
    background-color: #fff;
    border-color: #09769F;
}

.hk-wizard-item-finish .hk-wizard-item-icon>.hk-wizard-icon {
    color: #09769F;
}

.hk-wizard-item-finish .hk-wizard-item-icon>.hk-wizard-icon .hk-wizard-icon-dot {
    background: #09769F;
}

.hk-wizard-item-finish>.hk-wizard-item-container>.hk-wizard-item-content>.hk-wizard-item-title {
    color: #262a2e;
}

.hk-wizard-item-finish>.hk-wizard-item-container>.hk-wizard-item-content>.hk-wizard-item-title:after {
    background-color: #09769F;
}

.hk-wizard-item-finish>.hk-wizard-item-container>.hk-wizard-item-tail:after {
    background-color: #09769F;
}

.hk-wizard-item-error .hk-wizard-item-icon {
    background-color: #fff;
    border-color: #FF0000;
}

.hk-wizard-item-error .hk-wizard-item-icon>.hk-wizard-icon {
    color: #FF0000;
}

.hk-wizard-item-error .hk-wizard-item-icon>.hk-wizard-icon .hk-wizard-icon-dot {
    background: #FF0000;
}

.hk-wizard-item-error>.hk-wizard-item-container>.hk-wizard-item-content>.hk-wizard-item-title {
    color: #FF0000;
}

.hk-wizard-item-error>.hk-wizard-item-container>.hk-wizard-item-content>.hk-wizard-item-title:after {
    background-color: #d8d8d8;
}

.hk-wizard-item-error>.hk-wizard-item-container>.hk-wizard-item-content>.hk-wizard-item-description {
    color: #FF0000;
}

.hk-wizard-item-error>.hk-wizard-item-container>.hk-wizard-item-tail:after {
    background-color: #d8d8d8;
}

.hk-wizard-item.hk-wizard-next-error .hk-wizard-item-title:after {
    background: #FF0000;
}

.hk-wizard-item-disabled {
    cursor: not-allowed;
}

.hk-wizard-horizontal:not(.hk-wizard-label-vertical) .hk-wizard-item {
    padding-left: 1rem;
    white-space: nowrap;
}

.hk-wizard-horizontal:not(.hk-wizard-label-vertical) .hk-wizard-item:first-child {
    padding-left: 0;
}

.hk-wizard-horizontal:not(.hk-wizard-label-vertical) .hk-wizard-item:last-child .hk-wizard-item-title {
    padding-right: 0;
}

.hk-wizard-horizontal:not(.hk-wizard-label-vertical) .hk-wizard-item-tail {
    display: none;
}

.hk-wizard-horizontal:not(.hk-wizard-label-vertical) .hk-wizard-item-description {
    max-width: 140px;
    white-space: normal;
}

.hk-wizard-item-custom>.hk-wizard-item-container>.hk-wizard-item-icon {
    height: auto;
    background: 0 0;
    border: 0;
}

.hk-wizard-item-custom>.hk-wizard-item-container>.hk-wizard-item-icon>.hk-wizard-icon {
    top: 0;
    left: .5px;
    width: 32px;
    height: 32px;
    font-size: 1.5rem;
    line-height: 32px;
}

.hk-wizard-item-custom.hk-wizard-item-process .hk-wizard-item-icon>.hk-wizard-icon {
    color: #09769F;
}

.hk-wizard-sm.hk-wizard-horizontal:not(.hk-wizard-label-vertical) .hk-wizard-item {
    padding-left: .75rem;
}

.hk-wizard-sm.hk-wizard-horizontal:not(.hk-wizard-label-vertical) .hk-wizard-item:first-child {
    padding-left: 0;
}

.hk-wizard-sm .hk-wizard-item-icon {
    width: 24px;
    height: 24px;
    margin: 0 .5rem 0 0;
    font-size: 0.75rem;
    line-height: 24px;
    text-align: center;
    border-radius: 1.5rem;
}

.hk-wizard-sm .hk-wizard-item-title {
    padding-right: .75rem;
    font-size: 0.875rem;
    line-height: 24px;
}

.hk-wizard-sm .hk-wizard-item-title:after {
    top: 12px;
}

.hk-wizard-sm .hk-wizard-item-description {
    font-size: 0.875rem;
}

.hk-wizard-sm .hk-wizard-item-tail {
    top: 8px;
}

.hk-wizard-sm .hk-wizard-item-custom .hk-wizard-item-icon {
    width: inherit;
    height: inherit;
    line-height: inherit;
    background: 0 0;
    border: 0;
    border-radius: 0;
}

.hk-wizard-sm .hk-wizard-item-custom .hk-wizard-item-icon>.hk-wizard-icon {
    font-size: 1.5rem;
    line-height: 24px;
    transform: none;
}

.hk-wizard-vertical {
    display: flex;
    flex-direction: column;
}

.hk-wizard-vertical>.hk-wizard-item {
    display: block;
    flex: 1 0 auto;
    padding-left: 0;
    overflow: visible;
}

.hk-wizard-vertical>.hk-wizard-item .hk-wizard-item-icon {
    float: left;
    margin-right: 1rem;
}

.hk-wizard-vertical>.hk-wizard-item .hk-wizard-item-content {
    display: block;
    min-height: 48px;
    overflow: hidden;
}

.hk-wizard-vertical>.hk-wizard-item .hk-wizard-item-title {
    line-height: 32px;
}

.hk-wizard-vertical>.hk-wizard-item .hk-wizard-item-description {
    padding-bottom: .75rem;
}

.hk-wizard-vertical>.hk-wizard-item>.hk-wizard-item-container>.hk-wizard-item-tail {
    position: absolute;
    top: 0;
    left: 16px;
    width: 1px;
    height: 100%;
    padding: 2.375rem 0 .375rem;
}

.hk-wizard-vertical>.hk-wizard-item>.hk-wizard-item-container>.hk-wizard-item-tail:after {
    width: 1px;
    height: 100%;
}

.hk-wizard-vertical>.hk-wizard-item>.hk-wizard-item-container>.hk-wizard-item-content>.hk-wizard-item-title:after {
    display: none;
}

.hk-wizard-vertical>.hk-wizard-item:not(:last-child)>.hk-wizard-item-container>.hk-wizard-item-tail {
    display: block;
}

.hk-wizard-vertical.hk-wizard-sm .hk-wizard-item-container .hk-wizard-item-tail {
    position: absolute;
    top: 0;
    left: 12px;
    padding: 1.875rem 0 .375rem;
}

.hk-wizard-vertical.hk-wizard-sm .hk-wizard-item-container .hk-wizard-item-title {
    line-height: 24px;
}

.hk-wizard-label-vertical .hk-wizard-item {
    overflow: visible;
}

.hk-wizard-label-vertical .hk-wizard-item-tail {
    margin-left: 3.625rem;
    padding: .25rem 1.5rem;
}

.hk-wizard-label-vertical .hk-wizard-item-content {
    display: block;
    width: 116px;
    margin-top: .5rem;
    text-align: center;
}

.hk-wizard-label-vertical .hk-wizard-item-icon {
    display: inline-block;
    margin-left: 2.625rem;
}

.hk-wizard-label-vertical .hk-wizard-item-title {
    padding-right: 0;
    padding-left: 0;
}

.hk-wizard-label-vertical .hk-wizard-item-title:after {
    display: none;
}

.hk-wizard-label-vertical .hk-wizard-item-subtitle {
    display: block;
    margin-bottom: 4px;
    margin-left: 0;
    line-height: 1.5715;
}

.hk-wizard-label-vertical.hk-wizard-sm:not(.hk-wizard-dot) .hk-wizard-item-icon {
    margin-left: 2.875rem;
}

.hk-wizard-dot .hk-wizard-item-title {
    line-height: 1.5715;
}

.hk-wizard-dot .hk-wizard-item-tail {
    top: 2px;
    width: 100%;
    margin: 0 0 0 4.375rem;
    padding: 0;
}

.hk-wizard-dot .hk-wizard-item-tail:after {
    width: calc(100% - 20px);
    height: 3px;
    margin-left: .75rem;
}

.hk-wizard-dot .hk-wizard-item:first-child .hk-wizard-icon-dot {
    left: 2px;
}

.hk-wizard-dot .hk-wizard-item-icon {
    width: 8px;
    height: 8px;
    margin-left: 4.25rem;
    padding-right: 0;
    line-height: 8px;
    background: 0 0;
    border: 0;
}

.hk-wizard-dot .hk-wizard-item-icon .hk-wizard-icon-dot {
    position: relative;
    float: left;
    width: 100%;
    height: 100%;
    border-radius: 6.25rem;
    transition: all .3s;
}

.hk-wizard-dot .hk-wizard-item-icon .hk-wizard-icon-dot:after {
    position: absolute;
    top: -12px;
    left: -26px;
    width: 60px;
    height: 32px;
    content: "";
}

.hk-wizard-dot .hk-wizard-item-content {
    width: 140px;
}

.hk-wizard-dot .hk-wizard-item-process .hk-wizard-item-icon {
    position: relative;
    top: -1px;
    width: 10px;
    height: 10px;
    line-height: 10px;
    background: 0 0;
}

.hk-wizard-dot .hk-wizard-item-process .hk-wizard-icon:first-child .hk-wizard-icon-dot {
    left: 0;
}

.hk-wizard-dot.hk-wizard-sm .hk-wizard-item-title {
    line-height: 1.5715;
}

.hk-wizard-dot.hk-wizard-sm .hk-wizard-item-tail {
    top: 2px;
    width: 100%;
    margin: 0 0 0 4.375rem;
    padding: 0;
}

.hk-wizard-dot.hk-wizard-sm .hk-wizard-item-tail:after {
    width: calc(100% - 20px);
    height: 3px;
    margin-left: .75rem;
}

.hk-wizard-dot.hk-wizard-sm .hk-wizard-item:first-child .hk-wizard-icon-dot {
    left: 2px;
}

.hk-wizard-dot.hk-wizard-sm .hk-wizard-item-icon {
    width: 8px;
    height: 8px;
    margin-left: 4.25rem;
    padding-right: 0;
    line-height: 8px;
    background: 0 0;
    border: 0;
}

.hk-wizard-dot.hk-wizard-sm .hk-wizard-item-icon .hk-wizard-icon-dot {
    position: relative;
    float: left;
    width: 100%;
    height: 100%;
    border-radius: 6.25rem;
}

.hk-wizard-dot.hk-wizard-sm .hk-wizard-item-icon .hk-wizard-icon-dot:after {
    position: absolute;
    top: -12px;
    left: -26px;
    width: 60px;
    height: 32px;
    content: "";
}

.hk-wizard-dot.hk-wizard-sm .hk-wizard-item-content {
    width: 140px;
}

.hk-wizard-dot.hk-wizard-sm .hk-wizard-item-process .hk-wizard-item-icon {
    position: relative;
    top: -1px;
    width: 10px;
    height: 10px;
    line-height: 10px;
    background: 0 0;
}

.hk-wizard-dot.hk-wizard-sm .hk-wizard-item-process .hk-wizard-icon:first-child .hk-wizard-icon-dot {
    left: 0;
}

.hk-wizard-vertical.hk-wizard-dot .hk-wizard-item-icon {
    margin-top: .75rem;
    margin-left: 0;
    background: 0 0;
}

.hk-wizard-vertical.hk-wizard-dot .hk-wizard-item>.hk-wizard-item-container>.hk-wizard-item-tail {
    top: 6.5px;
    left: -9px;
    margin: 0;
    padding: 1.375rem 0 .25rem;
}

.hk-wizard-vertical.hk-wizard-dot .hk-wizard-item:first-child .hk-wizard-icon-dot {
    left: 0;
}

.hk-wizard-vertical.hk-wizard-dot .hk-wizard-item-content {
    width: inherit;
}

.hk-wizard-vertical.hk-wizard-dot .hk-wizard-item-process .hk-wizard-item-container .hk-wizard-item-icon .hk-wizard-icon-dot {
    top: -1px;
    left: -1px;
}

/*Pricing Table*/
.hk-pricing-table-1 .table th {
    font-size: 1rem;
    white-space: inherit !important;
}

.hk-pricing-table-1 .table thead tr th h5 {
    padding: .75rem 1.25rem;
    margin: 0 -1.25rem;
    border-bottom: 1px solid #d8d8d8;
}

.hk-pricing-table-1 .table thead tr th.pricing-highlighted h5 {
    background: #09769F;
    color: #fff;
}

.hk-pricing-table-1 .table tbody .monthly-price {
    text-align: center;
}

.hk-pricing-table-1 .table tbody .monthly-price .money {
    display: inline-block;
    vertical-align: top;
    padding-top: 1rem;
}

.hk-pricing-table-1 .table tbody .monthly-price .price sup {
    display: inline-block;
    vertical-align: top;
    padding-top: 1.5rem;
    font-size: 1.125rem;
}

.hk-pricing-table-1 .table tbody .monthly-price .price .price-number {
    font-size: 3.3rem;
    font-weight: 500;
}

.hk-pricing-table-1 .table tbody .monthly-price .duration {
    position: relative;
    top: 10px;
}

.hk-pricing-table-2 .table-responsive {
    padding-top: 3.125rem;
}

.hk-pricing-table-2 .table th {
    font-size: 1rem;
    white-space: inherit !important;
}

.hk-pricing-table-2 .table .pricing-highlighted {
    background: rgba(0, 125, 136, 0.15);
}

.hk-pricing-table-2 .table thead tr th {
    position: relative;
}

.hk-pricing-table-2 .table thead tr th.pricing-highlighted h5 {
    padding: .75rem 1.25rem;
    top: -47px;
    position: absolute;
    border-bottom: 1px solid #d8d8d8;
    background: #09769F;
    color: #fff;
    left: -1px;
    right: -1px;
}

.hk-pricing-table-2 .table tbody .monthly-price {
    text-align: center;
}

.hk-pricing-table-2 .table tbody .monthly-price .money {
    display: inline-block;
    vertical-align: top;
    padding-top: 1rem;
}

.hk-pricing-table-2 .table tbody .monthly-price .price sup {
    display: inline-block;
    vertical-align: top;
    padding-top: 1.5rem;
    font-size: 1.125rem;
}

.hk-pricing-table-2 .table tbody .monthly-price .price .price-number {
    font-size: 3.3rem;
    font-weight: 500;
}

.hk-pricing-table-2 .table tbody .monthly-price .duration {
    position: relative;
    top: 10px;
}

.hk-pricing-table-3 {
    text-align: center;
}

.hk-pricing-table-3 .table-responsive {
    padding-top: 3.125rem;
}

.hk-pricing-table-3 .table th {
    font-size: 1rem;
    white-space: inherit !important;
}

.hk-pricing-table-3 .table th .icon-wrap {
    margin-top: 1.5rem;
    font-size: 2.5rem;
}

.hk-pricing-table-3 .table tbody .monthly-price .money {
    display: inline-block;
    vertical-align: top;
    padding-top: 1rem;
}

.hk-pricing-table-3 .table tbody .monthly-price .price sup {
    display: inline-block;
    vertical-align: top;
    padding-top: 1.5rem;
    font-size: 1.125rem;
}

.hk-pricing-table-3 .table tbody .monthly-price .price .price-number {
    font-size: 2.6rem;
    font-weight: 500;
}

.hk-pricing-table-3 .table tbody .monthly-price .duration {
    display: inline-block;
    vertical-align: top;
    padding-top: 1.125rem;
}




/*Togglable*/
.hk-sidebar-togglable {
    height: 25px;
    width: 25px;
    left: -12.5px;
    border-radius: 50%;
    top: 52px;
    position: absolute;
    background: #fff;
    cursor: pointer;
    border: 1px solid #d8d8d8;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    box-shadow: 0 0.125rem 0.125rem rgba(47, 52, 58, 0.1) !important;
    -webkit-transition: -webkit-transform 0.3s ease-in-out;
    -moz-transition: -moz-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
    z-index: 2;
}

.hk-sidebar-togglable:after {
    content: "\ea62";
    font-family: remixicon;
    text-rendering: auto;
}

.hk-sidebar-togglable.active {
    -webkit-transform: translateX(32px);
    -moz-transform: translateX(32px);
    -ms-transform: translateX(32px);
    -o-transform: translateX(32px);
    transform: translateX(32px);
}

.hk-sidebar-togglable.active:after {
    content: "\ea6c";
}




/*Playlist widget*/
.hk-playlist-wrap .media {
    cursor: pointer;
}

.hk-playlist-wrap .media .media-body .song-name {
    color: #262a2e;
}

.hk-playlist-wrap .media .media-body .palyer-action-wrap .volume-ctrl {
    opacity: 0;
    -webkit-transition: 0.15 ease-in-out;
    -moz-transition: 0.15 ease-in-out;
    transition: 0.15 ease-in-out;
}

.hk-playlist-wrap .media.active .media-body .song-name {
    color: #09769F;
}

.hk-playlist-wrap .media.active .media-body .palyer-action-wrap .volume-ctrl {
    opacity: 1;
}



/*Ribbons*/
.hk-ribbon-type-1 {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 20px;
    background: #09769F;
    color: #fff;
    padding: .25rem .75rem;
    border-radius: 0.5rem;
    font-size: 0.75rem;
    min-height: 26px;
    top: 0;
    text-align: center;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}

.hk-ribbon-type-1>span {
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    z-index: 1;
}

.hk-ribbon-type-1.ribbon-wth-flag:before {
    background-color: transparent;
    top: 0;
    bottom: 0;
    content: "";
    position: absolute;
    border-top: 13px solid #09769F;
    border-bottom: 13px solid #09769F;
    border-right: 13px solid #09769F;
    border-left: 0.6rem solid transparent !important;
    left: -14px;
}

.hk-ribbon-type-1.ribbon-wth-flag:after {
    background-color: transparent;
    border: 13px solid #09769F;
    top: 0;
    bottom: 0;
    content: "";
    position: absolute;
    right: -14px;
    border-top: 13px solid #09769F;
    border-bottom: 13px solid #09769F;
    border-left: 13px solid #09769F;
    border-right: 0.6rem solid transparent !important;
}

.hk-ribbon-type-1.overhead-start {
    left: 14px;
}

.hk-ribbon-type-1.overhead-start.ribbon-wth-flag {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.hk-ribbon-type-1.overhead-start.ribbon-wth-flag:before {
    display: none;
}

.hk-ribbon-type-1.overhead-end {
    right: 14px;
    left: auto;
}

.hk-ribbon-type-1.overhead-end.ribbon-wth-flag {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.hk-ribbon-type-1.overhead-end.ribbon-wth-flag:after {
    display: none;
}

.hk-ribbon-type-1.overhead-center {
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    right: auto;
}

.hk-ribbon-type-1.overhead-center.ribbon-wth-flag {
    border-radius: 0;
}

.hk-ribbon-type-1.start-over {
    left: -14px;
    top: 14px;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none;
}

.hk-ribbon-type-1.start-over.ribbon-wth-flag {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.hk-ribbon-type-1.start-over.ribbon-wth-flag:before {
    display: none;
}

.hk-ribbon-type-1.end-over {
    right: -14px;
    left: auto;
    top: 14px;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none;
}

.hk-ribbon-type-1.end-over.ribbon-wth-flag {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.hk-ribbon-type-1.end-over.ribbon-wth-flag:after {
    display: none;
}

.hk-ribbon-type-1.start-touch {
    left: 0;
    top: 14px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none;
}

.hk-ribbon-type-1.start-touch.ribbon-wth-flag {
    border-radius: 0;
}

.hk-ribbon-type-1.start-touch.ribbon-wth-flag:before {
    display: none;
}

.hk-ribbon-type-1.end-touch {
    right: 0;
    left: auto;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    top: 14px;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none;
}

.hk-ribbon-type-1.end-touch.ribbon-wth-flag {
    border-radius: 0;
}

.hk-ribbon-type-1.end-touch.ribbon-wth-flag:after {
    display: none;
}

.hk-ribbon-type-1.ribbon-square {
    border-radius: 0;
}

.hk-ribbon-type-1.ribbon-wth-icon>span>* {
    font-size: 1.125rem;
    margin-right: .25rem;
}

.hk-ribbon-type-1.hk-ribbon-uppercase {
    font-weight: 600;
    font-size: 0.75rem;
    text-transform: uppercase;
}

.hk-ribbon-type-1.hk-ribbon-blue {
    background: #298DFF;
}

.hk-ribbon-type-1.hk-ribbon-blue.ribbon-wth-flag:before,
.hk-ribbon-type-1.hk-ribbon-blue.ribbon-wth-flag:after {
    border-color: #298DFF;
}

.hk-ribbon-type-1.hk-ribbon-primary {
    background: #09769F;
}

.hk-ribbon-type-1.hk-ribbon-primary.ribbon-wth-flag:before,
.hk-ribbon-type-1.hk-ribbon-primary.ribbon-wth-flag:after {
    border-color: #09769F;
}

.hk-ribbon-type-1.hk-ribbon-info {
    background: #18DDEF;
}

.hk-ribbon-type-1.hk-ribbon-info.ribbon-wth-flag:before,
.hk-ribbon-type-1.hk-ribbon-info.ribbon-wth-flag:after {
    border-color: #18DDEF;
}

.hk-ribbon-type-1.hk-ribbon-cyan {
    background: #18DDEF;
}

.hk-ribbon-type-1.hk-ribbon-cyan.ribbon-wth-flag:before,
.hk-ribbon-type-1.hk-ribbon-cyan.ribbon-wth-flag:after {
    border-color: #18DDEF;
}

.hk-ribbon-type-1.hk-ribbon-green {
    background: #00D67F;
}

.hk-ribbon-type-1.hk-ribbon-green.ribbon-wth-flag:before,
.hk-ribbon-type-1.hk-ribbon-green.ribbon-wth-flag:after {
    border-color: #00D67F;
}

.hk-ribbon-type-1.hk-ribbon-success {
    background: #00D67F;
}

.hk-ribbon-type-1.hk-ribbon-success.ribbon-wth-flag:before,
.hk-ribbon-type-1.hk-ribbon-success.ribbon-wth-flag:after {
    border-color: #00D67F;
}

.hk-ribbon-type-1.hk-ribbon-red {
    background: #FF0000;
}

.hk-ribbon-type-1.hk-ribbon-red.ribbon-wth-flag:before,
.hk-ribbon-type-1.hk-ribbon-red.ribbon-wth-flag:after {
    border-color: #FF0000;
}

.hk-ribbon-type-1.hk-ribbon-danger {
    background: #FF0000;
}

.hk-ribbon-type-1.hk-ribbon-danger.ribbon-wth-flag:before,
.hk-ribbon-type-1.hk-ribbon-danger.ribbon-wth-flag:after {
    border-color: #FF0000;
}

.hk-ribbon-type-1.hk-ribbon-yellow {
    background: #FFC400;
}

.hk-ribbon-type-1.hk-ribbon-yellow.ribbon-wth-flag:before,
.hk-ribbon-type-1.hk-ribbon-yellow.ribbon-wth-flag:after {
    border-color: #FFC400;
}

.hk-ribbon-type-1.hk-ribbon-warning {
    background: #FFC400;
}

.hk-ribbon-type-1.hk-ribbon-warning.ribbon-wth-flag:before,
.hk-ribbon-type-1.hk-ribbon-warning.ribbon-wth-flag:after {
    border-color: #FFC400;
}

.hk-ribbon-type-1.hk-ribbon-sky {
    background: #00B0FF;
}

.hk-ribbon-type-1.hk-ribbon-sky.ribbon-wth-flag:before,
.hk-ribbon-type-1.hk-ribbon-sky.ribbon-wth-flag:after {
    border-color: #00B0FF;
}

.hk-ribbon-type-1.hk-ribbon-pink {
    background: #E92990;
}

.hk-ribbon-type-1.hk-ribbon-pink.ribbon-wth-flag:before,
.hk-ribbon-type-1.hk-ribbon-pink.ribbon-wth-flag:after {
    border-color: #E92990;
}

.hk-ribbon-type-1.hk-ribbon-purple {
    background: #C02FF3;
}

.hk-ribbon-type-1.hk-ribbon-purple.ribbon-wth-flag:before,
.hk-ribbon-type-1.hk-ribbon-purple.ribbon-wth-flag:after {
    border-color: #C02FF3;
}

.hk-ribbon-type-1.hk-ribbon-violet {
    background: #7429F8;
}

.hk-ribbon-type-1.hk-ribbon-violet.ribbon-wth-flag:before,
.hk-ribbon-type-1.hk-ribbon-violet.ribbon-wth-flag:after {
    border-color: #7429F8;
}

.hk-ribbon-type-1.hk-ribbon-indigo {
    background: #0069F7;
}

.hk-ribbon-type-1.hk-ribbon-indigo.ribbon-wth-flag:before,
.hk-ribbon-type-1.hk-ribbon-indigo.ribbon-wth-flag:after {
    border-color: #0069F7;
}

.hk-ribbon-type-1.hk-ribbon-teal {
    background: #09769F;
}

.hk-ribbon-type-1.hk-ribbon-teal.ribbon-wth-flag:before,
.hk-ribbon-type-1.hk-ribbon-teal.ribbon-wth-flag:after {
    border-color: #09769F;
}

.hk-ribbon-type-1.hk-ribbon-neon {
    background: #81E230;
}

.hk-ribbon-type-1.hk-ribbon-neon.ribbon-wth-flag:before,
.hk-ribbon-type-1.hk-ribbon-neon.ribbon-wth-flag:after {
    border-color: #81E230;
}

.hk-ribbon-type-1.hk-ribbon-lime {
    background: #C2E939;
}

.hk-ribbon-type-1.hk-ribbon-lime.ribbon-wth-flag:before,
.hk-ribbon-type-1.hk-ribbon-lime.ribbon-wth-flag:after {
    border-color: #C2E939;
}

.hk-ribbon-type-1.hk-ribbon-sun {
    color: #262a2e;
    background: #FFEA00;
}

.hk-ribbon-type-1.hk-ribbon-sun.ribbon-wth-flag:before,
.hk-ribbon-type-1.hk-ribbon-sun.ribbon-wth-flag:after {
    border-color: #FFEA00;
}

.hk-ribbon-type-1.hk-ribbon-orange {
    background: #FF9100;
}

.hk-ribbon-type-1.hk-ribbon-orange.ribbon-wth-flag:before,
.hk-ribbon-type-1.hk-ribbon-orange.ribbon-wth-flag:after {
    border-color: #FF9100;
}

.hk-ribbon-type-1.hk-ribbon-pumpkin {
    background: #FF5700;
}

.hk-ribbon-type-1.hk-ribbon-pumpkin.ribbon-wth-flag:before,
.hk-ribbon-type-1.hk-ribbon-pumpkin.ribbon-wth-flag:after {
    border-color: #FF5700;
}

.hk-ribbon-type-1.hk-ribbon-brown {
    background: #954D15;
}

.hk-ribbon-type-1.hk-ribbon-brown.ribbon-wth-flag:before,
.hk-ribbon-type-1.hk-ribbon-brown.ribbon-wth-flag:after {
    border-color: #954D15;
}

.hk-ribbon-type-1.hk-ribbon-gold {
    background: #C39749;
}

.hk-ribbon-type-1.hk-ribbon-gold.ribbon-wth-flag:before,
.hk-ribbon-type-1.hk-ribbon-gold.ribbon-wth-flag:after {
    border-color: #C39749;
}

.hk-ribbon-type-1.hk-ribbon-smoke {
    background: #5e7d8a;
}

.hk-ribbon-type-1.hk-ribbon-smoke.ribbon-wth-flag:before,
.hk-ribbon-type-1.hk-ribbon-smoke.ribbon-wth-flag:after {
    border-color: #5e7d8a;
}

.hk-ribbon-type-1.hk-ribbon-grey {
    background: #9e9e9e;
}

.hk-ribbon-type-1.hk-ribbon-grey.ribbon-wth-flag:before,
.hk-ribbon-type-1.hk-ribbon-grey.ribbon-wth-flag:after {
    border-color: #9e9e9e;
}

.hk-ribbon-type-1.hk-ribbon-secondary {
    background: #6f6f6f;
}

.hk-ribbon-type-1.hk-ribbon-secondary.ribbon-wth-flag:before,
.hk-ribbon-type-1.hk-ribbon-secondary.ribbon-wth-flag:after {
    border-color: #6f6f6f;
}

.hk-ribbon-type-1.hk-ribbon-light {
    background: #eaeaea;
    color: #262a2e;
}

.hk-ribbon-type-1.hk-ribbon-light.ribbon-wth-flag:before,
.hk-ribbon-type-1.hk-ribbon-light.ribbon-wth-flag:after {
    border-color: #eaeaea;
}

.hk-ribbon-type-1.hk-ribbon-dark {
    background: #262a2e;
}

.hk-ribbon-type-1.hk-ribbon-dark.ribbon-wth-flag:before,
.hk-ribbon-type-1.hk-ribbon-dark.ribbon-wth-flag:after {
    border-color: #262a2e;
}

.hk-ribbon-type-2 {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 20px;
    background: #09769F;
    color: #fff;
    padding: .25rem .5rem;
    font-size: 1.125rem;
    font-weight: 500;
    top: -8px;
    height: 39px;
    width: 34px;
    text-align: center;
}

.hk-ribbon-type-2>span {
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    z-index: 1;
    font-size: 1.25rem;
}

.hk-ribbon-type-2:after {
    background-color: transparent;
    border-top: 17px solid #09769F;
    border-right: 17px solid #09769F;
    border-left: 17px solid #09769F;
    border-bottom: 0.6rem solid transparent !important;
    bottom: -10px;
    content: "";
    position: absolute;
    left: 0;
    right: 0;
}

.hk-ribbon-type-2.overhead-start {
    left: 20px;
}

.hk-ribbon-type-2.overhead-end {
    right: 20px;
    left: auto;
}

.hk-ribbon-type-2.overhead-center {
    left: 50%;
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    right: auto;
}

.hk-ribbon-type-2.top-touch {
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none;
    top: 0;
}

.hk-ribbon-type-2.top-touch.overhead-center {
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
}

.hk-ribbon-type-2.hk-ribbon-blue {
    background: #298DFF;
}

.hk-ribbon-type-2.hk-ribbon-blue:after {
    border-color: #298DFF;
}

.hk-ribbon-type-2.hk-ribbon-primary {
    background: #09769F;
}

.hk-ribbon-type-2.hk-ribbon-primary:after {
    border-color: #09769F;
}

.hk-ribbon-type-2.hk-ribbon-cyan {
    background: #18DDEF;
}

.hk-ribbon-type-2.hk-ribbon-cyan:before,
.hk-ribbon-type-2.hk-ribbon-cyan:after {
    border-color: #18DDEF;
}

.hk-ribbon-type-2.hk-ribbon-info {
    background: #18DDEF;
}

.hk-ribbon-type-2.hk-ribbon-info:before,
.hk-ribbon-type-2.hk-ribbon-info:after {
    border-color: #18DDEF;
}

.hk-ribbon-type-2.hk-ribbon-green {
    background: #00D67F;
}

.hk-ribbon-type-2.hk-ribbon-green:before,
.hk-ribbon-type-2.hk-ribbon-green:after {
    border-color: #00D67F;
}

.hk-ribbon-type-2.hk-ribbon-success {
    background: #00D67F;
}

.hk-ribbon-type-2.hk-ribbon-success:before,
.hk-ribbon-type-2.hk-ribbon-success:after {
    border-color: #00D67F;
}

.hk-ribbon-type-2.hk-ribbon-red {
    background: #FF0000;
}

.hk-ribbon-type-2.hk-ribbon-red:before,
.hk-ribbon-type-2.hk-ribbon-red:after {
    border-color: #FF0000;
}

.hk-ribbon-type-2.hk-ribbon-danger {
    background: #FF0000;
}

.hk-ribbon-type-2.hk-ribbon-danger:before,
.hk-ribbon-type-2.hk-ribbon-danger:after {
    border-color: #FF0000;
}

.hk-ribbon-type-2.hk-ribbon-yellow {
    background: #FFC400;
}

.hk-ribbon-type-2.hk-ribbon-yellow:before,
.hk-ribbon-type-2.hk-ribbon-yellow:after {
    border-color: #FFC400;
}

.hk-ribbon-type-2.hk-ribbon-warning {
    background: #FFC400;
}

.hk-ribbon-type-2.hk-ribbon-warning:before,
.hk-ribbon-type-2.hk-ribbon-warning:after {
    border-color: #FFC400;
}

.hk-ribbon-type-2.hk-ribbon-sky {
    background: #00B0FF;
}

.hk-ribbon-type-2.hk-ribbon-sky:before,
.hk-ribbon-type-2.hk-ribbon-sky:after {
    border-color: #00B0FF;
}

.hk-ribbon-type-2.hk-ribbon-pink {
    background: #E92990;
}

.hk-ribbon-type-2.hk-ribbon-pink:before,
.hk-ribbon-type-2.hk-ribbon-pink:after {
    border-color: #E92990;
}

.hk-ribbon-type-2.hk-ribbon-purple {
    background: #C02FF3;
}

.hk-ribbon-type-2.hk-ribbon-purple:before,
.hk-ribbon-type-2.hk-ribbon-purple:after {
    border-color: #C02FF3;
}

.hk-ribbon-type-2.hk-ribbon-violet {
    background: #7429F8;
}

.hk-ribbon-type-2.hk-ribbon-violet:before,
.hk-ribbon-type-2.hk-ribbon-violet:after {
    border-color: #7429F8;
}

.hk-ribbon-type-2.hk-ribbon-indigo {
    background: #0069F7;
}

.hk-ribbon-type-2.hk-ribbon-indigo:before,
.hk-ribbon-type-2.hk-ribbon-indigo:after {
    border-color: #0069F7;
}

.hk-ribbon-type-2.hk-ribbon-teal {
    background: #09769F;
}

.hk-ribbon-type-2.hk-ribbon-teal:before,
.hk-ribbon-type-2.hk-ribbon-teal:after {
    border-color: #09769F;
}

.hk-ribbon-type-2.hk-ribbon-neon {
    background: #81E230;
}

.hk-ribbon-type-2.hk-ribbon-neon:before,
.hk-ribbon-type-2.hk-ribbon-neon:after {
    border-color: #81E230;
}

.hk-ribbon-type-2.hk-ribbon-lime {
    background: #C2E939;
}

.hk-ribbon-type-2.hk-ribbon-lime:before,
.hk-ribbon-type-2.hk-ribbon-lime:after {
    border-color: #C2E939;
}

.hk-ribbon-type-2.hk-ribbon-sun {
    background: #FFEA00;
    color: #262a2e;
}

.hk-ribbon-type-2.hk-ribbon-sun:before,
.hk-ribbon-type-2.hk-ribbon-sun:after {
    border-color: #FFEA00;
}

.hk-ribbon-type-2.hk-ribbon-orange {
    background: #FF9100;
}

.hk-ribbon-type-2.hk-ribbon-orange:before,
.hk-ribbon-type-2.hk-ribbon-orange:after {
    border-color: #FF9100;
}

.hk-ribbon-type-2.hk-ribbon-pumpkin {
    background: #FF5700;
}

.hk-ribbon-type-2.hk-ribbon-pumpkin:before,
.hk-ribbon-type-2.hk-ribbon-pumpkin:after {
    border-color: #FF5700;
}

.hk-ribbon-type-2.hk-ribbon-brown {
    background: #954D15;
}

.hk-ribbon-type-2.hk-ribbon-brown:before,
.hk-ribbon-type-2.hk-ribbon-brown:after {
    border-color: #954D15;
}

.hk-ribbon-type-2.hk-ribbon-gold {
    background: #C39749;
}

.hk-ribbon-type-2.hk-ribbon-gold:before,
.hk-ribbon-type-2.hk-ribbon-gold:after {
    border-color: #C39749;
}

.hk-ribbon-type-2.hk-ribbon-smoke {
    background: #5e7d8a;
}

.hk-ribbon-type-2.hk-ribbon-smoke:before,
.hk-ribbon-type-2.hk-ribbon-smoke:after {
    border-color: #5e7d8a;
}

.hk-ribbon-type-2.hk-ribbon-grey {
    background: #9e9e9e;
}

.hk-ribbon-type-2.hk-ribbon-grey:before,
.hk-ribbon-type-2.hk-ribbon-grey:after {
    border-color: #9e9e9e;
}

.hk-ribbon-type-2.hk-ribbon-secondary {
    background: #6f6f6f;
}

.hk-ribbon-type-2.hk-ribbon-secondary:before,
.hk-ribbon-type-2.hk-ribbon-secondary:after {
    border-color: #6f6f6f;
}

.hk-ribbon-type-2.hk-ribbon-light {
    background: #eaeaea;
    color: #262a2e;
}

.hk-ribbon-type-2.hk-ribbon-light:before,
.hk-ribbon-type-2.hk-ribbon-light:after {
    border-color: #eaeaea;
}

.hk-ribbon-type-2.hk-ribbon-dark {
    background: #262a2e;
}

.hk-ribbon-type-2.hk-ribbon-dark:before,
.hk-ribbon-type-2.hk-ribbon-dark:after {
    border-color: #262a2e;
}

/*Toolbar*/
.hk-toolbar .nav {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    height: 100%;
}

.hk-toolbar .nav .nav-item {
    height: 100%;
}

.hk-toolbar.toolbar-asymmetric {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
}


/*Authantication Pages*/
.hk-wrapper.hk-pg-auth .hk-pg-wrapper .hk-pg-body .auth-content {
    min-height: calc(100vh - 249px);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
}

.hk-wrapper.hk-pg-auth .hk-pg-wrapper .v-separator {
    margin: 0;
    position: relative;
}

.hk-wrapper.hk-pg-auth .hk-pg-wrapper .v-separator:after {
    height: 100%;
    right: 0;
}

.hk-wrapper.hk-pg-auth .hk-pg-wrapper .v-separator.separator-sm:after {
    height: 70%;
}

.hk-wrapper.hk-pg-auth .hk-pg-wrapper .auth-split {
    min-height: 100vh;
}

.hk-wrapper.hk-pg-auth .hk-pg-wrapper .auth-split .auth-content {
    min-height: 100vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    position: relative;
}

.hk-wrapper.hk-pg-auth .hk-pg-wrapper .auth-split .auth-content mark {
    z-index: 1;
    color: #262a2e;
}

.hk-wrapper.hk-pg-auth .hk-pg-wrapper .auth-split .auth-content mark:after {
    z-index: -1;
    background: #00D67F;
    opacity: 1;
}

.hk-wrapper.hk-pg-auth .hk-pg-wrapper .auth-split .auth-content .extr-link {
    font-size: 0.75rem;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: flex-end;
    -webkit-justify-content: flex-end;
}

.hk-wrapper.hk-pg-auth .hk-pg-wrapper .auth-split .auth-content .extr-link .feather-icon,
.hk-wrapper.hk-pg-auth .hk-pg-wrapper .auth-split .auth-content .extr-link .svg-icon {
    margin-right: .25rem;
}

.hk-wrapper.hk-pg-auth .hk-pg-wrapper .auth-split .auth-content .extr-link .feather-icon svg,
.hk-wrapper.hk-pg-auth .hk-pg-wrapper .auth-split .auth-content .extr-link .svg-icon svg {
    height: 14px;
    width: 14px;
}

.hk-wrapper.hk-pg-auth .hk-pg-wrapper .auth-split .credit-text {
    position: absolute;
    left: 30px;
    bottom: 30px;
    mix-blend-mode: inherit;
}

.hk-wrapper.hk-pg-auth .hk-pg-wrapper .auth-split .bg-img {
    position: absolute;
    left: 0;
    top: 0;
    max-width: 100%;
    object-fit: cover;
    height: 100%;
    z-index: -1;
}

@media (max-width: 767px) {
    .hk-wrapper.hk-pg-auth .hk-pg-wrapper .hk-pg-body .container-xxl .auth-content {
        min-height: auto;
        -webkit-box-align: flex-start;
        -ms-flex-align: flex-start;
        align-items: flex-start;
    }

    .hk-wrapper.hk-pg-auth .v-separator:after {
        display: none;
    }
}
