
/*Font Size*/
.fs-7 {
    font-size: 0.875rem !important;
}

.fs-8 {
    font-size: 0.75rem !important;
}

.font-1 {
    font-size: 0.5rem !important;
}

.font-2 {
    font-size: 0.75rem !important;
}

.font-3 {
    font-size: 1rem !important;
}

.font-4 {
    font-size: 1.25rem !important;
}

.font-5 {
    font-size: 1.5rem !important;
}

.font-6 {
    font-size: 1.75rem !important;
}

.font-6-5 {
    font-size: 1.8rem !important;
}

.font-7 {
    font-size: 2rem !important;
}

.font-8 {
    font-size: 2.25rem !important;
}

.font-9 {
    font-size: 2.5rem !important;
}

.font-10 {
    font-size: 2.75rem !important;
}

/*Font Weight*/
.fw-semibold {
    font-weight: 600 !important;
}

.fw-medium {
    font-weight: 500 !important;
}

/*Text Case*/
.text-nonecase {
    text-transform: none !important;
}

/*Letter Spacing*/
.ls-0 {
    letter-spacing: 0px !important;
}

.ls-1 {
    letter-spacing: 0.25px !important;
}

.ls-2 {
    letter-spacing: 0.5px !important;
}

.ls-3 {
    letter-spacing: 0.75px !important;
}

.ls-4 {
    letter-spacing: 1px !important;
}

.ls-n-1 {
    letter-spacing: -0.25px !important;
}

.ls-n-2 {
    letter-spacing: -0.5px !important;
}

.ls-n-3 {
    letter-spacing: -0.75px !important;
}

.ls-n-4 {
    letter-spacing: -1px !important;
}

/*Line Height*/
.lh-0 {
    line-height: 0 !important;
}
