.GVRFootnote {
    font-weight: 600;
}

.ListParagraph {
    margin-left: 10px;
}

.badge.badge-pill {
    margin-bottom: 10px;
    margin-right: 5px;
}

.badge_segment {
    background: #fff;
    border-radius: 2px;
    font-size: 13px;
    margin-left: 6px;
    padding: 3px 8px;
    border: 1px solid #EEE;
}

.breadcrumb-item+.breadcrumb-item:before {
    content: ">";
}

.signalBox{
    border-radius: 5px;
    border: 1px solid #d8d8d8;
}
.signalBoxtext{
   font-size: 1rem;
    padding: 0.75rem 1.25rem;
    font-weight: 500;
   
}
.carousel-caption {
    bottom: 0 !important;
}

.center-search {
    width: 75%;
    margin: 30px 0;
}

.chartbuttons {
    margin: 3px;
}

.chartsTable {
    width: 720px;
    overflow: scroll;
}

.data_book_col {
    min-height: 120px;
}

.disabledCursor {
    cursor: default;
}

.exploreTitle {
    color: #09769F;
    transition: color .2s ease;
    cursor: pointer;
}

.filter-toc-body {
    margin-top: 10px;
    height: 525px;
    overflow-y: scroll;
}

.footer_2::after {
    background: linear-gradient(180deg, #340F49CF 0%, rgb(223, 236, 241) 100%);
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.3;
}

.fr {
    float: right;
}

.fs-14,
.header_user_drop .dropdown-toggle,
.hr_report_view_col p {
    font-size: 14px;
}

.fs-16 {
    font-size: 16px;
}

.fs-18 {
    font-size: 18px;
}

.gv_header_search button {
    border-radius: 0 30px 30px 0;
}

.gv_header_search input.form-control {
    border-radius: 30px;
    height: 60px;
}

.header-search-box .header-s-btn {
    background: #09769F;
    color: #fff;
    margin-left: -5px;
    border-radius: 0 5px 5px 0;
    padding: 5px 15px;
}

.header-search-box .header-s-btn:hover,
.header-search-box .header-s-btn:focus {
    box-shadow: none !important;
    background: #02abba !important;
    color: #FFF;
}

.header-search-box .page-s-btn {
    background: #09769F;
    color: #fff;
    border-radius: 0 5px 5px 0;
    padding: 5px 50px;
    position: absolute;
    top: 0;
    right: 0;
    height: 55px;
}

.header-search-box .page-s-btn:hover,
.header-search-box .page-s-btn:focus {
    box-shadow: none !important;
    background: #02abba !important;
    color: #FFF;
}

.header-search-box .header-s-input {
    padding: 5px 15px;
}

.header-search-box .page-hd-input {
    height: 55px;
    padding-left: 40px !important;
}

.header-search-box .header-s-input:hover,
.header-search-box .header-s-input:focus {
    box-shadow: none !important;
    border: 1px solid #09769F !important;
}

.header-search-box .page-s-icon {
    top: 14px !important;
}

.header-search-box .s-input {
    width: 1000px;
    padding-left: 45px;
}

.header-search-box .search_bar_icn {
    position: absolute;
    left: 10px;
    top: 17px !important;
}

.hk-wrapper .hk-navbar .navbar-search .dropdown-menu,
.hk-wrapper .hk-navbar .navbar-search .apexcharts-toolbar .apexcharts-menu,
.apexcharts-toolbar .hk-wrapper .hk-navbar .navbar-search .apexcharts-menu {
    width: 45rem;
}



.hr_report_view_col h1 {
    font-size: 24px;
}

.hz_fr_search_banner {
    background: #5BCAF4E3;
}

.hz_fr_search_banner input {
    background-color: #fff !important;
    border: none;
    font-size: 15px;
    height: 48px;
    border-radius: 4px;
    background: url(/src/assets/img/search.svg) no-repeat;
    background-position: 10px 14px;
    padding: 12px 20px 12px 36px;
}

.interest_topic_text h6 a {
    cursor: not-allowed;
}

.left_filter_bar {
    border: 1px solid #ddd;
    border-radius: 5px;
    background: #fff;
    margin-bottom: 20px;
}

.left_filter_bar .accordion-body {
    padding: 12px;
}

.left_filter_bar .accordion-button:hover,
.accordion-button:focus {
    box-shadow: none;
    background: #fff;
    font-size: 14px;
}

.left_filter_bar .accordion-button:not(.collapsed) {
    background: #fff;
    box-shadow: none;
    font-size: 14px;
}

.left_filter_bar .accordion-item {
    border: 0 none;
}

.left_filter_bar ul li {
    margin: 6px 0;
}

.list_view_box a:hover {
    color: #09769F;
}


.make_blur {
    filter: blur(1px);
}

.make_blur_9 {
    filter: blur(9px);
}

.mr-1 {
    margin-right: 1rem !important;
}

.mr-5 {
    margin-right: 5px;
}

.mt-4-5 {
    margin: 2.5rem !important;
}

.owl-carousel .owl-item img {
    display: initial !important;
    width: 100% !important;
}



.pagination .page-item.current-page .page-link {
    background: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
    color: #fff;
    border-color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
}


.pl-0 {
    padding-left: 0 !important;
}

.profile-wrap header.profile-header {
    height: auto;
}

.relative-overlay {
    position: relative !important;
    top: -50% !important;
}

.report_deatil_top_tillebar {
    padding-right: 16px;
    margin-bottom: 15px;
}

.report_deatil_top_tillebar_ksview {
    padding-right: 16px;
    margin-bottom: 20px;
}
.upgradesignal{
    position: fixed !important;
    margin-left: 100px;
    }

.report_deatil_top_tillebar_ksview span,
.report_deatil_top_tillebar span {
    color: #454545;
}

.report_header {
    display: flex;
    justify-content: space-between;
}

.reports_data_search {
    margin-left: auto;
    display: inline-block;
    position: relative;
}

.reports_data_search .submitbutton {
    position: absolute;
    background: transparent;
    border: none;
    right: 4px;
    top: 0;
    z-index: 9;
    padding: 5px;
}

.reports_data_search input {
    padding-right: 32px;
}

.reports_data_search input[type="text"] {
    position: relative;
}

.reports_tabs .nav-item {
    width: 100%;
}

.reports_tabs .nav-link {
    text-align: left;
    border-bottom: 1px solid #dde0e4;
    color: #262a2e;
    width: 100%;
    font-size: 14px;
    position: relative;
}

.reports_tabs .nav-link.active {
    color: #fff;
    background: #5ccbf5;
}

.reports_tabs .nav-link.active:after {
    content: "";
    border: solid #fff;
    transform: rotate(-45deg);
    position: absolute;
    right: 10px;
    top: 13px;
    border-width: 0 2px 2px 0;
    padding: 4px;
}

.scrollbar-thumb {
    background: #00000033;
}

.search_bar_icn {
    color: #757575 !important;
}

.shadow {
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
}

.srh_btn_banner {
    position: absolute;
    border-radius: 4px;
    font-size: 15px;
    height: 48px;
    color: #fff;
    background: #340f49;
    border: 0 none;
    right: 0;
    top: 0;
    text-align: center;
    padding: 9px 30px;
}

.task img {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.task p,
.task ul {
    margin-bottom: 10px;
}

.task-list {
    padding-right: 18px;
}

.task-list svg{
   max-width: 100% !important;
}

.task-list .CoverTitle{
    color: white;
}

.task-list .ListParagraph span {
    font-family: ITCFranklinGothicStd-Book !important;
}

.task-list div {
    width: auto !important;
}

.task-list h1 {
    color: #340f49 !important;
    font-weight: 600;
    font-size: 24px;
    margin: 20px 0 !important;
}

.task-list h1 span {
    display: none !important;
}

.task-list h2 {
    color: #340f49 !important;
    font-weight: 700;
    font-size: 22px;
    margin: 20px 0 !important;
}

.task-list h2 span,
.task-list h3 span,
.task-list h4 span,
.task-list h5 span {
    color: #340f49 !important;
    font-weight: 700;
    font-size: inherit !important;
}
.chartDetailstext{
    background-color:#5bcaf447;
}
.task-list h3 {
    color: #340f49 !important;
    font-weight: 700;
    font-size: 20px;
    margin: 20px 0 !important;
}
.task-list .bookmark{
    position: absolute;
    right: 0;
}
.task-list h4 {
    color: #340f49 !important;
    font-weight: 700;
    font-size: 18px;
    margin: 20px 0 !important;
}

.task-list h5 {
    color: #340f49 !important;
    font-weight: 700;
    font-size: 15px;
    margin: 20px 0 !important;
}

.task-list ol li,
.task * {
    text-indent: 0 !important;
    font-family: 'Open Sans', sans-serif !important;
}

.task-list table {
    float: none !important;
    width: 100% !important;
    margin: 10px 10px 10px 0 !important;
}

.task-list ul li {
    list-style: disc;
}

.task-list span, .task-list p{
    font-family: 'Open Sans', sans-serif;
}

.top_btn_back_bar {
    width: 65px;
    height: 35px;
}

.vh-35 {
    height: 35vh;
}

a.hr_link {
    color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
}

a.hr_link:hover {
    color: #340f49;
}

button.nav-btn {
    text-align: left;
}

input.form-control.bg-transparent {
    border-radius: 5px !important;
}

label.form-check-label {
    font-size: 12px;
}

mark {
    background: #FF0;
    color: #000;
}

span.input-suffix {
    border: none !important;
    pointer-events: all !important;
}

td {
    font-size: 11px;
    word-wrap: break-word;
    padding: 2px !important;
}

th {
    font-size: 13px;
}

ul.pagination {
    margin-bottom: 30px;
}

.select-all-checkbox:checked {
    background-color: black;
    border-color: black;
}
.bg-lightblue{
    background: lightblue !important;
}
.ind-list .rct-title {
    font-size: 13px !important;
    color: #423547 !important;
}
.ind-list .react-checkbox-tree label:hover {
    background: none !important;
}
.ind-list .rct-text{
    align-items: baseline !important;
}
.ind-list .rct-text:hover {
    background: #EED4FF !important;
    width: fit-content !important;
    border-radius: 4px !important;
}
.ind-list .rct-node-clickable .rct-node-icon{   
    padding: 0px;
}
.ind-list .rct-node-leaf .rct-icon{
    display:none;
}

.ind-list .rct-node-clickable:hover {
    background: none !important;
}

.ind-list .rct-collapse {
    padding: 0px !important;
}

.ind-list button.rct-collapse.rct-collapse-btn {
    padding-right: 0px !important;
}

.ind-list .react-checkbox-tree{
    margin-left: 10px;
}

.ind-list .accordion .accordion-button:not(.collapsed)::after{
    color: #351249;
    font-size: 2rem;
    top: 7px;
}
.ind-list .accordion .accordion-button::after{
    color: #351249;
    font-size: 2rem;
    top: 7px;
}

.ind-list .accordion .accordion-button:not(.collapsed){
    border-bottom: none;
}

.ind-list .accordion-item {
    border: 1px solid #ECF0F2;
}
.ind-list .accordion-collapse  {
    position: absolute;
    width: 100%;
    background: white;
    z-index: 99;
    border: 1px solid #ecf0f2;
    border-top:none !important;
    left: 0;
    box-shadow: 0px 20px 24px 0px rgba(21, 21, 22, 0.04);
}

.remove:hover svg {
    fill: #fff !important;
  }
@media (min-width:1200px) {

    .hk-wrapper .hk-navbar .navbar-search .input-search input.form-control,
    .hk-wrapper .hk-navbar .navbar-search .input-search input.form-select,
    .hk-wrapper .hk-navbar .navbar-search .input-search .wizard>.content>.body input,
    .wizard>.content>.body .hk-wrapper .hk-navbar .navbar-search .input-search input,
    .hk-wrapper .hk-navbar .navbar-search .input-search input.dd-handle {
        width: 45rem;
    }
}

@media (min-width:1201px) {
    .header-search-box .header-s-input {
        width: 545px;
        padding-left: 35px;
    }
}




.custom-tooltip {
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    min-width: 150px;
}



.custom-tooltip span {
    display: inline-block;
    margin-right: 5px;
    padding: 10px;
}
.ms-01rem{
    margin-left: 0.1rem !important;
}

.ms-37rem{
    margin-left: 3.7rem !important;
}
.mnw-1080p{
    min-width: 1083px !important;

}
.read_border{
    box-shadow: 0 1px 3px 0 rgba(0,0,0,.08), 0 5px 26px 0 rgba(67,94,131,.15);
    background: white;
}

.heatmap table {
    border-collapse: collapse;

    float: none !important;
    width: 100% !important;
    margin: 10px 10px 10px 0 !important;
    font-size: 16px;
}
.heatmap thead {
    background-color: #2b6c7e !important;
    color: #fff;
}

.heatmap table, .heatmap td,.heatmap th {
    /* border: 1px solid #2b6c7e; */
    border: 1px solid #2B6CB9;

    padding: 10px;

  }
 
.accordion .accordion-button {
    padding: 0.75rem 0.8rem !important;
}

.rct-node li > ol{
    margin-left: -10px !important;
}
body .react-checkbox-tree ol ol {
    padding-left: 18px !important;
}
.react-checkbox-tree ol ol {
    margin-left: -5px !important;
}
.chart_header{
    padding-top: 1.5rem !important;
    padding-left: 1.75rem !important;
    padding-right: 1.6rem !important;
}
.table_header{
    padding-top: 0.5rem !important;
    padding-left: 0.5rem !important;
    padding-right: 0.2rem !important;
}
.statistic_chart_header{
    padding-top: 0.25rem !important;
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
}
.statistic_table_header{
    padding-top: 0.25rem !important;
    padding-left: 0.5rem !important;
    padding-right: 0.0rem !important;
    padding-bottom: 0.25rem !important;
}
.border-bottom-1{
   border-bottom: 1px solid #575a5d;
}
.top-21{
    top:21%
}
.top-18{
    top:18%
}
.create-account{
    text-align: right;
    display: flex;
    justify-content: space-between;
}
.full-btn{
    width: -webkit-fill-available;
}
.background-blue{
    background-color: rgba(41, 141, 255, var(--bs-text-opacity)) !important;
}

/* Media Query for Mobile Devices */
@media (max-width: 765px) {
     .mobile{
        display: block ;
     }
     .desktop , .tablet{
        display: none !important;
     }
     .feather-icon{
        font-size: 18px;
     }

     .hk-wrapper .hk-navbar .nav-start-wrap, .hk-wrapper .hk-navbar .nav-end-wrap {
        display: flex;
        align-items: center;
        width: 100vw;
        justify-content: space-around;
        margin-left: -1rem;
    }
    .hk-wrapper[data-layout="vertical"][data-layout-style="default"] .hk-navbar {
        left: 0;
        width: 100vw !important;
    }
    .hk-wrapper form {
        
        width: 85vw;
        padding: 2px;
}
.col-mb-12{
    flex: 0 0 auto;
    width: 100%;
}
.px-mb-2{
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
}
.footer{
    margin-bottom: -2rem;
}
.pagination{
    margin-left: -0.6rem;
}

    }

 
/* Media Query for low resolution  Tablets, Ipads */
/* @media (min-width: 481px) and (max-width: 767px) {
    .mobile{
        display: block;
     }
     .desktop , .tablet{
        display: none;
     }
} */
 
/* Media Query for Tablets Ipads portrait mode
@media (min-width: 768px) and (max-width: 1024px){
    body {
        background-color: blue;
    }
} */
 
/* Media Query for Laptops and Desktops */
@media (min-width: 768px){
    .mobile{
        display: none !important;
     }
     .desktop , .tablet{
        display: block;
     }
}
 
/* Media Query for Large screens
@media (min-width: 1281px) {
    body {
        background-color: white;
    }
} */

/* Invite Tag */
.email-tags-div {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    min-height: 34px;
    padding: .375rem .75rem;
    border: 1px solid rgb(186, 172, 194);
    border-radius: 5px;
    
    
  }

.email-tags {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
  } 
  
  .email-tag-li {
    width: auto;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    padding: 0 8px;
    font-size: 14px;
    list-style: none;
    border-radius: 6px;
    margin: 4px 8px 4px 0;
    background: #e3e8f0;
  }


  .email-tag-li .email-tag-close-icon {
    display: block;
    width: 16px;
    height: 17px;
    line-height: 16px;
    text-align: center;
    font-size: 14px;
    margin-left: 8px;
    border-radius: 50%;
    background: #fff;
    cursor: pointer;
  }
  
.email-tags-div input {
    flex: 1;
    border: none;
    height: 35px;
    font-size: 14px;
    background-color:transparent!important;
    
  }

.email-tags-div input:focus-within , 
.email-tags-div input:focus-visible 
{
    outline: 0 !important;
  }

/* // Invite Tag */



.search-dropdown {
    position: relative;
    color: #333;
    cursor: default;
  }
  
  /* .search-dropdown .arrow {
    border-color: #999 transparent transparent;
    border-style: solid;
    border-width: 5px 5px 0;
    content: " ";
    display: block;
    height: 0;
    margin-top: 0.3rem;
    position: absolute;
    right: 10px;
    top: 14px;
    width: 0;
  } */
  
  /* .search-dropdown .arrow.open {
    border-color: transparent transparent #999;
    border-width: 0 5px 5px;
  } */
  
  .search-dropdown .selected-value input {
    /* line-height: 1.5;
    font-size: 1rem;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 2px;
    box-sizing: border-box;
    cursor: default;
    outline: none;
    padding: 8px 52px 8px 10px;
    transition: all 200ms ease;
    width: 100%; */
  }
  
  .search-dropdown .options {
    display: none;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
    box-sizing: border-box;
    margin-top: -1px;
    max-height: 200px;
    overflow-y: auto;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 1000;
    -webkit-overflow-scrolling: touch;
  }
  
  .search-dropdown .options.open {
    display: block;
  }
  
  .search-dropdown .option {
    box-sizing: border-box;
    color: rgba(51, 51, 51, 0.8);
    cursor: pointer;
    display: block;
    padding: 8px 10px;
  }
  
  .search-dropdown .option.selected,
  .search-dropdown .option:hover {
    background-color: #f2f9fc;
    color: #333;
  }

  input.otp_input {
    height: 55px;
    width: 15% !important;
}
  
.icn_clear{
    position: absolute;
    top: 15px;
    right: 15px;
}

h1.decor-alpha, h2.decor-alpha, h3.decor-alpha, h4.decor-alpha {
    position: relative;
    padding-left: 1rem;
}

h1.decor-alpha::before, h2.decor-alpha::before, h3.decor-alpha::before, h4.decor-alpha::before {
    content: ' ';
    position: absolute;
    left: 0;
    display: block;
    width: 6px;
    height: 100%;
    background-color: #36c3ff;
}
.chart-title{
    font-size: 1.2em;
    font-weight: bold;
}