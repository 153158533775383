/* Modal or Popup custom css */
.btn-close-corner {position: absolute;right: 0;top: 0;background: white;border-radius: 23px;line-height: 0;font-size: 16px;opacity: 1;box-shadow:-1px 1px 6px #00000042;}
.btn-close-corner:hover {opacity:1;}
.modal_demo_header {border-top:4px solid #5ccbf5;}
.modal_heading_small {display:block;font-size:14px;}
.btn-close-corner-normal {
    position: absolute;
    right: 8px;
    top: 8px;
    line-height: 0;
    font-size: 13px;
    opacity: 1;
}
.btn-close-middle {
right: 50% !important;
top: -11px !important;
}


