
/*------------------------------------------------------------------
[Header]
Project:	Jampack
-------------------------------------------------------------------*/
/*Top Nav*/
.hk-wrapper .hk-navbar {
    border-bottom: 1px solid #eaeaea;
    height: 65px;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
}

.hk-wrapper .hk-navbar .navbar-nav .nav-item .dropdown .dropdown-menu,
.hk-wrapper .hk-navbar .navbar-nav .nav-item .dropdown .apexcharts-toolbar .apexcharts-menu,
.apexcharts-toolbar .hk-wrapper .hk-navbar .navbar-nav .nav-item .dropdown .apexcharts-menu {
    position: absolute;
}

.hk-wrapper .hk-navbar .navbar-nav .nav-item .dropdown.dropdown-notifications .dropdown-menu,
.hk-wrapper .hk-navbar .navbar-nav .nav-item .dropdown.dropdown-notifications .apexcharts-toolbar .apexcharts-menu,
.apexcharts-toolbar .hk-wrapper .hk-navbar .navbar-nav .nav-item .dropdown.dropdown-notifications .apexcharts-menu {
    width: 21.875rem;
}

.hk-wrapper .hk-navbar .navbar-nav .nav-item .dropdown.dropdown-notifications .dropdown-menu .dropdown-header,
.hk-wrapper .hk-navbar .navbar-nav .nav-item .dropdown.dropdown-notifications .apexcharts-toolbar .apexcharts-menu .dropdown-header,
.apexcharts-toolbar .hk-wrapper .hk-navbar .navbar-nav .nav-item .dropdown.dropdown-notifications .apexcharts-menu .dropdown-header {
    color: #262a2e;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
    border-bottom: 1px solid #f7f7f7;
}

.hk-wrapper .hk-navbar .navbar-nav .nav-item .dropdown.dropdown-notifications .dropdown-menu .dropdown-body,
.hk-wrapper .hk-navbar .navbar-nav .nav-item .dropdown.dropdown-notifications .apexcharts-toolbar .apexcharts-menu .dropdown-body,
.apexcharts-toolbar .hk-wrapper .hk-navbar .navbar-nav .nav-item .dropdown.dropdown-notifications .apexcharts-menu .dropdown-body {
    height: 23.75rem;
}

.hk-wrapper .hk-navbar .navbar-nav .nav-item .dropdown.dropdown-notifications .dropdown-menu .dropdown-footer,
.hk-wrapper .hk-navbar .navbar-nav .nav-item .dropdown.dropdown-notifications .apexcharts-toolbar .apexcharts-menu .dropdown-footer,
.apexcharts-toolbar .hk-wrapper .hk-navbar .navbar-nav .nav-item .dropdown.dropdown-notifications .apexcharts-menu .dropdown-footer {
    border-top: 1px solid #f7f7f7;
    padding: 1rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    color: #09769F;
    font-size: 0.875rem;
}

.hk-wrapper .hk-navbar .navbar-nav .nav-item .dropdown.dropdown-notifications .dropdown-menu .dropdown-item,
.hk-wrapper .hk-navbar .navbar-nav .nav-item .dropdown.dropdown-notifications .apexcharts-toolbar .apexcharts-menu .dropdown-item,
.apexcharts-toolbar .hk-wrapper .hk-navbar .navbar-nav .nav-item .dropdown.dropdown-notifications .apexcharts-menu .dropdown-item {
    white-space: inherit;
    padding-top: .75rem;
    padding-bottom: .75rem;
    border-radius: .5rem;
}

.hk-wrapper .hk-navbar .navbar-nav .nav-item .dropdown.dropdown-notifications .dropdown-menu .dropdown-item .media .media-head,
.hk-wrapper .hk-navbar .navbar-nav .nav-item .dropdown.dropdown-notifications .apexcharts-toolbar .apexcharts-menu .dropdown-item .media .media-head,
.apexcharts-toolbar .hk-wrapper .hk-navbar .navbar-nav .nav-item .dropdown.dropdown-notifications .apexcharts-menu .dropdown-item .media .media-head {
    margin-right: 1rem;
}

.hk-wrapper .hk-navbar .navbar-nav .nav-item .dropdown.dropdown-notifications .dropdown-menu .dropdown-item .media .media-body>div .notifications-info,
.hk-wrapper .hk-navbar .navbar-nav .nav-item .dropdown.dropdown-notifications .apexcharts-toolbar .apexcharts-menu .dropdown-item .media .media-body>div .notifications-info,
.apexcharts-toolbar .hk-wrapper .hk-navbar .navbar-nav .nav-item .dropdown.dropdown-notifications .apexcharts-menu .dropdown-item .media .media-body>div .notifications-info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: .25rem;
}

.hk-wrapper .hk-navbar .navbar-nav .nav-item .dropdown.dropdown-notifications .dropdown-menu .dropdown-item .media .media-body>div .notifications-info>.badge,
.hk-wrapper .hk-navbar .navbar-nav .nav-item .dropdown.dropdown-notifications .apexcharts-toolbar .apexcharts-menu .dropdown-item .media .media-body>div .notifications-info>.badge,
.apexcharts-toolbar .hk-wrapper .hk-navbar .navbar-nav .nav-item .dropdown.dropdown-notifications .apexcharts-menu .dropdown-item .media .media-body>div .notifications-info>.badge {
    margin-right: .625rem;
}

.hk-wrapper .hk-navbar .navbar-nav .nav-item .dropdown.dropdown-notifications .dropdown-menu .dropdown-item .media .media-body>div .notifications-info .notifications-time,
.hk-wrapper .hk-navbar .navbar-nav .nav-item .dropdown.dropdown-notifications .apexcharts-toolbar .apexcharts-menu .dropdown-item .media .media-body>div .notifications-info .notifications-time,
.apexcharts-toolbar .hk-wrapper .hk-navbar .navbar-nav .nav-item .dropdown.dropdown-notifications .apexcharts-menu .dropdown-item .media .media-body>div .notifications-info .notifications-time {
    font-size: 0.75rem;
    color: #6f6f6f;
}

.hk-wrapper .hk-navbar .navbar-search {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.hk-wrapper .hk-navbar .navbar-search .input-search input.form-control>span.btn-input-clear,
.hk-wrapper .hk-navbar .navbar-search .input-search input.form-select>span.btn-input-clear,
.hk-wrapper .hk-navbar .navbar-search .input-search .wizard>.content>.body input>span.btn-input-clear,
.wizard>.content>.body .hk-wrapper .hk-navbar .navbar-search .input-search input>span.btn-input-clear,
.hk-wrapper .hk-navbar .navbar-search .input-search input.dd-handle>span.btn-input-clear {
    display: none;
}

.hk-wrapper .hk-navbar .navbar-search .input-search input.form-control+.input-suffix,
.hk-wrapper .hk-navbar .navbar-search .input-search input.form-select+.input-suffix,
.hk-wrapper .hk-navbar .navbar-search .input-search .wizard>.content>.body input+.input-suffix,
.wizard>.content>.body .hk-wrapper .hk-navbar .navbar-search .input-search input+.input-suffix,
.hk-wrapper .hk-navbar .navbar-search .input-search input.dd-handle+.input-suffix,
.hk-wrapper .hk-navbar .navbar-search .input-search input.form-control+.input-prefix,
.hk-wrapper .hk-navbar .navbar-search .input-search input.form-select+.input-prefix,
.hk-wrapper .hk-navbar .navbar-search .input-search .wizard>.content>.body input+.input-prefix,
.wizard>.content>.body .hk-wrapper .hk-navbar .navbar-search .input-search input+.input-prefix,
.hk-wrapper .hk-navbar .navbar-search .input-search input.dd-handle+.input-prefix {
    pointer-events: none;
}

.hk-wrapper .hk-navbar .navbar-search .input-search input.form-control+.input-suffix>span.spinner-border,
.hk-wrapper .hk-navbar .navbar-search .input-search input.form-select+.input-suffix>span.spinner-border,
.hk-wrapper .hk-navbar .navbar-search .input-search .wizard>.content>.body input+.input-suffix>span.spinner-border,
.wizard>.content>.body .hk-wrapper .hk-navbar .navbar-search .input-search input+.input-suffix>span.spinner-border,
.hk-wrapper .hk-navbar .navbar-search .input-search input.dd-handle+.input-suffix>span.spinner-border,
.hk-wrapper .hk-navbar .navbar-search .input-search input.form-control+.input-prefix>span.spinner-border,
.hk-wrapper .hk-navbar .navbar-search .input-search input.form-select+.input-prefix>span.spinner-border,
.hk-wrapper .hk-navbar .navbar-search .input-search .wizard>.content>.body input+.input-prefix>span.spinner-border,
.wizard>.content>.body .hk-wrapper .hk-navbar .navbar-search .input-search input+.input-prefix>span.spinner-border,
.hk-wrapper .hk-navbar .navbar-search .input-search input.dd-handle+.input-prefix>span.spinner-border {
    display: none;
}

.hk-wrapper .hk-navbar .navbar-search .input-search input.form-control:focus+.input-suffix,
.hk-wrapper .hk-navbar .navbar-search .input-search input.form-select:focus+.input-suffix,
.hk-wrapper .hk-navbar .navbar-search .input-search .wizard>.content>.body input:focus+.input-suffix,
.wizard>.content>.body .hk-wrapper .hk-navbar .navbar-search .input-search input:focus+.input-suffix,
.hk-wrapper .hk-navbar .navbar-search .input-search input.dd-handle:focus+.input-suffix,
.hk-wrapper .hk-navbar .navbar-search .input-search input.form-control:focus+.input-prefix,
.hk-wrapper .hk-navbar .navbar-search .input-search input.form-select:focus+.input-prefix,
.hk-wrapper .hk-navbar .navbar-search .input-search .wizard>.content>.body input:focus+.input-prefix,
.wizard>.content>.body .hk-wrapper .hk-navbar .navbar-search .input-search input:focus+.input-prefix,
.hk-wrapper .hk-navbar .navbar-search .input-search input.dd-handle:focus+.input-prefix {
    border: none;
    pointer-events: auto;
}

.hk-wrapper .hk-navbar .navbar-search .input-search input.form-control:focus+.input-suffix>span:not(.btn-input-clear):not(.spinner-border),
.hk-wrapper .hk-navbar .navbar-search .input-search input.form-select:focus+.input-suffix>span:not(.btn-input-clear):not(.spinner-border),
.hk-wrapper .hk-navbar .navbar-search .input-search .wizard>.content>.body input:focus+.input-suffix>span:not(.btn-input-clear):not(.spinner-border),
.wizard>.content>.body .hk-wrapper .hk-navbar .navbar-search .input-search input:focus+.input-suffix>span:not(.btn-input-clear):not(.spinner-border),
.hk-wrapper .hk-navbar .navbar-search .input-search input.dd-handle:focus+.input-suffix>span:not(.btn-input-clear):not(.spinner-border),
.hk-wrapper .hk-navbar .navbar-search .input-search input.form-control:focus+.input-prefix>span:not(.btn-input-clear):not(.spinner-border),
.hk-wrapper .hk-navbar .navbar-search .input-search input.form-select:focus+.input-prefix>span:not(.btn-input-clear):not(.spinner-border),
.hk-wrapper .hk-navbar .navbar-search .input-search .wizard>.content>.body input:focus+.input-prefix>span:not(.btn-input-clear):not(.spinner-border),
.wizard>.content>.body .hk-wrapper .hk-navbar .navbar-search .input-search input:focus+.input-prefix>span:not(.btn-input-clear):not(.spinner-border),
.hk-wrapper .hk-navbar .navbar-search .input-search input.dd-handle:focus+.input-prefix>span:not(.btn-input-clear):not(.spinner-border) {
    display: none;
}

.hk-wrapper .hk-navbar .navbar-search .input-search.loading input.form-control:focus+.input-suffix>span.btn-input-clear,
.hk-wrapper .hk-navbar .navbar-search .input-search.loading input.form-select:focus+.input-suffix>span.btn-input-clear,
.hk-wrapper .hk-navbar .navbar-search .input-search.loading .wizard>.content>.body input:focus+.input-suffix>span.btn-input-clear,
.wizard>.content>.body .hk-wrapper .hk-navbar .navbar-search .input-search.loading input:focus+.input-suffix>span.btn-input-clear,
.hk-wrapper .hk-navbar .navbar-search .input-search.loading input.dd-handle:focus+.input-suffix>span.btn-input-clear,
.hk-wrapper .hk-navbar .navbar-search .input-search.loading input.form-control:focus+.input-prefix>span.btn-input-clear,
.hk-wrapper .hk-navbar .navbar-search .input-search.loading input.form-select:focus+.input-prefix>span.btn-input-clear,
.hk-wrapper .hk-navbar .navbar-search .input-search.loading .wizard>.content>.body input:focus+.input-prefix>span.btn-input-clear,
.wizard>.content>.body .hk-wrapper .hk-navbar .navbar-search .input-search.loading input:focus+.input-prefix>span.btn-input-clear,
.hk-wrapper .hk-navbar .navbar-search .input-search.loading input.dd-handle:focus+.input-prefix>span.btn-input-clear {
    display: none !important;
}

.hk-wrapper .hk-navbar .navbar-search .input-search.loading input.form-control:focus+.input-suffix>span.spinner-border,
.hk-wrapper .hk-navbar .navbar-search .input-search.loading input.form-select:focus+.input-suffix>span.spinner-border,
.hk-wrapper .hk-navbar .navbar-search .input-search.loading .wizard>.content>.body input:focus+.input-suffix>span.spinner-border,
.wizard>.content>.body .hk-wrapper .hk-navbar .navbar-search .input-search.loading input:focus+.input-suffix>span.spinner-border,
.hk-wrapper .hk-navbar .navbar-search .input-search.loading input.dd-handle:focus+.input-suffix>span.spinner-border,
.hk-wrapper .hk-navbar .navbar-search .input-search.loading input.form-control:focus+.input-prefix>span.spinner-border,
.hk-wrapper .hk-navbar .navbar-search .input-search.loading input.form-select:focus+.input-prefix>span.spinner-border,
.hk-wrapper .hk-navbar .navbar-search .input-search.loading .wizard>.content>.body input:focus+.input-prefix>span.spinner-border,
.wizard>.content>.body .hk-wrapper .hk-navbar .navbar-search .input-search.loading input:focus+.input-prefix>span.spinner-border,
.hk-wrapper .hk-navbar .navbar-search .input-search.loading input.dd-handle:focus+.input-prefix>span.spinner-border {
    display: inline;
}

.hk-wrapper .hk-navbar .navbar-search .dropdown-menu,
.hk-wrapper .hk-navbar .navbar-search .apexcharts-toolbar .apexcharts-menu,
.apexcharts-toolbar .hk-wrapper .hk-navbar .navbar-search .apexcharts-menu {
    width: 18rem;
}

.hk-wrapper .hk-navbar .navbar-search .dropdown-menu .dropdown-body,
.hk-wrapper .hk-navbar .navbar-search .apexcharts-toolbar .apexcharts-menu .dropdown-body,
.apexcharts-toolbar .hk-wrapper .hk-navbar .navbar-search .apexcharts-menu .dropdown-body {
    max-height: 19.75rem;
}

.hk-wrapper .hk-navbar .navbar-search .dropdown-menu .dropdown-footer,
.hk-wrapper .hk-navbar .navbar-search .apexcharts-toolbar .apexcharts-menu .dropdown-footer,
.apexcharts-toolbar .hk-wrapper .hk-navbar .navbar-search .apexcharts-menu .dropdown-footer {
    border-top: 1px solid #f7f7f7;
    padding: 1rem;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    color: #09769F;
    font-size: 0.875rem;
}

.hk-wrapper .hk-navbar .navbar-search .dropdown-menu .dropdown-item,
.hk-wrapper .hk-navbar .navbar-search .apexcharts-toolbar .apexcharts-menu .dropdown-item,
.apexcharts-toolbar .hk-wrapper .hk-navbar .navbar-search .apexcharts-menu .dropdown-item {
    white-space: inherit;
}

.hk-wrapper .hk-navbar .nav-start-wrap,
.hk-wrapper .hk-navbar .nav-end-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.hk-wrapper .hk-navbar .nav-start-wrap>.navbar-nav,
.hk-wrapper .hk-navbar .nav-end-wrap>.navbar-nav {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.hk-wrapper .hk-navbar .navbar-brand .brand-img:last-child {
    margin-left: 0.5rem;
}

.hk-wrapper[data-navbar-style="collapsed"] .hk-navbar {
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -o-transform: translateY(-100%);
    transform: translateY(-100%);
    overflow: hidden;
}

.hk-wrapper[data-navbar-style="collapsed"] .hk-pg-wrapper {
    padding-top: 0;
}

@media (min-width: 992px) {
    .hk-wrapper .hk-navbar {
        -webkit-transition: 0.2s ease;
        -moz-transition: 0.2s ease;
        transition: 0.2s ease;
    }
}

@media (min-width: 1200px) {

    .hk-wrapper .hk-navbar .navbar-search .input-search input.form-control,
    .hk-wrapper .hk-navbar .navbar-search .input-search input.form-select,
    .hk-wrapper .hk-navbar .navbar-search .input-search .wizard>.content>.body input,
    .wizard>.content>.body .hk-wrapper .hk-navbar .navbar-search .input-search input,
    .hk-wrapper .hk-navbar .navbar-search .input-search input.dd-handle {
        width: 18rem;
    }

    .hk-wrapper .hk-navbar .navbar-search .input-search input.form-control:focus+.input-suffix>span.btn-input-clear,
    .hk-wrapper .hk-navbar .navbar-search .input-search input.form-select:focus+.input-suffix>span.btn-input-clear,
    .hk-wrapper .hk-navbar .navbar-search .input-search .wizard>.content>.body input:focus+.input-suffix>span.btn-input-clear,
    .wizard>.content>.body .hk-wrapper .hk-navbar .navbar-search .input-search input:focus+.input-suffix>span.btn-input-clear,
    .hk-wrapper .hk-navbar .navbar-search .input-search input.dd-handle:focus+.input-suffix>span.btn-input-clear,
    .hk-wrapper .hk-navbar .navbar-search .input-search input.form-control:focus+.input-prefix>span.btn-input-clear,
    .hk-wrapper .hk-navbar .navbar-search .input-search input.form-select:focus+.input-prefix>span.btn-input-clear,
    .hk-wrapper .hk-navbar .navbar-search .input-search .wizard>.content>.body input:focus+.input-prefix>span.btn-input-clear,
    .wizard>.content>.body .hk-wrapper .hk-navbar .navbar-search .input-search input:focus+.input-prefix>span.btn-input-clear,
    .hk-wrapper .hk-navbar .navbar-search .input-search input.dd-handle:focus+.input-prefix>span.btn-input-clear {
        display: inline !important;
    }

    .hk-wrapper .hk-navbar .nav-start-wrap,
    .hk-wrapper .hk-navbar .nav-end-wrap {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }

    .hk-wrapper .hk-navbar .nav-start-wrap>.navbar-nav,
    .hk-wrapper .hk-navbar .nav-end-wrap>.navbar-nav {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .hk-wrapper[data-navbar-style="collapsed"][data-layout="horizontal"] .hk-pg-wrapper {
        padding-top: 65px;
    }

    .hk-wrapper[data-navbar-style="collapsed"][data-layout="horizontal"][data-layout-style="default"] .hk-menu {
        top: 0;
    }
}

@media (max-width: 1199px) {

    .hk-wrapper .hk-navbar .navbar-search .mobile-search .input-search .input-suffix,
    .hk-wrapper .hk-navbar .navbar-search .mobile-search .input-search .input-prefix {
        pointer-events: auto !important;
    }

    .hk-wrapper .hk-navbar .navbar-search .mobile-search .input-search .input-suffix>span.btn-input-clear,
    .hk-wrapper .hk-navbar .navbar-search .mobile-search .input-search .input-prefix>span.btn-input-clear {
        display: inline !important;
    }

    .hk-wrapper .hk-navbar .navbar-search .dropdown-menu,
    .hk-wrapper .hk-navbar .navbar-search .apexcharts-toolbar .apexcharts-menu,
    .apexcharts-toolbar .hk-wrapper .hk-navbar .navbar-search .apexcharts-menu {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .hk-wrapper .hk-navbar .navbar-search .dropdown-menu .dropdown-body,
    .hk-wrapper .hk-navbar .navbar-search .apexcharts-toolbar .apexcharts-menu .dropdown-body,
    .apexcharts-toolbar .hk-wrapper .hk-navbar .navbar-search .apexcharts-menu .dropdown-body {
        max-height: calc(100% - 55px);
    }
}
