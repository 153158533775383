* {
    list-style: none;
    margin: 0;
    padding: 0;
    scrollbar-width: thin;  
    scrollbar-color: #999;
}
*::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}
*::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

*::-webkit-scrollbar-thumb {
    background-color: #999;
}

.as_info_btn {
    display: inline-block;
    text-align: center;
    border-radius: 6px;
    background: #5ccbf5;
    color: #fff;
    border: none;
    cursor: pointer;
    padding: 8px 20px;
}

.as_info_btn:hover,
.as_info_btn:focus {
    background: #340f49;
    color: #fff;
}

.as_primary_btn {
    display: inline-block;
    text-align: center;
    border-radius: 6px;
    background: #340f49;
    color: #fff;
    border: none;
    cursor: pointer;
    padding: 8px 20px;
}

.as_primary_btn:hover,
.as_primary_btn:focus {
    background: #5ccbf5;
    color: #fff;
}

.as_primary_btn2 {
    display: inline-block;
    text-align: center;
    border-radius: 6px;
    background: #340f49;
    color: #fff;
    border: none;
    cursor: pointer;
    padding: 8px 20px;
    border: 2px solid #340f49;
    
}

.as_primary_btn2:hover,
.as_primary_btn2:focus {
    border: 2px solid #340f49;
    background-color: #5ccbf5;
    color: #340f49;
    font-weight: 500;
}

.badge.badge-soft-secondary {
    background: #f3f3f3;
    color: #6f6f6f;
    border-radius: 50rem;
    transition: all .15s ease;
    padding: .5rem .625rem;
}

.badge:hover {
    box-shadow: 0 .1875rem .5rem rgba(47, 52, 58, 0.3) !important;
    transform: translateY(-3px);
}

.bg_Bondi_Blue {
    background: #5ccbf5 !important;
}

.bg_light {
    background: #f5fafc;
}
.bg_light_2 {
    background: #f3f5f6;
}

.callout {
    border-left: .33rem solid #007bff;
}

.callout-danger {
    border-left-color: #dc3545;
}

.callout-info {
    border-left-color: #17a2b8;
}

.callout-secoundary {
    border-left-color: #6c757d;
}

.callout-warning {
    border-left-color: #ffc107;
}

.card_bg_light {
    background: #63C7FF;
    color: #fff;
    border: 0 none;
}

.card_bg_light2 {
    background: #A66DD4;
    color: #fff;
    border: 0 none;
}

.card_bg_light3 {
    background: #6DD4B1;
    color: #fff;
    border: 0 none;
}

.card_bg_light4 {
    background: #3B76EF;
    color: #fff;
    border: 0 none;
}

.font-s-10 {
    font-size: 10px !important;
}

.font-s-11 {
    font-size: 11px !important;
}

.font-s-12 {
    font-size: 12px !important;
}

.font-s-13 {
    font-size: 13px !important;
}

.font-s-14 {
    font-size: 14px !important;
}

.font-s-15 {
    font-size: 15px !important;
}

.font-s-16 {
    font-size: 16px !important;
}

.font-s-17 {
    font-size: 17px !important;
}

.font-s-18 {
    font-size: 18px !important;
}

.font-s-19 {
    font-size: 19px !important;
}

.font-s-20 {
    font-size: 20px !important;
}

.font-s-22 {
    font-size: 22px !important;
}

.font-s-24 {
    font-size: 24px !important;
}

.font-s-8 {
    font-size: 8px !important;
}

.font-s-9 {
    font-size: 9px !important;
}

.font-weight-600 {
    font-weight: 600;
}

.form-control:focus,
button:focus,
.btn:focus,
.page-link:focus {
    box-shadow: none;
    outline: none;
}

.hover_underline:hover {
    text-decoration: underline !important;
    text-underline-offset: 3px;
}
.hover_info:hover {
    color:#41b6e3 !important;
}
.hover_zoom {
    transition:all .3s;
}
.hover_zoom:hover {
    box-shadow: 0 20px 25px -5px rgba(0,0,0,.1),0 10px 10px -5px rgba(0,0,0,.04);
    transform: scale(1.02);
}


.m-6 {
    margin: 4rem !important;
}

.m-7 {
    margin: 5rem !important;
}

.mb-6 {
    margin-bottom: 4rem !important;
}

.mb-7 {
    margin-bottom: 5rem !important;
}

.ml-4 {
    margin-left: 2rem !important;
}

.ml-6 {
    margin-left: 4rem !important;
}

.ml-7 {
    margin-left: 5rem !important;
}

.mr-6 {
    margin-right: 4rem !important;
}

.mr-7 {
    margin-right: 5rem !important;
}

.mt-20 {
    margin-top: 20px !important;
}

.mt-6 {
    margin-top: 4rem !important;
}

.mt-7 {
    margin-top: 5rem !important;
}

.mt_minus_1 {
    margin-top: -.25rem;
}

.mt_minus_2 {
    margin-top: -5rem;
}

.mt_minus_3 {
    margin-top: -1rem;
}

.mt_minus_4 {
    margin-top: -1.5rem;
}

.mx-6 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
}

.mx-7 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
}

.my-6 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
}

.my-7 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
}

.p-6 {
    padding: 4rem !important;
}

.p-7 {
    padding: 5rem !important;
}

.pb-6 {
    padding-bottom: 4rem !important;
}

.pb-7 {
    padding-bottom: 5rem !important;
}

.pl-6 {
    padding-left: 4rem !important;
}

.pl-7 {
    padding-left: 5rem !important;
}

.pr-6 {
    padding-right: 4rem !important;
}

.pr-7 {
    padding-right: 5rem !important;
}

.pt-6 {
    padding-top: 4rem !important;
}

.pt-7 {
    padding-top: 5rem !important;
}

.px-6 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
}

.px-7 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
}

.py-6 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
}

.py-7 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
}

.text-grey {
    color: #9e9e9e;
}

.text_Blue {
    color: #00f;
}

.text_Bondi_Blue {
    color: #5bcaf4 !important;
}

.text_blue {
    color: #41b6e3;
}

.text_demi {
    font-weight: 600;
}

.text_purple {
    color: #340f49 !important;
}
.text_purple2 {
    color: #423547 !important;
}
.text_purple3 {
    color: #756280 !important;
    ;
}
.text_purple4 {
    color: #351249 !important;
    ;
}
.bg_purple {
    background: #340f49 !important;
}
.bg_purple2 {
    background-color: #EED4FF !important;
}


.btn-primary-horizon {
    border : 1px solid #351249 !important;
    background-color: #351249 !important;
    color: #fff !important;
}

.btn-primary-horizon:hover{
    border : 1px solid #351249 !important;
    color: #351249 !important;
    background-color : transparent !important;
}

.text_underline {
    text-decoration: underline;
}

.z-index {
    z-index: 9;
}

a {
    text-decoration: none;
}

a:hover,
a:focus {
    text-decoration: none;
    color: #340f49;
    outline: none;
}

body, :root, html {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    color: #423547;
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    font-weight: normal;
    line-height: 1.4;
}
img {
    max-width: 100%;
    height: auto;
}

ul {
    margin: 0;
    padding: 0;
}
.btn-outline-1  {
    color: #09769F;
    background-color: #fff;
    background-image: none;
    border-color: #09769F;
}
.btn-outline-1:hover, .btn-outline-1:focus {
    color: #fff;
    background-color: #09769F;
    border-color: #09769F;
}
.btn-outline-2  {
    color: #350f49;
    background-color: #fff;
    background-image: none;
    border-color: #ECF0F2;
    box-shadow: 0px 8px 24px 0px rgba(21, 21, 22, 0.04); 
}
.btn-outline-2:hover, .btn-outline-2:focus {
    color: #423547;
    background-color: #F5FAFC;
    border-color: #423547;
}
.form-select, .form-control {
    color: #423547 !important;
}
.bg_gradient_1 {
    background: linear-gradient(327deg, #A751D9 -81.71%, #EED4FF 52.94%);
}
.shadow_1 {box-shadow: 0px 8px 24px 0px rgba(21, 21, 22, 0.04);}

@media (min-width:576px) {
    .m-sm-6 {
        margin: 4rem !important;
    }

    .m-sm-7 {
        margin: 5rem !important;
    }

    .mb-sm-6 {
        margin-bottom: 4rem !important;
    }

    .mb-sm-7 {
        margin-bottom: 5rem !important;
    }

    .ml-sm-6 {
        margin-left: 4rem !important;
    }

    .ml-sm-7 {
        margin-left: 5rem !important;
    }

    .mr-sm-6 {
        margin-right: 4rem !important;
    }

    .mr-sm-7 {
        margin-right: 5rem !important;
    }

    .mt-sm-6 {
        margin-top: 4rem !important;
    }

    .mt-sm-7 {
        margin-top: 5rem !important;
    }

    .mx-sm-6 {
        margin-right: 4rem !important;
        margin-left: 4rem !important;
    }

    .mx-sm-7 {
        margin-right: 5rem !important;
        margin-left: 5rem !important;
    }

    .my-sm-6 {
        margin-top: 4rem !important;
        margin-bottom: 4rem !important;
    }

    .my-sm-7 {
        margin-top: 5rem !important;
        margin-bottom: 5rem !important;
    }

    .p-sm-6 {
        padding: 4rem !important;
    }

    .p-sm-7 {
        padding: 5rem !important;
    }

    .pb-sm-6 {
        padding-bottom: 4rem !important;
    }

    .pb-sm-7 {
        padding-bottom: 5rem !important;
    }

    .pl-sm-6 {
        padding-left: 4rem !important;
    }

    .pl-sm-7 {
        padding-left: 5rem !important;
    }

    .pr-sm-6 {
        padding-right: 4rem !important;
    }

    .pr-sm-7 {
        padding-right: 5rem !important;
    }

    .pt-sm-6 {
        padding-top: 4rem !important;
    }

    .pt-sm-7 {
        padding-top: 5rem !important;
    }

    .px-sm-6 {
        padding-right: 4rem !important;
        padding-left: 4rem !important;
    }

    .px-sm-7 {
        padding-right: 5rem !important;
        padding-left: 5rem !important;
    }

    .py-sm-6 {
        padding-top: 4rem !important;
        padding-bottom: 4rem !important;
    }

    .py-sm-7 {
        padding-top: 5rem !important;
        padding-bottom: 5rem !important;
    }
}

@media (min-width:768px) {
    .m-md-6 {
        margin: 4rem !important;
    }

    .m-md-7 {
        margin: 5rem !important;
    }

    .mb-md-6 {
        margin-bottom: 4rem !important;
    }

    .mb-md-7 {
        margin-bottom: 5rem !important;
    }

    .ml-md-6 {
        margin-left: 4rem !important;
    }

    .ml-md-7 {
        margin-left: 5rem !important;
    }

    .mr-md-6 {
        margin-right: 4rem !important;
    }

    .mr-md-7 {
        margin-right: 5rem !important;
    }

    .mt-md-6 {
        margin-top: 4rem !important;
    }

    .mt-md-7 {
        margin-top: 5rem !important;
    }

    .mx-md-6 {
        margin-right: 4rem !important;
        margin-left: 4rem !important;
    }

    .mx-md-7 {
        margin-right: 5rem !important;
        margin-left: 5rem !important;
    }

    .my-md-6 {
        margin-top: 4rem !important;
        margin-bottom: 4rem !important;
    }

    .my-md-7 {
        margin-top: 5rem !important;
        margin-bottom: 5rem !important;
    }

    .p-md-6 {
        padding: 4rem !important;
    }

    .p-md-7 {
        padding: 5rem !important;
    }

    .pb-md-6 {
        padding-bottom: 4rem !important;
    }

    .pb-md-7 {
        padding-bottom: 5rem !important;
    }

    .pl-md-6 {
        padding-left: 4rem !important;
    }

    .pl-md-7 {
        padding-left: 5rem !important;
    }

    .pr-md-6 {
        padding-right: 4rem !important;
    }

    .pr-md-7 {
        padding-right: 5rem !important;
    }

    .pt-md-6 {
        padding-top: 4rem !important;
    }

    .pt-md-7 {
        padding-top: 5rem !important;
    }

    .px-md-6 {
        padding-right: 4rem !important;
        padding-left: 4rem !important;
    }

    .px-md-7 {
        padding-right: 5rem !important;
        padding-left: 5rem !important;
    }

    .py-md-6 {
        padding-top: 4rem !important;
        padding-bottom: 4rem !important;
    }

    .py-md-7 {
        padding-top: 5rem !important;
        padding-bottom: 5rem !important;
    }
}

@media (min-width:992px) {
    .m-lg-6 {
        margin: 4rem !important;
    }

    .m-lg-7 {
        margin: 5rem !important;
    }

    .mb-lg-6 {
        margin-bottom: 4rem !important;
    }

    .mb-lg-7 {
        margin-bottom: 5rem !important;
    }

    .ml-lg-6 {
        margin-left: 4rem !important;
    }

    .ml-lg-7 {
        margin-left: 5rem !important;
    }

    .mr-lg-6 {
        margin-right: 4rem !important;
    }

    .mr-lg-7 {
        margin-right: 5rem !important;
    }

    .mt-lg-6 {
        margin-top: 4rem !important;
    }

    .mt-lg-7 {
        margin-top: 5rem !important;
    }

    .mx-lg-6 {
        margin-right: 4rem !important;
        margin-left: 4rem !important;
    }

    .mx-lg-7 {
        margin-right: 5rem !important;
        margin-left: 5rem !important;
    }

    .my-lg-6 {
        margin-top: 4rem !important;
        margin-bottom: 4rem !important;
    }

    .my-lg-7 {
        margin-top: 5rem !important;
        margin-bottom: 5rem !important;
    }

    .p-lg-6 {
        padding: 4rem !important;
    }

    .p-lg-7 {
        padding: 5rem !important;
    }

    .pb-lg-6 {
        padding-bottom: 4rem !important;
    }

    .pb-lg-7 {
        padding-bottom: 5rem !important;
    }

    .pl-lg-6 {
        padding-left: 4rem !important;
    }

    .pl-lg-7 {
        padding-left: 5rem !important;
    }

    .pr-lg-6 {
        padding-right: 4rem !important;
    }

    .pr-lg-7 {
        padding-right: 5rem !important;
    }

    .pt-lg-6 {
        padding-top: 4rem !important;
    }

    .pt-lg-7 {
        padding-top: 5rem !important;
    }

    .px-lg-6 {
        padding-right: 4rem !important;
        padding-left: 4rem !important;
    }

    .px-lg-7 {
        padding-right: 5rem !important;
        padding-left: 5rem !important;
    }

    .py-lg-6 {
        padding-top: 4rem !important;
        padding-bottom: 4rem !important;
    }

    .py-lg-7 {
        padding-top: 5rem !important;
        padding-bottom: 5rem !important;
    }
}

@media (min-width:1200px) {
    .m-xl-6 {
        margin: 4rem !important;
    }

    .m-xl-7 {
        margin: 5rem !important;
    }

    .mb-xl-6 {
        margin-bottom: 4rem !important;
    }

    .mb-xl-7 {
        margin-bottom: 5rem !important;
    }

    .ml-xl-6 {
        margin-left: 4rem !important;
    }

    .ml-xl-7 {
        margin-left: 5rem !important;
    }

    .mr-xl-6 {
        margin-right: 4rem !important;
    }

    .mr-xl-7 {
        margin-right: 5rem !important;
    }

    .mt-xl-6 {
        margin-top: 4rem !important;
    }

    .mt-xl-7 {
        margin-top: 5rem !important;
    }

    .mx-xl-6 {
        margin-right: 4rem !important;
        margin-left: 4rem !important;
    }

    .mx-xl-7 {
        margin-right: 5rem !important;
        margin-left: 5rem !important;
    }

    .my-xl-6 {
        margin-top: 4rem !important;
        margin-bottom: 4rem !important;
    }

    .my-xl-7 {
        margin-top: 5rem !important;
        margin-bottom: 5rem !important;
    }

    .p-xl-6 {
        padding: 4rem !important;
    }

    .p-xl-7 {
        padding: 5rem !important;
    }

    .pb-xl-6 {
        padding-bottom: 4rem !important;
    }

    .pb-xl-7 {
        padding-bottom: 5rem !important;
    }

    .pl-xl-6 {
        padding-left: 4rem !important;
    }

    .pl-xl-7 {
        padding-left: 5rem !important;
    }

    .pr-xl-6 {
        padding-right: 4rem !important;
    }

    .pr-xl-7 {
        padding-right: 5rem !important;
    }

    .pt-xl-6 {
        padding-top: 4rem !important;
    }

    .pt-xl-7 {
        padding-top: 5rem !important;
    }

    .px-xl-6 {
        padding-right: 4rem !important;
        padding-left: 4rem !important;
    }

    .px-xl-7 {
        padding-right: 5rem !important;
        padding-left: 5rem !important;
    }

    .py-xl-6 {
        padding-top: 4rem !important;
        padding-bottom: 4rem !important;
    }

    .py-xl-7 {
        padding-top: 5rem !important;
        padding-bottom: 5rem !important;
    }
}

@media screen and (max-width:767px) {
    .h1-responsive {
        font-size: 1.7rem !important;
        line-height: 1.2;
    }

    .h2-responsive {
        font-size: 1.5rem !important;
        line-height: 1.3;
    }

    .h3-responsive {
        font-size: 1.3rem !important;
        line-height: 1.4;
    }

    .h4-responsive {
        font-size: 1.1rem !important;
        line-height: 1.4;
    }

    .h5-responsive {
        font-size: 1rem !important;
        line-height: 1.4;
    }

    .h6-responsive {
        font-size: .875rem !important;
        line-height: 1.4;
    }
}