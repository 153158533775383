
/*------------------------------------------------------------------
[Nav]
Project:	Jampack
-------------------------------------------------------------------*/
/*Navbar*/
.navbar .navbar-toggler-menu,
.navbar .navbar-toggler {
    border: none;
    padding: 0;
}

.navbar .navbar-toggler-menu {
    align-self: center;
    margin-right: .625rem;
}

.navbar .navbar-brand {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
}

.navbar.navbar-dark {
    background: #262a2e;
}

.navbar.navbar-dark .badge.badge-indicator {
    border-color: #262a2e;
}

.navbar.navbar-dark .form-control,
.navbar.navbar-dark .form-select,
.navbar.navbar-dark .wizard>.content>.body input,
.wizard>.content>.body .navbar.navbar-dark input,
.navbar.navbar-dark .dd-handle {
    border-color: #fff;
    color: #fff;
}

.navbar.navbar-dark .input-affix-wrapper.affix-border .input-prefix,
.navbar.navbar-dark .input-affix-wrapper.affix-border .input-suffix {
    border-color: #fff;
    color: #fff;
}

.navbar.navbar-dark .btn.btn-icon[class*="btn-flush-"].btn-flush-dark,
.navbar.navbar-dark div.hopscotch-bubble .hopscotch-bubble-container .hopscotch-actions .btn-icon[class*="btn-flush-"].btn-flush-dark.hopscotch-nav-button,
div.hopscotch-bubble .hopscotch-bubble-container .hopscotch-actions .navbar.navbar-dark .btn-icon[class*="btn-flush-"].btn-flush-dark.hopscotch-nav-button {
    color: #fff;
}

.navbar.navbar-dark .btn.btn-icon[class*="btn-flush-"].btn-flush-dark.flush-soft-hover:before,
.navbar.navbar-dark div.hopscotch-bubble .hopscotch-bubble-container .hopscotch-actions .btn-icon[class*="btn-flush-"].btn-flush-dark.flush-soft-hover.hopscotch-nav-button:before,
div.hopscotch-bubble .hopscotch-bubble-container .hopscotch-actions .navbar.navbar-dark .btn-icon[class*="btn-flush-"].btn-flush-dark.flush-soft-hover.hopscotch-nav-button:before {
    background: rgba(255, 255, 255, 0.05);
}

.navbar.navbar-dark .btn.btn-icon[class*="btn-flush-"].btn-flush-dark.flush-soft-hover:not(:disabled):not(.disabled).active .icon,
.navbar.navbar-dark div.hopscotch-bubble .hopscotch-bubble-container .hopscotch-actions .btn-icon[class*="btn-flush-"].btn-flush-dark.flush-soft-hover.hopscotch-nav-button:not(:disabled):not(.disabled).active .icon,
div.hopscotch-bubble .hopscotch-bubble-container .hopscotch-actions .navbar.navbar-dark .btn-icon[class*="btn-flush-"].btn-flush-dark.flush-soft-hover.hopscotch-nav-button:not(:disabled):not(.disabled).active .icon,
.navbar.navbar-dark .btn.btn-icon[class*="btn-flush-"].btn-flush-dark.flush-soft-hover:not(:disabled):not(.disabled):active .icon,
.navbar.navbar-dark div.hopscotch-bubble .hopscotch-bubble-container .hopscotch-actions .btn-icon[class*="btn-flush-"].btn-flush-dark.flush-soft-hover.hopscotch-nav-button:not(:disabled):not(.disabled):active .icon,
div.hopscotch-bubble .hopscotch-bubble-container .hopscotch-actions .navbar.navbar-dark .btn-icon[class*="btn-flush-"].btn-flush-dark.flush-soft-hover.hopscotch-nav-button:not(:disabled):not(.disabled):active .icon,
.navbar.navbar-dark .btn.btn-icon[class*="btn-flush-"].btn-flush-dark.flush-soft-hover:hover .icon,
.navbar.navbar-dark div.hopscotch-bubble .hopscotch-bubble-container .hopscotch-actions .btn-icon[class*="btn-flush-"].btn-flush-dark.flush-soft-hover.hopscotch-nav-button:hover .icon,
div.hopscotch-bubble .hopscotch-bubble-container .hopscotch-actions .navbar.navbar-dark .btn-icon[class*="btn-flush-"].btn-flush-dark.flush-soft-hover.hopscotch-nav-button:hover .icon,
.navbar.navbar-dark .btn.btn-icon[class*="btn-flush-"].btn-flush-dark.flush-soft-hover:focus .icon,
.navbar.navbar-dark div.hopscotch-bubble .hopscotch-bubble-container .hopscotch-actions .btn-icon[class*="btn-flush-"].btn-flush-dark.flush-soft-hover.hopscotch-nav-button:focus .icon,
div.hopscotch-bubble .hopscotch-bubble-container .hopscotch-actions .navbar.navbar-dark .btn-icon[class*="btn-flush-"].btn-flush-dark.flush-soft-hover.hopscotch-nav-button:focus .icon {
    color: #fff;
}

.navbar.navbar-dark .btn.btn-icon[class*="btn-flush-"].btn-flush-dark.disabled,
.navbar.navbar-dark div.hopscotch-bubble .hopscotch-bubble-container .hopscotch-actions .btn-icon[class*="btn-flush-"].btn-flush-dark.disabled.hopscotch-nav-button,
div.hopscotch-bubble .hopscotch-bubble-container .hopscotch-actions .navbar.navbar-dark .btn-icon[class*="btn-flush-"].btn-flush-dark.disabled.hopscotch-nav-button,
.navbar.navbar-dark .btn.btn-icon[class*="btn-flush-"].btn-flush-dark:disabled,
.navbar.navbar-dark div.hopscotch-bubble .hopscotch-bubble-container .hopscotch-actions .btn-icon[class*="btn-flush-"].btn-flush-dark.hopscotch-nav-button:disabled,
div.hopscotch-bubble .hopscotch-bubble-container .hopscotch-actions .navbar.navbar-dark .btn-icon[class*="btn-flush-"].btn-flush-dark.hopscotch-nav-button:disabled {
    color: rgba(255, 255, 255, 0.8);
}

.navbar.navbar-light {
    background: #fff;
}

.navbar.navbar-light .badge.badge-indicator {
    border-color: #fff;
}

/*Nav*/
.nav-link {
    color: #09769F;
}

.nav-link:hover,
.nav-link:focus {
    color: #3d9ca5;
}

.nav .nav-item {
    position: relative;
}

.nav .nav-item .nav-link.no-arrow {
    padding-right: 0.75rem !important;
}

.nav .nav-item .nav-link.no-arrow:after {
    display: none;
}

.nav .nav-item .nav-link.link-with-arrow {
    padding-right: 1.8rem !important;
    position: relative;
}

.nav .nav-item .nav-link.link-with-arrow:after {
    content: '\f2f9';
    font: normal normal normal 14px/1 'Material-Design-Iconic-Font';
    font-size: 1rem;
    text-rendering: auto;
    margin-left: auto;
    position: absolute;
    right: 1rem;
    top: calc(50% - 8px);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;
}

.nav.nav-icon>.nav-item .nav-link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}

.nav.nav-icon>.nav-item .nav-link>.nav-icon-wrap {
    margin-right: .875rem;
    font-size: 1.25rem;
    line-height: 0;
    position: relative;
}

.nav.nav-icon.nav-icon-top>.nav-item>.nav-link {
    text-align: center;
    display: block;
}

.nav.nav-icon.nav-icon-top>.nav-item>.nav-link>.nav-icon-wrap {
    margin-right: 0;
    margin-bottom: .5rem;
    display: block;
}

.nav.nav-pills>.nav-item>.nav-link {
    border-radius: 0.5rem;
    margin-left: .25rem;
    margin-right: .25rem;
}

.nav.nav-pills>.nav-item>.nav-link.active {
    background-color: #09769F !important;
}

.nav.nav-pills>.nav-item>.nav-link.active.link-with-arrow:after {
    color: rgba(255, 255, 255, 0.6);
}

.nav.nav-pills>.nav-item>.nav-link.active:after {
    color: rgba(255, 255, 255, 0.6);
}

.nav.nav-pills .show>.nav-link {
    background-color: transparent;
}

.nav.nav-pills.nav-pills-square>.nav-item>.nav-link {
    border-radius: 0;
}

.nav.nav-pills.nav-pills-rounded>.nav-item>.nav-link {
    border-radius: 50rem;
}

.nav.nav-pills.nav-pills-soft>.nav-item>.nav-link.active {
    background-color: #EBFAFF !important;
    color: #09769F !important;
}

.nav.nav-pills.nav-pills-soft>.nav-item>.nav-link.active.link-with-arrow:after {
    color: inherit;
}

.nav.nav-pills.nav-pills-soft>.nav-item>.nav-link.active:after {
    color: inherit;
}

.nav.nav-pills.nav-icon.nav-icon-top>.nav-item>.nav-link {
    padding-top: .95rem;
    padding-bottom: .95rem;
}

.nav.nav-line>.nav-item>.nav-link {
    border-bottom: 1px solid transparent;
    -webkit-transition: 0.2s ease-in-out;
    -moz-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
}

.nav.nav-line>.nav-item>.nav-link.active {
    border-color: #09769F !important;
}

.nav.nav-vertical {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
}

.nav.nav-vertical .nav-item .nav-link {
    justify-content: flex-start;
    -webkit-justify-content: flex-start;
    position: relative;
}

.nav.nav-vertical .nav-item .nav-link[data-toggle="collapse"]::after {
    content: '\f2f9';
    font: normal normal normal 14px/1 'Material-Design-Iconic-Font';
    font-size: 1rem;
    text-rendering: auto;
    margin-left: auto;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;
}

.nav.nav-vertical .nav-item .nav-link[data-toggle="collapse"][aria-expanded="true"]::after {
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg);
}

.nav.nav-vertical.nav-pills>.nav-item>.nav-link {
    margin: .25rem 0;
}

.nav.nav-vertical.nav-line>.nav-item>.nav-link {
    border-bottom: none;
    border-left: 1px solid transparent;
}

.nav.nav-vertical.nav-separator>.nav-item>.nav-link {
    padding: .75rem 1.5rem;
}

.nav.nav-vertical.nav-separator>.nav-item::after {
    content: "";
    position: absolute;
    width: 100%;
    left: 0;
    top: auto;
    bottom: 0;
    height: 1px;
    margin: 0;
}

.nav.nav-dark .nav-link {
    color: white;
}

.nav.nav-dark .nav-link:hover,
.nav.nav-dark .nav-link:focus {
    color: rgba(255, 255, 255, 0.8);
}

.nav.nav-dark .nav-link.disabled {
    color: rgba(255, 255, 255, 0.3);
}

.nav.nav-dark .nav-link.active {
    color: #09769F;
}

.nav.nav-dark .nav-link:after {
    color: rgba(255, 255, 255, 0.3);
}

.nav.nav-dark .nav-link.link-with-arrow:after {
    color: rgba(255, 255, 255, 0.3);
}

.nav.nav-dark .nav-item.show .nav-link {
    color: #fff;
}

.nav.nav-dark.nav-pills>.nav-item>.nav-link.active {
    color: #fff;
}

.nav.nav-dark.nav-pills>.nav-item>.nav-link.active:hover,
.nav.nav-dark.nav-pills>.nav-item>.nav-link.active:focus {
    color: #fff;
}

.nav.nav-dark.nav-pills>.nav-item>.nav-link {
    color: #fff;
}

.nav.nav-dark.nav-pills>.nav-item>.nav-link:hover,
.nav.nav-dark.nav-pills>.nav-item>.nav-link:focus {
    background: rgba(255, 255, 255, 0.1);
}

.nav.nav-dark.nav-pills>.nav-item>.nav-link.disabled {
    color: rgba(255, 255, 255, 0.3);
}

.nav.nav-dark.nav-vertical .nav-item .nav-link[data-toggle="collapse"]::after {
    color: rgba(255, 255, 255, 0.3);
}

.nav.nav-dark.nav-line .nav-item.show .nav-link {
    color: #fff;
}

.nav.nav-light .nav-link {
    color: #262a2e;
}

.nav.nav-light .nav-link:hover,
.nav.nav-light .nav-link:focus {
    color: rgba(38, 42, 46, 0.8);
}

.nav.nav-light .nav-link.disabled {
    color: rgba(38, 42, 46, 0.3);
}

.nav.nav-light .nav-link.active {
    color: #09769F;
}

.nav.nav-light .nav-link:after {
    color: rgba(38, 42, 46, 0.3);
}

.nav.nav-light .nav-link.link-with-arrow:after {
    color: rgba(38, 42, 46, 0.3);
}

.nav.nav-light .nav-item.show .nav-link {
    color: #262a2e;
}

.nav.nav-light.nav-vertical .nav-item .nav-link[data-toggle="collapse"]::after {
    color: rgba(38, 42, 46, 0.3);
}

.nav.nav-light.nav-pills>.nav-item>.nav-link.active {
    color: #fff;
}

.nav.nav-light.nav-pills>.nav-item>.nav-link.active:hover,
.nav.nav-light.nav-pills>.nav-item>.nav-link.active:focus {
    color: #fff;
}

.nav.nav-light.nav-pills>.nav-item>.nav-link {
    color: #262a2e;
}

.nav.nav-light.nav-pills>.nav-item>.nav-link:hover,
.nav.nav-light.nav-pills>.nav-item>.nav-link:focus {
    background: rgba(38, 42, 46, 0.08);
}

.nav.nav-light.nav-pills>.nav-item>.nav-link.disabled {
    color: rgba(38, 42, 46, 0.3);
}

.nav.nav-sm>.nav-item .nav-link>.nav-icon-wrap {
    margin-right: .875rem;
    font-size: 1rem;
}

.nav.nav-sm .nav-link {
    padding: .25rem 0.75rem;
    font-size: 0.875rem;
}

.nav-tabs {
    border-bottom: 1px solid #d8d8d8;
}

.nav-tabs .nav-item.show .nav-link:hover,
.nav-tabs .nav-item.show .nav-link:focus,
.nav-tabs>.nav-item>.nav-link:hover,
.nav-tabs>.nav-item>.nav-link:focus {
    border-color: transparent;
}

.nav-tabs .nav-item.show .nav-link.active,
.nav-tabs>.nav-item>.nav-link.active {
    background-color: transparent;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) #fff;
}

.nav-tabs.nav-sm .nav-item.show .nav-link,
.nav-tabs.nav-sm>.nav-item>.nav-link {
    padding: .4rem 0.75rem;
}

.nav-tabs .nav-item.show .nav-link {
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) #fff;
}

.nav-tabs.nav-pills {
    border-bottom: 0;
}

.nav-tabs.nav-pills>.nav-item {
    margin-bottom: 0;
}

.nav-tabs.nav-pills>.nav-item>.nav-link {
    border: none !important;
}

.nav-tabs.nav-light>.nav-item>.nav-link.active {
    background: #fff;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) #fff;
}

.nav-tabs.nav-light .nav-item.show .nav-link {
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) #fff;
}

.nav-tabs.nav-dark {
    border-color: rgba(255, 255, 255, 0.1);
}

.nav-tabs.nav-dark>.nav-item>.nav-link.active {
    background: #fff;
    border-color: rgba(255, 255, 255, 0.1) rgba(255, 255, 255, 0.1) #fff;
}

.nav-tabs.nav-dark .nav-item.show .nav-link {
    border-color: rgba(255, 255, 255, 0.1) rgba(255, 255, 255, 0.1) #fff;
}

.nav-tabs.nav-line {
    border-bottom: 0;
}

.nav-tabs.nav-line>.nav-item {
    margin-bottom: 0;
}

.nav-tabs.nav-line>.nav-item>.nav-link {
    background: transparent;
    border-left: 0;
    border-top: 0;
    border-right: 0;
}

.nav-tabs.nav-line>.nav-item>.nav-link.active {
    background: transparent;
}

.tab-content {
    margin-top: 1rem;
}

.nav-segmented-tabs {
    display: -ms-flexbox;
    display: flex;
    border-bottom: none;
}

.nav-segmented-tabs>.nav-item {
    margin-right: -1px;
}

.nav-segmented-tabs>.nav-item:first-child .nav-link {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
}

.nav-segmented-tabs>.nav-item:last-child .nav-link {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
}

.nav-segmented-tabs>.nav-item>.nav-link {
    border: 1px solid #d8d8d8 !important;
    border-radius: 0;
    text-align: center;
    background: transparent;
    height: 100%;
}

.nav-segmented-tabs>.nav-item>.nav-link.active {
    color: #fff !important;
    background: #09769F !important;
    border-color: #09769F !important;
}

.nav-segmented-tabs.segmented-tabs-rounded>.nav-item:first-child .nav-link {
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
}

.nav-segmented-tabs.segmented-tabs-rounded>.nav-item:last-child .nav-link {
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
}

.nav-segmented-tabs.segmented-tabs-square>.nav-item>.nav-link {
    border-radius: 0;
}

.nav-segmented-tabs.segmented-tabs-filled>.nav-item {
    margin-right: 0;
}

.nav-segmented-tabs.segmented-tabs-filled>.nav-item .nav-link {
    border: none !important;
    background: #f7f7f7;
}

.nav-segmented-tabs.nav-dark>.nav-item>.nav-link {
    border-color: rgba(255, 255, 255, 0.15) !important;
}

.nav-segmented-tabs.nav-dark>.nav-item.show>.nav-link {
    background: transparent;
    color: white;
}

.nav-segmented-tabs.nav-dark.segmented-tabs-filled>.nav-item .nav-link {
    background: rgba(255, 255, 255, 0.05);
}

.nav-header-bold {
    padding: 1.5rem 0 .5rem 0;
    width: 240px;
    font-size: 0.75rem;
    text-transform: uppercase;
    font-weight: 600;
    color: #09769F;
}

/*Section Nav*/
.hk-wrapper[data-aside="fixed"] .hk-pg-wrapper {
    margin-right: 180px;
}

.hk-wrapper[data-aside="fixed"] .hk-aside {
    position: fixed;
    top: 65px;
    overflow-y: auto;
    width: 180px;
    right: 0;
    z-index: 1;
    background: #fff;
    border-left: 1px solid #d8d8d8;
    bottom: 0;
}

.hk-wrapper[data-aside="fixed"] .hk-aside .nicescroll-bar {
    padding: 1.5rem;
}

.hk-wrapper[data-aside="sticky"] .hk-aside {
    position: sticky;
    top: 70px;
    overflow-y: auto;
    height: calc(100vh - 250px);
    z-index: 1;
    margin-top: 3rem;
}

.hk-wrapper .nav-toc .nav-header {
    font-size: .75rem;
    text-transform: uppercase;
    font-weight: 600;
    color: #9e9e9e;
}

.hk-wrapper .nav-toc .nav {
    margin: 1rem 0;
    font-size: .875rem;
    flex-wrap: nowrap;
    border-left: 1px solid;
    border-color: #d8d8d8;
}

.hk-wrapper .nav-toc .nav .nav-item {
    margin-bottom: .25rem;
}

.hk-wrapper .nav-toc .nav .nav-item:last-child {
    margin-bottom: 0;
}

.hk-wrapper .nav-toc .nav .nav-item a.nav-link {
    color: #262a2e;
    padding: 0 0 0 1rem;
    display: block;
    margin-left: -1px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    border-left: 1px solid transparent;
}

.hk-wrapper .nav-toc .nav .nav-item a.nav-link:hover,
.hk-wrapper .nav-toc .nav .nav-item a.nav-link:focus {
    color: rgba(38, 42, 46, 0.8);
}

.hk-wrapper .nav-toc .nav .nav-item a.nav-link.active {
    color: #09769F;
    border-color: #09769F;
}

@media (max-width: 767px) {
    .hk-wrapper[data-aside="fixed"] .hk-pg-wrapper {
        margin-right: 0;
    }

    .hk-wrapper[data-aside="fixed"] .hk-aside {
        display: none;
    }
}

.toc-wrapper .nav.nav-vertical .nav-item .nav-link {
    font-size: 0.875rem;
    padding: 0.125rem 0;
}