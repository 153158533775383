

.padding_227{
	padding-left: 227px;
}

ul.timeline_1 {
    width: 397px;
    margin: 0 auto;
    position: relative;
    padding-bottom: 40px;
}

ul.timeline_1 li.timeline_item h5{
	font-size: 25px;
	font-weight: 700;
}

ul.timeline_1 li.timeline_item p{
	font-size: 18px;
	font-weight: 400;
}

ul.timeline_1:before{
	content: " ";
    position: absolute;
    width: 2px;
    height: 100%;
    left: 50%;
    background-color: #d7d7d7;
    top: 5px;
}

.chart_image_1{
    position: absolute;
    left: 170px;
    top: 5px;
}
.chart_image_2{
    position: absolute;
    left: 170px;
    top: 5px;
}
.chart_image_3{
    position: absolute;
    left: 170px;
    top: 5px;
}