/*Card*/
.card {
    border-color: #eaeaea;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
    margin-bottom: .875rem;
    border-radius: 0.5rem;
}

.card .card-header {
    background: transparent;
    border-color: #eaeaea;
    padding: 0.75rem 1.25rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
}

.card .card-header>h1,
.card .card-header>h2,
.card .card-header>h3,
.card .card-header>h4,
.card .card-header>h5,
.card .card-header>h6,
.card .card-header>.h1,
.card .card-header>.h2,
.card .card-header>.h3,
.card .card-header>.h4,
.card .card-header>.h5,
.card .card-header>.h6 {
    margin-bottom: 0;
}

.card .card-header.card-header-bold {
    font-size: 0.875rem;
    text-transform: uppercase;
    font-weight: 600;
}

.card .card-header.card-header-wth-bg {
    background: #f7f7f7;
    border-color: transparent;
}

.card .card-header.card-header-wth-bg.card-header-action .card-action-wrap {
    color: #fff;
}

.card .card-header.card-header-action .card-action-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.card .card-header.card-header-action .card-action-wrap>a.collapsed {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
}

.card .card-header.card-header-action.card-header-wth-text {
    align-items: flex-start;
    -webkit-box-align: flex-start;
    -ms-flex-align: flex-start;
    border-radius: calc(0.5rem - 1px) calc(0.5rem - 1px) 0 0;
}

.card .card-header.card-header-wth-bg-inv {
    color: #fff;
    background: #09769F;
    border-color: transparent;
}

.card .card-header.card-header-wth-bg-inv h1,
.card .card-header.card-header-wth-bg-inv h2,
.card .card-header.card-header-wth-bg-inv h3,
.card .card-header.card-header-wth-bg-inv h4,
.card .card-header.card-header-wth-bg-inv h5,
.card .card-header.card-header-wth-bg-inv h6,
.card .card-header.card-header-wth-bg-inv .h1,
.card .card-header.card-header-wth-bg-inv .h2,
.card .card-header.card-header-wth-bg-inv .h3,
.card .card-header.card-header-wth-bg-inv .h4,
.card .card-header.card-header-wth-bg-inv .h5,
.card .card-header.card-header-wth-bg-inv .h6 {
    color: #fff;
}

.card .card-header:first-child {
    border-radius: calc(0.5rem - 1px) calc(0.5rem - 1px) 0 0;
}

.card .card-header .nav-tabs.card-header-tabs {
    border-bottom: 0;
}

.card.sticky-card-header .card-header {
    top: 64px;
    position: -webkit-sticky;
    position: sticky;
    z-index: 100;
}

.card .card-text {
    margin-bottom: 1rem;
}

.card .card-text:last-child {
    margin-bottom: 0;
}

.card .card-title {
    font-size: 1rem;
    color: #262a2e;
    font-weight: 500;
}

.card .card-subtitle {
    font-size: 0.875rem;
}

.card .card-body {
    padding: 1.25rem;
    min-height: 1px;
}

.card .card-body>.card-action-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: absolute;
    right: 14px;
    top: 8px;
}

.card .card-body>.card-action-wrap .btn,
.card .card-body>.card-action-wrap div.hopscotch-bubble .hopscotch-bubble-container .hopscotch-actions .hopscotch-nav-button,
div.hopscotch-bubble .hopscotch-bubble-container .hopscotch-actions .card .card-body>.card-action-wrap .hopscotch-nav-button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-shrink: 0;
}

.card .card-body>.card-close {
    position: absolute;
    right: 14px;
    top: 10px;
    cursor: pointer;
}

.card .card-body>.card-close a {
    color: #262a2e;
    -webkit-transition: 0.2s ease;
    -moz-transition: 0.2s ease;
    transition: 0.2s ease;
    margin-left: 1rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    opacity: .6;
    font-size: 1.125rem;
}

.card .card-body>.card-close a:hover {
    opacity: 1;
}

.card.close-over .btn-close {
    position: absolute;
    top: -10px;
    right: -10px;
}

.card.card-selected {
    position: relative;
    border-color: #09769F;
}

.card.card-selected:after {
    height: 22px;
    width: 22px;
    background: #09769F;
    color: #fff;
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    cursor: pointer;
    font: normal normal normal 16px/1 'Material-Design-Iconic-Font';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\f26b";
    top: -10px;
    right: -10px;
    line-height: 0;
    position: absolute;
}

.card .card-footer {
    background: transparent;
    border-color: #eaeaea;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0.75rem 1.25rem;
}

.card .card-footer:last-child {
    border-radius: 0 0 calc(0.5rem - 1px) calc(0.5rem - 1px);
}

.card .card-img-bg {
    overflow: hidden;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.card.card-refresh {
    position: relative;
}

.card.card-refresh .refresh-container {
    background: rgba(255, 255, 255, 0.9);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    display: none;
    z-index: 5;
    cursor: wait;
    border-radius: 0.5rem;
    overflow: hidden;
}

.card.card-refresh .refresh-container .loader-pendulums {
    font-size: 2rem;
}

.card.fullscreen {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: 0;
    position: fixed;
    overflow-y: auto;
    z-index: 1040;
    border-radius: 0;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.card.card-lg .card-header,
.card.card-lg .card-footer {
    padding: 1rem 1.5rem;
}

.card.card-lg .card-body {
    padding: 1.5rem;
}

.card.card-sm .card-header,
.card.card-sm .card-footer {
    padding: .625rem 1rem;
}

.card.card-sm .card-body {
    padding: 1rem;
}

.card.card-shadow {
    border: none;
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.1);
}

.card.card-flush {
    border: none;
    box-shadow: none;
}

.card.card-border {
    border: 1px solid #ECF0F2;
    box-shadow: 0px 8px 24px 0px rgba(21, 21, 22, 0.04);
    border-left: 5px solid #e7dfed;
}

.card.card-simple .card-header,
.card.card-simple .card-footer {
    border: none;
}

.card.card-wth-line {
    padding-top: .25rem;
    border-top: 0;
}

.card.card-wth-line .card-line {
    position: absolute;
    top: 0;
    height: 30px;
    width: 100%;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
}

.card.card-wth-line .card-body {
    position: relative;
    z-index: 1;
    background: #fff;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
}

.card.card-wth-progress .progress {
    background: transparent;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
}

.card.card-wth-tabs>.card-header {
    padding-bottom: .5rem;
}

.card-img,
.card-img-top {
    border-top-left-radius: calc(0.5rem - 1px);
    border-top-right-radius: calc(0.5rem - 1px);
}

.card-img,
.card-img-bottom {
    border-bottom-right-radius: calc(0.5rem - 1px);
    border-bottom-left-radius: calc(0.5rem - 1px);
}

.card-group>.card {
    margin-bottom: .875rem;
}

.hk-wrapper.hk-menu-toggle .card.fullscreen {
    left: 0;
}

@media (max-width: 1199px) {
    .card.fullscreen {
        left: 0;
    }

    .hk-wrapper.hk-menu-toggle .card.fullscreen {
        left: 269px;
        right: -269px;
    }
}

@media (max-width: 576px) {

    .card.card-lg .card-header,
    .card.card-lg .card-footer {
        padding: .75rem 1.25rem;
    }

    .card.card-lg .card-body {
        padding: 1.25rem;
    }
}