
.country_select_full:after {
  clear: both;
  content: "";
  width: 100%;
  display: block;
}

.country_select {
  float: left;
  width: 90px;
}

#phoneSelector-req {
  border-left: 1px solid #c7c7c7;
}
#phoneSelector-req {
  border-radius: 6px 0px 0px 6px !important;
}

.country_select_full .input.text {
  float: left;
  width: calc(100% - 90px);
}
input#LeadPhoneNo {
  border-radius: 0 6px 6px 0 !important;
}

.contact_us .reqest-box input, .contact_us .reqest-box select, .contact_us .reqest-box textarea {
 
  margin-bottom: 0 !important;
  padding: 9px 10px !important;
  font-size: 13px;

}
.country_select_full .form_input {
  border-left: 0;
  height: 33px;
}

.seperator{
  border-right: 1px solid #c7c7c7 !important;
}
