
/*Dropdown*/
.dropdown-toggle.no-caret:after {
    display: none;
}

.dropdown-toggle:after {
    content: '\f2f9';
    font: normal normal normal 1rem/1 'Material-Design-Iconic-Font';
    border: none !important;
    vertical-align: 0 !important;
    margin-left: .4em;
}

.dropdown-toggle.dropdown-toggle-split:after,
.dropdown-toggle.dropdown-toggle-split:before {
    margin: 0 !important;
}

.dropup .dropdown-toggle::after {
    content: '\f2fc';
    margin-left: .4em;
}

.dropstart .dropdown-toggle::before {
    content: '\f2fa';
    vertical-align: 0 !important;
    font: normal normal normal 1rem/1 'Material-Design-Iconic-Font';
    border: none !important;
    margin-right: .4em;
}

.dropstart .dropdown-toggle::after {
    display: none;
}

.dropend .dropdown-toggle::after {
    content: '\f2fb';
    vertical-align: 0;
    margin-left: .4em;
}

.btn.btn-lg.dropdown-toggle:after,
div.hopscotch-bubble .hopscotch-bubble-container .hopscotch-actions .btn-lg.dropdown-toggle.hopscotch-nav-button:after,
.btn.btn-lg.dropdown-toggle:before,
div.hopscotch-bubble .hopscotch-bubble-container .hopscotch-actions .btn-lg.dropdown-toggle.hopscotch-nav-button:before,
.btn.btn-sm.dropdown-toggle:after,
div.hopscotch-bubble .hopscotch-bubble-container .hopscotch-actions .dropdown-toggle.hopscotch-nav-button:after,
.btn.btn-sm.dropdown-toggle:before,
div.hopscotch-bubble .hopscotch-bubble-container .hopscotch-actions .dropdown-toggle.hopscotch-nav-button:before,
.btn.btn-xs.dropdown-toggle:after,
div.hopscotch-bubble .hopscotch-bubble-container .hopscotch-actions .btn-xs.dropdown-toggle.hopscotch-nav-button:after,
.btn.btn-xs.dropdown-toggle:before,
div.hopscotch-bubble .hopscotch-bubble-container .hopscotch-actions .btn-xs.dropdown-toggle.hopscotch-nav-button:before {
    font-size: inherit;
}

.dropdown-menu,
.apexcharts-toolbar .apexcharts-menu {
    color: #6f6f6f;
    border: none;
    border-radius: 0.5rem;
    padding: .5rem;
    box-shadow: 0px 6px 16px rgba(47, 52, 58, 0.1);
}

.dropdown-menu .dropdown-item-text,
.apexcharts-toolbar .apexcharts-menu .dropdown-item-text {
    color: #6f6f6f;
    padding: .5rem .75rem;
}

.dropdown-menu .dropdown-header,
.apexcharts-toolbar .apexcharts-menu .dropdown-header {
    color: #9e9e9e;
    padding: .5rem .75rem;
    font-weight: 500;
}

.dropdown-menu .dropdown-header.dropdown-header-bold,
.apexcharts-toolbar .apexcharts-menu .dropdown-header.dropdown-header-bold {
    font-size: 0.75rem;
    text-transform: uppercase;
    font-weight: 600;
}

.dropdown-menu .dropdown-header.header-wth-bg,
.apexcharts-toolbar .apexcharts-menu .dropdown-header.header-wth-bg {
    background: #262a2e;
    color: #fff;
    margin-top: -.5rem;
    margin-left: -.5rem;
    margin-right: -.5rem;
    margin-bottom: .5rem;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    padding-top: .8rem;
    padding-bottom: .8rem;
}

.dropdown-menu.dropdown-bordered,
.apexcharts-toolbar .dropdown-bordered.apexcharts-menu {
    border: 1px solid #d8d8d8;
    box-shadow: none;
}

.dropdown-menu.dropdown-menu-icon-text .dropdown-item .dropdown-item-text,
.apexcharts-toolbar .dropdown-menu-icon-text.apexcharts-menu .dropdown-item .dropdown-item-text {
    padding: 0;
}

.dropdown-menu.dropdown-menu-avatar .dropdown-item,
.apexcharts-toolbar .dropdown-menu-avatar.apexcharts-menu .dropdown-item {
    padding-top: .3rem;
    padding-bottom: .3rem;
}

.dropdown-menu.dropdown-menu-avatar .dropdown-item .dropdown-item-text,
.apexcharts-toolbar .dropdown-menu-avatar.apexcharts-menu .dropdown-item .dropdown-item-text {
    padding: 0;
}

.dropdown-menu.dropdown-menu-avatar .dropdown-item .avatar,
.apexcharts-toolbar .dropdown-menu-avatar.apexcharts-menu .dropdown-item .avatar {
    margin-right: 1rem;
}

.dropdown-menu .dropdown-item,
.apexcharts-toolbar .apexcharts-menu .dropdown-item {
    color: #262a2e;
    cursor: pointer;
    border-radius: .25rem;
    padding: .25rem .75rem;
}

.dropdown-menu .dropdown-item .dropdown-icon,
.apexcharts-toolbar .apexcharts-menu .dropdown-item .dropdown-icon {
    font-size: 1.125rem;
    margin-right: .625rem;
    width: 21px;
    position: relative;
    top: 2px;
}

.dropdown-menu .dropdown-item .dropdown-icon.feather-icon,
.apexcharts-toolbar .apexcharts-menu .dropdown-item .dropdown-icon.feather-icon,
.dropdown-menu .dropdown-item .dropdown-icon .svg-icon,
.apexcharts-toolbar .apexcharts-menu .dropdown-item .dropdown-icon .svg-icon {
    top: -2px;
}

.dropdown-menu .dropdown-item.active,
.apexcharts-toolbar .apexcharts-menu .dropdown-item.active,
.dropdown-menu .dropdown-item:active,
.apexcharts-toolbar .apexcharts-menu .dropdown-item:active {
    background-color: #09769F;
    color: #fff;
}

.dropdown-menu .dropdown-item:focus:not(.active):not(.disabled),
.apexcharts-toolbar .apexcharts-menu .dropdown-item:focus:not(.active):not(.disabled),
.dropdown-menu .dropdown-item:hover:not(.active):not(.disabled),
.apexcharts-toolbar .apexcharts-menu .dropdown-item:hover:not(.active):not(.disabled) {
    background-color: #f7f7f7;
    color: #262a2e;
}

.dropdown-menu .dropdown-item:disabled,
.apexcharts-toolbar .apexcharts-menu .dropdown-item:disabled,
.dropdown-menu .dropdown-item.disabled,
.apexcharts-toolbar .apexcharts-menu .dropdown-item.disabled {
    color: #b5b5b5;
    background-color: transparent;
}

.dropdown-menu .sub-dropdown-menu,
.apexcharts-toolbar .apexcharts-menu .sub-dropdown-menu {
    position: relative;
}

.dropdown-menu .sub-dropdown-menu .dropdown-menu,
.apexcharts-toolbar .apexcharts-menu .sub-dropdown-menu .dropdown-menu,
.dropdown-menu .sub-dropdown-menu .apexcharts-toolbar .apexcharts-menu,
.apexcharts-toolbar .dropdown-menu .sub-dropdown-menu .apexcharts-menu,
.apexcharts-toolbar .apexcharts-menu .sub-dropdown-menu .apexcharts-menu {
    top: 0;
}

.dropdown-menu .sub-dropdown-menu .dropdown-menu:before,
.apexcharts-toolbar .apexcharts-menu .sub-dropdown-menu .dropdown-menu:before,
.dropdown-menu .sub-dropdown-menu .apexcharts-toolbar .apexcharts-menu:before,
.apexcharts-toolbar .dropdown-menu .sub-dropdown-menu .apexcharts-menu:before,
.apexcharts-toolbar .apexcharts-menu .sub-dropdown-menu .apexcharts-menu:before,
.dropdown-menu .sub-dropdown-menu .dropdown-menu:after,
.apexcharts-toolbar .apexcharts-menu .sub-dropdown-menu .dropdown-menu:after,
.dropdown-menu .sub-dropdown-menu .apexcharts-toolbar .apexcharts-menu:after,
.apexcharts-toolbar .dropdown-menu .sub-dropdown-menu .apexcharts-menu:after,
.apexcharts-toolbar .apexcharts-menu .sub-dropdown-menu .apexcharts-menu:after {
    display: none !important;
}

.dropdown-menu .sub-dropdown-menu .dropdown-menu.open-left-side,
.apexcharts-toolbar .apexcharts-menu .sub-dropdown-menu .dropdown-menu.open-left-side,
.dropdown-menu .sub-dropdown-menu .apexcharts-toolbar .open-left-side.apexcharts-menu,
.apexcharts-toolbar .dropdown-menu .sub-dropdown-menu .open-left-side.apexcharts-menu,
.apexcharts-toolbar .apexcharts-menu .sub-dropdown-menu .open-left-side.apexcharts-menu {
    right: 100% !important;
    left: auto !important;
}

.dropdown-menu .sub-dropdown-menu .dropdown-menu.open-right-side,
.apexcharts-toolbar .apexcharts-menu .sub-dropdown-menu .dropdown-menu.open-right-side,
.dropdown-menu .sub-dropdown-menu .apexcharts-toolbar .open-right-side.apexcharts-menu,
.apexcharts-toolbar .dropdown-menu .sub-dropdown-menu .open-right-side.apexcharts-menu,
.apexcharts-toolbar .apexcharts-menu .sub-dropdown-menu .open-right-side.apexcharts-menu {
    left: 100% !important;
}

.dropdown-menu.dropdown-menu-dark,
.apexcharts-toolbar .dropdown-menu-dark.apexcharts-menu {
    background: #262a2e;
    border-color: #262a2e;
    color: #fff;
}

.dropdown-menu.dropdown-menu-dark .dropdown-header,
.apexcharts-toolbar .dropdown-menu-dark.apexcharts-menu .dropdown-header,
.dropdown-menu.dropdown-menu-dark .dropdown-item-text,
.apexcharts-toolbar .dropdown-menu-dark.apexcharts-menu .dropdown-item-text {
    color: #6f6f6f;
}

.dropdown-menu.dropdown-menu-dark .dropdown-item,
.apexcharts-toolbar .dropdown-menu-dark.apexcharts-menu .dropdown-item {
    color: #eaeaea;
}

.dropdown-menu.dropdown-menu-dark .dropdown-item:focus:not(.active):not(.disabled),
.apexcharts-toolbar .dropdown-menu-dark.apexcharts-menu .dropdown-item:focus:not(.active):not(.disabled),
.dropdown-menu.dropdown-menu-dark .dropdown-item:hover:not(.active):not(.disabled),
.apexcharts-toolbar .dropdown-menu-dark.apexcharts-menu .dropdown-item:hover:not(.active):not(.disabled) {
    background-color: #4f4f4f;
    color: #fff;
}

.dropdown-menu.dropdown-menu-dark .dropdown-item.active,
.apexcharts-toolbar .dropdown-menu-dark.apexcharts-menu .dropdown-item.active,
.dropdown-menu.dropdown-menu-dark .dropdown-item:active,
.apexcharts-toolbar .dropdown-menu-dark.apexcharts-menu .dropdown-item:active {
    color: #fff;
}

.dropdown-menu.dropdown-menu-dark .dropdown-item:disabled,
.apexcharts-toolbar .dropdown-menu-dark.apexcharts-menu .dropdown-item:disabled,
.dropdown-menu.dropdown-menu-dark .dropdown-item.disabled,
.apexcharts-toolbar .dropdown-menu-dark.apexcharts-menu .dropdown-item.disabled {
    color: #5f5f5f;
}

.dropdown-menu.dropdown-menu-dark .dropdown-divider,
.apexcharts-toolbar .dropdown-menu-dark.apexcharts-menu .dropdown-divider {
    border-color: #262a2e;
}

.dropdown-divider {
    border-color: #eaeaea;
    margin-right: -.5rem;
    margin-left: -.5rem;
}

.show-on-hover:hover>.dropdown-menu,
.apexcharts-toolbar .show-on-hover:hover>.apexcharts-menu {
    display: block;
}

.show-on-hover>.dropdown-toggle:active {
    pointer-events: none;
}

.dropdown-menu.animated,
.apexcharts-toolbar .animated.apexcharts-menu {
    -webkit-animation-duration: 0.55s;
    animation-duration: 0.55s;
    -webkit-animation-timing-function: ease;
    animation-timing-function: ease;
}

.custom-caret svg {
    height: 19px;
    width: 19px;
}

.btn-sm .custom-caret svg,
div.hopscotch-bubble .hopscotch-bubble-container .hopscotch-actions .hopscotch-nav-button .custom-caret svg {
    height: 17px;
    width: 17px;
}

.btn-group-lg>.btn+.dropdown-toggle-split,
div.hopscotch-bubble .hopscotch-bubble-container .hopscotch-actions .btn-group-lg>.hopscotch-nav-button+.dropdown-toggle-split,
.btn-lg+.dropdown-toggle-split {
    padding-right: 1.3rem;
    padding-left: 1.3rem;
}

.btn-group-sm>.btn+.dropdown-toggle-split,
div.hopscotch-bubble .hopscotch-bubble-container .hopscotch-actions .btn-group-sm>.hopscotch-nav-button+.dropdown-toggle-split,
.btn-sm+.dropdown-toggle-split,
div.hopscotch-bubble .hopscotch-bubble-container .hopscotch-actions .hopscotch-nav-button+.dropdown-toggle-split {
    padding-right: .8rem;
    padding-left: .8rem;
}