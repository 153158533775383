/*Profile*/
.profile-wrap .profile-intro {
    position: relative;
    z-index: 1;
    margin-top: -6rem;
}

.profile-wrap .profile-img-wrap {
    height: 10rem;
    position: relative;
}

.profile-wrap .profile-img-wrap>img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.profile-wrap header.profile-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
    height: 65px;
    /* background: #fff; */
    border-bottom: 1px solid #d8d8d8;
    position: relative;
}

.profile-wrap header.profile-header .profile-options-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.profile-wrap header.profile-header .profile-options-wrap>a {
    margin-left: .5rem;
    display: inline-block;
}

.profile-wrap header.profile-header .profile-options-wrap>a:first-child {
    margin-left: 0;
}

.profile-wrap .comment-block .media .media-head {
    margin-right: 1rem;
}

.profile-wrap .comment-block .media .media-body>div:first-child {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: .25rem;
}

.profile-wrap .comment-block .media .media-body>div:first-child .cm-name {
    font-weight: 500;
    color: #262a2e;
}

.profile-wrap .comment-block .media .media-body>div:first-child .badge {
    margin-left: .625rem;
    flex-shrink: 0;
}

.profile-wrap .comment-block .media .media-body .comment-action-wrap span,
.profile-wrap .comment-block .media .media-body .comment-action-wrap a {
    color: #6f6f6f;
    font-size: 0.75rem;
}

.profile-wrap .comment-block .media .media-body .comment-action-wrap .comment-dot-sep {
    margin: 0 .25rem;
    color: #d8d8d8;
}

.profile-wrap .comment-block .media .media-body>.media {
    margin-top: 1.875rem;
}

.profile-wrap .comment-block .media .media-head {
    margin-right: 1rem;
}

.profile-wrap .comment-block .media .media-body>div:first-child {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: .25rem;
}

.profile-wrap .comment-block .media .media-body>div:first-child .cm-name {
    font-weight: 500;
    color: #262a2e;
}

.profile-wrap .comment-block .media .media-body>div:first-child .badge {
    margin-left: .625rem;
    flex-shrink: 0;
}

.profile-wrap .comment-block .media .media-body .comment-action-wrap span,
.profile-wrap .comment-block .media .media-body .comment-action-wrap a {
    color: #6f6f6f;
    font-size: 0.75rem;
}

.profile-wrap .comment-block .media .media-body .comment-action-wrap .comment-dot-sep {
    margin: 0 .25rem;
    color: #d8d8d8;
}

.profile-wrap .comment-block .media .media-body>.media {
    margin-top: 1.875rem;
}

.profile-wrap .card.card-profile-feed .btn-pg-link {
    position: absolute;
    bottom: 25px;
    left: 25px;
}

.profile-wrap .card.card-profile-feed .feed-img-layout .row {
    margin-right: -0.25rem;
    margin-left: -0.25rem;
}

.profile-wrap .card.card-profile-feed .feed-img-layout .row>[class*="col-"] {
    padding-right: .25rem;
    padding-left: .25rem;
}

.profile-wrap .card.card-profile-feed .feed-img-layout .feed-img {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
}

.profile-wrap .card.card-profile-feed .btn-video-link {
    background: rgba(47, 52, 58, 0.5);
    height: 45px;
    width: 45px;
    position: absolute;
    border-radius: 50%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    color: #fff;
}

.profile-wrap .card.card-profile-feed .btn-video-link:before {
    display: inline-block;
    font: normal normal normal 1.5rem/1 'Material-Design-Iconic-Font';
    padding-left: .25rem;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    text-rendering: auto;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\f3aa";
}

.profile-wrap .card.card-profile-feed .card-footer>div a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #9e9e9e;
    font-size: .875rem;
}

.profile-wrap .card.card-profile-feed .card-footer>div a>i {
    line-height: 1;
    margin-right: .5rem;
}

.profile-wrap .card.card-profile-feed .card-footer>div:last-child {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.profile-wrap .card.card-profile-feed .card-footer>div:last-child a {
    margin-right: .625rem;
}

.profile-wrap .card.card-profile-feed .card-footer>div:last-child a:last-child {
    margin-right: 0;
}

.edit-profile-wrap .nav-profile .nav-header {
    font-size: .75rem;
    text-transform: uppercase;
    font-weight: 600;
    color: #9e9e9e;
}

.edit-profile-wrap .nav-profile .nav {
    margin: 1rem 0;
    font-size: .875rem;
    flex-wrap: nowrap;
    border: 0;
}

.edit-profile-wrap .nav-profile .nav .nav-item {
    margin-bottom: .25rem;
}

.edit-profile-wrap .nav-profile .nav .nav-item:last-child {
    margin-bottom: 0;
}

.edit-profile-wrap .nav-profile .nav .nav-item a.nav-link {
    color: #262a2e;
    border: 0;
    padding: 0;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.edit-profile-wrap .nav-profile .nav .nav-item a.nav-link:hover,
.edit-profile-wrap .nav-profile .nav .nav-item a.nav-link:focus {
    color: rgba(38, 42, 46, 0.8);
}

.edit-profile-wrap .nav-profile .nav .nav-item a.nav-link.active {
    color: #09769F;
}
