/*Border*/
.border-dashed {
    border-style: dashed !important;
}

.border-dotted {
    border-style: dotted !important;
}

.circle {
    border-radius: 50% !important;
}

.rounded-pill-start {
    border-top-left-radius: 50rem !important;
    border-bottom-left-radius: 50rem !important;
}

.rounded-pill-end {
    border-top-right-radius: 50rem !important;
    border-bottom-right-radius: 50rem !important;
}

.rounded-top-start {
    border-top-left-radius: 0.25rem !important;
}

.rounded-top-end {
    border-top-right-radius: 0.25rem !important;
}

.rounded-bottom-start {
    border-bottom-left-radius: 0.25rem !important;
}

.rounded-bottom-end {
    border-bottom-right-radius: 0.25rem !important;
}

.rounded-top-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
}

.rounded-top-start-0 {
    border-top-left-radius: 0 !important;
}

.rounded-top-end-0 {
    border-top-right-radius: 0 !important;
}

.rounded-start-0 {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.rounded-end-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.rounded-bottom-0 {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.rounded-bottom-start-0 {
    border-bottom-left-radius: 0 !important;
}

.rounded-bottom-end-0 {
    border-bottom-right-radius: 0 !important;
}

.rounded-0 {
    border-radius: 0rem !important;
}

.rounded-1 {
    border-radius: 0.125rem !important;
}

.rounded-2 {
    border-radius: 0.25rem !important;
}

.rounded-3 {
    border-radius: 0.375rem !important;
}

.rounded-4 {
    border-radius: 0.5rem !important;
}

.rounded-5 {
    border-radius: 0.625rem !important;
}

.rounded-6 {
    border-radius: 0.75rem !important;
}

.rounded-7 {
    border-radius: 0.875rem !important;
}

.rounded-8 {
    border-radius: 1rem !important;
}

.rounded-9 {
    border-radius: 1.125rem !important;
}

.rounded-10 {
    border-radius: 1.25rem !important;
}

.rounded-11 {
    border-radius: 1.375rem !important;
}

.rounded-12 {
    border-radius: 1.5rem !important;
}

.rounded-13 {
    border-radius: 1.625rem !important;
}

.rounded-14 {
    border-radius: 1.75rem !important;
}

.rounded-15 {
    border-radius: 1.875rem !important;
}

.rounded-16 {
    border-radius: 2rem !important;
}

.rounded-17 {
    border-radius: 2.125rem !important;
}

.rounded-18 {
    border-radius: 2.25rem !important;
}

.rounded-19 {
    border-radius: 2.375rem !important;
}

.rounded-20 {
    border-radius: 2.5rem !important;
}

.rounded-21 {
    border-radius: 2.75rem !important;
}

.rounded-22 {
    border-radius: 3rem !important;
}

.rounded-23 {
    border-radius: 3.25rem !important;
}

.rounded-24 {
    border-radius: 3.5rem !important;
}

.rounded-25 {
    border-radius: 3.75rem !important;
}

.rounded-26 {
    border-radius: 4rem !important;
}

.rounded-27 {
    border-radius: 4.25rem !important;
}

.rounded-28 {
    border-radius: 4.5rem !important;
}

.rounded-29 {
    border-radius: 4.75rem !important;
}

.rounded-30 {
    border-radius: 5rem !important;
}

.rounded-31 {
    border-radius: 5.25rem !important;
}

.rounded-32 {
    border-radius: 5.5rem !important;
}

.rounded-33 {
    border-radius: 5.75rem !important;
}

.rounded-34 {
    border-radius: 6rem !important;
}

.border-sky {
    border-color: #00B0FF !important;
}

.border-cyan {
    border-color: #18DDEF !important;
}

.border-info {
    border-color: #18DDEF !important;
}

.border-red {
    border-color: #FF0000 !important;
}

.border-danger {
    border-color: #FF0000 !important;
}

.border-green {
    border-color: #00D67F !important;
}

.border-success {
    border-color: #00D67F !important;
}

.border-yellow {
    border-color: #FFC400 !important;
}

.border-warning {
    border-color: #FFC400 !important;
}

.border-pink {
    border-color: #E92990 !important;
}

.border-purple {
    border-color: #C02FF3 !important;
}

.border-violet {
    border-color: #7429F8 !important;
}

.border-indigo {
    border-color: #0069F7 !important;
}

.border-blue {
    border-color: #298DFF !important;
}

.border-primary {
    border-color: #09769F !important;
}

.border-teal {
    border-color: #09769F !important;
}

.border-neon {
    border-color: #81E230 !important;
}

.border-lime {
    border-color: #C2E939 !important;
}

.border-sun {
    border-color: #FFEA00 !important;
}

.border-orange {
    border-color: #FF9100 !important;
}

.border-pumpkin {
    border-color: #FF5700 !important;
}

.border-brown {
    border-color: #954D15 !important;
}

.border-gold {
    border-color: #C39749 !important;
}

.border-smoke {
    border-color: #5e7d8a !important;
}

.border-white {
    border-color: #fff !important;
}

.border-light {
    border-color: #d8d8d8 !important;
}

.border-dark-10 {
    border-color: #2F343A !important;
}

.border-dark-20 {
    border-color: #2a2f34 !important;
}

.border-dark,
.border-dark-40 {
    border-color: #262a2e !important;
}

.border-dark-60 {
    border-color: #212429 !important;
}

.border-dark-80 {
    border-color: #1c1f23 !important;
}

.border-dark-100 {
    border-color: #181a1d !important;
}

.border-grey {
    border-color: #9e9e9e !important;
}

.border-secondary {
    border-color: #6f6f6f !important;
}

.subtractive-example>.d-inline-block {
    border: 1px solid #9e9e9e;
}