/*Badge*/
.badge {
    font-weight: 500;
    border-radius: 0.5rem;
    padding: .5rem .625rem;
    font-size: 70%;
    min-width: 25px;
    letter-spacing: 0.3px;
    vertical-align: middle;
    display: inline-block;
    text-align: center;
    text-transform: capitalize;
}

.badge.badge-indicator {
    width: 10px;
    height: 10px;
    min-width: initial;
    border: 2px solid #fff;
    border-radius: 50%;
    padding: 0;
    position: relative;
}

.badge.badge-indicator:empty {
    display: inline-block;
}

.badge.badge-indicator.badge-indicator-sm {
    width: 7px;
    height: 7px;
}

.badge.badge-indicator.badge-indicator-lg {
    width: 12px;
    height: 12px;
}

.badge.badge-indicator.badge-indicator-xl {
    width: 16px;
    height: 16px;
}

.badge.badge-indicator.badge-indicator-nobdr {
    width: 8px;
    height: 8px;
    border: none;
}

.badge.badge-indicator.badge-indicator-nobdr-sm {
    width: 5px;
    height: 5px;
    border: none;
}

@-webkit-keyframes ripple {
    0% {
        transform: scale(0.8);
        opacity: 0.5;
    }

    to {
        transform: scale(2.4);
        opacity: 0;
    }
}

@keyframes ripple {
    0% {
        transform: scale(0.8);
        opacity: 0.5;
    }

    to {
        transform: scale(2.4);
        opacity: 0;
    }
}

.badge.badge-indicator.badge-indicator-processing {
    position: relative;
}

.badge.badge-indicator.badge-indicator-processing::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid;
    border-radius: 50%;
    -webkit-animation: ripple 1.2s infinite ease-in-out;
    animation: ripple 1.2s infinite ease-in-out;
    content: "";
}

.badge>span {
    display: flex;
    align-items: center;
}

.badge>span .icon {
    display: flex;
    font-size: 0.875rem;
    margin-right: .25rem;
}

.badge>span .badge-dot {
    display: flex;
    font-size: 8px;
    margin-right: .25rem;
}

.badge.badge-sm {
    font-size: 60%;
    padding: .25rem .5rem;
    min-width: 19px;
}

.badge.badge-sm>span .badge-dot {
    font-size: .375rem;
}

.badge.badge-sm>span .icon {
    font-size: 0.75rem;
}

.badge.badge-cyan {
    background: #18DDEF;
    color: #fff;
}

.badge.badge-cyan.badge-indicator-processing:after {
    border-color: #18DDEF;
}

.badge.badge-info {
    background: #18DDEF;
    color: #fff;
}

.badge.badge-info.badge-indicator-processing:after {
    border-color: #18DDEF;
}

.badge.badge-sky {
    background: #00B0FF;
    color: #fff;
}

.badge.badge-sky.badge-indicator-processing:after {
    border-color: #00B0FF;
}

.badge.badge-green {
    background: #00D67F;
    color: #fff;
}

.badge.badge-green.badge-indicator-processing:after {
    border-color: #00D67F;
}

.badge.badge-success {
    background: #00D67F;
    color: #fff;
}

.badge.badge-success.badge-indicator-processing:after {
    border-color: #00D67F;
}

.badge.badge-red {
    background: #FF0000;
    color: #fff;
}

.badge.badge-red.badge-indicator-processing:after {
    border-color: #FF0000;
}

.badge.badge-danger {
    background: #FF0000;
    color: #fff;
}

.badge.badge-danger.badge-indicator-processing:after {
    border-color: #FF0000;
}

.badge.badge-yellow {
    background: #FFC400;
    color: #fff;
}

.badge.badge-yellow.badge-indicator-processing:after {
    border-color: #FFC400;
}

.badge.badge-warning {
    background: #FFC400;
    color: #fff;
}

.badge.badge-warning.badge-indicator-processing:after {
    border-color: #FFC400;
}

.badge.badge-pink {
    background: #E92990;
    color: #fff;
}

.badge.badge-pink.badge-indicator-processing:after {
    border-color: #E92990;
}

.badge.badge-purple {
    background: #C02FF3;
    color: #fff;
}

.badge.badge-purple.badge-indicator-processing:after {
    border-color: #C02FF3;
}

.badge.badge-violet {
    background: #7429F8;
    color: #fff;
}

.badge.badge-violet.badge-indicator-processing:after {
    border-color: #7429F8;
}

.badge.badge-indigo {
    background: #0069F7;
    color: #fff;
}

.badge.badge-indigo.badge-indicator-processing:after {
    border-color: #0069F7;
}

.badge.badge-blue {
    background: #298DFF;
    color: #fff;
}

.badge.badge-blue.badge-indicator-processing:after {
    border-color: #298DFF;
}

.badge.badge-primary {
    background: #09769F;
    color: #fff;
}

.badge.badge-primary.badge-indicator-processing:after {
    border-color: #09769F;
}

.badge.badge-teal {
    background: #09769F;
    color: #fff;
}

.badge.badge-teal.badge-indicator-processing:after {
    border-color: #09769F;
}

.badge.badge-neon {
    background: #81E230;
    color: #fff;
}

.badge.badge-neon.badge-indicator-processing:after {
    border-color: #81E230;
}

.badge.badge-lime {
    background: #C2E939;
    color: #fff;
}

.badge.badge-lime.badge-indicator-processing:after {
    border-color: #C2E939;
}

.badge.badge-sun {
    background: #FFEA00;
    color: #262a2e;
}

.badge.badge-sun.badge-indicator-processing:after {
    border-color: #FFEA00;
}

.badge.badge-orange {
    background: #FF9100;
    color: #fff;
}

.badge.badge-orange.badge-indicator-processing:after {
    border-color: #FF9100;
}

.badge.badge-pumpkin {
    background: #FF5700;
    color: #fff;
}

.badge.badge-pumpkin.badge-indicator-processing:after {
    border-color: #FF5700;
}

.badge.badge-smoke {
    background: #5e7d8a;
    color: #fff;
}

.badge.badge-smoke.badge-indicator-processing:after {
    border-color: #5e7d8a;
}

.badge.badge-brown {
    background: #954D15;
    color: #fff;
}

.badge.badge-brown.badge-indicator-processing:after {
    border-color: #954D15;
}

.badge.badge-gold {
    background: #C39749;
    color: #fff;
}

.badge.badge-gold.badge-indicator-processing:after {
    border-color: #C39749;
}

.badge.badge-grey {
    background: #9e9e9e;
    color: #fff;
}

.badge.badge-grey.badge-indicator-processing:after {
    border-color: #9e9e9e;
}

.badge.badge-secondary {
    background: #6f6f6f;
    color: #fff;
}

.badge.badge-secondary.badge-indicator-processing:after {
    border-color: #6f6f6f;
}

.badge.badge-light {
    background: #eaeaea;
    color: #262a2e;
}

.badge.badge-light.badge-indicator-processing:after {
    border-color: #eaeaea;
}

.badge.badge-dark {
    background: #262a2e;
    color: #fff;
}

.badge.badge-dark.badge-indicator-processing:after {
    border-color: #262a2e;
}

.badge.badge-outline {
    background: transparent;
    border: 1px solid;
}

.badge.badge-outline.badge-cyan {
    border-color: #18DDEF;
    color: #18DDEF;
}

.badge.badge-outline.badge-info {
    border-color: #18DDEF;
    color: #18DDEF;
}

.badge.badge-outline.badge-sky {
    border-color: #00B0FF;
    color: #00B0FF;
}

.badge.badge-outline.badge-green {
    border-color: #00D67F;
    color: #00ab66;
}

.badge.badge-outline.badge-success {
    border-color: #00D67F;
    color: #00ab66;
}

.badge.badge-outline.badge-red {
    border-color: #FF0000;
    color: #e60000;
}

.badge.badge-outline.badge-danger {
    border-color: #FF0000;
    color: #e60000;
}

.badge.badge-outline.badge-yellow {
    border-color: #FFC400;
    color: #cc9d00;
}

.badge.badge-outline.badge-warning {
    border-color: #FFC400;
    color: #cc9d00;
}

.badge.badge-outline.badge-grey {
    border-color: #9e9e9e;
    color: #9e9e9e;
}

.badge.badge-outline.badge-secondary {
    border-color: #6f6f6f;
    color: #6f6f6f;
}

.badge.badge-outline.badge-light {
    border-color: #eaeaea;
    color: #262a2e;
}

.badge.badge-outline.badge-dark {
    border-color: #262a2e;
    color: #262a2e;
}

.badge.badge-outline.badge-pink {
    border-color: #E92990;
    color: #E92990;
}

.badge.badge-outline.badge-purple {
    border-color: #C02FF3;
    color: #C02FF3;
}

.badge.badge-outline.badge-violet {
    border-color: #7429F8;
    color: #7429F8;
}

.badge.badge-outline.badge-indigo {
    border-color: #0069F7;
    color: #0069F7;
}

.badge.badge-outline.badge-blue {
    border-color: #298DFF;
    color: #298DFF;
}

.badge.badge-outline.badge-primary {
    border-color: #09769F;
    color: #09769F;
}

.badge.badge-outline.badge-teal {
    border-color: #09769F;
    color: #09769F;
}

.badge.badge-outline.badge-neon {
    border-color: #81E230;
    color: #81E230;
}

.badge.badge-outline.badge-lime {
    border-color: #C2E939;
    color: #C2E939;
}

.badge.badge-outline.badge-sun {
    border-color: #FFEA00;
    color: #FFEA00;
}

.badge.badge-outline.badge-orange {
    border-color: #FF9100;
    color: #FF9100;
}

.badge.badge-outline.badge-pumpkin {
    border-color: #FF5700;
    color: #FF5700;
}

.badge.badge-outline.badge-smoke {
    border-color: #5e7d8a;
    color: #5e7d8a;
}

.badge.badge-outline.badge-brown {
    border-color: #954D15;
    color: #954D15;
}

.badge.badge-outline.badge-gold {
    border-color: #C39749;
    color: #C39749;
}

.badge.badge-soft-cyan {
    background: #edfcfe;
    color: #18DDEF;
}

.badge.badge-soft-cyan.badge-indicator-processing:after {
    border-color: #edfcfe;
}

.badge.badge-soft-info {
    background: #edfcfe;
    color: #18DDEF;
}

.badge.badge-soft-info.badge-indicator-processing:after {
    border-color: #edfcfe;
}

.badge.badge-soft-sky {
    background: #ebf9ff;
    color: #00B0FF;
}

.badge.badge-soft-sky.badge-indicator-processing:after {
    border-color: #ebf9ff;
}

.badge.badge-soft-green {
    background: #ebfcf5;
    color: #00ab66;
}

.badge.badge-soft-green.badge-indicator-processing:after {
    border-color: #ebfcf5;
}

.badge.badge-soft-success {
    background: #ebfcf5;
    color: #00ab66;
}

.badge.badge-soft-success.badge-indicator-processing:after {
    border-color: #ebfcf5;
}

.badge.badge-soft-red {
    background: #ffebeb;
    color: #e60000;
}

.badge.badge-soft-red.badge-indicator-processing:after {
    border-color: #ffebeb;
}

.badge.badge-soft-danger {
    background: #ffebeb;
    color: #e60000;
}

.badge.badge-soft-danger.badge-indicator-processing:after {
    border-color: #ffebeb;
}

.badge.badge-soft-yellow {
    background: #fffaeb;
    color: #cc9d00;
}

.badge.badge-soft-yellow.badge-indicator-processing:after {
    border-color: #fffaeb;
}

.badge.badge-soft-warning {
    background: #fffaeb;
    color: #cc9d00;
}

.badge.badge-soft-warning.badge-indicator-processing:after {
    border-color: #fffaeb;
}

.badge.badge-soft-pink {
    background: #fdeef6;
    color: #E92990;
}

.badge.badge-soft-pink.badge-indicator-processing:after {
    border-color: #fdeef6;
}

.badge.badge-soft-purple {
    background: #faeefe;
    color: #C02FF3;
}

.badge.badge-soft-purple.badge-indicator-processing:after {
    border-color: #faeefe;
}

.badge.badge-soft-violet {
    background: #f4eefe;
    color: #7429F8;
}

.badge.badge-soft-violet.badge-indicator-processing:after {
    border-color: #f4eefe;
}

.badge.badge-soft-indigo {
    background: #ebf3fe;
    color: #0069F7;
}

.badge.badge-soft-indigo.badge-indicator-processing:after {
    border-color: #ebf3fe;
}

.badge.badge-soft-blue {
    background: #eef6ff;
    color: #298DFF;
}

.badge.badge-soft-blue.badge-indicator-processing:after {
    border-color: #eef6ff;
}

.badge.badge-soft-primary {
    background: #EBFAFF;
    color: #09769F;
}

.badge.badge-soft-primary.badge-indicator-processing:after {
    border-color: #EBFAFF;
}

.badge.badge-soft-teal {
    background: #EBFAFF;
    color: #09769F;
}

.badge.badge-soft-teal.badge-indicator-processing:after {
    border-color: #EBFAFF;
}

.badge.badge-soft-neon {
    background: #f5fdee;
    color: #81E230;
}

.badge.badge-soft-neon.badge-indicator-processing:after {
    border-color: #f5fdee;
}

.badge.badge-soft-lime {
    background: #fafdef;
    color: #C2E939;
}

.badge.badge-soft-lime.badge-indicator-processing:after {
    border-color: #fafdef;
}

.badge.badge-soft-sun {
    background: #fffdeb;
    color: #FFEA00;
}

.badge.badge-soft-sun.badge-indicator-processing:after {
    border-color: #fffdeb;
}

.badge.badge-soft-orange {
    background: #fff6eb;
    color: #FF9100;
}

.badge.badge-soft-orange.badge-indicator-processing:after {
    border-color: #fff6eb;
}

.badge.badge-soft-pumpkin {
    background: #fff2eb;
    color: #FF5700;
}

.badge.badge-soft-pumpkin.badge-indicator-processing:after {
    border-color: #fff2eb;
}

.badge.badge-soft-smoke {
    background: #f2f5f6;
    color: #5e7d8a;
}

.badge.badge-soft-smoke.badge-indicator-processing:after {
    border-color: #f2f5f6;
}

.badge.badge-soft-brown {
    background: #f7f1ec;
    color: #954D15;
}

.badge.badge-soft-brown.badge-indicator-processing:after {
    border-color: #f7f1ec;
}

.badge.badge-soft-gold {
    background: #faf7f0;
    color: #C39749;
}

.badge.badge-soft-gold.badge-indicator-processing:after {
    border-color: #faf7f0;
}

.badge.badge-soft-grey {
    background: #f7f7f7;
    color: #9e9e9e;
}

.badge.badge-soft-grey.badge-indicator-processing:after {
    border-color: #f7f7f7;
}

.badge.badge-soft-secondary {
    background: #f3f3f3;
    color: #6f6f6f;
}

.badge.badge-soft-secondary.badge-indicator-processing:after {
    border-color: #f3f3f3;
}

.badge.badge-soft-light {
    background: #f7f7f7;
    color: #6f6f6f;
}

.badge.badge-soft-light.badge-indicator-processing:after {
    border-color: #f7f7f7;
}

.badge.badge-soft-dark {
    background: #d8d8d8;
    color: #262a2e;
}

.badge.badge-soft-dark.badge-indicator-processing:after {
    border-color: #d8d8d8;
}

.badge.badge-pill {
    border-radius: 50rem;
}

a.badge {
    -webkit-transition: all 0.15s ease;
    -moz-transition: all 0.15s ease;
    transition: all 0.15s ease;
}

a.badge:hover,
a.badge:focus {
    -webkit-transform: translateY(-3px);
    -moz-transform: translateY(-3px);
    -ms-transform: translateY(-3px);
    -o-transform: translateY(-3px);
    transform: translateY(-3px);
    box-shadow: 0 0.1875rem 0.5rem rgba(47, 52, 58, 0.3) !important;
}

a.badge-sky:hover,
a.badge-sky:focus {
    box-shadow: 0 0.1875rem 0.5rem rgba(0, 176, 255, 0.3) !important;
}

a.badge-cyan:hover,
a.badge-cyan:focus {
    background: #18DDEF;
    color: #fff;
    box-shadow: 0 0.1875rem 0.5rem rgba(24, 221, 239, 0.3) !important;
}

a.badge-info:hover,
a.badge-info:focus {
    background: #18DDEF;
    color: #fff;
    box-shadow: 0 0.1875rem 0.5rem rgba(24, 221, 239, 0.3) !important;
}

a.badge-blue:hover,
a.badge-blue:focus {
    background: #298DFF;
    color: #fff;
    box-shadow: 0 0.1875rem 0.5rem rgba(41, 141, 255, 0.3) !important;
}

a.badge-primary:hover,
a.badge-primary:focus {
    background: #09769F;
    color: #fff;
    box-shadow: 0 0.1875rem 0.5rem rgba(0, 125, 136, 0.3) !important;
}

a.badge-green:hover,
a.badge-green:focus {
    background: #00D67F;
    color: #fff;
    box-shadow: 0 0.1875rem 0.5rem rgba(0, 214, 127, 0.3) !important;
}

a.badge-success:hover,
a.badge-success:focus {
    background: #00D67F;
    color: #fff;
    box-shadow: 0 0.1875rem 0.5rem rgba(0, 214, 127, 0.3) !important;
}

a.badge-red:hover,
a.badge-red:focus {
    background: #FF0000;
    color: #fff;
    box-shadow: 0 0.1875rem 0.5rem rgba(255, 0, 0, 0.3) !important;
}

a.badge-danger:hover,
a.badge-danger:focus {
    background: #FF0000;
    color: #fff;
    box-shadow: 0 0.1875rem 0.5rem rgba(255, 0, 0, 0.3) !important;
}

a.badge-yellow:hover,
a.badge-yellow:focus {
    background: #FFC400;
    color: #fff;
    box-shadow: 0 0.1875rem 0.5rem rgba(255, 196, 0, 0.3) !important;
}

a.badge-warning:hover,
a.badge-warning:focus {
    background: #FFC400;
    color: #fff;
    box-shadow: 0 0.1875rem 0.5rem rgba(255, 196, 0, 0.3) !important;
}

a.badge-grey:hover,
a.badge-grey:focus {
    background: #9e9e9e;
    color: #fff;
    box-shadow: 0 0.1875rem 0.5rem rgba(158, 158, 158, 0.3) !important;
}

a.badge-secondary:hover,
a.badge-secondary:focus {
    background: #6f6f6f;
    color: #fff;
    box-shadow: 0 0.1875rem 0.5rem rgba(111, 111, 111, 0.3) !important;
}

a.badge-pink:hover,
a.badge-pink:focus {
    box-shadow: 0 0.1875rem 0.5rem rgba(233, 41, 144, 0.3) !important;
}

a.badge-purple:hover,
a.badge-purple:focus {
    box-shadow: 0 0.1875rem 0.5rem rgba(192, 47, 243, 0.3) !important;
}

a.badge-violet:hover,
a.badge-violet:focus {
    box-shadow: 0 0.1875rem 0.5rem rgba(116, 41, 248, 0.3) !important;
}

a.badge-indigo:hover,
a.badge-indigo:focus {
    box-shadow: 0 0.1875rem 0.5rem rgba(0, 105, 247, 0.3) !important;
}

a.badge-teal:hover,
a.badge-teal:focus {
    box-shadow: 0 0.1875rem 0.5rem rgba(0, 125, 136, 0.3) !important;
}

a.badge-neon:hover,
a.badge-neon:focus {
    box-shadow: 0 0.1875rem 0.5rem rgba(129, 226, 48, 0.3) !important;
}

a.badge-lime:hover,
a.badge-lime:focus {
    box-shadow: 0 0.1875rem 0.5rem rgba(194, 233, 57, 0.3) !important;
}

a.badge-sun:hover,
a.badge-sun:focus {
    box-shadow: 0 0.1875rem 0.5rem rgba(255, 234, 0, 0.3) !important;
}

a.badge-orange:hover,
a.badge-orange:focus {
    box-shadow: 0 0.1875rem 0.5rem rgba(255, 145, 0, 0.3) !important;
}

a.badge-pumpkin:hover,
a.badge-pumpkin:focus {
    box-shadow: 0 0.1875rem 0.5rem rgba(255, 87, 0, 0.3) !important;
}

a.badge-smoke:hover,
a.badge-smoke:focus {
    box-shadow: 0 0.1875rem 0.5rem rgba(94, 125, 138, 0.3) !important;
}

a.badge-brown:hover,
a.badge-brown:focus {
    box-shadow: 0 0.1875rem 0.5rem rgba(149, 77, 21, 0.3) !important;
}

a.badge-gold:hover,
a.badge-gold:focus {
    box-shadow: 0 0.1875rem 0.5rem rgba(195, 151, 73, 0.3) !important;
}

a.badge-light:hover,
a.badge-light:focus {
    background: #eaeaea;
    color: #262a2e;
    box-shadow: 0 0.1875rem 0.5rem #d8d8d8 !important;
}

a.badge-dark:hover,
a.badge-dark:focus {
    background: #262a2e;
    color: #fff;
    box-shadow: 0 0.1875rem 0.5rem rgba(38, 42, 46, 0.3) !important;
}

a.badge-outline:hover,
a.badge-outline:focus {
    background: transparent;
    box-shadow: none;
}

.badge-status .badge-label {
    margin-left: .25rem;
    font-size: 0.875rem;
}

.badge-on-text .badge {
    position: relative;
    top: -5px;
    right: 0;
}

.badge-on-text-end .badge {
    margin-left: .5rem;
    position: relative;
    top: -1px;
}