.premium_overlays_box {
    position: absolute;
    left: calc(50% - 200px);
    width: 400px;
    background: #fff;
    top: calc(50% - 100px);
    padding: 15px 20px;
    text-align: center;
    z-index: 9;
    box-shadow: 0px 0px 10px #aaa;
    border-radius: 6px;
}

.premium_overlays_box span {
    color: #212429;
    margin-bottom: 10px;
    margin-right: 10px
}

.premium_overlays_box button {
    background: #340f49;
    border: 1px solid #340f49;
    padding: 6px 12px;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    border-radius: 4px;
}

.premium_overlays_box button:hover {
    background: #fff;
    color: #340f49;
    border: 1px solid #340f49;
}

.premium_overlays_box_multiple {
    position: absolute;
    left: calc(50% - 250px);
    width: 400px;
    background: #fff;
    padding: 15px 20px;
    text-align: center;
    z-index: 9999;
    box-shadow: 0px 0px 10px #aaa;
    border-radius: 6px;
}

.premium_overlays_box_multiple span {
    color: #212429;
    margin-bottom: 10px;
    margin-right: 10px
}

.premium_overlays_box_multiple button {
    background: #340f49;
    border: 1px solid #340f49;
    padding: 6px 12px;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    border-radius: 4px;
}

.premium_overlays_box_multiple button:hover {
    background: #fff;
    color: #340f49;
    border: 1px solid #340f49;
}


.right_stickey {
    top: 60px;
    position: sticky;
}
.premium_statics_col {
    position: absolute;
    left:0;
    top:0;
    width:100%;
    height: 100%;
    background:rgba(256,256,256,0.6);
    z-index: 9;
}
.premium_statics_center {
    display: flex;
    height:100%;
    align-items:center;
}
.premium_statics_box {
    width: 550px;
    padding: 24px;
    z-index: 9;
    background:#340f49;
    border-radius: 8px;
    margin-left:auto;
    margin-right:auto;
    height: 236px;
    border-left: 4px solid #5ccdf5;
    border-right: 4px solid #5ccdf5;
}
.get_access_btn {
    color: #fff;
    background: transparent;
    font-weight: 600;
    font-size: 15px;
    border-radius: 6px;
    display: inline-block;
    padding: 10px 28px;
    cursor: pointer;
    border: 2px solid #fff;
}
.get_access_btn:hover, .get_access_btn:focus {
    background:#5ccbf5;
}

[data-layout-style="collapsed"] .premium_access_sec.upgrade_access_sec {
  left:72px;
  width: calc( 100% - 72px);
}
.premium_access_sec.upgrade_access_sec {
    position: fixed;
    top: auto;
    bottom: 0;
    /* background: linear-gradient(0deg, rgba(66, 53, 71, 0.4) 0%, rgba(246, 250, 252, 0) 100%); */
    z-index: 99;
    height: 230px;
    left: 270px;
    width: calc( 100% - 270px);
    background: linear-gradient(327deg, #09769F -81.71%, #BDEAFB 52.94%);
  }

.premium_access_sec {
    position: absolute;
    left:0;
    top:0;
    width:100%;
    height: 100%;
    background:rgba(256,256,256,0.6);
    z-index: 9;
}
.premium_access_align {
    display: flex;
    height:100%;
    align-items:center;
}
.premium_access_box_layout {
    width: 650px;
    width: 62%;
    z-index: 9;
    /* background: #fff; */
    /* box-shadow: 0 0 63px #534c4c3d; */
    /* border-radius: 6px; */
    margin-left: auto;
    margin-right: auto;
    border-radius: 4px;
    /* background: linear-gradient(327deg, #09769F -81.71%, #BDEAFB 52.94%);*/
}
.premium_access_left_bar {
    background:linear-gradient(180deg, rgb(255, 255, 255) 0%, rgb(246, 249, 251) 100%);
    padding:36px;
    border-radius:6px 0 0 6px;
}
.premium_access_right_bar {
    padding:36px;
}