
/*Position*/
.position-top-start,
.position-top-end,
.position-bottom-start,
.position-bottom-end,
.position-start-center,
.position-end-center,
.position-top-center,
.position-bottom-center,
.position-top-start-overflow,
.position-top-end-overflow,
.position-bottom-start-overflow,
.position-bottom-end-overflow,
.position-start-center-overflow,
.position-end-center-overflow,
.position-top-center-overflow,
.position-bottom-center-overflow {
    position: absolute !important;
    z-index: 2;
}

.position-top-start {
    top: 0 !important;
    left: 0 !important;
}

.position-top-end {
    top: 0 !important;
    right: 0 !important;
}

.position-bottom-start {
    bottom: 0 !important;
    left: 0 !important;
    top: auto !important;
}

.position-bottom-end {
    bottom: 0 !important;
    right: 0 !important;
    top: auto !important;
}

.position-top-center {
    top: 0 !important;
    right: 50% !important;
    -webkit-transform: translateX(50%) !important;
    -moz-transform: translateX(50%) !important;
    -ms-transform: translateX(50%) !important;
    -o-transform: translateX(50%) !important;
    transform: translateX(50%) !important;
}

.position-bottom-center {
    bottom: 0 !important;
    right: 50% !important;
    -webkit-transform: translateX(50%) !important;
    -moz-transform: translateX(50%) !important;
    -ms-transform: translateX(50%) !important;
    -o-transform: translateX(50%) !important;
    transform: translateX(50%) !important;
}

.position-start-center {
    left: 0 !important;
    top: 50% !important;
    -webkit-transform: translateY(-50%) !important;
    -moz-transform: translateY(-50%) !important;
    -ms-transform: translateY(-50%) !important;
    -o-transform: translateY(-50%) !important;
    transform: translateY(-50%) !important;
}

.position-end-center {
    right: 0 !important;
    top: 50% !important;
    -webkit-transform: translateY(-50%) !important;
    -moz-transform: translateY(-50%) !important;
    -ms-transform: translateY(-50%) !important;
    -o-transform: translateY(-50%) !important;
    transform: translateY(-50%) !important;
}

.position-center {
    left: 50% !important;
    top: 50% !important;
    -webkit-transform: translate(-50%, -50%) !important;
    -moz-transform: translate(-50%, -50%) !important;
    -ms-transform: translate(-50%, -50%) !important;
    -o-transform: translate(-50%, -50%) !important;
    transform: translate(-50%, -50%) !important;
}

.position-top-start-overflow {
    top: 0 !important;
    left: 0 !important;
    -webkit-transform: translate(-50%, -50%) !important;
    -moz-transform: translate(-50%, -50%) !important;
    -ms-transform: translate(-50%, -50%) !important;
    -o-transform: translate(-50%, -50%) !important;
    transform: translate(-50%, -50%) !important;
}

.position-top-end-overflow {
    top: 0 !important;
    right: 0 !important;
    -webkit-transform: translate(50%, -50%) !important;
    -moz-transform: translate(50%, -50%) !important;
    -ms-transform: translate(50%, -50%) !important;
    -o-transform: translate(50%, -50%) !important;
    transform: translate(50%, -50%) !important;
}

.position-bottom-start-overflow {
    bottom: 0 !important;
    left: 0 !important;
    top: auto !important;
    -webkit-transform: translate(-50%, 50%) !important;
    -moz-transform: translate(-50%, 50%) !important;
    -ms-transform: translate(-50%, 50%) !important;
    -o-transform: translate(-50%, 50%) !important;
    transform: translate(-50%, 50%) !important;
}

.position-bottom-end-overflow {
    bottom: 0 !important;
    right: 0 !important;
    top: auto !important;
    -webkit-transform: translate(50%, 50%) !important;
    -moz-transform: translate(50%, 50%) !important;
    -ms-transform: translate(50%, 50%) !important;
    -o-transform: translate(50%, 50%) !important;
    transform: translate(50%, 50%) !important;
}

.position-top-center-overflow {
    top: 0 !important;
    right: 50% !important;
    -webkit-transform: translate(50%, -50%) !important;
    -moz-transform: translate(50%, -50%) !important;
    -ms-transform: translate(50%, -50%) !important;
    -o-transform: translate(50%, -50%) !important;
    transform: translate(50%, -50%) !important;
}

.position-bottom-center-overflow {
    bottom: 0 !important;
    right: 50% !important;
    -webkit-transform: translate(50%, 50%) !important;
    -moz-transform: translate(50%, 50%) !important;
    -ms-transform: translate(50%, 50%) !important;
    -o-transform: translate(50%, 50%) !important;
    transform: translate(50%, 50%) !important;
}

.position-start-center-overflow {
    left: 0 !important;
    top: 50% !important;
    -webkit-transform: translate(-50%, -50%) !important;
    -moz-transform: translate(-50%, -50%) !important;
    -ms-transform: translate(-50%, -50%) !important;
    -o-transform: translate(-50%, -50%) !important;
    transform: translate(-50%, -50%) !important;
}

.position-end-center-overflow {
    right: 0 !important;
    top: 50% !important;
    -webkit-transform: translate(50%, -50%) !important;
    -moz-transform: translate(50%, -50%) !important;
    -ms-transform: translate(50%, -50%) !important;
    -o-transform: translate(50%, -50%) !important;
    transform: translate(50%, -50%) !important;
}

.position-top-start-overflow-1,
.position-top-end-overflow-1,
.position-bottom-start-overflow-1,
.position-bottom-end-overflow-1 {
    position: absolute !important;
    z-index: 2;
}

.position-top-start-overflow-1 {
    top: 12% !important;
    left: 16% !important;
    -webkit-transform: translate(-50%, -50%) !important;
    -moz-transform: translate(-50%, -50%) !important;
    -ms-transform: translate(-50%, -50%) !important;
    -o-transform: translate(-50%, -50%) !important;
    transform: translate(-50%, -50%) !important;
}

.position-top-end-overflow-1 {
    top: 12% !important;
    right: 16% !important;
    -webkit-transform: translate(50%, -50%) !important;
    -moz-transform: translate(50%, -50%) !important;
    -ms-transform: translate(50%, -50%) !important;
    -o-transform: translate(50%, -50%) !important;
    transform: translate(50%, -50%) !important;
}

.position-bottom-start-overflow-1 {
    bottom: 12% !important;
    left: 16% !important;
    top: auto !important;
    -webkit-transform: translate(-50%, 50%) !important;
    -moz-transform: translate(-50%, 50%) !important;
    -ms-transform: translate(-50%, 50%) !important;
    -o-transform: translate(-50%, 50%) !important;
    transform: translate(-50%, 50%) !important;
}

.position-bottom-end-overflow-1 {
    bottom: 12% !important;
    right: 16% !important;
    top: auto !important;
    -webkit-transform: translate(50%, 50%) !important;
    -moz-transform: translate(50%, 50%) !important;
    -ms-transform: translate(50%, 50%) !important;
    -o-transform: translate(50%, 50%) !important;
    transform: translate(50%, 50%) !important;
}
