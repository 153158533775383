
/*------------------------------------------------------------------
[Footer]
Project:	Jampack
-------------------------------------------------------------------*/
/*Footer*/
[data-footer="simple"].hk-wrapper .hk-pg-wrapper,
.footer-simple-example.hk-wrapper .hk-pg-wrapper {
    padding-bottom: 120px;
}

[data-footer="simple"] .hk-footer,
.footer-simple-example .hk-footer {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    border-top: 1px solid #eaeaea;
    background: #fff;
}

[data-footer="simple"] .hk-footer .footer>.row,
.footer-simple-example .hk-footer .footer>.row {
    height: 60px;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    padding: 22px 0;
}

[data-footer="simple"] .hk-footer .footer .footer-text,
.footer-simple-example .hk-footer .footer .footer-text {
    font-size: 0.75rem;
}

[data-footer="simple"] .hk-footer .footer .footer-text a,
.footer-simple-example .hk-footer .footer .footer-text a {
    color: #09769F;
}

[data-footer="simple"] .hk-footer .footer .footer-text .copy-text,
.footer-simple-example .hk-footer .footer .footer-text .copy-text {
    padding-right: 5px;
}

[data-footer="simple"] .hk-footer .footer .footer-text .footer-link-sep,
.footer-simple-example .hk-footer .footer .footer-text .footer-link-sep {
    padding: 0 5px;
    color: #d8d8d8;
}

[data-footer="simple"] .hk-footer .footer .footer-extr-link,
.footer-simple-example .hk-footer .footer .footer-extr-link {
    font-size: 0.75rem;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: flex-end;
    -webkit-justify-content: flex-end;
}

[data-footer="simple"] .hk-footer .footer .footer-extr-link .feather-icon,
.footer-simple-example .hk-footer .footer .footer-extr-link .feather-icon {
    margin-right: .25rem;
    color: #09769F;
}

[data-footer="simple"] .hk-footer .footer .footer-extr-link .feather-icon svg,
.footer-simple-example .hk-footer .footer .footer-extr-link .feather-icon svg {
    height: 14px;
    width: 14px;
}

[data-footer="simple"] .hk-footer.footer-wth-bg,
.footer-simple-example .hk-footer.footer-wth-bg {
    background: #f7f7f7;
    border-top: none;
}

[data-footer="complex"].hk-wrapper .hk-pg-wrapper,
.footer-complex-example.hk-wrapper .hk-pg-wrapper {
    padding-bottom: 400px;
}

[data-footer="complex"] .hk-footer,
.footer-complex-example .hk-footer {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    border-top: 1px solid #d8d8d8;
}

[data-footer="complex"] .hk-footer.footer-wth-bg,
.footer-complex-example .hk-footer.footer-wth-bg {
    background: #f7f7f7;
    border-top: none;
}

[data-footer="complex"] .hk-footer .footer,
.footer-complex-example .hk-footer .footer {
    padding-top: 40px;
    padding-bottom: 30px;
}

[data-footer="complex"] .hk-footer .footer>.row:last-child,
.footer-complex-example .hk-footer .footer>.row:last-child {
    margin-top: 3.375rem;
    align-items: center;
}

[data-footer="complex"] .hk-footer .footer .footer-brand,
.footer-complex-example .hk-footer .footer .footer-brand {
    display: block;
    margin-bottom: .25rem;
}

[data-footer="complex"] .hk-footer .footer .footer-info-text,
.footer-complex-example .hk-footer .footer .footer-info-text {
    font-size: 0.875rem;
    width: 85%;
}

[data-footer="complex"] .hk-footer .footer .quick-links-head,
.footer-complex-example .hk-footer .footer .quick-links-head {
    text-transform: uppercase;
    margin-bottom: 1rem;
    font-size: .85rem;
    font-weight: 600;
}

[data-footer="complex"] .hk-footer .footer .quick-links,
.footer-complex-example .hk-footer .footer .quick-links {
    display: block;
    color: #262a2e;
    margin-bottom: .25rem;
    font-size: 0.875rem;
}

[data-footer="complex"] .hk-footer .footer .quick-links:hover,
.footer-complex-example .hk-footer .footer .quick-links:hover {
    color: #262a2e;
}

[data-footer="complex"] .hk-footer .footer .footer-text,
.footer-complex-example .hk-footer .footer .footer-text {
    font-size: 0.75rem;
}

[data-footer="complex"] .hk-footer .footer .footer-text a,
.footer-complex-example .hk-footer .footer .footer-text a {
    color: #09769F;
}

[data-footer="complex"] .hk-footer .footer .footer-text .copy-text,
.footer-complex-example .hk-footer .footer .footer-text .copy-text {
    padding-right: .25rem;
}

[data-footer="complex"] .hk-footer .footer .footer-text .footer-link-sep,
.footer-complex-example .hk-footer .footer .footer-text .footer-link-sep {
    padding: 0 .25rem;
    color: #d8d8d8;
}

[data-footer="complex"] .hk-footer .footer .footer-social-btn-wrap,
.footer-complex-example .hk-footer .footer .footer-social-btn-wrap {
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: flex-end;
    -webkit-justify-content: flex-end;
}

[data-footer="complex"] .hk-footer .footer .footer-social-btn-wrap .btn,
[data-footer="complex"] .hk-footer .footer .footer-social-btn-wrap div.hopscotch-bubble .hopscotch-bubble-container .hopscotch-actions .hopscotch-nav-button,
div.hopscotch-bubble .hopscotch-bubble-container .hopscotch-actions [data-footer="complex"] .hk-footer .footer .footer-social-btn-wrap .hopscotch-nav-button,
.footer-complex-example .hk-footer .footer .footer-social-btn-wrap .btn,
.footer-complex-example .hk-footer .footer .footer-social-btn-wrap div.hopscotch-bubble .hopscotch-bubble-container .hopscotch-actions .hopscotch-nav-button,
div.hopscotch-bubble .hopscotch-bubble-container .hopscotch-actions .footer-complex-example .hk-footer .footer .footer-social-btn-wrap .hopscotch-nav-button {
    margin-left: .625rem;
    flex-shrink: 0;
}

.footer-complex-example.hk-wrapper .hk-pg-wrapper {
    padding-bottom: 120px;
}

@media (max-width: 1400px) {

    [data-footer="complex"].hk-wrapper .hk-pg-wrapper,
    .footer-complex-example.hk-wrapper .hk-pg-wrapper {
        padding-bottom: 31.875rem;
    }

    [data-footer="complex"] .hk-footer .footer .footer-info-text,
    .footer-complex-example .hk-footer .footer .footer-info-text {
        width: 100%;
    }

    [data-footer="complex"] .hk-footer .footer>.row>.col-xl-6,
    .footer-complex-example .hk-footer .footer>.row>.col-xl-6 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    [data-footer="complex"] .hk-footer .footer>.row>.col-xl-6:last-child,
    .footer-complex-example .hk-footer .footer>.row>.col-xl-6:last-child {
        margin-top: 2.125rem;
    }

    [data-footer="complex"] .hk-footer .footer>.row:last-child,
    .footer-complex-example .hk-footer .footer>.row:last-child {
        margin-top: 2.125rem;
    }
}

@media (max-width: 1199px) {

    [data-footer="simple"] .hk-footer .footer>.row,
    .footer-simple-example .hk-footer .footer>.row {
        height: auto;
    }

    [data-footer="simple"] .hk-footer .footer>.row>.col-xl-6 .col-xl-4,
    .footer-simple-example .hk-footer .footer>.row>.col-xl-6 .col-xl-4 {
        margin-bottom: 1.875rem;
    }

    [data-footer="simple"] .hk-footer .footer>.row>.col-xl-6 .col-xl-4:last-child,
    .footer-simple-example .hk-footer .footer>.row>.col-xl-6 .col-xl-4:last-child {
        margin-bottom: 0;
    }

    [data-footer="simple"] .hk-footer .footer .footer-text,
    .footer-simple-example .hk-footer .footer .footer-text {
        text-align: center;
        margin-bottom: .625rem;
    }

    [data-footer="simple"] .hk-footer .footer .footer-extr-link,
    .footer-simple-example .hk-footer .footer .footer-extr-link {
        justify-content: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
    }

    [data-footer="complex"].hk-wrapper .hk-pg-wrapper,
    .footer-complex-example.hk-wrapper .hk-pg-wrapper {
        padding-bottom: 34.5rem;
    }

    [data-footer="complex"] .hk-footer .footer .footer-social-btn-wrap,
    .footer-complex-example .hk-footer .footer .footer-social-btn-wrap {
        justify-content: flex-start;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: flex-start;
        margin-top: 1.5rem;
    }

    [data-footer="complex"] .hk-footer .footer .footer-social-btn-wrap .btn:first-child,
    [data-footer="complex"] .hk-footer .footer .footer-social-btn-wrap div.hopscotch-bubble .hopscotch-bubble-container .hopscotch-actions .hopscotch-nav-button:first-child,
    div.hopscotch-bubble .hopscotch-bubble-container .hopscotch-actions [data-footer="complex"] .hk-footer .footer .footer-social-btn-wrap .hopscotch-nav-button:first-child,
    .footer-complex-example .hk-footer .footer .footer-social-btn-wrap .btn:first-child,
    .footer-complex-example .hk-footer .footer .footer-social-btn-wrap div.hopscotch-bubble .hopscotch-bubble-container .hopscotch-actions .hopscotch-nav-button:first-child,
    div.hopscotch-bubble .hopscotch-bubble-container .hopscotch-actions .footer-complex-example .hk-footer .footer .footer-social-btn-wrap .hopscotch-nav-button:first-child {
        margin-left: 0;
    }
}

@media (max-width: 767px) {

    [data-footer="complex"].hk-wrapper .hk-pg-wrapper,
    .footer-complex-example.hk-wrapper .hk-pg-wrapper {
        padding-bottom: 63rem;
    }

    [data-footer="complex"] .hk-footer .footer>.row>.col-xl-6 .col-md-4,
    .footer-complex-example .hk-footer .footer>.row>.col-xl-6 .col-md-4 {
        margin-bottom: 1.875rem;
    }

    [data-footer="complex"] .hk-footer .footer>.row>.col-xl-6 .col-md-4:last-child,
    .footer-complex-example .hk-footer .footer>.row>.col-xl-6 .col-md-4:last-child {
        margin-bottom: 0;
    }
}

@media (max-width: 666px) {

    [data-footer="simple"].hk-wrapper .hk-pg-wrapper,
    .footer-simple-example.hk-wrapper .hk-pg-wrapper {
        padding-bottom: 10rem;
    }
}