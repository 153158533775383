/*Media Object*/
.media {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
}

.media-head {
    display: flex;
}

.media-body {
    -ms-flex: 1;
    flex: 1;
}
