
/*Pagination*/
.pagination-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.page-link {
    color: #262a2e;
    border-color: #d8d8d8;
    min-width: 40px;
    min-height: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}

.page-link:focus,
.page-link:hover {
    color: #262a2e;
    box-shadow: none;
    background-color: #eaeaea;
    border-color: #d8d8d8;
}

.pagination .page-item.active .page-link {
    background: #09769F;
    border-color: #09769F;
    color: #fff;
}

.pagination .page-item.disabled .page-link {
    color: #b5b5b5;
}

.pagination .page-item:first-child .page-link {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
}

.pagination .page-item:last-child .page-link {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
}

.pagination.custom-pagination .page-item+.page-item {
    padding-left: .5rem;
    margin: 0;
}

.pagination.custom-pagination .page-link {
    padding: .5rem .8rem;
    border-radius: 0.5rem;
}

.pagination.custom-pagination.pagination-rounded .page-link {
    border-radius: 50rem;
}

.pagination.custom-pagination.pagination-rounded .page-item:first-child .page-link,
.pagination.custom-pagination.pagination-rounded .page-item:last-child .page-link {
    border-radius: 50rem;
}

.pagination.custom-pagination.pagination-filled .page-item:not(.active) .page-link {
    background: #f7f7f7;
    border-color: #f7f7f7;
}

.pagination.custom-pagination.pagination-filled .page-item:not(.active):hover .page-link {
    background-color: #eaeaea;
}

.pagination.custom-pagination.pagination-simple .page-item:not(.active) .page-link {
    border-color: transparent;
}

.pagination.pagination-sm .page-link {
    padding: .3rem .6rem;
    min-height: 30px;
    min-width: 30px;
}