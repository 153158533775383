@charset "UTF-8";

@import url(https://fonts.googleapis.com/icon?family=DM+Sans:400,500,700&display=swap||Material+Icons);

:root {
    --bs-body-font-family: 'DM Sans', sans-serif;
    --bs-text-opacity: 1;
    --bs-bg-opacity: 1;
}

/****************************/
/** Reset **/
/****************************/
html,
body,
div,
span,
applet,
object,
iframe,
p,
pre,
a,
abbr,
acronym,
address,
big,
cite,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
fieldset,
form,
label,
legend,
caption,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
}

em,
cite {
    font-style: italic;
}

strong {
    font-weight: bold;
}

body {
    color: #6f6f6f;
    font-family: var(--bs-body-font-family);
    font-size: var(--bs-body-font-size);
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    overflow-x: hidden;
}

.main-font,
.jq-toast-wrap .jq-toast-single,
.jq-toast-wrap .jq-toast-single .jq-toast-heading,
.infowindow-wrap .infowindow-header,
.infowindow-wrap .infowindow-body {
    font-family: var(--bs-body-font-family);
}

a:hover,
a:active {
    outline: 0;
}

ul {
    list-style: none;
}

ul.list-ul {
    list-style: disc;
}

ul.list-ul ul {
    list-style-type: circle;
}

ul.list-icon {
    list-style: none;
    padding-left: 0;
}

ul.list-icon>li {
    padding-left: 1.875rem;
    counter-increment: li;
}

ul.list-icon>li i,
ul.list-icon>li .feather-icon,
ul.list-icon>li .svg-icon {
    display: inline-block;
    height: 20px;
    width: 20px;
    text-align: center;
    margin-left: -1.25rem;
    position: relative;
    left: -10px;
    padding: 1px;
    top: -1px;
}

ul.list-icon>li i {
    font-size: 21px;
    top: 5px;
}

ul.hk-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0 -.68rem;
    padding-left: 0;
}

ul.hk-list li {
    padding: .68rem;
}

ul.hk-list.hk-list-sm {
    margin: 0 -.4rem;
}

ul.hk-list.hk-list-sm li {
    padding: .4rem;
}

a {
    text-decoration: none;
    color: #09769F;
    -webkit-transition: color 0.2s ease;
    -moz-transition: color 0.2s ease;
    transition: color 0.2s ease;
}

a:hover {
    text-decoration: none;
    color: #3d9ca5;
}

a:focus {
    text-decoration: none;
    outline: none;
    color: #3d9ca5;
}

a.link-info {
    color: #18DDEF !important;
}

a.link-info:hover,
a.link-info:focus {
    color: #4fe5f3 !important;
}

a.link-success {
    color: #00D67F !important;
}

a.link-success:hover,
a.link-success:focus {
    color: #3de09e !important;
}

a.link-warning {
    color: #FFC400 !important;
}

a.link-warning:hover,
a.link-warning:focus {
    color: #ffd23d !important;
}

a.link-danger {
    color: #FF0000 !important;
}

a.link-danger:hover,
a.link-danger:focus {
    color: #ff3d3d !important;
}

a.link-primary {
    color: #09769F !important;
}

a.link-primary:hover,
a.link-primary:focus {
    color: #3d9ca5 !important;
}

a.link-muted {
    color: #6f6f6f !important;
}

a.link-muted:hover,
a.link-muted:focus {
    color: #b5b5b5 !important;
}

a.link-secondary {
    color: #6f6f6f !important;
}

a.link-secondary:hover,
a.link-secondary:focus {
    color: #929292 !important;
}

a.link-dark {
    color: #262a2e !important;
}

a.link-dark:hover,
a.link-dark:focus {
    color: #2a2f34 !important;
}

a.link-light {
    color: #9e9e9e !important;
}

a.link-light:hover,
a.link-light:focus {
    color: #b5b5b5 !important;
}

a.link-white {
    color: #fff !important;
}

a.link-white:hover,
a.link-white:focus {
    color: rgba(255, 255, 255, 0.8);
}

a.link-disabled {
    color: #b5b5b5 !important;
    pointer-events: none;
    user-select: none;
}

a.link-disabled:hover,
a.link-disabled:focus {
    color: #b5b5b5 !important;
}

a.link-high-em {
    font-weight: 500;
}

a.link-medium-em {
    font-size: .875rem;
}

a.link-low-em {
    font-size: .75rem;
}



.heading-wth-icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
}

.heading-wth-icon .head-icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.heading-wth-icon .head-icon i {
    line-height: 0;
}

.h1.heading-wth-icon .head-icon,
h1.heading-wth-icon .head-icon {
    margin-right: 1.125rem;
}

@media (max-width: 1199px) {

    .h1.heading-wth-icon .head-icon,
    h1.heading-wth-icon .head-icon {
        margin-right: 1.125rem;
    }
}

.h2.heading-wth-icon .head-icon,
h2.heading-wth-icon .head-icon {
    margin-right: 1rem;
}

@media (max-width: 1199px) {

    .h2.heading-wth-icon .head-icon,
    h2.heading-wth-icon .head-icon {
        margin-right: 1.125rem;
    }
}

.h3.heading-wth-icon .head-icon,
h3.heading-wth-icon .head-icon {
    margin-right: .875rem;
}

@media (max-width: 1199px) {

    .h3.heading-wth-icon .head-icon,
    h3.heading-wth-icon .head-icon {
        margin-right: 1.125rem;
    }
}

.h4.heading-wth-icon .head-icon,
h4.heading-wth-icon .head-icon {
    margin-right: .75rem;
}

.h5.heading-wth-icon .head-icon,
h5.heading-wth-icon .head-icon {
    margin-right: .625rem;
}

.h6.heading-wth-icon .head-icon,
h6.heading-wth-icon .head-icon {
    margin-right: .5rem;
}

.force-UTF-8 {
    content: "¡";
}

dt {
    font-weight: 600;
}

::-webkit-file-upload-button {
    cursor: pointer;
}

/****************************/
/** Basic **/
/****************************/
/*CSS Basic*/
.overflow-y-scroll {
    overflow-y: scroll;
}

.overflow-x-scroll {
    overflow-x: scroll;
}

.white-space-wrap {
    white-space: initial;
}

.white-space-nowrap {
    white-space: nowrap;
}

.word-break,
a.link-url {
    word-break: break-all;
}

.multine-ellipsis {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
}

.seprator-block {
    margin-bottom: 5rem;
}

.no-transition {
    -webkit-transition: 0s;
    -moz-transition: 0s;
    transition: 0s;
}

.transform-none {
    transform: none !important;
}

.name {
    color: #262a2e !important;
    font-weight: 500;
}

.twa-2x {
    background-size: 1.3em 1.3em;
    height: 1.3em;
    width: 1.3em;
}

.flex-basis-0 {
    flex-basis: 0 !important;
}

.flex-1 {
    -ms-flex: 1 !important;
    flex: 1 !important;
}

td.text-truncate,
th.text-truncate {
    max-width: 1px;
}

[contenteditable="true"] {
    outline: none;
}

.icon-flip-y {
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
    transform: rotateY(180deg);
}

.icon-flip-x {
    -webkit-transform: rotateX(180deg);
    -moz-transform: rotateX(180deg);
    -ms-transform: rotateX(180deg);
    -o-transform: rotateX(180deg);
    transform: rotateX(180deg);
}

