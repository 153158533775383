

/*Typography*/
p.p-xl {
    font-size: 1.25rem;
}

p.p-lg {
    font-size: 1.125rem;
}

p.p-sm {
    font-size: 0.875rem;
}

p.p-xs {
    font-size: 0.75rem;
}

.title,
.title-lg,
.title-sm,
.title-xs {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    margin-bottom: .5rem;
}

.title .title-icon,
.title-lg .title-icon,
.title-sm .title-icon,
.title-xs .title-icon {
    margin-right: .5rem;
    display: inline-flex;
}

.title .title-icon i,
.title-lg .title-icon i,
.title-sm .title-icon i,
.title-xs .title-icon i {
    line-height: 0;
}

.title.title-wth-divider,
.title-lg.title-wth-divider,
.title-sm.title-wth-divider,
.title-xs.title-wth-divider {
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
}

.title.title-wth-divider>span,
.title-lg.title-wth-divider>span,
.title-sm.title-wth-divider>span,
.title-xs.title-wth-divider>span {
    display: flex;
    white-space: nowrap;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
}

.title.title-wth-divider::before,
.title-lg.title-wth-divider::before,
.title-sm.title-wth-divider::before,
.title-xs.title-wth-divider::before {
    content: "";
    display: block;
    height: 1px;
    width: 0;
    background: #d8d8d8;
}

.title.title-wth-divider::after,
.title-lg.title-wth-divider::after,
.title-sm.title-wth-divider::after,
.title-xs.title-wth-divider::after {
    content: "";
    display: block;
    height: 1px;
    width: 100%;
    margin-left: .625rem;
    background: #d8d8d8;
}

.title.title-wth-divider.divider-right::before,
.title-lg.title-wth-divider.divider-right::before,
.title-sm.title-wth-divider.divider-right::before,
.title-xs.title-wth-divider.divider-right::before {
    width: 100%;
    margin-right: .625rem;
}

.title.title-wth-divider.divider-right::after,
.title-lg.title-wth-divider.divider-right::after,
.title-sm.title-wth-divider.divider-right::after,
.title-xs.title-wth-divider.divider-right::after {
    width: 0;
    margin: 0;
}

.title.title-wth-divider.divider-center::before,
.title-lg.title-wth-divider.divider-center::before,
.title-sm.title-wth-divider.divider-center::before,
.title-xs.title-wth-divider.divider-center::before {
    width: 100%;
    margin-right: .625rem;
}

.title.title-wth-divider.divider-center::after,
.title-lg.title-wth-divider.divider-center::after,
.title-sm.title-wth-divider.divider-center::after,
.title-xs.title-wth-divider.divider-center::after {
    width: 100%;
    margin-left: .625rem;
}

.title {
    font-size: 1rem;
    color: #262a2e;
    font-weight: 500;
}

.title .title-icon {
    font-size: 1.5rem;
}

.title-lg {
    font-size: 1.125rem;
    color: #262a2e;
    font-weight: 500;
}

.title-lg .title-icon {
    font-size: 1.75rem;
}

.title-sm {
    font-size: 0.875rem;
    color: #262a2e;
    font-weight: 500;
}

.title-sm .title-icon {
    font-size: 1.25rem;
}

.title-xs {
    font-size: 0.75rem;
    color: #262a2e;
    font-weight: 500;
}

.title-xs .title-icon {
    font-size: 1rem;
}

.small,
small {
    font-size: 80%;
    font-weight: 400;
}

.hidden-element {
    display: none !important;
}

code {
    margin: 0 .25rem;
    color: #09769F;
}

.strikethrough s,
.strikethrough del {
    position: relative;
    text-decoration: none;
}

.strikethrough s:after,
.strikethrough del:after {
    content: "";
    height: 12px;
    background: #09769F;
    mix-blend-mode: multiply;
    opacity: .3;
    width: 100%;
    position: absolute;
    margin-left: 0;
    top: 5px;
    left: 0;
    pointer-events: none;
}

.strikethrough del:after {
    background: #FF0000;
}

.mark,
mark {
    padding: 0;
    background: none;
    color: #262a2e;
    position: relative;
}

.mark:after,
mark:after {
    content: "";
    height: 12px;
    background: #FF9100;
    mix-blend-mode: multiply;
    opacity: .3;
    width: 100%;
    position: absolute;
    margin-left: 0;
    top: 5px;
    left: 0;
    pointer-events: none;
}

.display-1,
.display-2,
.display-3,
.display-4,
.display-5,
.display-6 {
    color: #262a2e;
}

.hk-dragger {
    font-size: 2rem;
    cursor: grab;
    color: #9e9e9e;
    margin-right: .625rem;
}

/*Hr*/
hr {
    background: #eaeaea;
}

hr.hr-2 {
    height: 2px !important;
}

hr.hr-3 {
    height: 3px !important;
}



/*Placehoder*/
.placeholder {
    border-radius: 0.25rem;
}

/*Gap*/
.gap-5 {
    gap: 2rem !important;
}

.gap-6 {
    gap: 2.5rem !important;
}

.gap-7 {
    gap: 3rem !important;
}
