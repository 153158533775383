/*Advance List*/
.advance-list {
    padding-left: 0;
}

.advance-list>li {
    margin-bottom: 1.25rem;
}

.advance-list>li:last-child {
    margin-bottom: 0;
}

.advance-list-item {
    padding: .5rem 1.25rem;
    min-height: 50px;
    border: 1px solid #d8d8d8;
    border-radius: 0.5rem;
    list-style: none;
    background: #fff;
    position: relative;
    -webkit-transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    -moz-transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.advance-list-item:hover {
    -webkit-transform: scale(1.02, 1.02);
    -moz-transform: scale(1.02, 1.02);
    -ms-transform: scale(1.02, 1.02);
    -o-transform: scale(1.02, 1.02);
    transform: scale(1.02, 1.02);
    z-index: 1;
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

.advance-list-item.drp-open {
    z-index: 4;
}