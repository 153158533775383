
/*Separator*/
.separator {
    padding: 1px 0;
    margin: 1.25rem 0;
}

.separator:after {
    content: "";
    display: block;
    width: 100%;
    border-bottom: 1px solid #eaeaea;
}

.separator-full {
    padding: 1px 0;
    margin: 1.25rem 0;
}

.separator-full:after {
    content: "";
    display: block;
    position: absolute;
    height: 1px;
    width: 100%;
    left: 0;
    border-bottom: 1px solid #eaeaea;
}

.v-separator-full {
    margin: 0 1rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.v-separator-full:after {
    content: "";
    position: absolute;
    top: 0;
    display: block;
    height: 100%;
    border-right: 1px solid #eaeaea;
}

.v-separator {
    margin: 0 1rem;
}

.v-separator:after {
    content: "";
    position: absolute;
    display: block;
    height: calc(100% - 30px);
    border-right: 1px solid #d8d8d8;
    top: 0;
    bottom: 0;
    margin: auto;
}

.separator-light:after {
    border-color: #f7f7f7;
}