
/*------------------------------------------------------------------
[Master Stylesheet]
Project:	Jampack
++++ Father Icon ++++
++++ ChatApp ++++
++++ Chat Popup ++++
++++ Chatbot Popup ++++
++++ Calendar ++++
++++ Email UI ++++
++++ Taskboard ++++
++++ Checklist List ++++
++++ Contact ++++
++++ File Manager ++++
++++ Gallery App ++++
++++ Todo ++++
++++ Blog App ++++
++++ invoice ++++
++++ Integrations ++++
-------------------------------------------------------------------*/

/* Father Icon */
.feather {
	 stroke: currentColor;
	 stroke-width: 2;
	 stroke-linecap: round;
	 stroke-linejoin: round;
	 fill: none;
}
 .svg-icon {
	 line-height: 0 !important;
}
 .feather-icon {
	 line-height: 0 !important;
}
 .feather-icon.fe-x > svg {
	 height: 16px;
	 width: 16px;
}
 .feather-icon.fe-2x > svg {
	 height: 32px;
	 width: 32px;
}
 .feather-icon > svg, .svg-icon > svg {
	 height: 1em;
	 width: 1em;
}
 .flag-icon {
	 height: 20px;
	 width: 20px;
}
 
/* // Father icon */

/*ChatApp*/
.chatapp-wrap {
    position: relative;
    overflow: hidden;
    height: calc(100vh - 65px);
    -webkit-transition: height 0.2s ease;
    -moz-transition: height 0.2s ease;
    transition: height 0.2s ease;
}

.chatapp-wrap .chatapp-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    position: relative;
    overflow: hidden;
    left: 0;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.chatapp-wrap .chatapp-content .chatapp-aside {
    border-right: 1px solid #eaeaea;
    background: #fff;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-flow: column;
    height: 100%;
    z-index: 1;
    left: 0;
    margin-top: 0;
    width: 370px;
    flex-shrink: 0;
}

.chatapp-wrap .chatapp-content .chatapp-aside header.aside-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
    height: 65px;
    padding: 0 1.25rem;
    border-bottom: 1px solid #eaeaea;
    background: #fff;
    color: #262a2e;
}

.chatapp-wrap .chatapp-content .chatapp-aside header.aside-header .chatapp-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.chatapp-wrap .chatapp-content .chatapp-aside header.aside-header .chatapp-title h1 {
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 0;
}

.chatapp-wrap .chatapp-content .chatapp-aside header.aside-header .chatapp-title:after {
    top: 0;
}

.chatapp-wrap .chatapp-content .chatapp-aside header.aside-header .btn,
.chatapp-wrap .chatapp-content .chatapp-aside header.aside-header div.hopscotch-bubble .hopscotch-bubble-container .hopscotch-actions .hopscotch-nav-button,
div.hopscotch-bubble .hopscotch-bubble-container .hopscotch-actions .chatapp-wrap .chatapp-content .chatapp-aside header.aside-header .hopscotch-nav-button {
    margin-right: .25rem;
}

.chatapp-wrap .chatapp-content .chatapp-aside header.aside-header .btn:last-child,
.chatapp-wrap .chatapp-content .chatapp-aside header.aside-header div.hopscotch-bubble .hopscotch-bubble-container .hopscotch-actions .hopscotch-nav-button:last-child,
div.hopscotch-bubble .hopscotch-bubble-container .hopscotch-actions .chatapp-wrap .chatapp-content .chatapp-aside header.aside-header .hopscotch-nav-button:last-child {
    margin-right: 0;
}

.chatapp-wrap .chatapp-content .chatapp-aside .aside-body {
    -ms-flex: 1;
    flex: 1;
    height: 100%;
    overflow: auto;
    padding: 1.25rem;
}

.chatapp-wrap .chatapp-content .chatapp-aside .aside-body .aside-search {
    margin-bottom: 1.25rem;
}

.chatapp-wrap .chatapp-content .chatapp-aside .aside-body .frequent-contact {
    margin-bottom: 1.25rem;
}

.chatapp-wrap .chatapp-content .chatapp-aside .aside-body .frequent-contact ul li .avatar {
    cursor: pointer;
}

.chatapp-wrap .chatapp-content .chatapp-aside .aside-body .chat-contacts-list {
    margin: 0 -0.8rem;
}

.chatapp-wrap .chatapp-content .chatapp-aside .aside-body .chat-contacts-list .list-group-item {
    padding: 0;
    border: none;
    margin-bottom: .25rem;
}

.chatapp-wrap .chatapp-content .chatapp-aside .aside-body .chat-contacts-list .list-group-item:last-child {
    margin-bottom: 0;
}

.chatapp-wrap .chatapp-content .chatapp-aside .aside-body .chat-contacts-list .list-group-item .media {
    padding: .75rem .8rem;
    border-radius: 0.5rem;
    -webkit-transition: 0.2s ease;
    -moz-transition: 0.2s ease;
    transition: 0.2s ease;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    cursor: pointer;
}

.chatapp-wrap .chatapp-content .chatapp-aside .aside-body .chat-contacts-list .list-group-item .media .media-head {
    position: relative;
    margin-right: 1rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.chatapp-wrap .chatapp-content .chatapp-aside .aside-body .chat-contacts-list .list-group-item .media .media-body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    min-width: 0;
}

.chatapp-wrap .chatapp-content .chatapp-aside .aside-body .chat-contacts-list .list-group-item .media .media-body>div:first-child {
    min-width: 0;
    flex: 1;
}

.chatapp-wrap .chatapp-content .chatapp-aside .aside-body .chat-contacts-list .list-group-item .media .media-body>div:first-child .user-name,
.chatapp-wrap .chatapp-content .chatapp-aside .aside-body .chat-contacts-list .list-group-item .media .media-body>div:first-child .user-last-chat,
.chatapp-wrap .chatapp-content .chatapp-aside .aside-body .chat-contacts-list .list-group-item .media .media-body>div:first-child .user-status {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.chatapp-wrap .chatapp-content .chatapp-aside .aside-body .chat-contacts-list .list-group-item .media .media-body>div:first-child .user-name {
    color: #262a2e;
    font-weight: 500;
    margin-bottom: .25rem;
}

.chatapp-wrap .chatapp-content .chatapp-aside .aside-body .chat-contacts-list .list-group-item .media .media-body>div:last-child {
    text-align: right;
    padding-left: 1rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
    -webkit-box-align: flex-end;
    -ms-flex-align: flex-end;
    align-items: flex-end;
    width: 4.5rem;
}

.chatapp-wrap .chatapp-content .chatapp-aside .aside-body .chat-contacts-list .list-group-item .media .media-body>div:last-child .last-chat-time {
    font-size: 0.75rem;
    color: #09769F;
}

.chatapp-wrap .chatapp-content .chatapp-aside .aside-body .chat-contacts-list .list-group-item .media .media-body>div:last-child .badge {
    margin-top: .25rem;
}

.chatapp-wrap .chatapp-content .chatapp-aside .aside-body .chat-contacts-list .list-group-item .media .media-body>div:last-child .action-drp {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 15px;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.chatapp-wrap .chatapp-content .chatapp-aside .aside-body .chat-contacts-list .list-group-item .media:hover,
.chatapp-wrap .chatapp-content .chatapp-aside .aside-body .chat-contacts-list .list-group-item .media:focus {
    background-color: #f2f2f2;
}

.chatapp-wrap .chatapp-content .chatapp-aside .aside-body .chat-contacts-list .list-group-item .media:hover .media-body>div:last-child .action-drp,
.chatapp-wrap .chatapp-content .chatapp-aside .aside-body .chat-contacts-list .list-group-item .media:focus .media-body>div:last-child .action-drp {
    opacity: 1;
    visibility: visible;
}

.chatapp-wrap .chatapp-content .chatapp-aside .aside-body .chat-contacts-list .list-group-item .media:hover .media-body>div:last-child .last-chat-time,
.chatapp-wrap .chatapp-content .chatapp-aside .aside-body .chat-contacts-list .list-group-item .media:hover .media-body>div:last-child .badge-pill,
.chatapp-wrap .chatapp-content .chatapp-aside .aside-body .chat-contacts-list .list-group-item .media:focus .media-body>div:last-child .last-chat-time,
.chatapp-wrap .chatapp-content .chatapp-aside .aside-body .chat-contacts-list .list-group-item .media:focus .media-body>div:last-child .badge-pill {
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.chatapp-wrap .chatapp-content .chatapp-aside .aside-body .chat-contacts-list .list-group-item .media.read-chat .media-body>div:last-child .last-chat-time {
    color: #6f6f6f;
}

.chatapp-wrap .chatapp-content .chatapp-aside .aside-body .chat-contacts-list .list-group-item .media.read-chat.active-user {
    background-color: #f2f2f2;
}

.chatapp-wrap .chatapp-content .chatapp-aside .aside-body .chat-contacts-list .list-group-item .media.read-chat.active-user .media-body>div:first-child .user-name {
    color: #09769F;
}

.chatapp-wrap .chatapp-content .chatapp-aside .aside-body .chat-contacts-list .title-wth-divider {
    padding: 0 .8rem;
}

.chatapp-wrap .chatapp-content .chatapp-aside .aside-body .chat-contacts-list .title-wth-divider:after {
    background: #f7f7f7;
}

.chatapp-wrap .chatapp-content .chatapp-aside .aside-body .channels-list .nav-item .nav-link {
    padding-left: 0;
    padding-right: 0;
}

.chatapp-wrap .chatapp-content .chatapp-single-chat {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-grow: 1;
    flex-flow: column;
    height: 100%;
    background: #fff;
    z-index: 10;
}

.chatapp-wrap .chatapp-content .chatapp-single-chat header.chat-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
    height: 65px;
    padding: 0 1.25rem;
    background: #fff;
    border-bottom: 1px solid #eaeaea;
}

.chatapp-wrap .chatapp-content .chatapp-single-chat header.chat-header .back-user-list {
    display: none;
    margin-right: .625rem;
}

.chatapp-wrap .chatapp-content .chatapp-single-chat header.chat-header .media {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.chatapp-wrap .chatapp-content .chatapp-single-chat header.chat-header .media .media-head {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-right: 1rem;
}

.chatapp-wrap .chatapp-content .chatapp-single-chat header.chat-header .media .media-body .user-name {
    font-size: 1rem;
    font-weight: 500;
    color: #262a2e;
    text-transform: capitalize;
}

.chatapp-wrap .chatapp-content .chatapp-single-chat header.chat-header .media .media-body .user-status {
    font-size: 0.875rem;
}

.chatapp-wrap .chatapp-content .chatapp-single-chat header.chat-header .media .media-body .user-name,
.chatapp-wrap .chatapp-content .chatapp-single-chat header.chat-header .media .media-body .user-status {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 150px;
}

.chatapp-wrap .chatapp-content .chatapp-single-chat header.chat-header .chat-options-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.chatapp-wrap .chatapp-content .chatapp-single-chat header.chat-header .chat-options-wrap>a {
    margin-left: .25rem;
}

.chatapp-wrap .chatapp-content .chatapp-single-chat .chat-body {
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
    -ms-flex: 1;
    flex: 1;
    height: 100%;
    overflow: auto;
}

.chatapp-wrap .chatapp-content .chatapp-single-chat .chat-body ul.list-unstyled {
    width: 90%;
    margin: 0 auto;
    padding: 2rem 1.25rem;
}

.chatapp-wrap .chatapp-content .chatapp-single-chat .chat-body .media .media-body {
    margin-left: 0.75rem;
}

.chatapp-wrap .chatapp-content .chatapp-single-chat .chat-body .media .media-body .msg-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.chatapp-wrap .chatapp-content .chatapp-single-chat .chat-body .media .media-body .msg-box>div {
    padding: .625rem 1rem;
    border-radius: 1.25rem;
    display: inline-block;
    position: relative;
}

.chatapp-wrap .chatapp-content .chatapp-single-chat .chat-body .media .media-body .msg-box>div p:first-child {
    color: #262a2e;
}

.chatapp-wrap .chatapp-content .chatapp-single-chat .chat-body .media .media-body .msg-box>div .chat-time {
    display: block;
    text-transform: uppercase;
    font-size: 0.75rem;
    margin-top: .25rem;
    opacity: .5;
}

.chatapp-wrap .chatapp-content .chatapp-single-chat .chat-body .media .media-body .msg-box+.msg-box {
    margin-top: .25rem;
}

.chatapp-wrap .chatapp-content .chatapp-single-chat .chat-body .media .media-body .msg-box .msg-action {
    margin-left: 1rem;
    opacity: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 0;
    background: transparent !important;
}

.chatapp-wrap .chatapp-content .chatapp-single-chat .chat-body .media .media-body .msg-box .msg-action:after {
    display: none;
}

.chatapp-wrap .chatapp-content .chatapp-single-chat .chat-body .media .media-body .msg-box:hover .msg-action {
    opacity: 1;
}

.chatapp-wrap .chatapp-content .chatapp-single-chat .chat-body .media .media-body.msg-docs {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.chatapp-wrap .chatapp-content .chatapp-single-chat .chat-body .media .media-body.msg-docs .msg-box>div {
    cursor: pointer;
}

.chatapp-wrap .chatapp-content .chatapp-single-chat .chat-body .media .media-body.msg-docs .msg-box>div .media {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.chatapp-wrap .chatapp-content .chatapp-single-chat .chat-body .media .media-body.msg-docs .msg-box>div .media .file-name {
    color: #262a2e;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 165px;
}

.chatapp-wrap .chatapp-content .chatapp-single-chat .chat-body .media .media-body.msg-docs .msg-box>div .media .file-size {
    font-size: 0.875rem;
    margin-top: .25rem;
}

.chatapp-wrap .chatapp-content .chatapp-single-chat .chat-body .media .media-body.msg-docs .msg-box>div .file-overlay {
    position: absolute;
    opacity: 0;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    color: #fff;
    background: rgba(38, 42, 46, 0.4);
    border-radius: 1.25rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
}

.chatapp-wrap .chatapp-content .chatapp-single-chat .chat-body .media .media-body.msg-docs .msg-box>div:hover .file-overlay {
    opacity: 1;
}

.chatapp-wrap .chatapp-content .chatapp-single-chat .chat-body .media .media-body.msg-docs .msg-box>div.msg-action:after {
    display: none;
}

.chatapp-wrap .chatapp-content .chatapp-single-chat .chat-body .media .media-body.msg-docs .msg-box+.msg-box {
    margin-top: 0;
}

.chatapp-wrap .chatapp-content .chatapp-single-chat .chat-body .media .media-body.msg-imgs .msg-box>div {
    padding: 0;
}

.chatapp-wrap .chatapp-content .chatapp-single-chat .chat-body .media .media-body.msg-imgs .msg-box>div img {
    border-radius: 1.25rem;
    max-width: 336px;
}

.chatapp-wrap .chatapp-content .chatapp-single-chat .chat-body .media .media-body.msg-imgs .msg-box:first-child>div,
.chatapp-wrap .chatapp-content .chatapp-single-chat .chat-body .media .media-body.msg-imgs .msg-box:last-child>div {
    border-radius: 1.25rem !important;
}

.chatapp-wrap .chatapp-content .chatapp-single-chat .chat-body .media.received {
    margin-bottom: 1.25rem;
}

.chatapp-wrap .chatapp-content .chatapp-single-chat .chat-body .media.received .media-body .msg-box>div {
    background: #f2f2f2;
}

.chatapp-wrap .chatapp-content .chatapp-single-chat .chat-body .media.received .media-body .msg-box:first-child>div {
    border-top-left-radius: 0;
}

.chatapp-wrap .chatapp-content .chatapp-single-chat .chat-body .media.sent {
    margin-bottom: 1.25rem;
}

.chatapp-wrap .chatapp-content .chatapp-single-chat .chat-body .media.sent .media-body {
    margin-left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: flex-end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: flex-end;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: flex-end;
    -ms-flex-align: flex-end;
    align-items: flex-end;
}

.chatapp-wrap .chatapp-content .chatapp-single-chat .chat-body .media.sent .media-body .msg-box {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
}

.chatapp-wrap .chatapp-content .chatapp-single-chat .chat-body .media.sent .media-body .msg-box>div {
    background: #09769F;
}

.chatapp-wrap .chatapp-content .chatapp-single-chat .chat-body .media.sent .media-body .msg-box>div p {
    color: #fff;
}

.chatapp-wrap .chatapp-content .chatapp-single-chat .chat-body .media.sent .media-body .msg-box>div .chat-time {
    color: #fff;
}

.chatapp-wrap .chatapp-content .chatapp-single-chat .chat-body .media.sent .media-body .msg-box:first-child>div {
    border-top-right-radius: 0;
}

.chatapp-wrap .chatapp-content .chatapp-single-chat .chat-body .media.sent .media-body .msg-box .msg-action {
    margin-left: 0;
    margin-right: 1rem;
}

.chatapp-wrap .chatapp-content .chatapp-single-chat .chat-body .media.media-attachment {
    margin-bottom: 1.125rem;
}

.chatapp-wrap .chatapp-content .chatapp-single-chat .chat-body .media.media-attachment .media-body>.msg-box {
    margin-bottom: .25rem;
    margin-right: .25rem;
}

.chatapp-wrap .chatapp-content .chatapp-single-chat .chat-body .day-sep {
    position: relative;
    text-align: center;
    overflow: hidden;
    font-size: 0.875rem;
    margin: 1.875rem 0;
    text-transform: capitalize;
}

.chatapp-wrap .chatapp-content .chatapp-single-chat .chat-body .day-sep:before {
    background: #eaeaea;
    content: "";
    height: 1px;
    right: 50%;
    position: absolute;
    top: 50%;
    width: 100%;
}

.chatapp-wrap .chatapp-content .chatapp-single-chat .chat-body .day-sep:after {
    background: #eaeaea;
    content: "";
    height: 1px;
    left: 50%;
    position: absolute;
    top: 50%;
    width: 100%;
}

.chatapp-wrap .chatapp-content .chatapp-single-chat .chat-body .day-sep span {
    display: inline-block;
    background: #fff;
    z-index: 1;
    position: relative;
    padding: 0 .625rem;
}

.chatapp-wrap .chatapp-content .chatapp-single-chat .chat-body .start-conversation {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 195px);
    flex-flow: column;
}

.chatapp-wrap .chatapp-content .chatapp-single-chat footer.chat-footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0.75rem 1.25rem;
    background: #fff;
    border-top: 1px solid #eaeaea;
    margin-top: -1px;
    position: relative;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.chatapp-wrap .chatapp-content .chatapp-single-chat footer.chat-footer .feather-icon,
.chatapp-wrap .chatapp-content .chatapp-single-chat footer.chat-footer .svg-icon {
    display: block;
}

.chatapp-wrap .chatapp-content .chatapp-single-chat footer.chat-footer .input-group {
    width: 100%;
}

.chatapp-wrap .chatapp-content .chatapp-single-chat footer.chat-footer .input-group .form-control,
.chatapp-wrap .chatapp-content .chatapp-single-chat footer.chat-footer .input-group .form-select,
.chatapp-wrap .chatapp-content .chatapp-single-chat footer.chat-footer .input-group .wizard>.content>.body input,
.wizard>.content>.body .chatapp-wrap .chatapp-content .chatapp-single-chat footer.chat-footer .input-group input,
.chatapp-wrap .chatapp-content .chatapp-single-chat footer.chat-footer .input-group .dd-handle {
    margin: 0 0.75rem;
}

.chatapp-wrap .chatapp-content .chatapp-single-chat .chat-info {
    position: absolute;
    top: 65px;
    right: -370px;
    width: 370px;
    margin-left: 0;
    bottom: 0;
    z-index: 100;
    border: none;
    background: #fff;
    border-left: 1px solid #eaeaea;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.chatapp-wrap .chatapp-content .chatapp-single-chat .chat-info .nicescroll-bar {
    padding: 1.25rem;
}

.chatapp-wrap .chatapp-content .chatapp-single-chat .chat-info .cp-name {
    font-size: 1.25rem;
    font-weight: 500;
    color: #262a2e;
}

.chatapp-wrap .chatapp-content .chatapp-single-chat .chat-info .cp-info {
    padding-left: 0;
}

.chatapp-wrap .chatapp-content .chatapp-single-chat .chat-info .cp-info li {
    margin-bottom: .625rem;
}

.chatapp-wrap .chatapp-content .chatapp-single-chat .chat-info .cp-info li a {
    color: #262a2e;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.chatapp-wrap .chatapp-content .chatapp-single-chat .chat-info .cp-info li a .cp-icon-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-right: .5rem;
}

.chatapp-wrap .chatapp-content .chatapp-single-chat .chat-info .cp-info li a .cp-icon-wrap .feather-icon,
.chatapp-wrap .chatapp-content .chatapp-single-chat .chat-info .cp-info li a .cp-icon-wrap .svg-icon {
    font-size: 1.125rem;
}

.chatapp-wrap .chatapp-content .chatapp-single-chat .chat-info .cp-action {
    padding-left: 0;
}

.chatapp-wrap .chatapp-content .chatapp-single-chat .chat-info .cp-action li {
    margin-bottom: .625rem;
}

.chatapp-wrap .chatapp-content .chatapp-single-chat .chat-info .cp-action li a {
    color: #262a2e;
}

.chatapp-wrap .chatapp-content .chatapp-single-chat .chat-info .cp-action li:last-child {
    margin-bottom: .25rem;
}

.chatapp-wrap .chatapp-content .chatapp-single-chat .chat-info .cp-files {
    margin: 0 -.8rem;
    padding-left: 0;
}

.chatapp-wrap .chatapp-content .chatapp-single-chat .chat-info .cp-files li {
    padding: .8rem;
    border-radius: 0.5rem;
    -webkit-transition: 0.2s ease;
    -moz-transition: 0.2s ease;
    transition: 0.2s ease;
    margin-bottom: .25rem;
}

.chatapp-wrap .chatapp-content .chatapp-single-chat .chat-info .cp-files li:last-child {
    margin-bottom: 0;
}

.chatapp-wrap .chatapp-content .chatapp-single-chat .chat-info .cp-files li .media {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.chatapp-wrap .chatapp-content .chatapp-single-chat .chat-info .cp-files li .media .media-head {
    margin-right: 1rem;
}

.chatapp-wrap .chatapp-content .chatapp-single-chat .chat-info .cp-files li .media .media-head .avatar.avatar-sm .avatar-icon {
    font-size: 1.75rem;
}

.chatapp-wrap .chatapp-content .chatapp-single-chat .chat-info .cp-files li .media .media-body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-width: 0;
}

.chatapp-wrap .chatapp-content .chatapp-single-chat .chat-info .cp-files li .media .media-body>div:first-child {
    min-width: 0;
    -ms-flex: 1;
    flex: 1;
}

.chatapp-wrap .chatapp-content .chatapp-single-chat .chat-info .cp-files li .media .media-body .file-name {
    color: #262a2e;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 500;
}

.chatapp-wrap .chatapp-content .chatapp-single-chat .chat-info .cp-files li .media .media-body .file-size {
    margin-top: .25rem;
    font-size: 0.875rem;
}

.chatapp-wrap .chatapp-content .chatapp-single-chat .chat-info .cp-files li:hover {
    background-color: #f2f2f2;
}

.chatapp-wrap .chatapp-content .chatapp-single-chat .chat-info .cp-files li:hover .media .media-body .file-name {
    color: #09769F;
}

.chatapp-wrap .chatapp-content .chatapp-single-chat .chat-info .cp-files li:hover .media .media-body a.cp-file-action {
    color: #262a2e;
}

.chatapp-wrap.chatapp-info-active .chatapp-single-chat .chat-body {
    margin-right: 370px;
}

.chatapp-wrap.chatapp-info-active .chatapp-single-chat .chat-info {
    right: 0;
}

.chatapp-wrap.chatapp-info-active .chatapp-single-chat footer.chat-footer {
    margin-right: 370px;
}

.chatapp-wrap .chatapp-call-window .modal-content {
    min-height: calc(100vh - 100px);
}

.chatapp-wrap .chatapp-call-window .modal-content .modal-header {
    Padding: 0.3rem 1.25rem;
}

.chatapp-wrap .chatapp-call-window .modal-content .modal-header .modal-title {
    text-align: center;
    width: 100%;
    font-size: 0.875rem;
}

.chatapp-wrap .chatapp-call-window .modal-content .modal-header>.modal-action {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    right: 1.25rem;
    position: absolute;
}

.chatapp-wrap .chatapp-call-window .modal-content .modal-body {
    display: flex;
    align-items: center;
    flex-flow: column;
    justify-content: center;
    padding: 3.125rem;
}

.chatapp-wrap .chatapp-call-window .modal-content .modal-footer {
    border-top: 0;
    min-height: 160px;
    justify-content: center;
}

.chatapp-wrap .chatapp-call-window .modal-content .modal-footer .chatapp-caller-img {
    right: 30px;
    bottom: 30px;
    position: absolute;
}

.chatapp-wrap .chatapp-call-window .modal-content.fullscreen {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    overflow-y: auto;
    border-radius: 0;
}

.chatapp-wrap .chatapp-call-window .modal-content.fullscreen .modal-header {
    border-radius: 0;
}

.chatapp-wrap .invite-user-list {
    padding: 0 1rem;
}

.chatapp-wrap .invite-user-list>li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
    margin-bottom: .625rem;
}

.chatapp-wrap .invite-user-list>li .user-name {
    color: #262a2e;
    font-weight: 500;
}

.hk-wrapper[data-navbar-style="collapsed"] .chatapp-wrap {
    height: 100vh;
}

.one {
    opacity: 0;
    -webkit-animation: dot 1.3s infinite;
    -webkit-animation-delay: 0.0s;
    animation: dot 1.3s infinite;
    animation-delay: 0.0s;
}

.two {
    opacity: 0;
    -webkit-animation: dot 1.3s infinite;
    -webkit-animation-delay: 0.2s;
    animation: dot 1.3s infinite;
    animation-delay: 0.2s;
}

.three {
    opacity: 0;
    -webkit-animation: dot 1.3s infinite;
    -webkit-animation-delay: 0.3s;
    animation: dot 1.3s infinite;
    animation-delay: 0.3s;
}

@-webkit-keyframes dot {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes dot {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@media (max-width: 1599px) {
    .chatapp-wrap .chatapp-content .chatapp-single-chat .chat-info {
        right: -320px;
        width: 320px;
    }

    .chatapp-wrap .chatapp-content .chatapp-single-chat .chat-body ul.list-unstyled {
        width: 100%;
    }

    .chatapp-wrap .chatapp-content .chatapp-aside {
        width: 320px;
    }

    .chatapp-wrap.chatapp-info-active .chatapp-single-chat .chat-info {
        right: 0;
    }

    .chatapp-wrap.chatapp-info-active .chatapp-single-chat .chat-body {
        margin-right: 20rem;
    }

    .chatapp-wrap.chatapp-info-active .chatapp-single-chat footer.chat-footer {
        margin-right: 20rem;
    }

    ul.hk-list {
        margin: 0 -.28rem;
    }

    ul.hk-list li {
        padding: .68rem .28rem;
    }
}

@media (min-width: 1200px) {
    button.btn-close.info-close {
        display: none;
    }
}

@media (max-width: 1199px) {
    .chatapp-wrap .chatapp-content .chatapp-single-chat .chat-info {
        top: 0;
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    }

    .chatapp-wrap .chatapp-content .chatapp-single-chat .chat-body {
        margin-right: 0;
    }

    .chatapp-wrap.chatapp-info-active .chatapp-content .chatapp-single-chat .chat-body {
        margin-right: 0;
    }

    .chatapp-wrap.chatapp-info-active .chatapp-content .chatapp-single-chat footer {
        margin-right: 0;
    }

    /*Common for all App*/
    button.btn-close.info-close {
        top: 20px;
        position: absolute;
        right: 20px;
        z-index: 1;
    }
}

@media (min-width: 992px) {
    .chatapp-wrap .chatapp-content .chatapp-aside .hk-sidebar-togglable {
        display: none;
    }
}

@media (max-width: 991px) {
    .chatapp-wrap .chatapp-content .chatapp-aside {
        width: 100%;
    }

    .chatapp-wrap .chatapp-content .chatapp-single-chat {
        right: -100%;
        position: fixed;
        top: 0;
        width: 100%;
    }

    .chatapp-wrap .chatapp-content .chatapp-single-chat header.chat-header {
        justify-content: flex-start;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: flex-start;
    }

    .chatapp-wrap .chatapp-content .chatapp-single-chat header.chat-header .back-user-list {
        display: block;
        flex-shrink: 0;
    }

    .chatapp-wrap .chatapp-content .chatapp-single-chat header.chat-header .chat-options-wrap {
        margin-left: auto;
    }

    .chatapp-wrap .chatapp-content .chatapp-single-chat .chat-body ul.list-unstyled {
        width: 100%;
    }

    .chatapp-wrap .chatapp-content .chatapp-single-chat .chat-info .avatar.avatar-xxl {
        width: 6rem;
        height: 6rem;
    }

    .chatapp-wrap.chatapp-slide .chatapp-content .chatapp-aside {
        left: -100%;
    }

    .chatapp-wrap.chatapp-slide .chatapp-content .chatapp-single-chat {
        right: 0;
    }

    .chatapp-wrap .chatapp-call-window .modal-content .modal-footer .chatapp-caller-img {
        display: none;
    }
}

@media (max-width: 767px) {
    .chatapp-wrap .chatapp-content {
        margin-left: 0;
        padding-left: 0;
    }

    .chatapp-wrap .chatapp-content .chatapp-aside .aside-body .frequent-contact ul {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    }

    .chatapp-wrap .chatapp-content .chatapp-single-chat header.chat-header .media .media-head .avatar.avatar-sm {
        width: 2.5rem;
        height: 2.5rem;
    }

    .chatapp-wrap .chatapp-content .chatapp-single-chat .chat-body .media .media-body.msg-docs .msg-box>div .media .avatar.avatar-xs {
        width: 1.5rem;
        height: 1.5rem;
        flex-shrink: 0;
    }

    .chatapp-wrap .chatapp-content .chatapp-single-chat .chat-body .media .media-body.msg-docs .msg-box>div .media .file-name {
        max-width: calc(100vw - 300px);
    }

    .chatapp-wrap .hk-sidebar-togglable {
        -webkit-transform: translateX(32px);
        -moz-transform: translateX(32px);
        -ms-transform: translateX(32px);
        -o-transform: translateX(32px);
        transform: translateX(32px);
    }

    .chatapp-wrap .hk-sidebar-togglable:after {
        content: "\ea6c";
    }

    .chatapp-wrap .hk-sidebar-togglable.active {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }

    .chatapp-wrap .hk-sidebar-togglable.active:after {
        content: "\ea62";
    }
}

/*Chat Popup*/
@keyframes mercuryTypingAnimation {
    0% {
        transform: translateY(0px);
        background-color: #99cbcf;
    }

    28% {
        transform: translateY(-7px);
        background-color: #6bb4ba;
    }

    44% {
        transform: translateY(0px);
        background-color: #3d9ca5;
    }
}

.typing {
    align-items: center;
    display: flex;
    height: 17px;
}

.typing .dot {
    animation: mercuryTypingAnimation 1.8s infinite ease-in-out;
    background-color: #99cbcf;
    height: 5px;
    border-radius: 50%;
    margin-right: .25rem;
    vertical-align: middle;
    width: 5px;
    display: inline-block;
}

.typing .dot:nth-child(1) {
    animation-delay: 200ms;
}

.typing .dot:nth-child(2) {
    animation-delay: 300ms;
}

.typing .dot:nth-child(3) {
    animation-delay: 400ms;
}

.typing .dot:last-child {
    margin-right: 0;
}

.hk-chat-popup {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    display: none;
    width: 380px;
    box-shadow: 0 8px 10px rgba(0, 0, 0, 0.1);
    z-index: 10000;
    background: #fff;
    flex-flow: column;
}

.hk-chat-popup header {
    background: #262a2e;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
    flex-shrink: 0;
    Padding: 0.5rem 1.25rem;
    height: 64px;
}

.hk-chat-popup header .media-wrap .media {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.hk-chat-popup header .media-wrap .media .media-head {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-right: 1rem;
}

.hk-chat-popup header .media-wrap .media .media-body .user-name {
    font-size: 1rem;
    color: #fff;
    text-transform: capitalize;
}

.hk-chat-popup header .media-wrap .media .media-body .user-status {
    font-size: 0.875rem;
    color: #fff;
    text-transform: capitalize;
}

.hk-chat-popup header .input-group .contact-search {
    padding: 0;
    color: #fff;
    border: none;
    background: transparent;
}

.hk-chat-popup .chat-popup-body {
    overflow: auto;
    flex: 1 1 auto;
}

.hk-chat-popup .chat-popup-body .contact-list-wrap {
    padding: 0 1.25rem 1.25rem;
}

.hk-chat-popup .chat-popup-body .contact-list-wrap .chat-contacts-list {
    margin: 0 -0.8rem;
}

.hk-chat-popup .chat-popup-body .contact-list-wrap .chat-contacts-list .list-group-item {
    padding: 0;
    border: none;
    margin-bottom: .25rem;
}

.hk-chat-popup .chat-popup-body .contact-list-wrap .chat-contacts-list .list-group-item:last-child {
    margin-bottom: 0;
}

.hk-chat-popup .chat-popup-body .contact-list-wrap .chat-contacts-list .list-group-item .media {
    padding: .75rem .8rem;
    border-radius: 0.5rem;
    -webkit-transition: 0.2s ease;
    -moz-transition: 0.2s ease;
    transition: 0.2s ease;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    cursor: pointer;
}

.hk-chat-popup .chat-popup-body .contact-list-wrap .chat-contacts-list .list-group-item .media .media-head {
    position: relative;
    margin-right: 1rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.hk-chat-popup .chat-popup-body .contact-list-wrap .chat-contacts-list .list-group-item .media .media-body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    min-width: 0;
}

.hk-chat-popup .chat-popup-body .contact-list-wrap .chat-contacts-list .list-group-item .media .media-body>div:first-child {
    min-width: 0;
    flex: 1;
}

.hk-chat-popup .chat-popup-body .contact-list-wrap .chat-contacts-list .list-group-item .media .media-body>div:first-child .user-name,
.hk-chat-popup .chat-popup-body .contact-list-wrap .chat-contacts-list .list-group-item .media .media-body>div:first-child .user-last-chat,
.hk-chat-popup .chat-popup-body .contact-list-wrap .chat-contacts-list .list-group-item .media .media-body>div:first-child .user-status {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.hk-chat-popup .chat-popup-body .contact-list-wrap .chat-contacts-list .list-group-item .media .media-body>div:first-child .user-name {
    color: #262a2e;
    font-weight: 500;
    margin-bottom: .25rem;
}

.hk-chat-popup .chat-popup-body .contact-list-wrap .chat-contacts-list .list-group-item .media .media-body>div:last-child {
    text-align: right;
    padding-left: 1rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
    -webkit-box-align: flex-end;
    -ms-flex-align: flex-end;
    align-items: flex-end;
    width: 4.5rem;
}

.hk-chat-popup .chat-popup-body .contact-list-wrap .chat-contacts-list .list-group-item .media .media-body>div:last-child .last-chat-time {
    font-size: 0.75rem;
    color: #09769F;
}

.hk-chat-popup .chat-popup-body .contact-list-wrap .chat-contacts-list .list-group-item .media .media-body>div:last-child .badge {
    margin-top: .25rem;
}

.hk-chat-popup .chat-popup-body .contact-list-wrap .chat-contacts-list .list-group-item .media .media-body>div:last-child .action-drp {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 15px;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.hk-chat-popup .chat-popup-body .contact-list-wrap .chat-contacts-list .list-group-item .media:hover,
.hk-chat-popup .chat-popup-body .contact-list-wrap .chat-contacts-list .list-group-item .media:focus {
    background-color: #f2f2f2;
}

.hk-chat-popup .chat-popup-body .contact-list-wrap .chat-contacts-list .list-group-item .media:hover .media-body>div:last-child .action-drp,
.hk-chat-popup .chat-popup-body .contact-list-wrap .chat-contacts-list .list-group-item .media:focus .media-body>div:last-child .action-drp {
    opacity: 1;
    visibility: visible;
}

.hk-chat-popup .chat-popup-body .contact-list-wrap .chat-contacts-list .list-group-item .media:hover .media-body>div:last-child .last-chat-time,
.hk-chat-popup .chat-popup-body .contact-list-wrap .chat-contacts-list .list-group-item .media:hover .media-body>div:last-child .badge-pill,
.hk-chat-popup .chat-popup-body .contact-list-wrap .chat-contacts-list .list-group-item .media:focus .media-body>div:last-child .last-chat-time,
.hk-chat-popup .chat-popup-body .contact-list-wrap .chat-contacts-list .list-group-item .media:focus .media-body>div:last-child .badge-pill {
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.hk-chat-popup .chat-popup-body .contact-list-wrap .chat-contacts-list .list-group-item .media.read-chat .media-body>div:last-child .last-chat-time {
    color: #6f6f6f;
}

.hk-chat-popup .chat-popup-body .contact-list-wrap .chat-contacts-list .list-group-item .media.read-chat.active-user {
    background-color: #f2f2f2;
}

.hk-chat-popup .chat-popup-body .contact-list-wrap .chat-contacts-list .list-group-item .media.read-chat.active-user .media-body>div:first-child .user-name {
    color: #09769F;
}

.hk-chat-popup .chat-popup-body .contact-list-wrap .chat-contacts-list .title-wth-divider {
    padding: 0 .8rem;
}

.hk-chat-popup .chat-popup-body .contact-list-wrap .chat-contacts-list .title-wth-divider:after {
    background: #f7f7f7;
}

.hk-chat-popup .chat-popup-body ul.list-unstyled {
    width: 95%;
    margin: 0 auto;
    padding: 1.25rem 0;
}

.hk-chat-popup .chat-popup-body .media .media-body {
    margin-left: 0.75rem;
}

.hk-chat-popup .chat-popup-body .media .media-body .msg-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.hk-chat-popup .chat-popup-body .media .media-body .msg-box>div {
    padding: .625rem 1rem;
    border-radius: 1.25rem;
    display: inline-block;
    position: relative;
}

.hk-chat-popup .chat-popup-body .media .media-body .msg-box>div p:first-child {
    color: #262a2e;
}

.hk-chat-popup .chat-popup-body .media .media-body .msg-box>div .chat-time {
    display: block;
    text-transform: uppercase;
    font-size: 0.75rem;
    margin-top: .25rem;
    opacity: .5;
}

.hk-chat-popup .chat-popup-body .media .media-body .msg-box>div .file-overlay {
    position: absolute;
    opacity: 0;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    color: #fff;
    background: rgba(38, 42, 46, 0.4);
    border-radius: 1.25rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
}

.hk-chat-popup .chat-popup-body .media .media-body .msg-box>div:hover .file-overlay {
    opacity: 1;
}

.hk-chat-popup .chat-popup-body .media .media-body .msg-box+.msg-box {
    margin-top: .25rem;
}

.hk-chat-popup .chat-popup-body .media .media-body .msg-box .msg-action {
    margin-left: 1rem;
    opacity: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 0;
    background: transparent !important;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
}

.hk-chat-popup .chat-popup-body .media .media-body .msg-box:hover .msg-action {
    opacity: 1;
}

.hk-chat-popup .chat-popup-body .media .media-body.msg-docs {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.hk-chat-popup .chat-popup-body .media .media-body.msg-docs .msg-box>div .media {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.hk-chat-popup .chat-popup-body .media .media-body.msg-docs .msg-box>div .media .avatar.avatar-sm .avatar-icon {
    font-size: 1.75rem;
}

.hk-chat-popup .chat-popup-body .media .media-body.msg-docs .msg-box>div .media .file-name {
    color: #262a2e;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 165px;
}

.hk-chat-popup .chat-popup-body .media .media-body.msg-docs .msg-box>div .media .file-size {
    font-size: 0.875rem;
    margin-top: .25rem;
}

.hk-chat-popup .chat-popup-body .media .media-body.msg-docs .msg-box+.msg-box {
    margin-top: 0;
}

.hk-chat-popup .chat-popup-body .media .media-body.msg-imgs .msg-box>div {
    padding: 0;
}

.hk-chat-popup .chat-popup-body .media .media-body.msg-imgs .msg-box>div img {
    border-radius: 1.25rem;
    height: 150px;
}

.hk-chat-popup .chat-popup-body .media .media-body.msg-imgs .msg-box:first-child>div,
.hk-chat-popup .chat-popup-body .media .media-body.msg-imgs .msg-box:last-child>div {
    border-radius: 1.25rem !important;
}

.hk-chat-popup .chat-popup-body .media.received {
    margin-bottom: 1.25rem;
}

.hk-chat-popup .chat-popup-body .media.received .media-body .msg-box>div {
    background: #f2f2f2;
}

.hk-chat-popup .chat-popup-body .media.received .media-body .msg-box:first-child>div {
    border-top-left-radius: 0;
}

.hk-chat-popup .chat-popup-body .media.sent {
    margin-bottom: 1.25rem;
}

.hk-chat-popup .chat-popup-body .media.sent .media-body {
    margin-left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: flex-end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: flex-end;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: flex-end;
    -ms-flex-align: flex-end;
    align-items: flex-end;
}

.hk-chat-popup .chat-popup-body .media.sent .media-body .msg-box {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
}

.hk-chat-popup .chat-popup-body .media.sent .media-body .msg-box>div {
    background: #09769F;
}

.hk-chat-popup .chat-popup-body .media.sent .media-body .msg-box>div p {
    color: #fff;
}

.hk-chat-popup .chat-popup-body .media.sent .media-body .msg-box>div .chat-time {
    color: #fff;
}

.hk-chat-popup .chat-popup-body .media.sent .media-body .msg-box:first-child>div {
    border-top-right-radius: 0;
}

.hk-chat-popup .chat-popup-body .media.media-attachment {
    margin-bottom: 17px;
}

.hk-chat-popup .chat-popup-body .media.media-attachment .media-body>.msg-box {
    margin-bottom: .25rem;
    margin-right: .25rem;
}

.hk-chat-popup .chat-popup-body .media.typing-wrap .media-body .msg-box>div:after {
    display: none;
}

.hk-chat-popup .chat-popup-body .day-sep {
    position: relative;
    text-align: center;
    overflow: hidden;
    font-size: 0.875rem;
    margin: 1.875rem 0;
    text-transform: capitalize;
}

.hk-chat-popup .chat-popup-body .day-sep:before {
    background: #eaeaea;
    content: "";
    height: 1px;
    right: 50%;
    position: absolute;
    top: 50%;
    width: 100%;
}

.hk-chat-popup .chat-popup-body .day-sep:after {
    background: #eaeaea;
    content: "";
    height: 1px;
    left: 50%;
    position: absolute;
    top: 50%;
    width: 100%;
}

.hk-chat-popup .chat-popup-body .day-sep span {
    display: inline-block;
    background: #fff;
    z-index: 1;
    position: relative;
    padding: 0 .625rem;
}

.hk-chat-popup footer {
    background: #fff;
    border-top: 1px solid #eaeaea;
    margin-top: -1px;
    position: relative;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
}

.hk-chat-popup footer .feather-icon,
.hk-chat-popup footer .svg-icon {
    display: block;
}

.hk-chat-popup footer .form-control,
.hk-chat-popup footer .form-select,
.hk-chat-popup footer .wizard>.content>.body input,
.wizard>.content>.body .hk-chat-popup footer input,
.hk-chat-popup footer .dd-handle {
    height: 57px;
}

.btn-popup-open,
.btn-popup-close {
    position: fixed;
    right: 20px;
    bottom: 30px;
    z-index: 111;
}

.chat-popover {
    position: fixed;
    right: 20px;
    bottom: 100px;
    background: #fff;
    width: 270px;
    border-radius: 0.5rem;
    box-shadow: 0 8px 10px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    display: none;
    z-index: 99;
}

.chat-popover p {
    font-size: 0.875rem;
}

@media (max-width: 800px) {
    .hk-chat-popup {
        width: 100%;
    }
}

/*Chatbot Popup*/
.hk-chatbot-popup {
    position: fixed;
    bottom: 100px;
    right: 30px;
    display: none;
    width: 380px;
    border-radius: 0.5rem;
    overflow: hidden;
    box-shadow: 0 8px 10px rgba(0, 0, 0, 0.1);
    z-index: 10000;
    background: #fff;
}

.hk-chatbot-popup header {
    background: #262a2e;
    Padding: 0.5rem 1.25rem 5.5rem;
}

.hk-chatbot-popup header .chatbot-head-top {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
}

.hk-chatbot-popup header .media-wrap .media {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.hk-chatbot-popup header .media-wrap .media .media-head {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-right: 1rem;
}

.hk-chatbot-popup header .media-wrap .media .media-body .user-name,
.hk-chatbot-popup header .media-wrap .media .media-body .user-status {
    font-size: 1rem;
    color: #fff;
    text-transform: capitalize;
}

.hk-chatbot-popup header .media-wrap .media .media-body .user-status {
    font-size: 0.875rem;
}

.hk-chatbot-popup .chatbot-popup-body {
    height: calc(100vh - 440px);
    position: relative;
    background: white;
}

.hk-chatbot-popup .chatbot-popup-body .nicescroll-bar {
    margin-top: -5.25rem;
}

.hk-chatbot-popup .chatbot-popup-body .init-content-wrap {
    padding: 1.25rem 1.25rem 1.25rem;
}

.hk-chatbot-popup .chatbot-popup-body .init-content-wrap .btn-wrap {
    text-align: right;
}

.hk-chatbot-popup .chatbot-popup-body .init-content-wrap .btn-wrap .btn,
.hk-chatbot-popup .chatbot-popup-body .init-content-wrap .btn-wrap div.hopscotch-bubble .hopscotch-bubble-container .hopscotch-actions .hopscotch-nav-button,
div.hopscotch-bubble .hopscotch-bubble-container .hopscotch-actions .hk-chatbot-popup .chatbot-popup-body .init-content-wrap .btn-wrap .hopscotch-nav-button {
    margin-bottom: .625rem;
}

.hk-chatbot-popup .chatbot-popup-body ul.list-unstyled {
    width: 95%;
    margin: 0 auto;
    padding: 1.25rem 0;
}

.hk-chatbot-popup .chatbot-popup-body .media .media-body {
    margin-left: 0.75rem;
}

.hk-chatbot-popup .chatbot-popup-body .media .media-body .msg-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.hk-chatbot-popup .chatbot-popup-body .media .media-body .msg-box>div {
    padding: .625rem 1rem;
    border-radius: 1.25rem;
    display: inline-block;
    position: relative;
}

.hk-chatbot-popup .chatbot-popup-body .media .media-body .msg-box>div p:first-child {
    color: #262a2e;
}

.hk-chatbot-popup .chatbot-popup-body .media .media-body .msg-box+.msg-box {
    margin-top: .25rem;
}

.hk-chatbot-popup .chatbot-popup-body .media.received {
    margin-bottom: 1.25rem;
}

.hk-chatbot-popup .chatbot-popup-body .media.received .media-body .msg-box>div {
    background: #f2f2f2;
}

.hk-chatbot-popup .chatbot-popup-body .media.received .media-body .msg-box:first-child>div {
    border-top-left-radius: 0;
}

.hk-chatbot-popup .chatbot-popup-body .media.sent {
    margin-bottom: 1.25rem;
}

.hk-chatbot-popup .chatbot-popup-body .media.sent .media-body {
    margin-left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: flex-end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: flex-end;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: flex-end;
    -ms-flex-align: flex-end;
    align-items: flex-end;
}

.hk-chatbot-popup .chatbot-popup-body .media.sent .media-body .msg-box {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
}

.hk-chatbot-popup .chatbot-popup-body .media.sent .media-body .msg-box>div {
    background: #09769F;
}

.hk-chatbot-popup .chatbot-popup-body .media.sent .media-body .msg-box>div p {
    color: #fff;
}

.hk-chatbot-popup .chatbot-popup-body .media.sent .media-body .msg-box>div .chat-time {
    color: #fff;
}

.hk-chatbot-popup .chatbot-popup-body .media.sent .media-body .msg-box:first-child>div {
    border-top-right-radius: 0;
}

.hk-chatbot-popup .chatbot-popup-body .media.typing-wrap .media-body .msg-box>div:after {
    display: none;
}

.hk-chatbot-popup .chatbot-popup-body .day-sep {
    position: relative;
    text-align: center;
    overflow: hidden;
    font-size: 0.875rem;
    margin: 1.875rem 0;
    text-transform: capitalize;
}

.hk-chatbot-popup .chatbot-popup-body .day-sep:before {
    background: #eaeaea;
    content: "";
    height: 1px;
    right: 50%;
    position: absolute;
    top: 50%;
    width: 100%;
}

.hk-chatbot-popup .chatbot-popup-body .day-sep:after {
    background: #eaeaea;
    content: "";
    height: 1px;
    left: 50%;
    position: absolute;
    top: 50%;
    width: 100%;
}

.hk-chatbot-popup .chatbot-popup-body .day-sep span {
    display: inline-block;
    background: #fff;
    z-index: 1;
    position: relative;
    padding: 0 .625rem;
}

.hk-chatbot-popup footer {
    background: #fff;
}

.hk-chatbot-popup footer .input-group {
    border-top: 1px solid #eaeaea;
}

.hk-chatbot-popup footer .input-group .form-control,
.hk-chatbot-popup footer .input-group .form-select,
.hk-chatbot-popup footer .input-group .wizard>.content>.body input,
.wizard>.content>.body .hk-chatbot-popup footer .input-group input,
.hk-chatbot-popup footer .input-group .dd-handle {
    height: 57px;
}

.hk-chatbot-popup footer .chatbot-intro-text {
    text-align: center;
    padding: .5rem 1.25rem;
}

.hk-chatbot-popup footer .footer-copy-text {
    background: #f7f7f7;
    padding: .5rem 1.25rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    font-size: 0.75rem;
}

.hk-chatbot-popup footer .footer-copy-text .brand-link img {
    width: 75px;
    margin-left: .25rem;
}

ul.chatScroll {
    max-height: 270px;
    /* set the desired max height */
    overflow-y: auto;
    scroll-behavior: smooth;
    /* Enable smooth scrolling */
}

/* Customize the scrollbar appearance */
ul.chatScroll::-webkit-scrollbar {
    width: 2px;
    /* Adjust the width as needed */
}

ul.chatScroll::-webkit-scrollbar-thumb {
    background-color: #888;
    /* Scrollbar thumb color */
}

ul.chatScroll::-webkit-scrollbar-track {
    background-color: #f4f4f4;
    /* Scrollbar track color */
}

@media (max-width: 767px) {
    .hk-chatbot-popup {
        width: 100%;
        flex-flow: column;
        border-radius: 0;
        bottom: 0;
        right: 0;
        top: 0;
    }

    .hk-chatbot-popup header {
        Padding: 0.5rem 1.25rem 1rem;
    }

    .hk-chatbot-popup .chatbot-popup-body {
        overflow: auto;
        flex: 1 1 auto;
        height: auto;
    }

    .hk-chatbot-popup .chatbot-popup-body .nicescroll-bar {
        margin-top: 0;
    }

    .hk-chatbot-popup footer .chatbot-intro-text {
        display: none;
    }
}

/*Calendar*/
.calendarapp-wrap {
    position: relative;
    height: calc(100vh - 65px);
    -webkit-transition: height 0.2s ease;
    -moz-transition: height 0.2s ease;
    transition: height 0.2s ease;
}

.calendarapp-wrap .calendarapp-sidebar {
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-flow: column;
    left: 0;
    width: 270px;
    margin-left: 0;
    top: 0;
    height: 100%;
    z-index: 1;
    border: none;
    background: #fff;
    border-right: 1px solid #eaeaea;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.calendarapp-wrap .calendarapp-sidebar>div.nicescroll-bar {
    flex: 1 1 auto;
    overflow: auto;
}

.calendarapp-wrap .calendarapp-sidebar .menu-content-wrap {
    padding: 1.5rem;
}

.calendarapp-wrap .calendarapp-sidebar .menu-content-wrap>.navbar-nav>.nav-item {
    margin-left: -1rem;
    margin-right: -1rem;
}

.calendarapp-wrap .calendarapp-sidebar .menu-content-wrap>.navbar-nav>.nav-item>.nav-link {
    border-radius: .5rem;
    padding: .5rem 1rem;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.calendarapp-wrap .calendarapp-sidebar .menu-content-wrap>.navbar-nav>.nav-item.active>.nav-link {
    background: rgba(0, 125, 136, 0.15);
    color: #09769F;
}

.calendarapp-wrap .calendarapp-sidebar .menu-content-wrap .daterangepicker {
    position: static;
    display: block !important;
    margin: 0;
    border: none;
    overflow: hidden;
    width: auto;
}

.calendarapp-wrap .calendarapp-sidebar .menu-content-wrap .daterangepicker .drp-calendar {
    padding: 0;
}

.calendarapp-wrap .calendarapp-sidebar .menu-content-wrap .daterangepicker .drp-calendar .calendar-table {
    padding: 0;
}

.calendarapp-wrap .calendarapp-sidebar .menu-content-wrap .daterangepicker .drp-calendar .calendar-table th,
.calendarapp-wrap .calendarapp-sidebar .menu-content-wrap .daterangepicker .drp-calendar .calendar-table td {
    min-width: 30px;
    width: 30px;
    height: 30px;
    z-index: 1;
}

.calendarapp-wrap .calendarapp-sidebar .menu-content-wrap .daterangepicker .drp-calendar .calendar-table th.active,
.calendarapp-wrap .calendarapp-sidebar .menu-content-wrap .daterangepicker .drp-calendar .calendar-table th:hover,
.calendarapp-wrap .calendarapp-sidebar .menu-content-wrap .daterangepicker .drp-calendar .calendar-table td.active,
.calendarapp-wrap .calendarapp-sidebar .menu-content-wrap .daterangepicker .drp-calendar .calendar-table td:hover {
    background: transparent;
}

.calendarapp-wrap .calendarapp-sidebar .menu-content-wrap .daterangepicker .drp-calendar .calendar-table td:after {
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 50%;
    z-index: -1;
}

.calendarapp-wrap .calendarapp-sidebar .menu-content-wrap .daterangepicker .drp-calendar .calendar-table td.active,
.calendarapp-wrap .calendarapp-sidebar .menu-content-wrap .daterangepicker .drp-calendar .calendar-table td:hover {
    background: transparent;
    color: #09769F;
}

.calendarapp-wrap .calendarapp-sidebar .menu-content-wrap .daterangepicker .drp-calendar .calendar-table td.active:after,
.calendarapp-wrap .calendarapp-sidebar .menu-content-wrap .daterangepicker .drp-calendar .calendar-table td:hover:after {
    background: #c7e2e5;
}

.calendarapp-wrap .calendarapp-sidebar .menu-content-wrap .upcoming-event-wrap .nav .nav-item .event-time {
    color: #6f6f6f;
    font-size: 0.75rem;
}

.calendarapp-wrap .calendarapp-sidebar .menu-content-wrap .upcoming-event-wrap .nav .nav-item .event-name {
    margin-top: .25rem;
    font-weight: 500;
}

.calendarapp-wrap .calendarapp-sidebar .menu-content-wrap .categories-wrap .form-check {
    padding-top: .5rem;
    padding-bottom: .5rem;
}

.calendarapp-wrap .calendarapp-sidebar .calendarapp-fixednav {
    height: 61px;
    border-top: 1px solid #eaeaea;
}

.calendarapp-wrap .calendarapp-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    padding-left: 270px;
    position: relative;
    height: 100%;
    left: 0;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.calendarapp-wrap .calendarapp-content .fc .fc-toolbar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
    height: 65px;
    padding: 0 1.25rem;
    margin-bottom: 0;
    background: #fff;
    position: relative;
}

.calendarapp-wrap .calendarapp-content .fc .fc-toolbar .fc-toolbar-chunk {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    flex: 1;
}

.calendarapp-wrap .calendarapp-content .fc .fc-toolbar .fc-toolbar-chunk:nth-child(1) .btn-group {
    order: 2;
    float: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.calendarapp-wrap .calendarapp-content .fc .fc-toolbar .fc-toolbar-chunk:nth-child(1) .btn-group .fc-prev-button,
.calendarapp-wrap .calendarapp-content .fc .fc-toolbar .fc-toolbar-chunk:nth-child(1) .btn-group .fc-next-button {
    border: none;
    background: transparent;
}

.calendarapp-wrap .calendarapp-content .fc .fc-toolbar .fc-toolbar-chunk:nth-child(1) .fc-today-button {
    border-radius: 0.5rem;
    margin-left: 0 !important;
    margin-right: 1rem;
}

.calendarapp-wrap .calendarapp-content .fc .fc-toolbar .fc-toolbar-chunk:nth-child(2) {
    justify-content: center;
}

.calendarapp-wrap .calendarapp-content .fc .fc-toolbar .fc-toolbar-chunk:nth-child(2) h2 {
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 0;
}

.calendarapp-wrap .calendarapp-content .fc .fc-toolbar .fc-toolbar-chunk:nth-child(3) {
    justify-content: flex-end;
}

.calendarapp-wrap .calendarapp-content .fc .fc-toolbar>*> :not(:first-child) {
    margin-left: 0.25rem;
}

.calendarapp-wrap .calendarapp-content .fc .fc-col-header td,
.calendarapp-wrap .calendarapp-content .fc .fc-col-header th {
    border: 1px solid #eaeaea;
}

.calendarapp-wrap .calendarapp-content .fc .fc-col-header-cell-cushion {
    font-size: 0.75rem;
    font-weight: 600;
    text-transform: uppercase;
    padding: .25rem 0;
    color: #262a2e;
}

.calendarapp-wrap .calendarapp-content .fc .fc-timegrid-axis-cushion,
.calendarapp-wrap .calendarapp-content .fc .fc-timegrid-slot-label-cushion {
    font-size: 0.875rem;
}

.calendarapp-wrap .calendarapp-content .fc .fc-daygrid-body {
    width: 100% !important;
    height: 100% !important;
}

.calendarapp-wrap .calendarapp-content .fc .fc-daygrid-body .fc-scrollgrid-sync-table {
    width: 100% !important;
    height: 100% !important;
}

.calendarapp-wrap .calendarapp-content .fc .fc-daygrid-body .fc-scrollgrid-sync-table td,
.calendarapp-wrap .calendarapp-content .fc .fc-daygrid-body .fc-scrollgrid-sync-table th {
    border: 1px solid #eaeaea;
}

.calendarapp-wrap .calendarapp-content .fc .fc-daygrid-day-top {
    justify-content: center;
}

.calendarapp-wrap .calendarapp-content .fc .fc-daygrid-day-number {
    padding: .625rem;
}

.calendarapp-wrap .calendarapp-content .fc .fc-daygrid-day.fc-day-today {
    background: transparent;
}

.calendarapp-wrap .calendarapp-content .fc .fc-daygrid-day.fc-day-today .fc-daygrid-day-top {
    padding: .25rem;
}

.calendarapp-wrap .calendarapp-content .fc .fc-daygrid-day.fc-day-today .fc-daygrid-day-top .fc-daygrid-day-number {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    height: 35px;
    width: 35px;
    background: #09769F;
    border-radius: 50%;
    color: #fff;
    position: relative;
}

.calendarapp-wrap .calendarapp-content .fc .fc-list-event-dot {
    border-color: #09769F;
}

.calendarapp-wrap .calendarapp-content .fc .fc-timegrid-slot {
    border-left: 0;
}

.calendarapp-wrap .calendarapp-content .fc .fc-timegrid-divider {
    border: 0;
}

.calendarapp-wrap .calendarapp-content .fc .fc-v-event {
    background-color: #09769F;
}

.calendarapp-wrap .calendarapp-content .fc .fc-timegrid-divider {
    padding: 0;
}

.calendarapp-wrap .calendarapp-content .fc .table-bordered {
    border: 1px solid #eaeaea;
    border-left: 0;
}

.calendarapp-wrap .calendarapp-content .fc .fc-view-harness .fc-daygrid {
    background: #fff;
}

.calendarapp-wrap .calendarapp-content .fc .fc-view-harness .fc-daygrid .table-bordered .fc-day-other {
    background-color: #f7f7f7;
}

.calendarapp-wrap .calendarapp-content .fc .fc-daygrid-event {
    padding: .125rem .375rem;
    border-radius: 0.5rem;
}

.calendarapp-wrap .calendarapp-content .fc .fc-daygrid-event.has-avatar {
    padding: .375rem .625rem;
}

.calendarapp-wrap .calendarapp-content .fc .fc-daygrid-event.has-avatar .avatar {
    margin-right: .625rem;
    flex-shrink: 0;
}

.calendarapp-wrap .calendarapp-content .fc .fc-daygrid-event .fc-event-main {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.calendarapp-wrap .calendarapp-content .fc .fc-daygrid-event .fc-event-main i {
    margin-right: .25rem;
}

.calendarapp-wrap .calendarapp-content .fc .fc-daygrid-event .fc-event-main span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.calendarapp-wrap .calendarapp-content .fc .fc-daygrid-event .fc-event-main .fc-event-main-frame {
    overflow: hidden;
}

.calendarapp-wrap .calendarapp-content .fc .fc-daygrid-event .fc-event-main .fc-event-main-frame .fc-event-title {
    text-overflow: ellipsis;
}

.calendarapp-wrap .calendarapp-content .fc-daygrid-event-dot {
    border-color: #09769F;
}

.calendarapp-wrap.calendarapp-sidebar-toggle .calendarapp-sidebar {
    left: -270px;
}

.calendarapp-wrap.calendarapp-sidebar-toggle .calendarapp-content {
    padding-left: 0;
}

.hk-wrapper[data-navbar-style="collapsed"] .calendarapp-wrap {
    height: 100vh;
}

.hk-wrapper .calendar-drawer>div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    flex-flow: column;
}

.hk-wrapper .calendar-drawer .event-head {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.hk-wrapper .calendar-drawer .event-head .event-name {
    font-size: 1.25rem;
    font-weight: 500;
    color: #262a2e;
    outline: none;
}

.hk-wrapper .calendar-drawer .event-head .badge-indicator {
    position: relative;
    top: 7px;
}

.hk-wrapper .calendar-drawer .event-detail {
    padding-left: 0;
}

.hk-wrapper .calendar-drawer .event-detail li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 1.125rem;
}

.hk-wrapper .calendar-drawer .event-detail li .ev-icon-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    color: #262a2e;
    position: relative;
    top: 1px;
    margin-right: .625rem;
}

.hk-wrapper .calendar-drawer .event-detail li .ev-icon-wrap .feather-icon,
.hk-wrapper .calendar-drawer .event-detail li .ev-icon-wrap .svg-icon {
    font-size: 1.125rem;
}

@media (max-width: 1199px) {
    .calendarapp-wrap .calendarapp-sidebar {
        left: -270px;
    }

    .calendarapp-wrap .calendarapp-content {
        margin-left: 0;
        padding-left: 0;
    }

    .calendarapp-wrap .calendarapp-content .fc-toolbar-chunk:nth-child(3)>.btn-group {
        display: none;
    }

    .calendarapp-wrap.calendarapp-sidebar-toggle .calendarapp-sidebar {
        left: 0;
    }

    .calendarapp-wrap.calendarapp-sidebar-toggle .calendarapp-content {
        left: 270px;
        padding-left: 270px;
        margin-left: -270px;
    }

    .calendarapp-wrap .hk-sidebar-togglable {
        -webkit-transform: translateX(32px);
        -moz-transform: translateX(32px);
        -ms-transform: translateX(32px);
        -o-transform: translateX(32px);
        transform: translateX(32px);
    }

    .calendarapp-wrap .hk-sidebar-togglable:after {
        content: "\ea6c";
    }

    .calendarapp-wrap .hk-sidebar-togglable.active {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }

    .calendarapp-wrap .hk-sidebar-togglable.active:after {
        content: "\ea62";
    }
}

/*Email UI*/
.emailapp-wrap {
    position: relative;
    height: calc(100vh - 65px);
    -webkit-transition: height 0.2s ease;
    -moz-transition: height 0.2s ease;
    transition: height 0.2s ease;
}

.emailapp-wrap .emailapp-sidebar {
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-flow: column;
    left: 0;
    width: 270px;
    margin-left: 0;
    top: 0;
    height: 100%;
    z-index: 1;
    border: none;
    background: #fff;
    border-right: 1px solid #eaeaea;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.emailapp-wrap .emailapp-sidebar>div.nicescroll-bar {
    flex: 1 1 auto;
    overflow: auto;
}

.emailapp-wrap .emailapp-sidebar .menu-content-wrap {
    padding: 1.5rem;
}

.emailapp-wrap .emailapp-sidebar .menu-content-wrap .menu-gap {
    height: 25px;
}

.emailapp-wrap .emailapp-sidebar .menu-content-wrap .menu-group>.navbar-nav>.nav-item {
    margin-left: -1rem;
    margin-right: -1rem;
}

.emailapp-wrap .emailapp-sidebar .menu-content-wrap .menu-group>.navbar-nav>.nav-item>.nav-link {
    border-radius: .375rem;
    padding: .5rem 1rem;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.emailapp-wrap .emailapp-sidebar .menu-content-wrap .menu-group>.navbar-nav>.nav-item>.nav-link .nav-icon-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-right: .875rem;
    font-size: 1.5rem;
}

.emailapp-wrap .emailapp-sidebar .menu-content-wrap .menu-group>.navbar-nav>.nav-item>.nav-link .nav-icon-wrap .nav-link-text {
    display: inline-block;
}

.emailapp-wrap .emailapp-sidebar .menu-content-wrap .menu-group>.navbar-nav>.nav-item.active>.nav-link {
    background: rgba(0, 125, 136, 0.15);
    color: #09769F;
}

.emailapp-wrap .emailapp-sidebar .emailapp-fixednav {
    height: 61px;
    border-top: 1px solid #eaeaea;
}

.emailapp-wrap .emailapp-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    padding-left: 270px;
    position: relative;
    height: 100%;
    left: 0;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.emailapp-wrap .emailapp-content .emailapp-aside {
    border-right: 1px solid #eaeaea;
    background: #fff;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-flow: column;
    height: 100%;
    z-index: 1;
    left: 0;
    width: 370px;
    flex-shrink: 0;
    margin-top: 0;
}

.emailapp-wrap .emailapp-content .emailapp-aside header.aside-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
    height: 65px;
    padding: 0 1.25rem;
    border-bottom: 1px solid #eaeaea;
    background: #fff;
    color: #262a2e;
}

.emailapp-wrap .emailapp-content .emailapp-aside header.aside-header .emailapp-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.emailapp-wrap .emailapp-content .emailapp-aside header.aside-header .emailapp-title h1 {
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 0;
}

.emailapp-wrap .emailapp-content .emailapp-aside header.aside-header .emailapp-title:after {
    top: 0;
}

.emailapp-wrap .emailapp-content .emailapp-aside header.aside-header .btn,
.emailapp-wrap .emailapp-content .emailapp-aside header.aside-header div.hopscotch-bubble .hopscotch-bubble-container .hopscotch-actions .hopscotch-nav-button,
div.hopscotch-bubble .hopscotch-bubble-container .hopscotch-actions .emailapp-wrap .emailapp-content .emailapp-aside header.aside-header .hopscotch-nav-button {
    margin-right: .5rem;
}

.emailapp-wrap .emailapp-content .emailapp-aside header.aside-header .btn:last-child,
.emailapp-wrap .emailapp-content .emailapp-aside header.aside-header div.hopscotch-bubble .hopscotch-bubble-container .hopscotch-actions .hopscotch-nav-button:last-child,
div.hopscotch-bubble .hopscotch-bubble-container .hopscotch-actions .emailapp-wrap .emailapp-content .emailapp-aside header.aside-header .hopscotch-nav-button:last-child {
    margin-right: 0;
}

.emailapp-wrap .emailapp-content .emailapp-aside .aside-search {
    margin-bottom: 1.25rem;
}

.emailapp-wrap .emailapp-content .emailapp-aside .aside-body {
    overflow: auto;
    -ms-flex: 1;
    flex: 1;
    height: 100%;
    padding: 1.25rem;
}

.emailapp-wrap .emailapp-content .emailapp-aside .aside-body .email-list {
    margin: 0 -0.8rem;
}

.emailapp-wrap .emailapp-content .emailapp-aside .aside-body .email-list .list-group-item {
    padding: 0;
    border: none;
    margin-bottom: .25rem;
}

.emailapp-wrap .emailapp-content .emailapp-aside .aside-body .email-list .list-group-item .media {
    padding: .75rem .8rem;
    border-radius: 0.5rem;
    -webkit-transition: 0.2s ease;
    -moz-transition: 0.2s ease;
    transition: 0.2s ease;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    cursor: pointer;
}

.emailapp-wrap .emailapp-content .emailapp-aside .aside-body .email-list .list-group-item .media .media-head {
    position: relative;
    margin-right: 1rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-flow: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.emailapp-wrap .emailapp-content .emailapp-aside .aside-body .email-list .list-group-item .media .media-head .badge-indicator {
    margin-top: .875rem;
}

.emailapp-wrap .emailapp-content .emailapp-aside .aside-body .email-list .list-group-item .media .media-body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    min-width: 0;
}

.emailapp-wrap .emailapp-content .emailapp-aside .aside-body .email-list .list-group-item .media .media-body>div {
    min-width: 0;
    flex: 1;
}

.emailapp-wrap .emailapp-content .emailapp-aside .aside-body .email-list .list-group-item .media .media-body>div>div:first-child {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: .25rem;
}

.emailapp-wrap .emailapp-content .emailapp-aside .aside-body .email-list .list-group-item .media .media-body>div>div:first-child div:last-child {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-left: auto;
}

.emailapp-wrap .emailapp-content .emailapp-aside .aside-body .email-list .list-group-item .media .media-body>div>div:first-child div:last-child .email-star {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-left: .625rem;
    opacity: 0;
    color: #6f6f6f;
}

.emailapp-wrap .emailapp-content .emailapp-aside .aside-body .email-list .list-group-item .media .media-body>div>div:first-child div:last-child .email-star>.feather-icon>svg,
.emailapp-wrap .emailapp-content .emailapp-aside .aside-body .email-list .list-group-item .media .media-body>div>div:first-child div:last-child .email-star .svg-icon>svg {
    cursor: pointer;
}

.emailapp-wrap .emailapp-content .emailapp-aside .aside-body .email-list .list-group-item .media .media-body>div>div:first-child div:last-child .email-star>.feather-icon>svg:hover,
.emailapp-wrap .emailapp-content .emailapp-aside .aside-body .email-list .list-group-item .media .media-body>div>div:first-child div:last-child .email-star .svg-icon>svg:hover {
    fill: #FFC400;
    stroke: #FFC400;
}

.emailapp-wrap .emailapp-content .emailapp-aside .aside-body .email-list .list-group-item .media .media-body>div>div:first-child div:last-child .email-star.marked {
    opacity: 1;
}

.emailapp-wrap .emailapp-content .emailapp-aside .aside-body .email-list .list-group-item .media .media-body>div>div:first-child div:last-child .email-star.marked>.feather-icon>svg,
.emailapp-wrap .emailapp-content .emailapp-aside .aside-body .email-list .list-group-item .media .media-body>div>div:first-child div:last-child .email-star.marked .svg-icon>svg {
    fill: #FFC400;
    stroke: #FFC400;
}

.emailapp-wrap .emailapp-content .emailapp-aside .aside-body .email-list .list-group-item .media .media-body>div>div:first-child div:last-child .email-star.marked>.feather-icon>svg:hover,
.emailapp-wrap .emailapp-content .emailapp-aside .aside-body .email-list .list-group-item .media .media-body>div>div:first-child div:last-child .email-star.marked .svg-icon>svg:hover {
    fill: none;
    stroke: currentColor;
}

.emailapp-wrap .emailapp-content .emailapp-aside .aside-body .email-list .list-group-item .media .media-body>div>div:first-child div:last-child .email-time {
    font-size: 0.75rem;
    margin-left: .625rem;
}

.emailapp-wrap .emailapp-content .emailapp-aside .aside-body .email-list .list-group-item .media .media-body>div .email-head,
.emailapp-wrap .emailapp-content .emailapp-aside .aside-body .email-list .list-group-item .media .media-body>div .email-subject {
    color: #262a2e;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-transform: capitalize;
}

.emailapp-wrap .emailapp-content .emailapp-aside .aside-body .email-list .list-group-item .media .media-body>div .email-subject {
    margin-bottom: .25rem;
    font-weight: 500;
}

.emailapp-wrap .emailapp-content .emailapp-aside .aside-body .email-list .list-group-item .media .media-body>div .email-head {
    font-weight: 500;
    margin-right: .5rem;
    max-width: 150px;
}

.emailapp-wrap .emailapp-content .emailapp-aside .aside-body .email-list .list-group-item .media .media-body>div .email-text {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 0.875rem;
}

.emailapp-wrap .emailapp-content .emailapp-aside .aside-body .email-list .list-group-item .media:hover,
.emailapp-wrap .emailapp-content .emailapp-aside .aside-body .email-list .list-group-item .media:focus {
    background-color: #f2f2f2;
}

.emailapp-wrap .emailapp-content .emailapp-aside .aside-body .email-list .list-group-item .media:hover .media-body>div>div:first-child div:last-child .email-star,
.emailapp-wrap .emailapp-content .emailapp-aside .aside-body .email-list .list-group-item .media:focus .media-body>div>div:first-child div:last-child .email-star {
    opacity: 1;
}

.emailapp-wrap .emailapp-content .emailapp-aside .aside-body .email-list .list-group-item .media.read-email.active-user {
    background-color: #f2f2f2;
}

.emailapp-wrap .emailapp-content .emailapp-aside .aside-body .email-list .hk-section-head-border {
    padding: 0 .8rem;
}

.emailapp-wrap .emailapp-content .emailapp-aside .aside-body .email-list .hk-section-head-border:after {
    background: #f7f7f7;
}

.emailapp-wrap .emailapp-content .emailapp-single-email {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-grow: 1;
    background: #fff;
    flex-flow: column;
    height: 100%;
    z-index: 10;
}

.emailapp-wrap .emailapp-content .emailapp-single-email header.email-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
    height: 65px;
    padding: 0 1rem;
    background: #fff;
    border-bottom: 1px solid #eaeaea;
    position: relative;
}

.emailapp-wrap .emailapp-content .emailapp-single-email header.email-header .feather-icon,
.emailapp-wrap .emailapp-content .emailapp-single-email header.email-header .svg-icon {
    display: block;
}

.emailapp-wrap .emailapp-content .emailapp-single-email header.email-header .back-user-list {
    display: none;
    margin-right: .625rem;
}

.emailapp-wrap .emailapp-content .emailapp-single-email header.email-header .media {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.emailapp-wrap .emailapp-content .emailapp-single-email header.email-header .media .media-head {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-right: 1rem;
}

.emailapp-wrap .emailapp-content .emailapp-single-email header.email-header .media .media-body .user-name {
    font-size: 1rem;
    font-weight: 500;
    color: #262a2e;
    text-transform: capitalize;
}

.emailapp-wrap .emailapp-content .emailapp-single-email header.email-header .media .media-body .user-status {
    font-size: 0.875rem;
}

.emailapp-wrap .emailapp-content .emailapp-single-email header.email-header .email-options-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.emailapp-wrap .emailapp-content .emailapp-single-email header.email-header .email-options-wrap>a {
    margin-left: .25rem;
    display: inline-block;
}

.emailapp-wrap .emailapp-content .emailapp-single-email header.email-header .email-options-wrap>a:first-child {
    margin-left: 0;
}

.emailapp-wrap .emailapp-content .emailapp-single-email .email-body {
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
    overflow: hidden;
    -ms-flex: 1;
    flex: 1;
}

.emailapp-wrap .emailapp-content .emailapp-single-email .email-body .nicescroll-bar {
    padding: 1.25rem;
}

.emailapp-wrap .emailapp-content .emailapp-single-email .email-body .single-email-subject {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
    margin-bottom: 1.25rem;
}

.emailapp-wrap .emailapp-content .emailapp-single-email .email-body .single-email-subject>div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.emailapp-wrap .emailapp-content .emailapp-single-email .email-body .single-email-subject>div h4 {
    margin-bottom: 0;
}

.emailapp-wrap .emailapp-content .emailapp-single-email .email-body .single-email-thread .accordion-item {
    overflow: auto;
}

.emailapp-wrap .emailapp-content .emailapp-single-email .email-body .single-email-thread .accordion-item .accordion-header {
    border-color: transparent;
}

.emailapp-wrap .emailapp-content .emailapp-single-email .email-body .single-email-thread .accordion-item .accordion-header>div {
    padding: 1.25rem;
    cursor: pointer;
    width: 100%;
    position: relative;
}

.emailapp-wrap .emailapp-content .emailapp-single-email .email-body .single-email-thread .accordion-item .accordion-header>div .email-head {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
}

.emailapp-wrap .emailapp-content .emailapp-single-email .email-body .single-email-thread .accordion-item .accordion-header>div .email-head .media {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.emailapp-wrap .emailapp-content .emailapp-single-email .email-body .single-email-thread .accordion-item .accordion-header>div .email-head .media .media-head {
    margin-right: 1rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.emailapp-wrap .emailapp-content .emailapp-single-email .email-body .single-email-thread .accordion-item .accordion-header>div .email-head .media .media-body>div:first-child {
    color: #262a2e;
    font-weight: 500;
}

.emailapp-wrap .emailapp-content .emailapp-single-email .email-body .single-email-thread .accordion-item .accordion-header>div .email-head .email-head-action {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.emailapp-wrap .emailapp-content .emailapp-single-email .email-body .single-email-thread .accordion-item .accordion-header>div .email-head .email-head-action .email-time {
    font-size: 0.75rem;
    margin-right: 1.25rem;
}

.emailapp-wrap .emailapp-content .emailapp-single-email .email-body .single-email-thread .accordion-item .accordion-header>div .email-head .email-head-action .email-star {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-right: .625rem;
    position: relative;
    z-index: 1;
}

.emailapp-wrap .emailapp-content .emailapp-single-email .email-body .single-email-thread .accordion-item .accordion-header>div .email-head .email-head-action .email-star>.feather-icon>svg,
.emailapp-wrap .emailapp-content .emailapp-single-email .email-body .single-email-thread .accordion-item .accordion-header>div .email-head .email-head-action .email-star .svg-icon>svg {
    cursor: pointer;
}

.emailapp-wrap .emailapp-content .emailapp-single-email .email-body .single-email-thread .accordion-item .accordion-header>div .email-head .email-head-action .email-star>.feather-icon>svg:hover,
.emailapp-wrap .emailapp-content .emailapp-single-email .email-body .single-email-thread .accordion-item .accordion-header>div .email-head .email-head-action .email-star .svg-icon>svg:hover {
    fill: #FFC400;
    stroke: #FFC400;
}

.emailapp-wrap .emailapp-content .emailapp-single-email .email-body .single-email-thread .accordion-item .accordion-header>div .email-head .email-head-action .email-star.marked>.feather-icon>svg,
.emailapp-wrap .emailapp-content .emailapp-single-email .email-body .single-email-thread .accordion-item .accordion-header>div .email-head .email-head-action .email-star.marked .svg-icon>svg {
    fill: #FFC400;
    stroke: #FFC400;
}

.emailapp-wrap .emailapp-content .emailapp-single-email .email-body .single-email-thread .accordion-item .accordion-header>div .email-head .email-head-action .email-star.marked>.feather-icon>svg:hover,
.emailapp-wrap .emailapp-content .emailapp-single-email .email-body .single-email-thread .accordion-item .accordion-header>div .email-head .email-head-action .email-star.marked .svg-icon>svg:hover {
    fill: none;
    stroke: currentColor;
}

.emailapp-wrap .emailapp-content .emailapp-single-email .email-body .single-email-thread .accordion-item .accordion-header>div .email-head .email-head-action>a {
    display: none;
}

.emailapp-wrap .emailapp-content .emailapp-single-email .email-body .single-email-thread .accordion-item .accordion-header>div .email-head [data-bs-toggle="collapse"] {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    left: 0;
    border-top-left-radius: calc(0.5rem - 1px);
    border-top-right-radius: calc(0.5rem - 1px);
}

.emailapp-wrap .emailapp-content .emailapp-single-email .email-body .single-email-thread .accordion-item .accordion-header>div .email-head [data-bs-toggle="collapse"]~.media .media-body>div:last-child {
    display: none;
}

.emailapp-wrap .emailapp-content .emailapp-single-email .email-body .single-email-thread .accordion-item .accordion-header>div .email-head [data-bs-toggle="collapse"][aria-expanded="true"]~.email-head-action>a {
    display: inline-block;
}

.emailapp-wrap .emailapp-content .emailapp-single-email .email-body .single-email-thread .accordion-item .accordion-header>div .email-head [data-bs-toggle="collapse"][aria-expanded="true"]~.media .media-body>div:last-child {
    display: block;
}

.emailapp-wrap .emailapp-content .emailapp-single-email .email-body .single-email-thread .accordion-item .accordion-header>div[data-bs-toggle="collapse"][aria-expanded="true"] .email-head .email-head-action>a {
    display: inline-block;
}

.emailapp-wrap .emailapp-content .emailapp-single-email .email-body .single-email-thread .accordion-item .email-attachment-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.emailapp-wrap .emailapp-content .emailapp-single-email .email-body .single-email-thread .accordion-item .email-attachment-wrap .attachment-box {
    margin-bottom: .5rem;
    margin-right: .25rem;
}

.emailapp-wrap .emailapp-content .emailapp-single-email .email-body .single-email-thread .accordion-item .email-attachment-wrap .attachment-box>div {
    cursor: pointer;
    padding: .625rem 1rem;
    border-radius: 1.25rem;
    display: inline-block;
    position: relative;
    background: #f2f2f2;
}

.emailapp-wrap .emailapp-content .emailapp-single-email .email-body .single-email-thread .accordion-item .email-attachment-wrap .attachment-box>div .media {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.emailapp-wrap .emailapp-content .emailapp-single-email .email-body .single-email-thread .accordion-item .email-attachment-wrap .attachment-box>div .media .avatar.avatar-sm .avatar-icon {
    font-size: 1.75rem;
}

.emailapp-wrap .emailapp-content .emailapp-single-email .email-body .single-email-thread .accordion-item .email-attachment-wrap .attachment-box>div .media .media-body {
    margin-left: 1.25rem;
}

.emailapp-wrap .emailapp-content .emailapp-single-email .email-body .single-email-thread .accordion-item .email-attachment-wrap .attachment-box>div .media .file-size {
    margin-top: .25rem;
}

.emailapp-wrap .emailapp-content .emailapp-single-email .email-body .single-email-thread .accordion-item .email-attachment-wrap .attachment-box>div .file-overlay {
    position: absolute;
    opacity: 0;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    color: #fff;
    background: rgba(38, 42, 46, 0.4);
    border-radius: 1.25rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
}

.emailapp-wrap .emailapp-content .emailapp-single-email .email-body .single-email-thread .accordion-item .email-attachment-wrap .attachment-box>div:hover .file-overlay {
    opacity: 1;
}

.emailapp-wrap .emailapp-content .emailapp-single-email .email-body .single-email-thread .accordion-item .email-attachment-wrap .attachment-box>div.msg-action:after {
    display: none;
}

.emailapp-wrap .emailapp-content .emailapp-single-email .email-body .single-email-thread .accordion-item .mail-desc-dropdown {
    display: inline-block;
}

.emailapp-wrap .emailapp-content .emailapp-single-email .email-body .single-email-thread .accordion-item .mail-desc-dropdown>.dropdown-toggle {
    position: relative;
    z-index: 1;
}

.emailapp-wrap .emailapp-content .emailapp-single-email .email-body .single-email-thread .accordion-item .mail-desc-dropdown .dropdown-menu,
.emailapp-wrap .emailapp-content .emailapp-single-email .email-body .single-email-thread .accordion-item .mail-desc-dropdown .apexcharts-toolbar .apexcharts-menu,
.apexcharts-toolbar .emailapp-wrap .emailapp-content .emailapp-single-email .email-body .single-email-thread .accordion-item .mail-desc-dropdown .apexcharts-menu {
    padding: 1rem;
    font-size: .875rem;
}

.emailapp-wrap .emailapp-content .emailapp-single-email .email-body .single-email-thread .accordion-item .mail-desc-dropdown .dropdown-menu>div,
.emailapp-wrap .emailapp-content .emailapp-single-email .email-body .single-email-thread .accordion-item .mail-desc-dropdown .apexcharts-toolbar .apexcharts-menu>div,
.apexcharts-toolbar .emailapp-wrap .emailapp-content .emailapp-single-email .email-body .single-email-thread .accordion-item .mail-desc-dropdown .apexcharts-menu>div {
    margin-bottom: .25rem;
}

.emailapp-wrap .emailapp-content .emailapp-single-email .email-body .single-email-thread .accordion-item .mail-desc-dropdown .dropdown-menu>div:last-child,
.emailapp-wrap .emailapp-content .emailapp-single-email .email-body .single-email-thread .accordion-item .mail-desc-dropdown .apexcharts-toolbar .apexcharts-menu>div:last-child,
.apexcharts-toolbar .emailapp-wrap .emailapp-content .emailapp-single-email .email-body .single-email-thread .accordion-item .mail-desc-dropdown .apexcharts-menu>div:last-child {
    margin-bottom: 0;
}

.emailapp-wrap .emailapp-content .emailapp-single-email .email-body .single-email-thread .accordion-item .mail-desc-dropdown .dropdown-menu>div>span,
.emailapp-wrap .emailapp-content .emailapp-single-email .email-body .single-email-thread .accordion-item .mail-desc-dropdown .apexcharts-toolbar .apexcharts-menu>div>span,
.apexcharts-toolbar .emailapp-wrap .emailapp-content .emailapp-single-email .email-body .single-email-thread .accordion-item .mail-desc-dropdown .apexcharts-menu>div>span {
    display: inline-block;
}

.emailapp-wrap .emailapp-content .emailapp-single-email .email-body .single-email-thread .accordion-item .mail-desc-dropdown .dropdown-menu>div>span:first-child,
.emailapp-wrap .emailapp-content .emailapp-single-email .email-body .single-email-thread .accordion-item .mail-desc-dropdown .apexcharts-toolbar .apexcharts-menu>div>span:first-child,
.apexcharts-toolbar .emailapp-wrap .emailapp-content .emailapp-single-email .email-body .single-email-thread .accordion-item .mail-desc-dropdown .apexcharts-menu>div>span:first-child {
    min-width: 50px;
    text-align: right;
    margin-right: .5rem;
}

.emailapp-wrap .emailapp-content .emailapp-single-email .email-body .single-email-thread .accordion-item .mail-desc-dropdown .dropdown-menu>div>span:last-child,
.emailapp-wrap .emailapp-content .emailapp-single-email .email-body .single-email-thread .accordion-item .mail-desc-dropdown .apexcharts-toolbar .apexcharts-menu>div>span:last-child,
.apexcharts-toolbar .emailapp-wrap .emailapp-content .emailapp-single-email .email-body .single-email-thread .accordion-item .mail-desc-dropdown .apexcharts-menu>div>span:last-child {
    color: #262a2e;
}

.emailapp-wrap.emailapp-sidebar-toggle .emailapp-sidebar {
    left: -270px;
}

.emailapp-wrap.emailapp-sidebar-toggle .emailapp-content {
    padding-left: 0;
}

.emailapp-wrap .compose-email-popup {
    position: fixed;
    bottom: 0;
    right: 20px;
    width: 700px;
    overflow-y: auto;
    box-shadow: 0 8px 10px rgba(0, 0, 0, 0.1);
    z-index: 10000;
    background: #fff;
    display: none;
    border-radius: 0.5rem 0.5rem 0 0;
}

.emailapp-wrap .compose-email-popup header {
    Padding: 0.75rem 1.25rem;
    background: #262a2e;
    color: #fff;
}

.emailapp-wrap .compose-email-popup header #max_compose_popup .icon>span:last-child {
    display: none;
}

.emailapp-wrap .compose-email-popup header .btn-close {
    color: #fff;
    opacity: .6;
}

.emailapp-wrap .compose-email-popup header .btn-close:not(:disabled):not(.disabled):focus,
.emailapp-wrap .compose-email-popup header .btn-close:not(:disabled):not(.disabled):hover {
    color: #fff;
    opacity: 1;
}

.emailapp-wrap .compose-email-popup form {
    padding: 1rem;
    flex: 1 1 auto;
    overflow: auto;
}

.emailapp-wrap .compose-email-popup form textarea {
    height: 230px;
}

.emailapp-wrap .compose-email-popup.minimize-email-popup {
    width: 300px;
    min-height: auto;
}

.emailapp-wrap .compose-email-popup.minimize-email-popup form,
.emailapp-wrap .compose-email-popup.minimize-email-popup .compose-email-footer {
    display: none;
}

.emailapp-wrap .compose-email-popup.maximize-email-popup {
    width: calc(100vw - 700px);
    height: calc(100vh - 200px);
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
    margin: auto;
}

.emailapp-wrap .compose-email-popup.maximize-email-popup header #max_compose_popup .icon>span:first-child {
    display: none;
}

.emailapp-wrap .compose-email-popup.maximize-email-popup header #max_compose_popup .icon>span:last-child {
    display: inline;
}

.emailapp-wrap .compose-email-popup.maximize-email-popup form textarea.form-control,
.emailapp-wrap .compose-email-popup.maximize-email-popup form textarea.form-select,
.emailapp-wrap .compose-email-popup.maximize-email-popup form textarea.dd-handle {
    height: calc(100vh - 500px);
}

.emailapp-wrap .compose-email-popup .compose-email-footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
    padding: 0 1rem 1rem;
}

.hk-wrapper.hk__email__backdrop::after {
    position: absolute;
    height: 100%;
    width: 100%;
    background: #262a2e;
    opacity: 0.5;
    z-index: 1033;
    top: 0;
    left: 0;
    content: "";
}

.hk-wrapper[data-navbar-style="collapsed"] .emailapp-wrap {
    height: 100vh;
}

@media (max-width: 1199px) {
    .emailapp-wrap .emailapp-sidebar {
        left: -270px;
    }

    .emailapp-wrap .emailapp-content {
        margin-left: 0;
        padding-left: 0;
    }

    .emailapp-wrap.emailapp-sidebar-toggle .emailapp-sidebar {
        left: 0;
    }

    .emailapp-wrap.emailapp-sidebar-toggle .emailapp-content {
        left: 270px;
        padding-left: 270px;
        margin-left: -270px;
    }

    .emailapp-wrap .hk-sidebar-togglable {
        -webkit-transform: translateX(32px);
        -moz-transform: translateX(32px);
        -ms-transform: translateX(32px);
        -o-transform: translateX(32px);
        transform: translateX(32px);
    }

    .emailapp-wrap .hk-sidebar-togglable:after {
        content: "\ea6c";
    }

    .emailapp-wrap .hk-sidebar-togglable.active {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }

    .emailapp-wrap .hk-sidebar-togglable.active:after {
        content: "\ea62";
    }
}

@media (max-width: 991px) {
    .emailapp-wrap .emailapp-content .emailapp-aside {
        width: 100%;
    }

    .emailapp-wrap .emailapp-content .emailapp-single-email {
        right: -100%;
        position: fixed;
        top: 0;
        width: 100%;
    }

    .emailapp-wrap .emailapp-content .emailapp-single-email header.email-header {
        justify-content: flex-start;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: flex-start;
    }

    .emailapp-wrap .emailapp-content .emailapp-single-email header.email-header .back-user-list {
        display: block;
        flex-shrink: 0;
    }

    .emailapp-wrap .emailapp-content .emailapp-single-email header.email-header .email-options-wrap+.email-options-wrap {
        margin-left: auto;
    }

    .emailapp-wrap .emailapp-content .emailapp-single-email header.email-header .hk-navbar-togglable {
        display: none !important;
    }

    .emailapp-wrap.emailapp-slide .emailapp-content .emailapp-aside {
        left: -100%;
    }

    .emailapp-wrap.emailapp-slide .emailapp-content .emailapp-single-email {
        right: 0;
    }

    .emailapp-wrap .compose-email-popup {
        width: 100%;
        height: 100%;
        right: 0;
        border-radius: 0;
    }

    .emailapp-wrap .compose-email-popup form textarea.form-control,
    .emailapp-wrap .compose-email-popup form textarea.form-select,
    .emailapp-wrap .compose-email-popup form textarea.dd-handle {
        height: calc(100vh - 300px) !important;
    }

    .emailapp-wrap .compose-email-popup.maximize-email-popup {
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        border-radius: 0;
    }
}

/*Taskboard*/
.taskboardapp-wrap {
    position: relative;
    height: calc(100vh - 65px);
    -webkit-transition: height 0.2s ease;
    -moz-transition: height 0.2s ease;
    transition: height 0.2s ease;
}

.taskboardapp-wrap .taskboardapp-sidebar {
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-flow: column;
    left: 0;
    width: 270px;
    margin-left: 0;
    top: 0;
    height: 100%;
    z-index: 1;
    border: none;
    background: #fff;
    border-right: 1px solid #eaeaea;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.taskboardapp-wrap .taskboardapp-sidebar>div.nicescroll-bar {
    flex: 1 1 auto;
    overflow: auto;
}

.taskboardapp-wrap .taskboardapp-sidebar .menu-content-wrap {
    padding: 1.5rem;
}

.taskboardapp-wrap .taskboardapp-sidebar .menu-content-wrap .menu-gap {
    height: 25px;
}

.taskboardapp-wrap .taskboardapp-sidebar .menu-content-wrap .menu-group>.navbar-nav>.nav-item {
    margin-left: -1rem;
    margin-right: -1rem;
}

.taskboardapp-wrap .taskboardapp-sidebar .menu-content-wrap .menu-group>.navbar-nav>.nav-item>.nav-link,
.taskboardapp-wrap .taskboardapp-sidebar .menu-content-wrap .menu-group>.navbar-nav>.nav-item>div {
    border-radius: .5rem;
    padding: .5rem 1rem;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.taskboardapp-wrap .taskboardapp-sidebar .menu-content-wrap .menu-group>.navbar-nav>.nav-item>.nav-link .nav-icon-wrap,
.taskboardapp-wrap .taskboardapp-sidebar .menu-content-wrap .menu-group>.navbar-nav>.nav-item>div .nav-icon-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 1.5rem;
    margin-right: .875rem;
}

.taskboardapp-wrap .taskboardapp-sidebar .menu-content-wrap .menu-group>.navbar-nav>.nav-item>.nav-link .nav-icon-wrap .nav-link-text,
.taskboardapp-wrap .taskboardapp-sidebar .menu-content-wrap .menu-group>.navbar-nav>.nav-item>div .nav-icon-wrap .nav-link-text {
    display: inline-block;
}

.taskboardapp-wrap .taskboardapp-sidebar .menu-content-wrap .menu-group>.navbar-nav>.nav-item.active>.nav-link {
    background: rgba(0, 125, 136, 0.15);
    color: #09769F;
}

.taskboardapp-wrap .taskboardapp-sidebar .taskboardapp-fixednav {
    height: 61px;
    border-top: 1px solid #eaeaea;
}

.taskboardapp-wrap .taskboardapp-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    padding-left: 270px;
    position: relative;
    height: 100%;
    left: 0;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.taskboardapp-wrap .taskboardapp-content .taskboardapp-detail-wrap {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-grow: 1;
    background: #fff;
    flex-flow: column;
    height: 100%;
    width: 100%;
    z-index: 10;
}

.taskboardapp-wrap .taskboardapp-content .taskboardapp-detail-wrap header.taskboard-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
    height: 65px;
    padding: 0 1.25rem;
    background: #fff;
    border-bottom: 1px solid #eaeaea;
    position: relative;
}

.taskboardapp-wrap .taskboardapp-content .taskboardapp-detail-wrap header.taskboard-header .taskboardapp-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    white-space: nowrap;
}

.taskboardapp-wrap .taskboardapp-content .taskboardapp-detail-wrap header.taskboard-header .taskboardapp-title h1 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 0;
}

.taskboardapp-wrap .taskboardapp-content .taskboardapp-detail-wrap header.taskboard-header .taskboardapp-title h1 .task-star {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-left: .625rem;
    font-size: 1.25rem;
}

.taskboardapp-wrap .taskboardapp-content .taskboardapp-detail-wrap header.taskboard-header .taskboardapp-title h1 .task-star>.feather-icon>svg,
.taskboardapp-wrap .taskboardapp-content .taskboardapp-detail-wrap header.taskboard-header .taskboardapp-title h1 .task-star .svg-icon>svg {
    cursor: pointer;
}

.taskboardapp-wrap .taskboardapp-content .taskboardapp-detail-wrap header.taskboard-header .taskboardapp-title h1 .task-star>.feather-icon>svg:hover,
.taskboardapp-wrap .taskboardapp-content .taskboardapp-detail-wrap header.taskboard-header .taskboardapp-title h1 .task-star .svg-icon>svg:hover {
    fill: #FFC400;
    stroke: #FFC400;
}

.taskboardapp-wrap .taskboardapp-content .taskboardapp-detail-wrap header.taskboard-header .taskboardapp-title h1 .task-star.marked>.feather-icon>svg,
.taskboardapp-wrap .taskboardapp-content .taskboardapp-detail-wrap header.taskboard-header .taskboardapp-title h1 .task-star.marked .svg-icon>svg {
    fill: #FFC400;
    stroke: #FFC400;
}

.taskboardapp-wrap .taskboardapp-content .taskboardapp-detail-wrap header.taskboard-header .taskboardapp-title h1 .task-star.marked>.feather-icon>svg:hover,
.taskboardapp-wrap .taskboardapp-content .taskboardapp-detail-wrap header.taskboard-header .taskboardapp-title h1 .task-star.marked .svg-icon>svg:hover {
    fill: none;
    stroke: currentColor;
}

.taskboardapp-wrap .taskboardapp-content .taskboardapp-detail-wrap header.taskboard-header .taskboardapp-title:after {
    top: 0;
}

.taskboardapp-wrap .taskboardapp-content .taskboardapp-detail-wrap header.taskboard-header .media {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.taskboardapp-wrap .taskboardapp-content .taskboardapp-detail-wrap header.taskboard-header .media .media-head {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-right: 1rem;
}

.taskboardapp-wrap .taskboardapp-content .taskboardapp-detail-wrap header.taskboard-header .media .media-body .user-name {
    font-size: 1rem;
    font-weight: 500;
    color: #262a2e;
    text-transform: capitalize;
}

.taskboardapp-wrap .taskboardapp-content .taskboardapp-detail-wrap header.taskboard-header .media .media-body .user-status {
    font-size: 0.875rem;
}

.taskboardapp-wrap .taskboardapp-content .taskboardapp-detail-wrap header.taskboard-header .taskboard-options-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.taskboardapp-wrap .taskboardapp-content .taskboardapp-detail-wrap header.taskboard-header .taskboard-options-wrap>a {
    margin-left: .25rem;
    display: inline-block;
}

.taskboardapp-wrap .taskboardapp-content .taskboardapp-detail-wrap header.taskboard-header .taskboard-options-wrap>a:first-child {
    margin-left: 0;
}

.taskboardapp-wrap .taskboardapp-content .taskboardapp-detail-wrap header.taskboard-header .taskboard-options-wrap>.d-flex.ms-auto>a {
    margin-left: .25rem;
    display: inline-block;
}

.taskboardapp-wrap .taskboardapp-content .taskboardapp-detail-wrap header.taskboard-header .taskboard-options-wrap>.d-flex.ms-auto>a:first-child {
    margin-left: 0;
}

.taskboardapp-wrap .taskboardapp-content .taskboardapp-detail-wrap .taskboard-body {
    overflow: hidden;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.taskboardapp-wrap .taskboardapp-content .taskboardapp-detail-wrap .taskboard-body>div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-flow: column;
    width: 100%;
}

.taskboardapp-wrap .taskboardapp-content .taskboardapp-detail-wrap .taskboard-body>div .taskbar-toolbar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
    padding: 1.25rem;
}

.taskboardapp-wrap .taskboardapp-content .taskboardapp-detail-wrap .taskboard-body>div .tasklist-scroll {
    display: -ms-flexbox;
    display: flex;
    overflow-x: auto;
    max-height: 100%;
    overflow-y: hidden;
    margin-top: 2.25rem;
    padding-bottom: 1.25rem;
    -webkit-transition: width 0.2s ease;
    -moz-transition: width 0.2s ease;
    transition: width 0.2s ease;
}

.taskboardapp-wrap .taskboardapp-content .taskboardapp-detail-wrap .taskboard-body>div .tasklist-wrap {
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: flex-start;
    -ms-flex-align: flex-start;
    align-items: flex-start;
    padding: 0 1.25rem;
    float: left;
}

.taskboardapp-wrap .taskboardapp-content .taskboardapp-detail-wrap .taskboard-body>div .tasklist-wrap .tasklist {
    max-height: 100%;
    margin: 0 .875rem;
}

.taskboardapp-wrap .taskboardapp-content .taskboardapp-detail-wrap .taskboard-body>div .tasklist-wrap .tasklist:first-child {
    margin-left: 0;
}

.taskboardapp-wrap .taskboardapp-content .taskboardapp-detail-wrap .taskboard-body>div .tasklist-wrap .tasklist:last-child {
    margin-right: 0;
}

.taskboardapp-wrap .taskboardapp-content .taskboardapp-detail-wrap .taskboard-body>div .tasklist-wrap .spipeline-list {
    margin: 0 .875rem;
}

.taskboardapp-wrap .taskboardapp-content .taskboardapp-detail-wrap .taskboard-body>div .tasklist-wrap .spipeline-list:first-child {
    margin-left: 0;
}

.taskboardapp-wrap .taskboardapp-content .taskboardapp-detail-wrap .taskboard-body>div .tasklist-wrap .spipeline-list:last-child {
    margin-right: 0;
}

.taskboardapp-wrap .taskboardapp-content .taskboardapp-detail-wrap .taskboard-body .board-team-wrap {
    margin-top: 3.75rem;
    margin-bottom: 3.75rem;
}

.taskboardapp-wrap .taskboardapp-content .taskboardapp-detail-wrap .taskboard-body .board-team-wrap .tab-pane .row>[class*="col-"] {
    margin-bottom: 1.875rem;
}

.taskboardapp-wrap .taskboardapp-content .taskboardapp-detail-wrap .taskboard-body .board-team-wrap .tab-pane .row>[class*="col-"] .card {
    margin-bottom: 0;
}

.taskboardapp-wrap .taskboardapp-content .taskboardapp-detail-wrap .taskboard-body .board-team-wrap .tab-pane .row>[class*="col-"] .card .media .media-head {
    margin-right: 1rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.taskboardapp-wrap .taskboardapp-content .taskboardapp-detail-wrap .taskboard-body .board-team-wrap .tab-pane .row>[class*="col-"] .card.board-card .media .media-head {
    margin-right: 1rem;
}

.taskboardapp-wrap .taskboardapp-content .taskboardapp-detail-wrap .taskboard-body .board-team-wrap .tab-pane .row>[class*="col-"] .card.board-card .media .media-body {
    overflow: hidden;
}

.taskboardapp-wrap .taskboardapp-content .taskboardapp-detail-wrap .taskboard-body .board-team-wrap .tab-pane .row>[class*="col-"] .card.board-card .media .media-body span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #262a2e;
    font-weight: 500;
    font-size: 1.125rem;
}

.taskboardapp-wrap .taskboardapp-content .taskboardapp-detail-wrap .taskboard-body .board-team-wrap .tab-pane .row>[class*="col-"] .card.team-card .media .media-head {
    margin-right: 1rem;
}

.taskboardapp-wrap .taskboardapp-content .taskboardapp-detail-wrap .taskboard-body .board-team-wrap .tab-pane .row>[class*="col-"] .card.team-card .media .media-body {
    overflow: hidden;
}

.taskboardapp-wrap .taskboardapp-content .taskboardapp-detail-wrap .taskboard-body .board-team-wrap .tab-pane .row>[class*="col-"] .card.team-card .media .media-body>div:first-child {
    color: #262a2e;
    font-weight: 500;
    font-size: 1.125rem;
    margin-bottom: .25rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.taskboardapp-wrap .taskboardapp-content .taskboardapp-detail-wrap .taskboard-body .board-team-wrap .tab-pane .row>[class*="col-"] .card.team-card .media .media-body>div:first-child .badge {
    margin-left: .625rem;
    flex-shrink: 0;
}

.taskboardapp-wrap .taskboardapp-content .taskboardapp-detail-wrap .taskboard-body .board-team-wrap .tab-pane .row>[class*="col-"] .card.team-card .media .media-body>div:first-child span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 200px;
}

.taskboardapp-wrap .taskboardapp-content .taskboardapp-detail-wrap .taskboard-body .board-team-wrap .tab-pane .row>[class*="col-"] .card.team-card .media .media-body>div:last-child {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.taskboardapp-wrap .taskboardapp-content .taskboardapp-detail-wrap .taskboard-body.taskboard-body-alt {
    background: #f7f7f7;
}

.taskboardapp-wrap .taskboardapp-content .taskboardapp-detail-wrap .taskboard-body.taskboard-body-alt>div .tasklist-scroll {
    overflow: auto;
    max-height: inherit;
    height: 100%;
    margin-top: 0;
    padding-bottom: 1.25rem;
    padding-top: 1.875rem;
    margin-bottom: 5.125rem;
}

.taskboardapp-wrap .taskboardapp-content .taskboardapp-detail-wrap .taskboard-info {
    position: absolute;
    top: 65px;
    right: -370px;
    width: 370px;
    margin-left: 0;
    bottom: 0;
    z-index: 100;
    border: none;
    background: #fff;
    border-left: 1px solid #eaeaea;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.taskboardapp-wrap .taskboardapp-content .taskboardapp-detail-wrap .taskboard-info .nicescroll-bar {
    padding: 1.25rem;
}

.taskboardapp-wrap.taskboardapp-sidebar-toggle .taskboardapp-sidebar {
    left: -270px;
}

.taskboardapp-wrap.taskboardapp-sidebar-toggle .taskboardapp-content {
    padding-left: 0;
}

.taskboardapp-wrap.taskboardapp-info-active .taskboardapp-detail-wrap .taskboard-info {
    right: 0;
}

.tasklist {
    min-height: 100px;
    width: 350px;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    background: #f7f7f7;
}

.tasklist>.card-header {
    flex-flow: column;
}

.tasklist>.card-header>div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
    width: 100%;
    margin-bottom: 1rem;
}

.tasklist .tasklist-handle {
    cursor: grab;
}

.tasklist .tasklist-handle h6 {
    pointer-events: none;
}

.tasklist.add-new-task {
    min-height: 0;
    border: 0;
    background: transparent;
}

.spipeline-list {
    min-height: 100px;
    width: 350px;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    background: transparent;
}

.spipeline-list>.card-header {
    flex-flow: column;
    background: #fff;
    border-radius: 0.5rem !important;
    margin-bottom: .875rem;
}

.spipeline-list>.card-header>div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
    width: 100%;
}

.spipeline-list>.card-header>div .overall-estimation {
    color: #262a2e;
    font-weight: 500;
}

.spipeline-list>.card-header>div .spipeline-dot-sep {
    color: #d8d8d8;
    margin: 0 5px;
}

.spipeline-list>.card-header>div .lead-count {
    font-size: 0.75rem;
}

.spipeline-list>.card-header .btn-block {
    margin-top: 1rem;
}

.spipeline-list>.card-body {
    border-radius: 0.5rem !important;
    padding: 0;
}

.spipeline-list .spipeline-handle {
    cursor: grab;
}

.spipeline-list .spipeline-handle h6 {
    pointer-events: none;
}

.spipeline-list.create-new-list {
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    min-height: 139px;
}

.spipeline-list.create-new-list>.card-header {
    background: transparent;
    margin: 0;
}

.spipeline-list.create-new-list>.card-header .btn-block {
    margin: 0;
}

.tasklist-card .card-header .card-action-wrap {
    opacity: 0;
    -webkit-transition: opacity 0.2s ease;
    -moz-transition: opacity 0.2s ease;
    transition: opacity 0.2s ease;
}

.tasklist-card:hover .card-header .card-action-wrap {
    opacity: 1;
}

.tasklist-card .card-body {
    padding-top: 0;
}

.tasklist-card .card-footer>div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.tasklist-card .card-footer .task-counter,
.tasklist-card .card-footer .task-discuss {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 0.875rem;
}

.tasklist-card .card-footer .task-counter>span,
.tasklist-card .card-footer .task-discuss>span {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.tasklist-card .card-footer .task-counter>span:first-child,
.tasklist-card .card-footer .task-discuss>span:first-child {
    margin-right: .25rem;
}

.tasklist-card .card-footer .task-discuss {
    margin-left: .5rem;
}

.tasklist-card .card-footer .task-deadline {
    font-size: 0.875rem;
}

.spipeline-card .card-body {
    padding: .75rem 1.25rem;
}

.spipeline-card .card-body>.media .media-head {
    margin-right: 1rem;
}

.spipeline-card .card-body>.media .media-body .brand-name {
    color: #262a2e;
    font-weight: 600;
}

.spipeline-card .card-body>.media .media-body .price-estimation {
    color: #262a2e;
    font-weight: 500;
}

.spipeline-card .card-body>.media .media-body .media {
    margin-top: .25rem;
}

.spipeline-card .card-body>.media .media-body .media .media-head {
    margin-right: .25rem;
}

.spipeline-card .card-body>.media .media-body .media .media-body p {
    font-size: 0.75rem;
}

.spipeline-card .card-body .spipeline-dropdown .position-relative {
    top: -10px;
}

.spipeline-card .card-body .spipeline-dropdown .dropdown-item {
    padding: .5rem .75rem;
}

.spipeline-card.lost-deal {
    background: #ffebeb;
}

.spipeline-card.won-deal {
    background: #ebfcf5;
}

.hk-wrapper[data-navbar-style="collapsed"] .taskboardapp-wrap {
    height: 100vh;
}

.task-detail-modal .modal-body header.task-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
    padding: 1.25rem;
    border-radius: 0.5rem 0.5rem 0 0;
    height: 65px;
    background: #fff;
    border-bottom: 1px solid #eaeaea;
    position: relative;
    margin-right: 12.5rem;
}

.task-detail-modal .modal-body header.task-header .apexcharts-datalabels-group text {
    font-family: inherit;
    font-size: 0.75rem;
    fill: #6f6f6f;
    position: absolute;
    transform: translateY(5px);
}

.task-detail-modal .modal-body header.task-header .apexcharts-datalabels-group text:last-child {
    display: none;
}

.task-detail-modal .modal-body header.task-header .task-options-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.task-detail-modal .modal-body header.task-header .task-options-wrap .task-star {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.task-detail-modal .modal-body header.task-header .task-options-wrap .task-star>.feather-icon>svg,
.task-detail-modal .modal-body header.task-header .task-options-wrap .task-star .svg-icon>svg {
    height: 21px;
    width: 21px;
    cursor: pointer;
}

.task-detail-modal .modal-body header.task-header .task-options-wrap .task-star>.feather-icon>svg:hover,
.task-detail-modal .modal-body header.task-header .task-options-wrap .task-star .svg-icon>svg:hover {
    fill: #FFC400;
    stroke: #FFC400;
}

.task-detail-modal .modal-body header.task-header .task-options-wrap .task-star.marked>.feather-icon>svg,
.task-detail-modal .modal-body header.task-header .task-options-wrap .task-star.marked .svg-icon>svg {
    fill: #FFC400;
    stroke: #FFC400;
}

.task-detail-modal .modal-body header.task-header .task-options-wrap .task-star.marked>.feather-icon>svg:hover,
.task-detail-modal .modal-body header.task-header .task-options-wrap .task-star.marked .svg-icon>svg:hover {
    fill: none;
    stroke: currentColor;
}

.task-detail-modal .modal-body header.task-header .task-options-wrap>a {
    display: inline-block;
}

.task-detail-modal .modal-body header.task-header .task-options-wrap>a:first-child {
    margin-left: 0;
}

.task-detail-modal .modal-body .task-detail-body {
    padding: 1.25rem;
    margin-right: 12.5rem;
}

.task-detail-modal .modal-body .task-detail-body form .media .media-head {
    margin-right: 1rem;
}

.task-detail-modal .modal-body .task-detail-body form .media .media-body .as-name {
    font-weight: 500;
    color: #262a2e;
}

.task-detail-modal .modal-body .task-detail-body .tab-content .tab-pane .note-block .media {
    margin-bottom: 1.25rem;
}

.task-detail-modal .modal-body .task-detail-body .tab-content .tab-pane .note-block .media .media-head {
    margin-right: 1rem;
}

.task-detail-modal .modal-body .task-detail-body .tab-content .tab-pane .note-block .media .media-body div:first-child {
    font-weight: 500;
    color: #262a2e;
}

.task-detail-modal .modal-body .task-detail-body .tab-content .tab-pane .note-block .media .media-body div:last-child {
    font-size: 0.875rem;
}

.task-detail-modal .modal-body .task-detail-body .tab-content .tab-pane .comment-block .media .media-head {
    margin-right: 1rem;
}

.task-detail-modal .modal-body .task-detail-body .tab-content .tab-pane .comment-block .media .media-body>div:first-child {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 1rem;
}

.task-detail-modal .modal-body .task-detail-body .tab-content .tab-pane .comment-block .media .media-body>div:first-child .cm-name {
    font-weight: 500;
    color: #262a2e;
}

.task-detail-modal .modal-body .task-detail-body .tab-content .tab-pane .comment-block .media .media-body>div:first-child .badge {
    margin-left: .625rem;
    flex-shrink: 0;
}

.task-detail-modal .modal-body .task-detail-body .tab-content .tab-pane .comment-block .media .media-body .comment-action-wrap span,
.task-detail-modal .modal-body .task-detail-body .tab-content .tab-pane .comment-block .media .media-body .comment-action-wrap a {
    color: #6f6f6f;
    font-size: 0.75rem;
}

.task-detail-modal .modal-body .task-detail-body .tab-content .tab-pane .comment-block .media .media-body .comment-action-wrap .comment-dot-sep {
    margin: 0 .25rem;
    color: #d8d8d8;
}

.task-detail-modal .modal-body .task-detail-body .tab-content .tab-pane .comment-block .media .media-body>.media {
    margin-top: 1.875rem;
}

.task-detail-modal .modal-body .task-detail-body .tab-content .tab-pane .file-block .sh-files {
    margin: 0 -.8rem;
    padding-left: 0;
}

.task-detail-modal .modal-body .task-detail-body .tab-content .tab-pane .file-block .sh-files li {
    padding: .8rem;
    border-radius: 0.5rem;
    -webkit-transition: 0.2s ease;
    -moz-transition: 0.2s ease;
    transition: 0.2s ease;
    margin-bottom: .25rem;
}

.task-detail-modal .modal-body .task-detail-body .tab-content .tab-pane .file-block .sh-files li:last-child {
    margin-bottom: 0;
}

.task-detail-modal .modal-body .task-detail-body .tab-content .tab-pane .file-block .sh-files li .media {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.task-detail-modal .modal-body .task-detail-body .tab-content .tab-pane .file-block .sh-files li .media .media-head {
    margin-right: 1rem;
}

.task-detail-modal .modal-body .task-detail-body .tab-content .tab-pane .file-block .sh-files li .media .media-head .avatar.avatar-sm .avatar-icon {
    font-size: 1.75rem;
}

.task-detail-modal .modal-body .task-detail-body .tab-content .tab-pane .file-block .sh-files li .media .media-body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-width: 0;
}

.task-detail-modal .modal-body .task-detail-body .tab-content .tab-pane .file-block .sh-files li .media .media-body>div:first-child {
    min-width: 0;
    -ms-flex: 1;
    flex: 1;
}

.task-detail-modal .modal-body .task-detail-body .tab-content .tab-pane .file-block .sh-files li .media .media-body>div:last-child {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.task-detail-modal .modal-body .task-detail-body .tab-content .tab-pane .file-block .sh-files li .media .media-body .file-name {
    color: #262a2e;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 500;
    max-width: 200px;
}

.task-detail-modal .modal-body .task-detail-body .tab-content .tab-pane .file-block .sh-files li .media .media-body .file-size {
    margin-top: .25rem;
    font-size: 0.875rem;
}

.task-detail-modal .modal-body .task-detail-body .tab-content .tab-pane .file-block .sh-files li:hover {
    background-color: #f2f2f2;
}

.task-detail-modal .modal-body .task-detail-body .tab-content .tab-pane .file-block .sh-files li:hover .media .media-body .file-name {
    color: #09769F;
}

.task-detail-modal .modal-body .task-detail-body .tab-content .tab-pane .file-block .sh-files li:hover .media .media-body a.cp-file-action {
    color: #262a2e;
}

.task-detail-modal .modal-body .task-action-wrap {
    position: absolute;
    top: 0;
    width: 200px;
    right: 0;
    z-index: 1;
    border-left: 1px solid #eaeaea;
    bottom: 0;
    background: #fff;
}

.task-detail-modal .modal-body .task-action-wrap .nicescroll-bar {
    padding: 1.5rem;
}

.task-detail-modal .modal-body .task-action-wrap .nicescroll-bar .nav-vertical>.nav-item>.nav-link {
    padding-left: 0;
    padding-right: 0;
}

@media (max-width: 1399px) {
    .taskboardapp-wrap .taskboardapp-sidebar {
        left: -270px;
    }

    .taskboardapp-wrap .taskboardapp-content {
        margin-left: 0;
        padding-left: 0;
    }

    .taskboardapp-wrap.taskboardapp-sidebar-toggle .taskboardapp-sidebar {
        left: 0;
    }

    .taskboardapp-wrap.taskboardapp-sidebar-toggle .taskboardapp-content {
        left: 270px;
        padding-left: 270px;
        margin-left: -270px;
    }

    .taskboardapp-wrap .hk-sidebar-togglable {
        -webkit-transform: translateX(32px);
        -moz-transform: translateX(32px);
        -ms-transform: translateX(32px);
        -o-transform: translateX(32px);
        transform: translateX(32px);
    }

    .taskboardapp-wrap .hk-sidebar-togglable:after {
        content: "\ea6c";
    }

    .taskboardapp-wrap .hk-sidebar-togglable.active {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }

    .taskboardapp-wrap .hk-sidebar-togglable.active:after {
        content: "\ea62";
    }
}

@media (max-width: 1199px) {
    .taskboardapp-wrap .taskboardapp-content .taskboardapp-detail-wrap .taskboard-info {
        top: 0;
    }

    .info-close {
        position: relative;
        top: -4px;
    }

    .task-detail-modal .modal-body header.task-header,
    .task-detail-modal .modal-body .task-detail-body {
        margin-right: 0;
    }

    .task-action-wrap {
        display: none;
    }
}

/*Checklist List*/
.hk-checklist .form-check {
    margin-bottom: .625rem;
    position: relative;
}

.hk-checklist .form-check .form-check-label {
    position: relative;
}

.hk-checklist .form-check .done-strikethrough {
    display: block;
    height: 12px;
    background: #FF0000;
    mix-blend-mode: multiply;
    opacity: .08;
    width: 0;
    transition: width .35s ease-in-out, opacity .25s ease-in-out;
    position: absolute;
    margin-left: 0;
    top: 35%;
    left: 0;
    pointer-events: none;
    content: "";
}

.hk-checklist .form-check.form-check input:checked+label .done-strikethrough {
    width: 100%;
}

.hk-checklist .form-check .delete-checklist {
    position: absolute;
    right: 0;
    opacity: 0;
    -webkit-transition: opacity 0.2s ease-in-out;
    -moz-transition: opacity 0.2s ease-in-out;
    transition: opacity 0.2s ease-in-out;
}

.hk-checklist .form-check:hover .delete-checklist {
    opacity: 1;
}

.hk-checklist .add-new-checklist .feather-icon {
    margin-right: .25rem;
}

.hk-checklist .form-control.checklist-input,
.hk-checklist .checklist-input.form-select,
.hk-checklist .wizard>.content>.body input.checklist-input,
.wizard>.content>.body .hk-checklist input.checklist-input,
.hk-checklist .checklist-input.dd-handle {
    position: absolute;
    top: 0;
    border: none;
    box-shadow: none;
    padding: 0;
    height: auto;
    width: auto;
}

/*Contact */
.contactapp-wrap {
    position: relative;
    height: calc(100vh - 65px);
}

.contactapp-wrap .contactapp-sidebar {
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-flow: column;
    left: 0;
    width: 270px;
    margin-left: 0;
    top: 0;
    height: 100%;
    z-index: 1;
    border: none;
    background: #fff;
    border-right: 1px solid #eaeaea;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.contactapp-wrap .contactapp-sidebar>div.nicescroll-bar {
    flex: 1 1 auto;
    overflow: auto;
}

.contactapp-wrap .contactapp-sidebar .menu-content-wrap {
    padding: 1.5rem;
}

.contactapp-wrap .contactapp-sidebar .menu-content-wrap .menu-gap {
    height: 25px;
}

.contactapp-wrap .contactapp-sidebar .menu-content-wrap .menu-group>.navbar-nav>.nav-item {
    margin-left: -1rem;
    margin-right: -1rem;
}

.contactapp-wrap .contactapp-sidebar .menu-content-wrap .menu-group>.navbar-nav>.nav-item>.nav-link {
    border-radius: .5rem;
    padding: .5rem 1rem;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.contactapp-wrap .contactapp-sidebar .menu-content-wrap .menu-group>.navbar-nav>.nav-item>.nav-link .nav-icon-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-right: .875rem;
    font-size: 1.5rem;
}

.contactapp-wrap .contactapp-sidebar .menu-content-wrap .menu-group>.navbar-nav>.nav-item>.nav-link .nav-icon-wrap .nav-link-text {
    display: inline-block;
}

.contactapp-wrap .contactapp-sidebar .menu-content-wrap .menu-group>.navbar-nav>.nav-item.active>.nav-link {
    background: rgba(0, 125, 136, 0.15);
    color: #09769F;
}

.contactapp-wrap .contactapp-sidebar .menu-content-wrap .tag-cloud .badge {
    margin-bottom: .25rem;
}

.contactapp-wrap .contactapp-sidebar .contactapp-fixednav {
    height: 61px;
    border-top: 1px solid #eaeaea;
}

.contactapp-wrap .contactapp-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    padding-left: 270px;
    position: relative;
    height: 100%;
    left: 0;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.contactapp-wrap .contactapp-content header.contact-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
    height: 65px;
    padding: 0 1.25rem;
    background: #fff;
    border-bottom: 1px solid #eaeaea;
    position: relative;
}

.contactapp-wrap .contactapp-content header.contact-header .contactapp-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.contactapp-wrap .contactapp-content header.contact-header .contactapp-title h1 {
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 0;
}

.contactapp-wrap .contactapp-content header.contact-header .contactapp-title:after {
    top: 0;
}

.contactapp-wrap .contactapp-content header.contact-header .media {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.contactapp-wrap .contactapp-content header.contact-header .media .media-head {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-right: 1rem;
}

.contactapp-wrap .contactapp-content header.contact-header .media .media-body .user-name {
    font-size: 1rem;
    font-weight: 500;
    color: #262a2e;
    text-transform: capitalize;
}

.contactapp-wrap .contactapp-content header.contact-header .media .media-body .user-status {
    font-size: 0.875rem;
}

.contactapp-wrap .contactapp-content header.contact-header .contact-options-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.contactapp-wrap .contactapp-content header.contact-header .contact-options-wrap>a {
    margin-left: .25rem;
    display: inline-block;
}

.contactapp-wrap .contactapp-content header.contact-header .contact-options-wrap>a:first-child {
    margin-left: 0;
}

.contactapp-wrap .contactapp-content .contactapp-detail-wrap {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-grow: 1;
    background: #fff;
    flex-flow: column;
    height: 100%;
    z-index: 10;
}

.contactapp-wrap .contactapp-content .contactapp-detail-wrap .contact-body {
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
    overflow: hidden;
    -ms-flex: 1;
    flex: 1;
}

.contactapp-wrap .contactapp-content .contactapp-detail-wrap .contact-body {
    padding: 1.25rem;
}

.contactapp-wrap .contactapp-content .contactapp-detail-wrap .contact-body .quick-access-form-wrap {
    padding: 1rem;
}

.contactapp-wrap .contactapp-content .contactapp-detail-wrap .contact-body .quick-access-form-wrap .quick-access-form {
    border-radius: 0.5rem;
    padding: 1.25rem;
    background: #f7f7f7;
}

.contactapp-wrap .contactapp-content .contactapp-detail-wrap .contact-body .quick-access-form-wrap .quick-access-form .dropify-square {
    position: absolute;
}

.contactapp-wrap .contactapp-content .contactapp-detail-wrap .contact-body .quick-access-form-wrap .quick-access-form .dropify-square .dropify-wrapper {
    height: 5.75rem;
    width: 5.75rem;
}

.contactapp-wrap .contactapp-content .contactapp-detail-wrap .contact-body .quick-access-form-wrap .quick-access-form .col-md-12 {
    padding-left: 7.125rem;
}

.contact-list-view  .contact-toolbar-left,
.contact-list-view  .contact-toolbar-right,
.contact-card-view  .contact-toolbar-left,
.contact-card-view  .contact-toolbar-right {
    min-height: 45px;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.contact-list-view  .contact-toolbar-left>div,
.contact-card-view  .contact-toolbar-left>div {
    margin-right: 1rem;
}

.contact-list-view  .contact-toolbar-left>div:last-child,
.contact-card-view  .contact-toolbar-left>div:last-child {
    margin-right: 0;
}

.contact-list-view  .contact-toolbar-right,
.contact-card-view  .contact-toolbar-right {
    justify-content: flex-end;
    -webkit-justify-content: flex-end;
}

.contact-list-view  .contact-toolbar-right>div,
.contact-card-view  .contact-toolbar-right>div {
    margin-left: 1rem;
}

.contact-list-view  .contact-toolbar-right>div:first-child,
.contact-card-view  .contact-toolbar-right>div:first-child {
    margin-left: 0;
}

.contact-list-view  .contact-toolbar-right .dataTables_filter,
.contact-list-view  .contact-toolbar-right .dataTables_length,
.contact-list-view  .contact-toolbar-right .dataTables_info,
.contact-card-view  .contact-toolbar-right .dataTables_filter,
.contact-card-view  .contact-toolbar-right .dataTables_length,
.contact-card-view  .contact-toolbar-right .dataTables_info {
    float: none;
}

.contact-list-view  .contact-toolbar-right .dataTables_filter label,
.contact-list-view  .contact-toolbar-right .dataTables_length label,
.contact-list-view  .contact-toolbar-right .dataTables_info label,
.contact-card-view  .contact-toolbar-right .dataTables_filter label,
.contact-card-view  .contact-toolbar-right .dataTables_length label,
.contact-card-view  .contact-toolbar-right .dataTables_info label {
    margin-bottom: 0;
}

.contact-list-view  .contact-toolbar-right .dataTables_info,
.contact-card-view  .contact-toolbar-right .dataTables_info {
    padding-top: 0;
    width: 95px;
    text-align: right;
}

.contact-list-view  .contact-toolbar-right .dataTables_paginate,
.contact-card-view  .contact-toolbar-right .dataTables_paginate {
    padding-top: 0;
}

.contact-list-view  .contact-toolbar-right .dataTables_paginate .pagination.custom-pagination .paginate_button:not(:first-child):not(:last-child),
.contact-card-view  .contact-toolbar-right .dataTables_paginate .pagination.custom-pagination .paginate_button:not(:first-child):not(:last-child) {
    display: none;
}

.contact-list-view  .contact-toolbar-right .dataTables_paginate .pagination.custom-pagination .page-link,
.contact-card-view  .contact-toolbar-right .dataTables_paginate .pagination.custom-pagination .page-link {
    padding: 0;
    background: transparent;
    min-width: auto;
    font-size: 1.25rem;
}

.contact-list-view  .contact-toolbar-right .dataTables_length select,
.contact-card-view  .contact-toolbar-right .dataTables_length select {
    margin-right: 0;
    margin-left: .625rem;
    display: inline-block;
    width: auto;
}

.contact-list-view  .contact-star {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-left: .625rem;
    font-size: 1rem;
}

.contact-list-view  .contact-star>.feather-icon>svg,
.contact-list-view  .contact-star .svg-icon>svg {
    cursor: pointer;
}

.contact-list-view  .contact-star>.feather-icon>svg:hover,
.contact-list-view  .contact-star .svg-icon>svg:hover {
    fill: #FFC400;
    stroke: #FFC400;
}

.contact-list-view  .contact-star.marked>.feather-icon>svg,
.contact-list-view  .contact-star.marked .svg-icon>svg {
    fill: #FFC400;
    stroke: #FFC400;
}

.contact-list-view  .contact-star.marked>.feather-icon>svg:hover,
.contact-list-view  .contact-star.marked .svg-icon>svg:hover {
    fill: none;
    stroke: currentColor;
}

.contact-card-view  .card.contact-card .form-check.form-check-lg {
    position: absolute;
    left: 14px;
    top: 17px;
    cursor: pointer;
    opacity: 0;
}

.contact-card-view  .card.contact-card .user-name {
    color: #262a2e;
    font-weight: 500;
    margin-top: .625rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.contact-card-view  .card.contact-card .user-name,
.contact-card-view  .card.contact-card .user-email,
.contact-card-view  .card.contact-card .user-contact,
.contact-card-view  .card.contact-card .user-desg {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: .25rem;
}

.contact-card-view  .card.contact-card .card-footer {
    padding: 0;
    justify-content: center;
}

.contact-card-view  .card.contact-card .card-footer>.d-flex {
    padding: 0.75rem 1.25rem;
    justify-content: center;
    width: 100%;
    color: #6f6f6f;
    font-size: 1rem;
}

.contact-card-view  .card.contact-card:hover .form-check.form-check-lg {
    opacity: 1;
}

.contact-card-view  .card .contact-star {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-right: .625rem;
    opacity: 0;
    font-size: 1rem;
}

.contact-card-view  .card .contact-star>.feather-icon>svg,
.contact-card-view  .card .contact-star .svg-icon>svg {
    cursor: pointer;
}

.contact-card-view  .card .contact-star>.feather-icon>svg:hover,
.contact-card-view  .card .contact-star .svg-icon>svg:hover {
    fill: #FFC400;
    stroke: #FFC400;
}

.contact-card-view  .card .contact-star.marked {
    opacity: 1;
}

.contact-card-view  .card .contact-star.marked>.feather-icon>svg,
.contact-card-view  .card .contact-star.marked .svg-icon>svg {
    fill: #FFC400;
    stroke: #FFC400;
}

.contact-card-view  .card .contact-star.marked>.feather-icon>svg:hover,
.contact-card-view  .card .contact-star.marked .svg-icon>svg:hover {
    fill: none;
    stroke: currentColor;
}

.contact-card-view  .card:hover .contact-star {
    opacity: 1;
}

.contact-card-view .select-multiple .card.contact-card .form-check.form-check-lg {
    opacity: 1;
}

.contactapp-wrap .contactapp-content .contact-detail-body .nicescroll-bar {
    padding: 0 !important;
}

.contactapp-wrap .contactapp-content .contact-detail-body .nicescroll-bar .contact-info {
    position: relative;
    padding: 1.25rem;
    flex-shrink: 0;
    border-right: 1px solid #eaeaea;
}

.contactapp-wrap .contactapp-content .contact-detail-body .nicescroll-bar .contact-info .action-btn {
    position: absolute;
    right: 1.25rem;
}

.contactapp-wrap .contactapp-content .contact-detail-body .nicescroll-bar .contact-info .cp-name {
    font-size: 1.25rem;
    font-weight: 500;
    color: #262a2e;
}

.contactapp-wrap .contactapp-content .contact-detail-body .nicescroll-bar .contact-info .cp-info {
    padding-left: 0;
}

.contactapp-wrap .contactapp-content .contact-detail-body .nicescroll-bar .contact-info .dropify-circle .dropify-wrapper {
    margin: 0 auto;
}

.contactapp-wrap .contactapp-content .contact-detail-body .nicescroll-bar .contact-info .card {
    margin-bottom: 0;
    box-shadow: none;
    border: 0;
    border-radius: 0;
    border-radius: 0;
    padding: 0.5rem 0;
}

.contactapp-wrap .contactapp-content .contact-detail-body .nicescroll-bar .contact-info .card .card-header {
    border: none;
    padding: .5rem 0;
}

.contactapp-wrap .contactapp-content .contact-detail-body .nicescroll-bar .contact-info .card .card-header>a {
    font-size: 0.875rem;
    font-weight: 500;
    display: block;
    width: 100%;
    color: #09769F;
    text-align: left;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
}

.contactapp-wrap .contactapp-content .contact-detail-body .nicescroll-bar .contact-info .card .card-body {
    padding: .5rem 0;
}

.contactapp-wrap .contactapp-content .contact-detail-body .nicescroll-bar .contact-info .card .card-body .cp-info li {
    margin-bottom: .625rem;
}

.contactapp-wrap .contactapp-content .contact-detail-body .nicescroll-bar .contact-info .card .card-body .cp-info li a {
    color: #262a2e;
}

.contactapp-wrap .contactapp-content .contact-detail-body .nicescroll-bar .contact-info .card .card-body .cp-info li>span {
    display: inline-block;
}

.contactapp-wrap .contactapp-content .contact-detail-body .nicescroll-bar .contact-info .card .card-body .cp-info li>span:first-child {
    min-width: 100px;
    margin-right: 1rem;
}

.contactapp-wrap .contactapp-content .contact-detail-body .nicescroll-bar .contact-info .card .card-body .cp-info li>span:last-child {
    color: #262a2e;
}

.contactapp-wrap .contactapp-content .contact-detail-body .nicescroll-bar .contact-info .card .card-body .cp-action {
    padding-left: 0;
}

.contactapp-wrap .contactapp-content .contact-detail-body .nicescroll-bar .contact-info .card .card-body .cp-action li {
    margin-bottom: 10px;
}

.contactapp-wrap .contactapp-content .contact-detail-body .nicescroll-bar .contact-info .card .card-body .cp-action li a {
    color: #262a2e;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.contactapp-wrap .contactapp-content .contact-detail-body .nicescroll-bar .contact-info .card .card-body .cp-action li a .cp-icon-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-right: .5rem;
    font-size: 1.125rem;
}

.contactapp-wrap .contactapp-content .contact-detail-body .nicescroll-bar .contact-info .card .card-body .cp-action li:last-child {
    margin-bottom: .25rem;
}

.contact-more-info  {
    flex-grow: 1;
}

.contact-more-info  .nav-tabs .nav-item .nav-link {
    padding: .75rem 1.25rem;
}

.contact-more-info  .nav-tabs.nav-line {
    border-bottom: 1px solid #eaeaea;
}

.contact-more-info  .tab-content {
    padding: 0 1.25rem;
}

.contact-more-info  .pipeline-status-wrap {
    padding: 0 1.25rem;
}

.contact-more-info  .pipeline-status-wrap .pipeline-stutus {
    padding: 0;
    list-style-type: none;
    font-family: 'Open Sans', sans-serif;
    font-size: 0.875rem;
    clear: both;
    line-height: 1em;
    margin: 0 -1px;
    text-align: center;
}

.contact-more-info  .pipeline-status-wrap .pipeline-stutus li {
    float: left;
    padding: 1rem 1.875rem 1rem 2.5rem;
    background: #d8d8d8;
    position: relative;
    width: 19.5%;
    margin: 0 1px;
}

.contact-more-info  .pipeline-status-wrap .pipeline-stutus li:before {
    content: '';
    border-left: 20px solid #fff;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
}

.contact-more-info  .pipeline-status-wrap .pipeline-stutus li:first-child {
    border-radius: 0.5rem 0 0 0.5rem;
}

.contact-more-info  .pipeline-status-wrap .pipeline-stutus li:first-child:before {
    display: none;
}

.contact-more-info  .pipeline-status-wrap .pipeline-stutus li:last-child {
    border-radius: 0 0.5rem 0.5rem 0;
}

.contact-more-info  .pipeline-status-wrap .pipeline-stutus li:last-child:after {
    display: none;
}

.contact-more-info  .pipeline-status-wrap .pipeline-stutus li:after {
    content: '';
    border-left: 20px solid #d8d8d8;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 100%;
    z-index: 20;
}

.contact-more-info  .pipeline-status-wrap .pipeline-stutus li.active {
    background: #FF9100;
    color: #fff;
}

.contact-more-info  .pipeline-status-wrap .pipeline-stutus li.active:after {
    border-left-color: #FF9100;
}

.contact-more-info  .pipeline-status-wrap .pipeline-stutus li.completed {
    background: #09769F;
    color: #fff;
}

.contact-more-info  .pipeline-status-wrap .pipeline-stutus li.completed:after {
    border-left-color: #09769F;
}

.contact-more-info  .pipeline-status-wrap .pipeline-stutus li>span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.contact-more-info  .activity-wrap {
    padding: 0 1.25rem 1.25rem;
}

.contact-more-info  .activity-wrap .activity-thread {
    padding-left: 0;
}

.contact-more-info  .activity-wrap .activity-thread li {
    margin-bottom: 1rem;
}

.contact-more-info  .activity-wrap .activity-thread li:last-child {
    margin-bottom: 0;
}

.contact-more-info  .activity-wrap .activity-thread li .media .media-head {
    margin-right: 1rem;
}

.contact-more-info  .activity-wrap .activity-thread li .media .media-body>div .activity-time {
    font-size: 0.75rem;
    margin-top: .25rem;
}

.contactapp-wrap .contactapp-content .contact-detail-modal header.contact-header {
    height: auto;
    padding: 1.25rem;
    border-radius: 0.5rem 0.5rem 0 0;
}

.contactapp-wrap .contactapp-content .contact-detail-modal header.contact-header ul.hk-list.hk-list-sm li {
    padding: 0 .4rem;
}

.contactapp-wrap.contactapp-sidebar-toggle .contactapp-sidebar {
    left: -270px;
}

.contactapp-wrap.contactapp-sidebar-toggle .contactapp-content {
    padding-left: 0;
}

.hk-wrapper[data-navbar-style="collapsed"] .contactapp-wrap {
    height: 100vh;
}

@media (max-width: 1740px) {
    .contactapp-wrap .contactapp-content {
        margin-left: 0;
        padding-left: 0;
    }

    .contact-list-view  .contact-toolbar-right .dataTables_info,
    .contact-list-view  .contact-toolbar-right .dataTables_length,
    .contact-card-view  .contact-toolbar-right .dataTables_info,
    .contact-card-view  .contact-toolbar-right .dataTables_length {
        display: none;
    }

    .contactapp-wrap .contactapp-sidebar {
        left: -270px;
    }

    .contactapp-wrap.contactapp-sidebar-toggle .contactapp-sidebar {
        left: 0;
    }

    .contactapp-wrap.contactapp-sidebar-toggle .contactapp-content {
        left: 270px;
        padding-left: 270px;
        margin-left: -270px;
    }

    .contactapp-wrap .hk-sidebar-togglable {
        -webkit-transform: translateX(32px);
        -moz-transform: translateX(32px);
        -ms-transform: translateX(32px);
        -o-transform: translateX(32px);
        transform: translateX(32px);
    }

    .contactapp-wrap .hk-sidebar-togglable:after {
        content: "\ea6c";
    }

    .contactapp-wrap .hk-sidebar-togglable.active {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }

    .contactapp-wrap .hk-sidebar-togglable.active:after {
        content: "\ea62";
    }
}

/*File Manager*/
.fmapp-wrap {
    position: relative;
    height: calc(100vh - 65px);
}

.fmapp-wrap .fmapp-sidebar {
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-flow: column;
    left: 0;
    width: 270px;
    margin-left: 0;
    top: 0;
    height: 100%;
    z-index: 1;
    border: none;
    background: #fff;
    border-right: 1px solid #eaeaea;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.fmapp-wrap .fmapp-sidebar>div.nicescroll-bar {
    flex: 1 1 auto;
    overflow: auto;
}

.fmapp-wrap .fmapp-sidebar .menu-content-wrap {
    padding: 1.5rem;
}

.fmapp-wrap .fmapp-sidebar .menu-content-wrap .menu-gap {
    height: 25px;
}

.fmapp-wrap .fmapp-sidebar .menu-content-wrap .menu-group>.navbar-nav>.nav-item {
    margin-left: -1rem;
    margin-right: -1rem;
}

.fmapp-wrap .fmapp-sidebar .menu-content-wrap .menu-group>.navbar-nav>.nav-item>.nav-link {
    border-radius: .5rem;
    padding: .5rem 1rem;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.fmapp-wrap .fmapp-sidebar .menu-content-wrap .menu-group>.navbar-nav>.nav-item>.nav-link .nav-icon-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-right: .875rem;
    font-size: 1.5rem;
}

.fmapp-wrap .fmapp-sidebar .menu-content-wrap .menu-group>.navbar-nav>.nav-item>.nav-link .nav-icon-wrap .nav-link-text {
    display: inline-block;
}

.fmapp-wrap .fmapp-sidebar .menu-content-wrap .menu-group>.navbar-nav>.nav-item.active>.nav-link {
    background: rgba(0, 125, 136, 0.15);
    color: #09769F;
}

.fmapp-wrap .fmapp-sidebar .fmapp-storage {
    padding: 1.5rem;
}

.fmapp-wrap .fmapp-sidebar .fmapp-fixednav {
    height: 61px;
    border-top: 1px solid #eaeaea;
}

.fmapp-wrap .fmapp-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    padding-left: 270px;
    position: relative;
    height: 100%;
    left: 0;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.fmapp-wrap .fmapp-content .fmapp-detail-wrap {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-grow: 1;
    background: #fff;
    flex-flow: column;
    height: 100%;
    z-index: 10;
}

.fmapp-wrap .fmapp-content .fmapp-detail-wrap header.fm-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
    height: 65px;
    padding: 0 1.25rem;
    background: #fff;
    border-bottom: 1px solid #eaeaea;
    position: relative;
}

.fmapp-wrap .fmapp-content .fmapp-detail-wrap header.fm-header .fmapp-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.fmapp-wrap .fmapp-content .fmapp-detail-wrap header.fm-header .fmapp-title h1 {
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 0;
}

.fmapp-wrap .fmapp-content .fmapp-detail-wrap header.fm-header .fmapp-title:after {
    top: 0;
}

.fmapp-wrap .fmapp-content .fmapp-detail-wrap header.fm-header .fm-options-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.fmapp-wrap .fmapp-content .fmapp-detail-wrap header.fm-header .fm-options-wrap>a {
    margin-left: .25rem;
    display: inline-block;
}

.fmapp-wrap .fmapp-content .fmapp-detail-wrap header.fm-header .fm-options-wrap>a:first-child {
    margin-left: 0;
}

.fmapp-wrap .fmapp-content .fmapp-detail-wrap .fm-body {
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
    overflow: hidden;
    -ms-flex: 1;
    flex: 1;
}

.fmapp-wrap .fmapp-content .fmapp-detail-wrap .fm-body .nicescroll-bar {
    padding: 1.25rem;
}

.file-list-view  .nav-line {
    margin: 0 -1.25rem;
    border-bottom: 1px solid #eaeaea;
}

.file-list-view  .file-star {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-left: .625rem;
}

.file-list-view  .file-star>.feather-icon>svg,
.file-list-view  .file-star .svg-icon>svg {
    height: 16px;
    width: 16px;
    cursor: pointer;
}

.file-list-view  .file-star>.feather-icon>svg:hover,
.file-list-view  .file-star .svg-icon>svg:hover {
    fill: #FFC400;
    stroke: #FFC400;
}

.file-list-view  .file-star.marked>.feather-icon>svg,
.file-list-view  .file-star.marked .svg-icon>svg {
    fill: #FFC400;
    stroke: #FFC400;
}

.file-list-view  .file-star.marked>.feather-icon>svg:hover,
.file-list-view  .file-star.marked .svg-icon>svg:hover {
    fill: none;
    stroke: currentColor;
}

.file-list-view  .media .media-body .file-name {
    color: #262a2e;
    font-weight: 500;
    max-width: 190px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.file-list-view  .fmapp-info-trigger.media {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
}

.file-list-view  .fmapp-info-trigger.media .media-body .file-name {
    -webkit-transition: color 0.2s ease;
    -moz-transition: color 0.2s ease;
    transition: color 0.2s ease;
}

.file-list-view  .fmapp-info-trigger.media:hover .media-body .file-name {
    color: #09769F;
}

.file-card-view  .file-star {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-right: .25rem;
    margin-top: .4rem;
    margin-left: .625rem;
    opacity: 0;
}

.file-card-view  .file-star>.feather-icon>svg,
.file-card-view  .file-star .svg-icon>svg {
    cursor: pointer;
}

.file-card-view  .file-star>.feather-icon>svg:hover,
.file-card-view  .file-star .svg-icon>svg:hover {
    fill: #FFC400;
    stroke: #FFC400;
}

.file-card-view  .file-star.marked>.feather-icon>svg,
.file-card-view  .file-star.marked .svg-icon>svg {
    fill: #FFC400;
    stroke: #FFC400;
}

.file-card-view  .file-star.marked>.feather-icon>svg:hover,
.file-card-view  .file-star.marked .svg-icon>svg:hover {
    fill: none;
    stroke: currentColor;
}

.file-card-view  .card.file-card {
    cursor: pointer;
}

.file-card-view  .card.file-card .card-body {
    min-height: 150px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
}

.file-card-view  .card.file-card .card-body i {
    font-size: 2.375rem;
}

.file-card-view  .card.file-card .card-footer {
    display: block;
    -webkit-transition: background 0.2s ease;
    -moz-transition: background 0.2s ease;
    transition: background 0.2s ease;
}

.file-card-view  .card.file-card .card-footer>.d-flex>div {
    min-width: 0;
}

.file-card-view  .card.file-card .card-footer>.d-flex>div .file-name {
    color: #262a2e;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.file-card-view  .card.file-card .file-star.marked {
    opacity: 1;
}

.file-card-view  .card.file-card:hover .file-star {
    opacity: 1;
}

.file-card-view  .card.file-card:hover .card-footer {
    background: #EBFAFF;
}

.file-card-view  .card.file-card:hover .card-footer .file-name {
    color: #09769F;
}

.file-card-view  .card.file-compact-card {
    -webkit-transition: background 0.2s ease;
    -moz-transition: background 0.2s ease;
    transition: background 0.2s ease;
    cursor: pointer;
}

.file-card-view  .card.file-compact-card .card-body .media {
    overflow: hidden;
}

.file-card-view  .card.file-compact-card .card-body .media .avatar {
    flex-shrink: 0;
}

.file-card-view  .card.file-compact-card .card-body .media .media-body {
    min-width: 0;
}

.file-card-view  .card.file-compact-card .card-body .media .media-body .file-name {
    color: #262a2e;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.file-card-view  .card.file-compact-card .file-star.marked {
    opacity: 1;
}

.file-card-view  .card.file-compact-card:hover {
    background: #EBFAFF;
}

.file-card-view  .card.file-compact-card:hover .file-star {
    opacity: 1;
}

.file-card-view  .card.file-compact-card:hover .card-body .media .avatar {
    flex-shrink: 0;
}

.file-card-view  .card.file-compact-card:hover .card-body .media .media-body .file-name {
    color: #09769F;
}

.fmapp-wrap .fmapp-content .fmapp-detail-wrap .file-info {
    position: absolute;
    top: 65px;
    right: -370px;
    width: 370px;
    margin-left: 0;
    bottom: 0;
    z-index: 100;
    border: none;
    background: #fff;
    border-left: 1px solid #eaeaea;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.fmapp-wrap .fmapp-content .fmapp-detail-wrap .file-info .nicescroll-bar {
    padding: 1.25rem;
}

.fmapp-wrap .fmapp-content .fmapp-detail-wrap .file-info .file-name {
    color: #262a2e;
    font-size: 1.25rem;
    font-weight: 500;
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.fmapp-wrap .fmapp-content .fmapp-detail-wrap .file-info .fm-info {
    padding-left: 0;
}

.fmapp-wrap .fmapp-content .fmapp-detail-wrap .file-info .fm-info li {
    margin-bottom: .625rem;
}

.fmapp-wrap .fmapp-content .fmapp-detail-wrap .file-info .fm-info li a {
    color: #262a2e;
}

.fmapp-wrap .fmapp-content .fmapp-detail-wrap .file-info .fm-info li>span {
    display: inline-block;
}

.fmapp-wrap .fmapp-content .fmapp-detail-wrap .file-info .fm-info li>span:first-child {
    min-width: 100px;
    margin-right: 1rem;
}

.fmapp-wrap .fmapp-content .fmapp-detail-wrap .file-info .fm-info li>span:last-child {
    color: #262a2e;
}

.fmapp-wrap .fmapp-content .fmapp-detail-wrap .file-info .fm-action {
    padding-left: 0;
}

.fmapp-wrap .fmapp-content .fmapp-detail-wrap .file-info .fm-action li {
    margin-bottom: .625rem;
}

.fmapp-wrap .fmapp-content .fmapp-detail-wrap .file-info .fm-action li a {
    color: #262a2e;
}

.fmapp-wrap .fmapp-content .fmapp-detail-wrap .file-info .fm-action li:last-child {
    margin-bottom: .25rem;
}

.fmapp-wrap.fmapp-info-active .fmapp-detail-wrap .file-info {
    right: 0;
}

.fmapp-wrap.fmapp-sidebar-toggle .fmapp-sidebar {
    left: -270px;
}

.fmapp-wrap.fmapp-sidebar-toggle .fmapp-content {
    padding-left: 0;
}

.hk-wrapper[data-navbar-style="collapsed"] .fmapp-wrap {
    height: 100vh;
}

@media (max-width: 1199px) {
    .fmapp-wrap .fmapp-content .fmapp-detail-wrap .file-info {
        top: 0;
    }
}

@media (max-width: 767px) {
    .fmapp-wrap .fmapp-sidebar {
        left: -270px;
    }

    .fmapp-wrap .fmapp-content {
        margin-left: 0;
        padding-left: 0;
    }

    .fmapp-wrap.fmapp-sidebar-toggle .fmapp-sidebar {
        left: 0;
    }

    .fmapp-wrap.fmapp-sidebar-toggle .fmapp-content {
        left: 270px;
        padding-left: 270px;
        margin-left: -270px;
    }

    .fmapp-wrap .hk-sidebar-togglable {
        -webkit-transform: translateX(32px);
        -moz-transform: translateX(32px);
        -ms-transform: translateX(32px);
        -o-transform: translateX(32px);
        transform: translateX(32px);
    }

    .fmapp-wrap .hk-sidebar-togglable:after {
        content: "\ea6c";
    }

    .fmapp-wrap .hk-sidebar-togglable.active {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }

    .fmapp-wrap .hk-sidebar-togglable.active:after {
        content: "\ea62";
    }
}

/*Gallery App*/
.galleryapp-wrap {
    position: relative;
    height: calc(100vh - 65px);
}

.galleryapp-wrap .galleryapp-sidebar {
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-flow: column;
    left: 0;
    width: 270px;
    margin-left: 0;
    top: 0;
    height: 100%;
    z-index: 1;
    border: none;
    background: #fff;
    border-right: 1px solid #eaeaea;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.galleryapp-wrap .galleryapp-sidebar>div.nicescroll-bar {
    flex: 1 1 auto;
    overflow: auto;
}

.galleryapp-wrap .galleryapp-sidebar .menu-content-wrap {
    padding: 1.5rem;
}

.galleryapp-wrap .galleryapp-sidebar .menu-content-wrap .menu-gap {
    height: 25px;
}

.galleryapp-wrap .galleryapp-sidebar .menu-content-wrap .menu-group>.navbar-nav>.nav-item {
    margin-left: -1rem;
    margin-right: -1rem;
}

.galleryapp-wrap .galleryapp-sidebar .menu-content-wrap .menu-group>.navbar-nav>.nav-item>.nav-link {
    border-radius: .5rem;
    padding: .5rem 1rem;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.galleryapp-wrap .galleryapp-sidebar .menu-content-wrap .menu-group>.navbar-nav>.nav-item>.nav-link .nav-icon-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-right: .875rem;
    font-size: 1.5rem;
}

.galleryapp-wrap .galleryapp-sidebar .menu-content-wrap .menu-group>.navbar-nav>.nav-item>.nav-link .nav-icon-wrap .nav-link-text {
    display: inline-block;
}

.galleryapp-wrap .galleryapp-sidebar .menu-content-wrap .menu-group>.navbar-nav>.nav-item.active>.nav-link {
    background: rgba(0, 125, 136, 0.15);
    color: #09769F;
}

.galleryapp-wrap .galleryapp-sidebar .galleryapp-fixednav {
    height: 61px;
    border-top: 1px solid #eaeaea;
}

.galleryapp-wrap .galleryapp-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    padding-left: 270px;
    position: relative;
    height: 100%;
    left: 0;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.galleryapp-wrap .galleryapp-content .galleryapp-detail-wrap {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-grow: 1;
    background: #fff;
    flex-flow: column;
    height: 100%;
    z-index: 10;
}

.galleryapp-wrap .galleryapp-content .galleryapp-detail-wrap header.gallery-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
    height: 65px;
    padding: 0 1.25rem;
    background: #fff;
    border-bottom: 1px solid #eaeaea;
    position: relative;
}

.galleryapp-wrap .galleryapp-content .galleryapp-detail-wrap header.gallery-header .galleryapp-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.galleryapp-wrap .galleryapp-content .galleryapp-detail-wrap header.gallery-header .galleryapp-title h1 {
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 0;
}

.galleryapp-wrap .galleryapp-content .galleryapp-detail-wrap header.gallery-header .galleryapp-title:after {
    top: 0;
}

.galleryapp-wrap .galleryapp-content .galleryapp-detail-wrap header.gallery-header .gallery-options-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.galleryapp-wrap .galleryapp-content .galleryapp-detail-wrap header.gallery-header .gallery-options-wrap>a {
    margin-left: .25rem;
    display: inline-block;
}

.galleryapp-wrap .galleryapp-content .galleryapp-detail-wrap header.gallery-header .gallery-options-wrap>a:first-child {
    margin-left: 0;
}

.galleryapp-wrap .galleryapp-content .galleryapp-detail-wrap .gallery-body {
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
    overflow: hidden;
    -ms-flex: 1;
    flex: 1;
}

.galleryapp-wrap .galleryapp-content .galleryapp-detail-wrap .gallery-body .nicescroll-bar {
    padding: 1.25rem;
}

.galleryapp-wrap .galleryapp-content .galleryapp-detail-wrap .gallery-body .nicescroll-bar .hk-gallery a {
    position: relative;
    display: block;
}

.galleryapp-wrap .galleryapp-content .galleryapp-detail-wrap .gallery-body .nicescroll-bar .hk-gallery a .form-check {
    position: absolute;
    left: 14px;
    top: 17px;
    cursor: pointer;
    opacity: 0;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.galleryapp-wrap .galleryapp-content .galleryapp-detail-wrap .gallery-body .nicescroll-bar .hk-gallery a .gallery-star {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: absolute;
    right: 14px;
    top: 20px;
    opacity: 0;
    color: #6f6f6f;
}

.galleryapp-wrap .galleryapp-content .galleryapp-detail-wrap .gallery-body .nicescroll-bar .hk-gallery a .gallery-star>.feather-icon>svg,
.galleryapp-wrap .galleryapp-content .galleryapp-detail-wrap .gallery-body .nicescroll-bar .hk-gallery a .gallery-star .svg-icon>svg {
    cursor: pointer;
}

.galleryapp-wrap .galleryapp-content .galleryapp-detail-wrap .gallery-body .nicescroll-bar .hk-gallery a .gallery-star>.feather-icon>svg:hover,
.galleryapp-wrap .galleryapp-content .galleryapp-detail-wrap .gallery-body .nicescroll-bar .hk-gallery a .gallery-star .svg-icon>svg:hover {
    fill: #FFC400;
    stroke: #FFC400;
}

.galleryapp-wrap .galleryapp-content .galleryapp-detail-wrap .gallery-body .nicescroll-bar .hk-gallery a .gallery-star.marked {
    opacity: 1;
}

.galleryapp-wrap .galleryapp-content .galleryapp-detail-wrap .gallery-body .nicescroll-bar .hk-gallery a .gallery-star.marked>.feather-icon>svg,
.galleryapp-wrap .galleryapp-content .galleryapp-detail-wrap .gallery-body .nicescroll-bar .hk-gallery a .gallery-star.marked .svg-icon>svg {
    fill: #FFC400;
    stroke: #FFC400;
}

.galleryapp-wrap .galleryapp-content .galleryapp-detail-wrap .gallery-body .nicescroll-bar .hk-gallery a .gallery-star.marked>.feather-icon>svg:hover,
.galleryapp-wrap .galleryapp-content .galleryapp-detail-wrap .gallery-body .nicescroll-bar .hk-gallery a .gallery-star.marked .svg-icon>svg:hover {
    fill: none;
    stroke: currentColor;
}

.galleryapp-wrap .galleryapp-content .galleryapp-detail-wrap .gallery-body .nicescroll-bar .hk-gallery a:hover .form-check {
    opacity: 1;
}

.galleryapp-wrap .galleryapp-content .galleryapp-detail-wrap .gallery-body .nicescroll-bar .hk-gallery a:hover .gallery-star {
    opacity: 1;
}

.galleryapp-wrap .galleryapp-content .galleryapp-detail-wrap .gallery-body .nicescroll-bar .hk-gallery a .gallery-img {
    min-height: 230px;
    background-size: cover;
    background-position: center;
}

.galleryapp-wrap .galleryapp-content .galleryapp-detail-wrap .gallery-body .nicescroll-bar .hk-gallery a .gallery-img.gallery-video:after {
    content: "\efb6";
    font-family: remixicon;
    font-style: normal;
    position: absolute;
    right: 14px;
    bottom: 14px;
    font-size: 1.5rem;
    height: 35px;
    width: 35px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.45);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    color: #fff;
}

.galleryapp-wrap .galleryapp-content .galleryapp-detail-wrap .gallery-body.select-multiple .nicescroll-bar {
    padding: 1.25rem;
}

.galleryapp-wrap .galleryapp-content .galleryapp-detail-wrap .gallery-body.select-multiple .nicescroll-bar .hk-gallery a .form-check {
    opacity: 1;
}

.galleryapp-wrap.galleryapp-sidebar-toggle .galleryapp-sidebar {
    left: -270px;
}

.galleryapp-wrap.galleryapp-sidebar-toggle .galleryapp-content {
    padding-left: 0;
}

.lg-outer.galleryapp-info-active .lg-img-wrap {
    padding-right: 26.25rem !important;
    /* background: url("../../public/assets/dist/img/loading.gif") no-repeat scroll transparent; */
    background-position: center center;
    background-position: calc((100% - 300px) / 2) center;
}

.lg-outer.galleryapp-info-active .lg-video-cont {
    padding-right: 20rem !important;
    /* background: url("../../public/assets/dist/img/loading.gif") no-repeat scroll transparent; */
    background-position: center center;
    background-position: calc((100% - 300px) / 2) center;
}

.lg-outer.galleryapp-info-active .lg-sub-html {
    height: 100%;
    overflow-y: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 420px;
    z-index: 99999;
    left: auto;
    color: inherit;
    padding: 0;
    text-align: left;
    /* background: #fff url("../../public/assets/dist/img/loading.gif") no-repeat scroll center center; */
}

.lg-outer.galleryapp-info-active .lg-sub-html header.gallery-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
    padding: 1.25rem;
    margin: 0 -1.25rem;
    height: 65px;
    border-bottom: 1px solid #eaeaea;
    position: relative;
}

.lg-outer.galleryapp-info-active .lg-sub-html header.gallery-header .file-name {
    font-weight: 500;
    color: #262a2e;
    max-width: 190px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.lg-outer.galleryapp-info-active .lg-sub-html header.gallery-header .file-type {
    font-size: 0.75rem;
}

.lg-outer.galleryapp-info-active .lg-sub-html header.gallery-header .gallery-options-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.lg-outer.galleryapp-info-active .lg-sub-html header.gallery-header .gallery-options-wrap>a {
    margin-left: .25rem;
    display: inline-block;
}

.lg-outer.galleryapp-info-active .lg-sub-html header.gallery-header .gallery-options-wrap>a:first-child {
    margin-left: 0;
}

.lg-outer.galleryapp-info-active .lg-sub-html .gallery-detail-body .file-info li {
    margin-bottom: .625rem;
}

.lg-outer.galleryapp-info-active .lg-sub-html .gallery-detail-body .file-info li>span {
    display: inline-block;
}

.lg-outer.galleryapp-info-active .lg-sub-html .gallery-detail-body .file-info li>span:first-child {
    min-width: 100px;
    margin-right: 1rem;
}

.lg-outer.galleryapp-info-active .lg-sub-html .gallery-detail-body .file-info li>span:last-child {
    color: #262a2e;
}

.lg-outer.galleryapp-info-active .galleryapp-info-active.fb_iframe_widget {
    background-image: none;
}

.lg-outer.galleryapp-info-active .galleryapp-info-active.fb_iframe_widget.fb_iframe_widget_loader {
    /* background: #fff url("../../public/assets/dist/img/loading.gif") no-repeat scroll center center; */
}

.lg-outer.galleryapp-info-active .lg-toolbar {
    right: 420px;
    width: auto;
}

.lg-outer.galleryapp-info-active .lg-actions .lg-next {
    right: 440px;
}

.lg-outer.galleryapp-info-active .lg-item {
    background-image: none;
}

.lg-outer.galleryapp-info-active .lg-item.lg-complete .lg-img-wrap {
    background-image: none;
}

.lg-outer.galleryapp-info-active .lg-item.lg-complete .lg-video-cont {
    background-image: none;
}

.hk-wrapper[data-navbar-style="collapsed"] .galleryapp-wrap {
    height: 100vh;
}

@media (max-width: 767px) {
    .galleryapp-wrap .galleryapp-sidebar {
        left: -270px;
    }

    .galleryapp-wrap .galleryapp-content {
        margin-left: 0;
        padding-left: 0;
    }

    .galleryapp-wrap.galleryapp-sidebar-toggle .galleryapp-sidebar {
        left: 0;
    }

    .galleryapp-wrap.galleryapp-sidebar-toggle .galleryapp-content {
        left: 270px;
        padding-left: 270px;
        margin-left: -270px;
    }

    .galleryapp-wrap .hk-sidebar-togglable {
        -webkit-transform: translateX(32px);
        -moz-transform: translateX(32px);
        -ms-transform: translateX(32px);
        -o-transform: translateX(32px);
        transform: translateX(32px);
    }

    .galleryapp-wrap .hk-sidebar-togglable:after {
        content: "\ea6c";
    }

    .galleryapp-wrap .hk-sidebar-togglable.active {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }

    .galleryapp-wrap .hk-sidebar-togglable.active:after {
        content: "\ea62";
    }

    .lg-outer.galleryapp-info-active .lg-img-wrap {
        padding-right: 0 !important;
    }

    .lg-outer.galleryapp-info-active .lg-video-cont {
        padding-right: 0 !important;
    }

    .lg-outer.galleryapp-info-active .lg-sub-html {
        display: none;
    }

    .lg-outer.galleryapp-info-active .lg-toolbar {
        right: 0;
    }

    .lg-outer.galleryapp-info-active .lg-actions .lg-next {
        right: 20px;
    }
}

/*Todo*/
.todoapp-wrap {
    position: relative;
    height: calc(100vh - 65px);
}

.todoapp-wrap .todoapp-sidebar {
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-flow: column;
    left: 0;
    width: 270px;
    margin-left: 0;
    top: 0;
    height: 100%;
    z-index: 1;
    border: none;
    background: #fff;
    border-right: 1px solid #eaeaea;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.todoapp-wrap .todoapp-sidebar>div.nicescroll-bar {
    flex: 1 1 auto;
    overflow: auto;
}

.todoapp-wrap .todoapp-sidebar .menu-content-wrap {
    padding: 1.5rem;
}

.todoapp-wrap .todoapp-sidebar .menu-content-wrap .menu-gap {
    height: 25px;
}

.todoapp-wrap .todoapp-sidebar .menu-content-wrap .menu-group>.navbar-nav>.nav-item {
    margin-left: -1rem;
    margin-right: -1rem;
}

.todoapp-wrap .todoapp-sidebar .menu-content-wrap .menu-group>.navbar-nav>.nav-item>.nav-link,
.todoapp-wrap .todoapp-sidebar .menu-content-wrap .menu-group>.navbar-nav>.nav-item>div {
    border-radius: .5rem;
    padding: .5rem 1rem;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.todoapp-wrap .todoapp-sidebar .menu-content-wrap .menu-group>.navbar-nav>.nav-item>.nav-link .nav-icon-wrap,
.todoapp-wrap .todoapp-sidebar .menu-content-wrap .menu-group>.navbar-nav>.nav-item>div .nav-icon-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 1.5rem;
    margin-right: .875rem;
}

.todoapp-wrap .todoapp-sidebar .menu-content-wrap .menu-group>.navbar-nav>.nav-item>.nav-link .nav-icon-wrap .nav-link-text,
.todoapp-wrap .todoapp-sidebar .menu-content-wrap .menu-group>.navbar-nav>.nav-item>div .nav-icon-wrap .nav-link-text {
    display: inline-block;
}

.todoapp-wrap .todoapp-sidebar .menu-content-wrap .menu-group>.navbar-nav>.nav-item.active>.nav-link {
    background: rgba(0, 125, 136, 0.15);
    color: #09769F;
}

.todoapp-wrap .todoapp-sidebar .todoapp-fixednav {
    height: 61px;
    border-top: 1px solid #eaeaea;
}

.todoapp-wrap .todoapp-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    padding-left: 270px;
    position: relative;
    height: 100%;
    left: 0;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.todoapp-wrap .todoapp-content .todoapp-detail-wrap {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-grow: 1;
    background: #fff;
    flex-flow: column;
    height: 100%;
    z-index: 10;
    width: calc(100vw - 270px);
}

.todoapp-wrap .todoapp-content .todoapp-detail-wrap header.todo-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
    height: 65px;
    padding: 0 1.25rem;
    background: #fff;
    border-bottom: 1px solid #eaeaea;
    position: relative;
}

.todoapp-wrap .todoapp-content .todoapp-detail-wrap header.todo-header .todoapp-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    white-space: nowrap;
}

.todoapp-wrap .todoapp-content .todoapp-detail-wrap header.todo-header .todoapp-title h1 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 0;
}

.todoapp-wrap .todoapp-content .todoapp-detail-wrap header.todo-header .todoapp-title h1 .task-star {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-left: .625rem;
    font-size: 1.25rem;
}

.todoapp-wrap .todoapp-content .todoapp-detail-wrap header.todo-header .todoapp-title h1 .task-star>.feather-icon>svg,
.todoapp-wrap .todoapp-content .todoapp-detail-wrap header.todo-header .todoapp-title h1 .task-star .svg-icon>svg {
    cursor: pointer;
}

.todoapp-wrap .todoapp-content .todoapp-detail-wrap header.todo-header .todoapp-title h1 .task-star>.feather-icon>svg:hover,
.todoapp-wrap .todoapp-content .todoapp-detail-wrap header.todo-header .todoapp-title h1 .task-star .svg-icon>svg:hover {
    fill: #FFC400;
    stroke: #FFC400;
}

.todoapp-wrap .todoapp-content .todoapp-detail-wrap header.todo-header .todoapp-title h1 .task-star.marked>.feather-icon>svg,
.todoapp-wrap .todoapp-content .todoapp-detail-wrap header.todo-header .todoapp-title h1 .task-star.marked .svg-icon>svg {
    fill: #FFC400;
    stroke: #FFC400;
}

.todoapp-wrap .todoapp-content .todoapp-detail-wrap header.todo-header .todoapp-title h1 .task-star.marked>.feather-icon>svg:hover,
.todoapp-wrap .todoapp-content .todoapp-detail-wrap header.todo-header .todoapp-title h1 .task-star.marked .svg-icon>svg:hover {
    fill: none;
    stroke: currentColor;
}

.todoapp-wrap .todoapp-content .todoapp-detail-wrap header.todo-header .todoapp-title:after {
    top: 0;
}

.todoapp-wrap .todoapp-content .todoapp-detail-wrap header.todo-header .media {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.todoapp-wrap .todoapp-content .todoapp-detail-wrap header.todo-header .media .media-head {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-right: 1rem;
}

.todoapp-wrap .todoapp-content .todoapp-detail-wrap header.todo-header .media .media-body .user-name {
    font-size: 1rem;
    font-weight: 500;
    color: #262a2e;
    text-transform: capitalize;
}

.todoapp-wrap .todoapp-content .todoapp-detail-wrap header.todo-header .media .media-body .user-status {
    font-size: 0.875rem;
}

.todoapp-wrap .todoapp-content .todoapp-detail-wrap header.todo-header .todo-options-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.todoapp-wrap .todoapp-content .todoapp-detail-wrap header.todo-header .todo-options-wrap>.d-flex.ms-auto>a,
.todoapp-wrap .todoapp-content .todoapp-detail-wrap header.todo-header .todo-options-wrap>a {
    margin-left: .25rem;
    display: inline-block;
}

.todoapp-wrap .todoapp-content .todoapp-detail-wrap header.todo-header .todo-options-wrap>.d-flex.ms-auto>a:first-child,
.todoapp-wrap .todoapp-content .todoapp-detail-wrap header.todo-header .todo-options-wrap>a:first-child {
    margin-left: 0;
}

.todoapp-wrap .todoapp-content .todoapp-detail-wrap .todo-body {
    overflow: hidden;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-right: 0;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.todoapp-wrap .todoapp-content .todoapp-detail-wrap .todo-body .nicescroll-bar {
    padding: 1.25rem;
}

.todoapp-wrap .todoapp-content .todoapp-detail-wrap .todo-body .nicescroll-bar .todo-toolbar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
}

.todoapp-wrap .todoapp-content .todoapp-detail-wrap .todo-body .nicescroll-bar .todo-toolbar>div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.todoapp-wrap .todoapp-content .todoapp-detail-wrap .todo-body .nicescroll-bar .todo-toolbar>div .pagination.custom-pagination .paginate_button:not(:first-child):not(:last-child) {
    display: none;
}

.todoapp-wrap .todoapp-content .todoapp-detail-wrap .todo-body .nicescroll-bar .todo-toolbar>div .pagination.custom-pagination .page-link {
    padding: 0;
    background: transparent;
    min-width: auto;
    font-size: 1.25rem;
}

.todoapp-wrap .todoapp-content .todoapp-detail-wrap .todo-body .nicescroll-bar .split-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
}

.todoapp-wrap .todoapp-content .todoapp-detail-wrap .todo-body .nicescroll-bar .split-wrap .gutter.gutter-horizontal {
    cursor: ew-resize;
    position: relative;
    width: 7px;
    cursor: ew-resize;
    text-align: center;
    background: #fff;
    border-left: 1px solid #eaeaea;
    border-right: 1px solid #eaeaea;
    z-index: 5;
}

.todoapp-wrap .todoapp-content .todoapp-detail-wrap .todo-body .nicescroll-bar .split-wrap .gutter.gutter-horizontal:after {
    display: inline-block;
    font-family: remixicon !important;
    font-style: normal;
    content: "\ef26";
    position: absolute;
    top: 50%;
    left: -8px;
    font-size: 1.25rem;
    transform: translateY(-50%);
    color: #09769F;
}

.todoapp-wrap .todoapp-content .todoapp-detail-wrap .todo-body .nicescroll-bar .split-wrap .split .table-wrap {
    width: calc(100vw - 270px);
}

.todoapp-wrap .todoapp-content .todoapp-detail-wrap .todo-body .nicescroll-bar .split-wrap .split .table-wrap table.gt-todo-table .gt-single-task .todo-star {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-left: .625rem;
    margin-right: .625rem;
}

.todoapp-wrap .todoapp-content .todoapp-detail-wrap .todo-body .nicescroll-bar .split-wrap .split .table-wrap table.gt-todo-table .gt-single-task .todo-star>.feather-icon>svg,
.todoapp-wrap .todoapp-content .todoapp-detail-wrap .todo-body .nicescroll-bar .split-wrap .split .table-wrap table.gt-todo-table .gt-single-task .todo-star .svg-icon>svg {
    cursor: pointer;
}

.todoapp-wrap .todoapp-content .todoapp-detail-wrap .todo-body .nicescroll-bar .split-wrap .split .table-wrap table.gt-todo-table .gt-single-task .todo-star>.feather-icon>svg:hover,
.todoapp-wrap .todoapp-content .todoapp-detail-wrap .todo-body .nicescroll-bar .split-wrap .split .table-wrap table.gt-todo-table .gt-single-task .todo-star .svg-icon>svg:hover {
    fill: #FFC400;
    stroke: #FFC400;
}

.todoapp-wrap .todoapp-content .todoapp-detail-wrap .todo-body .nicescroll-bar .split-wrap .split .table-wrap table.gt-todo-table .gt-single-task .todo-star.marked>.feather-icon>svg,
.todoapp-wrap .todoapp-content .todoapp-detail-wrap .todo-body .nicescroll-bar .split-wrap .split .table-wrap table.gt-todo-table .gt-single-task .todo-star.marked .svg-icon>svg {
    fill: #FFC400;
    stroke: #FFC400;
}

.todoapp-wrap .todoapp-content .todoapp-detail-wrap .todo-body .nicescroll-bar .split-wrap .split .table-wrap table.gt-todo-table .gt-single-task .todo-star.marked>.feather-icon>svg:hover,
.todoapp-wrap .todoapp-content .todoapp-detail-wrap .todo-body .nicescroll-bar .split-wrap .split .table-wrap table.gt-todo-table .gt-single-task .todo-star.marked .svg-icon>svg:hover {
    fill: none;
    stroke: currentColor;
}

.todoapp-wrap .todoapp-content .todoapp-detail-wrap .todo-body .nicescroll-bar .split-wrap .split .table-wrap table.gt-todo-table .gt-single-task .todo-text {
    color: #262a2e;
}

.todoapp-wrap .todoapp-content .todoapp-detail-wrap .todo-body .nicescroll-bar .split-wrap .split .table-wrap table.gt-todo-table .gt-single-task>div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.todoapp-wrap .todoapp-content .todoapp-detail-wrap .todo-body .nicescroll-bar .split-wrap .split .table-wrap table.gt-todo-table.table-bordered.dataTable {
    margin-top: 0 !important;
}

.todoapp-wrap .todoapp-content .todoapp-detail-wrap .todo-body .nicescroll-bar .split-wrap .split .table-wrap table.gt-todo-table.table-bordered.dataTable th:first-child,
.todoapp-wrap .todoapp-content .todoapp-detail-wrap .todo-body .nicescroll-bar .split-wrap .split .table-wrap table.gt-todo-table.table-bordered.dataTable td:first-child {
    border-left-width: 0;
}

.todoapp-wrap .todoapp-content .todoapp-detail-wrap .todo-body .nicescroll-bar .split-wrap .split .table-wrap table.gt-todo-table.table-bordered.dataTable thead tr:first-child th,
.todoapp-wrap .todoapp-content .todoapp-detail-wrap .todo-body .nicescroll-bar .split-wrap .split .table-wrap table.gt-todo-table.table-bordered.dataTable thead tr:first-child td {
    border-top-width: 0;
}

.todoapp-wrap .todoapp-content .todoapp-detail-wrap .todo-body .nicescroll-bar .split-wrap .split .gantt-wrap .gantt-container {
    overflow: initial;
}

.todoapp-wrap .todoapp-content .todoapp-detail-wrap .todo-body .nicescroll-bar .split-wrap .split .gantt-wrap .gantt-container .gantt {
    fill: #09769F;
}

.todoapp-wrap .todoapp-content .todoapp-detail-wrap .todo-body .nicescroll-bar .split-wrap .split .gantt-wrap .gantt-container .gantt .grid-row:nth-child(even) {
    fill: #fff;
}

.todoapp-wrap .todoapp-content .todoapp-detail-wrap .todo-body .nicescroll-bar .split-wrap .split .gantt-wrap .gantt-container .gantt .row-line,
.todoapp-wrap .todoapp-content .todoapp-detail-wrap .todo-body .nicescroll-bar .split-wrap .split .gantt-wrap .gantt-container .gantt .tick {
    stroke: #d8d8d8;
}

.todoapp-wrap .todoapp-content .todoapp-detail-wrap .todo-body .nicescroll-bar .split-wrap .split .gantt-wrap .gantt-container .gantt .bar {
    fill: #8e8e8e;
    stroke: #6f6f6f;
}

.todoapp-wrap .todoapp-content .todoapp-detail-wrap .todo-body .nicescroll-bar .split-wrap .split .gantt-wrap .gantt-container .gantt .bar-progress {
    fill: #09769F;
}

.todoapp-wrap .todoapp-content .todoapp-detail-wrap .todo-body .nicescroll-bar .split-wrap .split .gantt-wrap .gantt-container .gantt .grid-header {
    stroke-width: 0;
}

.todoapp-wrap .todoapp-content .todoapp-detail-wrap .task-info {
    position: absolute;
    top: 65px;
    right: -550px;
    width: 550px;
    margin-left: 0;
    bottom: 0;
    z-index: 100;
    border: none;
    background: #fff;
    border-left: 1px solid #eaeaea;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.todoapp-wrap .todoapp-content .todoapp-detail-wrap .task-info header.task-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
    padding: 0 1.25rem;
    border-radius: 0.5rem 0.5rem 0 0;
    height: 65px;
    background: #fff;
    border-bottom: 1px solid #eaeaea;
    position: relative;
}

.todoapp-wrap .todoapp-content .todoapp-detail-wrap .task-info header.task-header .apexcharts-datalabels-group text {
    font-family: inherit;
    font-size: 0.75rem;
    fill: #6f6f6f;
    position: absolute;
    transform: translateY(5px);
}

.todoapp-wrap .todoapp-content .todoapp-detail-wrap .task-info header.task-header .apexcharts-datalabels-group text:last-child {
    display: none;
}

.todoapp-wrap .todoapp-content .todoapp-detail-wrap .task-info header.task-header .task-options-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.todoapp-wrap .todoapp-content .todoapp-detail-wrap .task-info header.task-header .task-options-wrap .task-star {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-left: .625rem;
    font-size: 1.25rem;
}

.todoapp-wrap .todoapp-content .todoapp-detail-wrap .task-info header.task-header .task-options-wrap .task-star>.feather-icon>svg,
.todoapp-wrap .todoapp-content .todoapp-detail-wrap .task-info header.task-header .task-options-wrap .task-star .svg-icon>svg {
    cursor: pointer;
}

.todoapp-wrap .todoapp-content .todoapp-detail-wrap .task-info header.task-header .task-options-wrap .task-star>.feather-icon>svg:hover,
.todoapp-wrap .todoapp-content .todoapp-detail-wrap .task-info header.task-header .task-options-wrap .task-star .svg-icon>svg:hover {
    fill: #FFC400;
    stroke: #FFC400;
}

.todoapp-wrap .todoapp-content .todoapp-detail-wrap .task-info header.task-header .task-options-wrap .task-star.marked>.feather-icon>svg,
.todoapp-wrap .todoapp-content .todoapp-detail-wrap .task-info header.task-header .task-options-wrap .task-star.marked .svg-icon>svg {
    fill: #FFC400;
    stroke: #FFC400;
}

.todoapp-wrap .todoapp-content .todoapp-detail-wrap .task-info header.task-header .task-options-wrap .task-star.marked>.feather-icon>svg:hover,
.todoapp-wrap .todoapp-content .todoapp-detail-wrap .task-info header.task-header .task-options-wrap .task-star.marked .svg-icon>svg:hover {
    fill: none;
    stroke: currentColor;
}

.todoapp-wrap .todoapp-content .todoapp-detail-wrap .task-info header.task-header .task-options-wrap>a {
    display: inline-block;
}

.todoapp-wrap .todoapp-content .todoapp-detail-wrap .task-info header.task-header .task-options-wrap>a:first-child {
    margin-left: 0;
}

.todoapp-wrap .todoapp-content .todoapp-detail-wrap .task-info .task-detail-body {
    padding: 1.25rem;
}

.todoapp-wrap .todoapp-content .todoapp-detail-wrap .task-info .task-detail-body form .media .media-head {
    margin-right: 1rem;
}

.todoapp-wrap .todoapp-content .todoapp-detail-wrap .task-info .task-detail-body form .media .media-body .as-name {
    font-weight: 500;
    color: #262a2e;
}

.todoapp-wrap .todoapp-content .todoapp-detail-wrap .task-info .task-detail-body .tab-content .tab-pane .note-block .media {
    margin-bottom: 1.25rem;
}

.todoapp-wrap .todoapp-content .todoapp-detail-wrap .task-info .task-detail-body .tab-content .tab-pane .note-block .media .media-head {
    margin-right: 1rem;
}

.todoapp-wrap .todoapp-content .todoapp-detail-wrap .task-info .task-detail-body .tab-content .tab-pane .note-block .media .media-body div:first-child {
    font-weight: 500;
    color: #262a2e;
}

.todoapp-wrap .todoapp-content .todoapp-detail-wrap .task-info .task-detail-body .tab-content .tab-pane .note-block .media .media-body div:last-child {
    font-size: 0.875rem;
}

.todoapp-wrap .todoapp-content .todoapp-detail-wrap .task-info .task-detail-body .tab-content .tab-pane .comment-block .media .media-head {
    margin-right: 1rem;
}

.todoapp-wrap .todoapp-content .todoapp-detail-wrap .task-info .task-detail-body .tab-content .tab-pane .comment-block .media .media-body>div:first-child {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 1rem;
}

.todoapp-wrap .todoapp-content .todoapp-detail-wrap .task-info .task-detail-body .tab-content .tab-pane .comment-block .media .media-body>div:first-child .cm-name {
    font-weight: 500;
    color: #262a2e;
}

.todoapp-wrap .todoapp-content .todoapp-detail-wrap .task-info .task-detail-body .tab-content .tab-pane .comment-block .media .media-body>div:first-child .badge {
    margin-left: .625rem;
    flex-shrink: 0;
}

.todoapp-wrap .todoapp-content .todoapp-detail-wrap .task-info .task-detail-body .tab-content .tab-pane .comment-block .media .media-body .comment-action-wrap span,
.todoapp-wrap .todoapp-content .todoapp-detail-wrap .task-info .task-detail-body .tab-content .tab-pane .comment-block .media .media-body .comment-action-wrap a {
    color: #6f6f6f;
    font-size: 0.75rem;
}

.todoapp-wrap .todoapp-content .todoapp-detail-wrap .task-info .task-detail-body .tab-content .tab-pane .comment-block .media .media-body .comment-action-wrap .comment-dot-sep {
    margin: 0 .25rem;
    color: #d8d8d8;
}

.todoapp-wrap .todoapp-content .todoapp-detail-wrap .task-info .task-detail-body .tab-content .tab-pane .comment-block .media .media-body>.media {
    margin-top: 1.875rem;
}

.todoapp-wrap .todoapp-content .todoapp-detail-wrap .task-info .task-detail-body .tab-content .tab-pane .file-block .sh-files {
    padding-left: 0;
    margin: 0 -.8rem;
}

.todoapp-wrap .todoapp-content .todoapp-detail-wrap .task-info .task-detail-body .tab-content .tab-pane .file-block .sh-files li {
    padding: .8rem;
    border-radius: 0.5rem;
    -webkit-transition: 0.2s ease;
    -moz-transition: 0.2s ease;
    transition: 0.2s ease;
    margin-bottom: .25rem;
}

.todoapp-wrap .todoapp-content .todoapp-detail-wrap .task-info .task-detail-body .tab-content .tab-pane .file-block .sh-files li:last-child {
    margin-bottom: 0;
}

.todoapp-wrap .todoapp-content .todoapp-detail-wrap .task-info .task-detail-body .tab-content .tab-pane .file-block .sh-files li .media {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.todoapp-wrap .todoapp-content .todoapp-detail-wrap .task-info .task-detail-body .tab-content .tab-pane .file-block .sh-files li .media .avatar.avatar-sm {
    margin-right: 1rem;
}

.todoapp-wrap .todoapp-content .todoapp-detail-wrap .task-info .task-detail-body .tab-content .tab-pane .file-block .sh-files li .media .avatar.avatar-sm .avatar-icon {
    font-size: 1.75rem;
}

.todoapp-wrap .todoapp-content .todoapp-detail-wrap .task-info .task-detail-body .tab-content .tab-pane .file-block .sh-files li .media .media-body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-width: 0;
}

.todoapp-wrap .todoapp-content .todoapp-detail-wrap .task-info .task-detail-body .tab-content .tab-pane .file-block .sh-files li .media .media-body>div:first-child {
    min-width: 0;
    -ms-flex: 1;
    flex: 1;
}

.todoapp-wrap .todoapp-content .todoapp-detail-wrap .task-info .task-detail-body .tab-content .tab-pane .file-block .sh-files li .media .media-body>div:last-child {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.todoapp-wrap .todoapp-content .todoapp-detail-wrap .task-info .task-detail-body .tab-content .tab-pane .file-block .sh-files li .media .media-body .file-name {
    color: #262a2e;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 500;
    max-width: 200px;
}

.todoapp-wrap .todoapp-content .todoapp-detail-wrap .task-info .task-detail-body .tab-content .tab-pane .file-block .sh-files li .media .media-body .file-size {
    margin-top: .25rem;
    font-size: 0.875rem;
}

.todoapp-wrap .todoapp-content .todoapp-detail-wrap .task-info .task-detail-body .tab-content .tab-pane .file-block .sh-files li:hover {
    background-color: #f2f2f2;
}

.todoapp-wrap .todoapp-content .todoapp-detail-wrap .task-info .task-detail-body .tab-content .tab-pane .file-block .sh-files li:hover .media .media-body .file-name {
    color: #09769F;
}

.todoapp-wrap .todoapp-content .todoapp-detail-wrap .task-info .task-detail-body .tab-content .tab-pane .file-block .sh-files li:hover .media .media-body a.cp-file-action {
    color: #262a2e;
}

.todoapp-wrap.todoapp-sidebar-toggle .todoapp-sidebar {
    left: -270px;
}

.todoapp-wrap.todoapp-sidebar-toggle .todoapp-content {
    padding-left: 0;
}

.todoapp-wrap.todoapp-info-active .todoapp-detail-wrap .todo-body {
    margin-right: 550px;
}

.todoapp-wrap.todoapp-info-active .todoapp-detail-wrap .task-info {
    right: 0;
}

.todoapp-wrap.ganttapp-wrap .todoapp-content .todoapp-detail-wrap .nicescroll-bar {
    padding: 0;
}

.todoapp-wrap.ganttapp-wrap .todoapp-content .todoapp-detail-wrap .nicescroll-bar table.dataTable.cell-border thead th {
    border-right: 1px solid #eaeaea;
}

.todoapp-wrap.ganttapp-wrap .todoapp-content .todoapp-detail-wrap .nicescroll-bar table.dataTable.cell-border tbody tr th:first-child,
.todoapp-wrap.ganttapp-wrap .todoapp-content .todoapp-detail-wrap .nicescroll-bar table.dataTable.cell-border tbody tr td:first-child {
    border-left: 0;
}

.advance-list-item.single-task-list .form-check {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 0;
}

.advance-list-item.single-task-list .form-check+div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-weight: 500;
}

.advance-list-item.single-task-list .form-check+div .todo-star {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-left: .625rem;
}

.advance-list-item.single-task-list .form-check+div .todo-star>.feather-icon>svg,
.advance-list-item.single-task-list .form-check+div .todo-star .svg-icon>svg {
    cursor: pointer;
}

.advance-list-item.single-task-list .form-check+div .todo-star>.feather-icon>svg:hover,
.advance-list-item.single-task-list .form-check+div .todo-star .svg-icon>svg:hover {
    fill: #FFC400;
    stroke: #FFC400;
}

.advance-list-item.single-task-list .form-check+div .todo-star.marked>.feather-icon>svg,
.advance-list-item.single-task-list .form-check+div .todo-star.marked .svg-icon>svg {
    fill: #FFC400;
    stroke: #FFC400;
}

.advance-list-item.single-task-list .form-check+div .todo-star.marked>.feather-icon>svg:hover,
.advance-list-item.single-task-list .form-check+div .todo-star.marked .svg-icon>svg:hover {
    fill: none;
    stroke: currentColor;
}

.advance-list-item.single-task-list .form-check+div .todo-text {
    cursor: pointer;
    max-width: 300px;
}

.advance-list-item.single-task-list .form-check+div .badge-indicator {
    margin: 0 .75rem 0 .5rem;
    flex-shrink: 0;
}

.advance-list-item.single-task-list.selected {
    background: #EBFAFF;
}

.hk-wrapper[data-navbar-style="collapsed"] .todoapp-wrap {
    height: 100vh;
}

@media (max-width: 1740px) {
    .todoapp-wrap .todoapp-sidebar {
        left: -270px;
    }

    .todoapp-wrap .todoapp-content {
        margin-left: 0;
        padding-left: 0;
    }

    .todoapp-wrap.todoapp-sidebar-toggle .todoapp-sidebar {
        left: 0;
    }

    .todoapp-wrap.todoapp-sidebar-toggle .todoapp-content {
        left: 270px;
        padding-left: 270px;
        margin-left: -270px;
    }

    .todoapp-wrap .hk-sidebar-togglable {
        -webkit-transform: translateX(32px);
        -moz-transform: translateX(32px);
        -ms-transform: translateX(32px);
        -o-transform: translateX(32px);
        transform: translateX(32px);
    }

    .todoapp-wrap .hk-sidebar-togglable:after {
        content: "\ea6c";
    }

    .todoapp-wrap .hk-sidebar-togglable.active {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }

    .todoapp-wrap .hk-sidebar-togglable.active:after {
        content: "\ea62";
    }
}

@media (max-width: 1500px) {
    .todoapp-wrap.todoapp-info-active .todoapp-detail-wrap .todo-body {
        margin-right: 0;
    }

    .todoapp-wrap.todoapp-info-active .todoapp-detail-wrap .task-info {
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    }
}

@media (max-width: 1199px) {
    .todoapp-wrap .todoapp-content .todoapp-detail-wrap .task-info {
        top: 0;
        width: 100%;
        right: -100%;
    }

    .todoapp-wrap.todoapp-info-active .todoapp-detail-wrap .task-info {
        right: 0;
    }
}

@media (max-width: 767px) {
    .advance-list-item.single-task-list {
        min-height: auto;
    }

    .advance-list-item.single-task-list .form-check+div .todo-text {
        cursor: pointer;
        max-width: calc(100vw - 250px);
    }
}

/*Blog App*/
.blogapp-wrap {
    position: relative;
    height: calc(100vh - 65px);
}

.blogapp-wrap .blogapp-sidebar {
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-flow: column;
    left: 0;
    width: 250px;
    margin-left: 0;
    top: 0;
    height: 100%;
    z-index: 1;
    border: none;
    background: #fff;
    border-right: 1px solid #eaeaea;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.blogapp-wrap .blogapp-sidebar>div.nicescroll-bar {
    flex: 1 1 auto;
    overflow: auto;
}

.blogapp-wrap .blogapp-sidebar .menu-content-wrap {
    padding: 1.5rem;
}

.blogapp-wrap .blogapp-sidebar .menu-content-wrap .menu-gap {
    height: 25px;
}

.blogapp-wrap .blogapp-sidebar .menu-content-wrap .nav-header {
    padding: 0 0 .5rem 0;
    font-size: 0.875rem;
    color: #09769F;
    font-weight: 500;
}

.blogapp-wrap .blogapp-sidebar .menu-content-wrap .menu-group>.navbar-nav>.nav-item {
    margin-left: -1rem;
    margin-right: -1rem;
}

.blogapp-wrap .blogapp-sidebar .menu-content-wrap .menu-group>.navbar-nav>.nav-item>.nav-link {
    border-radius: .5rem;
    padding: .5rem 1rem;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.blogapp-wrap .blogapp-sidebar .menu-content-wrap .menu-group>.navbar-nav>.nav-item>.nav-link[data-toggle="collapse"]::after {
    content: '\f2f9';
    font: normal normal normal 18px/1 'Material-Design-Iconic-Font';
    text-rendering: auto;
    margin-left: auto;
    -webkit-transform: translateX(0) rotate(0deg);
    -moz-transform: translateX(0) rotate(0deg);
    -ms-transform: translateX(0) rotate(0deg);
    -o-transform: translateX(0) rotate(0deg);
    transform: translateX(0) rotate(0deg);
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;
    color: rgba(38, 42, 46, 0.3);
}

.blogapp-wrap .blogapp-sidebar .menu-content-wrap .menu-group>.navbar-nav>.nav-item>.nav-link[data-toggle="collapse"][aria-expanded="true"]::after {
    -webkit-transform: translateX(0) rotate(-180deg);
    -moz-transform: translateX(0) rotate(-180deg);
    -ms-transform: translateX(0) rotate(-180deg);
    -o-transform: translateX(0) rotate(-180deg);
    transform: translateX(0) rotate(-180deg);
}

.blogapp-wrap .blogapp-sidebar .menu-content-wrap .menu-group>.navbar-nav>.nav-item>.nav-link .nav-icon-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-right: .875rem;
    font-size: 1.5rem;
}

.blogapp-wrap .blogapp-sidebar .menu-content-wrap .menu-group>.navbar-nav>.nav-item>.nav-link .nav-icon-wrap .nav-link-text {
    display: inline-block;
}

.blogapp-wrap .blogapp-sidebar .menu-content-wrap .menu-group>.navbar-nav>.nav-item .secondary-nav .nav-item .nav-link {
    padding-left: 3.3rem;
}

.blogapp-wrap .blogapp-sidebar .menu-content-wrap .menu-group>.navbar-nav>.nav-item.active>.nav-link {
    background: rgba(0, 125, 136, 0.15);
    color: #09769F;
}

.blogapp-wrap .blogapp-sidebar .tag-cloud .badge {
    margin-bottom: .25rem;
}

.blogapp-wrap .blogapp-sidebar .blogapp-fixednav {
    height: 61px;
    border-top: 1px solid #eaeaea;
}

.blogapp-wrap .blogapp-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    padding-left: 16.875rem;
    position: relative;
    height: 100%;
    left: 0;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.blogapp-wrap .blogapp-content .blogapp-detail-wrap {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-grow: 1;
    background: #fff;
    flex-flow: column;
    height: 100%;
    z-index: 10;
}

.blogapp-wrap .blogapp-content .blogapp-detail-wrap header.blog-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
    height: 65px;
    padding: 0 1.25rem;
    background: #fff;
    border-bottom: 1px solid #eaeaea;
    position: relative;
}

.blogapp-wrap .blogapp-content .blogapp-detail-wrap header.blog-header .blogapp-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.blogapp-wrap .blogapp-content .blogapp-detail-wrap header.blog-header .blogapp-title h1 {
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 0;
}

.blogapp-wrap .blogapp-content .blogapp-detail-wrap header.blog-header .blogapp-title:after {
    top: 0;
}

.blogapp-wrap .blogapp-content .blogapp-detail-wrap header.blog-header .blog-options-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.blogapp-wrap .blogapp-content .blogapp-detail-wrap header.blog-header .blog-options-wrap>a {
    margin-left: .25rem;
    display: inline-block;
}

.blogapp-wrap .blogapp-content .blogapp-detail-wrap header.blog-header .blog-options-wrap>a:first-child {
    margin-left: 0;
}

.blogapp-wrap .blogapp-content .blogapp-detail-wrap .blog-body {
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
    overflow: hidden;
    -ms-flex: 1;
    flex: 1;
}

.blogapp-wrap .blogapp-content .blogapp-detail-wrap .blog-body .nicescroll-bar {
    padding: 1.25rem;
}

.blogapp-wrap .blogapp-content .blogapp-detail-wrap .blog-body .nicescroll-bar .blog-toolbar-left,
.blogapp-wrap .blogapp-content .blogapp-detail-wrap .blog-body .nicescroll-bar .blog-toolbar-right {
    min-height: 45px;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.blogapp-wrap .blogapp-content .blogapp-detail-wrap .blog-body .nicescroll-bar .blog-toolbar-left>div {
    margin-right: 1rem;
}

.blogapp-wrap .blogapp-content .blogapp-detail-wrap .blog-body .nicescroll-bar .blog-toolbar-left>div:last-child {
    margin-right: 0;
}

.blogapp-wrap .blogapp-content .blogapp-detail-wrap .blog-body .nicescroll-bar .blog-toolbar-right {
    justify-content: flex-end;
    -webkit-justify-content: flex-end;
}

.blogapp-wrap .blogapp-content .blogapp-detail-wrap .blog-body .nicescroll-bar .blog-toolbar-right>div {
    margin-left: 1rem;
}

.blogapp-wrap .blogapp-content .blogapp-detail-wrap .blog-body .nicescroll-bar .blog-toolbar-right>div:first-child {
    margin-left: 0;
}

.blogapp-wrap .blogapp-content .blogapp-detail-wrap .blog-body .nicescroll-bar .blog-toolbar-right .dataTables_filter,
.blogapp-wrap .blogapp-content .blogapp-detail-wrap .blog-body .nicescroll-bar .blog-toolbar-right .dataTables_length,
.blogapp-wrap .blogapp-content .blogapp-detail-wrap .blog-body .nicescroll-bar .blog-toolbar-right .dataTables_info {
    float: none;
}

.blogapp-wrap .blogapp-content .blogapp-detail-wrap .blog-body .nicescroll-bar .blog-toolbar-right .dataTables_filter label,
.blogapp-wrap .blogapp-content .blogapp-detail-wrap .blog-body .nicescroll-bar .blog-toolbar-right .dataTables_length label,
.blogapp-wrap .blogapp-content .blogapp-detail-wrap .blog-body .nicescroll-bar .blog-toolbar-right .dataTables_info label {
    margin-bottom: 0;
}

.blogapp-wrap .blogapp-content .blogapp-detail-wrap .blog-body .nicescroll-bar .blog-toolbar-right .dataTables_info {
    padding-top: 0;
    width: 95px;
    text-align: right;
}

.blogapp-wrap .blogapp-content .blogapp-detail-wrap .blog-body .nicescroll-bar .blog-toolbar-right .dataTables_paginate {
    padding-top: 0;
}

.blogapp-wrap .blogapp-content .blogapp-detail-wrap .blog-body .nicescroll-bar .blog-toolbar-right .dataTables_paginate .pagination.custom-pagination .paginate_button:not(:first-child):not(:last-child) {
    display: none;
}

.blogapp-wrap .blogapp-content .blogapp-detail-wrap .blog-body .nicescroll-bar .blog-toolbar-right .dataTables_paginate .pagination.custom-pagination .page-link {
    padding: 0;
    background: transparent;
    min-width: auto;
    font-size: 1.25rem;
}

.blogapp-wrap .blogapp-content .blogapp-detail-wrap .blog-body .nicescroll-bar .blog-toolbar-right .dataTables_length select {
    margin-right: 0;
    margin-left: .625rem;
    display: inline-block;
    width: auto;
}

.blogapp-wrap .blogapp-content .blogapp-detail-wrap .blog-body .nicescroll-bar .edit-post-form .tox-tinymce {
    border: 0;
}

.blogapp-wrap .blogapp-content .blogapp-detail-wrap .blog-body .nicescroll-bar .edit-post-form .tox-tinymce .tox-statusbar__text-container {
    display: none !important;
}

.blogapp-wrap .blogapp-content .blogapp-detail-wrap .blog-body .nicescroll-bar .edit-post-form .nav-tabs>.nav-item>.nav-link {
    position: relative;
    z-index: 1;
}

.blogapp-wrap .blogapp-content .blogapp-detail-wrap .blog-body .nicescroll-bar .edit-post-form .nav-tabs>.nav-item>.nav-link.active {
    border-color: #eaeaea #eaeaea #fff;
}

.blogapp-wrap .blogapp-content .blogapp-detail-wrap .blog-body .nicescroll-bar .edit-post-form .advance-option-post .nav-tabs {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
}

.blogapp-wrap .blogapp-content .blogapp-detail-wrap .blog-body .nicescroll-bar .edit-post-form .advance-option-post .uploaded-img-prev a .card {
    min-height: 100px;
    background-size: cover;
    background-position: center;
    margin-bottom: 0;
    margin-top: 1rem;
}

.blogapp-wrap .blogapp-content .blogapp-detail-wrap .blog-body .nicescroll-bar .post-list .nav-line {
    margin: 0 -1.25rem;
    border-bottom: 1px solid #eaeaea;
}

.blogapp-wrap .blogapp-content .blogapp-detail-wrap .blog-body .nicescroll-bar .post-list .post-star {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-left: .625rem;
}

.blogapp-wrap .blogapp-content .blogapp-detail-wrap .blog-body .nicescroll-bar .post-list .post-star>.feather-icon>svg,
.blogapp-wrap .blogapp-content .blogapp-detail-wrap .blog-body .nicescroll-bar .post-list .post-star .svg-icon>svg {
    height: 16px;
    width: 16px;
    cursor: pointer;
}

.blogapp-wrap .blogapp-content .blogapp-detail-wrap .blog-body .nicescroll-bar .post-list .post-star>.feather-icon>svg:hover,
.blogapp-wrap .blogapp-content .blogapp-detail-wrap .blog-body .nicescroll-bar .post-list .post-star .svg-icon>svg:hover {
    fill: #FFC400;
    stroke: #FFC400;
}

.blogapp-wrap .blogapp-content .blogapp-detail-wrap .blog-body .nicescroll-bar .post-list .post-star.marked>.feather-icon>svg,
.blogapp-wrap .blogapp-content .blogapp-detail-wrap .blog-body .nicescroll-bar .post-list .post-star.marked .svg-icon>svg {
    fill: #FFC400;
    stroke: #FFC400;
}

.blogapp-wrap .blogapp-content .blogapp-detail-wrap .blog-body .nicescroll-bar .post-list .post-star.marked>.feather-icon>svg:hover,
.blogapp-wrap .blogapp-content .blogapp-detail-wrap .blog-body .nicescroll-bar .post-list .post-star.marked .svg-icon>svg:hover {
    fill: none;
    stroke: currentColor;
}

.blogapp-wrap.blogapp-sidebar-toggle .blogapp-sidebar {
    left: -270px;
}

.blogapp-wrap.blogapp-sidebar-toggle .blogapp-content {
    padding-left: 0;
}

.hk-wrapper[data-navbar-style="collapsed"] .blogapp-wrap {
    height: 100vh;
}

@media (max-width: 1740px) {

    .blogapp-wrap .blogapp-content .blogapp-detail-wrap .blog-body .nicescroll-bar .blog-toolbar-right .dataTables_info,
    .blogapp-wrap .blogapp-content .blogapp-detail-wrap .blog-body .nicescroll-bar .blog-toolbar-right .dataTables_length {
        display: none;
    }
}

@media (max-width: 767px) {
    .blogapp-wrap .blogapp-sidebar {
        left: -270px;
    }

    .blogapp-wrap .blogapp-content {
        margin-left: 0;
        padding-left: 0;
    }

    .blogapp-wrap.blogapp-sidebar-toggle .blogapp-sidebar {
        left: 0;
    }

    .blogapp-wrap.blogapp-sidebar-toggle .blogapp-content {
        left: 16.875rem;
        padding-left: 16.875rem;
        margin-left: -16.875rem;
    }

    .blogapp-wrap .hk-sidebar-togglable {
        -webkit-transform: translateX(32px);
        -moz-transform: translateX(32px);
        -ms-transform: translateX(32px);
        -o-transform: translateX(32px);
        transform: translateX(32px);
    }

    .blogapp-wrap .hk-sidebar-togglable:after {
        content: "\ea6c";
    }

    .blogapp-wrap .hk-sidebar-togglable.active {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }

    .blogapp-wrap .hk-sidebar-togglable.active:after {
        content: "\ea62";
    }
}

/*invoice*/
.invoiceapp-wrap {
    position: relative;
    height: calc(100vh - 65px);
}

.invoiceapp-wrap .invoiceapp-sidebar {
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-flow: column;
    left: 0;
    width: 270px;
    margin-left: 0;
    top: 0;
    height: 100%;
    z-index: 1;
    border: none;
    background: #fff;
    border-right: 1px solid #eaeaea;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.invoiceapp-wrap .invoiceapp-sidebar>div.nicescroll-bar {
    flex: 1 1 auto;
    overflow: auto;
}

.invoiceapp-wrap .invoiceapp-sidebar .menu-content-wrap {
    padding: 1.5rem;
}

.invoiceapp-wrap .invoiceapp-sidebar .menu-content-wrap .menu-gap {
    height: 25px;
}

.invoiceapp-wrap .invoiceapp-sidebar .menu-content-wrap .nav-header {
    padding: 0 0 .5rem 0;
    font-size: 0.875rem;
    color: #09769F;
    font-weight: 500;
}

.invoiceapp-wrap .invoiceapp-sidebar .menu-content-wrap .menu-group>.navbar-nav>.nav-item {
    margin-left: -1rem;
    margin-right: -1rem;
}

.invoiceapp-wrap .invoiceapp-sidebar .menu-content-wrap .menu-group>.navbar-nav>.nav-item>.nav-link {
    border-radius: .5rem;
    padding: .5rem 1rem;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.invoiceapp-wrap .invoiceapp-sidebar .menu-content-wrap .menu-group>.navbar-nav>.nav-item>.nav-link .nav-icon-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-right: .875rem;
    font-size: 1.5rem;
}

.invoiceapp-wrap .invoiceapp-sidebar .menu-content-wrap .menu-group>.navbar-nav>.nav-item>.nav-link .nav-icon-wrap .nav-link-text {
    display: inline-block;
}

.invoiceapp-wrap .invoiceapp-sidebar .menu-content-wrap .menu-group>.navbar-nav>.nav-item.active>.nav-link {
    background: rgba(0, 125, 136, 0.15);
    color: #09769F;
}

.invoiceapp-wrap .invoiceapp-sidebar .invoiceapp-fixednav {
    height: 61px;
    border-top: 1px solid #eaeaea;
}

.invoiceapp-wrap .invoiceapp-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    padding-left: 16.875rem;
    position: relative;
    height: 100%;
    left: 0;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.invoiceapp-wrap .invoiceapp-content header.invoice-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
    height: 65px;
    padding: 0 1.25rem;
    background: #fff;
    border-bottom: 1px solid #eaeaea;
    position: relative;
}

.invoiceapp-wrap .invoiceapp-content header.invoice-header .invoiceapp-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.invoiceapp-wrap .invoiceapp-content header.invoice-header .invoiceapp-title h1 {
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 0;
}

.invoiceapp-wrap .invoiceapp-content header.invoice-header .invoiceapp-title:after {
    top: 0;
}

.invoiceapp-wrap .invoiceapp-content header.invoice-header .media {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.invoiceapp-wrap .invoiceapp-content header.invoice-header .media .media-head {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-right: 1rem;
}

.invoiceapp-wrap .invoiceapp-content header.invoice-header .media .media-body .user-name {
    font-size: 1rem;
    font-weight: 500;
    color: #262a2e;
    text-transform: capitalize;
}

.invoiceapp-wrap .invoiceapp-content header.invoice-header .media .media-body .user-status {
    font-size: 0.875rem;
}

.invoiceapp-wrap .invoiceapp-content header.invoice-header .invoice-options-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.invoiceapp-wrap .invoiceapp-content header.invoice-header .invoice-options-wrap>a {
    margin-left: .25rem;
    display: inline-block;
}

.invoiceapp-wrap .invoiceapp-content header.invoice-header .invoice-options-wrap>a:first-child {
    margin-left: 0;
}

.invoiceapp-wrap .invoiceapp-content .invoiceapp-detail-wrap {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-grow: 1;
    background: #fff;
    flex-flow: column;
    height: 100%;
    z-index: 10;
}

.invoiceapp-wrap .invoiceapp-content .invoiceapp-detail-wrap .invoice-body {
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
    overflow: hidden;
    -ms-flex: 1;
    flex: 1;
}

.invoiceapp-wrap .invoiceapp-content .invoiceapp-detail-wrap .invoice-body .nicescroll-bar {
    padding: 1.25rem;
}

.invoiceapp-wrap .invoiceapp-content .invoiceapp-detail-wrap .invoice-body .nicescroll-bar .invoice-toolbar-left,
.invoiceapp-wrap .invoiceapp-content .invoiceapp-detail-wrap .invoice-body .nicescroll-bar .invoice-toolbar-right {
    min-height: 45px;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.invoiceapp-wrap .invoiceapp-content .invoiceapp-detail-wrap .invoice-body .nicescroll-bar .invoice-toolbar-left>div {
    margin-right: 1rem;
}

.invoiceapp-wrap .invoiceapp-content .invoiceapp-detail-wrap .invoice-body .nicescroll-bar .invoice-toolbar-left>div:last-child {
    margin-right: 0;
}

.invoiceapp-wrap .invoiceapp-content .invoiceapp-detail-wrap .invoice-body .nicescroll-bar .invoice-toolbar-right {
    justify-content: flex-end;
    -webkit-justify-content: flex-end;
}

.invoiceapp-wrap .invoiceapp-content .invoiceapp-detail-wrap .invoice-body .nicescroll-bar .invoice-toolbar-right>div {
    margin-left: 1rem;
}

.invoiceapp-wrap .invoiceapp-content .invoiceapp-detail-wrap .invoice-body .nicescroll-bar .invoice-toolbar-right>div:first-child {
    margin-left: 0;
}

.invoiceapp-wrap .invoiceapp-content .invoiceapp-detail-wrap .invoice-body .nicescroll-bar .invoice-toolbar-right .dataTables_filter,
.invoiceapp-wrap .invoiceapp-content .invoiceapp-detail-wrap .invoice-body .nicescroll-bar .invoice-toolbar-right .dataTables_length,
.invoiceapp-wrap .invoiceapp-content .invoiceapp-detail-wrap .invoice-body .nicescroll-bar .invoice-toolbar-right .dataTables_info {
    float: none;
}

.invoiceapp-wrap .invoiceapp-content .invoiceapp-detail-wrap .invoice-body .nicescroll-bar .invoice-toolbar-right .dataTables_filter label,
.invoiceapp-wrap .invoiceapp-content .invoiceapp-detail-wrap .invoice-body .nicescroll-bar .invoice-toolbar-right .dataTables_length label,
.invoiceapp-wrap .invoiceapp-content .invoiceapp-detail-wrap .invoice-body .nicescroll-bar .invoice-toolbar-right .dataTables_info label {
    margin-bottom: 0;
}

.invoiceapp-wrap .invoiceapp-content .invoiceapp-detail-wrap .invoice-body .nicescroll-bar .invoice-toolbar-right .dataTables_info {
    padding-top: 0;
    width: 95px;
    text-align: right;
}

.invoiceapp-wrap .invoiceapp-content .invoiceapp-detail-wrap .invoice-body .nicescroll-bar .invoice-toolbar-right .dataTables_paginate {
    padding-top: 0;
}

.invoiceapp-wrap .invoiceapp-content .invoiceapp-detail-wrap .invoice-body .nicescroll-bar .invoice-toolbar-right .dataTables_paginate .pagination.custom-pagination .paginate_button:not(:first-child):not(:last-child) {
    display: none;
}

.invoiceapp-wrap .invoiceapp-content .invoiceapp-detail-wrap .invoice-body .nicescroll-bar .invoice-toolbar-right .dataTables_paginate .pagination.custom-pagination .page-link {
    padding: 0;
    background: transparent;
    min-width: auto;
    font-size: 1.25rem;
}

.invoiceapp-wrap .invoiceapp-content .invoiceapp-detail-wrap .invoice-body .nicescroll-bar .invoice-toolbar-right .dataTables_length select {
    margin-right: 0;
    margin-left: .625rem;
    display: inline-block;
    width: auto;
}

.invoiceapp-wrap .invoiceapp-content .invoiceapp-detail-wrap .invoice-body .nicescroll-bar .invoice-list-view .invoice-star {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-left: .625rem;
}

.invoiceapp-wrap .invoiceapp-content .invoiceapp-detail-wrap .invoice-body .nicescroll-bar .invoice-list-view .invoice-star>.feather-icon>svg,
.invoiceapp-wrap .invoiceapp-content .invoiceapp-detail-wrap .invoice-body .nicescroll-bar .invoice-list-view .invoice-star .svg-icon>svg {
    cursor: pointer;
}

.invoiceapp-wrap .invoiceapp-content .invoiceapp-detail-wrap .invoice-body .nicescroll-bar .invoice-list-view .invoice-star>.feather-icon>svg:hover,
.invoiceapp-wrap .invoiceapp-content .invoiceapp-detail-wrap .invoice-body .nicescroll-bar .invoice-list-view .invoice-star .svg-icon>svg:hover {
    fill: #FFC400;
    stroke: #FFC400;
}

.invoiceapp-wrap .invoiceapp-content .invoiceapp-detail-wrap .invoice-body .nicescroll-bar .invoice-list-view .invoice-star.marked>.feather-icon>svg,
.invoiceapp-wrap .invoiceapp-content .invoiceapp-detail-wrap .invoice-body .nicescroll-bar .invoice-list-view .invoice-star.marked .svg-icon>svg {
    fill: #FFC400;
    stroke: #FFC400;
}

.invoiceapp-wrap .invoiceapp-content .invoiceapp-detail-wrap .invoice-body .nicescroll-bar .invoice-list-view .invoice-star.marked>.feather-icon>svg:hover,
.invoiceapp-wrap .invoiceapp-content .invoiceapp-detail-wrap .invoice-body .nicescroll-bar .invoice-list-view .invoice-star.marked .svg-icon>svg:hover {
    fill: none;
    stroke: currentColor;
}

.invoiceapp-wrap .invoiceapp-content .invoiceapp-detail-wrap .invoice-body .nicescroll-bar .template-invoice-wrap,
.invoiceapp-wrap .invoiceapp-content .invoiceapp-detail-wrap .invoice-body .nicescroll-bar .create-invoice-wrap {
    border: 1px solid #eaeaea;
}

.invoiceapp-wrap .invoiceapp-content .invoiceapp-detail-wrap .invoice-body .nicescroll-bar .template-invoice-wrap .invoice-table thead tr th,
.invoiceapp-wrap .invoiceapp-content .invoiceapp-detail-wrap .invoice-body .nicescroll-bar .create-invoice-wrap .invoice-table thead tr th {
    border-color: transparent;
}

.invoiceapp-wrap .invoiceapp-content .invoiceapp-detail-wrap .invoice-body .nicescroll-bar .template-invoice-wrap .invoice-table thead tr th:first-child,
.invoiceapp-wrap .invoiceapp-content .invoiceapp-detail-wrap .invoice-body .nicescroll-bar .create-invoice-wrap .invoice-table thead tr th:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}

.invoiceapp-wrap .invoiceapp-content .invoiceapp-detail-wrap .invoice-body .nicescroll-bar .template-invoice-wrap .invoice-table thead tr th:last-child,
.invoiceapp-wrap .invoiceapp-content .invoiceapp-detail-wrap .invoice-body .nicescroll-bar .create-invoice-wrap .invoice-table thead tr th:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
}

.invoiceapp-wrap .invoiceapp-content .invoiceapp-detail-wrap .invoice-body .nicescroll-bar .template-invoice-wrap .subtotal-table,
.invoiceapp-wrap .invoiceapp-content .invoiceapp-detail-wrap .invoice-body .nicescroll-bar .create-invoice-wrap .subtotal-table {
    min-width: 450px;
}

.invoiceapp-wrap .invoiceapp-content .invoiceapp-detail-wrap .invoice-body .nicescroll-bar .template-invoice-wrap .invoice-table,
.invoiceapp-wrap .invoiceapp-content .invoiceapp-detail-wrap .invoice-body .nicescroll-bar .template-invoice-wrap .subtotal-table,
.invoiceapp-wrap .invoiceapp-content .invoiceapp-detail-wrap .invoice-body .nicescroll-bar .create-invoice-wrap .invoice-table,
.invoiceapp-wrap .invoiceapp-content .invoiceapp-detail-wrap .invoice-body .nicescroll-bar .create-invoice-wrap .subtotal-table {
    border-collapse: separate;
    border-spacing: 0px;
}

.invoiceapp-wrap .invoiceapp-content .invoiceapp-detail-wrap .invoice-body .nicescroll-bar .template-invoice-wrap .invoice-table td,
.invoiceapp-wrap .invoiceapp-content .invoiceapp-detail-wrap .invoice-body .nicescroll-bar .template-invoice-wrap .invoice-table th,
.invoiceapp-wrap .invoiceapp-content .invoiceapp-detail-wrap .invoice-body .nicescroll-bar .template-invoice-wrap .subtotal-table td,
.invoiceapp-wrap .invoiceapp-content .invoiceapp-detail-wrap .invoice-body .nicescroll-bar .template-invoice-wrap .subtotal-table th,
.invoiceapp-wrap .invoiceapp-content .invoiceapp-detail-wrap .invoice-body .nicescroll-bar .create-invoice-wrap .invoice-table td,
.invoiceapp-wrap .invoiceapp-content .invoiceapp-detail-wrap .invoice-body .nicescroll-bar .create-invoice-wrap .invoice-table th,
.invoiceapp-wrap .invoiceapp-content .invoiceapp-detail-wrap .invoice-body .nicescroll-bar .create-invoice-wrap .subtotal-table td,
.invoiceapp-wrap .invoiceapp-content .invoiceapp-detail-wrap .invoice-body .nicescroll-bar .create-invoice-wrap .subtotal-table th {
    border: 1px solid #eaeaea;
}

.invoiceapp-wrap .invoiceapp-content .invoiceapp-detail-wrap .invoice-body .nicescroll-bar .template-invoice-wrap .form-group,
.invoiceapp-wrap .invoiceapp-content .invoiceapp-detail-wrap .invoice-body .nicescroll-bar .create-invoice-wrap .form-group {
    position: relative;
}

.invoiceapp-wrap .invoiceapp-content .invoiceapp-detail-wrap .invoice-settings {
    position: absolute;
    top: 65px;
    right: -370px;
    width: 370px;
    margin-left: 0;
    bottom: 0;
    z-index: 100;
    border: none;
    background: #fff;
    border-left: 1px solid #eaeaea;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.invoiceapp-wrap .invoiceapp-content .invoiceapp-detail-wrap .invoice-settings .nicescroll-bar {
    padding: 1.25rem;
}

.invoiceapp-wrap.invoiceapp-setting-active .invoiceapp-content .invoiceapp-detail-wrap .invoice-body {
    margin-right: 370px;
}

.invoiceapp-wrap.invoiceapp-setting-active .invoiceapp-content .invoiceapp-detail-wrap .invoice-settings {
    right: 0;
}

.invoiceapp-wrap.invoiceapp-sidebar-toggle .invoiceapp-sidebar {
    left: -270px;
}

.invoiceapp-wrap.invoiceapp-sidebar-toggle .invoiceapp-content {
    padding-left: 0;
}

.hk-wrapper[data-navbar-style="collapsed"] .invoiceapp-wrap {
    height: 100vh;
}

@media (max-width: 1740px) {

    .invoiceapp-wrap .invoiceapp-content .invoiceapp-detail-wrap .invoice-body .nicescroll-bar .invoice-toolbar-right .dataTables_info,
    .invoiceapp-wrap .invoiceapp-content .invoiceapp-detail-wrap .invoice-body .nicescroll-bar .invoice-toolbar-right .dataTables_length {
        display: none;
    }
}

@media (max-width: 1600px) {
    .invoiceapp-wrap .invoiceapp-content .invoiceapp-detail-wrap .invoice-settings {
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    }

    .invoiceapp-wrap .invoiceapp-content .invoiceapp-detail-wrap .invoice-body {
        margin-right: 0;
    }

    .invoiceapp-wrap.invoiceapp-setting-active .invoiceapp-content .invoiceapp-detail-wrap .invoice-body {
        margin-right: 0;
    }
}

@media (max-width: 1199px) {
    .invoiceapp-wrap .invoiceapp-content .invoiceapp-detail-wrap .invoice-settings {
        top: 0;
    }

    .invoiceapp-wrap .invoiceapp-content .invoiceapp-detail-wrap .invoice-body .nicescroll-bar .create-invoice-wrap .invoice-table-wrap {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

    .invoiceapp-wrap .invoiceapp-content .invoiceapp-detail-wrap .invoice-body .nicescroll-bar .create-invoice-wrap .invoice-table-wrap .invoice-table {
        min-width: 900px;
    }

    .invoiceapp-wrap .invoiceapp-sidebar {
        left: -270px;
    }

    .invoiceapp-wrap .invoiceapp-content {
        margin-left: 0;
        padding-left: 0;
    }

    .invoiceapp-wrap.invoiceapp-sidebar-toggle .invoiceapp-sidebar {
        left: 0;
    }

    .invoiceapp-wrap.invoiceapp-sidebar-toggle .invoiceapp-content {
        left: 16.875rem;
        padding-left: 16.875rem;
        margin-left: -16.875rem;
    }

    .invoiceapp-wrap .hk-sidebar-togglable {
        -webkit-transform: translateX(32px);
        -moz-transform: translateX(32px);
        -ms-transform: translateX(32px);
        -o-transform: translateX(32px);
        transform: translateX(32px);
    }

    .invoiceapp-wrap .hk-sidebar-togglable:after {
        content: "\ea6c";
    }

    .invoiceapp-wrap .hk-sidebar-togglable.active {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }

    .invoiceapp-wrap .hk-sidebar-togglable.active:after {
        content: "\ea62";
    }
}

/*Integrations*/
.integrationsapp-wrap {
    position: relative;
    height: calc(100vh - 65px);
}

.integrationsapp-wrap .integrationsapp-sidebar {
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-flow: column;
    left: 0;
    width: 270px;
    margin-left: 0;
    top: 0;
    height: 100%;
    z-index: 1;
    border: none;
    background: #fff;
    border-right: 1px solid #eaeaea;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.integrationsapp-wrap .integrationsapp-sidebar>div.nicescroll-bar {
    flex: 1 1 auto;
    overflow: auto;
}

.integrationsapp-wrap .integrationsapp-sidebar .menu-content-wrap {
    padding: 1.5rem;
}

.integrationsapp-wrap .integrationsapp-sidebar .menu-content-wrap .menu-gap {
    height: 25px;
}

.integrationsapp-wrap .integrationsapp-sidebar .menu-content-wrap .nav-header {
    padding: 0 0 .5rem 0;
    font-size: 0.875rem;
    color: #09769F;
    font-weight: 500;
}

.integrationsapp-wrap .integrationsapp-sidebar .menu-content-wrap .menu-group>.navbar-nav>.nav-item {
    margin-left: -1rem;
    margin-right: -1rem;
}

.integrationsapp-wrap .integrationsapp-sidebar .menu-content-wrap .menu-group>.navbar-nav>.nav-item>.nav-link {
    border-radius: .5rem;
    padding: .5rem 1rem;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.integrationsapp-wrap .integrationsapp-sidebar .menu-content-wrap .menu-group>.navbar-nav>.nav-item>.nav-link .nav-icon-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-right: .875rem;
    font-size: 1.5rem;
}

.integrationsapp-wrap .integrationsapp-sidebar .menu-content-wrap .menu-group>.navbar-nav>.nav-item>.nav-link .nav-icon-wrap .nav-link-text {
    display: inline-block;
}

.integrationsapp-wrap .integrationsapp-sidebar .menu-content-wrap .menu-group>.navbar-nav>.nav-item.active>.nav-link {
    background: rgba(0, 125, 136, 0.15);
    color: #09769F;
}

.integrationsapp-wrap .integrationsapp-sidebar .integrationsapp-fixednav {
    height: 61px;
    border-top: 1px solid #eaeaea;
}

.integrationsapp-wrap .integrationsapp-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    padding-left: 16.875rem;
    position: relative;
    height: 100%;
    left: 0;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.integrationsapp-wrap .integrationsapp-content .integrationsapp-detail-wrap {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-grow: 1;
    background: #fff;
    flex-flow: column;
    height: 100%;
    z-index: 10;
}

.integrationsapp-wrap .integrationsapp-content .integrationsapp-detail-wrap header.integrations-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
    height: 65px;
    padding: 0 1.25rem;
    background: #fff;
    border-bottom: 1px solid #eaeaea;
    position: relative;
}

.integrationsapp-wrap .integrationsapp-content .integrationsapp-detail-wrap header.integrations-header .integrationsapp-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.integrationsapp-wrap .integrationsapp-content .integrationsapp-detail-wrap header.integrations-header .integrationsapp-title h1 {
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 0;
}

.integrationsapp-wrap .integrationsapp-content .integrationsapp-detail-wrap header.integrations-header .integrationsapp-title:after {
    top: 0;
}

.integrationsapp-wrap .integrationsapp-content .integrationsapp-detail-wrap header.integrations-header .integrations-options-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.integrationsapp-wrap .integrationsapp-content .integrationsapp-detail-wrap header.integrations-header .integrations-options-wrap>a {
    margin-left: .25rem;
    display: inline-block;
}

.integrationsapp-wrap .integrationsapp-content .integrationsapp-detail-wrap header.integrations-header .integrations-options-wrap>a:first-child {
    margin-left: 0;
}

.integrationsapp-wrap .integrationsapp-content .integrationsapp-detail-wrap .integrations-body {
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
    overflow: hidden;
    -ms-flex: 1;
    flex: 1;
}

.integrationsapp-wrap .integrationsapp-content .integrationsapp-detail-wrap .integrations-body .nicescroll-bar {
    padding: 1.25rem;
}

.integrationsapp-wrap .integrationsapp-content .integrationsapp-detail-wrap .integrations-body .nicescroll-bar .app-name {
    color: #262a2e;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 500;
}

.integrationsapp-wrap .integrationsapp-content .integrationsapp-detail-wrap .integrations-body .nicescroll-bar .app-cat {
    font-size: 0.875rem;
    margin-bottom: .625rem;
}

.integrationsapp-wrap .integrationsapp-content .integrationsapp-detail-wrap .integrations-body .nicescroll-bar .tab-content .comment-block .media .media-head {
    margin-right: 1rem;
}

.integrationsapp-wrap .integrationsapp-content .integrationsapp-detail-wrap .integrations-body .nicescroll-bar .tab-content .comment-block .media .media-body>div:first-child {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: .25rem;
}

.integrationsapp-wrap .integrationsapp-content .integrationsapp-detail-wrap .integrations-body .nicescroll-bar .tab-content .comment-block .media .media-body>div:first-child .cm-name {
    font-weight: 500;
    color: #262a2e;
}

.integrationsapp-wrap .integrationsapp-content .integrationsapp-detail-wrap .integrations-body .nicescroll-bar .tab-content .comment-block .media .media-body>div:first-child .badge {
    margin-left: .625rem;
    flex-shrink: 0;
}

.integrationsapp-wrap .integrationsapp-content .integrationsapp-detail-wrap .integrations-body .nicescroll-bar .tab-content .comment-block .media .media-body .comment-action-wrap span,
.integrationsapp-wrap .integrationsapp-content .integrationsapp-detail-wrap .integrations-body .nicescroll-bar .tab-content .comment-block .media .media-body .comment-action-wrap a {
    color: #6f6f6f;
    font-size: 0.75rem;
}

.integrationsapp-wrap .integrationsapp-content .integrationsapp-detail-wrap .integrations-body .nicescroll-bar .tab-content .comment-block .media .media-body .comment-action-wrap .comment-dot-sep {
    margin: 0 .25rem;
    color: #d8d8d8;
}

.integrationsapp-wrap .integrationsapp-content .integrationsapp-detail-wrap .integrations-body .nicescroll-bar .tab-content .comment-block .media .media-body>.media {
    margin-top: 1.875rem;
}

.integrationsapp-wrap .integrationsapp-content .integrationsapp-detail-wrap .integrations-body .nicescroll-bar .tab-content .review-block .media .media-head {
    margin-right: 1rem;
}

.integrationsapp-wrap .integrationsapp-content .integrationsapp-detail-wrap .integrations-body .nicescroll-bar .tab-content .review-block .media .media-body .cr-name {
    font-weight: 500;
    color: #262a2e;
}

.integrationsapp-wrap .integrationsapp-content .integrationsapp-detail-wrap .integrations-body .nicescroll-bar .tab-content .review-block .review-action-wrap span,
.integrationsapp-wrap .integrationsapp-content .integrationsapp-detail-wrap .integrations-body .nicescroll-bar .tab-content .review-block .review-action-wrap a {
    font-size: 0.75rem;
}

.integrationsapp-wrap .integrationsapp-content .integrationsapp-detail-wrap .integrations-body .nicescroll-bar .tab-content .review-block .review-action-wrap .review-dot-sep {
    margin: 0 .25rem;
    color: #d8d8d8;
}

.integrationsapp-wrap .integrationsapp-content .integrationsapp-detail-wrap .integrations-body .nicescroll-bar .tab-content .review-block .review .review {
    margin-left: 3.125rem;
    margin-top: 1.875rem;
}

.integrationsapp-wrap .integrationsapp-content .integrationsapp-detail-wrap .integrations-body .nicescroll-bar .card-int {
    min-height: 250px;
}

.integrationsapp-wrap .integrationsapp-content .integrationsapp-detail-wrap .integrations-body .nicescroll-bar .content-aside .tag-cloud .badge {
    margin-bottom: .25rem;
}

.integrationsapp-wrap.integrationsapp-sidebar-toggle .integrationsapp-sidebar {
    left: -270px;
}

.integrationsapp-wrap.integrationsapp-sidebar-toggle .integrationsapp-content {
    padding-left: 0;
}

.hk-wrapper[data-navbar-style="collapsed"] .integrationsapp-wrap {
    height: 100vh;
}

@media (max-width: 991px) {
    .integrationsapp-wrap .integrationsapp-sidebar {
        left: -270px;
    }

    .integrationsapp-wrap .integrationsapp-content {
        margin-left: 0;
        padding-left: 0;
    }

    .integrationsapp-wrap.integrationsapp-sidebar-toggle .integrationsapp-sidebar {
        left: 0;
    }

    .integrationsapp-wrap.integrationsapp-sidebar-toggle .integrationsapp-content {
        left: 16.875rem;
        padding-left: 16.875rem;
        margin-left: -16.875rem;
    }

    .integrationsapp-wrap .hk-sidebar-togglable {
        -webkit-transform: translateX(32px);
        -moz-transform: translateX(32px);
        -ms-transform: translateX(32px);
        -o-transform: translateX(32px);
        transform: translateX(32px);
    }

    .integrationsapp-wrap .hk-sidebar-togglable:after {
        content: "\ea6c";
    }

    .integrationsapp-wrap .hk-sidebar-togglable.active {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }

    .integrationsapp-wrap .hk-sidebar-togglable.active:after {
        content: "\ea62";
    }
}