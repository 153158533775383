
/*------------------------------------------------------------------
[Classic Menu]
Project:	Jampack
--------------------------------------------------------------------*/
/**Vertical Navigation**/
.hk-wrapper[data-layout="vertical"] .hk-pg-wrapper {
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.hk-wrapper[data-layout="vertical"] .hk-menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-flow: column;
    position: fixed;
    top: 0;
    bottom: 0;
    width: 270px;
    margin-left: 0;
    z-index: 1032;
    border-right: 1px solid #eaeaea;
    -webkit-transition: all 0.1s ease;
    -moz-transition: all 0.1s ease;
    transition: all 0.1s ease;
    /*Sidebar fixednav*/
    /*Horizontal Scrollbar*/
}

.hk-wrapper[data-layout="vertical"] .hk-menu>.nicescroll-bar {
    overflow: auto;
    flex: 1 1 auto;
}

.hk-wrapper[data-layout="vertical"] .hk-menu .menu-content-wrap {
    padding: 1.5rem 1.925rem;
    position: relative;
}

.hk-wrapper[data-layout="vertical"] .hk-menu .menu-content-wrap .menu-group .nav-header {
    padding: 0 0 .5rem 0;
    font-size: 0.875rem;
    color: #9e9e9e;
    font-weight: 500;
}

.hk-wrapper[data-layout="vertical"] .hk-menu .menu-content-wrap .menu-group .navbar-nav .nav-item .nav-link {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: .5rem 1rem;
    margin: 0 -1rem;
    white-space: nowrap;
}

.hk-wrapper[data-layout="vertical"] .hk-menu .menu-content-wrap .menu-group .navbar-nav .nav-item .nav-link>* {
    -webkit-transition: -webkit-transform 0.25s ease;
    -moz-transition: -moz-transform 0.25s ease;
    transition: transform 0.25s ease;
}

.hk-wrapper[data-layout="vertical"] .hk-menu .menu-content-wrap .menu-group .navbar-nav .nav-item .nav-link .nav-icon-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-right: .65rem;
}

.hk-wrapper[data-layout="vertical"] .hk-menu .menu-content-wrap .menu-group .navbar-nav .nav-item .nav-link .nav-icon-wrap>*:not(.badge) {
    font-size: 1.5rem;
}

.hk-wrapper[data-layout="vertical"] .hk-menu .menu-content-wrap .menu-group .navbar-nav .nav-item .nav-link[data-bs-toggle="collapse"]::after {
    position: absolute;
    right: 1rem;
    z-index: -1;
    content: '\f2f9';
    font: normal normal normal 1.125rem/1 'Material-Design-Iconic-Font';
    text-rendering: auto;
    margin-left: auto;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.hk-wrapper[data-layout="vertical"] .hk-menu .menu-content-wrap .menu-group .navbar-nav .nav-item .nav-link[data-bs-toggle="collapse"][aria-expanded="true"]::after {
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg);
}

.hk-wrapper[data-layout="vertical"] .hk-menu .menu-content-wrap .menu-group .navbar-nav .nav-item .nav-link:hover>* {
    -webkit-transform: translateX(5px);
    -moz-transform: translateX(5px);
    -ms-transform: translateX(5px);
    -o-transform: translateX(5px);
    transform: translateX(5px);
}

.hk-wrapper[data-layout="vertical"] .hk-menu .menu-content-wrap .menu-group .navbar-nav .nav-item.active>.nav-link {
    color: #09769F !important;
}

.hk-wrapper[data-layout="vertical"] .hk-menu .menu-content-wrap .menu-group .navbar-nav>.nav-item.active>.nav-link {
    background: rgba(0, 125, 136, 0.15);
    color: #09769F !important;
    border-radius: 0.5rem;
}

.hk-wrapper[data-layout="vertical"] .hk-menu .menu-content-wrap .menu-group .navbar-nav>.nav-item>ul {
    position: relative;
    padding: 0.5rem 0;
}

.hk-wrapper[data-layout="vertical"] .hk-menu .menu-content-wrap .menu-group .navbar-nav>.nav-item>ul:after {
    content: "";
    width: 1px;
    height: calc(100% - 20px);
    position: absolute;
    left: 10px;
    top: 0;
    bottom: 0;
    margin: auto;
}

.hk-wrapper[data-layout="vertical"] .hk-menu .menu-content-wrap .menu-group .nav-children .nav-item .nav-link {
    font-size: 0.875rem;
    padding: 0.15rem 0 0.15rem 1.4rem;
    margin: 0 0 0 1rem;
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    transition: all 0.25s ease;
}

.hk-wrapper[data-layout="vertical"] .hk-menu .menu-content-wrap .menu-group .nav-children .nav-item .nav-link[data-bs-toggle="collapse"]::after {
    right: 0;
}

.hk-wrapper[data-layout="vertical"] .hk-menu .menu-content-wrap .menu-group .nav-children .nav-children .nav-item .nav-link {
    padding: 0.15rem 0 0.15rem 2rem;
}

.hk-wrapper[data-layout="vertical"] .hk-menu .menu-content-wrap .menu-gap {
    height: 25px;
}

.hk-wrapper[data-layout="vertical"] .hk-menu .menu-header {
    padding: 0.5rem 1.1875rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex: 0 0 auto;
    height: 65px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    overflow: hidden;
}

.hk-wrapper[data-layout="vertical"] .hk-menu .menu-header .navbar-toggle {
    flex-shrink: 0;
}

.hk-wrapper[data-layout="vertical"] .hk-menu .menu-header>span {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    flex-grow: 1;
    justify-content: space-between;
}

.hk-wrapper[data-layout-style=collapsed] .hk-menu .menu-header>span {
  padding-right: 15px;
}

.hk-wrapper[data-layout="vertical"] .hk-menu .menu-header>span .navbar-brand .brand-img:last-child {
    margin-left: 0.5rem;
}

.hk-wrapper[data-layout="vertical"] .hk-menu .bottom-nav {
    flex: 0 0 auto;
    padding-bottom: .5rem;
}

.hk-wrapper[data-layout="vertical"] .hk-menu .simplebar-track.simplebar-horizontal {
    display: none;
}

.hk-wrapper[data-layout="vertical"][data-layout-style="default"] .hk-pg-wrapper {
    margin-left: 0;
}

.hk-wrapper[data-layout="vertical"][data-layout-style="default"] .hk-navbar {
    left: 0;
}

.hk-wrapper[data-layout="vertical"][data-layout-style="default"] .hk-menu {
    left: -270px;
}

.hk-wrapper[data-layout="vertical"][data-layout-style="collapsed"] .hk-menu {
    left: 0;
}

.hk-wrapper[data-layout="vertical"][data-layout-style="collapsed"] .hk-menu-backdrop {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 1031;
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    transition: all 0.25s ease;
    background: rgba(38, 42, 46, 0.5);
    opacity: 1;
    height: auto;
    width: auto;
    visibility: visible;
}

.hk-wrapper[data-layout="vertical"][data-menu="light"] .hk-menu {
    background: #fff;
}

.hk-wrapper[data-layout="vertical"][data-menu="light"] .hk-menu .menu-content-wrap .menu-group .navbar-nav .nav-item .nav-link {
    color: #262a2e;
}

.hk-wrapper[data-layout="vertical"][data-menu="light"] .hk-menu .menu-content-wrap .menu-group .navbar-nav .nav-item .nav-link[data-bs-toggle="collapse"]::after {
    color: rgba(38, 42, 46, 0.3);
}

.hk-wrapper[data-layout="vertical"][data-menu="light"] .hk-menu .menu-content-wrap .menu-group .navbar-nav>.nav-item>ul:after {
    background: #d8d8d8;
}

.hk-wrapper[data-layout="vertical"][data-menu="dark"] .hk-menu {
    background: #262a2e;
}

.hk-wrapper[data-layout="vertical"][data-menu="dark"] .hk-menu .menu-content-wrap .menu-group .navbar-nav .nav-item .nav-link {
    color: #fff;
}

.hk-wrapper[data-layout="vertical"][data-menu="dark"] .hk-menu .menu-content-wrap .menu-group .navbar-nav .nav-item .nav-link[data-bs-toggle="collapse"]::after {
    color: rgba(255, 255, 255, 0.3);
}

.hk-wrapper[data-layout="vertical"][data-menu="dark"] .hk-menu .menu-content-wrap .menu-group .navbar-nav>.nav-item>ul:after {
    background: #d8d8d8;
}

@media (min-width: 1200px) {
    .hk-wrapper[data-layout="vertical"][data-layout-style="default"] .hk-pg-wrapper {
        margin-left: 270px;
    }

    .hk-wrapper[data-layout="vertical"][data-layout-style="default"] .hk-navbar {
        left: 270px;
    }

    .hk-wrapper[data-layout="vertical"][data-layout-style="default"] .hk-menu {
        left: 0;
    }

    .hk-wrapper[data-layout="vertical"][data-layout-style="collapsed"] .hk-pg-wrapper {
        margin-left: 96px;
    }

    .hk-wrapper[data-layout="vertical"][data-layout-style="collapsed"] .hk-navbar {
        left: 96px;
    }

    .hk-wrapper[data-layout="vertical"][data-layout-style="collapsed"] .hk-menu {
        width: 96px;
    }

    .hk-wrapper[data-layout="vertical"][data-layout-style="collapsed"] .hk-menu .menu-content-wrap>.callout {
        display: none;
    }

    .hk-wrapper[data-layout="vertical"][data-layout-style="collapsed"] .hk-menu .menu-content-wrap .menu-group .nav-header {
        display: none;
    }

    .hk-wrapper[data-layout="vertical"][data-layout-style="collapsed"] .hk-menu .menu-content-wrap .menu-group .navbar-nav>.nav-item>.nav-link>*:not(.nav-icon-wrap) {
        visibility: hidden;
        flex-shrink: 0;
    }

    .hk-wrapper[data-layout="vertical"][data-layout-style="collapsed"] .hk-menu .menu-content-wrap .menu-group .navbar-nav>.nav-item>.nav-link[data-bs-toggle="collapse"]::after {
        right: -1.5rem;
    }

    .hk-wrapper[data-layout="vertical"][data-layout-style="collapsed"] .hk-menu .menu-content-wrap .menu-group .collapse.show {
        display: none;
    }

    .hk-wrapper[data-layout="vertical"][data-layout-style="collapsed"] .hk-menu .menu-content-wrap .menu-gap {
        display: none;
    }

    .hk-wrapper[data-layout="vertical"][data-layout-style="collapsed"] .hk-menu .menu-header>span .navbar-brand .brand-img:last-child {
        display: none;
    }

    .hk-wrapper[data-layout="vertical"][data-layout-style="collapsed"] .hk-menu .navbar-toggle {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
    }

    .hk-wrapper[data-layout="vertical"][data-layout-style="collapsed"][data-hover="active"] .hk-menu:hover {
        width: 270px;
    }

    .hk-wrapper[data-layout="vertical"][data-layout-style="collapsed"][data-hover="active"] .hk-menu:hover .menu-content-wrap>.callout {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }

    .hk-wrapper[data-layout="vertical"][data-layout-style="collapsed"][data-hover="active"] .hk-menu:hover .menu-content-wrap .menu-group .nav-header {
        display: block;
    }

    .hk-wrapper[data-layout="vertical"][data-layout-style="collapsed"][data-hover="active"] .hk-menu:hover .menu-content-wrap .menu-group .navbar-nav>.nav-item>.nav-link>*:not(.nav-icon-wrap) {
        visibility: visible;
    }

    .hk-wrapper[data-layout="vertical"][data-layout-style="collapsed"][data-hover="active"] .hk-menu:hover .menu-content-wrap .menu-group .navbar-nav>.nav-item>.nav-link[data-bs-toggle="collapse"]::after {
        right: 1rem;
    }

    .hk-wrapper[data-layout="vertical"][data-layout-style="collapsed"][data-hover="active"] .hk-menu:hover .menu-content-wrap .menu-group .collapse.show {
        display: -ms-flexbox;
        display: flex;
    }

    .hk-wrapper[data-layout="vertical"][data-layout-style="collapsed"][data-hover="active"] .hk-menu:hover .menu-content-wrap .menu-gap {
        display: block;
    }

    .hk-wrapper[data-layout="vertical"][data-layout-style="collapsed"][data-hover="active"] .hk-menu:hover .menu-header>span .navbar-brand .brand-img:last-child {
        display: inline;
    }

    .hk-wrapper[data-layout="vertical"][data-layout-style="collapsed"] .hk-menu-backdrop {
        opacity: 0;
        height: 0;
        width: 0;
        visibility: hidden;
    }
}
