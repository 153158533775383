/*Breadcrumb*/
.breadcrumb .breadcrumb-item {
    font-size: 0.875rem;
}

.breadcrumb .breadcrumb-item>a {
    color: #2F343A;
}

.breadcrumb .breadcrumb-item>a:hover,
.breadcrumb .breadcrumb-item>a:focus {
    color: #09769F;
}

.breadcrumb .breadcrumb-item.active {
    color: #6f6f6f;
}

.breadcrumb.breadcrumb-sm .breadcrumb-item {
    font-size: 0.75rem;
}

.breadcrumb.breadcrumb-dark .breadcrumb-item>a {
    color: rgba(255, 255, 255, 0.85);
}

.breadcrumb.breadcrumb-dark .breadcrumb-item>a:hover,
.breadcrumb.breadcrumb-dark .breadcrumb-item>a:focus {
    color: white;
}

.breadcrumb.breadcrumb-dark .breadcrumb-item.active {
    color: rgba(255, 255, 255, 0.7);
}

.breadcrumb.breadcrumb-dark .breadcrumb-item+.breadcrumb-item:before {
    color: rgba(255, 255, 255, 0.3);
}

.breadcrumb.breadcrumb-full-width {
    border-radius: 0;
}

.breadcrumb-item+.breadcrumb-item {
    padding-left: .3rem;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: flex;
}

.breadcrumb-item+.breadcrumb-item:before {
    font-family: 'Open Sans', sans-serif !important;
    content: ">";
    padding-right: .3rem;
    position: relative;
    top: 1px;
    font-size: 0.625rem;
    color: rgba(181, 181, 181, 0.8);
}