/*Background Colors*/
.bg-sky-light-5 {
    background-color: rgba(235, 249, 255, var(--bs-bg-opacity)) !important;
}

.bg-sky-light-4 {
    background-color: rgba(199, 238, 255, var(--bs-bg-opacity)) !important;
}

.bg-sky-light-3 {
    background-color: rgba(153, 223, 255, var(--bs-bg-opacity)) !important;
}

.bg-sky-light-2 {
    background-color: rgba(107, 209, 255, var(--bs-bg-opacity)) !important;
}

.bg-sky-light-1 {
    background-color: rgba(61, 195, 255, var(--bs-bg-opacity)) !important;
}

.bg-sky {
    background-color: rgba(0, 176, 255, var(--bs-bg-opacity)) !important;
}

.bg-sky-dark-5 {
    background-color: rgba(0, 88, 128, var(--bs-bg-opacity)) !important;
}

.bg-sky-dark-4 {
    background-color: rgba(0, 106, 153, var(--bs-bg-opacity)) !important;
}

.bg-sky-dark-3 {
    background-color: rgba(0, 123, 179, var(--bs-bg-opacity)) !important;
}

.bg-sky-dark-2 {
    background-color: rgba(0, 141, 204, var(--bs-bg-opacity)) !important;
}

.bg-sky-dark-1 {
    background-color: rgba(0, 158, 230, var(--bs-bg-opacity)) !important;
}

.bg-green-light-5 {
    background-color: rgba(235, 252, 245, var(--bs-bg-opacity)) !important;
}

.bg-green-light-4 {
    background-color: rgba(199, 246, 227, var(--bs-bg-opacity)) !important;
}

.bg-green-light-3 {
    background-color: rgba(153, 239, 204, var(--bs-bg-opacity)) !important;
}

.bg-green-light-2 {
    background-color: rgba(107, 231, 181, var(--bs-bg-opacity)) !important;
}

.bg-green-light-1 {
    background-color: rgba(61, 224, 158, var(--bs-bg-opacity)) !important;
}

.bg-green {
    background-color: rgba(0, 214, 127, var(--bs-bg-opacity)) !important;
}

.bg-green-dark-5 {
    background-color: rgba(0, 107, 64, var(--bs-bg-opacity)) !important;
}

.bg-green-dark-4 {
    background-color: rgba(0, 128, 76, var(--bs-bg-opacity)) !important;
}

.bg-green-dark-3 {
    background-color: rgba(0, 150, 89, var(--bs-bg-opacity)) !important;
}

.bg-green-dark-2 {
    background-color: rgba(0, 171, 102, var(--bs-bg-opacity)) !important;
}

.bg-green-dark-1 {
    background-color: rgba(0, 193, 114, var(--bs-bg-opacity)) !important;
}

.bg-success-light-5 {
    background-color: rgba(235, 252, 245, var(--bs-bg-opacity)) !important;
}

.bg-success-light-4 {
    background-color: rgba(199, 246, 227, var(--bs-bg-opacity)) !important;
}

.bg-success-light-3 {
    background-color: rgba(153, 239, 204, var(--bs-bg-opacity)) !important;
}

.bg-success-light-2 {
    background-color: rgba(107, 231, 181, var(--bs-bg-opacity)) !important;
}

.bg-success-light-1 {
    background-color: rgba(61, 224, 158, var(--bs-bg-opacity)) !important;
}

.bg-success {
    background-color: rgba(0, 214, 127, var(--bs-bg-opacity)) !important;
}

.bg-success-dark-5 {
    background-color: rgba(0, 107, 64, var(--bs-bg-opacity)) !important;
}

.bg-success-dark-4 {
    background-color: rgba(0, 128, 76, var(--bs-bg-opacity)) !important;
}

.bg-success-dark-3 {
    background-color: rgba(0, 150, 89, var(--bs-bg-opacity)) !important;
}

.bg-success-dark-2 {
    background-color: rgba(0, 171, 102, var(--bs-bg-opacity)) !important;
}

.bg-success-dark-1 {
    background-color: rgba(0, 193, 114, var(--bs-bg-opacity)) !important;
}

.bg-yellow-light-5 {
    background-color: rgba(255, 250, 235, var(--bs-bg-opacity)) !important;
}

.bg-yellow-light-4 {
    background-color: rgba(255, 242, 199, var(--bs-bg-opacity)) !important;
}

.bg-yellow-light-3 {
    background-color: rgba(255, 231, 153, var(--bs-bg-opacity)) !important;
}

.bg-yellow-light-2 {
    background-color: rgba(255, 221, 107, var(--bs-bg-opacity)) !important;
}

.bg-yellow-light-1 {
    background-color: rgba(255, 210, 61, var(--bs-bg-opacity)) !important;
}

.bg-yellow {
    background-color: rgba(255, 196, 0, var(--bs-bg-opacity)) !important;
}

.bg-yellow-dark-5 {
    background-color: rgba(128, 98, 0, var(--bs-bg-opacity)) !important;
}

.bg-yellow-dark-4 {
    background-color: rgba(153, 118, 0, var(--bs-bg-opacity)) !important;
}

.bg-yellow-dark-3 {
    background-color: rgba(179, 137, 0, var(--bs-bg-opacity)) !important;
}

.bg-yellow-dark-2 {
    background-color: rgba(204, 157, 0, var(--bs-bg-opacity)) !important;
}

.bg-yellow-dark-1 {
    background-color: rgba(230, 176, 0, var(--bs-bg-opacity)) !important;
}

.bg-warning-light-5 {
    background-color: rgba(255, 250, 235, var(--bs-bg-opacity)) !important;
}

.bg-warning-light-4 {
    background-color: rgba(255, 242, 199, var(--bs-bg-opacity)) !important;
}

.bg-warning-light-3 {
    background-color: rgba(255, 231, 153, var(--bs-bg-opacity)) !important;
}

.bg-warning-light-2 {
    background-color: rgba(255, 221, 107, var(--bs-bg-opacity)) !important;
}

.bg-warning-light-1 {
    background-color: rgba(255, 210, 61, var(--bs-bg-opacity)) !important;
}

.bg-warning {
    background-color: rgba(255, 196, 0, var(--bs-bg-opacity)) !important;
}

.bg-warning-dark-5 {
    background-color: rgba(128, 98, 0, var(--bs-bg-opacity)) !important;
}

.bg-warning-dark-4 {
    background-color: rgba(153, 118, 0, var(--bs-bg-opacity)) !important;
}

.bg-warning-dark-3 {
    background-color: rgba(179, 137, 0, var(--bs-bg-opacity)) !important;
}

.bg-warning-dark-2 {
    background-color: rgba(204, 157, 0, var(--bs-bg-opacity)) !important;
}

.bg-warning-dark-1 {
    background-color: rgba(230, 176, 0, var(--bs-bg-opacity)) !important;
}

.bg-red-light-5 {
    background-color: rgba(255, 235, 235, var(--bs-bg-opacity)) !important;
}

.bg-red-light-4 {
    background-color: rgba(255, 199, 199, var(--bs-bg-opacity)) !important;
}

.bg-red-light-3 {
    background-color: rgba(255, 153, 153, var(--bs-bg-opacity)) !important;
}

.bg-red-light-2 {
    background-color: rgba(255, 107, 107, var(--bs-bg-opacity)) !important;
}

.bg-red-light-1 {
    background-color: rgba(255, 61, 61, var(--bs-bg-opacity)) !important;
}

.bg-red {
    background-color: rgba(255, 0, 0, var(--bs-bg-opacity)) !important;
}

.bg-red-dark-5 {
    background-color: rgba(128, 0, 0, var(--bs-bg-opacity)) !important;
}

.bg-red-dark-4 {
    background-color: rgba(153, 0, 0, var(--bs-bg-opacity)) !important;
}

.bg-red-dark-3 {
    background-color: rgba(179, 0, 0, var(--bs-bg-opacity)) !important;
}

.bg-red-dark-2 {
    background-color: rgba(204, 0, 0, var(--bs-bg-opacity)) !important;
}

.bg-red-dark-1 {
    background-color: rgba(230, 0, 0, var(--bs-bg-opacity)) !important;
}

.bg-danger-light-5 {
    background-color: rgba(255, 235, 235, var(--bs-bg-opacity)) !important;
}

.bg-danger-light-4 {
    background-color: rgba(255, 199, 199, var(--bs-bg-opacity)) !important;
}

.bg-danger-light-3 {
    background-color: rgba(255, 153, 153, var(--bs-bg-opacity)) !important;
}

.bg-danger-light-2 {
    background-color: rgba(255, 107, 107, var(--bs-bg-opacity)) !important;
}

.bg-danger-light-1 {
    background-color: rgba(255, 61, 61, var(--bs-bg-opacity)) !important;
}

.bg-danger {
    background-color: rgba(255, 0, 0, var(--bs-bg-opacity)) !important;
}

.bg-danger-dark-5 {
    background-color: rgba(128, 0, 0, var(--bs-bg-opacity)) !important;
}

.bg-danger-dark-4 {
    background-color: rgba(153, 0, 0, var(--bs-bg-opacity)) !important;
}

.bg-danger-dark-3 {
    background-color: rgba(179, 0, 0, var(--bs-bg-opacity)) !important;
}

.bg-danger-dark-2 {
    background-color: rgba(204, 0, 0, var(--bs-bg-opacity)) !important;
}

.bg-danger-dark-1 {
    background-color: rgba(230, 0, 0, var(--bs-bg-opacity)) !important;
}

.bg-blue-light-5 {
    background-color: rgba(238, 246, 255, var(--bs-bg-opacity)) !important;
}

.bg-blue-light-4 {
    background-color: rgba(208, 230, 255, var(--bs-bg-opacity)) !important;
}

.bg-blue-light-3 {
    background-color: rgba(169, 209, 255, var(--bs-bg-opacity)) !important;
}

.bg-blue-light-2 {
    background-color: rgba(131, 189, 255, var(--bs-bg-opacity)) !important;
}

.bg-blue-light-1 {
    background-color: rgba(92, 168, 255, var(--bs-bg-opacity)) !important;
}

.bg-blue {
    background-color: rgba(41, 141, 255, var(--bs-bg-opacity)) !important;
}

.bg-blue-dark-5 {
    background-color: rgba(21, 71, 128, var(--bs-bg-opacity)) !important;
}

.bg-blue-dark-4 {
    background-color: rgba(25, 85, 153, var(--bs-bg-opacity)) !important;
}

.bg-blue-dark-3 {
    background-color: rgba(29, 99, 179, var(--bs-bg-opacity)) !important;
}

.bg-blue-dark-2 {
    background-color: rgba(33, 113, 204, var(--bs-bg-opacity)) !important;
}

.bg-blue-dark-1 {
    background-color: rgba(37, 127, 230, var(--bs-bg-opacity)) !important;
}

.bg-primary-light-5 {
    background-color: rgba(235, 245, 245, var(--bs-bg-opacity)) !important;
}

.bg-primary-light-4 {
    background-color: rgba(199, 226, 229, var(--bs-bg-opacity)) !important;
}

.bg-primary-light-3 {
    background-color: rgba(153, 203, 207, var(--bs-bg-opacity)) !important;
}

.bg-primary-light-2 {
    background-color: rgba(107, 180, 186, var(--bs-bg-opacity)) !important;
}

.bg-primary-light-1 {
    background-color: rgba(61, 156, 165, var(--bs-bg-opacity)) !important;
}

.bg-primary {
    background-color: rgba(0, 125, 136, var(--bs-bg-opacity)) !important;
}

.bg-primary-dark-5 {
    background-color: rgba(0, 63, 68, var(--bs-bg-opacity)) !important;
}

.bg-primary-dark-4 {
    background-color: rgba(0, 75, 82, var(--bs-bg-opacity)) !important;
}

.bg-primary-dark-3 {
    background-color: rgba(0, 88, 95, var(--bs-bg-opacity)) !important;
}

.bg-primary-dark-2 {
    background-color: rgba(0, 100, 109, var(--bs-bg-opacity)) !important;
}

.bg-primary-dark-1 {
    background-color: rgba(0, 113, 122, var(--bs-bg-opacity)) !important;
}

.bg-cyan-light-5 {
    background-color: rgba(237, 252, 254, var(--bs-bg-opacity)) !important;
}

.bg-cyan-light-4 {
    background-color: rgba(204, 248, 251, var(--bs-bg-opacity)) !important;
}

.bg-cyan-light-3 {
    background-color: rgba(163, 241, 249, var(--bs-bg-opacity)) !important;
}

.bg-cyan-light-2 {
    background-color: rgba(121, 235, 246, var(--bs-bg-opacity)) !important;
}

.bg-cyan-light-1 {
    background-color: rgba(79, 229, 243, var(--bs-bg-opacity)) !important;
}

.bg-cyan {
    background-color: rgba(24, 221, 239, var(--bs-bg-opacity)) !important;
}

.bg-cyan-dark-5 {
    background-color: rgba(12, 111, 120, var(--bs-bg-opacity)) !important;
}

.bg-cyan-dark-4 {
    background-color: rgba(14, 133, 143, var(--bs-bg-opacity)) !important;
}

.bg-cyan-dark-3 {
    background-color: rgba(17, 155, 167, var(--bs-bg-opacity)) !important;
}

.bg-cyan-dark-2 {
    background-color: rgba(19, 177, 191, var(--bs-bg-opacity)) !important;
}

.bg-cyan-dark-1 {
    background-color: rgba(22, 199, 215, var(--bs-bg-opacity)) !important;
}

.bg-info-light-5 {
    background-color: rgba(237, 252, 254, var(--bs-bg-opacity)) !important;
}

.bg-info-light-4 {
    background-color: rgba(204, 248, 251, var(--bs-bg-opacity)) !important;
}

.bg-info-light-3 {
    background-color: rgba(163, 241, 249, var(--bs-bg-opacity)) !important;
}

.bg-info-light-2 {
    background-color: rgba(121, 235, 246, var(--bs-bg-opacity)) !important;
}

.bg-info-light-1 {
    background-color: rgba(79, 229, 243, var(--bs-bg-opacity)) !important;
}

.bg-info {
    background-color: rgba(24, 221, 239, var(--bs-bg-opacity)) !important;
}

.bg-info-dark-5 {
    background-color: rgba(12, 111, 120, var(--bs-bg-opacity)) !important;
}

.bg-info-dark-4 {
    background-color: rgba(14, 133, 143, var(--bs-bg-opacity)) !important;
}

.bg-info-dark-3 {
    background-color: rgba(17, 155, 167, var(--bs-bg-opacity)) !important;
}

.bg-info-dark-2 {
    background-color: rgba(19, 177, 191, var(--bs-bg-opacity)) !important;
}

.bg-info-dark-1 {
    background-color: rgba(22, 199, 215, var(--bs-bg-opacity)) !important;
}

.bg-grey-light-5 {
    background-color: rgba(247, 247, 247, var(--bs-bg-opacity)) !important;
}

.bg-grey-light-4 {
    background-color: rgba(234, 234, 234, var(--bs-bg-opacity)) !important;
}

.bg-grey-light-3 {
    background-color: rgba(216, 216, 216, var(--bs-bg-opacity)) !important;
}

.bg-grey-light-2 {
    background-color: rgba(199, 199, 199, var(--bs-bg-opacity)) !important;
}

.bg-grey-light-1 {
    background-color: rgba(181, 181, 181, var(--bs-bg-opacity)) !important;
}

.bg-grey {
    background-color: rgba(158, 158, 158, var(--bs-bg-opacity)) !important;
}

.bg-grey-dark-1 {
    background-color: rgba(142, 142, 142, var(--bs-bg-opacity)) !important;
}

.bg-grey-dark-2 {
    background-color: rgba(126, 126, 126, var(--bs-bg-opacity)) !important;
}

.bg-grey-dark-3 {
    background-color: rgba(111, 111, 111, var(--bs-bg-opacity)) !important;
}

.bg-grey-dark-4 {
    background-color: rgba(95, 95, 95, var(--bs-bg-opacity)) !important;
}

.bg-grey-dark-5 {
    background-color: rgba(79, 79, 79, var(--bs-bg-opacity)) !important;
}

.bg-secondary-light-5 {
    background-color: rgba(243, 243, 243, var(--bs-bg-opacity)) !important;
}

.bg-secondary-light-4 {
    background-color: rgba(223, 223, 223, var(--bs-bg-opacity)) !important;
}

.bg-secondary-light-3 {
    background-color: rgba(197, 197, 197, var(--bs-bg-opacity)) !important;
}

.bg-secondary-light-2 {
    background-color: rgba(171, 171, 171, var(--bs-bg-opacity)) !important;
}

.bg-secondary-light-1 {
    background-color: rgba(146, 146, 146, var(--bs-bg-opacity)) !important;
}

.bg-secondary {
    background-color: rgba(111, 111, 111, var(--bs-bg-opacity)) !important;
}

.bg-secondary-dark-1 {
    background-color: rgba(100, 100, 100, var(--bs-bg-opacity)) !important;
}

.bg-secondary-dark-2 {
    background-color: rgba(89, 89, 89, var(--bs-bg-opacity)) !important;
}

.bg-secondary-dark-3 {
    background-color: rgba(78, 78, 78, var(--bs-bg-opacity)) !important;
}

.bg-secondary-dark-4 {
    background-color: rgba(67, 67, 67, var(--bs-bg-opacity)) !important;
}

.bg-secondary-dark-5 {
    background-color: rgba(56, 56, 56, var(--bs-bg-opacity)) !important;
}

.bg-pink-light-5 {
    background-color: rgba(253, 238, 246, var(--bs-bg-opacity)) !important;
}

.bg-pink-light-4 {
    background-color: rgba(250, 208, 231, var(--bs-bg-opacity)) !important;
}

.bg-pink-light-3 {
    background-color: rgba(246, 169, 211, var(--bs-bg-opacity)) !important;
}

.bg-pink-light-2 {
    background-color: rgba(242, 131, 191, var(--bs-bg-opacity)) !important;
}

.bg-pink-light-1 {
    background-color: rgba(238, 92, 171, var(--bs-bg-opacity)) !important;
}

.bg-pink {
    background-color: rgba(233, 41, 144, var(--bs-bg-opacity)) !important;
}

.bg-pink-dark-5 {
    background-color: rgba(117, 21, 72, var(--bs-bg-opacity)) !important;
}

.bg-pink-dark-4 {
    background-color: rgba(140, 25, 86, var(--bs-bg-opacity)) !important;
}

.bg-pink-dark-3 {
    background-color: rgba(163, 29, 101, var(--bs-bg-opacity)) !important;
}

.bg-pink-dark-2 {
    background-color: rgba(186, 33, 115, var(--bs-bg-opacity)) !important;
}

.bg-pink-dark-1 {
    background-color: rgba(210, 37, 130, var(--bs-bg-opacity)) !important;
}

.bg-purple-light-5 {
    background-color: rgba(250, 238, 254, var(--bs-bg-opacity)) !important;
}

.bg-purple-light-4 {
    background-color: rgba(241, 209, 252, var(--bs-bg-opacity)) !important;
}

.bg-purple-light-3 {
    background-color: rgba(230, 172, 250, var(--bs-bg-opacity)) !important;
}

.bg-purple-light-2 {
    background-color: rgba(218, 134, 248, var(--bs-bg-opacity)) !important;
}

.bg-purple-light-1 {
    background-color: rgba(207, 97, 246, var(--bs-bg-opacity)) !important;
}

.bg-purple {
    background-color: rgba(192, 47, 243, var(--bs-bg-opacity)) !important;
}

.bg-purple-dark-5 {
    background-color: rgba(96, 24, 122, var(--bs-bg-opacity)) !important;
}

.bg-purple-dark-4 {
    background-color: rgba(115, 28, 146, var(--bs-bg-opacity)) !important;
}

.bg-purple-dark-3 {
    background-color: rgba(134, 33, 170, var(--bs-bg-opacity)) !important;
}

.bg-purple-dark-2 {
    background-color: rgba(154, 38, 194, var(--bs-bg-opacity)) !important;
}

.bg-purple-dark-1 {
    background-color: rgba(173, 42, 219, var(--bs-bg-opacity)) !important;
}

.bg-violet-light-5 {
    background-color: rgba(244, 238, 254, var(--bs-bg-opacity)) !important;
}

.bg-violet-light-4 {
    background-color: rgba(224, 208, 253, var(--bs-bg-opacity)) !important;
}

.bg-violet-light-3 {
    background-color: rgba(199, 169, 252, var(--bs-bg-opacity)) !important;
}

.bg-violet-light-2 {
    background-color: rgba(174, 131, 251, var(--bs-bg-opacity)) !important;
}

.bg-violet-light-1 {
    background-color: rgba(149, 92, 250, var(--bs-bg-opacity)) !important;
}

.bg-violet {
    background-color: rgba(116, 41, 248, var(--bs-bg-opacity)) !important;
}

.bg-violet-dark-5 {
    background-color: rgba(58, 21, 124, var(--bs-bg-opacity)) !important;
}

.bg-violet-dark-4 {
    background-color: rgba(70, 25, 149, var(--bs-bg-opacity)) !important;
}

.bg-violet-dark-3 {
    background-color: rgba(81, 29, 174, var(--bs-bg-opacity)) !important;
}

.bg-violet-dark-2 {
    background-color: rgba(93, 33, 198, var(--bs-bg-opacity)) !important;
}

.bg-violet-dark-1 {
    background-color: rgba(104, 37, 223, var(--bs-bg-opacity)) !important;
}

.bg-indigo-light-5 {
    background-color: rgba(235, 243, 254, var(--bs-bg-opacity)) !important;
}

.bg-indigo-light-4 {
    background-color: rgba(199, 222, 253, var(--bs-bg-opacity)) !important;
}

.bg-indigo-light-3 {
    background-color: rgba(153, 195, 252, var(--bs-bg-opacity)) !important;
}

.bg-indigo-light-2 {
    background-color: rgba(107, 168, 250, var(--bs-bg-opacity)) !important;
}

.bg-indigo-light-1 {
    background-color: rgba(61, 141, 249, var(--bs-bg-opacity)) !important;
}

.bg-indigo {
    background-color: rgba(0, 105, 247, var(--bs-bg-opacity)) !important;
}

.bg-indigo-dark-5 {
    background-color: rgba(0, 53, 124, var(--bs-bg-opacity)) !important;
}

.bg-indigo-dark-4 {
    background-color: rgba(0, 63, 148, var(--bs-bg-opacity)) !important;
}

.bg-indigo-dark-3 {
    background-color: rgba(0, 74, 173, var(--bs-bg-opacity)) !important;
}

.bg-indigo-dark-2 {
    background-color: rgba(0, 84, 198, var(--bs-bg-opacity)) !important;
}

.bg-indigo-dark-1 {
    background-color: rgba(0, 95, 222, var(--bs-bg-opacity)) !important;
}

.bg-teal-light-5 {
    background-color: rgba(235, 245, 245, var(--bs-bg-opacity)) !important;
}

.bg-teal-light-4 {
    background-color: rgba(199, 226, 229, var(--bs-bg-opacity)) !important;
}

.bg-teal-light-3 {
    background-color: rgba(153, 203, 207, var(--bs-bg-opacity)) !important;
}

.bg-teal-light-2 {
    background-color: rgba(107, 180, 186, var(--bs-bg-opacity)) !important;
}

.bg-teal-light-1 {
    background-color: rgba(61, 156, 165, var(--bs-bg-opacity)) !important;
}

.bg-teal {
    background-color: rgba(0, 125, 136, var(--bs-bg-opacity)) !important;
}

.bg-teal-dark-5 {
    background-color: rgba(0, 63, 68, var(--bs-bg-opacity)) !important;
}

.bg-teal-dark-4 {
    background-color: rgba(0, 75, 82, var(--bs-bg-opacity)) !important;
}

.bg-teal-dark-3 {
    background-color: rgba(0, 88, 95, var(--bs-bg-opacity)) !important;
}

.bg-teal-dark-2 {
    background-color: rgba(0, 100, 109, var(--bs-bg-opacity)) !important;
}

.bg-teal-dark-1 {
    background-color: rgba(0, 113, 122, var(--bs-bg-opacity)) !important;
}

.bg-neon-light-5 {
    background-color: rgba(245, 253, 238, var(--bs-bg-opacity)) !important;
}

.bg-neon-light-4 {
    background-color: rgba(227, 249, 209, var(--bs-bg-opacity)) !important;
}

.bg-neon-light-3 {
    background-color: rgba(205, 243, 172, var(--bs-bg-opacity)) !important;
}

.bg-neon-light-2 {
    background-color: rgba(182, 238, 135, var(--bs-bg-opacity)) !important;
}

.bg-neon-light-1 {
    background-color: rgba(159, 233, 98, var(--bs-bg-opacity)) !important;
}

.bg-neon {
    background-color: rgba(129, 226, 48, var(--bs-bg-opacity)) !important;
}

.bg-neon-dark-5 {
    background-color: rgba(65, 113, 24, var(--bs-bg-opacity)) !important;
}

.bg-neon-dark-4 {
    background-color: rgba(77, 136, 29, var(--bs-bg-opacity)) !important;
}

.bg-neon-dark-3 {
    background-color: rgba(90, 158, 34, var(--bs-bg-opacity)) !important;
}

.bg-neon-dark-2 {
    background-color: rgba(103, 181, 38, var(--bs-bg-opacity)) !important;
}

.bg-neon-dark-1 {
    background-color: rgba(116, 203, 43, var(--bs-bg-opacity)) !important;
}

.bg-lime-light-5 {
    background-color: rgba(250, 253, 239, var(--bs-bg-opacity)) !important;
}

.bg-lime-light-4 {
    background-color: rgba(242, 250, 211, var(--bs-bg-opacity)) !important;
}

.bg-lime-light-3 {
    background-color: rgba(231, 246, 176, var(--bs-bg-opacity)) !important;
}

.bg-lime-light-2 {
    background-color: rgba(220, 242, 140, var(--bs-bg-opacity)) !important;
}

.bg-lime-light-1 {
    background-color: rgba(209, 238, 105, var(--bs-bg-opacity)) !important;
}

.bg-lime {
    background-color: rgba(194, 233, 57, var(--bs-bg-opacity)) !important;
}

.bg-lime-dark-5 {
    background-color: rgba(97, 117, 29, var(--bs-bg-opacity)) !important;
}

.bg-lime-dark-4 {
    background-color: rgba(116, 140, 34, var(--bs-bg-opacity)) !important;
}

.bg-lime-dark-3 {
    background-color: rgba(136, 163, 40, var(--bs-bg-opacity)) !important;
}

.bg-lime-dark-2 {
    background-color: rgba(155, 186, 46, var(--bs-bg-opacity)) !important;
}

.bg-lime-dark-1 {
    background-color: rgba(175, 210, 51, var(--bs-bg-opacity)) !important;
}

.bg-sun-light-5 {
    background-color: rgba(255, 253, 235, var(--bs-bg-opacity)) !important;
}

.bg-sun-light-4 {
    background-color: rgba(255, 250, 199, var(--bs-bg-opacity)) !important;
}

.bg-sun-light-3 {
    background-color: rgba(255, 247, 153, var(--bs-bg-opacity)) !important;
}

.bg-sun-light-2 {
    background-color: rgba(255, 243, 107, var(--bs-bg-opacity)) !important;
}

.bg-sun-light-1 {
    background-color: rgba(255, 239, 61, var(--bs-bg-opacity)) !important;
}

.bg-sun {
    background-color: rgba(255, 234, 0, var(--bs-bg-opacity)) !important;
}

.bg-sun-dark-5 {
    background-color: rgba(128, 117, 0, var(--bs-bg-opacity)) !important;
}

.bg-sun-dark-4 {
    background-color: rgba(153, 140, 0, var(--bs-bg-opacity)) !important;
}

.bg-sun-dark-3 {
    background-color: rgba(179, 164, 0, var(--bs-bg-opacity)) !important;
}

.bg-sun-dark-2 {
    background-color: rgba(204, 187, 0, var(--bs-bg-opacity)) !important;
}

.bg-sun-dark-1 {
    background-color: rgba(230, 211, 0, var(--bs-bg-opacity)) !important;
}

.bg-orange-light-5 {
    background-color: rgba(255, 246, 235, var(--bs-bg-opacity)) !important;
}

.bg-orange-light-4 {
    background-color: rgba(255, 231, 199, var(--bs-bg-opacity)) !important;
}

.bg-orange-light-3 {
    background-color: rgba(255, 211, 153, var(--bs-bg-opacity)) !important;
}

.bg-orange-light-2 {
    background-color: rgba(255, 191, 107, var(--bs-bg-opacity)) !important;
}

.bg-orange-light-1 {
    background-color: rgba(255, 171, 61, var(--bs-bg-opacity)) !important;
}

.bg-orange {
    background-color: rgba(255, 145, 0, var(--bs-bg-opacity)) !important;
}

.bg-orange-dark-5 {
    background-color: rgba(128, 73, 0, var(--bs-bg-opacity)) !important;
}

.bg-orange-dark-4 {
    background-color: rgba(153, 87, 0, var(--bs-bg-opacity)) !important;
}

.bg-orange-dark-3 {
    background-color: rgba(179, 102, 0, var(--bs-bg-opacity)) !important;
}

.bg-orange-dark-2 {
    background-color: rgba(204, 116, 0, var(--bs-bg-opacity)) !important;
}

.bg-orange-dark-1 {
    background-color: rgba(230, 131, 0, var(--bs-bg-opacity)) !important;
}

.bg-pumpkin-light-5 {
    background-color: rgba(255, 242, 235, var(--bs-bg-opacity)) !important;
}

.bg-pumpkin-light-4 {
    background-color: rgba(255, 218, 199, var(--bs-bg-opacity)) !important;
}

.bg-pumpkin-light-3 {
    background-color: rgba(255, 188, 153, var(--bs-bg-opacity)) !important;
}

.bg-pumpkin-light-2 {
    background-color: rgba(255, 158, 107, var(--bs-bg-opacity)) !important;
}

.bg-pumpkin-light-1 {
    background-color: rgba(255, 127, 61, var(--bs-bg-opacity)) !important;
}

.bg-pumpkin {
    background-color: rgba(255, 87, 0, var(--bs-bg-opacity)) !important;
}

.bg-pumpkin-dark-5 {
    background-color: rgba(128, 44, 0, var(--bs-bg-opacity)) !important;
}

.bg-pumpkin-dark-4 {
    background-color: rgba(153, 52, 0, var(--bs-bg-opacity)) !important;
}

.bg-pumpkin-dark-3 {
    background-color: rgba(179, 61, 0, var(--bs-bg-opacity)) !important;
}

.bg-pumpkin-dark-2 {
    background-color: rgba(204, 70, 0, var(--bs-bg-opacity)) !important;
}

.bg-pumpkin-dark-1 {
    background-color: rgba(230, 78, 0, var(--bs-bg-opacity)) !important;
}

.bg-brown-light-5 {
    background-color: rgba(247, 241, 236, var(--bs-bg-opacity)) !important;
}

.bg-brown-light-4 {
    background-color: rgba(232, 216, 204, var(--bs-bg-opacity)) !important;
}

.bg-brown-light-3 {
    background-color: rgba(213, 184, 161, var(--bs-bg-opacity)) !important;
}

.bg-brown-light-2 {
    background-color: rgba(194, 152, 119, var(--bs-bg-opacity)) !important;
}

.bg-brown-light-1 {
    background-color: rgba(174, 120, 77, var(--bs-bg-opacity)) !important;
}

.bg-brown {
    background-color: rgba(149, 77, 21, var(--bs-bg-opacity)) !important;
}

.bg-brown-dark-5 {
    background-color: rgba(75, 39, 11, var(--bs-bg-opacity)) !important;
}

.bg-brown-dark-4 {
    background-color: rgba(89, 46, 13, var(--bs-bg-opacity)) !important;
}

.bg-brown-dark-3 {
    background-color: rgba(104, 54, 15, var(--bs-bg-opacity)) !important;
}

.bg-brown-dark-2 {
    background-color: rgba(119, 62, 17, var(--bs-bg-opacity)) !important;
}

.bg-brown-dark-1 {
    background-color: rgba(134, 69, 19, var(--bs-bg-opacity)) !important;
}

.bg-gold-light-5 {
    background-color: rgba(250, 247, 240, var(--bs-bg-opacity)) !important;
}

.bg-gold-light-4 {
    background-color: rgba(242, 232, 215, var(--bs-bg-opacity)) !important;
}

.bg-gold-light-3 {
    background-color: rgba(231, 213, 182, var(--bs-bg-opacity)) !important;
}

.bg-gold-light-2 {
    background-color: rgba(220, 195, 149, var(--bs-bg-opacity)) !important;
}

.bg-gold-light-1 {
    background-color: rgba(209, 176, 117, var(--bs-bg-opacity)) !important;
}

.bg-gold {
    background-color: rgba(195, 151, 73, var(--bs-bg-opacity)) !important;
}

.bg-gold-dark-5 {
    background-color: rgba(98, 76, 37, var(--bs-bg-opacity)) !important;
}

.bg-gold-dark-4 {
    background-color: rgba(117, 91, 44, var(--bs-bg-opacity)) !important;
}

.bg-gold-dark-3 {
    background-color: rgba(137, 106, 51, var(--bs-bg-opacity)) !important;
}

.bg-gold-dark-2 {
    background-color: rgba(156, 121, 58, var(--bs-bg-opacity)) !important;
}

.bg-gold-dark-1 {
    background-color: rgba(176, 136, 66, var(--bs-bg-opacity)) !important;
}

.bg-smoke-light-5 {
    background-color: rgba(242, 245, 246, var(--bs-bg-opacity)) !important;
}

.bg-smoke-light-4 {
    background-color: rgba(220, 226, 229, var(--bs-bg-opacity)) !important;
}

.bg-smoke-light-3 {
    background-color: rgba(191, 203, 208, var(--bs-bg-opacity)) !important;
}

.bg-smoke-light-2 {
    background-color: rgba(162, 180, 187, var(--bs-bg-opacity)) !important;
}

.bg-smoke-light-1 {
    background-color: rgba(133, 156, 166, var(--bs-bg-opacity)) !important;
}

.bg-smoke {
    background-color: rgba(94, 125, 138, var(--bs-bg-opacity)) !important;
}

.bg-smoke-dark-5 {
    background-color: rgba(47, 63, 69, var(--bs-bg-opacity)) !important;
}

.bg-smoke-dark-4 {
    background-color: rgba(56, 75, 83, var(--bs-bg-opacity)) !important;
}

.bg-smoke-dark-3 {
    background-color: rgba(66, 88, 97, var(--bs-bg-opacity)) !important;
}

.bg-smoke-dark-2 {
    background-color: rgba(75, 100, 110, var(--bs-bg-opacity)) !important;
}

.bg-smoke-dark-1 {
    background-color: rgba(85, 113, 124, var(--bs-bg-opacity)) !important;
}

.bg-white {
    background-color: rgba(255, 255, 255, var(--bs-bg-opacity)) !important;
}

.bg-light {
    background-color: rgba(247, 247, 247, var(--bs-bg-opacity)) !important;
}

.bg-dark-10 {
    background-color: rgba(47, 52, 58, var(--bs-bg-opacity)) !important;
}

.bg-dark-20 {
    background-color: rgba(42, 47, 52, var(--bs-bg-opacity)) !important;
}

.bg-dark,
.bg-dark-40 {
    background-color: rgba(38, 42, 46, var(--bs-bg-opacity)) !important;
}

.bg-dark-60 {
    background-color: rgba(33, 36, 41, var(--bs-bg-opacity)) !important;
}

.bg-dark-80 {
    background-color: rgba(28, 31, 35, var(--bs-bg-opacity)) !important;
}

.bg-dark-100 {
    background-color: rgba(24, 26, 29, var(--bs-bg-opacity)) !important;
}

.bg-twitter {
    background-color: #00acee !important;
}

.bg-facebook {
    background-color: #1778F2 !important;
}

.bg-gradient-primary {
    background: #09769F;
    background: linear-gradient(45deg, #09769F, #955cfa) !important;
}

.bg-gradient-info {
    background: #18DDEF;
    background: linear-gradient(45deg, #18DDEF, #09769F) !important;
}

.bg-gradient-success {
    background: #00D67F;
    background: linear-gradient(45deg, #00D67F, #81E230) !important;
}

.bg-gradient-warning {
    background: #FFC400;
    background: linear-gradient(45deg, #FFC400, #FFEA00) !important;
}

.bg-gradient-danger {
    background: #ff3d3d;
    background: linear-gradient(45deg, #ff3d3d, #E92990) !important;
}

.bg-gradient-secondary {
    background: #6f6f6f;
    background: linear-gradient(45deg, #6f6f6f, #d8d8d8) !important;
}

.bg-gradient-light {
    background: #b5b5b5;
    background: linear-gradient(45deg, #b5b5b5, #f7f7f7) !important;
}

.bg-gradient-dark {
    background: #262a2e;
    background: linear-gradient(45deg, #262a2e, #6f6f6f) !important;
}

.bg-gradient-pony {
    background: #f6a9d3;
    background: linear-gradient(45deg, #f6a9d3, #4fe5f3) !important;
}

.bg-gradient-space {
    background: #cf61f6;
    background: linear-gradient(45deg, #cf61f6, #ffdd6b) !important;
}

.bg-gradient-streaks {
    background: #7429F8;
    background: linear-gradient(45deg, #7429F8, #09769F) !important;
}

.bg-gradient-bunting {
    background: #5ca8ff;
    background: linear-gradient(45deg, #5ca8ff, #d5b8a1) !important;
}

.bg-gradient-paradise {
    background: #00357c;
    background: linear-gradient(45deg, #00357c, #7429F8) !important;
}

.bg-gradient-heaven {
    background: #81E230;
    background: linear-gradient(45deg, #81E230, #00B0FF) !important;
}

.bg-gradient-honey {
    background: #C2E939;
    background: linear-gradient(45deg, #C2E939, #FF9100) !important;
}

.bg-gradient-warbler {
    background: #FFEA00;
    background: linear-gradient(45deg, #FFEA00, #9e9e9e) !important;
}

.bg-gradient-dusk {
    background: #ff7f3d;
    background: linear-gradient(45deg, #ff7f3d, #955cfa) !important;
}

.bg-gradient-citrine {
    background: #ba2173;
    background: linear-gradient(45deg, #ba2173, #68360f) !important;
}

.bg-gradient-royston {
    background: #6be7b5;
    background: linear-gradient(45deg, #6be7b5, #c29877) !important;
}

.bg-gradient-ashes {
    background: #2f3f45;
    background: linear-gradient(45deg, #2f3f45, #4b646e) !important;
}

.bg-gradient-metal {
    background: #C39749;
    background: linear-gradient(45deg, #C39749, #9e9e9e) !important;
}

.bg-gradient-sunset {
    background: #ff7f3d;
    background: linear-gradient(45deg, #ff7f3d, #ffab3d) !important;
}

.overlay-wrap {
    position: relative;
}

.overlay-wrap .bg-overlay {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
}