
/*Text Colors*/
.text-sky {
    color: rgba(0, 176, 255, var(--bs-text-opacity)) !important;
}

.text-cyan {
    color: rgba(24, 221, 239, var(--bs-text-opacity)) !important;
}

.text-info {
    color: rgba(24, 221, 239, var(--bs-text-opacity)) !important;
}

.text-green {
    color: rgba(0, 214, 127, var(--bs-text-opacity)) !important;
}

.text-success {
    color: rgba(0, 214, 127, var(--bs-text-opacity)) !important;
}

.text-yellow {
    color: rgba(255, 196, 0, var(--bs-text-opacity)) !important;
}

.text-warning {
    color: rgba(255, 196, 0, var(--bs-text-opacity)) !important;
}

.text-red {
    color: rgba(255, 0, 0, var(--bs-text-opacity)) !important;
}

.text-danger {
    color: rgba(255, 0, 0, var(--bs-text-opacity)) !important;
}

.text-blue {
    color: rgba(41, 141, 255, var(--bs-text-opacity)) !important;
}

.text-primary {
    color: rgba(0, 125, 136, var(--bs-text-opacity)) !important;
}

.text-pink {
    color: rgba(233, 41, 144, var(--bs-text-opacity)) !important;
}

.text-purple {
    color: rgba(192, 47, 243, var(--bs-text-opacity)) !important;
}

.text-violet {
    color: rgba(116, 41, 248, var(--bs-text-opacity)) !important;
}

.text-indigo {
    color: rgba(0, 105, 247, var(--bs-text-opacity)) !important;
}

.text-teal {
    color: rgba(0, 125, 136, var(--bs-text-opacity)) !important;
}

.text-neon {
    color: rgba(129, 226, 48, var(--bs-text-opacity)) !important;
}

.text-lime {
    color: rgba(194, 233, 57, var(--bs-text-opacity)) !important;
}

.text-sun {
    color: rgba(255, 234, 0, var(--bs-text-opacity)) !important;
}

.text-orange {
    color: rgba(255, 145, 0, var(--bs-text-opacity)) !important;
}

.text-pumpkin {
    color: rgba(255, 87, 0, var(--bs-text-opacity)) !important;
}

.text-brown {
    color: rgba(149, 77, 21, var(--bs-text-opacity)) !important;
}

.text-gold {
    color: rgba(195, 151, 73, var(--bs-text-opacity)) !important;
}

.text-smoke {
    color: rgba(94, 125, 138, var(--bs-text-opacity)) !important;
}

.text-muted {
    color: rgba(111, 111, 111, var(--bs-text-opacity)) !important;
}

.text-secondary {
    color: rgba(111, 111, 111, var(--bs-text-opacity)) !important;
}

.text-grey {
    color: rgba(158, 158, 158, var(--bs-text-opacity)) !important;
}

.text-dark-10 {
    color: rgba(47, 52, 58, var(--bs-text-opacity)) !important;
}

.text-dark-20 {
    color: rgba(42, 47, 52, var(--bs-text-opacity)) !important;
}

.text-dark,
.text-dark-40 {
    color: rgba(38, 42, 46, var(--bs-text-opacity)) !important;
}

.text-dark-60 {
    color: rgba(33, 36, 41, var(--bs-text-opacity)) !important;
}

.text-dark-80 {
    color: rgba(28, 31, 35, var(--bs-text-opacity)) !important;
}

.text-dark-100 {
    color: rgba(24, 26, 29, var(--bs-text-opacity)) !important;
}

.text-light {
    color: rgba(126, 126, 126, var(--bs-text-opacity)) !important;
}

.text-white {
    color: rgba(255, 255, 255, var(--bs-text-opacity)) !important;
}

.text-body {
    color: rgba(111, 111, 111, var(--bs-text-opacity)) !important;
}

.text-disabled {
    color: rgba(181, 181, 181, var(--bs-text-opacity)) !important;
}

.text-high-em {
    color: #262a2e;
    font-weight: 500;
}

.text-medium-em {
    color: #262a2e;
    font-size: .875rem;
}

.text-low-em {
    font-size: .75rem;
}

/*Colors*/
.color-palette-wrap .color-wrap {
    padding: 1rem;
}
