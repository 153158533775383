
/*Scrollbar*/
.slimScrollBar {
    right: 0 !important;
}

.nicescroll-rails.nicescroll-rails-vr {
    z-index: 999 !important;
}

.simplebar-placeholder {
    width: auto !important;
}

.simplebar-content-wrapper::-webkit-scrollbar,
.simplebar-scroll-content::-webkit-scrollbar,
.simplebar-hide-scrollbar::-webkit-scrollbar {
    width: 0;
    height: 0;
    display: none;
}

.simplebar-scrollbar:before {
    background: #d6d9da;
}

.simplebar-scrollbar.simplebar-visible:before {
    opacity: .8;
}

.simplebar-content:after,
.simplebar-content:before {
    clear: both;
}

.nicescroll-bar {
    height: 100%;
    width: 100%;
}

.ps__thumb-y,
.ps__thumb-x {
    border-radius: 0;
    background: #d6d9da;
}

.ps__thumb-x,
.ps__rail-x {
    height: 6px;
}

.ps__thumb-y,
.ps__rail-y {
    width: 6px;
}

.ps__rail-x,
.ps__rail-y {
    background: transparent;
}

.ps__rail-x:hover>.ps__thumb-x,
.ps__rail-x:focus>.ps__thumb-x,
.ps__rail-x.ps--clicking .ps__thumb-x {
    border-radius: 0;
    height: 6px;
    background: #d6d9da;
}

.ps__rail-y:hover>.ps__thumb-y,
.ps__rail-y:focus>.ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
    border-radius: 0;
    width: 6px;
    background: #d6d9da;
}

.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
    background-color: transparent;
    opacity: 1;
}

.ps:hover>.ps__rail-x,
.ps:hover>.ps__rail-y,
.ps--focus>.ps__rail-x,
.ps--focus>.ps__rail-y,
.ps--scrolling-x>.ps__rail-x,
.ps--scrolling-y>.ps__rail-y {
    opacity: 1;
}