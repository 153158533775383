.accordion .accordion-button {
    color: #262a2e;
    font-weight: 500;
    padding: .75rem 1.25rem
}

.accordion .accordion-button:not(.collapsed) {
    background: #09769F;
    color: #fff;
    box-shadow: none;
    border-bottom: 1px solid #d8d8d8
}

.accordion .accordion-button:not(.collapsed)::after {
    background: none;
    color: #ffffff4d
}

.accordion .accordion-button:focus {
    box-shadow: none
}

.accordion .accordion-button::after {
    width: auto;
    height: auto;
    background: none;
    content: '\f2f9';
    font: normal normal normal 14px/1 Material-Design-Iconic-Font;
    font-size: 1.25rem;
    text-rendering: auto;
    position: absolute;
    top: 12px;
    right: 20px;
    color: #262a2e4d
}

.accordion .accordion-item:first-of-type {
    border-top-left-radius: calc(0.5rem - 1px);
    border-top-right-radius: calc(0.5rem - 1px)
}

.accordion .accordion-item:first-of-type .accordion-button {
    border-top-left-radius: calc(0.5rem - 1px);
    border-top-right-radius: calc(0.5rem - 1px)
}

.accordion .accordion-item:last-of-type {
    border-bottom-left-radius: calc(0.5rem - 1px);
    border-bottom-right-radius: calc(0.5rem - 1px)
}

.accordion .accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-right-radius: calc(0.5rem - 1px);
    border-bottom-left-radius: calc(0.5rem - 1px)
}

.accordion.accordion-flush .accordion-item:first-of-type {
    border-radius: 0
}

.accordion.accordion-flush .accordion-item:first-of-type .accordion-button {
    border-radius: 0
}

.accordion.accordion-flush .accordion-item:last-of-type {
    border-radius: 0
}

.accordion.accordion-flush .accordion-item:last-of-type .accordion-button.collapsed {
    border-radius: 0
}

.accordion.accordion-card>.accordion-item {
    margin-bottom: 1.875rem;
    border-bottom: 1px;
    border: 1px solid #d8d8d8;
    border-radius: calc(0.5rem - 1px);
    overflow: hidden
}

.accordion.accordion-card.accordion-card-shadow>.accordion-item {
    box-shadow: 0 2px 2px #0000001a
}

.accordion.accordion-card.accordion-card-bold>.accordion-item .accordion-body {
    background: #09769F;
    color: #fff
}

.accordion.accordion-soft .accordion-button:not(.collapsed) {
    background: #EBFAFF;
    color: #09769F
}

.accordion.accordion-soft .accordion-button:not(.collapsed):after {
    color: #262a2e4d
}

.accordion.accordion-soft.accordion-card.accordion-card-bold>.accordion-item .accordion-body {
    background: #EBFAFF;
    color: #09769F
}

.accordion.accordion-soft.accordion-soft-content .accordion-button:not(.collapsed) {
    background: transparent
}

.accordion.accordion-soft.accordion-soft-content>.accordion-item .accordion-body {
    background: #EBFAFF;
    color: #262a2e
}

.accordion.accordion-simple .accordion-button:not(.collapsed) {
    background: transparent;
    color: #09769F
}

.accordion.accordion-simple .accordion-button:not(.collapsed)::after {
    color: #262a2e4d
}

.accordion.accordion-simple.accordion-card.accordion-card-bold .accordion-button {
    border-color: transparent
}

.accordion.accordion-simple.accordion-card.accordion-card-bold .accordion-item .accordion-body {
    background: transparent;
    color: inherit
}

.accordion.accordion-icon .accordion-button .acon-icon {
    min-width: 30px;
    display: inline-block;
    margin-right: .625rem;
    font-size: 1.5rem
}