/*Padding*/
.p-0 {
    padding: 0rem !important;
}

.p-1 {
    padding: 0.25rem !important;
}

.p-0_4 {
    padding: 0.4rem !important;
}

.p-2 {
    padding: 0.5rem !important;
}

.p-3 {
    padding: 1rem !important;
}

.p-4 {
    padding: 1.5rem !important;
}

.p-5 {
    padding: 2rem !important;
}

.p-6 {
    padding: 2.5rem !important;
}

.p-7 {
    padding: 3rem !important;
}

.p-8 {
    padding: 3.5rem !important;
}

.p-9 {
    padding: 4rem !important;
}

.p-10 {
    padding: 4.5rem !important;
}

.p-11 {
    padding: 5rem !important;
}

.p-12 {
    padding: 5.5rem !important;
}

.p-13 {
    padding: 6rem !important;
}

.p-14 {
    padding: 6.5rem !important;
}

.p-15 {
    padding: 7rem !important;
}

.p-16 {
    padding: 7.5rem !important;
}

.p-17 {
    padding: 8rem !important;
}

.p-18 {
    padding: 8.5rem !important;
}

.p-19 {
    padding: 9rem !important;
}

.p-20 {
    padding: 9.5rem !important;
}

.p-21 {
    padding: 10rem !important;
}

.p-22 {
    padding: 10.5rem !important;
}

.p-23 {
    padding: 11rem !important;
}

.p-24 {
    padding: 11.5rem !important;
}

.p-25 {
    padding: 12rem !important;
}

.p-26 {
    padding: 12.5rem !important;
}

.p-27 {
    padding: 13rem !important;
}

.p-28 {
    padding: 13.5rem !important;
}

.p-29 {
    padding: 14rem !important;
}

.p-30 {
    padding: 14.5rem !important;
}

.p-31 {
    padding: 15rem !important;
}

.p-32 {
    padding: 15.5rem !important;
}

.p-33 {
    padding: 16rem !important;
}

.p-34 {
    padding: 16.5rem !important;
}

.p-35 {
    padding: 17rem !important;
}

.p-36 {
    padding: 17.5rem !important;
}

.p-37 {
    padding: 18rem !important;
}

.p-38 {
    padding: 18.5rem !important;
}

.p-39 {
    padding: 19rem !important;
}

.p-40 {
    padding: 19.5rem !important;
}

.p-41 {
    padding: 20rem !important;
}

.p-42 {
    padding: 20.5rem !important;
}

.p-43 {
    padding: 21rem !important;
}

.p-44 {
    padding: 21.5rem !important;
}

.p-45 {
    padding: 22rem !important;
}

.p-46 {
    padding: 22.5rem !important;
}

.p-47 {
    padding: 23rem !important;
}

.p-48 {
    padding: 23.5rem !important;
}

.p-49 {
    padding: 24rem !important;
}

.p-50 {
    padding: 24.5rem !important;
}

.p-51 {
    padding: 25rem !important;
}

.p-52 {
    padding: 25.5rem !important;
}

.p-53 {
    padding: 26rem !important;
}

.p-54 {
    padding: 26.5rem !important;
}

.p-55 {
    padding: 27rem !important;
}

.p-56 {
    padding: 27.5rem !important;
}

.p-57 {
    padding: 28rem !important;
}

.p-58 {
    padding: 28.5rem !important;
}

.p-59 {
    padding: 29rem !important;
}

.p-60 {
    padding: 29.5rem !important;
}

.p-61 {
    padding: 30rem !important;
}

.p-62 {
    padding: 30.5rem !important;
}

.p-63 {
    padding: 31rem !important;
}

.p-64 {
    padding: 31.5rem !important;
}

.p-65 {
    padding: 32rem !important;
}

.p-66 {
    padding: 32.5rem !important;
}

.p-67 {
    padding: 33rem !important;
}

.p-68 {
    padding: 33.5rem !important;
}

.p-69 {
    padding: 34rem !important;
}

.p-70 {
    padding: 34.5rem !important;
}

.p-71 {
    padding: 35rem !important;
}

.p-72 {
    padding: 35.5rem !important;
}

.p-73 {
    padding: 36rem !important;
}

.p-74 {
    padding: 36.5rem !important;
}

.p-75 {
    padding: 37rem !important;
}

.p-76 {
    padding: 37.5rem !important;
}

.p-77 {
    padding: 38rem !important;
}

.p-78 {
    padding: 38.5rem !important;
}

.p-79 {
    padding: 39rem !important;
}

.p-80 {
    padding: 39.5rem !important;
}

.p-81 {
    padding: 40rem !important;
}

.p-82 {
    padding: 40.5rem !important;
}

.p-83 {
    padding: 41rem !important;
}

.p-84 {
    padding: 41.5rem !important;
}

.p-85 {
    padding: 42rem !important;
}

.p-86 {
    padding: 42.5rem !important;
}

.p-87 {
    padding: 43rem !important;
}

.p-88 {
    padding: 43.5rem !important;
}

.p-89 {
    padding: 44rem !important;
}

.p-90 {
    padding: 44.5rem !important;
}

.p-91 {
    padding: 45rem !important;
}

.p-92 {
    padding: 45.5rem !important;
}

.p-93 {
    padding: 46rem !important;
}

.p-94 {
    padding: 46.5rem !important;
}

.p-95 {
    padding: 47rem !important;
}

.p-96 {
    padding: 47.5rem !important;
}

.p-97 {
    padding: 48rem !important;
}

.p-98 {
    padding: 48.5rem !important;
}

.p-99 {
    padding: 49rem !important;
}

.p-100 {
    padding: 49.5rem !important;
}

.p-101 {
    padding: 50rem !important;
}

.p-102 {
    padding: 50.5rem !important;
}

.p-103 {
    padding: 51rem !important;
}

.p-104 {
    padding: 51.5rem !important;
}

.p-105 {
    padding: 52rem !important;
}

.p-106 {
    padding: 52.5rem !important;
}

.p-107 {
    padding: 53rem !important;
}

.p-108 {
    padding: 53.5rem !important;
}

.p-109 {
    padding: 54rem !important;
}

.p-110 {
    padding: 54.5rem !important;
}

.p-111 {
    padding: 55rem !important;
}

.p-112 {
    padding: 55.5rem !important;
}

.p-113 {
    padding: 56rem !important;
}

.p-114 {
    padding: 56.5rem !important;
}

.p-115 {
    padding: 57rem !important;
}

.p-116 {
    padding: 57.5rem !important;
}

.p-117 {
    padding: 58rem !important;
}

.p-118 {
    padding: 58.5rem !important;
}

.p-119 {
    padding: 59rem !important;
}

.p-120 {
    padding: 59.5rem !important;
}

.p-121 {
    padding: 60rem !important;
}

.p-122 {
    padding: 60.5rem !important;
}

.p-123 {
    padding: 61rem !important;
}

.p-124 {
    padding: 61.5rem !important;
}

.p-125 {
    padding: 62rem !important;
}

.p-126 {
    padding: 62.5rem !important;
}

.p-127 {
    padding: 63rem !important;
}

.p-128 {
    padding: 63.5rem !important;
}

.p-129 {
    padding: 64rem !important;
}

.p-130 {
    padding: 64.5rem !important;
}

.p-131 {
    padding: 65rem !important;
}

.p-132 {
    padding: 65.5rem !important;
}

.p-133 {
    padding: 66rem !important;
}

.p-134 {
    padding: 66.5rem !important;
}

.p-135 {
    padding: 67rem !important;
}

.p-136 {
    padding: 67.5rem !important;
}

.p-137 {
    padding: 68rem !important;
}

.p-138 {
    padding: 68.5rem !important;
}

.p-139 {
    padding: 69rem !important;
}

.p-140 {
    padding: 69.5rem !important;
}

.p-141 {
    padding: 70rem !important;
}

.p-142 {
    padding: 70.5rem !important;
}

.p-143 {
    padding: 71rem !important;
}

.p-144 {
    padding: 71.5rem !important;
}

.p-145 {
    padding: 72rem !important;
}

.p-146 {
    padding: 72.5rem !important;
}

.p-147 {
    padding: 73rem !important;
}

.p-148 {
    padding: 73.5rem !important;
}

.p-149 {
    padding: 74rem !important;
}

.p-150 {
    padding: 74.5rem !important;
}

.p-151 {
    padding: 75rem !important;
}

.p-152 {
    padding: 75.5rem !important;
}

.p-153 {
    padding: 76rem !important;
}

.p-154 {
    padding: 76.5rem !important;
}

.p-155 {
    padding: 77rem !important;
}

.p-156 {
    padding: 77.5rem !important;
}

.p-157 {
    padding: 78rem !important;
}

.p-158 {
    padding: 78.5rem !important;
}

.p-159 {
    padding: 79rem !important;
}

.p-160 {
    padding: 79.5rem !important;
}

.pt-0 {
    padding-top: 0rem !important;
}

.pt-1 {
    padding-top: 0.25rem !important;
}

.pt-2 {
    padding-top: 0.5rem !important;
}

.pt-3 {
    padding-top: 1rem !important;
}

.pt-4 {
    padding-top: 1.5rem !important;
}

.pt-5 {
    padding-top: 2rem !important;
}

.pt-6 {
    padding-top: 2.5rem !important;
}

.pt-7 {
    padding-top: 3rem !important;
}

.pt-8 {
    padding-top: 3.5rem !important;
}

.pt-9 {
    padding-top: 4rem !important;
}

.pt-10 {
    padding-top: 4.5rem !important;
}

.pt-11 {
    padding-top: 5rem !important;
}

.pt-12 {
    padding-top: 5.5rem !important;
}

.pt-13 {
    padding-top: 6rem !important;
}

.pt-14 {
    padding-top: 6.5rem !important;
}

.pt-15 {
    padding-top: 7rem !important;
}

.pt-16 {
    padding-top: 7.5rem !important;
}

.pt-17 {
    padding-top: 8rem !important;
}

.pt-18 {
    padding-top: 8.5rem !important;
}

.pt-19 {
    padding-top: 9rem !important;
}

.pt-20 {
    padding-top: 9.5rem !important;
}

.pt-21 {
    padding-top: 10rem !important;
}

.pt-22 {
    padding-top: 10.5rem !important;
}

.pt-23 {
    padding-top: 11rem !important;
}

.pt-24 {
    padding-top: 11.5rem !important;
}

.pt-25 {
    padding-top: 12rem !important;
}

.pt-26 {
    padding-top: 12.5rem !important;
}

.pt-27 {
    padding-top: 13rem !important;
}

.pt-28 {
    padding-top: 13.5rem !important;
}

.pt-29 {
    padding-top: 14rem !important;
}

.pt-30 {
    padding-top: 14.5rem !important;
}

.pt-31 {
    padding-top: 15rem !important;
}

.pt-32 {
    padding-top: 15.5rem !important;
}

.pt-33 {
    padding-top: 16rem !important;
}

.pt-34 {
    padding-top: 16.5rem !important;
}

.pt-35 {
    padding-top: 17rem !important;
}

.pt-36 {
    padding-top: 17.5rem !important;
}

.pt-37 {
    padding-top: 18rem !important;
}

.pt-38 {
    padding-top: 18.5rem !important;
}

.pt-39 {
    padding-top: 19rem !important;
}

.pt-40 {
    padding-top: 19.5rem !important;
}

.pt-41 {
    padding-top: 20rem !important;
}

.pt-42 {
    padding-top: 20.5rem !important;
}

.pt-43 {
    padding-top: 21rem !important;
}

.pt-44 {
    padding-top: 21.5rem !important;
}

.pt-45 {
    padding-top: 22rem !important;
}

.pt-46 {
    padding-top: 22.5rem !important;
}

.pt-47 {
    padding-top: 23rem !important;
}

.pt-48 {
    padding-top: 23.5rem !important;
}

.pt-49 {
    padding-top: 24rem !important;
}

.pt-50 {
    padding-top: 24.5rem !important;
}

.pt-51 {
    padding-top: 25rem !important;
}

.pt-52 {
    padding-top: 25.5rem !important;
}

.pt-53 {
    padding-top: 26rem !important;
}

.pt-54 {
    padding-top: 26.5rem !important;
}

.pt-55 {
    padding-top: 27rem !important;
}

.pt-56 {
    padding-top: 27.5rem !important;
}

.pt-57 {
    padding-top: 28rem !important;
}

.pt-58 {
    padding-top: 28.5rem !important;
}

.pt-59 {
    padding-top: 29rem !important;
}

.pt-60 {
    padding-top: 29.5rem !important;
}

.pt-61 {
    padding-top: 30rem !important;
}

.pt-62 {
    padding-top: 30.5rem !important;
}

.pt-63 {
    padding-top: 31rem !important;
}

.pt-64 {
    padding-top: 31.5rem !important;
}

.pt-65 {
    padding-top: 32rem !important;
}

.pt-66 {
    padding-top: 32.5rem !important;
}

.pt-67 {
    padding-top: 33rem !important;
}

.pt-68 {
    padding-top: 33.5rem !important;
}

.pt-69 {
    padding-top: 34rem !important;
}

.pt-70 {
    padding-top: 34.5rem !important;
}

.pt-71 {
    padding-top: 35rem !important;
}

.pt-72 {
    padding-top: 35.5rem !important;
}

.pt-73 {
    padding-top: 36rem !important;
}

.pt-74 {
    padding-top: 36.5rem !important;
}

.pt-75 {
    padding-top: 37rem !important;
}

.pt-76 {
    padding-top: 37.5rem !important;
}

.pt-77 {
    padding-top: 38rem !important;
}

.pt-78 {
    padding-top: 38.5rem !important;
}

.pt-79 {
    padding-top: 39rem !important;
}

.pt-80 {
    padding-top: 39.5rem !important;
}

.pt-81 {
    padding-top: 40rem !important;
}

.pt-82 {
    padding-top: 40.5rem !important;
}

.pt-83 {
    padding-top: 41rem !important;
}

.pt-84 {
    padding-top: 41.5rem !important;
}

.pt-85 {
    padding-top: 42rem !important;
}

.pt-86 {
    padding-top: 42.5rem !important;
}

.pt-87 {
    padding-top: 43rem !important;
}

.pt-88 {
    padding-top: 43.5rem !important;
}

.pt-89 {
    padding-top: 44rem !important;
}

.pt-90 {
    padding-top: 44.5rem !important;
}

.pt-91 {
    padding-top: 45rem !important;
}

.pt-92 {
    padding-top: 45.5rem !important;
}

.pt-93 {
    padding-top: 46rem !important;
}

.pt-94 {
    padding-top: 46.5rem !important;
}

.pt-95 {
    padding-top: 47rem !important;
}

.pt-96 {
    padding-top: 47.5rem !important;
}

.pt-97 {
    padding-top: 48rem !important;
}

.pt-98 {
    padding-top: 48.5rem !important;
}

.pt-99 {
    padding-top: 49rem !important;
}

.pt-100 {
    padding-top: 49.5rem !important;
}

.pt-101 {
    padding-top: 50rem !important;
}

.pt-102 {
    padding-top: 50.5rem !important;
}

.pt-103 {
    padding-top: 51rem !important;
}

.pt-104 {
    padding-top: 51.5rem !important;
}

.pt-105 {
    padding-top: 52rem !important;
}

.pt-106 {
    padding-top: 52.5rem !important;
}

.pt-107 {
    padding-top: 53rem !important;
}

.pt-108 {
    padding-top: 53.5rem !important;
}

.pt-109 {
    padding-top: 54rem !important;
}

.pt-110 {
    padding-top: 54.5rem !important;
}

.pt-111 {
    padding-top: 55rem !important;
}

.pt-112 {
    padding-top: 55.5rem !important;
}

.pt-113 {
    padding-top: 56rem !important;
}

.pt-114 {
    padding-top: 56.5rem !important;
}

.pt-115 {
    padding-top: 57rem !important;
}

.pt-116 {
    padding-top: 57.5rem !important;
}

.pt-117 {
    padding-top: 58rem !important;
}

.pt-118 {
    padding-top: 58.5rem !important;
}

.pt-119 {
    padding-top: 59rem !important;
}

.pt-120 {
    padding-top: 59.5rem !important;
}

.pt-121 {
    padding-top: 60rem !important;
}

.pt-122 {
    padding-top: 60.5rem !important;
}

.pt-123 {
    padding-top: 61rem !important;
}

.pt-124 {
    padding-top: 61.5rem !important;
}

.pt-125 {
    padding-top: 62rem !important;
}

.pt-126 {
    padding-top: 62.5rem !important;
}

.pt-127 {
    padding-top: 63rem !important;
}

.pt-128 {
    padding-top: 63.5rem !important;
}

.pt-129 {
    padding-top: 64rem !important;
}

.pt-130 {
    padding-top: 64.5rem !important;
}

.pt-131 {
    padding-top: 65rem !important;
}

.pt-132 {
    padding-top: 65.5rem !important;
}

.pt-133 {
    padding-top: 66rem !important;
}

.pt-134 {
    padding-top: 66.5rem !important;
}

.pt-135 {
    padding-top: 67rem !important;
}

.pt-136 {
    padding-top: 67.5rem !important;
}

.pt-137 {
    padding-top: 68rem !important;
}

.pt-138 {
    padding-top: 68.5rem !important;
}

.pt-139 {
    padding-top: 69rem !important;
}

.pt-140 {
    padding-top: 69.5rem !important;
}

.pt-141 {
    padding-top: 70rem !important;
}

.pt-142 {
    padding-top: 70.5rem !important;
}

.pt-143 {
    padding-top: 71rem !important;
}

.pt-144 {
    padding-top: 71.5rem !important;
}

.pt-145 {
    padding-top: 72rem !important;
}

.pt-146 {
    padding-top: 72.5rem !important;
}

.pt-147 {
    padding-top: 73rem !important;
}

.pt-148 {
    padding-top: 73.5rem !important;
}

.pt-149 {
    padding-top: 74rem !important;
}

.pt-150 {
    padding-top: 74.5rem !important;
}

.pt-151 {
    padding-top: 75rem !important;
}

.pt-152 {
    padding-top: 75.5rem !important;
}

.pt-153 {
    padding-top: 76rem !important;
}

.pt-154 {
    padding-top: 76.5rem !important;
}

.pt-155 {
    padding-top: 77rem !important;
}

.pt-156 {
    padding-top: 77.5rem !important;
}

.pt-157 {
    padding-top: 78rem !important;
}

.pt-158 {
    padding-top: 78.5rem !important;
}

.pt-159 {
    padding-top: 79rem !important;
}

.pt-160 {
    padding-top: 79.5rem !important;
}

.pb-0 {
    padding-bottom: 0rem !important;
}

.pb-1 {
    padding-bottom: 0.25rem !important;
}

.pb-2 {
    padding-bottom: 0.5rem !important;
}

.pb-3 {
    padding-bottom: 1rem !important;
}

.pb-4 {
    padding-bottom: 1.5rem !important;
}

.pb-5 {
    padding-bottom: 2rem !important;
}

.pb-6 {
    padding-bottom: 2.5rem !important;
}

.pb-7 {
    padding-bottom: 3rem !important;
}

.pb-8 {
    padding-bottom: 3.5rem !important;
}

.pb-9 {
    padding-bottom: 4rem !important;
}

.pb-10 {
    padding-bottom: 4.5rem !important;
}

.pb-11 {
    padding-bottom: 5rem !important;
}

.pb-12 {
    padding-bottom: 5.5rem !important;
}

.pb-13 {
    padding-bottom: 6rem !important;
}

.pb-14 {
    padding-bottom: 6.5rem !important;
}

.pb-15 {
    padding-bottom: 7rem !important;
}

.pb-16 {
    padding-bottom: 7.5rem !important;
}

.pb-17 {
    padding-bottom: 8rem !important;
}

.pb-18 {
    padding-bottom: 8.5rem !important;
}

.pb-19 {
    padding-bottom: 9rem !important;
}

.pb-20 {
    padding-bottom: 9.5rem !important;
}

.pb-21 {
    padding-bottom: 10rem !important;
}

.pb-22 {
    padding-bottom: 10.5rem !important;
}

.pb-23 {
    padding-bottom: 11rem !important;
}

.pb-24 {
    padding-bottom: 11.5rem !important;
}

.pb-25 {
    padding-bottom: 12rem !important;
}

.pb-26 {
    padding-bottom: 12.5rem !important;
}

.pb-27 {
    padding-bottom: 13rem !important;
}

.pb-28 {
    padding-bottom: 13.5rem !important;
}

.pb-29 {
    padding-bottom: 14rem !important;
}

.pb-30 {
    padding-bottom: 14.5rem !important;
}

.pb-31 {
    padding-bottom: 15rem !important;
}

.pb-32 {
    padding-bottom: 15.5rem !important;
}

.pb-33 {
    padding-bottom: 16rem !important;
}

.pb-34 {
    padding-bottom: 16.5rem !important;
}

.pb-35 {
    padding-bottom: 17rem !important;
}

.pb-36 {
    padding-bottom: 17.5rem !important;
}

.pb-37 {
    padding-bottom: 18rem !important;
}

.pb-38 {
    padding-bottom: 18.5rem !important;
}

.pb-39 {
    padding-bottom: 19rem !important;
}

.pb-40 {
    padding-bottom: 19.5rem !important;
}

.pb-41 {
    padding-bottom: 20rem !important;
}

.pb-42 {
    padding-bottom: 20.5rem !important;
}

.pb-43 {
    padding-bottom: 21rem !important;
}

.pb-44 {
    padding-bottom: 21.5rem !important;
}

.pb-45 {
    padding-bottom: 22rem !important;
}

.pb-46 {
    padding-bottom: 22.5rem !important;
}

.pb-47 {
    padding-bottom: 23rem !important;
}

.pb-48 {
    padding-bottom: 23.5rem !important;
}

.pb-49 {
    padding-bottom: 24rem !important;
}

.pb-50 {
    padding-bottom: 24.5rem !important;
}

.pb-51 {
    padding-bottom: 25rem !important;
}

.pb-52 {
    padding-bottom: 25.5rem !important;
}

.pb-53 {
    padding-bottom: 26rem !important;
}

.pb-54 {
    padding-bottom: 26.5rem !important;
}

.pb-55 {
    padding-bottom: 27rem !important;
}

.pb-56 {
    padding-bottom: 27.5rem !important;
}

.pb-57 {
    padding-bottom: 28rem !important;
}

.pb-58 {
    padding-bottom: 28.5rem !important;
}

.pb-59 {
    padding-bottom: 29rem !important;
}

.pb-60 {
    padding-bottom: 29.5rem !important;
}

.pb-61 {
    padding-bottom: 30rem !important;
}

.pb-62 {
    padding-bottom: 30.5rem !important;
}

.pb-63 {
    padding-bottom: 31rem !important;
}

.pb-64 {
    padding-bottom: 31.5rem !important;
}

.pb-65 {
    padding-bottom: 32rem !important;
}

.pb-66 {
    padding-bottom: 32.5rem !important;
}

.pb-67 {
    padding-bottom: 33rem !important;
}

.pb-68 {
    padding-bottom: 33.5rem !important;
}

.pb-69 {
    padding-bottom: 34rem !important;
}

.pb-70 {
    padding-bottom: 34.5rem !important;
}

.pb-71 {
    padding-bottom: 35rem !important;
}

.pb-72 {
    padding-bottom: 35.5rem !important;
}

.pb-73 {
    padding-bottom: 36rem !important;
}

.pb-74 {
    padding-bottom: 36.5rem !important;
}

.pb-75 {
    padding-bottom: 37rem !important;
}

.pb-76 {
    padding-bottom: 37.5rem !important;
}

.pb-77 {
    padding-bottom: 38rem !important;
}

.pb-78 {
    padding-bottom: 38.5rem !important;
}

.pb-79 {
    padding-bottom: 39rem !important;
}

.pb-80 {
    padding-bottom: 39.5rem !important;
}

.pb-81 {
    padding-bottom: 40rem !important;
}

.pb-82 {
    padding-bottom: 40.5rem !important;
}

.pb-83 {
    padding-bottom: 41rem !important;
}

.pb-84 {
    padding-bottom: 41.5rem !important;
}

.pb-85 {
    padding-bottom: 42rem !important;
}

.pb-86 {
    padding-bottom: 42.5rem !important;
}

.pb-87 {
    padding-bottom: 43rem !important;
}

.pb-88 {
    padding-bottom: 43.5rem !important;
}

.pb-89 {
    padding-bottom: 44rem !important;
}

.pb-90 {
    padding-bottom: 44.5rem !important;
}

.pb-91 {
    padding-bottom: 45rem !important;
}

.pb-92 {
    padding-bottom: 45.5rem !important;
}

.pb-93 {
    padding-bottom: 46rem !important;
}

.pb-94 {
    padding-bottom: 46.5rem !important;
}

.pb-95 {
    padding-bottom: 47rem !important;
}

.pb-96 {
    padding-bottom: 47.5rem !important;
}

.pb-97 {
    padding-bottom: 48rem !important;
}

.pb-98 {
    padding-bottom: 48.5rem !important;
}

.pb-99 {
    padding-bottom: 49rem !important;
}

.pb-100 {
    padding-bottom: 49.5rem !important;
}

.pb-101 {
    padding-bottom: 50rem !important;
}

.pb-102 {
    padding-bottom: 50.5rem !important;
}

.pb-103 {
    padding-bottom: 51rem !important;
}

.pb-104 {
    padding-bottom: 51.5rem !important;
}

.pb-105 {
    padding-bottom: 52rem !important;
}

.pb-106 {
    padding-bottom: 52.5rem !important;
}

.pb-107 {
    padding-bottom: 53rem !important;
}

.pb-108 {
    padding-bottom: 53.5rem !important;
}

.pb-109 {
    padding-bottom: 54rem !important;
}

.pb-110 {
    padding-bottom: 54.5rem !important;
}

.pb-111 {
    padding-bottom: 55rem !important;
}

.pb-112 {
    padding-bottom: 55.5rem !important;
}

.pb-113 {
    padding-bottom: 56rem !important;
}

.pb-114 {
    padding-bottom: 56.5rem !important;
}

.pb-115 {
    padding-bottom: 57rem !important;
}

.pb-116 {
    padding-bottom: 57.5rem !important;
}

.pb-117 {
    padding-bottom: 58rem !important;
}

.pb-118 {
    padding-bottom: 58.5rem !important;
}

.pb-119 {
    padding-bottom: 59rem !important;
}

.pb-120 {
    padding-bottom: 59.5rem !important;
}

.pb-121 {
    padding-bottom: 60rem !important;
}

.pb-122 {
    padding-bottom: 60.5rem !important;
}

.pb-123 {
    padding-bottom: 61rem !important;
}

.pb-124 {
    padding-bottom: 61.5rem !important;
}

.pb-125 {
    padding-bottom: 62rem !important;
}

.pb-126 {
    padding-bottom: 62.5rem !important;
}

.pb-127 {
    padding-bottom: 63rem !important;
}

.pb-128 {
    padding-bottom: 63.5rem !important;
}

.pb-129 {
    padding-bottom: 64rem !important;
}

.pb-130 {
    padding-bottom: 64.5rem !important;
}

.pb-131 {
    padding-bottom: 65rem !important;
}

.pb-132 {
    padding-bottom: 65.5rem !important;
}

.pb-133 {
    padding-bottom: 66rem !important;
}

.pb-134 {
    padding-bottom: 66.5rem !important;
}

.pb-135 {
    padding-bottom: 67rem !important;
}

.pb-136 {
    padding-bottom: 67.5rem !important;
}

.pb-137 {
    padding-bottom: 68rem !important;
}

.pb-138 {
    padding-bottom: 68.5rem !important;
}

.pb-139 {
    padding-bottom: 69rem !important;
}

.pb-140 {
    padding-bottom: 69.5rem !important;
}

.pb-141 {
    padding-bottom: 70rem !important;
}

.pb-142 {
    padding-bottom: 70.5rem !important;
}

.pb-143 {
    padding-bottom: 71rem !important;
}

.pb-144 {
    padding-bottom: 71.5rem !important;
}

.pb-145 {
    padding-bottom: 72rem !important;
}

.pb-146 {
    padding-bottom: 72.5rem !important;
}

.pb-147 {
    padding-bottom: 73rem !important;
}

.pb-148 {
    padding-bottom: 73.5rem !important;
}

.pb-149 {
    padding-bottom: 74rem !important;
}

.pb-150 {
    padding-bottom: 74.5rem !important;
}

.pb-151 {
    padding-bottom: 75rem !important;
}

.pb-152 {
    padding-bottom: 75.5rem !important;
}

.pb-153 {
    padding-bottom: 76rem !important;
}

.pb-154 {
    padding-bottom: 76.5rem !important;
}

.pb-155 {
    padding-bottom: 77rem !important;
}

.pb-156 {
    padding-bottom: 77.5rem !important;
}

.pb-157 {
    padding-bottom: 78rem !important;
}

.pb-158 {
    padding-bottom: 78.5rem !important;
}

.pb-159 {
    padding-bottom: 79rem !important;
}

.pb-160 {
    padding-bottom: 79.5rem !important;
}

.ps-0 {
    padding-left: 0rem !important;
}

.ps-1 {
    padding-left: 0.25rem !important;
}

.ps-2 {
    padding-left: 0.5rem !important;
}

.ps-3 {
    padding-left: 1rem !important;
}

.ps-4 {
    padding-left: 1.5rem !important;
}

.ps-5 {
    padding-left: 2rem !important;
}

.ps-6 {
    padding-left: 2.5rem !important;
}

.ps-7 {
    padding-left: 3rem !important;
}

.ps-8 {
    padding-left: 3.5rem !important;
}

.ps-9 {
    padding-left: 4rem !important;
}

.ps-10 {
    padding-left: 4.5rem !important;
}

.ps-11 {
    padding-left: 5rem !important;
}

.ps-12 {
    padding-left: 5.5rem !important;
}

.ps-13 {
    padding-left: 6rem !important;
}

.ps-14 {
    padding-left: 6.5rem !important;
}

.ps-15 {
    padding-left: 7rem !important;
}

.ps-16 {
    padding-left: 7.5rem !important;
}

.ps-17 {
    padding-left: 8rem !important;
}

.ps-18 {
    padding-left: 8.5rem !important;
}

.ps-19 {
    padding-left: 9rem !important;
}

.ps-20 {
    padding-left: 9.5rem !important;
}

.ps-21 {
    padding-left: 10rem !important;
}

.ps-22 {
    padding-left: 10.5rem !important;
}

.ps-23 {
    padding-left: 11rem !important;
}

.ps-24 {
    padding-left: 11.5rem !important;
}

.ps-25 {
    padding-left: 12rem !important;
}

.ps-26 {
    padding-left: 12.5rem !important;
}

.ps-27 {
    padding-left: 13rem !important;
}

.ps-28 {
    padding-left: 13.5rem !important;
}

.ps-29 {
    padding-left: 14rem !important;
}

.ps-30 {
    padding-left: 14.5rem !important;
}

.ps-31 {
    padding-left: 15rem !important;
}

.ps-32 {
    padding-left: 15.5rem !important;
}

.ps-33 {
    padding-left: 16rem !important;
}

.ps-34 {
    padding-left: 16.5rem !important;
}

.ps-35 {
    padding-left: 17rem !important;
}

.ps-36 {
    padding-left: 17.5rem !important;
}

.ps-37 {
    padding-left: 18rem !important;
}

.ps-38 {
    padding-left: 18.5rem !important;
}

.ps-39 {
    padding-left: 19rem !important;
}

.ps-40 {
    padding-left: 19.5rem !important;
}

.ps-41 {
    padding-left: 20rem !important;
}

.ps-42 {
    padding-left: 20.5rem !important;
}

.ps-43 {
    padding-left: 21rem !important;
}

.ps-44 {
    padding-left: 21.5rem !important;
}

.ps-45 {
    padding-left: 22rem !important;
}

.ps-46 {
    padding-left: 22.5rem !important;
}

.ps-47 {
    padding-left: 23rem !important;
}

.ps-48 {
    padding-left: 23.5rem !important;
}

.ps-49 {
    padding-left: 24rem !important;
}

.ps-50 {
    padding-left: 24.5rem !important;
}

.ps-51 {
    padding-left: 25rem !important;
}

.ps-52 {
    padding-left: 25.5rem !important;
}

.ps-53 {
    padding-left: 26rem !important;
}

.ps-54 {
    padding-left: 26.5rem !important;
}

.ps-55 {
    padding-left: 27rem !important;
}

.ps-56 {
    padding-left: 27.5rem !important;
}

.ps-57 {
    padding-left: 28rem !important;
}

.ps-58 {
    padding-left: 28.5rem !important;
}

.ps-59 {
    padding-left: 29rem !important;
}

.ps-60 {
    padding-left: 29.5rem !important;
}

.ps-61 {
    padding-left: 30rem !important;
}

.ps-62 {
    padding-left: 30.5rem !important;
}

.ps-63 {
    padding-left: 31rem !important;
}

.ps-64 {
    padding-left: 31.5rem !important;
}

.ps-65 {
    padding-left: 32rem !important;
}

.ps-66 {
    padding-left: 32.5rem !important;
}

.ps-67 {
    padding-left: 33rem !important;
}

.ps-68 {
    padding-left: 33.5rem !important;
}

.ps-69 {
    padding-left: 34rem !important;
}

.ps-70 {
    padding-left: 34.5rem !important;
}

.ps-71 {
    padding-left: 35rem !important;
}

.ps-72 {
    padding-left: 35.5rem !important;
}

.ps-73 {
    padding-left: 36rem !important;
}

.ps-74 {
    padding-left: 36.5rem !important;
}

.ps-75 {
    padding-left: 37rem !important;
}

.ps-76 {
    padding-left: 37.5rem !important;
}

.ps-77 {
    padding-left: 38rem !important;
}

.ps-78 {
    padding-left: 38.5rem !important;
}

.ps-79 {
    padding-left: 39rem !important;
}

.ps-80 {
    padding-left: 39.5rem !important;
}

.ps-81 {
    padding-left: 40rem !important;
}

.ps-82 {
    padding-left: 40.5rem !important;
}

.ps-83 {
    padding-left: 41rem !important;
}

.ps-84 {
    padding-left: 41.5rem !important;
}

.ps-85 {
    padding-left: 42rem !important;
}

.ps-86 {
    padding-left: 42.5rem !important;
}

.ps-87 {
    padding-left: 43rem !important;
}

.ps-88 {
    padding-left: 43.5rem !important;
}

.ps-89 {
    padding-left: 44rem !important;
}

.ps-90 {
    padding-left: 44.5rem !important;
}

.ps-91 {
    padding-left: 45rem !important;
}

.ps-92 {
    padding-left: 45.5rem !important;
}

.ps-93 {
    padding-left: 46rem !important;
}

.ps-94 {
    padding-left: 46.5rem !important;
}

.ps-95 {
    padding-left: 47rem !important;
}

.ps-96 {
    padding-left: 47.5rem !important;
}

.ps-97 {
    padding-left: 48rem !important;
}

.ps-98 {
    padding-left: 48.5rem !important;
}

.ps-99 {
    padding-left: 49rem !important;
}

.ps-100 {
    padding-left: 49.5rem !important;
}

.ps-101 {
    padding-left: 50rem !important;
}

.ps-102 {
    padding-left: 50.5rem !important;
}

.ps-103 {
    padding-left: 51rem !important;
}

.ps-104 {
    padding-left: 51.5rem !important;
}

.ps-105 {
    padding-left: 52rem !important;
}

.ps-106 {
    padding-left: 52.5rem !important;
}

.ps-107 {
    padding-left: 53rem !important;
}

.ps-108 {
    padding-left: 53.5rem !important;
}

.ps-109 {
    padding-left: 54rem !important;
}

.ps-110 {
    padding-left: 54.5rem !important;
}

.ps-111 {
    padding-left: 55rem !important;
}

.ps-112 {
    padding-left: 55.5rem !important;
}

.ps-113 {
    padding-left: 56rem !important;
}

.ps-114 {
    padding-left: 56.5rem !important;
}

.ps-115 {
    padding-left: 57rem !important;
}

.ps-116 {
    padding-left: 57.5rem !important;
}

.ps-117 {
    padding-left: 58rem !important;
}

.ps-118 {
    padding-left: 58.5rem !important;
}

.ps-119 {
    padding-left: 59rem !important;
}

.ps-120 {
    padding-left: 59.5rem !important;
}

.ps-121 {
    padding-left: 60rem !important;
}

.ps-122 {
    padding-left: 60.5rem !important;
}

.ps-123 {
    padding-left: 61rem !important;
}

.ps-124 {
    padding-left: 61.5rem !important;
}

.ps-125 {
    padding-left: 62rem !important;
}

.ps-126 {
    padding-left: 62.5rem !important;
}

.ps-127 {
    padding-left: 63rem !important;
}

.ps-128 {
    padding-left: 63.5rem !important;
}

.ps-129 {
    padding-left: 64rem !important;
}

.ps-130 {
    padding-left: 64.5rem !important;
}

.ps-131 {
    padding-left: 65rem !important;
}

.ps-132 {
    padding-left: 65.5rem !important;
}

.ps-133 {
    padding-left: 66rem !important;
}

.ps-134 {
    padding-left: 66.5rem !important;
}

.ps-135 {
    padding-left: 67rem !important;
}

.ps-136 {
    padding-left: 67.5rem !important;
}

.ps-137 {
    padding-left: 68rem !important;
}

.ps-138 {
    padding-left: 68.5rem !important;
}

.ps-139 {
    padding-left: 69rem !important;
}

.ps-140 {
    padding-left: 69.5rem !important;
}

.ps-141 {
    padding-left: 70rem !important;
}

.ps-142 {
    padding-left: 70.5rem !important;
}

.ps-143 {
    padding-left: 71rem !important;
}

.ps-144 {
    padding-left: 71.5rem !important;
}

.ps-145 {
    padding-left: 72rem !important;
}

.ps-146 {
    padding-left: 72.5rem !important;
}

.ps-147 {
    padding-left: 73rem !important;
}

.ps-148 {
    padding-left: 73.5rem !important;
}

.ps-149 {
    padding-left: 74rem !important;
}

.ps-150 {
    padding-left: 74.5rem !important;
}

.ps-151 {
    padding-left: 75rem !important;
}

.ps-152 {
    padding-left: 75.5rem !important;
}

.ps-153 {
    padding-left: 76rem !important;
}

.ps-154 {
    padding-left: 76.5rem !important;
}

.ps-155 {
    padding-left: 77rem !important;
}

.ps-156 {
    padding-left: 77.5rem !important;
}

.ps-157 {
    padding-left: 78rem !important;
}

.ps-158 {
    padding-left: 78.5rem !important;
}

.ps-159 {
    padding-left: 79rem !important;
}

.ps-160 {
    padding-left: 79.5rem !important;
}

.pe-0 {
    padding-right: 0rem !important;
}

.pe-1 {
    padding-right: 0.25rem !important;
}

.pe-2 {
    padding-right: 0.5rem !important;
}

.pe-3 {
    padding-right: 1rem !important;
}

.pe-4 {
    padding-right: 1.5rem !important;
}

.pe-5 {
    padding-right: 2rem !important;
}

.pe-6 {
    padding-right: 2.5rem !important;
}

.pe-7 {
    padding-right: 3rem !important;
}

.pe-8 {
    padding-right: 3.5rem !important;
}

.pe-9 {
    padding-right: 4rem !important;
}

.pe-10 {
    padding-right: 4.5rem !important;
}

.pe-11 {
    padding-right: 5rem !important;
}

.pe-12 {
    padding-right: 5.5rem !important;
}

.pe-13 {
    padding-right: 6rem !important;
}

.pe-14 {
    padding-right: 6.5rem !important;
}

.pe-15 {
    padding-right: 7rem !important;
}

.pe-16 {
    padding-right: 7.5rem !important;
}

.pe-17 {
    padding-right: 8rem !important;
}

.pe-18 {
    padding-right: 8.5rem !important;
}

.pe-19 {
    padding-right: 9rem !important;
}

.pe-20 {
    padding-right: 9.5rem !important;
}

.pe-21 {
    padding-right: 10rem !important;
}

.pe-22 {
    padding-right: 10.5rem !important;
}

.pe-23 {
    padding-right: 11rem !important;
}

.pe-24 {
    padding-right: 11.5rem !important;
}

.pe-25 {
    padding-right: 12rem !important;
}

.pe-26 {
    padding-right: 12.5rem !important;
}

.pe-27 {
    padding-right: 13rem !important;
}

.pe-28 {
    padding-right: 13.5rem !important;
}

.pe-29 {
    padding-right: 14rem !important;
}

.pe-30 {
    padding-right: 14.5rem !important;
}

.pe-31 {
    padding-right: 15rem !important;
}

.pe-32 {
    padding-right: 15.5rem !important;
}

.pe-33 {
    padding-right: 16rem !important;
}

.pe-34 {
    padding-right: 16.5rem !important;
}

.pe-35 {
    padding-right: 17rem !important;
}

.pe-36 {
    padding-right: 17.5rem !important;
}

.pe-37 {
    padding-right: 18rem !important;
}

.pe-38 {
    padding-right: 18.5rem !important;
}

.pe-39 {
    padding-right: 19rem !important;
}

.pe-40 {
    padding-right: 19.5rem !important;
}

.pe-41 {
    padding-right: 20rem !important;
}

.pe-42 {
    padding-right: 20.5rem !important;
}

.pe-43 {
    padding-right: 21rem !important;
}

.pe-44 {
    padding-right: 21.5rem !important;
}

.pe-45 {
    padding-right: 22rem !important;
}

.pe-46 {
    padding-right: 22.5rem !important;
}

.pe-47 {
    padding-right: 23rem !important;
}

.pe-48 {
    padding-right: 23.5rem !important;
}

.pe-49 {
    padding-right: 24rem !important;
}

.pe-50 {
    padding-right: 24.5rem !important;
}

.pe-51 {
    padding-right: 25rem !important;
}

.pe-52 {
    padding-right: 25.5rem !important;
}

.pe-53 {
    padding-right: 26rem !important;
}

.pe-54 {
    padding-right: 26.5rem !important;
}

.pe-55 {
    padding-right: 27rem !important;
}

.pe-56 {
    padding-right: 27.5rem !important;
}

.pe-57 {
    padding-right: 28rem !important;
}

.pe-58 {
    padding-right: 28.5rem !important;
}

.pe-59 {
    padding-right: 29rem !important;
}

.pe-60 {
    padding-right: 29.5rem !important;
}

.pe-61 {
    padding-right: 30rem !important;
}

.pe-62 {
    padding-right: 30.5rem !important;
}

.pe-63 {
    padding-right: 31rem !important;
}

.pe-64 {
    padding-right: 31.5rem !important;
}

.pe-65 {
    padding-right: 32rem !important;
}

.pe-66 {
    padding-right: 32.5rem !important;
}

.pe-67 {
    padding-right: 33rem !important;
}

.pe-68 {
    padding-right: 33.5rem !important;
}

.pe-69 {
    padding-right: 34rem !important;
}

.pe-70 {
    padding-right: 34.5rem !important;
}

.pe-71 {
    padding-right: 35rem !important;
}

.pe-72 {
    padding-right: 35.5rem !important;
}

.pe-73 {
    padding-right: 36rem !important;
}

.pe-74 {
    padding-right: 36.5rem !important;
}

.pe-75 {
    padding-right: 37rem !important;
}

.pe-76 {
    padding-right: 37.5rem !important;
}

.pe-77 {
    padding-right: 38rem !important;
}

.pe-78 {
    padding-right: 38.5rem !important;
}

.pe-79 {
    padding-right: 39rem !important;
}

.pe-80 {
    padding-right: 39.5rem !important;
}

.pe-81 {
    padding-right: 40rem !important;
}

.pe-82 {
    padding-right: 40.5rem !important;
}

.pe-83 {
    padding-right: 41rem !important;
}

.pe-84 {
    padding-right: 41.5rem !important;
}

.pe-85 {
    padding-right: 42rem !important;
}

.pe-86 {
    padding-right: 42.5rem !important;
}

.pe-87 {
    padding-right: 43rem !important;
}

.pe-88 {
    padding-right: 43.5rem !important;
}

.pe-89 {
    padding-right: 44rem !important;
}

.pe-90 {
    padding-right: 44.5rem !important;
}

.pe-91 {
    padding-right: 45rem !important;
}

.pe-92 {
    padding-right: 45.5rem !important;
}

.pe-93 {
    padding-right: 46rem !important;
}

.pe-94 {
    padding-right: 46.5rem !important;
}

.pe-95 {
    padding-right: 47rem !important;
}

.pe-96 {
    padding-right: 47.5rem !important;
}

.pe-97 {
    padding-right: 48rem !important;
}

.pe-98 {
    padding-right: 48.5rem !important;
}

.pe-99 {
    padding-right: 49rem !important;
}

.pe-100 {
    padding-right: 49.5rem !important;
}

.pe-101 {
    padding-right: 50rem !important;
}

.pe-102 {
    padding-right: 50.5rem !important;
}

.pe-103 {
    padding-right: 51rem !important;
}

.pe-104 {
    padding-right: 51.5rem !important;
}

.pe-105 {
    padding-right: 52rem !important;
}

.pe-106 {
    padding-right: 52.5rem !important;
}

.pe-107 {
    padding-right: 53rem !important;
}

.pe-108 {
    padding-right: 53.5rem !important;
}

.pe-109 {
    padding-right: 54rem !important;
}

.pe-110 {
    padding-right: 54.5rem !important;
}

.pe-111 {
    padding-right: 55rem !important;
}

.pe-112 {
    padding-right: 55.5rem !important;
}

.pe-113 {
    padding-right: 56rem !important;
}

.pe-114 {
    padding-right: 56.5rem !important;
}

.pe-115 {
    padding-right: 57rem !important;
}

.pe-116 {
    padding-right: 57.5rem !important;
}

.pe-117 {
    padding-right: 58rem !important;
}

.pe-118 {
    padding-right: 58.5rem !important;
}

.pe-119 {
    padding-right: 59rem !important;
}

.pe-120 {
    padding-right: 59.5rem !important;
}

.pe-121 {
    padding-right: 60rem !important;
}

.pe-122 {
    padding-right: 60.5rem !important;
}

.pe-123 {
    padding-right: 61rem !important;
}

.pe-124 {
    padding-right: 61.5rem !important;
}

.pe-125 {
    padding-right: 62rem !important;
}

.pe-126 {
    padding-right: 62.5rem !important;
}

.pe-127 {
    padding-right: 63rem !important;
}

.pe-128 {
    padding-right: 63.5rem !important;
}

.pe-129 {
    padding-right: 64rem !important;
}

.pe-130 {
    padding-right: 64.5rem !important;
}

.pe-131 {
    padding-right: 65rem !important;
}

.pe-132 {
    padding-right: 65.5rem !important;
}

.pe-133 {
    padding-right: 66rem !important;
}

.pe-134 {
    padding-right: 66.5rem !important;
}

.pe-135 {
    padding-right: 67rem !important;
}

.pe-136 {
    padding-right: 67.5rem !important;
}

.pe-137 {
    padding-right: 68rem !important;
}

.pe-138 {
    padding-right: 68.5rem !important;
}

.pe-139 {
    padding-right: 69rem !important;
}

.pe-140 {
    padding-right: 69.5rem !important;
}

.pe-141 {
    padding-right: 70rem !important;
}

.pe-142 {
    padding-right: 70.5rem !important;
}

.pe-143 {
    padding-right: 71rem !important;
}

.pe-144 {
    padding-right: 71.5rem !important;
}

.pe-145 {
    padding-right: 72rem !important;
}

.pe-146 {
    padding-right: 72.5rem !important;
}

.pe-147 {
    padding-right: 73rem !important;
}

.pe-148 {
    padding-right: 73.5rem !important;
}

.pe-149 {
    padding-right: 74rem !important;
}

.pe-150 {
    padding-right: 74.5rem !important;
}

.pe-151 {
    padding-right: 75rem !important;
}

.pe-152 {
    padding-right: 75.5rem !important;
}

.pe-153 {
    padding-right: 76rem !important;
}

.pe-154 {
    padding-right: 76.5rem !important;
}

.pe-155 {
    padding-right: 77rem !important;
}

.pe-156 {
    padding-right: 77.5rem !important;
}

.pe-157 {
    padding-right: 78rem !important;
}

.pe-158 {
    padding-right: 78.5rem !important;
}

.pe-159 {
    padding-right: 79rem !important;
}

.pe-160 {
    padding-right: 79.5rem !important;
}

.px-0 {
    padding-left: 0rem !important;
    padding-right: 0rem !important;
}

.px-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
}

.px-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
}

.px-2_5 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
}

.px-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
}

.px-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
}

.px-5 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
}

.px-6 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
}

.px-7 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
}

.px-8 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
}

.px-9 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
}

.px-10 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
}

.px-11 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
}

.px-12 {
    padding-left: 5.5rem !important;
    padding-right: 5.5rem !important;
}

.px-13 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
}

.px-14 {
    padding-left: 6.5rem !important;
    padding-right: 6.5rem !important;
}

.px-15 {
    padding-left: 7rem !important;
    padding-right: 7rem !important;
}

.px-16 {
    padding-left: 7.5rem !important;
    padding-right: 7.5rem !important;
}

.px-17 {
    padding-left: 8rem !important;
    padding-right: 8rem !important;
}

.px-18 {
    padding-left: 8.5rem !important;
    padding-right: 8.5rem !important;
}

.px-19 {
    padding-left: 9rem !important;
    padding-right: 9rem !important;
}

.px-20 {
    padding-left: 9.5rem !important;
    padding-right: 9.5rem !important;
}

.px-21 {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
}

.px-22 {
    padding-left: 10.5rem !important;
    padding-right: 10.5rem !important;
}

.px-23 {
    padding-left: 11rem !important;
    padding-right: 11rem !important;
}

.px-24 {
    padding-left: 11.5rem !important;
    padding-right: 11.5rem !important;
}

.px-25 {
    padding-left: 12rem !important;
    padding-right: 12rem !important;
}

.px-26 {
    padding-left: 12.5rem !important;
    padding-right: 12.5rem !important;
}

.px-27 {
    padding-left: 13rem !important;
    padding-right: 13rem !important;
}

.px-28 {
    padding-left: 13.5rem !important;
    padding-right: 13.5rem !important;
}

.px-29 {
    padding-left: 14rem !important;
    padding-right: 14rem !important;
}

.px-30 {
    padding-left: 14.5rem !important;
    padding-right: 14.5rem !important;
}

.px-31 {
    padding-left: 15rem !important;
    padding-right: 15rem !important;
}

.px-32 {
    padding-left: 15.5rem !important;
    padding-right: 15.5rem !important;
}

.px-33 {
    padding-left: 16rem !important;
    padding-right: 16rem !important;
}

.px-34 {
    padding-left: 16.5rem !important;
    padding-right: 16.5rem !important;
}

.px-35 {
    padding-left: 17rem !important;
    padding-right: 17rem !important;
}

.px-36 {
    padding-left: 17.5rem !important;
    padding-right: 17.5rem !important;
}

.px-37 {
    padding-left: 18rem !important;
    padding-right: 18rem !important;
}

.px-38 {
    padding-left: 18.5rem !important;
    padding-right: 18.5rem !important;
}

.px-39 {
    padding-left: 19rem !important;
    padding-right: 19rem !important;
}

.px-40 {
    padding-left: 19.5rem !important;
    padding-right: 19.5rem !important;
}

.px-41 {
    padding-left: 20rem !important;
    padding-right: 20rem !important;
}

.px-42 {
    padding-left: 20.5rem !important;
    padding-right: 20.5rem !important;
}

.px-43 {
    padding-left: 21rem !important;
    padding-right: 21rem !important;
}

.px-44 {
    padding-left: 21.5rem !important;
    padding-right: 21.5rem !important;
}

.px-45 {
    padding-left: 22rem !important;
    padding-right: 22rem !important;
}

.px-46 {
    padding-left: 22.5rem !important;
    padding-right: 22.5rem !important;
}

.px-47 {
    padding-left: 23rem !important;
    padding-right: 23rem !important;
}

.px-48 {
    padding-left: 23.5rem !important;
    padding-right: 23.5rem !important;
}

.px-49 {
    padding-left: 24rem !important;
    padding-right: 24rem !important;
}

.px-50 {
    padding-left: 24.5rem !important;
    padding-right: 24.5rem !important;
}

.px-51 {
    padding-left: 25rem !important;
    padding-right: 25rem !important;
}

.px-52 {
    padding-left: 25.5rem !important;
    padding-right: 25.5rem !important;
}

.px-53 {
    padding-left: 26rem !important;
    padding-right: 26rem !important;
}

.px-54 {
    padding-left: 26.5rem !important;
    padding-right: 26.5rem !important;
}

.px-55 {
    padding-left: 27rem !important;
    padding-right: 27rem !important;
}

.px-56 {
    padding-left: 27.5rem !important;
    padding-right: 27.5rem !important;
}

.px-57 {
    padding-left: 28rem !important;
    padding-right: 28rem !important;
}

.px-58 {
    padding-left: 28.5rem !important;
    padding-right: 28.5rem !important;
}

.px-59 {
    padding-left: 29rem !important;
    padding-right: 29rem !important;
}

.px-60 {
    padding-left: 29.5rem !important;
    padding-right: 29.5rem !important;
}

.px-61 {
    padding-left: 30rem !important;
    padding-right: 30rem !important;
}

.px-62 {
    padding-left: 30.5rem !important;
    padding-right: 30.5rem !important;
}

.px-63 {
    padding-left: 31rem !important;
    padding-right: 31rem !important;
}

.px-64 {
    padding-left: 31.5rem !important;
    padding-right: 31.5rem !important;
}

.px-65 {
    padding-left: 32rem !important;
    padding-right: 32rem !important;
}

.px-66 {
    padding-left: 32.5rem !important;
    padding-right: 32.5rem !important;
}

.px-67 {
    padding-left: 33rem !important;
    padding-right: 33rem !important;
}

.px-68 {
    padding-left: 33.5rem !important;
    padding-right: 33.5rem !important;
}

.px-69 {
    padding-left: 34rem !important;
    padding-right: 34rem !important;
}

.px-70 {
    padding-left: 34.5rem !important;
    padding-right: 34.5rem !important;
}

.px-71 {
    padding-left: 35rem !important;
    padding-right: 35rem !important;
}

.px-72 {
    padding-left: 35.5rem !important;
    padding-right: 35.5rem !important;
}

.px-73 {
    padding-left: 36rem !important;
    padding-right: 36rem !important;
}

.px-74 {
    padding-left: 36.5rem !important;
    padding-right: 36.5rem !important;
}

.px-75 {
    padding-left: 37rem !important;
    padding-right: 37rem !important;
}

.px-76 {
    padding-left: 37.5rem !important;
    padding-right: 37.5rem !important;
}

.px-77 {
    padding-left: 38rem !important;
    padding-right: 38rem !important;
}

.px-78 {
    padding-left: 38.5rem !important;
    padding-right: 38.5rem !important;
}

.px-79 {
    padding-left: 39rem !important;
    padding-right: 39rem !important;
}

.px-80 {
    padding-left: 39.5rem !important;
    padding-right: 39.5rem !important;
}

.px-81 {
    padding-left: 40rem !important;
    padding-right: 40rem !important;
}

.px-82 {
    padding-left: 40.5rem !important;
    padding-right: 40.5rem !important;
}

.px-83 {
    padding-left: 41rem !important;
    padding-right: 41rem !important;
}

.px-84 {
    padding-left: 41.5rem !important;
    padding-right: 41.5rem !important;
}

.px-85 {
    padding-left: 42rem !important;
    padding-right: 42rem !important;
}

.px-86 {
    padding-left: 42.5rem !important;
    padding-right: 42.5rem !important;
}

.px-87 {
    padding-left: 43rem !important;
    padding-right: 43rem !important;
}

.px-88 {
    padding-left: 43.5rem !important;
    padding-right: 43.5rem !important;
}

.px-89 {
    padding-left: 44rem !important;
    padding-right: 44rem !important;
}

.px-90 {
    padding-left: 44.5rem !important;
    padding-right: 44.5rem !important;
}

.px-91 {
    padding-left: 45rem !important;
    padding-right: 45rem !important;
}

.px-92 {
    padding-left: 45.5rem !important;
    padding-right: 45.5rem !important;
}

.px-93 {
    padding-left: 46rem !important;
    padding-right: 46rem !important;
}

.px-94 {
    padding-left: 46.5rem !important;
    padding-right: 46.5rem !important;
}

.px-95 {
    padding-left: 47rem !important;
    padding-right: 47rem !important;
}

.px-96 {
    padding-left: 47.5rem !important;
    padding-right: 47.5rem !important;
}

.px-97 {
    padding-left: 48rem !important;
    padding-right: 48rem !important;
}

.px-98 {
    padding-left: 48.5rem !important;
    padding-right: 48.5rem !important;
}

.px-99 {
    padding-left: 49rem !important;
    padding-right: 49rem !important;
}

.px-100 {
    padding-left: 49.5rem !important;
    padding-right: 49.5rem !important;
}

.px-101 {
    padding-left: 50rem !important;
    padding-right: 50rem !important;
}

.px-102 {
    padding-left: 50.5rem !important;
    padding-right: 50.5rem !important;
}

.px-103 {
    padding-left: 51rem !important;
    padding-right: 51rem !important;
}

.px-104 {
    padding-left: 51.5rem !important;
    padding-right: 51.5rem !important;
}

.px-105 {
    padding-left: 52rem !important;
    padding-right: 52rem !important;
}

.px-106 {
    padding-left: 52.5rem !important;
    padding-right: 52.5rem !important;
}

.px-107 {
    padding-left: 53rem !important;
    padding-right: 53rem !important;
}

.px-108 {
    padding-left: 53.5rem !important;
    padding-right: 53.5rem !important;
}

.px-109 {
    padding-left: 54rem !important;
    padding-right: 54rem !important;
}

.px-110 {
    padding-left: 54.5rem !important;
    padding-right: 54.5rem !important;
}

.px-111 {
    padding-left: 55rem !important;
    padding-right: 55rem !important;
}

.px-112 {
    padding-left: 55.5rem !important;
    padding-right: 55.5rem !important;
}

.px-113 {
    padding-left: 56rem !important;
    padding-right: 56rem !important;
}

.px-114 {
    padding-left: 56.5rem !important;
    padding-right: 56.5rem !important;
}

.px-115 {
    padding-left: 57rem !important;
    padding-right: 57rem !important;
}

.px-116 {
    padding-left: 57.5rem !important;
    padding-right: 57.5rem !important;
}

.px-117 {
    padding-left: 58rem !important;
    padding-right: 58rem !important;
}

.px-118 {
    padding-left: 58.5rem !important;
    padding-right: 58.5rem !important;
}

.px-119 {
    padding-left: 59rem !important;
    padding-right: 59rem !important;
}

.px-120 {
    padding-left: 59.5rem !important;
    padding-right: 59.5rem !important;
}

.px-121 {
    padding-left: 60rem !important;
    padding-right: 60rem !important;
}

.px-122 {
    padding-left: 60.5rem !important;
    padding-right: 60.5rem !important;
}

.px-123 {
    padding-left: 61rem !important;
    padding-right: 61rem !important;
}

.px-124 {
    padding-left: 61.5rem !important;
    padding-right: 61.5rem !important;
}

.px-125 {
    padding-left: 62rem !important;
    padding-right: 62rem !important;
}

.px-126 {
    padding-left: 62.5rem !important;
    padding-right: 62.5rem !important;
}

.px-127 {
    padding-left: 63rem !important;
    padding-right: 63rem !important;
}

.px-128 {
    padding-left: 63.5rem !important;
    padding-right: 63.5rem !important;
}

.px-129 {
    padding-left: 64rem !important;
    padding-right: 64rem !important;
}

.px-130 {
    padding-left: 64.5rem !important;
    padding-right: 64.5rem !important;
}

.px-131 {
    padding-left: 65rem !important;
    padding-right: 65rem !important;
}

.px-132 {
    padding-left: 65.5rem !important;
    padding-right: 65.5rem !important;
}

.px-133 {
    padding-left: 66rem !important;
    padding-right: 66rem !important;
}

.px-134 {
    padding-left: 66.5rem !important;
    padding-right: 66.5rem !important;
}

.px-135 {
    padding-left: 67rem !important;
    padding-right: 67rem !important;
}

.px-136 {
    padding-left: 67.5rem !important;
    padding-right: 67.5rem !important;
}

.px-137 {
    padding-left: 68rem !important;
    padding-right: 68rem !important;
}

.px-138 {
    padding-left: 68.5rem !important;
    padding-right: 68.5rem !important;
}

.px-139 {
    padding-left: 69rem !important;
    padding-right: 69rem !important;
}

.px-140 {
    padding-left: 69.5rem !important;
    padding-right: 69.5rem !important;
}

.px-141 {
    padding-left: 70rem !important;
    padding-right: 70rem !important;
}

.px-142 {
    padding-left: 70.5rem !important;
    padding-right: 70.5rem !important;
}

.px-143 {
    padding-left: 71rem !important;
    padding-right: 71rem !important;
}

.px-144 {
    padding-left: 71.5rem !important;
    padding-right: 71.5rem !important;
}

.px-145 {
    padding-left: 72rem !important;
    padding-right: 72rem !important;
}

.px-146 {
    padding-left: 72.5rem !important;
    padding-right: 72.5rem !important;
}

.px-147 {
    padding-left: 73rem !important;
    padding-right: 73rem !important;
}

.px-148 {
    padding-left: 73.5rem !important;
    padding-right: 73.5rem !important;
}

.px-149 {
    padding-left: 74rem !important;
    padding-right: 74rem !important;
}

.px-150 {
    padding-left: 74.5rem !important;
    padding-right: 74.5rem !important;
}

.px-151 {
    padding-left: 75rem !important;
    padding-right: 75rem !important;
}

.px-152 {
    padding-left: 75.5rem !important;
    padding-right: 75.5rem !important;
}

.px-153 {
    padding-left: 76rem !important;
    padding-right: 76rem !important;
}

.px-154 {
    padding-left: 76.5rem !important;
    padding-right: 76.5rem !important;
}

.px-155 {
    padding-left: 77rem !important;
    padding-right: 77rem !important;
}

.px-156 {
    padding-left: 77.5rem !important;
    padding-right: 77.5rem !important;
}

.px-157 {
    padding-left: 78rem !important;
    padding-right: 78rem !important;
}

.px-158 {
    padding-left: 78.5rem !important;
    padding-right: 78.5rem !important;
}

.px-159 {
    padding-left: 79rem !important;
    padding-right: 79rem !important;
}

.px-160 {
    padding-left: 79.5rem !important;
    padding-right: 79.5rem !important;
}

.py-0 {
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
}

.py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
}

.py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
}

.py-2_5 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
}

.py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}

.py-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
}

.py-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
}

.py-6 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
}

.py-7 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
}

.py-8 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
}

.py-9 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
}

.py-10 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
}

.py-11 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
}

.py-12 {
    padding-top: 5.5rem !important;
    padding-bottom: 5.5rem !important;
}

.py-13 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
}

.py-14 {
    padding-top: 6.5rem !important;
    padding-bottom: 6.5rem !important;
}

.py-15 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
}

.py-16 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
}

.py-17 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
}

.py-18 {
    padding-top: 8.5rem !important;
    padding-bottom: 8.5rem !important;
}

.py-19 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
}

.py-20 {
    padding-top: 9.5rem !important;
    padding-bottom: 9.5rem !important;
}

.py-21 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
}

.py-22 {
    padding-top: 10.5rem !important;
    padding-bottom: 10.5rem !important;
}

.py-23 {
    padding-top: 11rem !important;
    padding-bottom: 11rem !important;
}

.py-24 {
    padding-top: 11.5rem !important;
    padding-bottom: 11.5rem !important;
}

.py-25 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
}

.py-26 {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important;
}

.py-27 {
    padding-top: 13rem !important;
    padding-bottom: 13rem !important;
}

.py-28 {
    padding-top: 13.5rem !important;
    padding-bottom: 13.5rem !important;
}

.py-29 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
}

.py-30 {
    padding-top: 14.5rem !important;
    padding-bottom: 14.5rem !important;
}

.py-31 {
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
}

.py-32 {
    padding-top: 15.5rem !important;
    padding-bottom: 15.5rem !important;
}

.py-33 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
}

.py-34 {
    padding-top: 16.5rem !important;
    padding-bottom: 16.5rem !important;
}

.py-35 {
    padding-top: 17rem !important;
    padding-bottom: 17rem !important;
}

.py-36 {
    padding-top: 17.5rem !important;
    padding-bottom: 17.5rem !important;
}

.py-37 {
    padding-top: 18rem !important;
    padding-bottom: 18rem !important;
}

.py-38 {
    padding-top: 18.5rem !important;
    padding-bottom: 18.5rem !important;
}

.py-39 {
    padding-top: 19rem !important;
    padding-bottom: 19rem !important;
}

.py-40 {
    padding-top: 19.5rem !important;
    padding-bottom: 19.5rem !important;
}

.py-41 {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
}

.py-42 {
    padding-top: 20.5rem !important;
    padding-bottom: 20.5rem !important;
}

.py-43 {
    padding-top: 21rem !important;
    padding-bottom: 21rem !important;
}

.py-44 {
    padding-top: 21.5rem !important;
    padding-bottom: 21.5rem !important;
}

.py-45 {
    padding-top: 22rem !important;
    padding-bottom: 22rem !important;
}

.py-46 {
    padding-top: 22.5rem !important;
    padding-bottom: 22.5rem !important;
}

.py-47 {
    padding-top: 23rem !important;
    padding-bottom: 23rem !important;
}

.py-48 {
    padding-top: 23.5rem !important;
    padding-bottom: 23.5rem !important;
}

.py-49 {
    padding-top: 24rem !important;
    padding-bottom: 24rem !important;
}

.py-50 {
    padding-top: 24.5rem !important;
    padding-bottom: 24.5rem !important;
}

.py-51 {
    padding-top: 25rem !important;
    padding-bottom: 25rem !important;
}

.py-52 {
    padding-top: 25.5rem !important;
    padding-bottom: 25.5rem !important;
}

.py-53 {
    padding-top: 26rem !important;
    padding-bottom: 26rem !important;
}

.py-54 {
    padding-top: 26.5rem !important;
    padding-bottom: 26.5rem !important;
}

.py-55 {
    padding-top: 27rem !important;
    padding-bottom: 27rem !important;
}

.py-56 {
    padding-top: 27.5rem !important;
    padding-bottom: 27.5rem !important;
}

.py-57 {
    padding-top: 28rem !important;
    padding-bottom: 28rem !important;
}

.py-58 {
    padding-top: 28.5rem !important;
    padding-bottom: 28.5rem !important;
}

.py-59 {
    padding-top: 29rem !important;
    padding-bottom: 29rem !important;
}

.py-60 {
    padding-top: 29.5rem !important;
    padding-bottom: 29.5rem !important;
}

.py-61 {
    padding-top: 30rem !important;
    padding-bottom: 30rem !important;
}

.py-62 {
    padding-top: 30.5rem !important;
    padding-bottom: 30.5rem !important;
}

.py-63 {
    padding-top: 31rem !important;
    padding-bottom: 31rem !important;
}

.py-64 {
    padding-top: 31.5rem !important;
    padding-bottom: 31.5rem !important;
}

.py-65 {
    padding-top: 32rem !important;
    padding-bottom: 32rem !important;
}

.py-66 {
    padding-top: 32.5rem !important;
    padding-bottom: 32.5rem !important;
}

.py-67 {
    padding-top: 33rem !important;
    padding-bottom: 33rem !important;
}

.py-68 {
    padding-top: 33.5rem !important;
    padding-bottom: 33.5rem !important;
}

.py-69 {
    padding-top: 34rem !important;
    padding-bottom: 34rem !important;
}

.py-70 {
    padding-top: 34.5rem !important;
    padding-bottom: 34.5rem !important;
}

.py-71 {
    padding-top: 35rem !important;
    padding-bottom: 35rem !important;
}

.py-72 {
    padding-top: 35.5rem !important;
    padding-bottom: 35.5rem !important;
}

.py-73 {
    padding-top: 36rem !important;
    padding-bottom: 36rem !important;
}

.py-74 {
    padding-top: 36.5rem !important;
    padding-bottom: 36.5rem !important;
}

.py-75 {
    padding-top: 37rem !important;
    padding-bottom: 37rem !important;
}

.py-76 {
    padding-top: 37.5rem !important;
    padding-bottom: 37.5rem !important;
}

.py-77 {
    padding-top: 38rem !important;
    padding-bottom: 38rem !important;
}

.py-78 {
    padding-top: 38.5rem !important;
    padding-bottom: 38.5rem !important;
}

.py-79 {
    padding-top: 39rem !important;
    padding-bottom: 39rem !important;
}

.py-80 {
    padding-top: 39.5rem !important;
    padding-bottom: 39.5rem !important;
}

.py-81 {
    padding-top: 40rem !important;
    padding-bottom: 40rem !important;
}

.py-82 {
    padding-top: 40.5rem !important;
    padding-bottom: 40.5rem !important;
}

.py-83 {
    padding-top: 41rem !important;
    padding-bottom: 41rem !important;
}

.py-84 {
    padding-top: 41.5rem !important;
    padding-bottom: 41.5rem !important;
}

.py-85 {
    padding-top: 42rem !important;
    padding-bottom: 42rem !important;
}

.py-86 {
    padding-top: 42.5rem !important;
    padding-bottom: 42.5rem !important;
}

.py-87 {
    padding-top: 43rem !important;
    padding-bottom: 43rem !important;
}

.py-88 {
    padding-top: 43.5rem !important;
    padding-bottom: 43.5rem !important;
}

.py-89 {
    padding-top: 44rem !important;
    padding-bottom: 44rem !important;
}

.py-90 {
    padding-top: 44.5rem !important;
    padding-bottom: 44.5rem !important;
}

.py-91 {
    padding-top: 45rem !important;
    padding-bottom: 45rem !important;
}

.py-92 {
    padding-top: 45.5rem !important;
    padding-bottom: 45.5rem !important;
}

.py-93 {
    padding-top: 46rem !important;
    padding-bottom: 46rem !important;
}

.py-94 {
    padding-top: 46.5rem !important;
    padding-bottom: 46.5rem !important;
}

.py-95 {
    padding-top: 47rem !important;
    padding-bottom: 47rem !important;
}

.py-96 {
    padding-top: 47.5rem !important;
    padding-bottom: 47.5rem !important;
}

.py-97 {
    padding-top: 48rem !important;
    padding-bottom: 48rem !important;
}

.py-98 {
    padding-top: 48.5rem !important;
    padding-bottom: 48.5rem !important;
}

.py-99 {
    padding-top: 49rem !important;
    padding-bottom: 49rem !important;
}

.py-100 {
    padding-top: 49.5rem !important;
    padding-bottom: 49.5rem !important;
}

.py-101 {
    padding-top: 50rem !important;
    padding-bottom: 50rem !important;
}

.py-102 {
    padding-top: 50.5rem !important;
    padding-bottom: 50.5rem !important;
}

.py-103 {
    padding-top: 51rem !important;
    padding-bottom: 51rem !important;
}

.py-104 {
    padding-top: 51.5rem !important;
    padding-bottom: 51.5rem !important;
}

.py-105 {
    padding-top: 52rem !important;
    padding-bottom: 52rem !important;
}

.py-106 {
    padding-top: 52.5rem !important;
    padding-bottom: 52.5rem !important;
}

.py-107 {
    padding-top: 53rem !important;
    padding-bottom: 53rem !important;
}

.py-108 {
    padding-top: 53.5rem !important;
    padding-bottom: 53.5rem !important;
}

.py-109 {
    padding-top: 54rem !important;
    padding-bottom: 54rem !important;
}

.py-110 {
    padding-top: 54.5rem !important;
    padding-bottom: 54.5rem !important;
}

.py-111 {
    padding-top: 55rem !important;
    padding-bottom: 55rem !important;
}

.py-112 {
    padding-top: 55.5rem !important;
    padding-bottom: 55.5rem !important;
}

.py-113 {
    padding-top: 56rem !important;
    padding-bottom: 56rem !important;
}

.py-114 {
    padding-top: 56.5rem !important;
    padding-bottom: 56.5rem !important;
}

.py-115 {
    padding-top: 57rem !important;
    padding-bottom: 57rem !important;
}

.py-116 {
    padding-top: 57.5rem !important;
    padding-bottom: 57.5rem !important;
}

.py-117 {
    padding-top: 58rem !important;
    padding-bottom: 58rem !important;
}

.py-118 {
    padding-top: 58.5rem !important;
    padding-bottom: 58.5rem !important;
}

.py-119 {
    padding-top: 59rem !important;
    padding-bottom: 59rem !important;
}

.py-120 {
    padding-top: 59.5rem !important;
    padding-bottom: 59.5rem !important;
}

.py-121 {
    padding-top: 60rem !important;
    padding-bottom: 60rem !important;
}

.py-122 {
    padding-top: 60.5rem !important;
    padding-bottom: 60.5rem !important;
}

.py-123 {
    padding-top: 61rem !important;
    padding-bottom: 61rem !important;
}

.py-124 {
    padding-top: 61.5rem !important;
    padding-bottom: 61.5rem !important;
}

.py-125 {
    padding-top: 62rem !important;
    padding-bottom: 62rem !important;
}

.py-126 {
    padding-top: 62.5rem !important;
    padding-bottom: 62.5rem !important;
}

.py-127 {
    padding-top: 63rem !important;
    padding-bottom: 63rem !important;
}

.py-128 {
    padding-top: 63.5rem !important;
    padding-bottom: 63.5rem !important;
}

.py-129 {
    padding-top: 64rem !important;
    padding-bottom: 64rem !important;
}

.py-130 {
    padding-top: 64.5rem !important;
    padding-bottom: 64.5rem !important;
}

.py-131 {
    padding-top: 65rem !important;
    padding-bottom: 65rem !important;
}

.py-132 {
    padding-top: 65.5rem !important;
    padding-bottom: 65.5rem !important;
}

.py-133 {
    padding-top: 66rem !important;
    padding-bottom: 66rem !important;
}

.py-134 {
    padding-top: 66.5rem !important;
    padding-bottom: 66.5rem !important;
}

.py-135 {
    padding-top: 67rem !important;
    padding-bottom: 67rem !important;
}

.py-136 {
    padding-top: 67.5rem !important;
    padding-bottom: 67.5rem !important;
}

.py-137 {
    padding-top: 68rem !important;
    padding-bottom: 68rem !important;
}

.py-138 {
    padding-top: 68.5rem !important;
    padding-bottom: 68.5rem !important;
}

.py-139 {
    padding-top: 69rem !important;
    padding-bottom: 69rem !important;
}

.py-140 {
    padding-top: 69.5rem !important;
    padding-bottom: 69.5rem !important;
}

.py-141 {
    padding-top: 70rem !important;
    padding-bottom: 70rem !important;
}

.py-142 {
    padding-top: 70.5rem !important;
    padding-bottom: 70.5rem !important;
}

.py-143 {
    padding-top: 71rem !important;
    padding-bottom: 71rem !important;
}

.py-144 {
    padding-top: 71.5rem !important;
    padding-bottom: 71.5rem !important;
}

.py-145 {
    padding-top: 72rem !important;
    padding-bottom: 72rem !important;
}

.py-146 {
    padding-top: 72.5rem !important;
    padding-bottom: 72.5rem !important;
}

.py-147 {
    padding-top: 73rem !important;
    padding-bottom: 73rem !important;
}

.py-148 {
    padding-top: 73.5rem !important;
    padding-bottom: 73.5rem !important;
}

.py-149 {
    padding-top: 74rem !important;
    padding-bottom: 74rem !important;
}

.py-150 {
    padding-top: 74.5rem !important;
    padding-bottom: 74.5rem !important;
}

.py-151 {
    padding-top: 75rem !important;
    padding-bottom: 75rem !important;
}

.py-152 {
    padding-top: 75.5rem !important;
    padding-bottom: 75.5rem !important;
}

.py-153 {
    padding-top: 76rem !important;
    padding-bottom: 76rem !important;
}

.py-154 {
    padding-top: 76.5rem !important;
    padding-bottom: 76.5rem !important;
}

.py-155 {
    padding-top: 77rem !important;
    padding-bottom: 77rem !important;
}

.py-156 {
    padding-top: 77.5rem !important;
    padding-bottom: 77.5rem !important;
}

.py-157 {
    padding-top: 78rem !important;
    padding-bottom: 78rem !important;
}

.py-158 {
    padding-top: 78.5rem !important;
    padding-bottom: 78.5rem !important;
}

.py-159 {
    padding-top: 79rem !important;
    padding-bottom: 79rem !important;
}

.py-160 {
    padding-top: 79.5rem !important;
    padding-bottom: 79.5rem !important;
}

@media (min-width: 576px) {
    .p-sm-0 {
        padding: 0rem !important;
    }

    .p-sm-1 {
        padding: 0.25rem !important;
    }

    .p-sm-2 {
        padding: 0.5rem !important;
    }

    .p-sm-3 {
        padding: 1rem !important;
    }

    .p-sm-4 {
        padding: 1.5rem !important;
    }

    .p-sm-5 {
        padding: 2rem !important;
    }

    .p-sm-6 {
        padding: 2.5rem !important;
    }

    .p-sm-7 {
        padding: 3rem !important;
    }

    .p-sm-8 {
        padding: 3.5rem !important;
    }

    .p-sm-9 {
        padding: 4rem !important;
    }

    .p-sm-10 {
        padding: 4.5rem !important;
    }

    .p-sm-11 {
        padding: 5rem !important;
    }

    .p-sm-12 {
        padding: 5.5rem !important;
    }

    .p-sm-13 {
        padding: 6rem !important;
    }

    .p-sm-14 {
        padding: 6.5rem !important;
    }

    .p-sm-15 {
        padding: 7rem !important;
    }

    .p-sm-16 {
        padding: 7.5rem !important;
    }

    .p-sm-17 {
        padding: 8rem !important;
    }

    .p-sm-18 {
        padding: 8.5rem !important;
    }

    .p-sm-19 {
        padding: 9rem !important;
    }

    .p-sm-20 {
        padding: 9.5rem !important;
    }

    .p-sm-21 {
        padding: 10rem !important;
    }

    .p-sm-22 {
        padding: 10.5rem !important;
    }

    .p-sm-23 {
        padding: 11rem !important;
    }

    .p-sm-24 {
        padding: 11.5rem !important;
    }

    .p-sm-25 {
        padding: 12rem !important;
    }

    .p-sm-26 {
        padding: 12.5rem !important;
    }

    .p-sm-27 {
        padding: 13rem !important;
    }

    .p-sm-28 {
        padding: 13.5rem !important;
    }

    .p-sm-29 {
        padding: 14rem !important;
    }

    .p-sm-30 {
        padding: 14.5rem !important;
    }

    .p-sm-31 {
        padding: 15rem !important;
    }

    .p-sm-32 {
        padding: 15.5rem !important;
    }

    .p-sm-33 {
        padding: 16rem !important;
    }

    .p-sm-34 {
        padding: 16.5rem !important;
    }

    .p-sm-35 {
        padding: 17rem !important;
    }

    .p-sm-36 {
        padding: 17.5rem !important;
    }

    .p-sm-37 {
        padding: 18rem !important;
    }

    .p-sm-38 {
        padding: 18.5rem !important;
    }

    .p-sm-39 {
        padding: 19rem !important;
    }

    .p-sm-40 {
        padding: 19.5rem !important;
    }

    .p-sm-41 {
        padding: 20rem !important;
    }

    .p-sm-42 {
        padding: 20.5rem !important;
    }

    .p-sm-43 {
        padding: 21rem !important;
    }

    .p-sm-44 {
        padding: 21.5rem !important;
    }

    .p-sm-45 {
        padding: 22rem !important;
    }

    .p-sm-46 {
        padding: 22.5rem !important;
    }

    .p-sm-47 {
        padding: 23rem !important;
    }

    .p-sm-48 {
        padding: 23.5rem !important;
    }

    .p-sm-49 {
        padding: 24rem !important;
    }

    .p-sm-50 {
        padding: 24.5rem !important;
    }

    .p-sm-51 {
        padding: 25rem !important;
    }

    .p-sm-52 {
        padding: 25.5rem !important;
    }

    .p-sm-53 {
        padding: 26rem !important;
    }

    .p-sm-54 {
        padding: 26.5rem !important;
    }

    .p-sm-55 {
        padding: 27rem !important;
    }

    .p-sm-56 {
        padding: 27.5rem !important;
    }

    .p-sm-57 {
        padding: 28rem !important;
    }

    .p-sm-58 {
        padding: 28.5rem !important;
    }

    .p-sm-59 {
        padding: 29rem !important;
    }

    .p-sm-60 {
        padding: 29.5rem !important;
    }

    .p-sm-61 {
        padding: 30rem !important;
    }

    .p-sm-62 {
        padding: 30.5rem !important;
    }

    .p-sm-63 {
        padding: 31rem !important;
    }

    .p-sm-64 {
        padding: 31.5rem !important;
    }

    .p-sm-65 {
        padding: 32rem !important;
    }

    .p-sm-66 {
        padding: 32.5rem !important;
    }

    .p-sm-67 {
        padding: 33rem !important;
    }

    .p-sm-68 {
        padding: 33.5rem !important;
    }

    .p-sm-69 {
        padding: 34rem !important;
    }

    .p-sm-70 {
        padding: 34.5rem !important;
    }

    .p-sm-71 {
        padding: 35rem !important;
    }

    .p-sm-72 {
        padding: 35.5rem !important;
    }

    .p-sm-73 {
        padding: 36rem !important;
    }

    .p-sm-74 {
        padding: 36.5rem !important;
    }

    .p-sm-75 {
        padding: 37rem !important;
    }

    .p-sm-76 {
        padding: 37.5rem !important;
    }

    .p-sm-77 {
        padding: 38rem !important;
    }

    .p-sm-78 {
        padding: 38.5rem !important;
    }

    .p-sm-79 {
        padding: 39rem !important;
    }

    .p-sm-80 {
        padding: 39.5rem !important;
    }

    .p-sm-81 {
        padding: 40rem !important;
    }

    .p-sm-82 {
        padding: 40.5rem !important;
    }

    .p-sm-83 {
        padding: 41rem !important;
    }

    .p-sm-84 {
        padding: 41.5rem !important;
    }

    .p-sm-85 {
        padding: 42rem !important;
    }

    .p-sm-86 {
        padding: 42.5rem !important;
    }

    .p-sm-87 {
        padding: 43rem !important;
    }

    .p-sm-88 {
        padding: 43.5rem !important;
    }

    .p-sm-89 {
        padding: 44rem !important;
    }

    .p-sm-90 {
        padding: 44.5rem !important;
    }

    .p-sm-91 {
        padding: 45rem !important;
    }

    .p-sm-92 {
        padding: 45.5rem !important;
    }

    .p-sm-93 {
        padding: 46rem !important;
    }

    .p-sm-94 {
        padding: 46.5rem !important;
    }

    .p-sm-95 {
        padding: 47rem !important;
    }

    .p-sm-96 {
        padding: 47.5rem !important;
    }

    .p-sm-97 {
        padding: 48rem !important;
    }

    .p-sm-98 {
        padding: 48.5rem !important;
    }

    .p-sm-99 {
        padding: 49rem !important;
    }

    .p-sm-100 {
        padding: 49.5rem !important;
    }

    .p-sm-101 {
        padding: 50rem !important;
    }

    .p-sm-102 {
        padding: 50.5rem !important;
    }

    .p-sm-103 {
        padding: 51rem !important;
    }

    .p-sm-104 {
        padding: 51.5rem !important;
    }

    .p-sm-105 {
        padding: 52rem !important;
    }

    .p-sm-106 {
        padding: 52.5rem !important;
    }

    .p-sm-107 {
        padding: 53rem !important;
    }

    .p-sm-108 {
        padding: 53.5rem !important;
    }

    .p-sm-109 {
        padding: 54rem !important;
    }

    .p-sm-110 {
        padding: 54.5rem !important;
    }

    .p-sm-111 {
        padding: 55rem !important;
    }

    .p-sm-112 {
        padding: 55.5rem !important;
    }

    .p-sm-113 {
        padding: 56rem !important;
    }

    .p-sm-114 {
        padding: 56.5rem !important;
    }

    .p-sm-115 {
        padding: 57rem !important;
    }

    .p-sm-116 {
        padding: 57.5rem !important;
    }

    .p-sm-117 {
        padding: 58rem !important;
    }

    .p-sm-118 {
        padding: 58.5rem !important;
    }

    .p-sm-119 {
        padding: 59rem !important;
    }

    .p-sm-120 {
        padding: 59.5rem !important;
    }

    .p-sm-121 {
        padding: 60rem !important;
    }

    .p-sm-122 {
        padding: 60.5rem !important;
    }

    .p-sm-123 {
        padding: 61rem !important;
    }

    .p-sm-124 {
        padding: 61.5rem !important;
    }

    .p-sm-125 {
        padding: 62rem !important;
    }

    .p-sm-126 {
        padding: 62.5rem !important;
    }

    .p-sm-127 {
        padding: 63rem !important;
    }

    .p-sm-128 {
        padding: 63.5rem !important;
    }

    .p-sm-129 {
        padding: 64rem !important;
    }

    .p-sm-130 {
        padding: 64.5rem !important;
    }

    .p-sm-131 {
        padding: 65rem !important;
    }

    .p-sm-132 {
        padding: 65.5rem !important;
    }

    .p-sm-133 {
        padding: 66rem !important;
    }

    .p-sm-134 {
        padding: 66.5rem !important;
    }

    .p-sm-135 {
        padding: 67rem !important;
    }

    .p-sm-136 {
        padding: 67.5rem !important;
    }

    .p-sm-137 {
        padding: 68rem !important;
    }

    .p-sm-138 {
        padding: 68.5rem !important;
    }

    .p-sm-139 {
        padding: 69rem !important;
    }

    .p-sm-140 {
        padding: 69.5rem !important;
    }

    .p-sm-141 {
        padding: 70rem !important;
    }

    .p-sm-142 {
        padding: 70.5rem !important;
    }

    .p-sm-143 {
        padding: 71rem !important;
    }

    .p-sm-144 {
        padding: 71.5rem !important;
    }

    .p-sm-145 {
        padding: 72rem !important;
    }

    .p-sm-146 {
        padding: 72.5rem !important;
    }

    .p-sm-147 {
        padding: 73rem !important;
    }

    .p-sm-148 {
        padding: 73.5rem !important;
    }

    .p-sm-149 {
        padding: 74rem !important;
    }

    .p-sm-150 {
        padding: 74.5rem !important;
    }

    .p-sm-151 {
        padding: 75rem !important;
    }

    .p-sm-152 {
        padding: 75.5rem !important;
    }

    .p-sm-153 {
        padding: 76rem !important;
    }

    .p-sm-154 {
        padding: 76.5rem !important;
    }

    .p-sm-155 {
        padding: 77rem !important;
    }

    .p-sm-156 {
        padding: 77.5rem !important;
    }

    .p-sm-157 {
        padding: 78rem !important;
    }

    .p-sm-158 {
        padding: 78.5rem !important;
    }

    .p-sm-159 {
        padding: 79rem !important;
    }

    .p-sm-160 {
        padding: 79.5rem !important;
    }

    .pt-sm-0 {
        padding-top: 0rem !important;
    }

    .pt-sm-1 {
        padding-top: 0.25rem !important;
    }

    .pt-sm-2 {
        padding-top: 0.5rem !important;
    }

    .pt-sm-3 {
        padding-top: 1rem !important;
    }

    .pt-sm-4 {
        padding-top: 1.5rem !important;
    }

    .pt-sm-5 {
        padding-top: 2rem !important;
    }

    .pt-sm-6 {
        padding-top: 2.5rem !important;
    }

    .pt-sm-7 {
        padding-top: 3rem !important;
    }

    .pt-sm-8 {
        padding-top: 3.5rem !important;
    }

    .pt-sm-9 {
        padding-top: 4rem !important;
    }

    .pt-sm-10 {
        padding-top: 4.5rem !important;
    }

    .pt-sm-11 {
        padding-top: 5rem !important;
    }

    .pt-sm-12 {
        padding-top: 5.5rem !important;
    }

    .pt-sm-13 {
        padding-top: 6rem !important;
    }

    .pt-sm-14 {
        padding-top: 6.5rem !important;
    }

    .pt-sm-15 {
        padding-top: 7rem !important;
    }

    .pt-sm-16 {
        padding-top: 7.5rem !important;
    }

    .pt-sm-17 {
        padding-top: 8rem !important;
    }

    .pt-sm-18 {
        padding-top: 8.5rem !important;
    }

    .pt-sm-19 {
        padding-top: 9rem !important;
    }

    .pt-sm-20 {
        padding-top: 9.5rem !important;
    }

    .pt-sm-21 {
        padding-top: 10rem !important;
    }

    .pt-sm-22 {
        padding-top: 10.5rem !important;
    }

    .pt-sm-23 {
        padding-top: 11rem !important;
    }

    .pt-sm-24 {
        padding-top: 11.5rem !important;
    }

    .pt-sm-25 {
        padding-top: 12rem !important;
    }

    .pt-sm-26 {
        padding-top: 12.5rem !important;
    }

    .pt-sm-27 {
        padding-top: 13rem !important;
    }

    .pt-sm-28 {
        padding-top: 13.5rem !important;
    }

    .pt-sm-29 {
        padding-top: 14rem !important;
    }

    .pt-sm-30 {
        padding-top: 14.5rem !important;
    }

    .pt-sm-31 {
        padding-top: 15rem !important;
    }

    .pt-sm-32 {
        padding-top: 15.5rem !important;
    }

    .pt-sm-33 {
        padding-top: 16rem !important;
    }

    .pt-sm-34 {
        padding-top: 16.5rem !important;
    }

    .pt-sm-35 {
        padding-top: 17rem !important;
    }

    .pt-sm-36 {
        padding-top: 17.5rem !important;
    }

    .pt-sm-37 {
        padding-top: 18rem !important;
    }

    .pt-sm-38 {
        padding-top: 18.5rem !important;
    }

    .pt-sm-39 {
        padding-top: 19rem !important;
    }

    .pt-sm-40 {
        padding-top: 19.5rem !important;
    }

    .pt-sm-41 {
        padding-top: 20rem !important;
    }

    .pt-sm-42 {
        padding-top: 20.5rem !important;
    }

    .pt-sm-43 {
        padding-top: 21rem !important;
    }

    .pt-sm-44 {
        padding-top: 21.5rem !important;
    }

    .pt-sm-45 {
        padding-top: 22rem !important;
    }

    .pt-sm-46 {
        padding-top: 22.5rem !important;
    }

    .pt-sm-47 {
        padding-top: 23rem !important;
    }

    .pt-sm-48 {
        padding-top: 23.5rem !important;
    }

    .pt-sm-49 {
        padding-top: 24rem !important;
    }

    .pt-sm-50 {
        padding-top: 24.5rem !important;
    }

    .pt-sm-51 {
        padding-top: 25rem !important;
    }

    .pt-sm-52 {
        padding-top: 25.5rem !important;
    }

    .pt-sm-53 {
        padding-top: 26rem !important;
    }

    .pt-sm-54 {
        padding-top: 26.5rem !important;
    }

    .pt-sm-55 {
        padding-top: 27rem !important;
    }

    .pt-sm-56 {
        padding-top: 27.5rem !important;
    }

    .pt-sm-57 {
        padding-top: 28rem !important;
    }

    .pt-sm-58 {
        padding-top: 28.5rem !important;
    }

    .pt-sm-59 {
        padding-top: 29rem !important;
    }

    .pt-sm-60 {
        padding-top: 29.5rem !important;
    }

    .pt-sm-61 {
        padding-top: 30rem !important;
    }

    .pt-sm-62 {
        padding-top: 30.5rem !important;
    }

    .pt-sm-63 {
        padding-top: 31rem !important;
    }

    .pt-sm-64 {
        padding-top: 31.5rem !important;
    }

    .pt-sm-65 {
        padding-top: 32rem !important;
    }

    .pt-sm-66 {
        padding-top: 32.5rem !important;
    }

    .pt-sm-67 {
        padding-top: 33rem !important;
    }

    .pt-sm-68 {
        padding-top: 33.5rem !important;
    }

    .pt-sm-69 {
        padding-top: 34rem !important;
    }

    .pt-sm-70 {
        padding-top: 34.5rem !important;
    }

    .pt-sm-71 {
        padding-top: 35rem !important;
    }

    .pt-sm-72 {
        padding-top: 35.5rem !important;
    }

    .pt-sm-73 {
        padding-top: 36rem !important;
    }

    .pt-sm-74 {
        padding-top: 36.5rem !important;
    }

    .pt-sm-75 {
        padding-top: 37rem !important;
    }

    .pt-sm-76 {
        padding-top: 37.5rem !important;
    }

    .pt-sm-77 {
        padding-top: 38rem !important;
    }

    .pt-sm-78 {
        padding-top: 38.5rem !important;
    }

    .pt-sm-79 {
        padding-top: 39rem !important;
    }

    .pt-sm-80 {
        padding-top: 39.5rem !important;
    }

    .pt-sm-81 {
        padding-top: 40rem !important;
    }

    .pt-sm-82 {
        padding-top: 40.5rem !important;
    }

    .pt-sm-83 {
        padding-top: 41rem !important;
    }

    .pt-sm-84 {
        padding-top: 41.5rem !important;
    }

    .pt-sm-85 {
        padding-top: 42rem !important;
    }

    .pt-sm-86 {
        padding-top: 42.5rem !important;
    }

    .pt-sm-87 {
        padding-top: 43rem !important;
    }

    .pt-sm-88 {
        padding-top: 43.5rem !important;
    }

    .pt-sm-89 {
        padding-top: 44rem !important;
    }

    .pt-sm-90 {
        padding-top: 44.5rem !important;
    }

    .pt-sm-91 {
        padding-top: 45rem !important;
    }

    .pt-sm-92 {
        padding-top: 45.5rem !important;
    }

    .pt-sm-93 {
        padding-top: 46rem !important;
    }

    .pt-sm-94 {
        padding-top: 46.5rem !important;
    }

    .pt-sm-95 {
        padding-top: 47rem !important;
    }

    .pt-sm-96 {
        padding-top: 47.5rem !important;
    }

    .pt-sm-97 {
        padding-top: 48rem !important;
    }

    .pt-sm-98 {
        padding-top: 48.5rem !important;
    }

    .pt-sm-99 {
        padding-top: 49rem !important;
    }

    .pt-sm-100 {
        padding-top: 49.5rem !important;
    }

    .pt-sm-101 {
        padding-top: 50rem !important;
    }

    .pt-sm-102 {
        padding-top: 50.5rem !important;
    }

    .pt-sm-103 {
        padding-top: 51rem !important;
    }

    .pt-sm-104 {
        padding-top: 51.5rem !important;
    }

    .pt-sm-105 {
        padding-top: 52rem !important;
    }

    .pt-sm-106 {
        padding-top: 52.5rem !important;
    }

    .pt-sm-107 {
        padding-top: 53rem !important;
    }

    .pt-sm-108 {
        padding-top: 53.5rem !important;
    }

    .pt-sm-109 {
        padding-top: 54rem !important;
    }

    .pt-sm-110 {
        padding-top: 54.5rem !important;
    }

    .pt-sm-111 {
        padding-top: 55rem !important;
    }

    .pt-sm-112 {
        padding-top: 55.5rem !important;
    }

    .pt-sm-113 {
        padding-top: 56rem !important;
    }

    .pt-sm-114 {
        padding-top: 56.5rem !important;
    }

    .pt-sm-115 {
        padding-top: 57rem !important;
    }

    .pt-sm-116 {
        padding-top: 57.5rem !important;
    }

    .pt-sm-117 {
        padding-top: 58rem !important;
    }

    .pt-sm-118 {
        padding-top: 58.5rem !important;
    }

    .pt-sm-119 {
        padding-top: 59rem !important;
    }

    .pt-sm-120 {
        padding-top: 59.5rem !important;
    }

    .pt-sm-121 {
        padding-top: 60rem !important;
    }

    .pt-sm-122 {
        padding-top: 60.5rem !important;
    }

    .pt-sm-123 {
        padding-top: 61rem !important;
    }

    .pt-sm-124 {
        padding-top: 61.5rem !important;
    }

    .pt-sm-125 {
        padding-top: 62rem !important;
    }

    .pt-sm-126 {
        padding-top: 62.5rem !important;
    }

    .pt-sm-127 {
        padding-top: 63rem !important;
    }

    .pt-sm-128 {
        padding-top: 63.5rem !important;
    }

    .pt-sm-129 {
        padding-top: 64rem !important;
    }

    .pt-sm-130 {
        padding-top: 64.5rem !important;
    }

    .pt-sm-131 {
        padding-top: 65rem !important;
    }

    .pt-sm-132 {
        padding-top: 65.5rem !important;
    }

    .pt-sm-133 {
        padding-top: 66rem !important;
    }

    .pt-sm-134 {
        padding-top: 66.5rem !important;
    }

    .pt-sm-135 {
        padding-top: 67rem !important;
    }

    .pt-sm-136 {
        padding-top: 67.5rem !important;
    }

    .pt-sm-137 {
        padding-top: 68rem !important;
    }

    .pt-sm-138 {
        padding-top: 68.5rem !important;
    }

    .pt-sm-139 {
        padding-top: 69rem !important;
    }

    .pt-sm-140 {
        padding-top: 69.5rem !important;
    }

    .pt-sm-141 {
        padding-top: 70rem !important;
    }

    .pt-sm-142 {
        padding-top: 70.5rem !important;
    }

    .pt-sm-143 {
        padding-top: 71rem !important;
    }

    .pt-sm-144 {
        padding-top: 71.5rem !important;
    }

    .pt-sm-145 {
        padding-top: 72rem !important;
    }

    .pt-sm-146 {
        padding-top: 72.5rem !important;
    }

    .pt-sm-147 {
        padding-top: 73rem !important;
    }

    .pt-sm-148 {
        padding-top: 73.5rem !important;
    }

    .pt-sm-149 {
        padding-top: 74rem !important;
    }

    .pt-sm-150 {
        padding-top: 74.5rem !important;
    }

    .pt-sm-151 {
        padding-top: 75rem !important;
    }

    .pt-sm-152 {
        padding-top: 75.5rem !important;
    }

    .pt-sm-153 {
        padding-top: 76rem !important;
    }

    .pt-sm-154 {
        padding-top: 76.5rem !important;
    }

    .pt-sm-155 {
        padding-top: 77rem !important;
    }

    .pt-sm-156 {
        padding-top: 77.5rem !important;
    }

    .pt-sm-157 {
        padding-top: 78rem !important;
    }

    .pt-sm-158 {
        padding-top: 78.5rem !important;
    }

    .pt-sm-159 {
        padding-top: 79rem !important;
    }

    .pt-sm-160 {
        padding-top: 79.5rem !important;
    }

    .pb-sm-0 {
        padding-bottom: 0rem !important;
    }

    .pb-sm-1 {
        padding-bottom: 0.25rem !important;
    }

    .pb-sm-2 {
        padding-bottom: 0.5rem !important;
    }

    .pb-sm-3 {
        padding-bottom: 1rem !important;
    }

    .pb-sm-4 {
        padding-bottom: 1.5rem !important;
    }

    .pb-sm-5 {
        padding-bottom: 2rem !important;
    }

    .pb-sm-6 {
        padding-bottom: 2.5rem !important;
    }

    .pb-sm-7 {
        padding-bottom: 3rem !important;
    }

    .pb-sm-8 {
        padding-bottom: 3.5rem !important;
    }

    .pb-sm-9 {
        padding-bottom: 4rem !important;
    }

    .pb-sm-10 {
        padding-bottom: 4.5rem !important;
    }

    .pb-sm-11 {
        padding-bottom: 5rem !important;
    }

    .pb-sm-12 {
        padding-bottom: 5.5rem !important;
    }

    .pb-sm-13 {
        padding-bottom: 6rem !important;
    }

    .pb-sm-14 {
        padding-bottom: 6.5rem !important;
    }

    .pb-sm-15 {
        padding-bottom: 7rem !important;
    }

    .pb-sm-16 {
        padding-bottom: 7.5rem !important;
    }

    .pb-sm-17 {
        padding-bottom: 8rem !important;
    }

    .pb-sm-18 {
        padding-bottom: 8.5rem !important;
    }

    .pb-sm-19 {
        padding-bottom: 9rem !important;
    }

    .pb-sm-20 {
        padding-bottom: 9.5rem !important;
    }

    .pb-sm-21 {
        padding-bottom: 10rem !important;
    }

    .pb-sm-22 {
        padding-bottom: 10.5rem !important;
    }

    .pb-sm-23 {
        padding-bottom: 11rem !important;
    }

    .pb-sm-24 {
        padding-bottom: 11.5rem !important;
    }

    .pb-sm-25 {
        padding-bottom: 12rem !important;
    }

    .pb-sm-26 {
        padding-bottom: 12.5rem !important;
    }

    .pb-sm-27 {
        padding-bottom: 13rem !important;
    }

    .pb-sm-28 {
        padding-bottom: 13.5rem !important;
    }

    .pb-sm-29 {
        padding-bottom: 14rem !important;
    }

    .pb-sm-30 {
        padding-bottom: 14.5rem !important;
    }

    .pb-sm-31 {
        padding-bottom: 15rem !important;
    }

    .pb-sm-32 {
        padding-bottom: 15.5rem !important;
    }

    .pb-sm-33 {
        padding-bottom: 16rem !important;
    }

    .pb-sm-34 {
        padding-bottom: 16.5rem !important;
    }

    .pb-sm-35 {
        padding-bottom: 17rem !important;
    }

    .pb-sm-36 {
        padding-bottom: 17.5rem !important;
    }

    .pb-sm-37 {
        padding-bottom: 18rem !important;
    }

    .pb-sm-38 {
        padding-bottom: 18.5rem !important;
    }

    .pb-sm-39 {
        padding-bottom: 19rem !important;
    }

    .pb-sm-40 {
        padding-bottom: 19.5rem !important;
    }

    .pb-sm-41 {
        padding-bottom: 20rem !important;
    }

    .pb-sm-42 {
        padding-bottom: 20.5rem !important;
    }

    .pb-sm-43 {
        padding-bottom: 21rem !important;
    }

    .pb-sm-44 {
        padding-bottom: 21.5rem !important;
    }

    .pb-sm-45 {
        padding-bottom: 22rem !important;
    }

    .pb-sm-46 {
        padding-bottom: 22.5rem !important;
    }

    .pb-sm-47 {
        padding-bottom: 23rem !important;
    }

    .pb-sm-48 {
        padding-bottom: 23.5rem !important;
    }

    .pb-sm-49 {
        padding-bottom: 24rem !important;
    }

    .pb-sm-50 {
        padding-bottom: 24.5rem !important;
    }

    .pb-sm-51 {
        padding-bottom: 25rem !important;
    }

    .pb-sm-52 {
        padding-bottom: 25.5rem !important;
    }

    .pb-sm-53 {
        padding-bottom: 26rem !important;
    }

    .pb-sm-54 {
        padding-bottom: 26.5rem !important;
    }

    .pb-sm-55 {
        padding-bottom: 27rem !important;
    }

    .pb-sm-56 {
        padding-bottom: 27.5rem !important;
    }

    .pb-sm-57 {
        padding-bottom: 28rem !important;
    }

    .pb-sm-58 {
        padding-bottom: 28.5rem !important;
    }

    .pb-sm-59 {
        padding-bottom: 29rem !important;
    }

    .pb-sm-60 {
        padding-bottom: 29.5rem !important;
    }

    .pb-sm-61 {
        padding-bottom: 30rem !important;
    }

    .pb-sm-62 {
        padding-bottom: 30.5rem !important;
    }

    .pb-sm-63 {
        padding-bottom: 31rem !important;
    }

    .pb-sm-64 {
        padding-bottom: 31.5rem !important;
    }

    .pb-sm-65 {
        padding-bottom: 32rem !important;
    }

    .pb-sm-66 {
        padding-bottom: 32.5rem !important;
    }

    .pb-sm-67 {
        padding-bottom: 33rem !important;
    }

    .pb-sm-68 {
        padding-bottom: 33.5rem !important;
    }

    .pb-sm-69 {
        padding-bottom: 34rem !important;
    }

    .pb-sm-70 {
        padding-bottom: 34.5rem !important;
    }

    .pb-sm-71 {
        padding-bottom: 35rem !important;
    }

    .pb-sm-72 {
        padding-bottom: 35.5rem !important;
    }

    .pb-sm-73 {
        padding-bottom: 36rem !important;
    }

    .pb-sm-74 {
        padding-bottom: 36.5rem !important;
    }

    .pb-sm-75 {
        padding-bottom: 37rem !important;
    }

    .pb-sm-76 {
        padding-bottom: 37.5rem !important;
    }

    .pb-sm-77 {
        padding-bottom: 38rem !important;
    }

    .pb-sm-78 {
        padding-bottom: 38.5rem !important;
    }

    .pb-sm-79 {
        padding-bottom: 39rem !important;
    }

    .pb-sm-80 {
        padding-bottom: 39.5rem !important;
    }

    .pb-sm-81 {
        padding-bottom: 40rem !important;
    }

    .pb-sm-82 {
        padding-bottom: 40.5rem !important;
    }

    .pb-sm-83 {
        padding-bottom: 41rem !important;
    }

    .pb-sm-84 {
        padding-bottom: 41.5rem !important;
    }

    .pb-sm-85 {
        padding-bottom: 42rem !important;
    }

    .pb-sm-86 {
        padding-bottom: 42.5rem !important;
    }

    .pb-sm-87 {
        padding-bottom: 43rem !important;
    }

    .pb-sm-88 {
        padding-bottom: 43.5rem !important;
    }

    .pb-sm-89 {
        padding-bottom: 44rem !important;
    }

    .pb-sm-90 {
        padding-bottom: 44.5rem !important;
    }

    .pb-sm-91 {
        padding-bottom: 45rem !important;
    }

    .pb-sm-92 {
        padding-bottom: 45.5rem !important;
    }

    .pb-sm-93 {
        padding-bottom: 46rem !important;
    }

    .pb-sm-94 {
        padding-bottom: 46.5rem !important;
    }

    .pb-sm-95 {
        padding-bottom: 47rem !important;
    }

    .pb-sm-96 {
        padding-bottom: 47.5rem !important;
    }

    .pb-sm-97 {
        padding-bottom: 48rem !important;
    }

    .pb-sm-98 {
        padding-bottom: 48.5rem !important;
    }

    .pb-sm-99 {
        padding-bottom: 49rem !important;
    }

    .pb-sm-100 {
        padding-bottom: 49.5rem !important;
    }

    .pb-sm-101 {
        padding-bottom: 50rem !important;
    }

    .pb-sm-102 {
        padding-bottom: 50.5rem !important;
    }

    .pb-sm-103 {
        padding-bottom: 51rem !important;
    }

    .pb-sm-104 {
        padding-bottom: 51.5rem !important;
    }

    .pb-sm-105 {
        padding-bottom: 52rem !important;
    }

    .pb-sm-106 {
        padding-bottom: 52.5rem !important;
    }

    .pb-sm-107 {
        padding-bottom: 53rem !important;
    }

    .pb-sm-108 {
        padding-bottom: 53.5rem !important;
    }

    .pb-sm-109 {
        padding-bottom: 54rem !important;
    }

    .pb-sm-110 {
        padding-bottom: 54.5rem !important;
    }

    .pb-sm-111 {
        padding-bottom: 55rem !important;
    }

    .pb-sm-112 {
        padding-bottom: 55.5rem !important;
    }

    .pb-sm-113 {
        padding-bottom: 56rem !important;
    }

    .pb-sm-114 {
        padding-bottom: 56.5rem !important;
    }

    .pb-sm-115 {
        padding-bottom: 57rem !important;
    }

    .pb-sm-116 {
        padding-bottom: 57.5rem !important;
    }

    .pb-sm-117 {
        padding-bottom: 58rem !important;
    }

    .pb-sm-118 {
        padding-bottom: 58.5rem !important;
    }

    .pb-sm-119 {
        padding-bottom: 59rem !important;
    }

    .pb-sm-120 {
        padding-bottom: 59.5rem !important;
    }

    .pb-sm-121 {
        padding-bottom: 60rem !important;
    }

    .pb-sm-122 {
        padding-bottom: 60.5rem !important;
    }

    .pb-sm-123 {
        padding-bottom: 61rem !important;
    }

    .pb-sm-124 {
        padding-bottom: 61.5rem !important;
    }

    .pb-sm-125 {
        padding-bottom: 62rem !important;
    }

    .pb-sm-126 {
        padding-bottom: 62.5rem !important;
    }

    .pb-sm-127 {
        padding-bottom: 63rem !important;
    }

    .pb-sm-128 {
        padding-bottom: 63.5rem !important;
    }

    .pb-sm-129 {
        padding-bottom: 64rem !important;
    }

    .pb-sm-130 {
        padding-bottom: 64.5rem !important;
    }

    .pb-sm-131 {
        padding-bottom: 65rem !important;
    }

    .pb-sm-132 {
        padding-bottom: 65.5rem !important;
    }

    .pb-sm-133 {
        padding-bottom: 66rem !important;
    }

    .pb-sm-134 {
        padding-bottom: 66.5rem !important;
    }

    .pb-sm-135 {
        padding-bottom: 67rem !important;
    }

    .pb-sm-136 {
        padding-bottom: 67.5rem !important;
    }

    .pb-sm-137 {
        padding-bottom: 68rem !important;
    }

    .pb-sm-138 {
        padding-bottom: 68.5rem !important;
    }

    .pb-sm-139 {
        padding-bottom: 69rem !important;
    }

    .pb-sm-140 {
        padding-bottom: 69.5rem !important;
    }

    .pb-sm-141 {
        padding-bottom: 70rem !important;
    }

    .pb-sm-142 {
        padding-bottom: 70.5rem !important;
    }

    .pb-sm-143 {
        padding-bottom: 71rem !important;
    }

    .pb-sm-144 {
        padding-bottom: 71.5rem !important;
    }

    .pb-sm-145 {
        padding-bottom: 72rem !important;
    }

    .pb-sm-146 {
        padding-bottom: 72.5rem !important;
    }

    .pb-sm-147 {
        padding-bottom: 73rem !important;
    }

    .pb-sm-148 {
        padding-bottom: 73.5rem !important;
    }

    .pb-sm-149 {
        padding-bottom: 74rem !important;
    }

    .pb-sm-150 {
        padding-bottom: 74.5rem !important;
    }

    .pb-sm-151 {
        padding-bottom: 75rem !important;
    }

    .pb-sm-152 {
        padding-bottom: 75.5rem !important;
    }

    .pb-sm-153 {
        padding-bottom: 76rem !important;
    }

    .pb-sm-154 {
        padding-bottom: 76.5rem !important;
    }

    .pb-sm-155 {
        padding-bottom: 77rem !important;
    }

    .pb-sm-156 {
        padding-bottom: 77.5rem !important;
    }

    .pb-sm-157 {
        padding-bottom: 78rem !important;
    }

    .pb-sm-158 {
        padding-bottom: 78.5rem !important;
    }

    .pb-sm-159 {
        padding-bottom: 79rem !important;
    }

    .pb-sm-160 {
        padding-bottom: 79.5rem !important;
    }

    .ps-sm-0 {
        padding-left: 0rem !important;
    }

    .ps-sm-1 {
        padding-left: 0.25rem !important;
    }

    .ps-sm-2 {
        padding-left: 0.5rem !important;
    }

    .ps-sm-3 {
        padding-left: 1rem !important;
    }

    .ps-sm-4 {
        padding-left: 1.5rem !important;
    }

    .ps-sm-5 {
        padding-left: 2rem !important;
    }

    .ps-sm-6 {
        padding-left: 2.5rem !important;
    }

    .ps-sm-7 {
        padding-left: 3rem !important;
    }

    .ps-sm-8 {
        padding-left: 3.5rem !important;
    }

    .ps-sm-9 {
        padding-left: 4rem !important;
    }

    .ps-sm-10 {
        padding-left: 4.5rem !important;
    }

    .ps-sm-11 {
        padding-left: 5rem !important;
    }

    .ps-sm-12 {
        padding-left: 5.5rem !important;
    }

    .ps-sm-13 {
        padding-left: 6rem !important;
    }

    .ps-sm-14 {
        padding-left: 6.5rem !important;
    }

    .ps-sm-15 {
        padding-left: 7rem !important;
    }

    .ps-sm-16 {
        padding-left: 7.5rem !important;
    }

    .ps-sm-17 {
        padding-left: 8rem !important;
    }

    .ps-sm-18 {
        padding-left: 8.5rem !important;
    }

    .ps-sm-19 {
        padding-left: 9rem !important;
    }

    .ps-sm-20 {
        padding-left: 9.5rem !important;
    }

    .ps-sm-21 {
        padding-left: 10rem !important;
    }

    .ps-sm-22 {
        padding-left: 10.5rem !important;
    }

    .ps-sm-23 {
        padding-left: 11rem !important;
    }

    .ps-sm-24 {
        padding-left: 11.5rem !important;
    }

    .ps-sm-25 {
        padding-left: 12rem !important;
    }

    .ps-sm-26 {
        padding-left: 12.5rem !important;
    }

    .ps-sm-27 {
        padding-left: 13rem !important;
    }

    .ps-sm-28 {
        padding-left: 13.5rem !important;
    }

    .ps-sm-29 {
        padding-left: 14rem !important;
    }

    .ps-sm-30 {
        padding-left: 14.5rem !important;
    }

    .ps-sm-31 {
        padding-left: 15rem !important;
    }

    .ps-sm-32 {
        padding-left: 15.5rem !important;
    }

    .ps-sm-33 {
        padding-left: 16rem !important;
    }

    .ps-sm-34 {
        padding-left: 16.5rem !important;
    }

    .ps-sm-35 {
        padding-left: 17rem !important;
    }

    .ps-sm-36 {
        padding-left: 17.5rem !important;
    }

    .ps-sm-37 {
        padding-left: 18rem !important;
    }

    .ps-sm-38 {
        padding-left: 18.5rem !important;
    }

    .ps-sm-39 {
        padding-left: 19rem !important;
    }

    .ps-sm-40 {
        padding-left: 19.5rem !important;
    }

    .ps-sm-41 {
        padding-left: 20rem !important;
    }

    .ps-sm-42 {
        padding-left: 20.5rem !important;
    }

    .ps-sm-43 {
        padding-left: 21rem !important;
    }

    .ps-sm-44 {
        padding-left: 21.5rem !important;
    }

    .ps-sm-45 {
        padding-left: 22rem !important;
    }

    .ps-sm-46 {
        padding-left: 22.5rem !important;
    }

    .ps-sm-47 {
        padding-left: 23rem !important;
    }

    .ps-sm-48 {
        padding-left: 23.5rem !important;
    }

    .ps-sm-49 {
        padding-left: 24rem !important;
    }

    .ps-sm-50 {
        padding-left: 24.5rem !important;
    }

    .ps-sm-51 {
        padding-left: 25rem !important;
    }

    .ps-sm-52 {
        padding-left: 25.5rem !important;
    }

    .ps-sm-53 {
        padding-left: 26rem !important;
    }

    .ps-sm-54 {
        padding-left: 26.5rem !important;
    }

    .ps-sm-55 {
        padding-left: 27rem !important;
    }

    .ps-sm-56 {
        padding-left: 27.5rem !important;
    }

    .ps-sm-57 {
        padding-left: 28rem !important;
    }

    .ps-sm-58 {
        padding-left: 28.5rem !important;
    }

    .ps-sm-59 {
        padding-left: 29rem !important;
    }

    .ps-sm-60 {
        padding-left: 29.5rem !important;
    }

    .ps-sm-61 {
        padding-left: 30rem !important;
    }

    .ps-sm-62 {
        padding-left: 30.5rem !important;
    }

    .ps-sm-63 {
        padding-left: 31rem !important;
    }

    .ps-sm-64 {
        padding-left: 31.5rem !important;
    }

    .ps-sm-65 {
        padding-left: 32rem !important;
    }

    .ps-sm-66 {
        padding-left: 32.5rem !important;
    }

    .ps-sm-67 {
        padding-left: 33rem !important;
    }

    .ps-sm-68 {
        padding-left: 33.5rem !important;
    }

    .ps-sm-69 {
        padding-left: 34rem !important;
    }

    .ps-sm-70 {
        padding-left: 34.5rem !important;
    }

    .ps-sm-71 {
        padding-left: 35rem !important;
    }

    .ps-sm-72 {
        padding-left: 35.5rem !important;
    }

    .ps-sm-73 {
        padding-left: 36rem !important;
    }

    .ps-sm-74 {
        padding-left: 36.5rem !important;
    }

    .ps-sm-75 {
        padding-left: 37rem !important;
    }

    .ps-sm-76 {
        padding-left: 37.5rem !important;
    }

    .ps-sm-77 {
        padding-left: 38rem !important;
    }

    .ps-sm-78 {
        padding-left: 38.5rem !important;
    }

    .ps-sm-79 {
        padding-left: 39rem !important;
    }

    .ps-sm-80 {
        padding-left: 39.5rem !important;
    }

    .ps-sm-81 {
        padding-left: 40rem !important;
    }

    .ps-sm-82 {
        padding-left: 40.5rem !important;
    }

    .ps-sm-83 {
        padding-left: 41rem !important;
    }

    .ps-sm-84 {
        padding-left: 41.5rem !important;
    }

    .ps-sm-85 {
        padding-left: 42rem !important;
    }

    .ps-sm-86 {
        padding-left: 42.5rem !important;
    }

    .ps-sm-87 {
        padding-left: 43rem !important;
    }

    .ps-sm-88 {
        padding-left: 43.5rem !important;
    }

    .ps-sm-89 {
        padding-left: 44rem !important;
    }

    .ps-sm-90 {
        padding-left: 44.5rem !important;
    }

    .ps-sm-91 {
        padding-left: 45rem !important;
    }

    .ps-sm-92 {
        padding-left: 45.5rem !important;
    }

    .ps-sm-93 {
        padding-left: 46rem !important;
    }

    .ps-sm-94 {
        padding-left: 46.5rem !important;
    }

    .ps-sm-95 {
        padding-left: 47rem !important;
    }

    .ps-sm-96 {
        padding-left: 47.5rem !important;
    }

    .ps-sm-97 {
        padding-left: 48rem !important;
    }

    .ps-sm-98 {
        padding-left: 48.5rem !important;
    }

    .ps-sm-99 {
        padding-left: 49rem !important;
    }

    .ps-sm-100 {
        padding-left: 49.5rem !important;
    }

    .ps-sm-101 {
        padding-left: 50rem !important;
    }

    .ps-sm-102 {
        padding-left: 50.5rem !important;
    }

    .ps-sm-103 {
        padding-left: 51rem !important;
    }

    .ps-sm-104 {
        padding-left: 51.5rem !important;
    }

    .ps-sm-105 {
        padding-left: 52rem !important;
    }

    .ps-sm-106 {
        padding-left: 52.5rem !important;
    }

    .ps-sm-107 {
        padding-left: 53rem !important;
    }

    .ps-sm-108 {
        padding-left: 53.5rem !important;
    }

    .ps-sm-109 {
        padding-left: 54rem !important;
    }

    .ps-sm-110 {
        padding-left: 54.5rem !important;
    }

    .ps-sm-111 {
        padding-left: 55rem !important;
    }

    .ps-sm-112 {
        padding-left: 55.5rem !important;
    }

    .ps-sm-113 {
        padding-left: 56rem !important;
    }

    .ps-sm-114 {
        padding-left: 56.5rem !important;
    }

    .ps-sm-115 {
        padding-left: 57rem !important;
    }

    .ps-sm-116 {
        padding-left: 57.5rem !important;
    }

    .ps-sm-117 {
        padding-left: 58rem !important;
    }

    .ps-sm-118 {
        padding-left: 58.5rem !important;
    }

    .ps-sm-119 {
        padding-left: 59rem !important;
    }

    .ps-sm-120 {
        padding-left: 59.5rem !important;
    }

    .ps-sm-121 {
        padding-left: 60rem !important;
    }

    .ps-sm-122 {
        padding-left: 60.5rem !important;
    }

    .ps-sm-123 {
        padding-left: 61rem !important;
    }

    .ps-sm-124 {
        padding-left: 61.5rem !important;
    }

    .ps-sm-125 {
        padding-left: 62rem !important;
    }

    .ps-sm-126 {
        padding-left: 62.5rem !important;
    }

    .ps-sm-127 {
        padding-left: 63rem !important;
    }

    .ps-sm-128 {
        padding-left: 63.5rem !important;
    }

    .ps-sm-129 {
        padding-left: 64rem !important;
    }

    .ps-sm-130 {
        padding-left: 64.5rem !important;
    }

    .ps-sm-131 {
        padding-left: 65rem !important;
    }

    .ps-sm-132 {
        padding-left: 65.5rem !important;
    }

    .ps-sm-133 {
        padding-left: 66rem !important;
    }

    .ps-sm-134 {
        padding-left: 66.5rem !important;
    }

    .ps-sm-135 {
        padding-left: 67rem !important;
    }

    .ps-sm-136 {
        padding-left: 67.5rem !important;
    }

    .ps-sm-137 {
        padding-left: 68rem !important;
    }

    .ps-sm-138 {
        padding-left: 68.5rem !important;
    }

    .ps-sm-139 {
        padding-left: 69rem !important;
    }

    .ps-sm-140 {
        padding-left: 69.5rem !important;
    }

    .ps-sm-141 {
        padding-left: 70rem !important;
    }

    .ps-sm-142 {
        padding-left: 70.5rem !important;
    }

    .ps-sm-143 {
        padding-left: 71rem !important;
    }

    .ps-sm-144 {
        padding-left: 71.5rem !important;
    }

    .ps-sm-145 {
        padding-left: 72rem !important;
    }

    .ps-sm-146 {
        padding-left: 72.5rem !important;
    }

    .ps-sm-147 {
        padding-left: 73rem !important;
    }

    .ps-sm-148 {
        padding-left: 73.5rem !important;
    }

    .ps-sm-149 {
        padding-left: 74rem !important;
    }

    .ps-sm-150 {
        padding-left: 74.5rem !important;
    }

    .ps-sm-151 {
        padding-left: 75rem !important;
    }

    .ps-sm-152 {
        padding-left: 75.5rem !important;
    }

    .ps-sm-153 {
        padding-left: 76rem !important;
    }

    .ps-sm-154 {
        padding-left: 76.5rem !important;
    }

    .ps-sm-155 {
        padding-left: 77rem !important;
    }

    .ps-sm-156 {
        padding-left: 77.5rem !important;
    }

    .ps-sm-157 {
        padding-left: 78rem !important;
    }

    .ps-sm-158 {
        padding-left: 78.5rem !important;
    }

    .ps-sm-159 {
        padding-left: 79rem !important;
    }

    .ps-sm-160 {
        padding-left: 79.5rem !important;
    }

    .pe-sm-0 {
        padding-right: 0rem !important;
    }

    .pe-sm-1 {
        padding-right: 0.25rem !important;
    }

    .pe-sm-2 {
        padding-right: 0.5rem !important;
    }

    .pe-sm-3 {
        padding-right: 1rem !important;
    }

    .pe-sm-4 {
        padding-right: 1.5rem !important;
    }

    .pe-sm-5 {
        padding-right: 2rem !important;
    }

    .pe-sm-6 {
        padding-right: 2.5rem !important;
    }

    .pe-sm-7 {
        padding-right: 3rem !important;
    }

    .pe-sm-8 {
        padding-right: 3.5rem !important;
    }

    .pe-sm-9 {
        padding-right: 4rem !important;
    }

    .pe-sm-10 {
        padding-right: 4.5rem !important;
    }

    .pe-sm-11 {
        padding-right: 5rem !important;
    }

    .pe-sm-12 {
        padding-right: 5.5rem !important;
    }

    .pe-sm-13 {
        padding-right: 6rem !important;
    }

    .pe-sm-14 {
        padding-right: 6.5rem !important;
    }

    .pe-sm-15 {
        padding-right: 7rem !important;
    }

    .pe-sm-16 {
        padding-right: 7.5rem !important;
    }

    .pe-sm-17 {
        padding-right: 8rem !important;
    }

    .pe-sm-18 {
        padding-right: 8.5rem !important;
    }

    .pe-sm-19 {
        padding-right: 9rem !important;
    }

    .pe-sm-20 {
        padding-right: 9.5rem !important;
    }

    .pe-sm-21 {
        padding-right: 10rem !important;
    }

    .pe-sm-22 {
        padding-right: 10.5rem !important;
    }

    .pe-sm-23 {
        padding-right: 11rem !important;
    }

    .pe-sm-24 {
        padding-right: 11.5rem !important;
    }

    .pe-sm-25 {
        padding-right: 12rem !important;
    }

    .pe-sm-26 {
        padding-right: 12.5rem !important;
    }

    .pe-sm-27 {
        padding-right: 13rem !important;
    }

    .pe-sm-28 {
        padding-right: 13.5rem !important;
    }

    .pe-sm-29 {
        padding-right: 14rem !important;
    }

    .pe-sm-30 {
        padding-right: 14.5rem !important;
    }

    .pe-sm-31 {
        padding-right: 15rem !important;
    }

    .pe-sm-32 {
        padding-right: 15.5rem !important;
    }

    .pe-sm-33 {
        padding-right: 16rem !important;
    }

    .pe-sm-34 {
        padding-right: 16.5rem !important;
    }

    .pe-sm-35 {
        padding-right: 17rem !important;
    }

    .pe-sm-36 {
        padding-right: 17.5rem !important;
    }

    .pe-sm-37 {
        padding-right: 18rem !important;
    }

    .pe-sm-38 {
        padding-right: 18.5rem !important;
    }

    .pe-sm-39 {
        padding-right: 19rem !important;
    }

    .pe-sm-40 {
        padding-right: 19.5rem !important;
    }

    .pe-sm-41 {
        padding-right: 20rem !important;
    }

    .pe-sm-42 {
        padding-right: 20.5rem !important;
    }

    .pe-sm-43 {
        padding-right: 21rem !important;
    }

    .pe-sm-44 {
        padding-right: 21.5rem !important;
    }

    .pe-sm-45 {
        padding-right: 22rem !important;
    }

    .pe-sm-46 {
        padding-right: 22.5rem !important;
    }

    .pe-sm-47 {
        padding-right: 23rem !important;
    }

    .pe-sm-48 {
        padding-right: 23.5rem !important;
    }

    .pe-sm-49 {
        padding-right: 24rem !important;
    }

    .pe-sm-50 {
        padding-right: 24.5rem !important;
    }

    .pe-sm-51 {
        padding-right: 25rem !important;
    }

    .pe-sm-52 {
        padding-right: 25.5rem !important;
    }

    .pe-sm-53 {
        padding-right: 26rem !important;
    }

    .pe-sm-54 {
        padding-right: 26.5rem !important;
    }

    .pe-sm-55 {
        padding-right: 27rem !important;
    }

    .pe-sm-56 {
        padding-right: 27.5rem !important;
    }

    .pe-sm-57 {
        padding-right: 28rem !important;
    }

    .pe-sm-58 {
        padding-right: 28.5rem !important;
    }

    .pe-sm-59 {
        padding-right: 29rem !important;
    }

    .pe-sm-60 {
        padding-right: 29.5rem !important;
    }

    .pe-sm-61 {
        padding-right: 30rem !important;
    }

    .pe-sm-62 {
        padding-right: 30.5rem !important;
    }

    .pe-sm-63 {
        padding-right: 31rem !important;
    }

    .pe-sm-64 {
        padding-right: 31.5rem !important;
    }

    .pe-sm-65 {
        padding-right: 32rem !important;
    }

    .pe-sm-66 {
        padding-right: 32.5rem !important;
    }

    .pe-sm-67 {
        padding-right: 33rem !important;
    }

    .pe-sm-68 {
        padding-right: 33.5rem !important;
    }

    .pe-sm-69 {
        padding-right: 34rem !important;
    }

    .pe-sm-70 {
        padding-right: 34.5rem !important;
    }

    .pe-sm-71 {
        padding-right: 35rem !important;
    }

    .pe-sm-72 {
        padding-right: 35.5rem !important;
    }

    .pe-sm-73 {
        padding-right: 36rem !important;
    }

    .pe-sm-74 {
        padding-right: 36.5rem !important;
    }

    .pe-sm-75 {
        padding-right: 37rem !important;
    }

    .pe-sm-76 {
        padding-right: 37.5rem !important;
    }

    .pe-sm-77 {
        padding-right: 38rem !important;
    }

    .pe-sm-78 {
        padding-right: 38.5rem !important;
    }

    .pe-sm-79 {
        padding-right: 39rem !important;
    }

    .pe-sm-80 {
        padding-right: 39.5rem !important;
    }

    .pe-sm-81 {
        padding-right: 40rem !important;
    }

    .pe-sm-82 {
        padding-right: 40.5rem !important;
    }

    .pe-sm-83 {
        padding-right: 41rem !important;
    }

    .pe-sm-84 {
        padding-right: 41.5rem !important;
    }

    .pe-sm-85 {
        padding-right: 42rem !important;
    }

    .pe-sm-86 {
        padding-right: 42.5rem !important;
    }

    .pe-sm-87 {
        padding-right: 43rem !important;
    }

    .pe-sm-88 {
        padding-right: 43.5rem !important;
    }

    .pe-sm-89 {
        padding-right: 44rem !important;
    }

    .pe-sm-90 {
        padding-right: 44.5rem !important;
    }

    .pe-sm-91 {
        padding-right: 45rem !important;
    }

    .pe-sm-92 {
        padding-right: 45.5rem !important;
    }

    .pe-sm-93 {
        padding-right: 46rem !important;
    }

    .pe-sm-94 {
        padding-right: 46.5rem !important;
    }

    .pe-sm-95 {
        padding-right: 47rem !important;
    }

    .pe-sm-96 {
        padding-right: 47.5rem !important;
    }

    .pe-sm-97 {
        padding-right: 48rem !important;
    }

    .pe-sm-98 {
        padding-right: 48.5rem !important;
    }

    .pe-sm-99 {
        padding-right: 49rem !important;
    }

    .pe-sm-100 {
        padding-right: 49.5rem !important;
    }

    .pe-sm-101 {
        padding-right: 50rem !important;
    }

    .pe-sm-102 {
        padding-right: 50.5rem !important;
    }

    .pe-sm-103 {
        padding-right: 51rem !important;
    }

    .pe-sm-104 {
        padding-right: 51.5rem !important;
    }

    .pe-sm-105 {
        padding-right: 52rem !important;
    }

    .pe-sm-106 {
        padding-right: 52.5rem !important;
    }

    .pe-sm-107 {
        padding-right: 53rem !important;
    }

    .pe-sm-108 {
        padding-right: 53.5rem !important;
    }

    .pe-sm-109 {
        padding-right: 54rem !important;
    }

    .pe-sm-110 {
        padding-right: 54.5rem !important;
    }

    .pe-sm-111 {
        padding-right: 55rem !important;
    }

    .pe-sm-112 {
        padding-right: 55.5rem !important;
    }

    .pe-sm-113 {
        padding-right: 56rem !important;
    }

    .pe-sm-114 {
        padding-right: 56.5rem !important;
    }

    .pe-sm-115 {
        padding-right: 57rem !important;
    }

    .pe-sm-116 {
        padding-right: 57.5rem !important;
    }

    .pe-sm-117 {
        padding-right: 58rem !important;
    }

    .pe-sm-118 {
        padding-right: 58.5rem !important;
    }

    .pe-sm-119 {
        padding-right: 59rem !important;
    }

    .pe-sm-120 {
        padding-right: 59.5rem !important;
    }

    .pe-sm-121 {
        padding-right: 60rem !important;
    }

    .pe-sm-122 {
        padding-right: 60.5rem !important;
    }

    .pe-sm-123 {
        padding-right: 61rem !important;
    }

    .pe-sm-124 {
        padding-right: 61.5rem !important;
    }

    .pe-sm-125 {
        padding-right: 62rem !important;
    }

    .pe-sm-126 {
        padding-right: 62.5rem !important;
    }

    .pe-sm-127 {
        padding-right: 63rem !important;
    }

    .pe-sm-128 {
        padding-right: 63.5rem !important;
    }

    .pe-sm-129 {
        padding-right: 64rem !important;
    }

    .pe-sm-130 {
        padding-right: 64.5rem !important;
    }

    .pe-sm-131 {
        padding-right: 65rem !important;
    }

    .pe-sm-132 {
        padding-right: 65.5rem !important;
    }

    .pe-sm-133 {
        padding-right: 66rem !important;
    }

    .pe-sm-134 {
        padding-right: 66.5rem !important;
    }

    .pe-sm-135 {
        padding-right: 67rem !important;
    }

    .pe-sm-136 {
        padding-right: 67.5rem !important;
    }

    .pe-sm-137 {
        padding-right: 68rem !important;
    }

    .pe-sm-138 {
        padding-right: 68.5rem !important;
    }

    .pe-sm-139 {
        padding-right: 69rem !important;
    }

    .pe-sm-140 {
        padding-right: 69.5rem !important;
    }

    .pe-sm-141 {
        padding-right: 70rem !important;
    }

    .pe-sm-142 {
        padding-right: 70.5rem !important;
    }

    .pe-sm-143 {
        padding-right: 71rem !important;
    }

    .pe-sm-144 {
        padding-right: 71.5rem !important;
    }

    .pe-sm-145 {
        padding-right: 72rem !important;
    }

    .pe-sm-146 {
        padding-right: 72.5rem !important;
    }

    .pe-sm-147 {
        padding-right: 73rem !important;
    }

    .pe-sm-148 {
        padding-right: 73.5rem !important;
    }

    .pe-sm-149 {
        padding-right: 74rem !important;
    }

    .pe-sm-150 {
        padding-right: 74.5rem !important;
    }

    .pe-sm-151 {
        padding-right: 75rem !important;
    }

    .pe-sm-152 {
        padding-right: 75.5rem !important;
    }

    .pe-sm-153 {
        padding-right: 76rem !important;
    }

    .pe-sm-154 {
        padding-right: 76.5rem !important;
    }

    .pe-sm-155 {
        padding-right: 77rem !important;
    }

    .pe-sm-156 {
        padding-right: 77.5rem !important;
    }

    .pe-sm-157 {
        padding-right: 78rem !important;
    }

    .pe-sm-158 {
        padding-right: 78.5rem !important;
    }

    .pe-sm-159 {
        padding-right: 79rem !important;
    }

    .pe-sm-160 {
        padding-right: 79.5rem !important;
    }

    .px-sm-0 {
        padding-left: 0rem !important;
        padding-right: 0rem !important;
    }

    .px-sm-1 {
        padding-left: 0.25rem !important;
        padding-right: 0.25rem !important;
    }

    .px-sm-2 {
        padding-left: 0.5rem !important;
        padding-right: 0.5rem !important;
    }

    .px-sm-3 {
        padding-left: 1rem !important;
        padding-right: 1rem !important;
    }

    .px-sm-4 {
        padding-left: 1.5rem !important;
        padding-right: 1.5rem !important;
    }

    .px-sm-5 {
        padding-left: 2rem !important;
        padding-right: 2rem !important;
    }

    .px-sm-6 {
        padding-left: 2.5rem !important;
        padding-right: 2.5rem !important;
    }

    .px-sm-7 {
        padding-left: 3rem !important;
        padding-right: 3rem !important;
    }

    .px-sm-8 {
        padding-left: 3.5rem !important;
        padding-right: 3.5rem !important;
    }

    .px-sm-9 {
        padding-left: 4rem !important;
        padding-right: 4rem !important;
    }

    .px-sm-10 {
        padding-left: 4.5rem !important;
        padding-right: 4.5rem !important;
    }

    .px-sm-11 {
        padding-left: 5rem !important;
        padding-right: 5rem !important;
    }

    .px-sm-12 {
        padding-left: 5.5rem !important;
        padding-right: 5.5rem !important;
    }

    .px-sm-13 {
        padding-left: 6rem !important;
        padding-right: 6rem !important;
    }

    .px-sm-14 {
        padding-left: 6.5rem !important;
        padding-right: 6.5rem !important;
    }

    .px-sm-15 {
        padding-left: 7rem !important;
        padding-right: 7rem !important;
    }

    .px-sm-16 {
        padding-left: 7.5rem !important;
        padding-right: 7.5rem !important;
    }

    .px-sm-17 {
        padding-left: 8rem !important;
        padding-right: 8rem !important;
    }

    .px-sm-18 {
        padding-left: 8.5rem !important;
        padding-right: 8.5rem !important;
    }

    .px-sm-19 {
        padding-left: 9rem !important;
        padding-right: 9rem !important;
    }

    .px-sm-20 {
        padding-left: 9.5rem !important;
        padding-right: 9.5rem !important;
    }

    .px-sm-21 {
        padding-left: 10rem !important;
        padding-right: 10rem !important;
    }

    .px-sm-22 {
        padding-left: 10.5rem !important;
        padding-right: 10.5rem !important;
    }

    .px-sm-23 {
        padding-left: 11rem !important;
        padding-right: 11rem !important;
    }

    .px-sm-24 {
        padding-left: 11.5rem !important;
        padding-right: 11.5rem !important;
    }

    .px-sm-25 {
        padding-left: 12rem !important;
        padding-right: 12rem !important;
    }

    .px-sm-26 {
        padding-left: 12.5rem !important;
        padding-right: 12.5rem !important;
    }

    .px-sm-27 {
        padding-left: 13rem !important;
        padding-right: 13rem !important;
    }

    .px-sm-28 {
        padding-left: 13.5rem !important;
        padding-right: 13.5rem !important;
    }

    .px-sm-29 {
        padding-left: 14rem !important;
        padding-right: 14rem !important;
    }

    .px-sm-30 {
        padding-left: 14.5rem !important;
        padding-right: 14.5rem !important;
    }

    .px-sm-31 {
        padding-left: 15rem !important;
        padding-right: 15rem !important;
    }

    .px-sm-32 {
        padding-left: 15.5rem !important;
        padding-right: 15.5rem !important;
    }

    .px-sm-33 {
        padding-left: 16rem !important;
        padding-right: 16rem !important;
    }

    .px-sm-34 {
        padding-left: 16.5rem !important;
        padding-right: 16.5rem !important;
    }

    .px-sm-35 {
        padding-left: 17rem !important;
        padding-right: 17rem !important;
    }

    .px-sm-36 {
        padding-left: 17.5rem !important;
        padding-right: 17.5rem !important;
    }

    .px-sm-37 {
        padding-left: 18rem !important;
        padding-right: 18rem !important;
    }

    .px-sm-38 {
        padding-left: 18.5rem !important;
        padding-right: 18.5rem !important;
    }

    .px-sm-39 {
        padding-left: 19rem !important;
        padding-right: 19rem !important;
    }

    .px-sm-40 {
        padding-left: 19.5rem !important;
        padding-right: 19.5rem !important;
    }

    .px-sm-41 {
        padding-left: 20rem !important;
        padding-right: 20rem !important;
    }

    .px-sm-42 {
        padding-left: 20.5rem !important;
        padding-right: 20.5rem !important;
    }

    .px-sm-43 {
        padding-left: 21rem !important;
        padding-right: 21rem !important;
    }

    .px-sm-44 {
        padding-left: 21.5rem !important;
        padding-right: 21.5rem !important;
    }

    .px-sm-45 {
        padding-left: 22rem !important;
        padding-right: 22rem !important;
    }

    .px-sm-46 {
        padding-left: 22.5rem !important;
        padding-right: 22.5rem !important;
    }

    .px-sm-47 {
        padding-left: 23rem !important;
        padding-right: 23rem !important;
    }

    .px-sm-48 {
        padding-left: 23.5rem !important;
        padding-right: 23.5rem !important;
    }

    .px-sm-49 {
        padding-left: 24rem !important;
        padding-right: 24rem !important;
    }

    .px-sm-50 {
        padding-left: 24.5rem !important;
        padding-right: 24.5rem !important;
    }

    .px-sm-51 {
        padding-left: 25rem !important;
        padding-right: 25rem !important;
    }

    .px-sm-52 {
        padding-left: 25.5rem !important;
        padding-right: 25.5rem !important;
    }

    .px-sm-53 {
        padding-left: 26rem !important;
        padding-right: 26rem !important;
    }

    .px-sm-54 {
        padding-left: 26.5rem !important;
        padding-right: 26.5rem !important;
    }

    .px-sm-55 {
        padding-left: 27rem !important;
        padding-right: 27rem !important;
    }

    .px-sm-56 {
        padding-left: 27.5rem !important;
        padding-right: 27.5rem !important;
    }

    .px-sm-57 {
        padding-left: 28rem !important;
        padding-right: 28rem !important;
    }

    .px-sm-58 {
        padding-left: 28.5rem !important;
        padding-right: 28.5rem !important;
    }

    .px-sm-59 {
        padding-left: 29rem !important;
        padding-right: 29rem !important;
    }

    .px-sm-60 {
        padding-left: 29.5rem !important;
        padding-right: 29.5rem !important;
    }

    .px-sm-61 {
        padding-left: 30rem !important;
        padding-right: 30rem !important;
    }

    .px-sm-62 {
        padding-left: 30.5rem !important;
        padding-right: 30.5rem !important;
    }

    .px-sm-63 {
        padding-left: 31rem !important;
        padding-right: 31rem !important;
    }

    .px-sm-64 {
        padding-left: 31.5rem !important;
        padding-right: 31.5rem !important;
    }

    .px-sm-65 {
        padding-left: 32rem !important;
        padding-right: 32rem !important;
    }

    .px-sm-66 {
        padding-left: 32.5rem !important;
        padding-right: 32.5rem !important;
    }

    .px-sm-67 {
        padding-left: 33rem !important;
        padding-right: 33rem !important;
    }

    .px-sm-68 {
        padding-left: 33.5rem !important;
        padding-right: 33.5rem !important;
    }

    .px-sm-69 {
        padding-left: 34rem !important;
        padding-right: 34rem !important;
    }

    .px-sm-70 {
        padding-left: 34.5rem !important;
        padding-right: 34.5rem !important;
    }

    .px-sm-71 {
        padding-left: 35rem !important;
        padding-right: 35rem !important;
    }

    .px-sm-72 {
        padding-left: 35.5rem !important;
        padding-right: 35.5rem !important;
    }

    .px-sm-73 {
        padding-left: 36rem !important;
        padding-right: 36rem !important;
    }

    .px-sm-74 {
        padding-left: 36.5rem !important;
        padding-right: 36.5rem !important;
    }

    .px-sm-75 {
        padding-left: 37rem !important;
        padding-right: 37rem !important;
    }

    .px-sm-76 {
        padding-left: 37.5rem !important;
        padding-right: 37.5rem !important;
    }

    .px-sm-77 {
        padding-left: 38rem !important;
        padding-right: 38rem !important;
    }

    .px-sm-78 {
        padding-left: 38.5rem !important;
        padding-right: 38.5rem !important;
    }

    .px-sm-79 {
        padding-left: 39rem !important;
        padding-right: 39rem !important;
    }

    .px-sm-80 {
        padding-left: 39.5rem !important;
        padding-right: 39.5rem !important;
    }

    .px-sm-81 {
        padding-left: 40rem !important;
        padding-right: 40rem !important;
    }

    .px-sm-82 {
        padding-left: 40.5rem !important;
        padding-right: 40.5rem !important;
    }

    .px-sm-83 {
        padding-left: 41rem !important;
        padding-right: 41rem !important;
    }

    .px-sm-84 {
        padding-left: 41.5rem !important;
        padding-right: 41.5rem !important;
    }

    .px-sm-85 {
        padding-left: 42rem !important;
        padding-right: 42rem !important;
    }

    .px-sm-86 {
        padding-left: 42.5rem !important;
        padding-right: 42.5rem !important;
    }

    .px-sm-87 {
        padding-left: 43rem !important;
        padding-right: 43rem !important;
    }

    .px-sm-88 {
        padding-left: 43.5rem !important;
        padding-right: 43.5rem !important;
    }

    .px-sm-89 {
        padding-left: 44rem !important;
        padding-right: 44rem !important;
    }

    .px-sm-90 {
        padding-left: 44.5rem !important;
        padding-right: 44.5rem !important;
    }

    .px-sm-91 {
        padding-left: 45rem !important;
        padding-right: 45rem !important;
    }

    .px-sm-92 {
        padding-left: 45.5rem !important;
        padding-right: 45.5rem !important;
    }

    .px-sm-93 {
        padding-left: 46rem !important;
        padding-right: 46rem !important;
    }

    .px-sm-94 {
        padding-left: 46.5rem !important;
        padding-right: 46.5rem !important;
    }

    .px-sm-95 {
        padding-left: 47rem !important;
        padding-right: 47rem !important;
    }

    .px-sm-96 {
        padding-left: 47.5rem !important;
        padding-right: 47.5rem !important;
    }

    .px-sm-97 {
        padding-left: 48rem !important;
        padding-right: 48rem !important;
    }

    .px-sm-98 {
        padding-left: 48.5rem !important;
        padding-right: 48.5rem !important;
    }

    .px-sm-99 {
        padding-left: 49rem !important;
        padding-right: 49rem !important;
    }

    .px-sm-100 {
        padding-left: 49.5rem !important;
        padding-right: 49.5rem !important;
    }

    .px-sm-101 {
        padding-left: 50rem !important;
        padding-right: 50rem !important;
    }

    .px-sm-102 {
        padding-left: 50.5rem !important;
        padding-right: 50.5rem !important;
    }

    .px-sm-103 {
        padding-left: 51rem !important;
        padding-right: 51rem !important;
    }

    .px-sm-104 {
        padding-left: 51.5rem !important;
        padding-right: 51.5rem !important;
    }

    .px-sm-105 {
        padding-left: 52rem !important;
        padding-right: 52rem !important;
    }

    .px-sm-106 {
        padding-left: 52.5rem !important;
        padding-right: 52.5rem !important;
    }

    .px-sm-107 {
        padding-left: 53rem !important;
        padding-right: 53rem !important;
    }

    .px-sm-108 {
        padding-left: 53.5rem !important;
        padding-right: 53.5rem !important;
    }

    .px-sm-109 {
        padding-left: 54rem !important;
        padding-right: 54rem !important;
    }

    .px-sm-110 {
        padding-left: 54.5rem !important;
        padding-right: 54.5rem !important;
    }

    .px-sm-111 {
        padding-left: 55rem !important;
        padding-right: 55rem !important;
    }

    .px-sm-112 {
        padding-left: 55.5rem !important;
        padding-right: 55.5rem !important;
    }

    .px-sm-113 {
        padding-left: 56rem !important;
        padding-right: 56rem !important;
    }

    .px-sm-114 {
        padding-left: 56.5rem !important;
        padding-right: 56.5rem !important;
    }

    .px-sm-115 {
        padding-left: 57rem !important;
        padding-right: 57rem !important;
    }

    .px-sm-116 {
        padding-left: 57.5rem !important;
        padding-right: 57.5rem !important;
    }

    .px-sm-117 {
        padding-left: 58rem !important;
        padding-right: 58rem !important;
    }

    .px-sm-118 {
        padding-left: 58.5rem !important;
        padding-right: 58.5rem !important;
    }

    .px-sm-119 {
        padding-left: 59rem !important;
        padding-right: 59rem !important;
    }

    .px-sm-120 {
        padding-left: 59.5rem !important;
        padding-right: 59.5rem !important;
    }

    .px-sm-121 {
        padding-left: 60rem !important;
        padding-right: 60rem !important;
    }

    .px-sm-122 {
        padding-left: 60.5rem !important;
        padding-right: 60.5rem !important;
    }

    .px-sm-123 {
        padding-left: 61rem !important;
        padding-right: 61rem !important;
    }

    .px-sm-124 {
        padding-left: 61.5rem !important;
        padding-right: 61.5rem !important;
    }

    .px-sm-125 {
        padding-left: 62rem !important;
        padding-right: 62rem !important;
    }

    .px-sm-126 {
        padding-left: 62.5rem !important;
        padding-right: 62.5rem !important;
    }

    .px-sm-127 {
        padding-left: 63rem !important;
        padding-right: 63rem !important;
    }

    .px-sm-128 {
        padding-left: 63.5rem !important;
        padding-right: 63.5rem !important;
    }

    .px-sm-129 {
        padding-left: 64rem !important;
        padding-right: 64rem !important;
    }

    .px-sm-130 {
        padding-left: 64.5rem !important;
        padding-right: 64.5rem !important;
    }

    .px-sm-131 {
        padding-left: 65rem !important;
        padding-right: 65rem !important;
    }

    .px-sm-132 {
        padding-left: 65.5rem !important;
        padding-right: 65.5rem !important;
    }

    .px-sm-133 {
        padding-left: 66rem !important;
        padding-right: 66rem !important;
    }

    .px-sm-134 {
        padding-left: 66.5rem !important;
        padding-right: 66.5rem !important;
    }

    .px-sm-135 {
        padding-left: 67rem !important;
        padding-right: 67rem !important;
    }

    .px-sm-136 {
        padding-left: 67.5rem !important;
        padding-right: 67.5rem !important;
    }

    .px-sm-137 {
        padding-left: 68rem !important;
        padding-right: 68rem !important;
    }

    .px-sm-138 {
        padding-left: 68.5rem !important;
        padding-right: 68.5rem !important;
    }

    .px-sm-139 {
        padding-left: 69rem !important;
        padding-right: 69rem !important;
    }

    .px-sm-140 {
        padding-left: 69.5rem !important;
        padding-right: 69.5rem !important;
    }

    .px-sm-141 {
        padding-left: 70rem !important;
        padding-right: 70rem !important;
    }

    .px-sm-142 {
        padding-left: 70.5rem !important;
        padding-right: 70.5rem !important;
    }

    .px-sm-143 {
        padding-left: 71rem !important;
        padding-right: 71rem !important;
    }

    .px-sm-144 {
        padding-left: 71.5rem !important;
        padding-right: 71.5rem !important;
    }

    .px-sm-145 {
        padding-left: 72rem !important;
        padding-right: 72rem !important;
    }

    .px-sm-146 {
        padding-left: 72.5rem !important;
        padding-right: 72.5rem !important;
    }

    .px-sm-147 {
        padding-left: 73rem !important;
        padding-right: 73rem !important;
    }

    .px-sm-148 {
        padding-left: 73.5rem !important;
        padding-right: 73.5rem !important;
    }

    .px-sm-149 {
        padding-left: 74rem !important;
        padding-right: 74rem !important;
    }

    .px-sm-150 {
        padding-left: 74.5rem !important;
        padding-right: 74.5rem !important;
    }

    .px-sm-151 {
        padding-left: 75rem !important;
        padding-right: 75rem !important;
    }

    .px-sm-152 {
        padding-left: 75.5rem !important;
        padding-right: 75.5rem !important;
    }

    .px-sm-153 {
        padding-left: 76rem !important;
        padding-right: 76rem !important;
    }

    .px-sm-154 {
        padding-left: 76.5rem !important;
        padding-right: 76.5rem !important;
    }

    .px-sm-155 {
        padding-left: 77rem !important;
        padding-right: 77rem !important;
    }

    .px-sm-156 {
        padding-left: 77.5rem !important;
        padding-right: 77.5rem !important;
    }

    .px-sm-157 {
        padding-left: 78rem !important;
        padding-right: 78rem !important;
    }

    .px-sm-158 {
        padding-left: 78.5rem !important;
        padding-right: 78.5rem !important;
    }

    .px-sm-159 {
        padding-left: 79rem !important;
        padding-right: 79rem !important;
    }

    .px-sm-160 {
        padding-left: 79.5rem !important;
        padding-right: 79.5rem !important;
    }

    .py-sm-0 {
        padding-top: 0rem !important;
        padding-bottom: 0rem !important;
    }

    .py-sm-1 {
        padding-top: 0.25rem !important;
        padding-bottom: 0.25rem !important;
    }

    .py-sm-2 {
        padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;
    }

    .py-sm-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
    }

    .py-sm-4 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important;
    }

    .py-sm-5 {
        padding-top: 2rem !important;
        padding-bottom: 2rem !important;
    }

    .py-sm-6 {
        padding-top: 2.5rem !important;
        padding-bottom: 2.5rem !important;
    }

    .py-sm-7 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important;
    }

    .py-sm-8 {
        padding-top: 3.5rem !important;
        padding-bottom: 3.5rem !important;
    }

    .py-sm-9 {
        padding-top: 4rem !important;
        padding-bottom: 4rem !important;
    }

    .py-sm-10 {
        padding-top: 4.5rem !important;
        padding-bottom: 4.5rem !important;
    }

    .py-sm-11 {
        padding-top: 5rem !important;
        padding-bottom: 5rem !important;
    }

    .py-sm-12 {
        padding-top: 5.5rem !important;
        padding-bottom: 5.5rem !important;
    }

    .py-sm-13 {
        padding-top: 6rem !important;
        padding-bottom: 6rem !important;
    }

    .py-sm-14 {
        padding-top: 6.5rem !important;
        padding-bottom: 6.5rem !important;
    }

    .py-sm-15 {
        padding-top: 7rem !important;
        padding-bottom: 7rem !important;
    }

    .py-sm-16 {
        padding-top: 7.5rem !important;
        padding-bottom: 7.5rem !important;
    }

    .py-sm-17 {
        padding-top: 8rem !important;
        padding-bottom: 8rem !important;
    }

    .py-sm-18 {
        padding-top: 8.5rem !important;
        padding-bottom: 8.5rem !important;
    }

    .py-sm-19 {
        padding-top: 9rem !important;
        padding-bottom: 9rem !important;
    }

    .py-sm-20 {
        padding-top: 9.5rem !important;
        padding-bottom: 9.5rem !important;
    }

    .py-sm-21 {
        padding-top: 10rem !important;
        padding-bottom: 10rem !important;
    }

    .py-sm-22 {
        padding-top: 10.5rem !important;
        padding-bottom: 10.5rem !important;
    }

    .py-sm-23 {
        padding-top: 11rem !important;
        padding-bottom: 11rem !important;
    }

    .py-sm-24 {
        padding-top: 11.5rem !important;
        padding-bottom: 11.5rem !important;
    }

    .py-sm-25 {
        padding-top: 12rem !important;
        padding-bottom: 12rem !important;
    }

    .py-sm-26 {
        padding-top: 12.5rem !important;
        padding-bottom: 12.5rem !important;
    }

    .py-sm-27 {
        padding-top: 13rem !important;
        padding-bottom: 13rem !important;
    }

    .py-sm-28 {
        padding-top: 13.5rem !important;
        padding-bottom: 13.5rem !important;
    }

    .py-sm-29 {
        padding-top: 14rem !important;
        padding-bottom: 14rem !important;
    }

    .py-sm-30 {
        padding-top: 14.5rem !important;
        padding-bottom: 14.5rem !important;
    }

    .py-sm-31 {
        padding-top: 15rem !important;
        padding-bottom: 15rem !important;
    }

    .py-sm-32 {
        padding-top: 15.5rem !important;
        padding-bottom: 15.5rem !important;
    }

    .py-sm-33 {
        padding-top: 16rem !important;
        padding-bottom: 16rem !important;
    }

    .py-sm-34 {
        padding-top: 16.5rem !important;
        padding-bottom: 16.5rem !important;
    }

    .py-sm-35 {
        padding-top: 17rem !important;
        padding-bottom: 17rem !important;
    }

    .py-sm-36 {
        padding-top: 17.5rem !important;
        padding-bottom: 17.5rem !important;
    }

    .py-sm-37 {
        padding-top: 18rem !important;
        padding-bottom: 18rem !important;
    }

    .py-sm-38 {
        padding-top: 18.5rem !important;
        padding-bottom: 18.5rem !important;
    }

    .py-sm-39 {
        padding-top: 19rem !important;
        padding-bottom: 19rem !important;
    }

    .py-sm-40 {
        padding-top: 19.5rem !important;
        padding-bottom: 19.5rem !important;
    }

    .py-sm-41 {
        padding-top: 20rem !important;
        padding-bottom: 20rem !important;
    }

    .py-sm-42 {
        padding-top: 20.5rem !important;
        padding-bottom: 20.5rem !important;
    }

    .py-sm-43 {
        padding-top: 21rem !important;
        padding-bottom: 21rem !important;
    }

    .py-sm-44 {
        padding-top: 21.5rem !important;
        padding-bottom: 21.5rem !important;
    }

    .py-sm-45 {
        padding-top: 22rem !important;
        padding-bottom: 22rem !important;
    }

    .py-sm-46 {
        padding-top: 22.5rem !important;
        padding-bottom: 22.5rem !important;
    }

    .py-sm-47 {
        padding-top: 23rem !important;
        padding-bottom: 23rem !important;
    }

    .py-sm-48 {
        padding-top: 23.5rem !important;
        padding-bottom: 23.5rem !important;
    }

    .py-sm-49 {
        padding-top: 24rem !important;
        padding-bottom: 24rem !important;
    }

    .py-sm-50 {
        padding-top: 24.5rem !important;
        padding-bottom: 24.5rem !important;
    }

    .py-sm-51 {
        padding-top: 25rem !important;
        padding-bottom: 25rem !important;
    }

    .py-sm-52 {
        padding-top: 25.5rem !important;
        padding-bottom: 25.5rem !important;
    }

    .py-sm-53 {
        padding-top: 26rem !important;
        padding-bottom: 26rem !important;
    }

    .py-sm-54 {
        padding-top: 26.5rem !important;
        padding-bottom: 26.5rem !important;
    }

    .py-sm-55 {
        padding-top: 27rem !important;
        padding-bottom: 27rem !important;
    }

    .py-sm-56 {
        padding-top: 27.5rem !important;
        padding-bottom: 27.5rem !important;
    }

    .py-sm-57 {
        padding-top: 28rem !important;
        padding-bottom: 28rem !important;
    }

    .py-sm-58 {
        padding-top: 28.5rem !important;
        padding-bottom: 28.5rem !important;
    }

    .py-sm-59 {
        padding-top: 29rem !important;
        padding-bottom: 29rem !important;
    }

    .py-sm-60 {
        padding-top: 29.5rem !important;
        padding-bottom: 29.5rem !important;
    }

    .py-sm-61 {
        padding-top: 30rem !important;
        padding-bottom: 30rem !important;
    }

    .py-sm-62 {
        padding-top: 30.5rem !important;
        padding-bottom: 30.5rem !important;
    }

    .py-sm-63 {
        padding-top: 31rem !important;
        padding-bottom: 31rem !important;
    }

    .py-sm-64 {
        padding-top: 31.5rem !important;
        padding-bottom: 31.5rem !important;
    }

    .py-sm-65 {
        padding-top: 32rem !important;
        padding-bottom: 32rem !important;
    }

    .py-sm-66 {
        padding-top: 32.5rem !important;
        padding-bottom: 32.5rem !important;
    }

    .py-sm-67 {
        padding-top: 33rem !important;
        padding-bottom: 33rem !important;
    }

    .py-sm-68 {
        padding-top: 33.5rem !important;
        padding-bottom: 33.5rem !important;
    }

    .py-sm-69 {
        padding-top: 34rem !important;
        padding-bottom: 34rem !important;
    }

    .py-sm-70 {
        padding-top: 34.5rem !important;
        padding-bottom: 34.5rem !important;
    }

    .py-sm-71 {
        padding-top: 35rem !important;
        padding-bottom: 35rem !important;
    }

    .py-sm-72 {
        padding-top: 35.5rem !important;
        padding-bottom: 35.5rem !important;
    }

    .py-sm-73 {
        padding-top: 36rem !important;
        padding-bottom: 36rem !important;
    }

    .py-sm-74 {
        padding-top: 36.5rem !important;
        padding-bottom: 36.5rem !important;
    }

    .py-sm-75 {
        padding-top: 37rem !important;
        padding-bottom: 37rem !important;
    }

    .py-sm-76 {
        padding-top: 37.5rem !important;
        padding-bottom: 37.5rem !important;
    }

    .py-sm-77 {
        padding-top: 38rem !important;
        padding-bottom: 38rem !important;
    }

    .py-sm-78 {
        padding-top: 38.5rem !important;
        padding-bottom: 38.5rem !important;
    }

    .py-sm-79 {
        padding-top: 39rem !important;
        padding-bottom: 39rem !important;
    }

    .py-sm-80 {
        padding-top: 39.5rem !important;
        padding-bottom: 39.5rem !important;
    }

    .py-sm-81 {
        padding-top: 40rem !important;
        padding-bottom: 40rem !important;
    }

    .py-sm-82 {
        padding-top: 40.5rem !important;
        padding-bottom: 40.5rem !important;
    }

    .py-sm-83 {
        padding-top: 41rem !important;
        padding-bottom: 41rem !important;
    }

    .py-sm-84 {
        padding-top: 41.5rem !important;
        padding-bottom: 41.5rem !important;
    }

    .py-sm-85 {
        padding-top: 42rem !important;
        padding-bottom: 42rem !important;
    }

    .py-sm-86 {
        padding-top: 42.5rem !important;
        padding-bottom: 42.5rem !important;
    }

    .py-sm-87 {
        padding-top: 43rem !important;
        padding-bottom: 43rem !important;
    }

    .py-sm-88 {
        padding-top: 43.5rem !important;
        padding-bottom: 43.5rem !important;
    }

    .py-sm-89 {
        padding-top: 44rem !important;
        padding-bottom: 44rem !important;
    }

    .py-sm-90 {
        padding-top: 44.5rem !important;
        padding-bottom: 44.5rem !important;
    }

    .py-sm-91 {
        padding-top: 45rem !important;
        padding-bottom: 45rem !important;
    }

    .py-sm-92 {
        padding-top: 45.5rem !important;
        padding-bottom: 45.5rem !important;
    }

    .py-sm-93 {
        padding-top: 46rem !important;
        padding-bottom: 46rem !important;
    }

    .py-sm-94 {
        padding-top: 46.5rem !important;
        padding-bottom: 46.5rem !important;
    }

    .py-sm-95 {
        padding-top: 47rem !important;
        padding-bottom: 47rem !important;
    }

    .py-sm-96 {
        padding-top: 47.5rem !important;
        padding-bottom: 47.5rem !important;
    }

    .py-sm-97 {
        padding-top: 48rem !important;
        padding-bottom: 48rem !important;
    }

    .py-sm-98 {
        padding-top: 48.5rem !important;
        padding-bottom: 48.5rem !important;
    }

    .py-sm-99 {
        padding-top: 49rem !important;
        padding-bottom: 49rem !important;
    }

    .py-sm-100 {
        padding-top: 49.5rem !important;
        padding-bottom: 49.5rem !important;
    }

    .py-sm-101 {
        padding-top: 50rem !important;
        padding-bottom: 50rem !important;
    }

    .py-sm-102 {
        padding-top: 50.5rem !important;
        padding-bottom: 50.5rem !important;
    }

    .py-sm-103 {
        padding-top: 51rem !important;
        padding-bottom: 51rem !important;
    }

    .py-sm-104 {
        padding-top: 51.5rem !important;
        padding-bottom: 51.5rem !important;
    }

    .py-sm-105 {
        padding-top: 52rem !important;
        padding-bottom: 52rem !important;
    }

    .py-sm-106 {
        padding-top: 52.5rem !important;
        padding-bottom: 52.5rem !important;
    }

    .py-sm-107 {
        padding-top: 53rem !important;
        padding-bottom: 53rem !important;
    }

    .py-sm-108 {
        padding-top: 53.5rem !important;
        padding-bottom: 53.5rem !important;
    }

    .py-sm-109 {
        padding-top: 54rem !important;
        padding-bottom: 54rem !important;
    }

    .py-sm-110 {
        padding-top: 54.5rem !important;
        padding-bottom: 54.5rem !important;
    }

    .py-sm-111 {
        padding-top: 55rem !important;
        padding-bottom: 55rem !important;
    }

    .py-sm-112 {
        padding-top: 55.5rem !important;
        padding-bottom: 55.5rem !important;
    }

    .py-sm-113 {
        padding-top: 56rem !important;
        padding-bottom: 56rem !important;
    }

    .py-sm-114 {
        padding-top: 56.5rem !important;
        padding-bottom: 56.5rem !important;
    }

    .py-sm-115 {
        padding-top: 57rem !important;
        padding-bottom: 57rem !important;
    }

    .py-sm-116 {
        padding-top: 57.5rem !important;
        padding-bottom: 57.5rem !important;
    }

    .py-sm-117 {
        padding-top: 58rem !important;
        padding-bottom: 58rem !important;
    }

    .py-sm-118 {
        padding-top: 58.5rem !important;
        padding-bottom: 58.5rem !important;
    }

    .py-sm-119 {
        padding-top: 59rem !important;
        padding-bottom: 59rem !important;
    }

    .py-sm-120 {
        padding-top: 59.5rem !important;
        padding-bottom: 59.5rem !important;
    }

    .py-sm-121 {
        padding-top: 60rem !important;
        padding-bottom: 60rem !important;
    }

    .py-sm-122 {
        padding-top: 60.5rem !important;
        padding-bottom: 60.5rem !important;
    }

    .py-sm-123 {
        padding-top: 61rem !important;
        padding-bottom: 61rem !important;
    }

    .py-sm-124 {
        padding-top: 61.5rem !important;
        padding-bottom: 61.5rem !important;
    }

    .py-sm-125 {
        padding-top: 62rem !important;
        padding-bottom: 62rem !important;
    }

    .py-sm-126 {
        padding-top: 62.5rem !important;
        padding-bottom: 62.5rem !important;
    }

    .py-sm-127 {
        padding-top: 63rem !important;
        padding-bottom: 63rem !important;
    }

    .py-sm-128 {
        padding-top: 63.5rem !important;
        padding-bottom: 63.5rem !important;
    }

    .py-sm-129 {
        padding-top: 64rem !important;
        padding-bottom: 64rem !important;
    }

    .py-sm-130 {
        padding-top: 64.5rem !important;
        padding-bottom: 64.5rem !important;
    }

    .py-sm-131 {
        padding-top: 65rem !important;
        padding-bottom: 65rem !important;
    }

    .py-sm-132 {
        padding-top: 65.5rem !important;
        padding-bottom: 65.5rem !important;
    }

    .py-sm-133 {
        padding-top: 66rem !important;
        padding-bottom: 66rem !important;
    }

    .py-sm-134 {
        padding-top: 66.5rem !important;
        padding-bottom: 66.5rem !important;
    }

    .py-sm-135 {
        padding-top: 67rem !important;
        padding-bottom: 67rem !important;
    }

    .py-sm-136 {
        padding-top: 67.5rem !important;
        padding-bottom: 67.5rem !important;
    }

    .py-sm-137 {
        padding-top: 68rem !important;
        padding-bottom: 68rem !important;
    }

    .py-sm-138 {
        padding-top: 68.5rem !important;
        padding-bottom: 68.5rem !important;
    }

    .py-sm-139 {
        padding-top: 69rem !important;
        padding-bottom: 69rem !important;
    }

    .py-sm-140 {
        padding-top: 69.5rem !important;
        padding-bottom: 69.5rem !important;
    }

    .py-sm-141 {
        padding-top: 70rem !important;
        padding-bottom: 70rem !important;
    }

    .py-sm-142 {
        padding-top: 70.5rem !important;
        padding-bottom: 70.5rem !important;
    }

    .py-sm-143 {
        padding-top: 71rem !important;
        padding-bottom: 71rem !important;
    }

    .py-sm-144 {
        padding-top: 71.5rem !important;
        padding-bottom: 71.5rem !important;
    }

    .py-sm-145 {
        padding-top: 72rem !important;
        padding-bottom: 72rem !important;
    }

    .py-sm-146 {
        padding-top: 72.5rem !important;
        padding-bottom: 72.5rem !important;
    }

    .py-sm-147 {
        padding-top: 73rem !important;
        padding-bottom: 73rem !important;
    }

    .py-sm-148 {
        padding-top: 73.5rem !important;
        padding-bottom: 73.5rem !important;
    }

    .py-sm-149 {
        padding-top: 74rem !important;
        padding-bottom: 74rem !important;
    }

    .py-sm-150 {
        padding-top: 74.5rem !important;
        padding-bottom: 74.5rem !important;
    }

    .py-sm-151 {
        padding-top: 75rem !important;
        padding-bottom: 75rem !important;
    }

    .py-sm-152 {
        padding-top: 75.5rem !important;
        padding-bottom: 75.5rem !important;
    }

    .py-sm-153 {
        padding-top: 76rem !important;
        padding-bottom: 76rem !important;
    }

    .py-sm-154 {
        padding-top: 76.5rem !important;
        padding-bottom: 76.5rem !important;
    }

    .py-sm-155 {
        padding-top: 77rem !important;
        padding-bottom: 77rem !important;
    }

    .py-sm-156 {
        padding-top: 77.5rem !important;
        padding-bottom: 77.5rem !important;
    }

    .py-sm-157 {
        padding-top: 78rem !important;
        padding-bottom: 78rem !important;
    }

    .py-sm-158 {
        padding-top: 78.5rem !important;
        padding-bottom: 78.5rem !important;
    }

    .py-sm-159 {
        padding-top: 79rem !important;
        padding-bottom: 79rem !important;
    }

    .py-sm-160 {
        padding-top: 79.5rem !important;
        padding-bottom: 79.5rem !important;
    }
}

@media (min-width: 768px) {
    .p-md-0 {
        padding: 0rem !important;
    }

    .p-md-1 {
        padding: 0.25rem !important;
    }

    .p-md-2 {
        padding: 0.5rem !important;
    }

    .p-md-3 {
        padding: 1rem !important;
    }

    .p-md-4 {
        padding: 1.5rem !important;
    }

    .p-md-5 {
        padding: 2rem !important;
    }

    .p-md-6 {
        padding: 2.5rem !important;
    }

    .p-md-7 {
        padding: 3rem !important;
    }

    .p-md-8 {
        padding: 3.5rem !important;
    }

    .p-md-9 {
        padding: 4rem !important;
    }

    .p-md-10 {
        padding: 4.5rem !important;
    }

    .p-md-11 {
        padding: 5rem !important;
    }

    .p-md-12 {
        padding: 5.5rem !important;
    }

    .p-md-13 {
        padding: 6rem !important;
    }

    .p-md-14 {
        padding: 6.5rem !important;
    }

    .p-md-15 {
        padding: 7rem !important;
    }

    .p-md-16 {
        padding: 7.5rem !important;
    }

    .p-md-17 {
        padding: 8rem !important;
    }

    .p-md-18 {
        padding: 8.5rem !important;
    }

    .p-md-19 {
        padding: 9rem !important;
    }

    .p-md-20 {
        padding: 9.5rem !important;
    }

    .p-md-21 {
        padding: 10rem !important;
    }

    .p-md-22 {
        padding: 10.5rem !important;
    }

    .p-md-23 {
        padding: 11rem !important;
    }

    .p-md-24 {
        padding: 11.5rem !important;
    }

    .p-md-25 {
        padding: 12rem !important;
    }

    .p-md-26 {
        padding: 12.5rem !important;
    }

    .p-md-27 {
        padding: 13rem !important;
    }

    .p-md-28 {
        padding: 13.5rem !important;
    }

    .p-md-29 {
        padding: 14rem !important;
    }

    .p-md-30 {
        padding: 14.5rem !important;
    }

    .p-md-31 {
        padding: 15rem !important;
    }

    .p-md-32 {
        padding: 15.5rem !important;
    }

    .p-md-33 {
        padding: 16rem !important;
    }

    .p-md-34 {
        padding: 16.5rem !important;
    }

    .p-md-35 {
        padding: 17rem !important;
    }

    .p-md-36 {
        padding: 17.5rem !important;
    }

    .p-md-37 {
        padding: 18rem !important;
    }

    .p-md-38 {
        padding: 18.5rem !important;
    }

    .p-md-39 {
        padding: 19rem !important;
    }

    .p-md-40 {
        padding: 19.5rem !important;
    }

    .p-md-41 {
        padding: 20rem !important;
    }

    .p-md-42 {
        padding: 20.5rem !important;
    }

    .p-md-43 {
        padding: 21rem !important;
    }

    .p-md-44 {
        padding: 21.5rem !important;
    }

    .p-md-45 {
        padding: 22rem !important;
    }

    .p-md-46 {
        padding: 22.5rem !important;
    }

    .p-md-47 {
        padding: 23rem !important;
    }

    .p-md-48 {
        padding: 23.5rem !important;
    }

    .p-md-49 {
        padding: 24rem !important;
    }

    .p-md-50 {
        padding: 24.5rem !important;
    }

    .p-md-51 {
        padding: 25rem !important;
    }

    .p-md-52 {
        padding: 25.5rem !important;
    }

    .p-md-53 {
        padding: 26rem !important;
    }

    .p-md-54 {
        padding: 26.5rem !important;
    }

    .p-md-55 {
        padding: 27rem !important;
    }

    .p-md-56 {
        padding: 27.5rem !important;
    }

    .p-md-57 {
        padding: 28rem !important;
    }

    .p-md-58 {
        padding: 28.5rem !important;
    }

    .p-md-59 {
        padding: 29rem !important;
    }

    .p-md-60 {
        padding: 29.5rem !important;
    }

    .p-md-61 {
        padding: 30rem !important;
    }

    .p-md-62 {
        padding: 30.5rem !important;
    }

    .p-md-63 {
        padding: 31rem !important;
    }

    .p-md-64 {
        padding: 31.5rem !important;
    }

    .p-md-65 {
        padding: 32rem !important;
    }

    .p-md-66 {
        padding: 32.5rem !important;
    }

    .p-md-67 {
        padding: 33rem !important;
    }

    .p-md-68 {
        padding: 33.5rem !important;
    }

    .p-md-69 {
        padding: 34rem !important;
    }

    .p-md-70 {
        padding: 34.5rem !important;
    }

    .p-md-71 {
        padding: 35rem !important;
    }

    .p-md-72 {
        padding: 35.5rem !important;
    }

    .p-md-73 {
        padding: 36rem !important;
    }

    .p-md-74 {
        padding: 36.5rem !important;
    }

    .p-md-75 {
        padding: 37rem !important;
    }

    .p-md-76 {
        padding: 37.5rem !important;
    }

    .p-md-77 {
        padding: 38rem !important;
    }

    .p-md-78 {
        padding: 38.5rem !important;
    }

    .p-md-79 {
        padding: 39rem !important;
    }

    .p-md-80 {
        padding: 39.5rem !important;
    }

    .p-md-81 {
        padding: 40rem !important;
    }

    .p-md-82 {
        padding: 40.5rem !important;
    }

    .p-md-83 {
        padding: 41rem !important;
    }

    .p-md-84 {
        padding: 41.5rem !important;
    }

    .p-md-85 {
        padding: 42rem !important;
    }

    .p-md-86 {
        padding: 42.5rem !important;
    }

    .p-md-87 {
        padding: 43rem !important;
    }

    .p-md-88 {
        padding: 43.5rem !important;
    }

    .p-md-89 {
        padding: 44rem !important;
    }

    .p-md-90 {
        padding: 44.5rem !important;
    }

    .p-md-91 {
        padding: 45rem !important;
    }

    .p-md-92 {
        padding: 45.5rem !important;
    }

    .p-md-93 {
        padding: 46rem !important;
    }

    .p-md-94 {
        padding: 46.5rem !important;
    }

    .p-md-95 {
        padding: 47rem !important;
    }

    .p-md-96 {
        padding: 47.5rem !important;
    }

    .p-md-97 {
        padding: 48rem !important;
    }

    .p-md-98 {
        padding: 48.5rem !important;
    }

    .p-md-99 {
        padding: 49rem !important;
    }

    .p-md-100 {
        padding: 49.5rem !important;
    }

    .p-md-101 {
        padding: 50rem !important;
    }

    .p-md-102 {
        padding: 50.5rem !important;
    }

    .p-md-103 {
        padding: 51rem !important;
    }

    .p-md-104 {
        padding: 51.5rem !important;
    }

    .p-md-105 {
        padding: 52rem !important;
    }

    .p-md-106 {
        padding: 52.5rem !important;
    }

    .p-md-107 {
        padding: 53rem !important;
    }

    .p-md-108 {
        padding: 53.5rem !important;
    }

    .p-md-109 {
        padding: 54rem !important;
    }

    .p-md-110 {
        padding: 54.5rem !important;
    }

    .p-md-111 {
        padding: 55rem !important;
    }

    .p-md-112 {
        padding: 55.5rem !important;
    }

    .p-md-113 {
        padding: 56rem !important;
    }

    .p-md-114 {
        padding: 56.5rem !important;
    }

    .p-md-115 {
        padding: 57rem !important;
    }

    .p-md-116 {
        padding: 57.5rem !important;
    }

    .p-md-117 {
        padding: 58rem !important;
    }

    .p-md-118 {
        padding: 58.5rem !important;
    }

    .p-md-119 {
        padding: 59rem !important;
    }

    .p-md-120 {
        padding: 59.5rem !important;
    }

    .p-md-121 {
        padding: 60rem !important;
    }

    .p-md-122 {
        padding: 60.5rem !important;
    }

    .p-md-123 {
        padding: 61rem !important;
    }

    .p-md-124 {
        padding: 61.5rem !important;
    }

    .p-md-125 {
        padding: 62rem !important;
    }

    .p-md-126 {
        padding: 62.5rem !important;
    }

    .p-md-127 {
        padding: 63rem !important;
    }

    .p-md-128 {
        padding: 63.5rem !important;
    }

    .p-md-129 {
        padding: 64rem !important;
    }

    .p-md-130 {
        padding: 64.5rem !important;
    }

    .p-md-131 {
        padding: 65rem !important;
    }

    .p-md-132 {
        padding: 65.5rem !important;
    }

    .p-md-133 {
        padding: 66rem !important;
    }

    .p-md-134 {
        padding: 66.5rem !important;
    }

    .p-md-135 {
        padding: 67rem !important;
    }

    .p-md-136 {
        padding: 67.5rem !important;
    }

    .p-md-137 {
        padding: 68rem !important;
    }

    .p-md-138 {
        padding: 68.5rem !important;
    }

    .p-md-139 {
        padding: 69rem !important;
    }

    .p-md-140 {
        padding: 69.5rem !important;
    }

    .p-md-141 {
        padding: 70rem !important;
    }

    .p-md-142 {
        padding: 70.5rem !important;
    }

    .p-md-143 {
        padding: 71rem !important;
    }

    .p-md-144 {
        padding: 71.5rem !important;
    }

    .p-md-145 {
        padding: 72rem !important;
    }

    .p-md-146 {
        padding: 72.5rem !important;
    }

    .p-md-147 {
        padding: 73rem !important;
    }

    .p-md-148 {
        padding: 73.5rem !important;
    }

    .p-md-149 {
        padding: 74rem !important;
    }

    .p-md-150 {
        padding: 74.5rem !important;
    }

    .p-md-151 {
        padding: 75rem !important;
    }

    .p-md-152 {
        padding: 75.5rem !important;
    }

    .p-md-153 {
        padding: 76rem !important;
    }

    .p-md-154 {
        padding: 76.5rem !important;
    }

    .p-md-155 {
        padding: 77rem !important;
    }

    .p-md-156 {
        padding: 77.5rem !important;
    }

    .p-md-157 {
        padding: 78rem !important;
    }

    .p-md-158 {
        padding: 78.5rem !important;
    }

    .p-md-159 {
        padding: 79rem !important;
    }

    .p-md-160 {
        padding: 79.5rem !important;
    }

    .pt-md-0 {
        padding-top: 0rem !important;
    }

    .pt-md-1 {
        padding-top: 0.25rem !important;
    }

    .pt-md-2 {
        padding-top: 0.5rem !important;
    }

    .pt-md-3 {
        padding-top: 1rem !important;
    }

    .pt-md-4 {
        padding-top: 1.5rem !important;
    }

    .pt-md-5 {
        padding-top: 2rem !important;
    }

    .pt-md-6 {
        padding-top: 2.5rem !important;
    }

    .pt-md-7 {
        padding-top: 3rem !important;
    }

    .pt-md-8 {
        padding-top: 3.5rem !important;
    }

    .pt-md-9 {
        padding-top: 4rem !important;
    }

    .pt-md-10 {
        padding-top: 4.5rem !important;
    }

    .pt-md-11 {
        padding-top: 5rem !important;
    }

    .pt-md-12 {
        padding-top: 5.5rem !important;
    }

    .pt-md-13 {
        padding-top: 6rem !important;
    }

    .pt-md-14 {
        padding-top: 6.5rem !important;
    }

    .pt-md-15 {
        padding-top: 7rem !important;
    }

    .pt-md-16 {
        padding-top: 7.5rem !important;
    }

    .pt-md-17 {
        padding-top: 8rem !important;
    }

    .pt-md-18 {
        padding-top: 8.5rem !important;
    }

    .pt-md-19 {
        padding-top: 9rem !important;
    }

    .pt-md-20 {
        padding-top: 9.5rem !important;
    }

    .pt-md-21 {
        padding-top: 10rem !important;
    }

    .pt-md-22 {
        padding-top: 10.5rem !important;
    }

    .pt-md-23 {
        padding-top: 11rem !important;
    }

    .pt-md-24 {
        padding-top: 11.5rem !important;
    }

    .pt-md-25 {
        padding-top: 12rem !important;
    }

    .pt-md-26 {
        padding-top: 12.5rem !important;
    }

    .pt-md-27 {
        padding-top: 13rem !important;
    }

    .pt-md-28 {
        padding-top: 13.5rem !important;
    }

    .pt-md-29 {
        padding-top: 14rem !important;
    }

    .pt-md-30 {
        padding-top: 14.5rem !important;
    }

    .pt-md-31 {
        padding-top: 15rem !important;
    }

    .pt-md-32 {
        padding-top: 15.5rem !important;
    }

    .pt-md-33 {
        padding-top: 16rem !important;
    }

    .pt-md-34 {
        padding-top: 16.5rem !important;
    }

    .pt-md-35 {
        padding-top: 17rem !important;
    }

    .pt-md-36 {
        padding-top: 17.5rem !important;
    }

    .pt-md-37 {
        padding-top: 18rem !important;
    }

    .pt-md-38 {
        padding-top: 18.5rem !important;
    }

    .pt-md-39 {
        padding-top: 19rem !important;
    }

    .pt-md-40 {
        padding-top: 19.5rem !important;
    }

    .pt-md-41 {
        padding-top: 20rem !important;
    }

    .pt-md-42 {
        padding-top: 20.5rem !important;
    }

    .pt-md-43 {
        padding-top: 21rem !important;
    }

    .pt-md-44 {
        padding-top: 21.5rem !important;
    }

    .pt-md-45 {
        padding-top: 22rem !important;
    }

    .pt-md-46 {
        padding-top: 22.5rem !important;
    }

    .pt-md-47 {
        padding-top: 23rem !important;
    }

    .pt-md-48 {
        padding-top: 23.5rem !important;
    }

    .pt-md-49 {
        padding-top: 24rem !important;
    }

    .pt-md-50 {
        padding-top: 24.5rem !important;
    }

    .pt-md-51 {
        padding-top: 25rem !important;
    }

    .pt-md-52 {
        padding-top: 25.5rem !important;
    }

    .pt-md-53 {
        padding-top: 26rem !important;
    }

    .pt-md-54 {
        padding-top: 26.5rem !important;
    }

    .pt-md-55 {
        padding-top: 27rem !important;
    }

    .pt-md-56 {
        padding-top: 27.5rem !important;
    }

    .pt-md-57 {
        padding-top: 28rem !important;
    }

    .pt-md-58 {
        padding-top: 28.5rem !important;
    }

    .pt-md-59 {
        padding-top: 29rem !important;
    }

    .pt-md-60 {
        padding-top: 29.5rem !important;
    }

    .pt-md-61 {
        padding-top: 30rem !important;
    }

    .pt-md-62 {
        padding-top: 30.5rem !important;
    }

    .pt-md-63 {
        padding-top: 31rem !important;
    }

    .pt-md-64 {
        padding-top: 31.5rem !important;
    }

    .pt-md-65 {
        padding-top: 32rem !important;
    }

    .pt-md-66 {
        padding-top: 32.5rem !important;
    }

    .pt-md-67 {
        padding-top: 33rem !important;
    }

    .pt-md-68 {
        padding-top: 33.5rem !important;
    }

    .pt-md-69 {
        padding-top: 34rem !important;
    }

    .pt-md-70 {
        padding-top: 34.5rem !important;
    }

    .pt-md-71 {
        padding-top: 35rem !important;
    }

    .pt-md-72 {
        padding-top: 35.5rem !important;
    }

    .pt-md-73 {
        padding-top: 36rem !important;
    }

    .pt-md-74 {
        padding-top: 36.5rem !important;
    }

    .pt-md-75 {
        padding-top: 37rem !important;
    }

    .pt-md-76 {
        padding-top: 37.5rem !important;
    }

    .pt-md-77 {
        padding-top: 38rem !important;
    }

    .pt-md-78 {
        padding-top: 38.5rem !important;
    }

    .pt-md-79 {
        padding-top: 39rem !important;
    }

    .pt-md-80 {
        padding-top: 39.5rem !important;
    }

    .pt-md-81 {
        padding-top: 40rem !important;
    }

    .pt-md-82 {
        padding-top: 40.5rem !important;
    }

    .pt-md-83 {
        padding-top: 41rem !important;
    }

    .pt-md-84 {
        padding-top: 41.5rem !important;
    }

    .pt-md-85 {
        padding-top: 42rem !important;
    }

    .pt-md-86 {
        padding-top: 42.5rem !important;
    }

    .pt-md-87 {
        padding-top: 43rem !important;
    }

    .pt-md-88 {
        padding-top: 43.5rem !important;
    }

    .pt-md-89 {
        padding-top: 44rem !important;
    }

    .pt-md-90 {
        padding-top: 44.5rem !important;
    }

    .pt-md-91 {
        padding-top: 45rem !important;
    }

    .pt-md-92 {
        padding-top: 45.5rem !important;
    }

    .pt-md-93 {
        padding-top: 46rem !important;
    }

    .pt-md-94 {
        padding-top: 46.5rem !important;
    }

    .pt-md-95 {
        padding-top: 47rem !important;
    }

    .pt-md-96 {
        padding-top: 47.5rem !important;
    }

    .pt-md-97 {
        padding-top: 48rem !important;
    }

    .pt-md-98 {
        padding-top: 48.5rem !important;
    }

    .pt-md-99 {
        padding-top: 49rem !important;
    }

    .pt-md-100 {
        padding-top: 49.5rem !important;
    }

    .pt-md-101 {
        padding-top: 50rem !important;
    }

    .pt-md-102 {
        padding-top: 50.5rem !important;
    }

    .pt-md-103 {
        padding-top: 51rem !important;
    }

    .pt-md-104 {
        padding-top: 51.5rem !important;
    }

    .pt-md-105 {
        padding-top: 52rem !important;
    }

    .pt-md-106 {
        padding-top: 52.5rem !important;
    }

    .pt-md-107 {
        padding-top: 53rem !important;
    }

    .pt-md-108 {
        padding-top: 53.5rem !important;
    }

    .pt-md-109 {
        padding-top: 54rem !important;
    }

    .pt-md-110 {
        padding-top: 54.5rem !important;
    }

    .pt-md-111 {
        padding-top: 55rem !important;
    }

    .pt-md-112 {
        padding-top: 55.5rem !important;
    }

    .pt-md-113 {
        padding-top: 56rem !important;
    }

    .pt-md-114 {
        padding-top: 56.5rem !important;
    }

    .pt-md-115 {
        padding-top: 57rem !important;
    }

    .pt-md-116 {
        padding-top: 57.5rem !important;
    }

    .pt-md-117 {
        padding-top: 58rem !important;
    }

    .pt-md-118 {
        padding-top: 58.5rem !important;
    }

    .pt-md-119 {
        padding-top: 59rem !important;
    }

    .pt-md-120 {
        padding-top: 59.5rem !important;
    }

    .pt-md-121 {
        padding-top: 60rem !important;
    }

    .pt-md-122 {
        padding-top: 60.5rem !important;
    }

    .pt-md-123 {
        padding-top: 61rem !important;
    }

    .pt-md-124 {
        padding-top: 61.5rem !important;
    }

    .pt-md-125 {
        padding-top: 62rem !important;
    }

    .pt-md-126 {
        padding-top: 62.5rem !important;
    }

    .pt-md-127 {
        padding-top: 63rem !important;
    }

    .pt-md-128 {
        padding-top: 63.5rem !important;
    }

    .pt-md-129 {
        padding-top: 64rem !important;
    }

    .pt-md-130 {
        padding-top: 64.5rem !important;
    }

    .pt-md-131 {
        padding-top: 65rem !important;
    }

    .pt-md-132 {
        padding-top: 65.5rem !important;
    }

    .pt-md-133 {
        padding-top: 66rem !important;
    }

    .pt-md-134 {
        padding-top: 66.5rem !important;
    }

    .pt-md-135 {
        padding-top: 67rem !important;
    }

    .pt-md-136 {
        padding-top: 67.5rem !important;
    }

    .pt-md-137 {
        padding-top: 68rem !important;
    }

    .pt-md-138 {
        padding-top: 68.5rem !important;
    }

    .pt-md-139 {
        padding-top: 69rem !important;
    }

    .pt-md-140 {
        padding-top: 69.5rem !important;
    }

    .pt-md-141 {
        padding-top: 70rem !important;
    }

    .pt-md-142 {
        padding-top: 70.5rem !important;
    }

    .pt-md-143 {
        padding-top: 71rem !important;
    }

    .pt-md-144 {
        padding-top: 71.5rem !important;
    }

    .pt-md-145 {
        padding-top: 72rem !important;
    }

    .pt-md-146 {
        padding-top: 72.5rem !important;
    }

    .pt-md-147 {
        padding-top: 73rem !important;
    }

    .pt-md-148 {
        padding-top: 73.5rem !important;
    }

    .pt-md-149 {
        padding-top: 74rem !important;
    }

    .pt-md-150 {
        padding-top: 74.5rem !important;
    }

    .pt-md-151 {
        padding-top: 75rem !important;
    }

    .pt-md-152 {
        padding-top: 75.5rem !important;
    }

    .pt-md-153 {
        padding-top: 76rem !important;
    }

    .pt-md-154 {
        padding-top: 76.5rem !important;
    }

    .pt-md-155 {
        padding-top: 77rem !important;
    }

    .pt-md-156 {
        padding-top: 77.5rem !important;
    }

    .pt-md-157 {
        padding-top: 78rem !important;
    }

    .pt-md-158 {
        padding-top: 78.5rem !important;
    }

    .pt-md-159 {
        padding-top: 79rem !important;
    }

    .pt-md-160 {
        padding-top: 79.5rem !important;
    }

    .pb-md-0 {
        padding-bottom: 0rem !important;
    }

    .pb-md-1 {
        padding-bottom: 0.25rem !important;
    }

    .pb-md-2 {
        padding-bottom: 0.5rem !important;
    }

    .pb-md-3 {
        padding-bottom: 1rem !important;
    }

    .pb-md-4 {
        padding-bottom: 1.5rem !important;
    }

    .pb-md-5 {
        padding-bottom: 2rem !important;
    }

    .pb-md-6 {
        padding-bottom: 2.5rem !important;
    }

    .pb-md-7 {
        padding-bottom: 3rem !important;
    }

    .pb-md-8 {
        padding-bottom: 3.5rem !important;
    }

    .pb-md-9 {
        padding-bottom: 4rem !important;
    }

    .pb-md-10 {
        padding-bottom: 4.5rem !important;
    }

    .pb-md-11 {
        padding-bottom: 5rem !important;
    }

    .pb-md-12 {
        padding-bottom: 5.5rem !important;
    }

    .pb-md-13 {
        padding-bottom: 6rem !important;
    }

    .pb-md-14 {
        padding-bottom: 6.5rem !important;
    }

    .pb-md-15 {
        padding-bottom: 7rem !important;
    }

    .pb-md-16 {
        padding-bottom: 7.5rem !important;
    }

    .pb-md-17 {
        padding-bottom: 8rem !important;
    }

    .pb-md-18 {
        padding-bottom: 8.5rem !important;
    }

    .pb-md-19 {
        padding-bottom: 9rem !important;
    }

    .pb-md-20 {
        padding-bottom: 9.5rem !important;
    }

    .pb-md-21 {
        padding-bottom: 10rem !important;
    }

    .pb-md-22 {
        padding-bottom: 10.5rem !important;
    }

    .pb-md-23 {
        padding-bottom: 11rem !important;
    }

    .pb-md-24 {
        padding-bottom: 11.5rem !important;
    }

    .pb-md-25 {
        padding-bottom: 12rem !important;
    }

    .pb-md-26 {
        padding-bottom: 12.5rem !important;
    }

    .pb-md-27 {
        padding-bottom: 13rem !important;
    }

    .pb-md-28 {
        padding-bottom: 13.5rem !important;
    }

    .pb-md-29 {
        padding-bottom: 14rem !important;
    }

    .pb-md-30 {
        padding-bottom: 14.5rem !important;
    }

    .pb-md-31 {
        padding-bottom: 15rem !important;
    }

    .pb-md-32 {
        padding-bottom: 15.5rem !important;
    }

    .pb-md-33 {
        padding-bottom: 16rem !important;
    }

    .pb-md-34 {
        padding-bottom: 16.5rem !important;
    }

    .pb-md-35 {
        padding-bottom: 17rem !important;
    }

    .pb-md-36 {
        padding-bottom: 17.5rem !important;
    }

    .pb-md-37 {
        padding-bottom: 18rem !important;
    }

    .pb-md-38 {
        padding-bottom: 18.5rem !important;
    }

    .pb-md-39 {
        padding-bottom: 19rem !important;
    }

    .pb-md-40 {
        padding-bottom: 19.5rem !important;
    }

    .pb-md-41 {
        padding-bottom: 20rem !important;
    }

    .pb-md-42 {
        padding-bottom: 20.5rem !important;
    }

    .pb-md-43 {
        padding-bottom: 21rem !important;
    }

    .pb-md-44 {
        padding-bottom: 21.5rem !important;
    }

    .pb-md-45 {
        padding-bottom: 22rem !important;
    }

    .pb-md-46 {
        padding-bottom: 22.5rem !important;
    }

    .pb-md-47 {
        padding-bottom: 23rem !important;
    }

    .pb-md-48 {
        padding-bottom: 23.5rem !important;
    }

    .pb-md-49 {
        padding-bottom: 24rem !important;
    }

    .pb-md-50 {
        padding-bottom: 24.5rem !important;
    }

    .pb-md-51 {
        padding-bottom: 25rem !important;
    }

    .pb-md-52 {
        padding-bottom: 25.5rem !important;
    }

    .pb-md-53 {
        padding-bottom: 26rem !important;
    }

    .pb-md-54 {
        padding-bottom: 26.5rem !important;
    }

    .pb-md-55 {
        padding-bottom: 27rem !important;
    }

    .pb-md-56 {
        padding-bottom: 27.5rem !important;
    }

    .pb-md-57 {
        padding-bottom: 28rem !important;
    }

    .pb-md-58 {
        padding-bottom: 28.5rem !important;
    }

    .pb-md-59 {
        padding-bottom: 29rem !important;
    }

    .pb-md-60 {
        padding-bottom: 29.5rem !important;
    }

    .pb-md-61 {
        padding-bottom: 30rem !important;
    }

    .pb-md-62 {
        padding-bottom: 30.5rem !important;
    }

    .pb-md-63 {
        padding-bottom: 31rem !important;
    }

    .pb-md-64 {
        padding-bottom: 31.5rem !important;
    }

    .pb-md-65 {
        padding-bottom: 32rem !important;
    }

    .pb-md-66 {
        padding-bottom: 32.5rem !important;
    }

    .pb-md-67 {
        padding-bottom: 33rem !important;
    }

    .pb-md-68 {
        padding-bottom: 33.5rem !important;
    }

    .pb-md-69 {
        padding-bottom: 34rem !important;
    }

    .pb-md-70 {
        padding-bottom: 34.5rem !important;
    }

    .pb-md-71 {
        padding-bottom: 35rem !important;
    }

    .pb-md-72 {
        padding-bottom: 35.5rem !important;
    }

    .pb-md-73 {
        padding-bottom: 36rem !important;
    }

    .pb-md-74 {
        padding-bottom: 36.5rem !important;
    }

    .pb-md-75 {
        padding-bottom: 37rem !important;
    }

    .pb-md-76 {
        padding-bottom: 37.5rem !important;
    }

    .pb-md-77 {
        padding-bottom: 38rem !important;
    }

    .pb-md-78 {
        padding-bottom: 38.5rem !important;
    }

    .pb-md-79 {
        padding-bottom: 39rem !important;
    }

    .pb-md-80 {
        padding-bottom: 39.5rem !important;
    }

    .pb-md-81 {
        padding-bottom: 40rem !important;
    }

    .pb-md-82 {
        padding-bottom: 40.5rem !important;
    }

    .pb-md-83 {
        padding-bottom: 41rem !important;
    }

    .pb-md-84 {
        padding-bottom: 41.5rem !important;
    }

    .pb-md-85 {
        padding-bottom: 42rem !important;
    }

    .pb-md-86 {
        padding-bottom: 42.5rem !important;
    }

    .pb-md-87 {
        padding-bottom: 43rem !important;
    }

    .pb-md-88 {
        padding-bottom: 43.5rem !important;
    }

    .pb-md-89 {
        padding-bottom: 44rem !important;
    }

    .pb-md-90 {
        padding-bottom: 44.5rem !important;
    }

    .pb-md-91 {
        padding-bottom: 45rem !important;
    }

    .pb-md-92 {
        padding-bottom: 45.5rem !important;
    }

    .pb-md-93 {
        padding-bottom: 46rem !important;
    }

    .pb-md-94 {
        padding-bottom: 46.5rem !important;
    }

    .pb-md-95 {
        padding-bottom: 47rem !important;
    }

    .pb-md-96 {
        padding-bottom: 47.5rem !important;
    }

    .pb-md-97 {
        padding-bottom: 48rem !important;
    }

    .pb-md-98 {
        padding-bottom: 48.5rem !important;
    }

    .pb-md-99 {
        padding-bottom: 49rem !important;
    }

    .pb-md-100 {
        padding-bottom: 49.5rem !important;
    }

    .pb-md-101 {
        padding-bottom: 50rem !important;
    }

    .pb-md-102 {
        padding-bottom: 50.5rem !important;
    }

    .pb-md-103 {
        padding-bottom: 51rem !important;
    }

    .pb-md-104 {
        padding-bottom: 51.5rem !important;
    }

    .pb-md-105 {
        padding-bottom: 52rem !important;
    }

    .pb-md-106 {
        padding-bottom: 52.5rem !important;
    }

    .pb-md-107 {
        padding-bottom: 53rem !important;
    }

    .pb-md-108 {
        padding-bottom: 53.5rem !important;
    }

    .pb-md-109 {
        padding-bottom: 54rem !important;
    }

    .pb-md-110 {
        padding-bottom: 54.5rem !important;
    }

    .pb-md-111 {
        padding-bottom: 55rem !important;
    }

    .pb-md-112 {
        padding-bottom: 55.5rem !important;
    }

    .pb-md-113 {
        padding-bottom: 56rem !important;
    }

    .pb-md-114 {
        padding-bottom: 56.5rem !important;
    }

    .pb-md-115 {
        padding-bottom: 57rem !important;
    }

    .pb-md-116 {
        padding-bottom: 57.5rem !important;
    }

    .pb-md-117 {
        padding-bottom: 58rem !important;
    }

    .pb-md-118 {
        padding-bottom: 58.5rem !important;
    }

    .pb-md-119 {
        padding-bottom: 59rem !important;
    }

    .pb-md-120 {
        padding-bottom: 59.5rem !important;
    }

    .pb-md-121 {
        padding-bottom: 60rem !important;
    }

    .pb-md-122 {
        padding-bottom: 60.5rem !important;
    }

    .pb-md-123 {
        padding-bottom: 61rem !important;
    }

    .pb-md-124 {
        padding-bottom: 61.5rem !important;
    }

    .pb-md-125 {
        padding-bottom: 62rem !important;
    }

    .pb-md-126 {
        padding-bottom: 62.5rem !important;
    }

    .pb-md-127 {
        padding-bottom: 63rem !important;
    }

    .pb-md-128 {
        padding-bottom: 63.5rem !important;
    }

    .pb-md-129 {
        padding-bottom: 64rem !important;
    }

    .pb-md-130 {
        padding-bottom: 64.5rem !important;
    }

    .pb-md-131 {
        padding-bottom: 65rem !important;
    }

    .pb-md-132 {
        padding-bottom: 65.5rem !important;
    }

    .pb-md-133 {
        padding-bottom: 66rem !important;
    }

    .pb-md-134 {
        padding-bottom: 66.5rem !important;
    }

    .pb-md-135 {
        padding-bottom: 67rem !important;
    }

    .pb-md-136 {
        padding-bottom: 67.5rem !important;
    }

    .pb-md-137 {
        padding-bottom: 68rem !important;
    }

    .pb-md-138 {
        padding-bottom: 68.5rem !important;
    }

    .pb-md-139 {
        padding-bottom: 69rem !important;
    }

    .pb-md-140 {
        padding-bottom: 69.5rem !important;
    }

    .pb-md-141 {
        padding-bottom: 70rem !important;
    }

    .pb-md-142 {
        padding-bottom: 70.5rem !important;
    }

    .pb-md-143 {
        padding-bottom: 71rem !important;
    }

    .pb-md-144 {
        padding-bottom: 71.5rem !important;
    }

    .pb-md-145 {
        padding-bottom: 72rem !important;
    }

    .pb-md-146 {
        padding-bottom: 72.5rem !important;
    }

    .pb-md-147 {
        padding-bottom: 73rem !important;
    }

    .pb-md-148 {
        padding-bottom: 73.5rem !important;
    }

    .pb-md-149 {
        padding-bottom: 74rem !important;
    }

    .pb-md-150 {
        padding-bottom: 74.5rem !important;
    }

    .pb-md-151 {
        padding-bottom: 75rem !important;
    }

    .pb-md-152 {
        padding-bottom: 75.5rem !important;
    }

    .pb-md-153 {
        padding-bottom: 76rem !important;
    }

    .pb-md-154 {
        padding-bottom: 76.5rem !important;
    }

    .pb-md-155 {
        padding-bottom: 77rem !important;
    }

    .pb-md-156 {
        padding-bottom: 77.5rem !important;
    }

    .pb-md-157 {
        padding-bottom: 78rem !important;
    }

    .pb-md-158 {
        padding-bottom: 78.5rem !important;
    }

    .pb-md-159 {
        padding-bottom: 79rem !important;
    }

    .pb-md-160 {
        padding-bottom: 79.5rem !important;
    }

    .ps-md-0 {
        padding-left: 0rem !important;
    }

    .ps-md-1 {
        padding-left: 0.25rem !important;
    }

    .ps-md-2 {
        padding-left: 0.5rem !important;
    }

    .ps-md-3 {
        padding-left: 1rem !important;
    }

    .ps-md-4 {
        padding-left: 1.5rem !important;
    }

    .ps-md-5 {
        padding-left: 2rem !important;
    }

    .ps-md-6 {
        padding-left: 2.5rem !important;
    }

    .ps-md-7 {
        padding-left: 3rem !important;
    }

    .ps-md-8 {
        padding-left: 3.5rem !important;
    }

    .ps-md-9 {
        padding-left: 4rem !important;
    }

    .ps-md-10 {
        padding-left: 4.5rem !important;
    }

    .ps-md-11 {
        padding-left: 5rem !important;
    }

    .ps-md-12 {
        padding-left: 5.5rem !important;
    }

    .ps-md-13 {
        padding-left: 6rem !important;
    }

    .ps-md-14 {
        padding-left: 6.5rem !important;
    }

    .ps-md-15 {
        padding-left: 7rem !important;
    }

    .ps-md-16 {
        padding-left: 7.5rem !important;
    }

    .ps-md-17 {
        padding-left: 8rem !important;
    }

    .ps-md-18 {
        padding-left: 8.5rem !important;
    }

    .ps-md-19 {
        padding-left: 9rem !important;
    }

    .ps-md-20 {
        padding-left: 9.5rem !important;
    }

    .ps-md-21 {
        padding-left: 10rem !important;
    }

    .ps-md-22 {
        padding-left: 10.5rem !important;
    }

    .ps-md-23 {
        padding-left: 11rem !important;
    }

    .ps-md-24 {
        padding-left: 11.5rem !important;
    }

    .ps-md-25 {
        padding-left: 12rem !important;
    }

    .ps-md-26 {
        padding-left: 12.5rem !important;
    }

    .ps-md-27 {
        padding-left: 13rem !important;
    }

    .ps-md-28 {
        padding-left: 13.5rem !important;
    }

    .ps-md-29 {
        padding-left: 14rem !important;
    }

    .ps-md-30 {
        padding-left: 14.5rem !important;
    }

    .ps-md-31 {
        padding-left: 15rem !important;
    }

    .ps-md-32 {
        padding-left: 15.5rem !important;
    }

    .ps-md-33 {
        padding-left: 16rem !important;
    }

    .ps-md-34 {
        padding-left: 16.5rem !important;
    }

    .ps-md-35 {
        padding-left: 17rem !important;
    }

    .ps-md-36 {
        padding-left: 17.5rem !important;
    }

    .ps-md-37 {
        padding-left: 18rem !important;
    }

    .ps-md-38 {
        padding-left: 18.5rem !important;
    }

    .ps-md-39 {
        padding-left: 19rem !important;
    }

    .ps-md-40 {
        padding-left: 19.5rem !important;
    }

    .ps-md-41 {
        padding-left: 20rem !important;
    }

    .ps-md-42 {
        padding-left: 20.5rem !important;
    }

    .ps-md-43 {
        padding-left: 21rem !important;
    }

    .ps-md-44 {
        padding-left: 21.5rem !important;
    }

    .ps-md-45 {
        padding-left: 22rem !important;
    }

    .ps-md-46 {
        padding-left: 22.5rem !important;
    }

    .ps-md-47 {
        padding-left: 23rem !important;
    }

    .ps-md-48 {
        padding-left: 23.5rem !important;
    }

    .ps-md-49 {
        padding-left: 24rem !important;
    }

    .ps-md-50 {
        padding-left: 24.5rem !important;
    }

    .ps-md-51 {
        padding-left: 25rem !important;
    }

    .ps-md-52 {
        padding-left: 25.5rem !important;
    }

    .ps-md-53 {
        padding-left: 26rem !important;
    }

    .ps-md-54 {
        padding-left: 26.5rem !important;
    }

    .ps-md-55 {
        padding-left: 27rem !important;
    }

    .ps-md-56 {
        padding-left: 27.5rem !important;
    }

    .ps-md-57 {
        padding-left: 28rem !important;
    }

    .ps-md-58 {
        padding-left: 28.5rem !important;
    }

    .ps-md-59 {
        padding-left: 29rem !important;
    }

    .ps-md-60 {
        padding-left: 29.5rem !important;
    }

    .ps-md-61 {
        padding-left: 30rem !important;
    }

    .ps-md-62 {
        padding-left: 30.5rem !important;
    }

    .ps-md-63 {
        padding-left: 31rem !important;
    }

    .ps-md-64 {
        padding-left: 31.5rem !important;
    }

    .ps-md-65 {
        padding-left: 32rem !important;
    }

    .ps-md-66 {
        padding-left: 32.5rem !important;
    }

    .ps-md-67 {
        padding-left: 33rem !important;
    }

    .ps-md-68 {
        padding-left: 33.5rem !important;
    }

    .ps-md-69 {
        padding-left: 34rem !important;
    }

    .ps-md-70 {
        padding-left: 34.5rem !important;
    }

    .ps-md-71 {
        padding-left: 35rem !important;
    }

    .ps-md-72 {
        padding-left: 35.5rem !important;
    }

    .ps-md-73 {
        padding-left: 36rem !important;
    }

    .ps-md-74 {
        padding-left: 36.5rem !important;
    }

    .ps-md-75 {
        padding-left: 37rem !important;
    }

    .ps-md-76 {
        padding-left: 37.5rem !important;
    }

    .ps-md-77 {
        padding-left: 38rem !important;
    }

    .ps-md-78 {
        padding-left: 38.5rem !important;
    }

    .ps-md-79 {
        padding-left: 39rem !important;
    }

    .ps-md-80 {
        padding-left: 39.5rem !important;
    }

    .ps-md-81 {
        padding-left: 40rem !important;
    }

    .ps-md-82 {
        padding-left: 40.5rem !important;
    }

    .ps-md-83 {
        padding-left: 41rem !important;
    }

    .ps-md-84 {
        padding-left: 41.5rem !important;
    }

    .ps-md-85 {
        padding-left: 42rem !important;
    }

    .ps-md-86 {
        padding-left: 42.5rem !important;
    }

    .ps-md-87 {
        padding-left: 43rem !important;
    }

    .ps-md-88 {
        padding-left: 43.5rem !important;
    }

    .ps-md-89 {
        padding-left: 44rem !important;
    }

    .ps-md-90 {
        padding-left: 44.5rem !important;
    }

    .ps-md-91 {
        padding-left: 45rem !important;
    }

    .ps-md-92 {
        padding-left: 45.5rem !important;
    }

    .ps-md-93 {
        padding-left: 46rem !important;
    }

    .ps-md-94 {
        padding-left: 46.5rem !important;
    }

    .ps-md-95 {
        padding-left: 47rem !important;
    }

    .ps-md-96 {
        padding-left: 47.5rem !important;
    }

    .ps-md-97 {
        padding-left: 48rem !important;
    }

    .ps-md-98 {
        padding-left: 48.5rem !important;
    }

    .ps-md-99 {
        padding-left: 49rem !important;
    }

    .ps-md-100 {
        padding-left: 49.5rem !important;
    }

    .ps-md-101 {
        padding-left: 50rem !important;
    }

    .ps-md-102 {
        padding-left: 50.5rem !important;
    }

    .ps-md-103 {
        padding-left: 51rem !important;
    }

    .ps-md-104 {
        padding-left: 51.5rem !important;
    }

    .ps-md-105 {
        padding-left: 52rem !important;
    }

    .ps-md-106 {
        padding-left: 52.5rem !important;
    }

    .ps-md-107 {
        padding-left: 53rem !important;
    }

    .ps-md-108 {
        padding-left: 53.5rem !important;
    }

    .ps-md-109 {
        padding-left: 54rem !important;
    }

    .ps-md-110 {
        padding-left: 54.5rem !important;
    }

    .ps-md-111 {
        padding-left: 55rem !important;
    }

    .ps-md-112 {
        padding-left: 55.5rem !important;
    }

    .ps-md-113 {
        padding-left: 56rem !important;
    }

    .ps-md-114 {
        padding-left: 56.5rem !important;
    }

    .ps-md-115 {
        padding-left: 57rem !important;
    }

    .ps-md-116 {
        padding-left: 57.5rem !important;
    }

    .ps-md-117 {
        padding-left: 58rem !important;
    }

    .ps-md-118 {
        padding-left: 58.5rem !important;
    }

    .ps-md-119 {
        padding-left: 59rem !important;
    }

    .ps-md-120 {
        padding-left: 59.5rem !important;
    }

    .ps-md-121 {
        padding-left: 60rem !important;
    }

    .ps-md-122 {
        padding-left: 60.5rem !important;
    }

    .ps-md-123 {
        padding-left: 61rem !important;
    }

    .ps-md-124 {
        padding-left: 61.5rem !important;
    }

    .ps-md-125 {
        padding-left: 62rem !important;
    }

    .ps-md-126 {
        padding-left: 62.5rem !important;
    }

    .ps-md-127 {
        padding-left: 63rem !important;
    }

    .ps-md-128 {
        padding-left: 63.5rem !important;
    }

    .ps-md-129 {
        padding-left: 64rem !important;
    }

    .ps-md-130 {
        padding-left: 64.5rem !important;
    }

    .ps-md-131 {
        padding-left: 65rem !important;
    }

    .ps-md-132 {
        padding-left: 65.5rem !important;
    }

    .ps-md-133 {
        padding-left: 66rem !important;
    }

    .ps-md-134 {
        padding-left: 66.5rem !important;
    }

    .ps-md-135 {
        padding-left: 67rem !important;
    }

    .ps-md-136 {
        padding-left: 67.5rem !important;
    }

    .ps-md-137 {
        padding-left: 68rem !important;
    }

    .ps-md-138 {
        padding-left: 68.5rem !important;
    }

    .ps-md-139 {
        padding-left: 69rem !important;
    }

    .ps-md-140 {
        padding-left: 69.5rem !important;
    }

    .ps-md-141 {
        padding-left: 70rem !important;
    }

    .ps-md-142 {
        padding-left: 70.5rem !important;
    }

    .ps-md-143 {
        padding-left: 71rem !important;
    }

    .ps-md-144 {
        padding-left: 71.5rem !important;
    }

    .ps-md-145 {
        padding-left: 72rem !important;
    }

    .ps-md-146 {
        padding-left: 72.5rem !important;
    }

    .ps-md-147 {
        padding-left: 73rem !important;
    }

    .ps-md-148 {
        padding-left: 73.5rem !important;
    }

    .ps-md-149 {
        padding-left: 74rem !important;
    }

    .ps-md-150 {
        padding-left: 74.5rem !important;
    }

    .ps-md-151 {
        padding-left: 75rem !important;
    }

    .ps-md-152 {
        padding-left: 75.5rem !important;
    }

    .ps-md-153 {
        padding-left: 76rem !important;
    }

    .ps-md-154 {
        padding-left: 76.5rem !important;
    }

    .ps-md-155 {
        padding-left: 77rem !important;
    }

    .ps-md-156 {
        padding-left: 77.5rem !important;
    }

    .ps-md-157 {
        padding-left: 78rem !important;
    }

    .ps-md-158 {
        padding-left: 78.5rem !important;
    }

    .ps-md-159 {
        padding-left: 79rem !important;
    }

    .ps-md-160 {
        padding-left: 79.5rem !important;
    }

    .pe-md-0 {
        padding-right: 0rem !important;
    }

    .pe-md-1 {
        padding-right: 0.25rem !important;
    }

    .pe-md-2 {
        padding-right: 0.5rem !important;
    }

    .pe-md-3 {
        padding-right: 1rem !important;
    }

    .pe-md-4 {
        padding-right: 1.5rem !important;
    }

    .pe-md-5 {
        padding-right: 2rem !important;
    }

    .pe-md-6 {
        padding-right: 2.5rem !important;
    }

    .pe-md-7 {
        padding-right: 3rem !important;
    }

    .pe-md-8 {
        padding-right: 3.5rem !important;
    }

    .pe-md-9 {
        padding-right: 4rem !important;
    }

    .pe-md-10 {
        padding-right: 4.5rem !important;
    }

    .pe-md-11 {
        padding-right: 5rem !important;
    }

    .pe-md-12 {
        padding-right: 5.5rem !important;
    }

    .pe-md-13 {
        padding-right: 6rem !important;
    }

    .pe-md-14 {
        padding-right: 6.5rem !important;
    }

    .pe-md-15 {
        padding-right: 7rem !important;
    }

    .pe-md-16 {
        padding-right: 7.5rem !important;
    }

    .pe-md-17 {
        padding-right: 8rem !important;
    }

    .pe-md-18 {
        padding-right: 8.5rem !important;
    }

    .pe-md-19 {
        padding-right: 9rem !important;
    }

    .pe-md-20 {
        padding-right: 9.5rem !important;
    }

    .pe-md-21 {
        padding-right: 10rem !important;
    }

    .pe-md-22 {
        padding-right: 10.5rem !important;
    }

    .pe-md-23 {
        padding-right: 11rem !important;
    }

    .pe-md-24 {
        padding-right: 11.5rem !important;
    }

    .pe-md-25 {
        padding-right: 12rem !important;
    }

    .pe-md-26 {
        padding-right: 12.5rem !important;
    }

    .pe-md-27 {
        padding-right: 13rem !important;
    }

    .pe-md-28 {
        padding-right: 13.5rem !important;
    }

    .pe-md-29 {
        padding-right: 14rem !important;
    }

    .pe-md-30 {
        padding-right: 14.5rem !important;
    }

    .pe-md-31 {
        padding-right: 15rem !important;
    }

    .pe-md-32 {
        padding-right: 15.5rem !important;
    }

    .pe-md-33 {
        padding-right: 16rem !important;
    }

    .pe-md-34 {
        padding-right: 16.5rem !important;
    }

    .pe-md-35 {
        padding-right: 17rem !important;
    }

    .pe-md-36 {
        padding-right: 17.5rem !important;
    }

    .pe-md-37 {
        padding-right: 18rem !important;
    }

    .pe-md-38 {
        padding-right: 18.5rem !important;
    }

    .pe-md-39 {
        padding-right: 19rem !important;
    }

    .pe-md-40 {
        padding-right: 19.5rem !important;
    }

    .pe-md-41 {
        padding-right: 20rem !important;
    }

    .pe-md-42 {
        padding-right: 20.5rem !important;
    }

    .pe-md-43 {
        padding-right: 21rem !important;
    }

    .pe-md-44 {
        padding-right: 21.5rem !important;
    }

    .pe-md-45 {
        padding-right: 22rem !important;
    }

    .pe-md-46 {
        padding-right: 22.5rem !important;
    }

    .pe-md-47 {
        padding-right: 23rem !important;
    }

    .pe-md-48 {
        padding-right: 23.5rem !important;
    }

    .pe-md-49 {
        padding-right: 24rem !important;
    }

    .pe-md-50 {
        padding-right: 24.5rem !important;
    }

    .pe-md-51 {
        padding-right: 25rem !important;
    }

    .pe-md-52 {
        padding-right: 25.5rem !important;
    }

    .pe-md-53 {
        padding-right: 26rem !important;
    }

    .pe-md-54 {
        padding-right: 26.5rem !important;
    }

    .pe-md-55 {
        padding-right: 27rem !important;
    }

    .pe-md-56 {
        padding-right: 27.5rem !important;
    }

    .pe-md-57 {
        padding-right: 28rem !important;
    }

    .pe-md-58 {
        padding-right: 28.5rem !important;
    }

    .pe-md-59 {
        padding-right: 29rem !important;
    }

    .pe-md-60 {
        padding-right: 29.5rem !important;
    }

    .pe-md-61 {
        padding-right: 30rem !important;
    }

    .pe-md-62 {
        padding-right: 30.5rem !important;
    }

    .pe-md-63 {
        padding-right: 31rem !important;
    }

    .pe-md-64 {
        padding-right: 31.5rem !important;
    }

    .pe-md-65 {
        padding-right: 32rem !important;
    }

    .pe-md-66 {
        padding-right: 32.5rem !important;
    }

    .pe-md-67 {
        padding-right: 33rem !important;
    }

    .pe-md-68 {
        padding-right: 33.5rem !important;
    }

    .pe-md-69 {
        padding-right: 34rem !important;
    }

    .pe-md-70 {
        padding-right: 34.5rem !important;
    }

    .pe-md-71 {
        padding-right: 35rem !important;
    }

    .pe-md-72 {
        padding-right: 35.5rem !important;
    }

    .pe-md-73 {
        padding-right: 36rem !important;
    }

    .pe-md-74 {
        padding-right: 36.5rem !important;
    }

    .pe-md-75 {
        padding-right: 37rem !important;
    }

    .pe-md-76 {
        padding-right: 37.5rem !important;
    }

    .pe-md-77 {
        padding-right: 38rem !important;
    }

    .pe-md-78 {
        padding-right: 38.5rem !important;
    }

    .pe-md-79 {
        padding-right: 39rem !important;
    }

    .pe-md-80 {
        padding-right: 39.5rem !important;
    }

    .pe-md-81 {
        padding-right: 40rem !important;
    }

    .pe-md-82 {
        padding-right: 40.5rem !important;
    }

    .pe-md-83 {
        padding-right: 41rem !important;
    }

    .pe-md-84 {
        padding-right: 41.5rem !important;
    }

    .pe-md-85 {
        padding-right: 42rem !important;
    }

    .pe-md-86 {
        padding-right: 42.5rem !important;
    }

    .pe-md-87 {
        padding-right: 43rem !important;
    }

    .pe-md-88 {
        padding-right: 43.5rem !important;
    }

    .pe-md-89 {
        padding-right: 44rem !important;
    }

    .pe-md-90 {
        padding-right: 44.5rem !important;
    }

    .pe-md-91 {
        padding-right: 45rem !important;
    }

    .pe-md-92 {
        padding-right: 45.5rem !important;
    }

    .pe-md-93 {
        padding-right: 46rem !important;
    }

    .pe-md-94 {
        padding-right: 46.5rem !important;
    }

    .pe-md-95 {
        padding-right: 47rem !important;
    }

    .pe-md-96 {
        padding-right: 47.5rem !important;
    }

    .pe-md-97 {
        padding-right: 48rem !important;
    }

    .pe-md-98 {
        padding-right: 48.5rem !important;
    }

    .pe-md-99 {
        padding-right: 49rem !important;
    }

    .pe-md-100 {
        padding-right: 49.5rem !important;
    }

    .pe-md-101 {
        padding-right: 50rem !important;
    }

    .pe-md-102 {
        padding-right: 50.5rem !important;
    }

    .pe-md-103 {
        padding-right: 51rem !important;
    }

    .pe-md-104 {
        padding-right: 51.5rem !important;
    }

    .pe-md-105 {
        padding-right: 52rem !important;
    }

    .pe-md-106 {
        padding-right: 52.5rem !important;
    }

    .pe-md-107 {
        padding-right: 53rem !important;
    }

    .pe-md-108 {
        padding-right: 53.5rem !important;
    }

    .pe-md-109 {
        padding-right: 54rem !important;
    }

    .pe-md-110 {
        padding-right: 54.5rem !important;
    }

    .pe-md-111 {
        padding-right: 55rem !important;
    }

    .pe-md-112 {
        padding-right: 55.5rem !important;
    }

    .pe-md-113 {
        padding-right: 56rem !important;
    }

    .pe-md-114 {
        padding-right: 56.5rem !important;
    }

    .pe-md-115 {
        padding-right: 57rem !important;
    }

    .pe-md-116 {
        padding-right: 57.5rem !important;
    }

    .pe-md-117 {
        padding-right: 58rem !important;
    }

    .pe-md-118 {
        padding-right: 58.5rem !important;
    }

    .pe-md-119 {
        padding-right: 59rem !important;
    }

    .pe-md-120 {
        padding-right: 59.5rem !important;
    }

    .pe-md-121 {
        padding-right: 60rem !important;
    }

    .pe-md-122 {
        padding-right: 60.5rem !important;
    }

    .pe-md-123 {
        padding-right: 61rem !important;
    }

    .pe-md-124 {
        padding-right: 61.5rem !important;
    }

    .pe-md-125 {
        padding-right: 62rem !important;
    }

    .pe-md-126 {
        padding-right: 62.5rem !important;
    }

    .pe-md-127 {
        padding-right: 63rem !important;
    }

    .pe-md-128 {
        padding-right: 63.5rem !important;
    }

    .pe-md-129 {
        padding-right: 64rem !important;
    }

    .pe-md-130 {
        padding-right: 64.5rem !important;
    }

    .pe-md-131 {
        padding-right: 65rem !important;
    }

    .pe-md-132 {
        padding-right: 65.5rem !important;
    }

    .pe-md-133 {
        padding-right: 66rem !important;
    }

    .pe-md-134 {
        padding-right: 66.5rem !important;
    }

    .pe-md-135 {
        padding-right: 67rem !important;
    }

    .pe-md-136 {
        padding-right: 67.5rem !important;
    }

    .pe-md-137 {
        padding-right: 68rem !important;
    }

    .pe-md-138 {
        padding-right: 68.5rem !important;
    }

    .pe-md-139 {
        padding-right: 69rem !important;
    }

    .pe-md-140 {
        padding-right: 69.5rem !important;
    }

    .pe-md-141 {
        padding-right: 70rem !important;
    }

    .pe-md-142 {
        padding-right: 70.5rem !important;
    }

    .pe-md-143 {
        padding-right: 71rem !important;
    }

    .pe-md-144 {
        padding-right: 71.5rem !important;
    }

    .pe-md-145 {
        padding-right: 72rem !important;
    }

    .pe-md-146 {
        padding-right: 72.5rem !important;
    }

    .pe-md-147 {
        padding-right: 73rem !important;
    }

    .pe-md-148 {
        padding-right: 73.5rem !important;
    }

    .pe-md-149 {
        padding-right: 74rem !important;
    }

    .pe-md-150 {
        padding-right: 74.5rem !important;
    }

    .pe-md-151 {
        padding-right: 75rem !important;
    }

    .pe-md-152 {
        padding-right: 75.5rem !important;
    }

    .pe-md-153 {
        padding-right: 76rem !important;
    }

    .pe-md-154 {
        padding-right: 76.5rem !important;
    }

    .pe-md-155 {
        padding-right: 77rem !important;
    }

    .pe-md-156 {
        padding-right: 77.5rem !important;
    }

    .pe-md-157 {
        padding-right: 78rem !important;
    }

    .pe-md-158 {
        padding-right: 78.5rem !important;
    }

    .pe-md-159 {
        padding-right: 79rem !important;
    }

    .pe-md-160 {
        padding-right: 79.5rem !important;
    }

    .px-md-0 {
        padding-left: 0rem !important;
        padding-right: 0rem !important;
    }

    .px-md-1 {
        padding-left: 0.25rem !important;
        padding-right: 0.25rem !important;
    }

    .px-md-2 {
        padding-left: 0.5rem !important;
        padding-right: 0.5rem !important;
    }

    .px-md-3 {
        padding-left: 1rem !important;
        padding-right: 1rem !important;
    }

    .px-md-4 {
        padding-left: 1.5rem !important;
        padding-right: 1.5rem !important;
    }

    .px-md-5 {
        padding-left: 2rem !important;
        padding-right: 2rem !important;
    }

    .px-md-6 {
        padding-left: 2.5rem !important;
        padding-right: 2.5rem !important;
    }

    .px-md-7 {
        padding-left: 3rem !important;
        padding-right: 3rem !important;
    }

    .px-md-8 {
        padding-left: 3.5rem !important;
        padding-right: 3.5rem !important;
    }

    .px-md-9 {
        padding-left: 4rem !important;
        padding-right: 4rem !important;
    }

    .px-md-10 {
        padding-left: 4.5rem !important;
        padding-right: 4.5rem !important;
    }

    .px-md-11 {
        padding-left: 5rem !important;
        padding-right: 5rem !important;
    }

    .px-md-12 {
        padding-left: 5.5rem !important;
        padding-right: 5.5rem !important;
    }

    .px-md-13 {
        padding-left: 6rem !important;
        padding-right: 6rem !important;
    }

    .px-md-14 {
        padding-left: 6.5rem !important;
        padding-right: 6.5rem !important;
    }

    .px-md-15 {
        padding-left: 7rem !important;
        padding-right: 7rem !important;
    }

    .px-md-16 {
        padding-left: 7.5rem !important;
        padding-right: 7.5rem !important;
    }

    .px-md-17 {
        padding-left: 8rem !important;
        padding-right: 8rem !important;
    }

    .px-md-18 {
        padding-left: 8.5rem !important;
        padding-right: 8.5rem !important;
    }

    .px-md-19 {
        padding-left: 9rem !important;
        padding-right: 9rem !important;
    }

    .px-md-20 {
        padding-left: 9.5rem !important;
        padding-right: 9.5rem !important;
    }

    .px-md-21 {
        padding-left: 10rem !important;
        padding-right: 10rem !important;
    }

    .px-md-22 {
        padding-left: 10.5rem !important;
        padding-right: 10.5rem !important;
    }

    .px-md-23 {
        padding-left: 11rem !important;
        padding-right: 11rem !important;
    }

    .px-md-24 {
        padding-left: 11.5rem !important;
        padding-right: 11.5rem !important;
    }

    .px-md-25 {
        padding-left: 12rem !important;
        padding-right: 12rem !important;
    }

    .px-md-26 {
        padding-left: 12.5rem !important;
        padding-right: 12.5rem !important;
    }

    .px-md-27 {
        padding-left: 13rem !important;
        padding-right: 13rem !important;
    }

    .px-md-28 {
        padding-left: 13.5rem !important;
        padding-right: 13.5rem !important;
    }

    .px-md-29 {
        padding-left: 14rem !important;
        padding-right: 14rem !important;
    }

    .px-md-30 {
        padding-left: 14.5rem !important;
        padding-right: 14.5rem !important;
    }

    .px-md-31 {
        padding-left: 15rem !important;
        padding-right: 15rem !important;
    }

    .px-md-32 {
        padding-left: 15.5rem !important;
        padding-right: 15.5rem !important;
    }

    .px-md-33 {
        padding-left: 16rem !important;
        padding-right: 16rem !important;
    }

    .px-md-34 {
        padding-left: 16.5rem !important;
        padding-right: 16.5rem !important;
    }

    .px-md-35 {
        padding-left: 17rem !important;
        padding-right: 17rem !important;
    }

    .px-md-36 {
        padding-left: 17.5rem !important;
        padding-right: 17.5rem !important;
    }

    .px-md-37 {
        padding-left: 18rem !important;
        padding-right: 18rem !important;
    }

    .px-md-38 {
        padding-left: 18.5rem !important;
        padding-right: 18.5rem !important;
    }

    .px-md-39 {
        padding-left: 19rem !important;
        padding-right: 19rem !important;
    }

    .px-md-40 {
        padding-left: 19.5rem !important;
        padding-right: 19.5rem !important;
    }

    .px-md-41 {
        padding-left: 20rem !important;
        padding-right: 20rem !important;
    }

    .px-md-42 {
        padding-left: 20.5rem !important;
        padding-right: 20.5rem !important;
    }

    .px-md-43 {
        padding-left: 21rem !important;
        padding-right: 21rem !important;
    }

    .px-md-44 {
        padding-left: 21.5rem !important;
        padding-right: 21.5rem !important;
    }

    .px-md-45 {
        padding-left: 22rem !important;
        padding-right: 22rem !important;
    }

    .px-md-46 {
        padding-left: 22.5rem !important;
        padding-right: 22.5rem !important;
    }

    .px-md-47 {
        padding-left: 23rem !important;
        padding-right: 23rem !important;
    }

    .px-md-48 {
        padding-left: 23.5rem !important;
        padding-right: 23.5rem !important;
    }

    .px-md-49 {
        padding-left: 24rem !important;
        padding-right: 24rem !important;
    }

    .px-md-50 {
        padding-left: 24.5rem !important;
        padding-right: 24.5rem !important;
    }

    .px-md-51 {
        padding-left: 25rem !important;
        padding-right: 25rem !important;
    }

    .px-md-52 {
        padding-left: 25.5rem !important;
        padding-right: 25.5rem !important;
    }

    .px-md-53 {
        padding-left: 26rem !important;
        padding-right: 26rem !important;
    }

    .px-md-54 {
        padding-left: 26.5rem !important;
        padding-right: 26.5rem !important;
    }

    .px-md-55 {
        padding-left: 27rem !important;
        padding-right: 27rem !important;
    }

    .px-md-56 {
        padding-left: 27.5rem !important;
        padding-right: 27.5rem !important;
    }

    .px-md-57 {
        padding-left: 28rem !important;
        padding-right: 28rem !important;
    }

    .px-md-58 {
        padding-left: 28.5rem !important;
        padding-right: 28.5rem !important;
    }

    .px-md-59 {
        padding-left: 29rem !important;
        padding-right: 29rem !important;
    }

    .px-md-60 {
        padding-left: 29.5rem !important;
        padding-right: 29.5rem !important;
    }

    .px-md-61 {
        padding-left: 30rem !important;
        padding-right: 30rem !important;
    }

    .px-md-62 {
        padding-left: 30.5rem !important;
        padding-right: 30.5rem !important;
    }

    .px-md-63 {
        padding-left: 31rem !important;
        padding-right: 31rem !important;
    }

    .px-md-64 {
        padding-left: 31.5rem !important;
        padding-right: 31.5rem !important;
    }

    .px-md-65 {
        padding-left: 32rem !important;
        padding-right: 32rem !important;
    }

    .px-md-66 {
        padding-left: 32.5rem !important;
        padding-right: 32.5rem !important;
    }

    .px-md-67 {
        padding-left: 33rem !important;
        padding-right: 33rem !important;
    }

    .px-md-68 {
        padding-left: 33.5rem !important;
        padding-right: 33.5rem !important;
    }

    .px-md-69 {
        padding-left: 34rem !important;
        padding-right: 34rem !important;
    }

    .px-md-70 {
        padding-left: 34.5rem !important;
        padding-right: 34.5rem !important;
    }

    .px-md-71 {
        padding-left: 35rem !important;
        padding-right: 35rem !important;
    }

    .px-md-72 {
        padding-left: 35.5rem !important;
        padding-right: 35.5rem !important;
    }

    .px-md-73 {
        padding-left: 36rem !important;
        padding-right: 36rem !important;
    }

    .px-md-74 {
        padding-left: 36.5rem !important;
        padding-right: 36.5rem !important;
    }

    .px-md-75 {
        padding-left: 37rem !important;
        padding-right: 37rem !important;
    }

    .px-md-76 {
        padding-left: 37.5rem !important;
        padding-right: 37.5rem !important;
    }

    .px-md-77 {
        padding-left: 38rem !important;
        padding-right: 38rem !important;
    }

    .px-md-78 {
        padding-left: 38.5rem !important;
        padding-right: 38.5rem !important;
    }

    .px-md-79 {
        padding-left: 39rem !important;
        padding-right: 39rem !important;
    }

    .px-md-80 {
        padding-left: 39.5rem !important;
        padding-right: 39.5rem !important;
    }

    .px-md-81 {
        padding-left: 40rem !important;
        padding-right: 40rem !important;
    }

    .px-md-82 {
        padding-left: 40.5rem !important;
        padding-right: 40.5rem !important;
    }

    .px-md-83 {
        padding-left: 41rem !important;
        padding-right: 41rem !important;
    }

    .px-md-84 {
        padding-left: 41.5rem !important;
        padding-right: 41.5rem !important;
    }

    .px-md-85 {
        padding-left: 42rem !important;
        padding-right: 42rem !important;
    }

    .px-md-86 {
        padding-left: 42.5rem !important;
        padding-right: 42.5rem !important;
    }

    .px-md-87 {
        padding-left: 43rem !important;
        padding-right: 43rem !important;
    }

    .px-md-88 {
        padding-left: 43.5rem !important;
        padding-right: 43.5rem !important;
    }

    .px-md-89 {
        padding-left: 44rem !important;
        padding-right: 44rem !important;
    }

    .px-md-90 {
        padding-left: 44.5rem !important;
        padding-right: 44.5rem !important;
    }

    .px-md-91 {
        padding-left: 45rem !important;
        padding-right: 45rem !important;
    }

    .px-md-92 {
        padding-left: 45.5rem !important;
        padding-right: 45.5rem !important;
    }

    .px-md-93 {
        padding-left: 46rem !important;
        padding-right: 46rem !important;
    }

    .px-md-94 {
        padding-left: 46.5rem !important;
        padding-right: 46.5rem !important;
    }

    .px-md-95 {
        padding-left: 47rem !important;
        padding-right: 47rem !important;
    }

    .px-md-96 {
        padding-left: 47.5rem !important;
        padding-right: 47.5rem !important;
    }

    .px-md-97 {
        padding-left: 48rem !important;
        padding-right: 48rem !important;
    }

    .px-md-98 {
        padding-left: 48.5rem !important;
        padding-right: 48.5rem !important;
    }

    .px-md-99 {
        padding-left: 49rem !important;
        padding-right: 49rem !important;
    }

    .px-md-100 {
        padding-left: 49.5rem !important;
        padding-right: 49.5rem !important;
    }

    .px-md-101 {
        padding-left: 50rem !important;
        padding-right: 50rem !important;
    }

    .px-md-102 {
        padding-left: 50.5rem !important;
        padding-right: 50.5rem !important;
    }

    .px-md-103 {
        padding-left: 51rem !important;
        padding-right: 51rem !important;
    }

    .px-md-104 {
        padding-left: 51.5rem !important;
        padding-right: 51.5rem !important;
    }

    .px-md-105 {
        padding-left: 52rem !important;
        padding-right: 52rem !important;
    }

    .px-md-106 {
        padding-left: 52.5rem !important;
        padding-right: 52.5rem !important;
    }

    .px-md-107 {
        padding-left: 53rem !important;
        padding-right: 53rem !important;
    }

    .px-md-108 {
        padding-left: 53.5rem !important;
        padding-right: 53.5rem !important;
    }

    .px-md-109 {
        padding-left: 54rem !important;
        padding-right: 54rem !important;
    }

    .px-md-110 {
        padding-left: 54.5rem !important;
        padding-right: 54.5rem !important;
    }

    .px-md-111 {
        padding-left: 55rem !important;
        padding-right: 55rem !important;
    }

    .px-md-112 {
        padding-left: 55.5rem !important;
        padding-right: 55.5rem !important;
    }

    .px-md-113 {
        padding-left: 56rem !important;
        padding-right: 56rem !important;
    }

    .px-md-114 {
        padding-left: 56.5rem !important;
        padding-right: 56.5rem !important;
    }

    .px-md-115 {
        padding-left: 57rem !important;
        padding-right: 57rem !important;
    }

    .px-md-116 {
        padding-left: 57.5rem !important;
        padding-right: 57.5rem !important;
    }

    .px-md-117 {
        padding-left: 58rem !important;
        padding-right: 58rem !important;
    }

    .px-md-118 {
        padding-left: 58.5rem !important;
        padding-right: 58.5rem !important;
    }

    .px-md-119 {
        padding-left: 59rem !important;
        padding-right: 59rem !important;
    }

    .px-md-120 {
        padding-left: 59.5rem !important;
        padding-right: 59.5rem !important;
    }

    .px-md-121 {
        padding-left: 60rem !important;
        padding-right: 60rem !important;
    }

    .px-md-122 {
        padding-left: 60.5rem !important;
        padding-right: 60.5rem !important;
    }

    .px-md-123 {
        padding-left: 61rem !important;
        padding-right: 61rem !important;
    }

    .px-md-124 {
        padding-left: 61.5rem !important;
        padding-right: 61.5rem !important;
    }

    .px-md-125 {
        padding-left: 62rem !important;
        padding-right: 62rem !important;
    }

    .px-md-126 {
        padding-left: 62.5rem !important;
        padding-right: 62.5rem !important;
    }

    .px-md-127 {
        padding-left: 63rem !important;
        padding-right: 63rem !important;
    }

    .px-md-128 {
        padding-left: 63.5rem !important;
        padding-right: 63.5rem !important;
    }

    .px-md-129 {
        padding-left: 64rem !important;
        padding-right: 64rem !important;
    }

    .px-md-130 {
        padding-left: 64.5rem !important;
        padding-right: 64.5rem !important;
    }

    .px-md-131 {
        padding-left: 65rem !important;
        padding-right: 65rem !important;
    }

    .px-md-132 {
        padding-left: 65.5rem !important;
        padding-right: 65.5rem !important;
    }

    .px-md-133 {
        padding-left: 66rem !important;
        padding-right: 66rem !important;
    }

    .px-md-134 {
        padding-left: 66.5rem !important;
        padding-right: 66.5rem !important;
    }

    .px-md-135 {
        padding-left: 67rem !important;
        padding-right: 67rem !important;
    }

    .px-md-136 {
        padding-left: 67.5rem !important;
        padding-right: 67.5rem !important;
    }

    .px-md-137 {
        padding-left: 68rem !important;
        padding-right: 68rem !important;
    }

    .px-md-138 {
        padding-left: 68.5rem !important;
        padding-right: 68.5rem !important;
    }

    .px-md-139 {
        padding-left: 69rem !important;
        padding-right: 69rem !important;
    }

    .px-md-140 {
        padding-left: 69.5rem !important;
        padding-right: 69.5rem !important;
    }

    .px-md-141 {
        padding-left: 70rem !important;
        padding-right: 70rem !important;
    }

    .px-md-142 {
        padding-left: 70.5rem !important;
        padding-right: 70.5rem !important;
    }

    .px-md-143 {
        padding-left: 71rem !important;
        padding-right: 71rem !important;
    }

    .px-md-144 {
        padding-left: 71.5rem !important;
        padding-right: 71.5rem !important;
    }

    .px-md-145 {
        padding-left: 72rem !important;
        padding-right: 72rem !important;
    }

    .px-md-146 {
        padding-left: 72.5rem !important;
        padding-right: 72.5rem !important;
    }

    .px-md-147 {
        padding-left: 73rem !important;
        padding-right: 73rem !important;
    }

    .px-md-148 {
        padding-left: 73.5rem !important;
        padding-right: 73.5rem !important;
    }

    .px-md-149 {
        padding-left: 74rem !important;
        padding-right: 74rem !important;
    }

    .px-md-150 {
        padding-left: 74.5rem !important;
        padding-right: 74.5rem !important;
    }

    .px-md-151 {
        padding-left: 75rem !important;
        padding-right: 75rem !important;
    }

    .px-md-152 {
        padding-left: 75.5rem !important;
        padding-right: 75.5rem !important;
    }

    .px-md-153 {
        padding-left: 76rem !important;
        padding-right: 76rem !important;
    }

    .px-md-154 {
        padding-left: 76.5rem !important;
        padding-right: 76.5rem !important;
    }

    .px-md-155 {
        padding-left: 77rem !important;
        padding-right: 77rem !important;
    }

    .px-md-156 {
        padding-left: 77.5rem !important;
        padding-right: 77.5rem !important;
    }

    .px-md-157 {
        padding-left: 78rem !important;
        padding-right: 78rem !important;
    }

    .px-md-158 {
        padding-left: 78.5rem !important;
        padding-right: 78.5rem !important;
    }

    .px-md-159 {
        padding-left: 79rem !important;
        padding-right: 79rem !important;
    }

    .px-md-160 {
        padding-left: 79.5rem !important;
        padding-right: 79.5rem !important;
    }

    .py-md-0 {
        padding-top: 0rem !important;
        padding-bottom: 0rem !important;
    }

    .py-md-1 {
        padding-top: 0.25rem !important;
        padding-bottom: 0.25rem !important;
    }

    .py-md-2 {
        padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;
    }

    .py-md-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
    }

    .py-md-4 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important;
    }

    .py-md-5 {
        padding-top: 2rem !important;
        padding-bottom: 2rem !important;
    }

    .py-md-6 {
        padding-top: 2.5rem !important;
        padding-bottom: 2.5rem !important;
    }

    .py-md-7 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important;
    }

    .py-md-8 {
        padding-top: 3.5rem !important;
        padding-bottom: 3.5rem !important;
    }

    .py-md-9 {
        padding-top: 4rem !important;
        padding-bottom: 4rem !important;
    }

    .py-md-10 {
        padding-top: 4.5rem !important;
        padding-bottom: 4.5rem !important;
    }

    .py-md-11 {
        padding-top: 5rem !important;
        padding-bottom: 5rem !important;
    }

    .py-md-12 {
        padding-top: 5.5rem !important;
        padding-bottom: 5.5rem !important;
    }

    .py-md-13 {
        padding-top: 6rem !important;
        padding-bottom: 6rem !important;
    }

    .py-md-14 {
        padding-top: 6.5rem !important;
        padding-bottom: 6.5rem !important;
    }

    .py-md-15 {
        padding-top: 7rem !important;
        padding-bottom: 7rem !important;
    }

    .py-md-16 {
        padding-top: 7.5rem !important;
        padding-bottom: 7.5rem !important;
    }

    .py-md-17 {
        padding-top: 8rem !important;
        padding-bottom: 8rem !important;
    }

    .py-md-18 {
        padding-top: 8.5rem !important;
        padding-bottom: 8.5rem !important;
    }

    .py-md-19 {
        padding-top: 9rem !important;
        padding-bottom: 9rem !important;
    }

    .py-md-20 {
        padding-top: 9.5rem !important;
        padding-bottom: 9.5rem !important;
    }

    .py-md-21 {
        padding-top: 10rem !important;
        padding-bottom: 10rem !important;
    }

    .py-md-22 {
        padding-top: 10.5rem !important;
        padding-bottom: 10.5rem !important;
    }

    .py-md-23 {
        padding-top: 11rem !important;
        padding-bottom: 11rem !important;
    }

    .py-md-24 {
        padding-top: 11.5rem !important;
        padding-bottom: 11.5rem !important;
    }

    .py-md-25 {
        padding-top: 12rem !important;
        padding-bottom: 12rem !important;
    }

    .py-md-26 {
        padding-top: 12.5rem !important;
        padding-bottom: 12.5rem !important;
    }

    .py-md-27 {
        padding-top: 13rem !important;
        padding-bottom: 13rem !important;
    }

    .py-md-28 {
        padding-top: 13.5rem !important;
        padding-bottom: 13.5rem !important;
    }

    .py-md-29 {
        padding-top: 14rem !important;
        padding-bottom: 14rem !important;
    }

    .py-md-30 {
        padding-top: 14.5rem !important;
        padding-bottom: 14.5rem !important;
    }

    .py-md-31 {
        padding-top: 15rem !important;
        padding-bottom: 15rem !important;
    }

    .py-md-32 {
        padding-top: 15.5rem !important;
        padding-bottom: 15.5rem !important;
    }

    .py-md-33 {
        padding-top: 16rem !important;
        padding-bottom: 16rem !important;
    }

    .py-md-34 {
        padding-top: 16.5rem !important;
        padding-bottom: 16.5rem !important;
    }

    .py-md-35 {
        padding-top: 17rem !important;
        padding-bottom: 17rem !important;
    }

    .py-md-36 {
        padding-top: 17.5rem !important;
        padding-bottom: 17.5rem !important;
    }

    .py-md-37 {
        padding-top: 18rem !important;
        padding-bottom: 18rem !important;
    }

    .py-md-38 {
        padding-top: 18.5rem !important;
        padding-bottom: 18.5rem !important;
    }

    .py-md-39 {
        padding-top: 19rem !important;
        padding-bottom: 19rem !important;
    }

    .py-md-40 {
        padding-top: 19.5rem !important;
        padding-bottom: 19.5rem !important;
    }

    .py-md-41 {
        padding-top: 20rem !important;
        padding-bottom: 20rem !important;
    }

    .py-md-42 {
        padding-top: 20.5rem !important;
        padding-bottom: 20.5rem !important;
    }

    .py-md-43 {
        padding-top: 21rem !important;
        padding-bottom: 21rem !important;
    }

    .py-md-44 {
        padding-top: 21.5rem !important;
        padding-bottom: 21.5rem !important;
    }

    .py-md-45 {
        padding-top: 22rem !important;
        padding-bottom: 22rem !important;
    }

    .py-md-46 {
        padding-top: 22.5rem !important;
        padding-bottom: 22.5rem !important;
    }

    .py-md-47 {
        padding-top: 23rem !important;
        padding-bottom: 23rem !important;
    }

    .py-md-48 {
        padding-top: 23.5rem !important;
        padding-bottom: 23.5rem !important;
    }

    .py-md-49 {
        padding-top: 24rem !important;
        padding-bottom: 24rem !important;
    }

    .py-md-50 {
        padding-top: 24.5rem !important;
        padding-bottom: 24.5rem !important;
    }

    .py-md-51 {
        padding-top: 25rem !important;
        padding-bottom: 25rem !important;
    }

    .py-md-52 {
        padding-top: 25.5rem !important;
        padding-bottom: 25.5rem !important;
    }

    .py-md-53 {
        padding-top: 26rem !important;
        padding-bottom: 26rem !important;
    }

    .py-md-54 {
        padding-top: 26.5rem !important;
        padding-bottom: 26.5rem !important;
    }

    .py-md-55 {
        padding-top: 27rem !important;
        padding-bottom: 27rem !important;
    }

    .py-md-56 {
        padding-top: 27.5rem !important;
        padding-bottom: 27.5rem !important;
    }

    .py-md-57 {
        padding-top: 28rem !important;
        padding-bottom: 28rem !important;
    }

    .py-md-58 {
        padding-top: 28.5rem !important;
        padding-bottom: 28.5rem !important;
    }

    .py-md-59 {
        padding-top: 29rem !important;
        padding-bottom: 29rem !important;
    }

    .py-md-60 {
        padding-top: 29.5rem !important;
        padding-bottom: 29.5rem !important;
    }

    .py-md-61 {
        padding-top: 30rem !important;
        padding-bottom: 30rem !important;
    }

    .py-md-62 {
        padding-top: 30.5rem !important;
        padding-bottom: 30.5rem !important;
    }

    .py-md-63 {
        padding-top: 31rem !important;
        padding-bottom: 31rem !important;
    }

    .py-md-64 {
        padding-top: 31.5rem !important;
        padding-bottom: 31.5rem !important;
    }

    .py-md-65 {
        padding-top: 32rem !important;
        padding-bottom: 32rem !important;
    }

    .py-md-66 {
        padding-top: 32.5rem !important;
        padding-bottom: 32.5rem !important;
    }

    .py-md-67 {
        padding-top: 33rem !important;
        padding-bottom: 33rem !important;
    }

    .py-md-68 {
        padding-top: 33.5rem !important;
        padding-bottom: 33.5rem !important;
    }

    .py-md-69 {
        padding-top: 34rem !important;
        padding-bottom: 34rem !important;
    }

    .py-md-70 {
        padding-top: 34.5rem !important;
        padding-bottom: 34.5rem !important;
    }

    .py-md-71 {
        padding-top: 35rem !important;
        padding-bottom: 35rem !important;
    }

    .py-md-72 {
        padding-top: 35.5rem !important;
        padding-bottom: 35.5rem !important;
    }

    .py-md-73 {
        padding-top: 36rem !important;
        padding-bottom: 36rem !important;
    }

    .py-md-74 {
        padding-top: 36.5rem !important;
        padding-bottom: 36.5rem !important;
    }

    .py-md-75 {
        padding-top: 37rem !important;
        padding-bottom: 37rem !important;
    }

    .py-md-76 {
        padding-top: 37.5rem !important;
        padding-bottom: 37.5rem !important;
    }

    .py-md-77 {
        padding-top: 38rem !important;
        padding-bottom: 38rem !important;
    }

    .py-md-78 {
        padding-top: 38.5rem !important;
        padding-bottom: 38.5rem !important;
    }

    .py-md-79 {
        padding-top: 39rem !important;
        padding-bottom: 39rem !important;
    }

    .py-md-80 {
        padding-top: 39.5rem !important;
        padding-bottom: 39.5rem !important;
    }

    .py-md-81 {
        padding-top: 40rem !important;
        padding-bottom: 40rem !important;
    }

    .py-md-82 {
        padding-top: 40.5rem !important;
        padding-bottom: 40.5rem !important;
    }

    .py-md-83 {
        padding-top: 41rem !important;
        padding-bottom: 41rem !important;
    }

    .py-md-84 {
        padding-top: 41.5rem !important;
        padding-bottom: 41.5rem !important;
    }

    .py-md-85 {
        padding-top: 42rem !important;
        padding-bottom: 42rem !important;
    }

    .py-md-86 {
        padding-top: 42.5rem !important;
        padding-bottom: 42.5rem !important;
    }

    .py-md-87 {
        padding-top: 43rem !important;
        padding-bottom: 43rem !important;
    }

    .py-md-88 {
        padding-top: 43.5rem !important;
        padding-bottom: 43.5rem !important;
    }

    .py-md-89 {
        padding-top: 44rem !important;
        padding-bottom: 44rem !important;
    }

    .py-md-90 {
        padding-top: 44.5rem !important;
        padding-bottom: 44.5rem !important;
    }

    .py-md-91 {
        padding-top: 45rem !important;
        padding-bottom: 45rem !important;
    }

    .py-md-92 {
        padding-top: 45.5rem !important;
        padding-bottom: 45.5rem !important;
    }

    .py-md-93 {
        padding-top: 46rem !important;
        padding-bottom: 46rem !important;
    }

    .py-md-94 {
        padding-top: 46.5rem !important;
        padding-bottom: 46.5rem !important;
    }

    .py-md-95 {
        padding-top: 47rem !important;
        padding-bottom: 47rem !important;
    }

    .py-md-96 {
        padding-top: 47.5rem !important;
        padding-bottom: 47.5rem !important;
    }

    .py-md-97 {
        padding-top: 48rem !important;
        padding-bottom: 48rem !important;
    }

    .py-md-98 {
        padding-top: 48.5rem !important;
        padding-bottom: 48.5rem !important;
    }

    .py-md-99 {
        padding-top: 49rem !important;
        padding-bottom: 49rem !important;
    }

    .py-md-100 {
        padding-top: 49.5rem !important;
        padding-bottom: 49.5rem !important;
    }

    .py-md-101 {
        padding-top: 50rem !important;
        padding-bottom: 50rem !important;
    }

    .py-md-102 {
        padding-top: 50.5rem !important;
        padding-bottom: 50.5rem !important;
    }

    .py-md-103 {
        padding-top: 51rem !important;
        padding-bottom: 51rem !important;
    }

    .py-md-104 {
        padding-top: 51.5rem !important;
        padding-bottom: 51.5rem !important;
    }

    .py-md-105 {
        padding-top: 52rem !important;
        padding-bottom: 52rem !important;
    }

    .py-md-106 {
        padding-top: 52.5rem !important;
        padding-bottom: 52.5rem !important;
    }

    .py-md-107 {
        padding-top: 53rem !important;
        padding-bottom: 53rem !important;
    }

    .py-md-108 {
        padding-top: 53.5rem !important;
        padding-bottom: 53.5rem !important;
    }

    .py-md-109 {
        padding-top: 54rem !important;
        padding-bottom: 54rem !important;
    }

    .py-md-110 {
        padding-top: 54.5rem !important;
        padding-bottom: 54.5rem !important;
    }

    .py-md-111 {
        padding-top: 55rem !important;
        padding-bottom: 55rem !important;
    }

    .py-md-112 {
        padding-top: 55.5rem !important;
        padding-bottom: 55.5rem !important;
    }

    .py-md-113 {
        padding-top: 56rem !important;
        padding-bottom: 56rem !important;
    }

    .py-md-114 {
        padding-top: 56.5rem !important;
        padding-bottom: 56.5rem !important;
    }

    .py-md-115 {
        padding-top: 57rem !important;
        padding-bottom: 57rem !important;
    }

    .py-md-116 {
        padding-top: 57.5rem !important;
        padding-bottom: 57.5rem !important;
    }

    .py-md-117 {
        padding-top: 58rem !important;
        padding-bottom: 58rem !important;
    }

    .py-md-118 {
        padding-top: 58.5rem !important;
        padding-bottom: 58.5rem !important;
    }

    .py-md-119 {
        padding-top: 59rem !important;
        padding-bottom: 59rem !important;
    }

    .py-md-120 {
        padding-top: 59.5rem !important;
        padding-bottom: 59.5rem !important;
    }

    .py-md-121 {
        padding-top: 60rem !important;
        padding-bottom: 60rem !important;
    }

    .py-md-122 {
        padding-top: 60.5rem !important;
        padding-bottom: 60.5rem !important;
    }

    .py-md-123 {
        padding-top: 61rem !important;
        padding-bottom: 61rem !important;
    }

    .py-md-124 {
        padding-top: 61.5rem !important;
        padding-bottom: 61.5rem !important;
    }

    .py-md-125 {
        padding-top: 62rem !important;
        padding-bottom: 62rem !important;
    }

    .py-md-126 {
        padding-top: 62.5rem !important;
        padding-bottom: 62.5rem !important;
    }

    .py-md-127 {
        padding-top: 63rem !important;
        padding-bottom: 63rem !important;
    }

    .py-md-128 {
        padding-top: 63.5rem !important;
        padding-bottom: 63.5rem !important;
    }

    .py-md-129 {
        padding-top: 64rem !important;
        padding-bottom: 64rem !important;
    }

    .py-md-130 {
        padding-top: 64.5rem !important;
        padding-bottom: 64.5rem !important;
    }

    .py-md-131 {
        padding-top: 65rem !important;
        padding-bottom: 65rem !important;
    }

    .py-md-132 {
        padding-top: 65.5rem !important;
        padding-bottom: 65.5rem !important;
    }

    .py-md-133 {
        padding-top: 66rem !important;
        padding-bottom: 66rem !important;
    }

    .py-md-134 {
        padding-top: 66.5rem !important;
        padding-bottom: 66.5rem !important;
    }

    .py-md-135 {
        padding-top: 67rem !important;
        padding-bottom: 67rem !important;
    }

    .py-md-136 {
        padding-top: 67.5rem !important;
        padding-bottom: 67.5rem !important;
    }

    .py-md-137 {
        padding-top: 68rem !important;
        padding-bottom: 68rem !important;
    }

    .py-md-138 {
        padding-top: 68.5rem !important;
        padding-bottom: 68.5rem !important;
    }

    .py-md-139 {
        padding-top: 69rem !important;
        padding-bottom: 69rem !important;
    }

    .py-md-140 {
        padding-top: 69.5rem !important;
        padding-bottom: 69.5rem !important;
    }

    .py-md-141 {
        padding-top: 70rem !important;
        padding-bottom: 70rem !important;
    }

    .py-md-142 {
        padding-top: 70.5rem !important;
        padding-bottom: 70.5rem !important;
    }

    .py-md-143 {
        padding-top: 71rem !important;
        padding-bottom: 71rem !important;
    }

    .py-md-144 {
        padding-top: 71.5rem !important;
        padding-bottom: 71.5rem !important;
    }

    .py-md-145 {
        padding-top: 72rem !important;
        padding-bottom: 72rem !important;
    }

    .py-md-146 {
        padding-top: 72.5rem !important;
        padding-bottom: 72.5rem !important;
    }

    .py-md-147 {
        padding-top: 73rem !important;
        padding-bottom: 73rem !important;
    }

    .py-md-148 {
        padding-top: 73.5rem !important;
        padding-bottom: 73.5rem !important;
    }

    .py-md-149 {
        padding-top: 74rem !important;
        padding-bottom: 74rem !important;
    }

    .py-md-150 {
        padding-top: 74.5rem !important;
        padding-bottom: 74.5rem !important;
    }

    .py-md-151 {
        padding-top: 75rem !important;
        padding-bottom: 75rem !important;
    }

    .py-md-152 {
        padding-top: 75.5rem !important;
        padding-bottom: 75.5rem !important;
    }

    .py-md-153 {
        padding-top: 76rem !important;
        padding-bottom: 76rem !important;
    }

    .py-md-154 {
        padding-top: 76.5rem !important;
        padding-bottom: 76.5rem !important;
    }

    .py-md-155 {
        padding-top: 77rem !important;
        padding-bottom: 77rem !important;
    }

    .py-md-156 {
        padding-top: 77.5rem !important;
        padding-bottom: 77.5rem !important;
    }

    .py-md-157 {
        padding-top: 78rem !important;
        padding-bottom: 78rem !important;
    }

    .py-md-158 {
        padding-top: 78.5rem !important;
        padding-bottom: 78.5rem !important;
    }

    .py-md-159 {
        padding-top: 79rem !important;
        padding-bottom: 79rem !important;
    }

    .py-md-160 {
        padding-top: 79.5rem !important;
        padding-bottom: 79.5rem !important;
    }
}

@media (min-width: 992px) {
    .p-lg-0 {
        padding: 0rem !important;
    }

    .p-lg-1 {
        padding: 0.25rem !important;
    }

    .p-lg-2 {
        padding: 0.5rem !important;
    }

    .p-lg-3 {
        padding: 1rem !important;
    }

    .p-lg-4 {
        padding: 1.5rem !important;
    }

    .p-lg-5 {
        padding: 2rem !important;
    }

    .p-lg-6 {
        padding: 2.5rem !important;
    }

    .p-lg-7 {
        padding: 3rem !important;
    }

    .p-lg-8 {
        padding: 3.5rem !important;
    }

    .p-lg-9 {
        padding: 4rem !important;
    }

    .p-lg-10 {
        padding: 4.5rem !important;
    }

    .p-lg-11 {
        padding: 5rem !important;
    }

    .p-lg-12 {
        padding: 5.5rem !important;
    }

    .p-lg-13 {
        padding: 6rem !important;
    }

    .p-lg-14 {
        padding: 6.5rem !important;
    }

    .p-lg-15 {
        padding: 7rem !important;
    }

    .p-lg-16 {
        padding: 7.5rem !important;
    }

    .p-lg-17 {
        padding: 8rem !important;
    }

    .p-lg-18 {
        padding: 8.5rem !important;
    }

    .p-lg-19 {
        padding: 9rem !important;
    }

    .p-lg-20 {
        padding: 9.5rem !important;
    }

    .p-lg-21 {
        padding: 10rem !important;
    }

    .p-lg-22 {
        padding: 10.5rem !important;
    }

    .p-lg-23 {
        padding: 11rem !important;
    }

    .p-lg-24 {
        padding: 11.5rem !important;
    }

    .p-lg-25 {
        padding: 12rem !important;
    }

    .p-lg-26 {
        padding: 12.5rem !important;
    }

    .p-lg-27 {
        padding: 13rem !important;
    }

    .p-lg-28 {
        padding: 13.5rem !important;
    }

    .p-lg-29 {
        padding: 14rem !important;
    }

    .p-lg-30 {
        padding: 14.5rem !important;
    }

    .p-lg-31 {
        padding: 15rem !important;
    }

    .p-lg-32 {
        padding: 15.5rem !important;
    }

    .p-lg-33 {
        padding: 16rem !important;
    }

    .p-lg-34 {
        padding: 16.5rem !important;
    }

    .p-lg-35 {
        padding: 17rem !important;
    }

    .p-lg-36 {
        padding: 17.5rem !important;
    }

    .p-lg-37 {
        padding: 18rem !important;
    }

    .p-lg-38 {
        padding: 18.5rem !important;
    }

    .p-lg-39 {
        padding: 19rem !important;
    }

    .p-lg-40 {
        padding: 19.5rem !important;
    }

    .p-lg-41 {
        padding: 20rem !important;
    }

    .p-lg-42 {
        padding: 20.5rem !important;
    }

    .p-lg-43 {
        padding: 21rem !important;
    }

    .p-lg-44 {
        padding: 21.5rem !important;
    }

    .p-lg-45 {
        padding: 22rem !important;
    }

    .p-lg-46 {
        padding: 22.5rem !important;
    }

    .p-lg-47 {
        padding: 23rem !important;
    }

    .p-lg-48 {
        padding: 23.5rem !important;
    }

    .p-lg-49 {
        padding: 24rem !important;
    }

    .p-lg-50 {
        padding: 24.5rem !important;
    }

    .p-lg-51 {
        padding: 25rem !important;
    }

    .p-lg-52 {
        padding: 25.5rem !important;
    }

    .p-lg-53 {
        padding: 26rem !important;
    }

    .p-lg-54 {
        padding: 26.5rem !important;
    }

    .p-lg-55 {
        padding: 27rem !important;
    }

    .p-lg-56 {
        padding: 27.5rem !important;
    }

    .p-lg-57 {
        padding: 28rem !important;
    }

    .p-lg-58 {
        padding: 28.5rem !important;
    }

    .p-lg-59 {
        padding: 29rem !important;
    }

    .p-lg-60 {
        padding: 29.5rem !important;
    }

    .p-lg-61 {
        padding: 30rem !important;
    }

    .p-lg-62 {
        padding: 30.5rem !important;
    }

    .p-lg-63 {
        padding: 31rem !important;
    }

    .p-lg-64 {
        padding: 31.5rem !important;
    }

    .p-lg-65 {
        padding: 32rem !important;
    }

    .p-lg-66 {
        padding: 32.5rem !important;
    }

    .p-lg-67 {
        padding: 33rem !important;
    }

    .p-lg-68 {
        padding: 33.5rem !important;
    }

    .p-lg-69 {
        padding: 34rem !important;
    }

    .p-lg-70 {
        padding: 34.5rem !important;
    }

    .p-lg-71 {
        padding: 35rem !important;
    }

    .p-lg-72 {
        padding: 35.5rem !important;
    }

    .p-lg-73 {
        padding: 36rem !important;
    }

    .p-lg-74 {
        padding: 36.5rem !important;
    }

    .p-lg-75 {
        padding: 37rem !important;
    }

    .p-lg-76 {
        padding: 37.5rem !important;
    }

    .p-lg-77 {
        padding: 38rem !important;
    }

    .p-lg-78 {
        padding: 38.5rem !important;
    }

    .p-lg-79 {
        padding: 39rem !important;
    }

    .p-lg-80 {
        padding: 39.5rem !important;
    }

    .p-lg-81 {
        padding: 40rem !important;
    }

    .p-lg-82 {
        padding: 40.5rem !important;
    }

    .p-lg-83 {
        padding: 41rem !important;
    }

    .p-lg-84 {
        padding: 41.5rem !important;
    }

    .p-lg-85 {
        padding: 42rem !important;
    }

    .p-lg-86 {
        padding: 42.5rem !important;
    }

    .p-lg-87 {
        padding: 43rem !important;
    }

    .p-lg-88 {
        padding: 43.5rem !important;
    }

    .p-lg-89 {
        padding: 44rem !important;
    }

    .p-lg-90 {
        padding: 44.5rem !important;
    }

    .p-lg-91 {
        padding: 45rem !important;
    }

    .p-lg-92 {
        padding: 45.5rem !important;
    }

    .p-lg-93 {
        padding: 46rem !important;
    }

    .p-lg-94 {
        padding: 46.5rem !important;
    }

    .p-lg-95 {
        padding: 47rem !important;
    }

    .p-lg-96 {
        padding: 47.5rem !important;
    }

    .p-lg-97 {
        padding: 48rem !important;
    }

    .p-lg-98 {
        padding: 48.5rem !important;
    }

    .p-lg-99 {
        padding: 49rem !important;
    }

    .p-lg-100 {
        padding: 49.5rem !important;
    }

    .p-lg-101 {
        padding: 50rem !important;
    }

    .p-lg-102 {
        padding: 50.5rem !important;
    }

    .p-lg-103 {
        padding: 51rem !important;
    }

    .p-lg-104 {
        padding: 51.5rem !important;
    }

    .p-lg-105 {
        padding: 52rem !important;
    }

    .p-lg-106 {
        padding: 52.5rem !important;
    }

    .p-lg-107 {
        padding: 53rem !important;
    }

    .p-lg-108 {
        padding: 53.5rem !important;
    }

    .p-lg-109 {
        padding: 54rem !important;
    }

    .p-lg-110 {
        padding: 54.5rem !important;
    }

    .p-lg-111 {
        padding: 55rem !important;
    }

    .p-lg-112 {
        padding: 55.5rem !important;
    }

    .p-lg-113 {
        padding: 56rem !important;
    }

    .p-lg-114 {
        padding: 56.5rem !important;
    }

    .p-lg-115 {
        padding: 57rem !important;
    }

    .p-lg-116 {
        padding: 57.5rem !important;
    }

    .p-lg-117 {
        padding: 58rem !important;
    }

    .p-lg-118 {
        padding: 58.5rem !important;
    }

    .p-lg-119 {
        padding: 59rem !important;
    }

    .p-lg-120 {
        padding: 59.5rem !important;
    }

    .p-lg-121 {
        padding: 60rem !important;
    }

    .p-lg-122 {
        padding: 60.5rem !important;
    }

    .p-lg-123 {
        padding: 61rem !important;
    }

    .p-lg-124 {
        padding: 61.5rem !important;
    }

    .p-lg-125 {
        padding: 62rem !important;
    }

    .p-lg-126 {
        padding: 62.5rem !important;
    }

    .p-lg-127 {
        padding: 63rem !important;
    }

    .p-lg-128 {
        padding: 63.5rem !important;
    }

    .p-lg-129 {
        padding: 64rem !important;
    }

    .p-lg-130 {
        padding: 64.5rem !important;
    }

    .p-lg-131 {
        padding: 65rem !important;
    }

    .p-lg-132 {
        padding: 65.5rem !important;
    }

    .p-lg-133 {
        padding: 66rem !important;
    }

    .p-lg-134 {
        padding: 66.5rem !important;
    }

    .p-lg-135 {
        padding: 67rem !important;
    }

    .p-lg-136 {
        padding: 67.5rem !important;
    }

    .p-lg-137 {
        padding: 68rem !important;
    }

    .p-lg-138 {
        padding: 68.5rem !important;
    }

    .p-lg-139 {
        padding: 69rem !important;
    }

    .p-lg-140 {
        padding: 69.5rem !important;
    }

    .p-lg-141 {
        padding: 70rem !important;
    }

    .p-lg-142 {
        padding: 70.5rem !important;
    }

    .p-lg-143 {
        padding: 71rem !important;
    }

    .p-lg-144 {
        padding: 71.5rem !important;
    }

    .p-lg-145 {
        padding: 72rem !important;
    }

    .p-lg-146 {
        padding: 72.5rem !important;
    }

    .p-lg-147 {
        padding: 73rem !important;
    }

    .p-lg-148 {
        padding: 73.5rem !important;
    }

    .p-lg-149 {
        padding: 74rem !important;
    }

    .p-lg-150 {
        padding: 74.5rem !important;
    }

    .p-lg-151 {
        padding: 75rem !important;
    }

    .p-lg-152 {
        padding: 75.5rem !important;
    }

    .p-lg-153 {
        padding: 76rem !important;
    }

    .p-lg-154 {
        padding: 76.5rem !important;
    }

    .p-lg-155 {
        padding: 77rem !important;
    }

    .p-lg-156 {
        padding: 77.5rem !important;
    }

    .p-lg-157 {
        padding: 78rem !important;
    }

    .p-lg-158 {
        padding: 78.5rem !important;
    }

    .p-lg-159 {
        padding: 79rem !important;
    }

    .p-lg-160 {
        padding: 79.5rem !important;
    }

    .pt-lg-0 {
        padding-top: 0rem !important;
    }

    .pt-lg-1 {
        padding-top: 0.25rem !important;
    }

    .pt-lg-2 {
        padding-top: 0.5rem !important;
    }

    .pt-lg-3 {
        padding-top: 1rem !important;
    }

    .pt-lg-4 {
        padding-top: 1.5rem !important;
    }

    .pt-lg-5 {
        padding-top: 2rem !important;
    }

    .pt-lg-6 {
        padding-top: 2.5rem !important;
    }

    .pt-lg-7 {
        padding-top: 3rem !important;
    }

    .pt-lg-8 {
        padding-top: 3.5rem !important;
    }

    .pt-lg-9 {
        padding-top: 4rem !important;
    }

    .pt-lg-10 {
        padding-top: 4.5rem !important;
    }

    .pt-lg-11 {
        padding-top: 5rem !important;
    }

    .pt-lg-12 {
        padding-top: 5.5rem !important;
    }

    .pt-lg-13 {
        padding-top: 6rem !important;
    }

    .pt-lg-14 {
        padding-top: 6.5rem !important;
    }

    .pt-lg-15 {
        padding-top: 7rem !important;
    }

    .pt-lg-16 {
        padding-top: 7.5rem !important;
    }

    .pt-lg-17 {
        padding-top: 8rem !important;
    }

    .pt-lg-18 {
        padding-top: 8.5rem !important;
    }

    .pt-lg-19 {
        padding-top: 9rem !important;
    }

    .pt-lg-20 {
        padding-top: 9.5rem !important;
    }

    .pt-lg-21 {
        padding-top: 10rem !important;
    }

    .pt-lg-22 {
        padding-top: 10.5rem !important;
    }

    .pt-lg-23 {
        padding-top: 11rem !important;
    }

    .pt-lg-24 {
        padding-top: 11.5rem !important;
    }

    .pt-lg-25 {
        padding-top: 12rem !important;
    }

    .pt-lg-26 {
        padding-top: 12.5rem !important;
    }

    .pt-lg-27 {
        padding-top: 13rem !important;
    }

    .pt-lg-28 {
        padding-top: 13.5rem !important;
    }

    .pt-lg-29 {
        padding-top: 14rem !important;
    }

    .pt-lg-30 {
        padding-top: 14.5rem !important;
    }

    .pt-lg-31 {
        padding-top: 15rem !important;
    }

    .pt-lg-32 {
        padding-top: 15.5rem !important;
    }

    .pt-lg-33 {
        padding-top: 16rem !important;
    }

    .pt-lg-34 {
        padding-top: 16.5rem !important;
    }

    .pt-lg-35 {
        padding-top: 17rem !important;
    }

    .pt-lg-36 {
        padding-top: 17.5rem !important;
    }

    .pt-lg-37 {
        padding-top: 18rem !important;
    }

    .pt-lg-38 {
        padding-top: 18.5rem !important;
    }

    .pt-lg-39 {
        padding-top: 19rem !important;
    }

    .pt-lg-40 {
        padding-top: 19.5rem !important;
    }

    .pt-lg-41 {
        padding-top: 20rem !important;
    }

    .pt-lg-42 {
        padding-top: 20.5rem !important;
    }

    .pt-lg-43 {
        padding-top: 21rem !important;
    }

    .pt-lg-44 {
        padding-top: 21.5rem !important;
    }

    .pt-lg-45 {
        padding-top: 22rem !important;
    }

    .pt-lg-46 {
        padding-top: 22.5rem !important;
    }

    .pt-lg-47 {
        padding-top: 23rem !important;
    }

    .pt-lg-48 {
        padding-top: 23.5rem !important;
    }

    .pt-lg-49 {
        padding-top: 24rem !important;
    }

    .pt-lg-50 {
        padding-top: 24.5rem !important;
    }

    .pt-lg-51 {
        padding-top: 25rem !important;
    }

    .pt-lg-52 {
        padding-top: 25.5rem !important;
    }

    .pt-lg-53 {
        padding-top: 26rem !important;
    }

    .pt-lg-54 {
        padding-top: 26.5rem !important;
    }

    .pt-lg-55 {
        padding-top: 27rem !important;
    }

    .pt-lg-56 {
        padding-top: 27.5rem !important;
    }

    .pt-lg-57 {
        padding-top: 28rem !important;
    }

    .pt-lg-58 {
        padding-top: 28.5rem !important;
    }

    .pt-lg-59 {
        padding-top: 29rem !important;
    }

    .pt-lg-60 {
        padding-top: 29.5rem !important;
    }

    .pt-lg-61 {
        padding-top: 30rem !important;
    }

    .pt-lg-62 {
        padding-top: 30.5rem !important;
    }

    .pt-lg-63 {
        padding-top: 31rem !important;
    }

    .pt-lg-64 {
        padding-top: 31.5rem !important;
    }

    .pt-lg-65 {
        padding-top: 32rem !important;
    }

    .pt-lg-66 {
        padding-top: 32.5rem !important;
    }

    .pt-lg-67 {
        padding-top: 33rem !important;
    }

    .pt-lg-68 {
        padding-top: 33.5rem !important;
    }

    .pt-lg-69 {
        padding-top: 34rem !important;
    }

    .pt-lg-70 {
        padding-top: 34.5rem !important;
    }

    .pt-lg-71 {
        padding-top: 35rem !important;
    }

    .pt-lg-72 {
        padding-top: 35.5rem !important;
    }

    .pt-lg-73 {
        padding-top: 36rem !important;
    }

    .pt-lg-74 {
        padding-top: 36.5rem !important;
    }

    .pt-lg-75 {
        padding-top: 37rem !important;
    }

    .pt-lg-76 {
        padding-top: 37.5rem !important;
    }

    .pt-lg-77 {
        padding-top: 38rem !important;
    }

    .pt-lg-78 {
        padding-top: 38.5rem !important;
    }

    .pt-lg-79 {
        padding-top: 39rem !important;
    }

    .pt-lg-80 {
        padding-top: 39.5rem !important;
    }

    .pt-lg-81 {
        padding-top: 40rem !important;
    }

    .pt-lg-82 {
        padding-top: 40.5rem !important;
    }

    .pt-lg-83 {
        padding-top: 41rem !important;
    }

    .pt-lg-84 {
        padding-top: 41.5rem !important;
    }

    .pt-lg-85 {
        padding-top: 42rem !important;
    }

    .pt-lg-86 {
        padding-top: 42.5rem !important;
    }

    .pt-lg-87 {
        padding-top: 43rem !important;
    }

    .pt-lg-88 {
        padding-top: 43.5rem !important;
    }

    .pt-lg-89 {
        padding-top: 44rem !important;
    }

    .pt-lg-90 {
        padding-top: 44.5rem !important;
    }

    .pt-lg-91 {
        padding-top: 45rem !important;
    }

    .pt-lg-92 {
        padding-top: 45.5rem !important;
    }

    .pt-lg-93 {
        padding-top: 46rem !important;
    }

    .pt-lg-94 {
        padding-top: 46.5rem !important;
    }

    .pt-lg-95 {
        padding-top: 47rem !important;
    }

    .pt-lg-96 {
        padding-top: 47.5rem !important;
    }

    .pt-lg-97 {
        padding-top: 48rem !important;
    }

    .pt-lg-98 {
        padding-top: 48.5rem !important;
    }

    .pt-lg-99 {
        padding-top: 49rem !important;
    }

    .pt-lg-100 {
        padding-top: 49.5rem !important;
    }

    .pt-lg-101 {
        padding-top: 50rem !important;
    }

    .pt-lg-102 {
        padding-top: 50.5rem !important;
    }

    .pt-lg-103 {
        padding-top: 51rem !important;
    }

    .pt-lg-104 {
        padding-top: 51.5rem !important;
    }

    .pt-lg-105 {
        padding-top: 52rem !important;
    }

    .pt-lg-106 {
        padding-top: 52.5rem !important;
    }

    .pt-lg-107 {
        padding-top: 53rem !important;
    }

    .pt-lg-108 {
        padding-top: 53.5rem !important;
    }

    .pt-lg-109 {
        padding-top: 54rem !important;
    }

    .pt-lg-110 {
        padding-top: 54.5rem !important;
    }

    .pt-lg-111 {
        padding-top: 55rem !important;
    }

    .pt-lg-112 {
        padding-top: 55.5rem !important;
    }

    .pt-lg-113 {
        padding-top: 56rem !important;
    }

    .pt-lg-114 {
        padding-top: 56.5rem !important;
    }

    .pt-lg-115 {
        padding-top: 57rem !important;
    }

    .pt-lg-116 {
        padding-top: 57.5rem !important;
    }

    .pt-lg-117 {
        padding-top: 58rem !important;
    }

    .pt-lg-118 {
        padding-top: 58.5rem !important;
    }

    .pt-lg-119 {
        padding-top: 59rem !important;
    }

    .pt-lg-120 {
        padding-top: 59.5rem !important;
    }

    .pt-lg-121 {
        padding-top: 60rem !important;
    }

    .pt-lg-122 {
        padding-top: 60.5rem !important;
    }

    .pt-lg-123 {
        padding-top: 61rem !important;
    }

    .pt-lg-124 {
        padding-top: 61.5rem !important;
    }

    .pt-lg-125 {
        padding-top: 62rem !important;
    }

    .pt-lg-126 {
        padding-top: 62.5rem !important;
    }

    .pt-lg-127 {
        padding-top: 63rem !important;
    }

    .pt-lg-128 {
        padding-top: 63.5rem !important;
    }

    .pt-lg-129 {
        padding-top: 64rem !important;
    }

    .pt-lg-130 {
        padding-top: 64.5rem !important;
    }

    .pt-lg-131 {
        padding-top: 65rem !important;
    }

    .pt-lg-132 {
        padding-top: 65.5rem !important;
    }

    .pt-lg-133 {
        padding-top: 66rem !important;
    }

    .pt-lg-134 {
        padding-top: 66.5rem !important;
    }

    .pt-lg-135 {
        padding-top: 67rem !important;
    }

    .pt-lg-136 {
        padding-top: 67.5rem !important;
    }

    .pt-lg-137 {
        padding-top: 68rem !important;
    }

    .pt-lg-138 {
        padding-top: 68.5rem !important;
    }

    .pt-lg-139 {
        padding-top: 69rem !important;
    }

    .pt-lg-140 {
        padding-top: 69.5rem !important;
    }

    .pt-lg-141 {
        padding-top: 70rem !important;
    }

    .pt-lg-142 {
        padding-top: 70.5rem !important;
    }

    .pt-lg-143 {
        padding-top: 71rem !important;
    }

    .pt-lg-144 {
        padding-top: 71.5rem !important;
    }

    .pt-lg-145 {
        padding-top: 72rem !important;
    }

    .pt-lg-146 {
        padding-top: 72.5rem !important;
    }

    .pt-lg-147 {
        padding-top: 73rem !important;
    }

    .pt-lg-148 {
        padding-top: 73.5rem !important;
    }

    .pt-lg-149 {
        padding-top: 74rem !important;
    }

    .pt-lg-150 {
        padding-top: 74.5rem !important;
    }

    .pt-lg-151 {
        padding-top: 75rem !important;
    }

    .pt-lg-152 {
        padding-top: 75.5rem !important;
    }

    .pt-lg-153 {
        padding-top: 76rem !important;
    }

    .pt-lg-154 {
        padding-top: 76.5rem !important;
    }

    .pt-lg-155 {
        padding-top: 77rem !important;
    }

    .pt-lg-156 {
        padding-top: 77.5rem !important;
    }

    .pt-lg-157 {
        padding-top: 78rem !important;
    }

    .pt-lg-158 {
        padding-top: 78.5rem !important;
    }

    .pt-lg-159 {
        padding-top: 79rem !important;
    }

    .pt-lg-160 {
        padding-top: 79.5rem !important;
    }

    .pb-lg-0 {
        padding-bottom: 0rem !important;
    }

    .pb-lg-1 {
        padding-bottom: 0.25rem !important;
    }

    .pb-lg-2 {
        padding-bottom: 0.5rem !important;
    }

    .pb-lg-3 {
        padding-bottom: 1rem !important;
    }

    .pb-lg-4 {
        padding-bottom: 1.5rem !important;
    }

    .pb-lg-5 {
        padding-bottom: 2rem !important;
    }

    .pb-lg-6 {
        padding-bottom: 2.5rem !important;
    }

    .pb-lg-7 {
        padding-bottom: 3rem !important;
    }

    .pb-lg-8 {
        padding-bottom: 3.5rem !important;
    }

    .pb-lg-9 {
        padding-bottom: 4rem !important;
    }

    .pb-lg-10 {
        padding-bottom: 4.5rem !important;
    }

    .pb-lg-11 {
        padding-bottom: 5rem !important;
    }

    .pb-lg-12 {
        padding-bottom: 5.5rem !important;
    }

    .pb-lg-13 {
        padding-bottom: 6rem !important;
    }

    .pb-lg-14 {
        padding-bottom: 6.5rem !important;
    }

    .pb-lg-15 {
        padding-bottom: 7rem !important;
    }

    .pb-lg-16 {
        padding-bottom: 7.5rem !important;
    }

    .pb-lg-17 {
        padding-bottom: 8rem !important;
    }

    .pb-lg-18 {
        padding-bottom: 8.5rem !important;
    }

    .pb-lg-19 {
        padding-bottom: 9rem !important;
    }

    .pb-lg-20 {
        padding-bottom: 9.5rem !important;
    }

    .pb-lg-21 {
        padding-bottom: 10rem !important;
    }

    .pb-lg-22 {
        padding-bottom: 10.5rem !important;
    }

    .pb-lg-23 {
        padding-bottom: 11rem !important;
    }

    .pb-lg-24 {
        padding-bottom: 11.5rem !important;
    }

    .pb-lg-25 {
        padding-bottom: 12rem !important;
    }

    .pb-lg-26 {
        padding-bottom: 12.5rem !important;
    }

    .pb-lg-27 {
        padding-bottom: 13rem !important;
    }

    .pb-lg-28 {
        padding-bottom: 13.5rem !important;
    }

    .pb-lg-29 {
        padding-bottom: 14rem !important;
    }

    .pb-lg-30 {
        padding-bottom: 14.5rem !important;
    }

    .pb-lg-31 {
        padding-bottom: 15rem !important;
    }

    .pb-lg-32 {
        padding-bottom: 15.5rem !important;
    }

    .pb-lg-33 {
        padding-bottom: 16rem !important;
    }

    .pb-lg-34 {
        padding-bottom: 16.5rem !important;
    }

    .pb-lg-35 {
        padding-bottom: 17rem !important;
    }

    .pb-lg-36 {
        padding-bottom: 17.5rem !important;
    }

    .pb-lg-37 {
        padding-bottom: 18rem !important;
    }

    .pb-lg-38 {
        padding-bottom: 18.5rem !important;
    }

    .pb-lg-39 {
        padding-bottom: 19rem !important;
    }

    .pb-lg-40 {
        padding-bottom: 19.5rem !important;
    }

    .pb-lg-41 {
        padding-bottom: 20rem !important;
    }

    .pb-lg-42 {
        padding-bottom: 20.5rem !important;
    }

    .pb-lg-43 {
        padding-bottom: 21rem !important;
    }

    .pb-lg-44 {
        padding-bottom: 21.5rem !important;
    }

    .pb-lg-45 {
        padding-bottom: 22rem !important;
    }

    .pb-lg-46 {
        padding-bottom: 22.5rem !important;
    }

    .pb-lg-47 {
        padding-bottom: 23rem !important;
    }

    .pb-lg-48 {
        padding-bottom: 23.5rem !important;
    }

    .pb-lg-49 {
        padding-bottom: 24rem !important;
    }

    .pb-lg-50 {
        padding-bottom: 24.5rem !important;
    }

    .pb-lg-51 {
        padding-bottom: 25rem !important;
    }

    .pb-lg-52 {
        padding-bottom: 25.5rem !important;
    }

    .pb-lg-53 {
        padding-bottom: 26rem !important;
    }

    .pb-lg-54 {
        padding-bottom: 26.5rem !important;
    }

    .pb-lg-55 {
        padding-bottom: 27rem !important;
    }

    .pb-lg-56 {
        padding-bottom: 27.5rem !important;
    }

    .pb-lg-57 {
        padding-bottom: 28rem !important;
    }

    .pb-lg-58 {
        padding-bottom: 28.5rem !important;
    }

    .pb-lg-59 {
        padding-bottom: 29rem !important;
    }

    .pb-lg-60 {
        padding-bottom: 29.5rem !important;
    }

    .pb-lg-61 {
        padding-bottom: 30rem !important;
    }

    .pb-lg-62 {
        padding-bottom: 30.5rem !important;
    }

    .pb-lg-63 {
        padding-bottom: 31rem !important;
    }

    .pb-lg-64 {
        padding-bottom: 31.5rem !important;
    }

    .pb-lg-65 {
        padding-bottom: 32rem !important;
    }

    .pb-lg-66 {
        padding-bottom: 32.5rem !important;
    }

    .pb-lg-67 {
        padding-bottom: 33rem !important;
    }

    .pb-lg-68 {
        padding-bottom: 33.5rem !important;
    }

    .pb-lg-69 {
        padding-bottom: 34rem !important;
    }

    .pb-lg-70 {
        padding-bottom: 34.5rem !important;
    }

    .pb-lg-71 {
        padding-bottom: 35rem !important;
    }

    .pb-lg-72 {
        padding-bottom: 35.5rem !important;
    }

    .pb-lg-73 {
        padding-bottom: 36rem !important;
    }

    .pb-lg-74 {
        padding-bottom: 36.5rem !important;
    }

    .pb-lg-75 {
        padding-bottom: 37rem !important;
    }

    .pb-lg-76 {
        padding-bottom: 37.5rem !important;
    }

    .pb-lg-77 {
        padding-bottom: 38rem !important;
    }

    .pb-lg-78 {
        padding-bottom: 38.5rem !important;
    }

    .pb-lg-79 {
        padding-bottom: 39rem !important;
    }

    .pb-lg-80 {
        padding-bottom: 39.5rem !important;
    }

    .pb-lg-81 {
        padding-bottom: 40rem !important;
    }

    .pb-lg-82 {
        padding-bottom: 40.5rem !important;
    }

    .pb-lg-83 {
        padding-bottom: 41rem !important;
    }

    .pb-lg-84 {
        padding-bottom: 41.5rem !important;
    }

    .pb-lg-85 {
        padding-bottom: 42rem !important;
    }

    .pb-lg-86 {
        padding-bottom: 42.5rem !important;
    }

    .pb-lg-87 {
        padding-bottom: 43rem !important;
    }

    .pb-lg-88 {
        padding-bottom: 43.5rem !important;
    }

    .pb-lg-89 {
        padding-bottom: 44rem !important;
    }

    .pb-lg-90 {
        padding-bottom: 44.5rem !important;
    }

    .pb-lg-91 {
        padding-bottom: 45rem !important;
    }

    .pb-lg-92 {
        padding-bottom: 45.5rem !important;
    }

    .pb-lg-93 {
        padding-bottom: 46rem !important;
    }

    .pb-lg-94 {
        padding-bottom: 46.5rem !important;
    }

    .pb-lg-95 {
        padding-bottom: 47rem !important;
    }

    .pb-lg-96 {
        padding-bottom: 47.5rem !important;
    }

    .pb-lg-97 {
        padding-bottom: 48rem !important;
    }

    .pb-lg-98 {
        padding-bottom: 48.5rem !important;
    }

    .pb-lg-99 {
        padding-bottom: 49rem !important;
    }

    .pb-lg-100 {
        padding-bottom: 49.5rem !important;
    }

    .pb-lg-101 {
        padding-bottom: 50rem !important;
    }

    .pb-lg-102 {
        padding-bottom: 50.5rem !important;
    }

    .pb-lg-103 {
        padding-bottom: 51rem !important;
    }

    .pb-lg-104 {
        padding-bottom: 51.5rem !important;
    }

    .pb-lg-105 {
        padding-bottom: 52rem !important;
    }

    .pb-lg-106 {
        padding-bottom: 52.5rem !important;
    }

    .pb-lg-107 {
        padding-bottom: 53rem !important;
    }

    .pb-lg-108 {
        padding-bottom: 53.5rem !important;
    }

    .pb-lg-109 {
        padding-bottom: 54rem !important;
    }

    .pb-lg-110 {
        padding-bottom: 54.5rem !important;
    }

    .pb-lg-111 {
        padding-bottom: 55rem !important;
    }

    .pb-lg-112 {
        padding-bottom: 55.5rem !important;
    }

    .pb-lg-113 {
        padding-bottom: 56rem !important;
    }

    .pb-lg-114 {
        padding-bottom: 56.5rem !important;
    }

    .pb-lg-115 {
        padding-bottom: 57rem !important;
    }

    .pb-lg-116 {
        padding-bottom: 57.5rem !important;
    }

    .pb-lg-117 {
        padding-bottom: 58rem !important;
    }

    .pb-lg-118 {
        padding-bottom: 58.5rem !important;
    }

    .pb-lg-119 {
        padding-bottom: 59rem !important;
    }

    .pb-lg-120 {
        padding-bottom: 59.5rem !important;
    }

    .pb-lg-121 {
        padding-bottom: 60rem !important;
    }

    .pb-lg-122 {
        padding-bottom: 60.5rem !important;
    }

    .pb-lg-123 {
        padding-bottom: 61rem !important;
    }

    .pb-lg-124 {
        padding-bottom: 61.5rem !important;
    }

    .pb-lg-125 {
        padding-bottom: 62rem !important;
    }

    .pb-lg-126 {
        padding-bottom: 62.5rem !important;
    }

    .pb-lg-127 {
        padding-bottom: 63rem !important;
    }

    .pb-lg-128 {
        padding-bottom: 63.5rem !important;
    }

    .pb-lg-129 {
        padding-bottom: 64rem !important;
    }

    .pb-lg-130 {
        padding-bottom: 64.5rem !important;
    }

    .pb-lg-131 {
        padding-bottom: 65rem !important;
    }

    .pb-lg-132 {
        padding-bottom: 65.5rem !important;
    }

    .pb-lg-133 {
        padding-bottom: 66rem !important;
    }

    .pb-lg-134 {
        padding-bottom: 66.5rem !important;
    }

    .pb-lg-135 {
        padding-bottom: 67rem !important;
    }

    .pb-lg-136 {
        padding-bottom: 67.5rem !important;
    }

    .pb-lg-137 {
        padding-bottom: 68rem !important;
    }

    .pb-lg-138 {
        padding-bottom: 68.5rem !important;
    }

    .pb-lg-139 {
        padding-bottom: 69rem !important;
    }

    .pb-lg-140 {
        padding-bottom: 69.5rem !important;
    }

    .pb-lg-141 {
        padding-bottom: 70rem !important;
    }

    .pb-lg-142 {
        padding-bottom: 70.5rem !important;
    }

    .pb-lg-143 {
        padding-bottom: 71rem !important;
    }

    .pb-lg-144 {
        padding-bottom: 71.5rem !important;
    }

    .pb-lg-145 {
        padding-bottom: 72rem !important;
    }

    .pb-lg-146 {
        padding-bottom: 72.5rem !important;
    }

    .pb-lg-147 {
        padding-bottom: 73rem !important;
    }

    .pb-lg-148 {
        padding-bottom: 73.5rem !important;
    }

    .pb-lg-149 {
        padding-bottom: 74rem !important;
    }

    .pb-lg-150 {
        padding-bottom: 74.5rem !important;
    }

    .pb-lg-151 {
        padding-bottom: 75rem !important;
    }

    .pb-lg-152 {
        padding-bottom: 75.5rem !important;
    }

    .pb-lg-153 {
        padding-bottom: 76rem !important;
    }

    .pb-lg-154 {
        padding-bottom: 76.5rem !important;
    }

    .pb-lg-155 {
        padding-bottom: 77rem !important;
    }

    .pb-lg-156 {
        padding-bottom: 77.5rem !important;
    }

    .pb-lg-157 {
        padding-bottom: 78rem !important;
    }

    .pb-lg-158 {
        padding-bottom: 78.5rem !important;
    }

    .pb-lg-159 {
        padding-bottom: 79rem !important;
    }

    .pb-lg-160 {
        padding-bottom: 79.5rem !important;
    }

    .ps-lg-0 {
        padding-left: 0rem !important;
    }

    .ps-lg-1 {
        padding-left: 0.25rem !important;
    }

    .ps-lg-2 {
        padding-left: 0.5rem !important;
    }

    .ps-lg-3 {
        padding-left: 1rem !important;
    }

    .ps-lg-4 {
        padding-left: 1.5rem !important;
    }

    .ps-lg-5 {
        padding-left: 2rem !important;
    }

    .ps-lg-6 {
        padding-left: 2.5rem !important;
    }

    .ps-lg-7 {
        padding-left: 3rem !important;
    }

    .ps-lg-8 {
        padding-left: 3.5rem !important;
    }

    .ps-lg-9 {
        padding-left: 4rem !important;
    }

    .ps-lg-10 {
        padding-left: 4.5rem !important;
    }

    .ps-lg-11 {
        padding-left: 5rem !important;
    }

    .ps-lg-12 {
        padding-left: 5.5rem !important;
    }

    .ps-lg-13 {
        padding-left: 6rem !important;
    }

    .ps-lg-14 {
        padding-left: 6.5rem !important;
    }

    .ps-lg-15 {
        padding-left: 7rem !important;
    }

    .ps-lg-16 {
        padding-left: 7.5rem !important;
    }

    .ps-lg-17 {
        padding-left: 8rem !important;
    }

    .ps-lg-18 {
        padding-left: 8.5rem !important;
    }

    .ps-lg-19 {
        padding-left: 9rem !important;
    }

    .ps-lg-20 {
        padding-left: 9.5rem !important;
    }

    .ps-lg-21 {
        padding-left: 10rem !important;
    }

    .ps-lg-22 {
        padding-left: 10.5rem !important;
    }

    .ps-lg-23 {
        padding-left: 11rem !important;
    }

    .ps-lg-24 {
        padding-left: 11.5rem !important;
    }

    .ps-lg-25 {
        padding-left: 12rem !important;
    }

    .ps-lg-26 {
        padding-left: 12.5rem !important;
    }

    .ps-lg-27 {
        padding-left: 13rem !important;
    }

    .ps-lg-28 {
        padding-left: 13.5rem !important;
    }

    .ps-lg-29 {
        padding-left: 14rem !important;
    }

    .ps-lg-30 {
        padding-left: 14.5rem !important;
    }

    .ps-lg-31 {
        padding-left: 15rem !important;
    }

    .ps-lg-32 {
        padding-left: 15.5rem !important;
    }

    .ps-lg-33 {
        padding-left: 16rem !important;
    }

    .ps-lg-34 {
        padding-left: 16.5rem !important;
    }

    .ps-lg-35 {
        padding-left: 17rem !important;
    }

    .ps-lg-36 {
        padding-left: 17.5rem !important;
    }

    .ps-lg-37 {
        padding-left: 18rem !important;
    }

    .ps-lg-38 {
        padding-left: 18.5rem !important;
    }

    .ps-lg-39 {
        padding-left: 19rem !important;
    }

    .ps-lg-40 {
        padding-left: 19.5rem !important;
    }

    .ps-lg-41 {
        padding-left: 20rem !important;
    }

    .ps-lg-42 {
        padding-left: 20.5rem !important;
    }

    .ps-lg-43 {
        padding-left: 21rem !important;
    }

    .ps-lg-44 {
        padding-left: 21.5rem !important;
    }

    .ps-lg-45 {
        padding-left: 22rem !important;
    }

    .ps-lg-46 {
        padding-left: 22.5rem !important;
    }

    .ps-lg-47 {
        padding-left: 23rem !important;
    }

    .ps-lg-48 {
        padding-left: 23.5rem !important;
    }

    .ps-lg-49 {
        padding-left: 24rem !important;
    }

    .ps-lg-50 {
        padding-left: 24.5rem !important;
    }

    .ps-lg-51 {
        padding-left: 25rem !important;
    }

    .ps-lg-52 {
        padding-left: 25.5rem !important;
    }

    .ps-lg-53 {
        padding-left: 26rem !important;
    }

    .ps-lg-54 {
        padding-left: 26.5rem !important;
    }

    .ps-lg-55 {
        padding-left: 27rem !important;
    }

    .ps-lg-56 {
        padding-left: 27.5rem !important;
    }

    .ps-lg-57 {
        padding-left: 28rem !important;
    }

    .ps-lg-58 {
        padding-left: 28.5rem !important;
    }

    .ps-lg-59 {
        padding-left: 29rem !important;
    }

    .ps-lg-60 {
        padding-left: 29.5rem !important;
    }

    .ps-lg-61 {
        padding-left: 30rem !important;
    }

    .ps-lg-62 {
        padding-left: 30.5rem !important;
    }

    .ps-lg-63 {
        padding-left: 31rem !important;
    }

    .ps-lg-64 {
        padding-left: 31.5rem !important;
    }

    .ps-lg-65 {
        padding-left: 32rem !important;
    }

    .ps-lg-66 {
        padding-left: 32.5rem !important;
    }

    .ps-lg-67 {
        padding-left: 33rem !important;
    }

    .ps-lg-68 {
        padding-left: 33.5rem !important;
    }

    .ps-lg-69 {
        padding-left: 34rem !important;
    }

    .ps-lg-70 {
        padding-left: 34.5rem !important;
    }

    .ps-lg-71 {
        padding-left: 35rem !important;
    }

    .ps-lg-72 {
        padding-left: 35.5rem !important;
    }

    .ps-lg-73 {
        padding-left: 36rem !important;
    }

    .ps-lg-74 {
        padding-left: 36.5rem !important;
    }

    .ps-lg-75 {
        padding-left: 37rem !important;
    }

    .ps-lg-76 {
        padding-left: 37.5rem !important;
    }

    .ps-lg-77 {
        padding-left: 38rem !important;
    }

    .ps-lg-78 {
        padding-left: 38.5rem !important;
    }

    .ps-lg-79 {
        padding-left: 39rem !important;
    }

    .ps-lg-80 {
        padding-left: 39.5rem !important;
    }

    .ps-lg-81 {
        padding-left: 40rem !important;
    }

    .ps-lg-82 {
        padding-left: 40.5rem !important;
    }

    .ps-lg-83 {
        padding-left: 41rem !important;
    }

    .ps-lg-84 {
        padding-left: 41.5rem !important;
    }

    .ps-lg-85 {
        padding-left: 42rem !important;
    }

    .ps-lg-86 {
        padding-left: 42.5rem !important;
    }

    .ps-lg-87 {
        padding-left: 43rem !important;
    }

    .ps-lg-88 {
        padding-left: 43.5rem !important;
    }

    .ps-lg-89 {
        padding-left: 44rem !important;
    }

    .ps-lg-90 {
        padding-left: 44.5rem !important;
    }

    .ps-lg-91 {
        padding-left: 45rem !important;
    }

    .ps-lg-92 {
        padding-left: 45.5rem !important;
    }

    .ps-lg-93 {
        padding-left: 46rem !important;
    }

    .ps-lg-94 {
        padding-left: 46.5rem !important;
    }

    .ps-lg-95 {
        padding-left: 47rem !important;
    }

    .ps-lg-96 {
        padding-left: 47.5rem !important;
    }

    .ps-lg-97 {
        padding-left: 48rem !important;
    }

    .ps-lg-98 {
        padding-left: 48.5rem !important;
    }

    .ps-lg-99 {
        padding-left: 49rem !important;
    }

    .ps-lg-100 {
        padding-left: 49.5rem !important;
    }

    .ps-lg-101 {
        padding-left: 50rem !important;
    }

    .ps-lg-102 {
        padding-left: 50.5rem !important;
    }

    .ps-lg-103 {
        padding-left: 51rem !important;
    }

    .ps-lg-104 {
        padding-left: 51.5rem !important;
    }

    .ps-lg-105 {
        padding-left: 52rem !important;
    }

    .ps-lg-106 {
        padding-left: 52.5rem !important;
    }

    .ps-lg-107 {
        padding-left: 53rem !important;
    }

    .ps-lg-108 {
        padding-left: 53.5rem !important;
    }

    .ps-lg-109 {
        padding-left: 54rem !important;
    }

    .ps-lg-110 {
        padding-left: 54.5rem !important;
    }

    .ps-lg-111 {
        padding-left: 55rem !important;
    }

    .ps-lg-112 {
        padding-left: 55.5rem !important;
    }

    .ps-lg-113 {
        padding-left: 56rem !important;
    }

    .ps-lg-114 {
        padding-left: 56.5rem !important;
    }

    .ps-lg-115 {
        padding-left: 57rem !important;
    }

    .ps-lg-116 {
        padding-left: 57.5rem !important;
    }

    .ps-lg-117 {
        padding-left: 58rem !important;
    }

    .ps-lg-118 {
        padding-left: 58.5rem !important;
    }

    .ps-lg-119 {
        padding-left: 59rem !important;
    }

    .ps-lg-120 {
        padding-left: 59.5rem !important;
    }

    .ps-lg-121 {
        padding-left: 60rem !important;
    }

    .ps-lg-122 {
        padding-left: 60.5rem !important;
    }

    .ps-lg-123 {
        padding-left: 61rem !important;
    }

    .ps-lg-124 {
        padding-left: 61.5rem !important;
    }

    .ps-lg-125 {
        padding-left: 62rem !important;
    }

    .ps-lg-126 {
        padding-left: 62.5rem !important;
    }

    .ps-lg-127 {
        padding-left: 63rem !important;
    }

    .ps-lg-128 {
        padding-left: 63.5rem !important;
    }

    .ps-lg-129 {
        padding-left: 64rem !important;
    }

    .ps-lg-130 {
        padding-left: 64.5rem !important;
    }

    .ps-lg-131 {
        padding-left: 65rem !important;
    }

    .ps-lg-132 {
        padding-left: 65.5rem !important;
    }

    .ps-lg-133 {
        padding-left: 66rem !important;
    }

    .ps-lg-134 {
        padding-left: 66.5rem !important;
    }

    .ps-lg-135 {
        padding-left: 67rem !important;
    }

    .ps-lg-136 {
        padding-left: 67.5rem !important;
    }

    .ps-lg-137 {
        padding-left: 68rem !important;
    }

    .ps-lg-138 {
        padding-left: 68.5rem !important;
    }

    .ps-lg-139 {
        padding-left: 69rem !important;
    }

    .ps-lg-140 {
        padding-left: 69.5rem !important;
    }

    .ps-lg-141 {
        padding-left: 70rem !important;
    }

    .ps-lg-142 {
        padding-left: 70.5rem !important;
    }

    .ps-lg-143 {
        padding-left: 71rem !important;
    }

    .ps-lg-144 {
        padding-left: 71.5rem !important;
    }

    .ps-lg-145 {
        padding-left: 72rem !important;
    }

    .ps-lg-146 {
        padding-left: 72.5rem !important;
    }

    .ps-lg-147 {
        padding-left: 73rem !important;
    }

    .ps-lg-148 {
        padding-left: 73.5rem !important;
    }

    .ps-lg-149 {
        padding-left: 74rem !important;
    }

    .ps-lg-150 {
        padding-left: 74.5rem !important;
    }

    .ps-lg-151 {
        padding-left: 75rem !important;
    }

    .ps-lg-152 {
        padding-left: 75.5rem !important;
    }

    .ps-lg-153 {
        padding-left: 76rem !important;
    }

    .ps-lg-154 {
        padding-left: 76.5rem !important;
    }

    .ps-lg-155 {
        padding-left: 77rem !important;
    }

    .ps-lg-156 {
        padding-left: 77.5rem !important;
    }

    .ps-lg-157 {
        padding-left: 78rem !important;
    }

    .ps-lg-158 {
        padding-left: 78.5rem !important;
    }

    .ps-lg-159 {
        padding-left: 79rem !important;
    }

    .ps-lg-160 {
        padding-left: 79.5rem !important;
    }

    .pe-lg-0 {
        padding-right: 0rem !important;
    }

    .pe-lg-1 {
        padding-right: 0.25rem !important;
    }

    .pe-lg-2 {
        padding-right: 0.5rem !important;
    }

    .pe-lg-3 {
        padding-right: 1rem !important;
    }

    .pe-lg-4 {
        padding-right: 1.5rem !important;
    }

    .pe-lg-5 {
        padding-right: 2rem !important;
    }

    .pe-lg-6 {
        padding-right: 2.5rem !important;
    }

    .pe-lg-7 {
        padding-right: 3rem !important;
    }

    .pe-lg-8 {
        padding-right: 3.5rem !important;
    }

    .pe-lg-9 {
        padding-right: 4rem !important;
    }

    .pe-lg-10 {
        padding-right: 4.5rem !important;
    }

    .pe-lg-11 {
        padding-right: 5rem !important;
    }

    .pe-lg-12 {
        padding-right: 5.5rem !important;
    }

    .pe-lg-13 {
        padding-right: 6rem !important;
    }

    .pe-lg-14 {
        padding-right: 6.5rem !important;
    }

    .pe-lg-15 {
        padding-right: 7rem !important;
    }

    .pe-lg-16 {
        padding-right: 7.5rem !important;
    }

    .pe-lg-17 {
        padding-right: 8rem !important;
    }

    .pe-lg-18 {
        padding-right: 8.5rem !important;
    }

    .pe-lg-19 {
        padding-right: 9rem !important;
    }

    .pe-lg-20 {
        padding-right: 9.5rem !important;
    }

    .pe-lg-21 {
        padding-right: 10rem !important;
    }

    .pe-lg-22 {
        padding-right: 10.5rem !important;
    }

    .pe-lg-23 {
        padding-right: 11rem !important;
    }

    .pe-lg-24 {
        padding-right: 11.5rem !important;
    }

    .pe-lg-25 {
        padding-right: 12rem !important;
    }

    .pe-lg-26 {
        padding-right: 12.5rem !important;
    }

    .pe-lg-27 {
        padding-right: 13rem !important;
    }

    .pe-lg-28 {
        padding-right: 13.5rem !important;
    }

    .pe-lg-29 {
        padding-right: 14rem !important;
    }

    .pe-lg-30 {
        padding-right: 14.5rem !important;
    }

    .pe-lg-31 {
        padding-right: 15rem !important;
    }

    .pe-lg-32 {
        padding-right: 15.5rem !important;
    }

    .pe-lg-33 {
        padding-right: 16rem !important;
    }

    .pe-lg-34 {
        padding-right: 16.5rem !important;
    }

    .pe-lg-35 {
        padding-right: 17rem !important;
    }

    .pe-lg-36 {
        padding-right: 17.5rem !important;
    }

    .pe-lg-37 {
        padding-right: 18rem !important;
    }

    .pe-lg-38 {
        padding-right: 18.5rem !important;
    }

    .pe-lg-39 {
        padding-right: 19rem !important;
    }

    .pe-lg-40 {
        padding-right: 19.5rem !important;
    }

    .pe-lg-41 {
        padding-right: 20rem !important;
    }

    .pe-lg-42 {
        padding-right: 20.5rem !important;
    }

    .pe-lg-43 {
        padding-right: 21rem !important;
    }

    .pe-lg-44 {
        padding-right: 21.5rem !important;
    }

    .pe-lg-45 {
        padding-right: 22rem !important;
    }

    .pe-lg-46 {
        padding-right: 22.5rem !important;
    }

    .pe-lg-47 {
        padding-right: 23rem !important;
    }

    .pe-lg-48 {
        padding-right: 23.5rem !important;
    }

    .pe-lg-49 {
        padding-right: 24rem !important;
    }

    .pe-lg-50 {
        padding-right: 24.5rem !important;
    }

    .pe-lg-51 {
        padding-right: 25rem !important;
    }

    .pe-lg-52 {
        padding-right: 25.5rem !important;
    }

    .pe-lg-53 {
        padding-right: 26rem !important;
    }

    .pe-lg-54 {
        padding-right: 26.5rem !important;
    }

    .pe-lg-55 {
        padding-right: 27rem !important;
    }

    .pe-lg-56 {
        padding-right: 27.5rem !important;
    }

    .pe-lg-57 {
        padding-right: 28rem !important;
    }

    .pe-lg-58 {
        padding-right: 28.5rem !important;
    }

    .pe-lg-59 {
        padding-right: 29rem !important;
    }

    .pe-lg-60 {
        padding-right: 29.5rem !important;
    }

    .pe-lg-61 {
        padding-right: 30rem !important;
    }

    .pe-lg-62 {
        padding-right: 30.5rem !important;
    }

    .pe-lg-63 {
        padding-right: 31rem !important;
    }

    .pe-lg-64 {
        padding-right: 31.5rem !important;
    }

    .pe-lg-65 {
        padding-right: 32rem !important;
    }

    .pe-lg-66 {
        padding-right: 32.5rem !important;
    }

    .pe-lg-67 {
        padding-right: 33rem !important;
    }

    .pe-lg-68 {
        padding-right: 33.5rem !important;
    }

    .pe-lg-69 {
        padding-right: 34rem !important;
    }

    .pe-lg-70 {
        padding-right: 34.5rem !important;
    }

    .pe-lg-71 {
        padding-right: 35rem !important;
    }

    .pe-lg-72 {
        padding-right: 35.5rem !important;
    }

    .pe-lg-73 {
        padding-right: 36rem !important;
    }

    .pe-lg-74 {
        padding-right: 36.5rem !important;
    }

    .pe-lg-75 {
        padding-right: 37rem !important;
    }

    .pe-lg-76 {
        padding-right: 37.5rem !important;
    }

    .pe-lg-77 {
        padding-right: 38rem !important;
    }

    .pe-lg-78 {
        padding-right: 38.5rem !important;
    }

    .pe-lg-79 {
        padding-right: 39rem !important;
    }

    .pe-lg-80 {
        padding-right: 39.5rem !important;
    }

    .pe-lg-81 {
        padding-right: 40rem !important;
    }

    .pe-lg-82 {
        padding-right: 40.5rem !important;
    }

    .pe-lg-83 {
        padding-right: 41rem !important;
    }

    .pe-lg-84 {
        padding-right: 41.5rem !important;
    }

    .pe-lg-85 {
        padding-right: 42rem !important;
    }

    .pe-lg-86 {
        padding-right: 42.5rem !important;
    }

    .pe-lg-87 {
        padding-right: 43rem !important;
    }

    .pe-lg-88 {
        padding-right: 43.5rem !important;
    }

    .pe-lg-89 {
        padding-right: 44rem !important;
    }

    .pe-lg-90 {
        padding-right: 44.5rem !important;
    }

    .pe-lg-91 {
        padding-right: 45rem !important;
    }

    .pe-lg-92 {
        padding-right: 45.5rem !important;
    }

    .pe-lg-93 {
        padding-right: 46rem !important;
    }

    .pe-lg-94 {
        padding-right: 46.5rem !important;
    }

    .pe-lg-95 {
        padding-right: 47rem !important;
    }

    .pe-lg-96 {
        padding-right: 47.5rem !important;
    }

    .pe-lg-97 {
        padding-right: 48rem !important;
    }

    .pe-lg-98 {
        padding-right: 48.5rem !important;
    }

    .pe-lg-99 {
        padding-right: 49rem !important;
    }

    .pe-lg-100 {
        padding-right: 49.5rem !important;
    }

    .pe-lg-101 {
        padding-right: 50rem !important;
    }

    .pe-lg-102 {
        padding-right: 50.5rem !important;
    }

    .pe-lg-103 {
        padding-right: 51rem !important;
    }

    .pe-lg-104 {
        padding-right: 51.5rem !important;
    }

    .pe-lg-105 {
        padding-right: 52rem !important;
    }

    .pe-lg-106 {
        padding-right: 52.5rem !important;
    }

    .pe-lg-107 {
        padding-right: 53rem !important;
    }

    .pe-lg-108 {
        padding-right: 53.5rem !important;
    }

    .pe-lg-109 {
        padding-right: 54rem !important;
    }

    .pe-lg-110 {
        padding-right: 54.5rem !important;
    }

    .pe-lg-111 {
        padding-right: 55rem !important;
    }

    .pe-lg-112 {
        padding-right: 55.5rem !important;
    }

    .pe-lg-113 {
        padding-right: 56rem !important;
    }

    .pe-lg-114 {
        padding-right: 56.5rem !important;
    }

    .pe-lg-115 {
        padding-right: 57rem !important;
    }

    .pe-lg-116 {
        padding-right: 57.5rem !important;
    }

    .pe-lg-117 {
        padding-right: 58rem !important;
    }

    .pe-lg-118 {
        padding-right: 58.5rem !important;
    }

    .pe-lg-119 {
        padding-right: 59rem !important;
    }

    .pe-lg-120 {
        padding-right: 59.5rem !important;
    }

    .pe-lg-121 {
        padding-right: 60rem !important;
    }

    .pe-lg-122 {
        padding-right: 60.5rem !important;
    }

    .pe-lg-123 {
        padding-right: 61rem !important;
    }

    .pe-lg-124 {
        padding-right: 61.5rem !important;
    }

    .pe-lg-125 {
        padding-right: 62rem !important;
    }

    .pe-lg-126 {
        padding-right: 62.5rem !important;
    }

    .pe-lg-127 {
        padding-right: 63rem !important;
    }

    .pe-lg-128 {
        padding-right: 63.5rem !important;
    }

    .pe-lg-129 {
        padding-right: 64rem !important;
    }

    .pe-lg-130 {
        padding-right: 64.5rem !important;
    }

    .pe-lg-131 {
        padding-right: 65rem !important;
    }

    .pe-lg-132 {
        padding-right: 65.5rem !important;
    }

    .pe-lg-133 {
        padding-right: 66rem !important;
    }

    .pe-lg-134 {
        padding-right: 66.5rem !important;
    }

    .pe-lg-135 {
        padding-right: 67rem !important;
    }

    .pe-lg-136 {
        padding-right: 67.5rem !important;
    }

    .pe-lg-137 {
        padding-right: 68rem !important;
    }

    .pe-lg-138 {
        padding-right: 68.5rem !important;
    }

    .pe-lg-139 {
        padding-right: 69rem !important;
    }

    .pe-lg-140 {
        padding-right: 69.5rem !important;
    }

    .pe-lg-141 {
        padding-right: 70rem !important;
    }

    .pe-lg-142 {
        padding-right: 70.5rem !important;
    }

    .pe-lg-143 {
        padding-right: 71rem !important;
    }

    .pe-lg-144 {
        padding-right: 71.5rem !important;
    }

    .pe-lg-145 {
        padding-right: 72rem !important;
    }

    .pe-lg-146 {
        padding-right: 72.5rem !important;
    }

    .pe-lg-147 {
        padding-right: 73rem !important;
    }

    .pe-lg-148 {
        padding-right: 73.5rem !important;
    }

    .pe-lg-149 {
        padding-right: 74rem !important;
    }

    .pe-lg-150 {
        padding-right: 74.5rem !important;
    }

    .pe-lg-151 {
        padding-right: 75rem !important;
    }

    .pe-lg-152 {
        padding-right: 75.5rem !important;
    }

    .pe-lg-153 {
        padding-right: 76rem !important;
    }

    .pe-lg-154 {
        padding-right: 76.5rem !important;
    }

    .pe-lg-155 {
        padding-right: 77rem !important;
    }

    .pe-lg-156 {
        padding-right: 77.5rem !important;
    }

    .pe-lg-157 {
        padding-right: 78rem !important;
    }

    .pe-lg-158 {
        padding-right: 78.5rem !important;
    }

    .pe-lg-159 {
        padding-right: 79rem !important;
    }

    .pe-lg-160 {
        padding-right: 79.5rem !important;
    }

    .px-lg-0 {
        padding-left: 0rem !important;
        padding-right: 0rem !important;
    }

    .px-lg-1 {
        padding-left: 0.25rem !important;
        padding-right: 0.25rem !important;
    }

    .px-lg-2 {
        padding-left: 0.5rem !important;
        padding-right: 0.5rem !important;
    }

    .px-lg-3 {
        padding-left: 1rem !important;
        padding-right: 1rem !important;
    }

    .px-lg-4 {
        padding-left: 1.5rem !important;
        padding-right: 1.5rem !important;
    }

    .px-lg-5 {
        padding-left: 2rem !important;
        padding-right: 2rem !important;
    }

    .px-lg-6 {
        padding-left: 2.5rem !important;
        padding-right: 2.5rem !important;
    }

    .px-lg-7 {
        padding-left: 3rem !important;
        padding-right: 3rem !important;
    }

    .px-lg-8 {
        padding-left: 3.5rem !important;
        padding-right: 3.5rem !important;
    }

    .px-lg-9 {
        padding-left: 4rem !important;
        padding-right: 4rem !important;
    }

    .px-lg-10 {
        padding-left: 4.5rem !important;
        padding-right: 4.5rem !important;
    }

    .px-lg-11 {
        padding-left: 5rem !important;
        padding-right: 5rem !important;
    }

    .px-lg-12 {
        padding-left: 5.5rem !important;
        padding-right: 5.5rem !important;
    }

    .px-lg-13 {
        padding-left: 6rem !important;
        padding-right: 6rem !important;
    }

    .px-lg-14 {
        padding-left: 6.5rem !important;
        padding-right: 6.5rem !important;
    }

    .px-lg-15 {
        padding-left: 7rem !important;
        padding-right: 7rem !important;
    }

    .px-lg-16 {
        padding-left: 7.5rem !important;
        padding-right: 7.5rem !important;
    }

    .px-lg-17 {
        padding-left: 8rem !important;
        padding-right: 8rem !important;
    }

    .px-lg-18 {
        padding-left: 8.5rem !important;
        padding-right: 8.5rem !important;
    }

    .px-lg-19 {
        padding-left: 9rem !important;
        padding-right: 9rem !important;
    }

    .px-lg-20 {
        padding-left: 9.5rem !important;
        padding-right: 9.5rem !important;
    }

    .px-lg-21 {
        padding-left: 10rem !important;
        padding-right: 10rem !important;
    }

    .px-lg-22 {
        padding-left: 10.5rem !important;
        padding-right: 10.5rem !important;
    }

    .px-lg-23 {
        padding-left: 11rem !important;
        padding-right: 11rem !important;
    }

    .px-lg-24 {
        padding-left: 11.5rem !important;
        padding-right: 11.5rem !important;
    }

    .px-lg-25 {
        padding-left: 12rem !important;
        padding-right: 12rem !important;
    }

    .px-lg-26 {
        padding-left: 12.5rem !important;
        padding-right: 12.5rem !important;
    }

    .px-lg-27 {
        padding-left: 13rem !important;
        padding-right: 13rem !important;
    }

    .px-lg-28 {
        padding-left: 13.5rem !important;
        padding-right: 13.5rem !important;
    }

    .px-lg-29 {
        padding-left: 14rem !important;
        padding-right: 14rem !important;
    }

    .px-lg-30 {
        padding-left: 14.5rem !important;
        padding-right: 14.5rem !important;
    }

    .px-lg-31 {
        padding-left: 15rem !important;
        padding-right: 15rem !important;
    }

    .px-lg-32 {
        padding-left: 15.5rem !important;
        padding-right: 15.5rem !important;
    }

    .px-lg-33 {
        padding-left: 16rem !important;
        padding-right: 16rem !important;
    }

    .px-lg-34 {
        padding-left: 16.5rem !important;
        padding-right: 16.5rem !important;
    }

    .px-lg-35 {
        padding-left: 17rem !important;
        padding-right: 17rem !important;
    }

    .px-lg-36 {
        padding-left: 17.5rem !important;
        padding-right: 17.5rem !important;
    }

    .px-lg-37 {
        padding-left: 18rem !important;
        padding-right: 18rem !important;
    }

    .px-lg-38 {
        padding-left: 18.5rem !important;
        padding-right: 18.5rem !important;
    }

    .px-lg-39 {
        padding-left: 19rem !important;
        padding-right: 19rem !important;
    }

    .px-lg-40 {
        padding-left: 19.5rem !important;
        padding-right: 19.5rem !important;
    }

    .px-lg-41 {
        padding-left: 20rem !important;
        padding-right: 20rem !important;
    }

    .px-lg-42 {
        padding-left: 20.5rem !important;
        padding-right: 20.5rem !important;
    }

    .px-lg-43 {
        padding-left: 21rem !important;
        padding-right: 21rem !important;
    }

    .px-lg-44 {
        padding-left: 21.5rem !important;
        padding-right: 21.5rem !important;
    }

    .px-lg-45 {
        padding-left: 22rem !important;
        padding-right: 22rem !important;
    }

    .px-lg-46 {
        padding-left: 22.5rem !important;
        padding-right: 22.5rem !important;
    }

    .px-lg-47 {
        padding-left: 23rem !important;
        padding-right: 23rem !important;
    }

    .px-lg-48 {
        padding-left: 23.5rem !important;
        padding-right: 23.5rem !important;
    }

    .px-lg-49 {
        padding-left: 24rem !important;
        padding-right: 24rem !important;
    }

    .px-lg-50 {
        padding-left: 24.5rem !important;
        padding-right: 24.5rem !important;
    }

    .px-lg-51 {
        padding-left: 25rem !important;
        padding-right: 25rem !important;
    }

    .px-lg-52 {
        padding-left: 25.5rem !important;
        padding-right: 25.5rem !important;
    }

    .px-lg-53 {
        padding-left: 26rem !important;
        padding-right: 26rem !important;
    }

    .px-lg-54 {
        padding-left: 26.5rem !important;
        padding-right: 26.5rem !important;
    }

    .px-lg-55 {
        padding-left: 27rem !important;
        padding-right: 27rem !important;
    }

    .px-lg-56 {
        padding-left: 27.5rem !important;
        padding-right: 27.5rem !important;
    }

    .px-lg-57 {
        padding-left: 28rem !important;
        padding-right: 28rem !important;
    }

    .px-lg-58 {
        padding-left: 28.5rem !important;
        padding-right: 28.5rem !important;
    }

    .px-lg-59 {
        padding-left: 29rem !important;
        padding-right: 29rem !important;
    }

    .px-lg-60 {
        padding-left: 29.5rem !important;
        padding-right: 29.5rem !important;
    }

    .px-lg-61 {
        padding-left: 30rem !important;
        padding-right: 30rem !important;
    }

    .px-lg-62 {
        padding-left: 30.5rem !important;
        padding-right: 30.5rem !important;
    }

    .px-lg-63 {
        padding-left: 31rem !important;
        padding-right: 31rem !important;
    }

    .px-lg-64 {
        padding-left: 31.5rem !important;
        padding-right: 31.5rem !important;
    }

    .px-lg-65 {
        padding-left: 32rem !important;
        padding-right: 32rem !important;
    }

    .px-lg-66 {
        padding-left: 32.5rem !important;
        padding-right: 32.5rem !important;
    }

    .px-lg-67 {
        padding-left: 33rem !important;
        padding-right: 33rem !important;
    }

    .px-lg-68 {
        padding-left: 33.5rem !important;
        padding-right: 33.5rem !important;
    }

    .px-lg-69 {
        padding-left: 34rem !important;
        padding-right: 34rem !important;
    }

    .px-lg-70 {
        padding-left: 34.5rem !important;
        padding-right: 34.5rem !important;
    }

    .px-lg-71 {
        padding-left: 35rem !important;
        padding-right: 35rem !important;
    }

    .px-lg-72 {
        padding-left: 35.5rem !important;
        padding-right: 35.5rem !important;
    }

    .px-lg-73 {
        padding-left: 36rem !important;
        padding-right: 36rem !important;
    }

    .px-lg-74 {
        padding-left: 36.5rem !important;
        padding-right: 36.5rem !important;
    }

    .px-lg-75 {
        padding-left: 37rem !important;
        padding-right: 37rem !important;
    }

    .px-lg-76 {
        padding-left: 37.5rem !important;
        padding-right: 37.5rem !important;
    }

    .px-lg-77 {
        padding-left: 38rem !important;
        padding-right: 38rem !important;
    }

    .px-lg-78 {
        padding-left: 38.5rem !important;
        padding-right: 38.5rem !important;
    }

    .px-lg-79 {
        padding-left: 39rem !important;
        padding-right: 39rem !important;
    }

    .px-lg-80 {
        padding-left: 39.5rem !important;
        padding-right: 39.5rem !important;
    }

    .px-lg-81 {
        padding-left: 40rem !important;
        padding-right: 40rem !important;
    }

    .px-lg-82 {
        padding-left: 40.5rem !important;
        padding-right: 40.5rem !important;
    }

    .px-lg-83 {
        padding-left: 41rem !important;
        padding-right: 41rem !important;
    }

    .px-lg-84 {
        padding-left: 41.5rem !important;
        padding-right: 41.5rem !important;
    }

    .px-lg-85 {
        padding-left: 42rem !important;
        padding-right: 42rem !important;
    }

    .px-lg-86 {
        padding-left: 42.5rem !important;
        padding-right: 42.5rem !important;
    }

    .px-lg-87 {
        padding-left: 43rem !important;
        padding-right: 43rem !important;
    }

    .px-lg-88 {
        padding-left: 43.5rem !important;
        padding-right: 43.5rem !important;
    }

    .px-lg-89 {
        padding-left: 44rem !important;
        padding-right: 44rem !important;
    }

    .px-lg-90 {
        padding-left: 44.5rem !important;
        padding-right: 44.5rem !important;
    }

    .px-lg-91 {
        padding-left: 45rem !important;
        padding-right: 45rem !important;
    }

    .px-lg-92 {
        padding-left: 45.5rem !important;
        padding-right: 45.5rem !important;
    }

    .px-lg-93 {
        padding-left: 46rem !important;
        padding-right: 46rem !important;
    }

    .px-lg-94 {
        padding-left: 46.5rem !important;
        padding-right: 46.5rem !important;
    }

    .px-lg-95 {
        padding-left: 47rem !important;
        padding-right: 47rem !important;
    }

    .px-lg-96 {
        padding-left: 47.5rem !important;
        padding-right: 47.5rem !important;
    }

    .px-lg-97 {
        padding-left: 48rem !important;
        padding-right: 48rem !important;
    }

    .px-lg-98 {
        padding-left: 48.5rem !important;
        padding-right: 48.5rem !important;
    }

    .px-lg-99 {
        padding-left: 49rem !important;
        padding-right: 49rem !important;
    }

    .px-lg-100 {
        padding-left: 49.5rem !important;
        padding-right: 49.5rem !important;
    }

    .px-lg-101 {
        padding-left: 50rem !important;
        padding-right: 50rem !important;
    }

    .px-lg-102 {
        padding-left: 50.5rem !important;
        padding-right: 50.5rem !important;
    }

    .px-lg-103 {
        padding-left: 51rem !important;
        padding-right: 51rem !important;
    }

    .px-lg-104 {
        padding-left: 51.5rem !important;
        padding-right: 51.5rem !important;
    }

    .px-lg-105 {
        padding-left: 52rem !important;
        padding-right: 52rem !important;
    }

    .px-lg-106 {
        padding-left: 52.5rem !important;
        padding-right: 52.5rem !important;
    }

    .px-lg-107 {
        padding-left: 53rem !important;
        padding-right: 53rem !important;
    }

    .px-lg-108 {
        padding-left: 53.5rem !important;
        padding-right: 53.5rem !important;
    }

    .px-lg-109 {
        padding-left: 54rem !important;
        padding-right: 54rem !important;
    }

    .px-lg-110 {
        padding-left: 54.5rem !important;
        padding-right: 54.5rem !important;
    }

    .px-lg-111 {
        padding-left: 55rem !important;
        padding-right: 55rem !important;
    }

    .px-lg-112 {
        padding-left: 55.5rem !important;
        padding-right: 55.5rem !important;
    }

    .px-lg-113 {
        padding-left: 56rem !important;
        padding-right: 56rem !important;
    }

    .px-lg-114 {
        padding-left: 56.5rem !important;
        padding-right: 56.5rem !important;
    }

    .px-lg-115 {
        padding-left: 57rem !important;
        padding-right: 57rem !important;
    }

    .px-lg-116 {
        padding-left: 57.5rem !important;
        padding-right: 57.5rem !important;
    }

    .px-lg-117 {
        padding-left: 58rem !important;
        padding-right: 58rem !important;
    }

    .px-lg-118 {
        padding-left: 58.5rem !important;
        padding-right: 58.5rem !important;
    }

    .px-lg-119 {
        padding-left: 59rem !important;
        padding-right: 59rem !important;
    }

    .px-lg-120 {
        padding-left: 59.5rem !important;
        padding-right: 59.5rem !important;
    }

    .px-lg-121 {
        padding-left: 60rem !important;
        padding-right: 60rem !important;
    }

    .px-lg-122 {
        padding-left: 60.5rem !important;
        padding-right: 60.5rem !important;
    }

    .px-lg-123 {
        padding-left: 61rem !important;
        padding-right: 61rem !important;
    }

    .px-lg-124 {
        padding-left: 61.5rem !important;
        padding-right: 61.5rem !important;
    }

    .px-lg-125 {
        padding-left: 62rem !important;
        padding-right: 62rem !important;
    }

    .px-lg-126 {
        padding-left: 62.5rem !important;
        padding-right: 62.5rem !important;
    }

    .px-lg-127 {
        padding-left: 63rem !important;
        padding-right: 63rem !important;
    }

    .px-lg-128 {
        padding-left: 63.5rem !important;
        padding-right: 63.5rem !important;
    }

    .px-lg-129 {
        padding-left: 64rem !important;
        padding-right: 64rem !important;
    }

    .px-lg-130 {
        padding-left: 64.5rem !important;
        padding-right: 64.5rem !important;
    }

    .px-lg-131 {
        padding-left: 65rem !important;
        padding-right: 65rem !important;
    }

    .px-lg-132 {
        padding-left: 65.5rem !important;
        padding-right: 65.5rem !important;
    }

    .px-lg-133 {
        padding-left: 66rem !important;
        padding-right: 66rem !important;
    }

    .px-lg-134 {
        padding-left: 66.5rem !important;
        padding-right: 66.5rem !important;
    }

    .px-lg-135 {
        padding-left: 67rem !important;
        padding-right: 67rem !important;
    }

    .px-lg-136 {
        padding-left: 67.5rem !important;
        padding-right: 67.5rem !important;
    }

    .px-lg-137 {
        padding-left: 68rem !important;
        padding-right: 68rem !important;
    }

    .px-lg-138 {
        padding-left: 68.5rem !important;
        padding-right: 68.5rem !important;
    }

    .px-lg-139 {
        padding-left: 69rem !important;
        padding-right: 69rem !important;
    }

    .px-lg-140 {
        padding-left: 69.5rem !important;
        padding-right: 69.5rem !important;
    }

    .px-lg-141 {
        padding-left: 70rem !important;
        padding-right: 70rem !important;
    }

    .px-lg-142 {
        padding-left: 70.5rem !important;
        padding-right: 70.5rem !important;
    }

    .px-lg-143 {
        padding-left: 71rem !important;
        padding-right: 71rem !important;
    }

    .px-lg-144 {
        padding-left: 71.5rem !important;
        padding-right: 71.5rem !important;
    }

    .px-lg-145 {
        padding-left: 72rem !important;
        padding-right: 72rem !important;
    }

    .px-lg-146 {
        padding-left: 72.5rem !important;
        padding-right: 72.5rem !important;
    }

    .px-lg-147 {
        padding-left: 73rem !important;
        padding-right: 73rem !important;
    }

    .px-lg-148 {
        padding-left: 73.5rem !important;
        padding-right: 73.5rem !important;
    }

    .px-lg-149 {
        padding-left: 74rem !important;
        padding-right: 74rem !important;
    }

    .px-lg-150 {
        padding-left: 74.5rem !important;
        padding-right: 74.5rem !important;
    }

    .px-lg-151 {
        padding-left: 75rem !important;
        padding-right: 75rem !important;
    }

    .px-lg-152 {
        padding-left: 75.5rem !important;
        padding-right: 75.5rem !important;
    }

    .px-lg-153 {
        padding-left: 76rem !important;
        padding-right: 76rem !important;
    }

    .px-lg-154 {
        padding-left: 76.5rem !important;
        padding-right: 76.5rem !important;
    }

    .px-lg-155 {
        padding-left: 77rem !important;
        padding-right: 77rem !important;
    }

    .px-lg-156 {
        padding-left: 77.5rem !important;
        padding-right: 77.5rem !important;
    }

    .px-lg-157 {
        padding-left: 78rem !important;
        padding-right: 78rem !important;
    }

    .px-lg-158 {
        padding-left: 78.5rem !important;
        padding-right: 78.5rem !important;
    }

    .px-lg-159 {
        padding-left: 79rem !important;
        padding-right: 79rem !important;
    }

    .px-lg-160 {
        padding-left: 79.5rem !important;
        padding-right: 79.5rem !important;
    }

    .py-lg-0 {
        padding-top: 0rem !important;
        padding-bottom: 0rem !important;
    }

    .py-lg-1 {
        padding-top: 0.25rem !important;
        padding-bottom: 0.25rem !important;
    }

    .py-lg-2 {
        padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;
    }

    .py-lg-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
    }

    .py-lg-4 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important;
    }

    .py-lg-5 {
        padding-top: 2rem !important;
        padding-bottom: 2rem !important;
    }

    .py-lg-6 {
        padding-top: 2.5rem !important;
        padding-bottom: 2.5rem !important;
    }

    .py-lg-7 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important;
    }

    .py-lg-8 {
        padding-top: 3.5rem !important;
        padding-bottom: 3.5rem !important;
    }

    .py-lg-9 {
        padding-top: 4rem !important;
        padding-bottom: 4rem !important;
    }

    .py-lg-10 {
        padding-top: 4.5rem !important;
        padding-bottom: 4.5rem !important;
    }

    .py-lg-11 {
        padding-top: 5rem !important;
        padding-bottom: 5rem !important;
    }

    .py-lg-12 {
        padding-top: 5.5rem !important;
        padding-bottom: 5.5rem !important;
    }

    .py-lg-13 {
        padding-top: 6rem !important;
        padding-bottom: 6rem !important;
    }

    .py-lg-14 {
        padding-top: 6.5rem !important;
        padding-bottom: 6.5rem !important;
    }

    .py-lg-15 {
        padding-top: 7rem !important;
        padding-bottom: 7rem !important;
    }

    .py-lg-16 {
        padding-top: 7.5rem !important;
        padding-bottom: 7.5rem !important;
    }

    .py-lg-17 {
        padding-top: 8rem !important;
        padding-bottom: 8rem !important;
    }

    .py-lg-18 {
        padding-top: 8.5rem !important;
        padding-bottom: 8.5rem !important;
    }

    .py-lg-19 {
        padding-top: 9rem !important;
        padding-bottom: 9rem !important;
    }

    .py-lg-20 {
        padding-top: 9.5rem !important;
        padding-bottom: 9.5rem !important;
    }

    .py-lg-21 {
        padding-top: 10rem !important;
        padding-bottom: 10rem !important;
    }

    .py-lg-22 {
        padding-top: 10.5rem !important;
        padding-bottom: 10.5rem !important;
    }

    .py-lg-23 {
        padding-top: 11rem !important;
        padding-bottom: 11rem !important;
    }

    .py-lg-24 {
        padding-top: 11.5rem !important;
        padding-bottom: 11.5rem !important;
    }

    .py-lg-25 {
        padding-top: 12rem !important;
        padding-bottom: 12rem !important;
    }

    .py-lg-26 {
        padding-top: 12.5rem !important;
        padding-bottom: 12.5rem !important;
    }

    .py-lg-27 {
        padding-top: 13rem !important;
        padding-bottom: 13rem !important;
    }

    .py-lg-28 {
        padding-top: 13.5rem !important;
        padding-bottom: 13.5rem !important;
    }

    .py-lg-29 {
        padding-top: 14rem !important;
        padding-bottom: 14rem !important;
    }

    .py-lg-30 {
        padding-top: 14.5rem !important;
        padding-bottom: 14.5rem !important;
    }

    .py-lg-31 {
        padding-top: 15rem !important;
        padding-bottom: 15rem !important;
    }

    .py-lg-32 {
        padding-top: 15.5rem !important;
        padding-bottom: 15.5rem !important;
    }

    .py-lg-33 {
        padding-top: 16rem !important;
        padding-bottom: 16rem !important;
    }

    .py-lg-34 {
        padding-top: 16.5rem !important;
        padding-bottom: 16.5rem !important;
    }

    .py-lg-35 {
        padding-top: 17rem !important;
        padding-bottom: 17rem !important;
    }

    .py-lg-36 {
        padding-top: 17.5rem !important;
        padding-bottom: 17.5rem !important;
    }

    .py-lg-37 {
        padding-top: 18rem !important;
        padding-bottom: 18rem !important;
    }

    .py-lg-38 {
        padding-top: 18.5rem !important;
        padding-bottom: 18.5rem !important;
    }

    .py-lg-39 {
        padding-top: 19rem !important;
        padding-bottom: 19rem !important;
    }

    .py-lg-40 {
        padding-top: 19.5rem !important;
        padding-bottom: 19.5rem !important;
    }

    .py-lg-41 {
        padding-top: 20rem !important;
        padding-bottom: 20rem !important;
    }

    .py-lg-42 {
        padding-top: 20.5rem !important;
        padding-bottom: 20.5rem !important;
    }

    .py-lg-43 {
        padding-top: 21rem !important;
        padding-bottom: 21rem !important;
    }

    .py-lg-44 {
        padding-top: 21.5rem !important;
        padding-bottom: 21.5rem !important;
    }

    .py-lg-45 {
        padding-top: 22rem !important;
        padding-bottom: 22rem !important;
    }

    .py-lg-46 {
        padding-top: 22.5rem !important;
        padding-bottom: 22.5rem !important;
    }

    .py-lg-47 {
        padding-top: 23rem !important;
        padding-bottom: 23rem !important;
    }

    .py-lg-48 {
        padding-top: 23.5rem !important;
        padding-bottom: 23.5rem !important;
    }

    .py-lg-49 {
        padding-top: 24rem !important;
        padding-bottom: 24rem !important;
    }

    .py-lg-50 {
        padding-top: 24.5rem !important;
        padding-bottom: 24.5rem !important;
    }

    .py-lg-51 {
        padding-top: 25rem !important;
        padding-bottom: 25rem !important;
    }

    .py-lg-52 {
        padding-top: 25.5rem !important;
        padding-bottom: 25.5rem !important;
    }

    .py-lg-53 {
        padding-top: 26rem !important;
        padding-bottom: 26rem !important;
    }

    .py-lg-54 {
        padding-top: 26.5rem !important;
        padding-bottom: 26.5rem !important;
    }

    .py-lg-55 {
        padding-top: 27rem !important;
        padding-bottom: 27rem !important;
    }

    .py-lg-56 {
        padding-top: 27.5rem !important;
        padding-bottom: 27.5rem !important;
    }

    .py-lg-57 {
        padding-top: 28rem !important;
        padding-bottom: 28rem !important;
    }

    .py-lg-58 {
        padding-top: 28.5rem !important;
        padding-bottom: 28.5rem !important;
    }

    .py-lg-59 {
        padding-top: 29rem !important;
        padding-bottom: 29rem !important;
    }

    .py-lg-60 {
        padding-top: 29.5rem !important;
        padding-bottom: 29.5rem !important;
    }

    .py-lg-61 {
        padding-top: 30rem !important;
        padding-bottom: 30rem !important;
    }

    .py-lg-62 {
        padding-top: 30.5rem !important;
        padding-bottom: 30.5rem !important;
    }

    .py-lg-63 {
        padding-top: 31rem !important;
        padding-bottom: 31rem !important;
    }

    .py-lg-64 {
        padding-top: 31.5rem !important;
        padding-bottom: 31.5rem !important;
    }

    .py-lg-65 {
        padding-top: 32rem !important;
        padding-bottom: 32rem !important;
    }

    .py-lg-66 {
        padding-top: 32.5rem !important;
        padding-bottom: 32.5rem !important;
    }

    .py-lg-67 {
        padding-top: 33rem !important;
        padding-bottom: 33rem !important;
    }

    .py-lg-68 {
        padding-top: 33.5rem !important;
        padding-bottom: 33.5rem !important;
    }

    .py-lg-69 {
        padding-top: 34rem !important;
        padding-bottom: 34rem !important;
    }

    .py-lg-70 {
        padding-top: 34.5rem !important;
        padding-bottom: 34.5rem !important;
    }

    .py-lg-71 {
        padding-top: 35rem !important;
        padding-bottom: 35rem !important;
    }

    .py-lg-72 {
        padding-top: 35.5rem !important;
        padding-bottom: 35.5rem !important;
    }

    .py-lg-73 {
        padding-top: 36rem !important;
        padding-bottom: 36rem !important;
    }

    .py-lg-74 {
        padding-top: 36.5rem !important;
        padding-bottom: 36.5rem !important;
    }

    .py-lg-75 {
        padding-top: 37rem !important;
        padding-bottom: 37rem !important;
    }

    .py-lg-76 {
        padding-top: 37.5rem !important;
        padding-bottom: 37.5rem !important;
    }

    .py-lg-77 {
        padding-top: 38rem !important;
        padding-bottom: 38rem !important;
    }

    .py-lg-78 {
        padding-top: 38.5rem !important;
        padding-bottom: 38.5rem !important;
    }

    .py-lg-79 {
        padding-top: 39rem !important;
        padding-bottom: 39rem !important;
    }

    .py-lg-80 {
        padding-top: 39.5rem !important;
        padding-bottom: 39.5rem !important;
    }

    .py-lg-81 {
        padding-top: 40rem !important;
        padding-bottom: 40rem !important;
    }

    .py-lg-82 {
        padding-top: 40.5rem !important;
        padding-bottom: 40.5rem !important;
    }

    .py-lg-83 {
        padding-top: 41rem !important;
        padding-bottom: 41rem !important;
    }

    .py-lg-84 {
        padding-top: 41.5rem !important;
        padding-bottom: 41.5rem !important;
    }

    .py-lg-85 {
        padding-top: 42rem !important;
        padding-bottom: 42rem !important;
    }

    .py-lg-86 {
        padding-top: 42.5rem !important;
        padding-bottom: 42.5rem !important;
    }

    .py-lg-87 {
        padding-top: 43rem !important;
        padding-bottom: 43rem !important;
    }

    .py-lg-88 {
        padding-top: 43.5rem !important;
        padding-bottom: 43.5rem !important;
    }

    .py-lg-89 {
        padding-top: 44rem !important;
        padding-bottom: 44rem !important;
    }

    .py-lg-90 {
        padding-top: 44.5rem !important;
        padding-bottom: 44.5rem !important;
    }

    .py-lg-91 {
        padding-top: 45rem !important;
        padding-bottom: 45rem !important;
    }

    .py-lg-92 {
        padding-top: 45.5rem !important;
        padding-bottom: 45.5rem !important;
    }

    .py-lg-93 {
        padding-top: 46rem !important;
        padding-bottom: 46rem !important;
    }

    .py-lg-94 {
        padding-top: 46.5rem !important;
        padding-bottom: 46.5rem !important;
    }

    .py-lg-95 {
        padding-top: 47rem !important;
        padding-bottom: 47rem !important;
    }

    .py-lg-96 {
        padding-top: 47.5rem !important;
        padding-bottom: 47.5rem !important;
    }

    .py-lg-97 {
        padding-top: 48rem !important;
        padding-bottom: 48rem !important;
    }

    .py-lg-98 {
        padding-top: 48.5rem !important;
        padding-bottom: 48.5rem !important;
    }

    .py-lg-99 {
        padding-top: 49rem !important;
        padding-bottom: 49rem !important;
    }

    .py-lg-100 {
        padding-top: 49.5rem !important;
        padding-bottom: 49.5rem !important;
    }

    .py-lg-101 {
        padding-top: 50rem !important;
        padding-bottom: 50rem !important;
    }

    .py-lg-102 {
        padding-top: 50.5rem !important;
        padding-bottom: 50.5rem !important;
    }

    .py-lg-103 {
        padding-top: 51rem !important;
        padding-bottom: 51rem !important;
    }

    .py-lg-104 {
        padding-top: 51.5rem !important;
        padding-bottom: 51.5rem !important;
    }

    .py-lg-105 {
        padding-top: 52rem !important;
        padding-bottom: 52rem !important;
    }

    .py-lg-106 {
        padding-top: 52.5rem !important;
        padding-bottom: 52.5rem !important;
    }

    .py-lg-107 {
        padding-top: 53rem !important;
        padding-bottom: 53rem !important;
    }

    .py-lg-108 {
        padding-top: 53.5rem !important;
        padding-bottom: 53.5rem !important;
    }

    .py-lg-109 {
        padding-top: 54rem !important;
        padding-bottom: 54rem !important;
    }

    .py-lg-110 {
        padding-top: 54.5rem !important;
        padding-bottom: 54.5rem !important;
    }

    .py-lg-111 {
        padding-top: 55rem !important;
        padding-bottom: 55rem !important;
    }

    .py-lg-112 {
        padding-top: 55.5rem !important;
        padding-bottom: 55.5rem !important;
    }

    .py-lg-113 {
        padding-top: 56rem !important;
        padding-bottom: 56rem !important;
    }

    .py-lg-114 {
        padding-top: 56.5rem !important;
        padding-bottom: 56.5rem !important;
    }

    .py-lg-115 {
        padding-top: 57rem !important;
        padding-bottom: 57rem !important;
    }

    .py-lg-116 {
        padding-top: 57.5rem !important;
        padding-bottom: 57.5rem !important;
    }

    .py-lg-117 {
        padding-top: 58rem !important;
        padding-bottom: 58rem !important;
    }

    .py-lg-118 {
        padding-top: 58.5rem !important;
        padding-bottom: 58.5rem !important;
    }

    .py-lg-119 {
        padding-top: 59rem !important;
        padding-bottom: 59rem !important;
    }

    .py-lg-120 {
        padding-top: 59.5rem !important;
        padding-bottom: 59.5rem !important;
    }

    .py-lg-121 {
        padding-top: 60rem !important;
        padding-bottom: 60rem !important;
    }

    .py-lg-122 {
        padding-top: 60.5rem !important;
        padding-bottom: 60.5rem !important;
    }

    .py-lg-123 {
        padding-top: 61rem !important;
        padding-bottom: 61rem !important;
    }

    .py-lg-124 {
        padding-top: 61.5rem !important;
        padding-bottom: 61.5rem !important;
    }

    .py-lg-125 {
        padding-top: 62rem !important;
        padding-bottom: 62rem !important;
    }

    .py-lg-126 {
        padding-top: 62.5rem !important;
        padding-bottom: 62.5rem !important;
    }

    .py-lg-127 {
        padding-top: 63rem !important;
        padding-bottom: 63rem !important;
    }

    .py-lg-128 {
        padding-top: 63.5rem !important;
        padding-bottom: 63.5rem !important;
    }

    .py-lg-129 {
        padding-top: 64rem !important;
        padding-bottom: 64rem !important;
    }

    .py-lg-130 {
        padding-top: 64.5rem !important;
        padding-bottom: 64.5rem !important;
    }

    .py-lg-131 {
        padding-top: 65rem !important;
        padding-bottom: 65rem !important;
    }

    .py-lg-132 {
        padding-top: 65.5rem !important;
        padding-bottom: 65.5rem !important;
    }

    .py-lg-133 {
        padding-top: 66rem !important;
        padding-bottom: 66rem !important;
    }

    .py-lg-134 {
        padding-top: 66.5rem !important;
        padding-bottom: 66.5rem !important;
    }

    .py-lg-135 {
        padding-top: 67rem !important;
        padding-bottom: 67rem !important;
    }

    .py-lg-136 {
        padding-top: 67.5rem !important;
        padding-bottom: 67.5rem !important;
    }

    .py-lg-137 {
        padding-top: 68rem !important;
        padding-bottom: 68rem !important;
    }

    .py-lg-138 {
        padding-top: 68.5rem !important;
        padding-bottom: 68.5rem !important;
    }

    .py-lg-139 {
        padding-top: 69rem !important;
        padding-bottom: 69rem !important;
    }

    .py-lg-140 {
        padding-top: 69.5rem !important;
        padding-bottom: 69.5rem !important;
    }

    .py-lg-141 {
        padding-top: 70rem !important;
        padding-bottom: 70rem !important;
    }

    .py-lg-142 {
        padding-top: 70.5rem !important;
        padding-bottom: 70.5rem !important;
    }

    .py-lg-143 {
        padding-top: 71rem !important;
        padding-bottom: 71rem !important;
    }

    .py-lg-144 {
        padding-top: 71.5rem !important;
        padding-bottom: 71.5rem !important;
    }

    .py-lg-145 {
        padding-top: 72rem !important;
        padding-bottom: 72rem !important;
    }

    .py-lg-146 {
        padding-top: 72.5rem !important;
        padding-bottom: 72.5rem !important;
    }

    .py-lg-147 {
        padding-top: 73rem !important;
        padding-bottom: 73rem !important;
    }

    .py-lg-148 {
        padding-top: 73.5rem !important;
        padding-bottom: 73.5rem !important;
    }

    .py-lg-149 {
        padding-top: 74rem !important;
        padding-bottom: 74rem !important;
    }

    .py-lg-150 {
        padding-top: 74.5rem !important;
        padding-bottom: 74.5rem !important;
    }

    .py-lg-151 {
        padding-top: 75rem !important;
        padding-bottom: 75rem !important;
    }

    .py-lg-152 {
        padding-top: 75.5rem !important;
        padding-bottom: 75.5rem !important;
    }

    .py-lg-153 {
        padding-top: 76rem !important;
        padding-bottom: 76rem !important;
    }

    .py-lg-154 {
        padding-top: 76.5rem !important;
        padding-bottom: 76.5rem !important;
    }

    .py-lg-155 {
        padding-top: 77rem !important;
        padding-bottom: 77rem !important;
    }

    .py-lg-156 {
        padding-top: 77.5rem !important;
        padding-bottom: 77.5rem !important;
    }

    .py-lg-157 {
        padding-top: 78rem !important;
        padding-bottom: 78rem !important;
    }

    .py-lg-158 {
        padding-top: 78.5rem !important;
        padding-bottom: 78.5rem !important;
    }

    .py-lg-159 {
        padding-top: 79rem !important;
        padding-bottom: 79rem !important;
    }

    .py-lg-160 {
        padding-top: 79.5rem !important;
        padding-bottom: 79.5rem !important;
    }
}

@media (min-width: 1200px) {
    .p-xl-0 {
        padding: 0rem !important;
    }

    .p-xl-1 {
        padding: 0.25rem !important;
    }

    .p-xl-2 {
        padding: 0.5rem !important;
    }

    .p-xl-3 {
        padding: 1rem !important;
    }

    .p-xl-4 {
        padding: 1.5rem !important;
    }

    .p-xl-5 {
        padding: 2rem !important;
    }

    .p-xl-6 {
        padding: 2.5rem !important;
    }

    .p-xl-7 {
        padding: 3rem !important;
    }

    .p-xl-8 {
        padding: 3.5rem !important;
    }

    .p-xl-9 {
        padding: 4rem !important;
    }

    .p-xl-10 {
        padding: 4.5rem !important;
    }

    .p-xl-11 {
        padding: 5rem !important;
    }

    .p-xl-12 {
        padding: 5.5rem !important;
    }

    .p-xl-13 {
        padding: 6rem !important;
    }

    .p-xl-14 {
        padding: 6.5rem !important;
    }

    .p-xl-15 {
        padding: 7rem !important;
    }

    .p-xl-16 {
        padding: 7.5rem !important;
    }

    .p-xl-17 {
        padding: 8rem !important;
    }

    .p-xl-18 {
        padding: 8.5rem !important;
    }

    .p-xl-19 {
        padding: 9rem !important;
    }

    .p-xl-20 {
        padding: 9.5rem !important;
    }

    .p-xl-21 {
        padding: 10rem !important;
    }

    .p-xl-22 {
        padding: 10.5rem !important;
    }

    .p-xl-23 {
        padding: 11rem !important;
    }

    .p-xl-24 {
        padding: 11.5rem !important;
    }

    .p-xl-25 {
        padding: 12rem !important;
    }

    .p-xl-26 {
        padding: 12.5rem !important;
    }

    .p-xl-27 {
        padding: 13rem !important;
    }

    .p-xl-28 {
        padding: 13.5rem !important;
    }

    .p-xl-29 {
        padding: 14rem !important;
    }

    .p-xl-30 {
        padding: 14.5rem !important;
    }

    .p-xl-31 {
        padding: 15rem !important;
    }

    .p-xl-32 {
        padding: 15.5rem !important;
    }

    .p-xl-33 {
        padding: 16rem !important;
    }

    .p-xl-34 {
        padding: 16.5rem !important;
    }

    .p-xl-35 {
        padding: 17rem !important;
    }

    .p-xl-36 {
        padding: 17.5rem !important;
    }

    .p-xl-37 {
        padding: 18rem !important;
    }

    .p-xl-38 {
        padding: 18.5rem !important;
    }

    .p-xl-39 {
        padding: 19rem !important;
    }

    .p-xl-40 {
        padding: 19.5rem !important;
    }

    .p-xl-41 {
        padding: 20rem !important;
    }

    .p-xl-42 {
        padding: 20.5rem !important;
    }

    .p-xl-43 {
        padding: 21rem !important;
    }

    .p-xl-44 {
        padding: 21.5rem !important;
    }

    .p-xl-45 {
        padding: 22rem !important;
    }

    .p-xl-46 {
        padding: 22.5rem !important;
    }

    .p-xl-47 {
        padding: 23rem !important;
    }

    .p-xl-48 {
        padding: 23.5rem !important;
    }

    .p-xl-49 {
        padding: 24rem !important;
    }

    .p-xl-50 {
        padding: 24.5rem !important;
    }

    .p-xl-51 {
        padding: 25rem !important;
    }

    .p-xl-52 {
        padding: 25.5rem !important;
    }

    .p-xl-53 {
        padding: 26rem !important;
    }

    .p-xl-54 {
        padding: 26.5rem !important;
    }

    .p-xl-55 {
        padding: 27rem !important;
    }

    .p-xl-56 {
        padding: 27.5rem !important;
    }

    .p-xl-57 {
        padding: 28rem !important;
    }

    .p-xl-58 {
        padding: 28.5rem !important;
    }

    .p-xl-59 {
        padding: 29rem !important;
    }

    .p-xl-60 {
        padding: 29.5rem !important;
    }

    .p-xl-61 {
        padding: 30rem !important;
    }

    .p-xl-62 {
        padding: 30.5rem !important;
    }

    .p-xl-63 {
        padding: 31rem !important;
    }

    .p-xl-64 {
        padding: 31.5rem !important;
    }

    .p-xl-65 {
        padding: 32rem !important;
    }

    .p-xl-66 {
        padding: 32.5rem !important;
    }

    .p-xl-67 {
        padding: 33rem !important;
    }

    .p-xl-68 {
        padding: 33.5rem !important;
    }

    .p-xl-69 {
        padding: 34rem !important;
    }

    .p-xl-70 {
        padding: 34.5rem !important;
    }

    .p-xl-71 {
        padding: 35rem !important;
    }

    .p-xl-72 {
        padding: 35.5rem !important;
    }

    .p-xl-73 {
        padding: 36rem !important;
    }

    .p-xl-74 {
        padding: 36.5rem !important;
    }

    .p-xl-75 {
        padding: 37rem !important;
    }

    .p-xl-76 {
        padding: 37.5rem !important;
    }

    .p-xl-77 {
        padding: 38rem !important;
    }

    .p-xl-78 {
        padding: 38.5rem !important;
    }

    .p-xl-79 {
        padding: 39rem !important;
    }

    .p-xl-80 {
        padding: 39.5rem !important;
    }

    .p-xl-81 {
        padding: 40rem !important;
    }

    .p-xl-82 {
        padding: 40.5rem !important;
    }

    .p-xl-83 {
        padding: 41rem !important;
    }

    .p-xl-84 {
        padding: 41.5rem !important;
    }

    .p-xl-85 {
        padding: 42rem !important;
    }

    .p-xl-86 {
        padding: 42.5rem !important;
    }

    .p-xl-87 {
        padding: 43rem !important;
    }

    .p-xl-88 {
        padding: 43.5rem !important;
    }

    .p-xl-89 {
        padding: 44rem !important;
    }

    .p-xl-90 {
        padding: 44.5rem !important;
    }

    .p-xl-91 {
        padding: 45rem !important;
    }

    .p-xl-92 {
        padding: 45.5rem !important;
    }

    .p-xl-93 {
        padding: 46rem !important;
    }

    .p-xl-94 {
        padding: 46.5rem !important;
    }

    .p-xl-95 {
        padding: 47rem !important;
    }

    .p-xl-96 {
        padding: 47.5rem !important;
    }

    .p-xl-97 {
        padding: 48rem !important;
    }

    .p-xl-98 {
        padding: 48.5rem !important;
    }

    .p-xl-99 {
        padding: 49rem !important;
    }

    .p-xl-100 {
        padding: 49.5rem !important;
    }

    .p-xl-101 {
        padding: 50rem !important;
    }

    .p-xl-102 {
        padding: 50.5rem !important;
    }

    .p-xl-103 {
        padding: 51rem !important;
    }

    .p-xl-104 {
        padding: 51.5rem !important;
    }

    .p-xl-105 {
        padding: 52rem !important;
    }

    .p-xl-106 {
        padding: 52.5rem !important;
    }

    .p-xl-107 {
        padding: 53rem !important;
    }

    .p-xl-108 {
        padding: 53.5rem !important;
    }

    .p-xl-109 {
        padding: 54rem !important;
    }

    .p-xl-110 {
        padding: 54.5rem !important;
    }

    .p-xl-111 {
        padding: 55rem !important;
    }

    .p-xl-112 {
        padding: 55.5rem !important;
    }

    .p-xl-113 {
        padding: 56rem !important;
    }

    .p-xl-114 {
        padding: 56.5rem !important;
    }

    .p-xl-115 {
        padding: 57rem !important;
    }

    .p-xl-116 {
        padding: 57.5rem !important;
    }

    .p-xl-117 {
        padding: 58rem !important;
    }

    .p-xl-118 {
        padding: 58.5rem !important;
    }

    .p-xl-119 {
        padding: 59rem !important;
    }

    .p-xl-120 {
        padding: 59.5rem !important;
    }

    .p-xl-121 {
        padding: 60rem !important;
    }

    .p-xl-122 {
        padding: 60.5rem !important;
    }

    .p-xl-123 {
        padding: 61rem !important;
    }

    .p-xl-124 {
        padding: 61.5rem !important;
    }

    .p-xl-125 {
        padding: 62rem !important;
    }

    .p-xl-126 {
        padding: 62.5rem !important;
    }

    .p-xl-127 {
        padding: 63rem !important;
    }

    .p-xl-128 {
        padding: 63.5rem !important;
    }

    .p-xl-129 {
        padding: 64rem !important;
    }

    .p-xl-130 {
        padding: 64.5rem !important;
    }

    .p-xl-131 {
        padding: 65rem !important;
    }

    .p-xl-132 {
        padding: 65.5rem !important;
    }

    .p-xl-133 {
        padding: 66rem !important;
    }

    .p-xl-134 {
        padding: 66.5rem !important;
    }

    .p-xl-135 {
        padding: 67rem !important;
    }

    .p-xl-136 {
        padding: 67.5rem !important;
    }

    .p-xl-137 {
        padding: 68rem !important;
    }

    .p-xl-138 {
        padding: 68.5rem !important;
    }

    .p-xl-139 {
        padding: 69rem !important;
    }

    .p-xl-140 {
        padding: 69.5rem !important;
    }

    .p-xl-141 {
        padding: 70rem !important;
    }

    .p-xl-142 {
        padding: 70.5rem !important;
    }

    .p-xl-143 {
        padding: 71rem !important;
    }

    .p-xl-144 {
        padding: 71.5rem !important;
    }

    .p-xl-145 {
        padding: 72rem !important;
    }

    .p-xl-146 {
        padding: 72.5rem !important;
    }

    .p-xl-147 {
        padding: 73rem !important;
    }

    .p-xl-148 {
        padding: 73.5rem !important;
    }

    .p-xl-149 {
        padding: 74rem !important;
    }

    .p-xl-150 {
        padding: 74.5rem !important;
    }

    .p-xl-151 {
        padding: 75rem !important;
    }

    .p-xl-152 {
        padding: 75.5rem !important;
    }

    .p-xl-153 {
        padding: 76rem !important;
    }

    .p-xl-154 {
        padding: 76.5rem !important;
    }

    .p-xl-155 {
        padding: 77rem !important;
    }

    .p-xl-156 {
        padding: 77.5rem !important;
    }

    .p-xl-157 {
        padding: 78rem !important;
    }

    .p-xl-158 {
        padding: 78.5rem !important;
    }

    .p-xl-159 {
        padding: 79rem !important;
    }

    .p-xl-160 {
        padding: 79.5rem !important;
    }

    .pt-xl-0 {
        padding-top: 0rem !important;
    }

    .pt-xl-1 {
        padding-top: 0.25rem !important;
    }

    .pt-xl-2 {
        padding-top: 0.5rem !important;
    }

    .pt-xl-3 {
        padding-top: 1rem !important;
    }

    .pt-xl-4 {
        padding-top: 1.5rem !important;
    }

    .pt-xl-5 {
        padding-top: 2rem !important;
    }

    .pt-xl-6 {
        padding-top: 2.5rem !important;
    }

    .pt-xl-7 {
        padding-top: 3rem !important;
    }

    .pt-xl-8 {
        padding-top: 3.5rem !important;
    }

    .pt-xl-9 {
        padding-top: 4rem !important;
    }

    .pt-xl-10 {
        padding-top: 4.5rem !important;
    }

    .pt-xl-11 {
        padding-top: 5rem !important;
    }

    .pt-xl-12 {
        padding-top: 5.5rem !important;
    }

    .pt-xl-13 {
        padding-top: 6rem !important;
    }

    .pt-xl-14 {
        padding-top: 6.5rem !important;
    }

    .pt-xl-15 {
        padding-top: 7rem !important;
    }

    .pt-xl-16 {
        padding-top: 7.5rem !important;
    }

    .pt-xl-17 {
        padding-top: 8rem !important;
    }

    .pt-xl-18 {
        padding-top: 8.5rem !important;
    }

    .pt-xl-19 {
        padding-top: 9rem !important;
    }

    .pt-xl-20 {
        padding-top: 9.5rem !important;
    }

    .pt-xl-21 {
        padding-top: 10rem !important;
    }

    .pt-xl-22 {
        padding-top: 10.5rem !important;
    }

    .pt-xl-23 {
        padding-top: 11rem !important;
    }

    .pt-xl-24 {
        padding-top: 11.5rem !important;
    }

    .pt-xl-25 {
        padding-top: 12rem !important;
    }

    .pt-xl-26 {
        padding-top: 12.5rem !important;
    }

    .pt-xl-27 {
        padding-top: 13rem !important;
    }

    .pt-xl-28 {
        padding-top: 13.5rem !important;
    }

    .pt-xl-29 {
        padding-top: 14rem !important;
    }

    .pt-xl-30 {
        padding-top: 14.5rem !important;
    }

    .pt-xl-31 {
        padding-top: 15rem !important;
    }

    .pt-xl-32 {
        padding-top: 15.5rem !important;
    }

    .pt-xl-33 {
        padding-top: 16rem !important;
    }

    .pt-xl-34 {
        padding-top: 16.5rem !important;
    }

    .pt-xl-35 {
        padding-top: 17rem !important;
    }

    .pt-xl-36 {
        padding-top: 17.5rem !important;
    }

    .pt-xl-37 {
        padding-top: 18rem !important;
    }

    .pt-xl-38 {
        padding-top: 18.5rem !important;
    }

    .pt-xl-39 {
        padding-top: 19rem !important;
    }

    .pt-xl-40 {
        padding-top: 19.5rem !important;
    }

    .pt-xl-41 {
        padding-top: 20rem !important;
    }

    .pt-xl-42 {
        padding-top: 20.5rem !important;
    }

    .pt-xl-43 {
        padding-top: 21rem !important;
    }

    .pt-xl-44 {
        padding-top: 21.5rem !important;
    }

    .pt-xl-45 {
        padding-top: 22rem !important;
    }

    .pt-xl-46 {
        padding-top: 22.5rem !important;
    }

    .pt-xl-47 {
        padding-top: 23rem !important;
    }

    .pt-xl-48 {
        padding-top: 23.5rem !important;
    }

    .pt-xl-49 {
        padding-top: 24rem !important;
    }

    .pt-xl-50 {
        padding-top: 24.5rem !important;
    }

    .pt-xl-51 {
        padding-top: 25rem !important;
    }

    .pt-xl-52 {
        padding-top: 25.5rem !important;
    }

    .pt-xl-53 {
        padding-top: 26rem !important;
    }

    .pt-xl-54 {
        padding-top: 26.5rem !important;
    }

    .pt-xl-55 {
        padding-top: 27rem !important;
    }

    .pt-xl-56 {
        padding-top: 27.5rem !important;
    }

    .pt-xl-57 {
        padding-top: 28rem !important;
    }

    .pt-xl-58 {
        padding-top: 28.5rem !important;
    }

    .pt-xl-59 {
        padding-top: 29rem !important;
    }

    .pt-xl-60 {
        padding-top: 29.5rem !important;
    }

    .pt-xl-61 {
        padding-top: 30rem !important;
    }

    .pt-xl-62 {
        padding-top: 30.5rem !important;
    }

    .pt-xl-63 {
        padding-top: 31rem !important;
    }

    .pt-xl-64 {
        padding-top: 31.5rem !important;
    }

    .pt-xl-65 {
        padding-top: 32rem !important;
    }

    .pt-xl-66 {
        padding-top: 32.5rem !important;
    }

    .pt-xl-67 {
        padding-top: 33rem !important;
    }

    .pt-xl-68 {
        padding-top: 33.5rem !important;
    }

    .pt-xl-69 {
        padding-top: 34rem !important;
    }

    .pt-xl-70 {
        padding-top: 34.5rem !important;
    }

    .pt-xl-71 {
        padding-top: 35rem !important;
    }

    .pt-xl-72 {
        padding-top: 35.5rem !important;
    }

    .pt-xl-73 {
        padding-top: 36rem !important;
    }

    .pt-xl-74 {
        padding-top: 36.5rem !important;
    }

    .pt-xl-75 {
        padding-top: 37rem !important;
    }

    .pt-xl-76 {
        padding-top: 37.5rem !important;
    }

    .pt-xl-77 {
        padding-top: 38rem !important;
    }

    .pt-xl-78 {
        padding-top: 38.5rem !important;
    }

    .pt-xl-79 {
        padding-top: 39rem !important;
    }

    .pt-xl-80 {
        padding-top: 39.5rem !important;
    }

    .pt-xl-81 {
        padding-top: 40rem !important;
    }

    .pt-xl-82 {
        padding-top: 40.5rem !important;
    }

    .pt-xl-83 {
        padding-top: 41rem !important;
    }

    .pt-xl-84 {
        padding-top: 41.5rem !important;
    }

    .pt-xl-85 {
        padding-top: 42rem !important;
    }

    .pt-xl-86 {
        padding-top: 42.5rem !important;
    }

    .pt-xl-87 {
        padding-top: 43rem !important;
    }

    .pt-xl-88 {
        padding-top: 43.5rem !important;
    }

    .pt-xl-89 {
        padding-top: 44rem !important;
    }

    .pt-xl-90 {
        padding-top: 44.5rem !important;
    }

    .pt-xl-91 {
        padding-top: 45rem !important;
    }

    .pt-xl-92 {
        padding-top: 45.5rem !important;
    }

    .pt-xl-93 {
        padding-top: 46rem !important;
    }

    .pt-xl-94 {
        padding-top: 46.5rem !important;
    }

    .pt-xl-95 {
        padding-top: 47rem !important;
    }

    .pt-xl-96 {
        padding-top: 47.5rem !important;
    }

    .pt-xl-97 {
        padding-top: 48rem !important;
    }

    .pt-xl-98 {
        padding-top: 48.5rem !important;
    }

    .pt-xl-99 {
        padding-top: 49rem !important;
    }

    .pt-xl-100 {
        padding-top: 49.5rem !important;
    }

    .pt-xl-101 {
        padding-top: 50rem !important;
    }

    .pt-xl-102 {
        padding-top: 50.5rem !important;
    }

    .pt-xl-103 {
        padding-top: 51rem !important;
    }

    .pt-xl-104 {
        padding-top: 51.5rem !important;
    }

    .pt-xl-105 {
        padding-top: 52rem !important;
    }

    .pt-xl-106 {
        padding-top: 52.5rem !important;
    }

    .pt-xl-107 {
        padding-top: 53rem !important;
    }

    .pt-xl-108 {
        padding-top: 53.5rem !important;
    }

    .pt-xl-109 {
        padding-top: 54rem !important;
    }

    .pt-xl-110 {
        padding-top: 54.5rem !important;
    }

    .pt-xl-111 {
        padding-top: 55rem !important;
    }

    .pt-xl-112 {
        padding-top: 55.5rem !important;
    }

    .pt-xl-113 {
        padding-top: 56rem !important;
    }

    .pt-xl-114 {
        padding-top: 56.5rem !important;
    }

    .pt-xl-115 {
        padding-top: 57rem !important;
    }

    .pt-xl-116 {
        padding-top: 57.5rem !important;
    }

    .pt-xl-117 {
        padding-top: 58rem !important;
    }

    .pt-xl-118 {
        padding-top: 58.5rem !important;
    }

    .pt-xl-119 {
        padding-top: 59rem !important;
    }

    .pt-xl-120 {
        padding-top: 59.5rem !important;
    }

    .pt-xl-121 {
        padding-top: 60rem !important;
    }

    .pt-xl-122 {
        padding-top: 60.5rem !important;
    }

    .pt-xl-123 {
        padding-top: 61rem !important;
    }

    .pt-xl-124 {
        padding-top: 61.5rem !important;
    }

    .pt-xl-125 {
        padding-top: 62rem !important;
    }

    .pt-xl-126 {
        padding-top: 62.5rem !important;
    }

    .pt-xl-127 {
        padding-top: 63rem !important;
    }

    .pt-xl-128 {
        padding-top: 63.5rem !important;
    }

    .pt-xl-129 {
        padding-top: 64rem !important;
    }

    .pt-xl-130 {
        padding-top: 64.5rem !important;
    }

    .pt-xl-131 {
        padding-top: 65rem !important;
    }

    .pt-xl-132 {
        padding-top: 65.5rem !important;
    }

    .pt-xl-133 {
        padding-top: 66rem !important;
    }

    .pt-xl-134 {
        padding-top: 66.5rem !important;
    }

    .pt-xl-135 {
        padding-top: 67rem !important;
    }

    .pt-xl-136 {
        padding-top: 67.5rem !important;
    }

    .pt-xl-137 {
        padding-top: 68rem !important;
    }

    .pt-xl-138 {
        padding-top: 68.5rem !important;
    }

    .pt-xl-139 {
        padding-top: 69rem !important;
    }

    .pt-xl-140 {
        padding-top: 69.5rem !important;
    }

    .pt-xl-141 {
        padding-top: 70rem !important;
    }

    .pt-xl-142 {
        padding-top: 70.5rem !important;
    }

    .pt-xl-143 {
        padding-top: 71rem !important;
    }

    .pt-xl-144 {
        padding-top: 71.5rem !important;
    }

    .pt-xl-145 {
        padding-top: 72rem !important;
    }

    .pt-xl-146 {
        padding-top: 72.5rem !important;
    }

    .pt-xl-147 {
        padding-top: 73rem !important;
    }

    .pt-xl-148 {
        padding-top: 73.5rem !important;
    }

    .pt-xl-149 {
        padding-top: 74rem !important;
    }

    .pt-xl-150 {
        padding-top: 74.5rem !important;
    }

    .pt-xl-151 {
        padding-top: 75rem !important;
    }

    .pt-xl-152 {
        padding-top: 75.5rem !important;
    }

    .pt-xl-153 {
        padding-top: 76rem !important;
    }

    .pt-xl-154 {
        padding-top: 76.5rem !important;
    }

    .pt-xl-155 {
        padding-top: 77rem !important;
    }

    .pt-xl-156 {
        padding-top: 77.5rem !important;
    }

    .pt-xl-157 {
        padding-top: 78rem !important;
    }

    .pt-xl-158 {
        padding-top: 78.5rem !important;
    }

    .pt-xl-159 {
        padding-top: 79rem !important;
    }

    .pt-xl-160 {
        padding-top: 79.5rem !important;
    }

    .pb-xl-0 {
        padding-bottom: 0rem !important;
    }

    .pb-xl-1 {
        padding-bottom: 0.25rem !important;
    }

    .pb-xl-2 {
        padding-bottom: 0.5rem !important;
    }

    .pb-xl-3 {
        padding-bottom: 1rem !important;
    }

    .pb-xl-4 {
        padding-bottom: 1.5rem !important;
    }

    .pb-xl-5 {
        padding-bottom: 2rem !important;
    }

    .pb-xl-6 {
        padding-bottom: 2.5rem !important;
    }

    .pb-xl-7 {
        padding-bottom: 3rem !important;
    }

    .pb-xl-8 {
        padding-bottom: 3.5rem !important;
    }

    .pb-xl-9 {
        padding-bottom: 4rem !important;
    }

    .pb-xl-10 {
        padding-bottom: 4.5rem !important;
    }

    .pb-xl-11 {
        padding-bottom: 5rem !important;
    }

    .pb-xl-12 {
        padding-bottom: 5.5rem !important;
    }

    .pb-xl-13 {
        padding-bottom: 6rem !important;
    }

    .pb-xl-14 {
        padding-bottom: 6.5rem !important;
    }

    .pb-xl-15 {
        padding-bottom: 7rem !important;
    }

    .pb-xl-16 {
        padding-bottom: 7.5rem !important;
    }

    .pb-xl-17 {
        padding-bottom: 8rem !important;
    }

    .pb-xl-18 {
        padding-bottom: 8.5rem !important;
    }

    .pb-xl-19 {
        padding-bottom: 9rem !important;
    }

    .pb-xl-20 {
        padding-bottom: 9.5rem !important;
    }

    .pb-xl-21 {
        padding-bottom: 10rem !important;
    }

    .pb-xl-22 {
        padding-bottom: 10.5rem !important;
    }

    .pb-xl-23 {
        padding-bottom: 11rem !important;
    }

    .pb-xl-24 {
        padding-bottom: 11.5rem !important;
    }

    .pb-xl-25 {
        padding-bottom: 12rem !important;
    }

    .pb-xl-26 {
        padding-bottom: 12.5rem !important;
    }

    .pb-xl-27 {
        padding-bottom: 13rem !important;
    }

    .pb-xl-28 {
        padding-bottom: 13.5rem !important;
    }

    .pb-xl-29 {
        padding-bottom: 14rem !important;
    }

    .pb-xl-30 {
        padding-bottom: 14.5rem !important;
    }

    .pb-xl-31 {
        padding-bottom: 15rem !important;
    }

    .pb-xl-32 {
        padding-bottom: 15.5rem !important;
    }

    .pb-xl-33 {
        padding-bottom: 16rem !important;
    }

    .pb-xl-34 {
        padding-bottom: 16.5rem !important;
    }

    .pb-xl-35 {
        padding-bottom: 17rem !important;
    }

    .pb-xl-36 {
        padding-bottom: 17.5rem !important;
    }

    .pb-xl-37 {
        padding-bottom: 18rem !important;
    }

    .pb-xl-38 {
        padding-bottom: 18.5rem !important;
    }

    .pb-xl-39 {
        padding-bottom: 19rem !important;
    }

    .pb-xl-40 {
        padding-bottom: 19.5rem !important;
    }

    .pb-xl-41 {
        padding-bottom: 20rem !important;
    }

    .pb-xl-42 {
        padding-bottom: 20.5rem !important;
    }

    .pb-xl-43 {
        padding-bottom: 21rem !important;
    }

    .pb-xl-44 {
        padding-bottom: 21.5rem !important;
    }

    .pb-xl-45 {
        padding-bottom: 22rem !important;
    }

    .pb-xl-46 {
        padding-bottom: 22.5rem !important;
    }

    .pb-xl-47 {
        padding-bottom: 23rem !important;
    }

    .pb-xl-48 {
        padding-bottom: 23.5rem !important;
    }

    .pb-xl-49 {
        padding-bottom: 24rem !important;
    }

    .pb-xl-50 {
        padding-bottom: 24.5rem !important;
    }

    .pb-xl-51 {
        padding-bottom: 25rem !important;
    }

    .pb-xl-52 {
        padding-bottom: 25.5rem !important;
    }

    .pb-xl-53 {
        padding-bottom: 26rem !important;
    }

    .pb-xl-54 {
        padding-bottom: 26.5rem !important;
    }

    .pb-xl-55 {
        padding-bottom: 27rem !important;
    }

    .pb-xl-56 {
        padding-bottom: 27.5rem !important;
    }

    .pb-xl-57 {
        padding-bottom: 28rem !important;
    }

    .pb-xl-58 {
        padding-bottom: 28.5rem !important;
    }

    .pb-xl-59 {
        padding-bottom: 29rem !important;
    }

    .pb-xl-60 {
        padding-bottom: 29.5rem !important;
    }

    .pb-xl-61 {
        padding-bottom: 30rem !important;
    }

    .pb-xl-62 {
        padding-bottom: 30.5rem !important;
    }

    .pb-xl-63 {
        padding-bottom: 31rem !important;
    }

    .pb-xl-64 {
        padding-bottom: 31.5rem !important;
    }

    .pb-xl-65 {
        padding-bottom: 32rem !important;
    }

    .pb-xl-66 {
        padding-bottom: 32.5rem !important;
    }

    .pb-xl-67 {
        padding-bottom: 33rem !important;
    }

    .pb-xl-68 {
        padding-bottom: 33.5rem !important;
    }

    .pb-xl-69 {
        padding-bottom: 34rem !important;
    }

    .pb-xl-70 {
        padding-bottom: 34.5rem !important;
    }

    .pb-xl-71 {
        padding-bottom: 35rem !important;
    }

    .pb-xl-72 {
        padding-bottom: 35.5rem !important;
    }

    .pb-xl-73 {
        padding-bottom: 36rem !important;
    }

    .pb-xl-74 {
        padding-bottom: 36.5rem !important;
    }

    .pb-xl-75 {
        padding-bottom: 37rem !important;
    }

    .pb-xl-76 {
        padding-bottom: 37.5rem !important;
    }

    .pb-xl-77 {
        padding-bottom: 38rem !important;
    }

    .pb-xl-78 {
        padding-bottom: 38.5rem !important;
    }

    .pb-xl-79 {
        padding-bottom: 39rem !important;
    }

    .pb-xl-80 {
        padding-bottom: 39.5rem !important;
    }

    .pb-xl-81 {
        padding-bottom: 40rem !important;
    }

    .pb-xl-82 {
        padding-bottom: 40.5rem !important;
    }

    .pb-xl-83 {
        padding-bottom: 41rem !important;
    }

    .pb-xl-84 {
        padding-bottom: 41.5rem !important;
    }

    .pb-xl-85 {
        padding-bottom: 42rem !important;
    }

    .pb-xl-86 {
        padding-bottom: 42.5rem !important;
    }

    .pb-xl-87 {
        padding-bottom: 43rem !important;
    }

    .pb-xl-88 {
        padding-bottom: 43.5rem !important;
    }

    .pb-xl-89 {
        padding-bottom: 44rem !important;
    }

    .pb-xl-90 {
        padding-bottom: 44.5rem !important;
    }

    .pb-xl-91 {
        padding-bottom: 45rem !important;
    }

    .pb-xl-92 {
        padding-bottom: 45.5rem !important;
    }

    .pb-xl-93 {
        padding-bottom: 46rem !important;
    }

    .pb-xl-94 {
        padding-bottom: 46.5rem !important;
    }

    .pb-xl-95 {
        padding-bottom: 47rem !important;
    }

    .pb-xl-96 {
        padding-bottom: 47.5rem !important;
    }

    .pb-xl-97 {
        padding-bottom: 48rem !important;
    }

    .pb-xl-98 {
        padding-bottom: 48.5rem !important;
    }

    .pb-xl-99 {
        padding-bottom: 49rem !important;
    }

    .pb-xl-100 {
        padding-bottom: 49.5rem !important;
    }

    .pb-xl-101 {
        padding-bottom: 50rem !important;
    }

    .pb-xl-102 {
        padding-bottom: 50.5rem !important;
    }

    .pb-xl-103 {
        padding-bottom: 51rem !important;
    }

    .pb-xl-104 {
        padding-bottom: 51.5rem !important;
    }

    .pb-xl-105 {
        padding-bottom: 52rem !important;
    }

    .pb-xl-106 {
        padding-bottom: 52.5rem !important;
    }

    .pb-xl-107 {
        padding-bottom: 53rem !important;
    }

    .pb-xl-108 {
        padding-bottom: 53.5rem !important;
    }

    .pb-xl-109 {
        padding-bottom: 54rem !important;
    }

    .pb-xl-110 {
        padding-bottom: 54.5rem !important;
    }

    .pb-xl-111 {
        padding-bottom: 55rem !important;
    }

    .pb-xl-112 {
        padding-bottom: 55.5rem !important;
    }

    .pb-xl-113 {
        padding-bottom: 56rem !important;
    }

    .pb-xl-114 {
        padding-bottom: 56.5rem !important;
    }

    .pb-xl-115 {
        padding-bottom: 57rem !important;
    }

    .pb-xl-116 {
        padding-bottom: 57.5rem !important;
    }

    .pb-xl-117 {
        padding-bottom: 58rem !important;
    }

    .pb-xl-118 {
        padding-bottom: 58.5rem !important;
    }

    .pb-xl-119 {
        padding-bottom: 59rem !important;
    }

    .pb-xl-120 {
        padding-bottom: 59.5rem !important;
    }

    .pb-xl-121 {
        padding-bottom: 60rem !important;
    }

    .pb-xl-122 {
        padding-bottom: 60.5rem !important;
    }

    .pb-xl-123 {
        padding-bottom: 61rem !important;
    }

    .pb-xl-124 {
        padding-bottom: 61.5rem !important;
    }

    .pb-xl-125 {
        padding-bottom: 62rem !important;
    }

    .pb-xl-126 {
        padding-bottom: 62.5rem !important;
    }

    .pb-xl-127 {
        padding-bottom: 63rem !important;
    }

    .pb-xl-128 {
        padding-bottom: 63.5rem !important;
    }

    .pb-xl-129 {
        padding-bottom: 64rem !important;
    }

    .pb-xl-130 {
        padding-bottom: 64.5rem !important;
    }

    .pb-xl-131 {
        padding-bottom: 65rem !important;
    }

    .pb-xl-132 {
        padding-bottom: 65.5rem !important;
    }

    .pb-xl-133 {
        padding-bottom: 66rem !important;
    }

    .pb-xl-134 {
        padding-bottom: 66.5rem !important;
    }

    .pb-xl-135 {
        padding-bottom: 67rem !important;
    }

    .pb-xl-136 {
        padding-bottom: 67.5rem !important;
    }

    .pb-xl-137 {
        padding-bottom: 68rem !important;
    }

    .pb-xl-138 {
        padding-bottom: 68.5rem !important;
    }

    .pb-xl-139 {
        padding-bottom: 69rem !important;
    }

    .pb-xl-140 {
        padding-bottom: 69.5rem !important;
    }

    .pb-xl-141 {
        padding-bottom: 70rem !important;
    }

    .pb-xl-142 {
        padding-bottom: 70.5rem !important;
    }

    .pb-xl-143 {
        padding-bottom: 71rem !important;
    }

    .pb-xl-144 {
        padding-bottom: 71.5rem !important;
    }

    .pb-xl-145 {
        padding-bottom: 72rem !important;
    }

    .pb-xl-146 {
        padding-bottom: 72.5rem !important;
    }

    .pb-xl-147 {
        padding-bottom: 73rem !important;
    }

    .pb-xl-148 {
        padding-bottom: 73.5rem !important;
    }

    .pb-xl-149 {
        padding-bottom: 74rem !important;
    }

    .pb-xl-150 {
        padding-bottom: 74.5rem !important;
    }

    .pb-xl-151 {
        padding-bottom: 75rem !important;
    }

    .pb-xl-152 {
        padding-bottom: 75.5rem !important;
    }

    .pb-xl-153 {
        padding-bottom: 76rem !important;
    }

    .pb-xl-154 {
        padding-bottom: 76.5rem !important;
    }

    .pb-xl-155 {
        padding-bottom: 77rem !important;
    }

    .pb-xl-156 {
        padding-bottom: 77.5rem !important;
    }

    .pb-xl-157 {
        padding-bottom: 78rem !important;
    }

    .pb-xl-158 {
        padding-bottom: 78.5rem !important;
    }

    .pb-xl-159 {
        padding-bottom: 79rem !important;
    }

    .pb-xl-160 {
        padding-bottom: 79.5rem !important;
    }

    .ps-xl-0 {
        padding-left: 0rem !important;
    }

    .ps-xl-1 {
        padding-left: 0.25rem !important;
    }

    .ps-xl-2 {
        padding-left: 0.5rem !important;
    }

    .ps-xl-3 {
        padding-left: 1rem !important;
    }

    .ps-xl-4 {
        padding-left: 1.5rem !important;
    }

    .ps-xl-5 {
        padding-left: 2rem !important;
    }

    .ps-xl-6 {
        padding-left: 2.5rem !important;
    }

    .ps-xl-7 {
        padding-left: 3rem !important;
    }

    .ps-xl-8 {
        padding-left: 3.5rem !important;
    }

    .ps-xl-9 {
        padding-left: 4rem !important;
    }

    .ps-xl-10 {
        padding-left: 4.5rem !important;
    }

    .ps-xl-11 {
        padding-left: 5rem !important;
    }

    .ps-xl-12 {
        padding-left: 5.5rem !important;
    }

    .ps-xl-13 {
        padding-left: 6rem !important;
    }

    .ps-xl-14 {
        padding-left: 6.5rem !important;
    }

    .ps-xl-15 {
        padding-left: 7rem !important;
    }

    .ps-xl-16 {
        padding-left: 7.5rem !important;
    }

    .ps-xl-17 {
        padding-left: 8rem !important;
    }

    .ps-xl-18 {
        padding-left: 8.5rem !important;
    }

    .ps-xl-19 {
        padding-left: 9rem !important;
    }

    .ps-xl-20 {
        padding-left: 9.5rem !important;
    }

    .ps-xl-21 {
        padding-left: 10rem !important;
    }

    .ps-xl-22 {
        padding-left: 10.5rem !important;
    }

    .ps-xl-23 {
        padding-left: 11rem !important;
    }

    .ps-xl-24 {
        padding-left: 11.5rem !important;
    }

    .ps-xl-25 {
        padding-left: 12rem !important;
    }

    .ps-xl-26 {
        padding-left: 12.5rem !important;
    }

    .ps-xl-27 {
        padding-left: 13rem !important;
    }

    .ps-xl-28 {
        padding-left: 13.5rem !important;
    }

    .ps-xl-29 {
        padding-left: 14rem !important;
    }

    .ps-xl-30 {
        padding-left: 14.5rem !important;
    }

    .ps-xl-31 {
        padding-left: 15rem !important;
    }

    .ps-xl-32 {
        padding-left: 15.5rem !important;
    }

    .ps-xl-33 {
        padding-left: 16rem !important;
    }

    .ps-xl-34 {
        padding-left: 16.5rem !important;
    }

    .ps-xl-35 {
        padding-left: 17rem !important;
    }

    .ps-xl-36 {
        padding-left: 17.5rem !important;
    }

    .ps-xl-37 {
        padding-left: 18rem !important;
    }

    .ps-xl-38 {
        padding-left: 18.5rem !important;
    }

    .ps-xl-39 {
        padding-left: 19rem !important;
    }

    .ps-xl-40 {
        padding-left: 19.5rem !important;
    }

    .ps-xl-41 {
        padding-left: 20rem !important;
    }

    .ps-xl-42 {
        padding-left: 20.5rem !important;
    }

    .ps-xl-43 {
        padding-left: 21rem !important;
    }

    .ps-xl-44 {
        padding-left: 21.5rem !important;
    }

    .ps-xl-45 {
        padding-left: 22rem !important;
    }

    .ps-xl-46 {
        padding-left: 22.5rem !important;
    }

    .ps-xl-47 {
        padding-left: 23rem !important;
    }

    .ps-xl-48 {
        padding-left: 23.5rem !important;
    }

    .ps-xl-49 {
        padding-left: 24rem !important;
    }

    .ps-xl-50 {
        padding-left: 24.5rem !important;
    }

    .ps-xl-51 {
        padding-left: 25rem !important;
    }

    .ps-xl-52 {
        padding-left: 25.5rem !important;
    }

    .ps-xl-53 {
        padding-left: 26rem !important;
    }

    .ps-xl-54 {
        padding-left: 26.5rem !important;
    }

    .ps-xl-55 {
        padding-left: 27rem !important;
    }

    .ps-xl-56 {
        padding-left: 27.5rem !important;
    }

    .ps-xl-57 {
        padding-left: 28rem !important;
    }

    .ps-xl-58 {
        padding-left: 28.5rem !important;
    }

    .ps-xl-59 {
        padding-left: 29rem !important;
    }

    .ps-xl-60 {
        padding-left: 29.5rem !important;
    }

    .ps-xl-61 {
        padding-left: 30rem !important;
    }

    .ps-xl-62 {
        padding-left: 30.5rem !important;
    }

    .ps-xl-63 {
        padding-left: 31rem !important;
    }

    .ps-xl-64 {
        padding-left: 31.5rem !important;
    }

    .ps-xl-65 {
        padding-left: 32rem !important;
    }

    .ps-xl-66 {
        padding-left: 32.5rem !important;
    }

    .ps-xl-67 {
        padding-left: 33rem !important;
    }

    .ps-xl-68 {
        padding-left: 33.5rem !important;
    }

    .ps-xl-69 {
        padding-left: 34rem !important;
    }

    .ps-xl-70 {
        padding-left: 34.5rem !important;
    }

    .ps-xl-71 {
        padding-left: 35rem !important;
    }

    .ps-xl-72 {
        padding-left: 35.5rem !important;
    }

    .ps-xl-73 {
        padding-left: 36rem !important;
    }

    .ps-xl-74 {
        padding-left: 36.5rem !important;
    }

    .ps-xl-75 {
        padding-left: 37rem !important;
    }

    .ps-xl-76 {
        padding-left: 37.5rem !important;
    }

    .ps-xl-77 {
        padding-left: 38rem !important;
    }

    .ps-xl-78 {
        padding-left: 38.5rem !important;
    }

    .ps-xl-79 {
        padding-left: 39rem !important;
    }

    .ps-xl-80 {
        padding-left: 39.5rem !important;
    }

    .ps-xl-81 {
        padding-left: 40rem !important;
    }

    .ps-xl-82 {
        padding-left: 40.5rem !important;
    }

    .ps-xl-83 {
        padding-left: 41rem !important;
    }

    .ps-xl-84 {
        padding-left: 41.5rem !important;
    }

    .ps-xl-85 {
        padding-left: 42rem !important;
    }

    .ps-xl-86 {
        padding-left: 42.5rem !important;
    }

    .ps-xl-87 {
        padding-left: 43rem !important;
    }

    .ps-xl-88 {
        padding-left: 43.5rem !important;
    }

    .ps-xl-89 {
        padding-left: 44rem !important;
    }

    .ps-xl-90 {
        padding-left: 44.5rem !important;
    }

    .ps-xl-91 {
        padding-left: 45rem !important;
    }

    .ps-xl-92 {
        padding-left: 45.5rem !important;
    }

    .ps-xl-93 {
        padding-left: 46rem !important;
    }

    .ps-xl-94 {
        padding-left: 46.5rem !important;
    }

    .ps-xl-95 {
        padding-left: 47rem !important;
    }

    .ps-xl-96 {
        padding-left: 47.5rem !important;
    }

    .ps-xl-97 {
        padding-left: 48rem !important;
    }

    .ps-xl-98 {
        padding-left: 48.5rem !important;
    }

    .ps-xl-99 {
        padding-left: 49rem !important;
    }

    .ps-xl-100 {
        padding-left: 49.5rem !important;
    }

    .ps-xl-101 {
        padding-left: 50rem !important;
    }

    .ps-xl-102 {
        padding-left: 50.5rem !important;
    }

    .ps-xl-103 {
        padding-left: 51rem !important;
    }

    .ps-xl-104 {
        padding-left: 51.5rem !important;
    }

    .ps-xl-105 {
        padding-left: 52rem !important;
    }

    .ps-xl-106 {
        padding-left: 52.5rem !important;
    }

    .ps-xl-107 {
        padding-left: 53rem !important;
    }

    .ps-xl-108 {
        padding-left: 53.5rem !important;
    }

    .ps-xl-109 {
        padding-left: 54rem !important;
    }

    .ps-xl-110 {
        padding-left: 54.5rem !important;
    }

    .ps-xl-111 {
        padding-left: 55rem !important;
    }

    .ps-xl-112 {
        padding-left: 55.5rem !important;
    }

    .ps-xl-113 {
        padding-left: 56rem !important;
    }

    .ps-xl-114 {
        padding-left: 56.5rem !important;
    }

    .ps-xl-115 {
        padding-left: 57rem !important;
    }

    .ps-xl-116 {
        padding-left: 57.5rem !important;
    }

    .ps-xl-117 {
        padding-left: 58rem !important;
    }

    .ps-xl-118 {
        padding-left: 58.5rem !important;
    }

    .ps-xl-119 {
        padding-left: 59rem !important;
    }

    .ps-xl-120 {
        padding-left: 59.5rem !important;
    }

    .ps-xl-121 {
        padding-left: 60rem !important;
    }

    .ps-xl-122 {
        padding-left: 60.5rem !important;
    }

    .ps-xl-123 {
        padding-left: 61rem !important;
    }

    .ps-xl-124 {
        padding-left: 61.5rem !important;
    }

    .ps-xl-125 {
        padding-left: 62rem !important;
    }

    .ps-xl-126 {
        padding-left: 62.5rem !important;
    }

    .ps-xl-127 {
        padding-left: 63rem !important;
    }

    .ps-xl-128 {
        padding-left: 63.5rem !important;
    }

    .ps-xl-129 {
        padding-left: 64rem !important;
    }

    .ps-xl-130 {
        padding-left: 64.5rem !important;
    }

    .ps-xl-131 {
        padding-left: 65rem !important;
    }

    .ps-xl-132 {
        padding-left: 65.5rem !important;
    }

    .ps-xl-133 {
        padding-left: 66rem !important;
    }

    .ps-xl-134 {
        padding-left: 66.5rem !important;
    }

    .ps-xl-135 {
        padding-left: 67rem !important;
    }

    .ps-xl-136 {
        padding-left: 67.5rem !important;
    }

    .ps-xl-137 {
        padding-left: 68rem !important;
    }

    .ps-xl-138 {
        padding-left: 68.5rem !important;
    }

    .ps-xl-139 {
        padding-left: 69rem !important;
    }

    .ps-xl-140 {
        padding-left: 69.5rem !important;
    }

    .ps-xl-141 {
        padding-left: 70rem !important;
    }

    .ps-xl-142 {
        padding-left: 70.5rem !important;
    }

    .ps-xl-143 {
        padding-left: 71rem !important;
    }

    .ps-xl-144 {
        padding-left: 71.5rem !important;
    }

    .ps-xl-145 {
        padding-left: 72rem !important;
    }

    .ps-xl-146 {
        padding-left: 72.5rem !important;
    }

    .ps-xl-147 {
        padding-left: 73rem !important;
    }

    .ps-xl-148 {
        padding-left: 73.5rem !important;
    }

    .ps-xl-149 {
        padding-left: 74rem !important;
    }

    .ps-xl-150 {
        padding-left: 74.5rem !important;
    }

    .ps-xl-151 {
        padding-left: 75rem !important;
    }

    .ps-xl-152 {
        padding-left: 75.5rem !important;
    }

    .ps-xl-153 {
        padding-left: 76rem !important;
    }

    .ps-xl-154 {
        padding-left: 76.5rem !important;
    }

    .ps-xl-155 {
        padding-left: 77rem !important;
    }

    .ps-xl-156 {
        padding-left: 77.5rem !important;
    }

    .ps-xl-157 {
        padding-left: 78rem !important;
    }

    .ps-xl-158 {
        padding-left: 78.5rem !important;
    }

    .ps-xl-159 {
        padding-left: 79rem !important;
    }

    .ps-xl-160 {
        padding-left: 79.5rem !important;
    }

    .pe-xl-0 {
        padding-right: 0rem !important;
    }

    .pe-xl-1 {
        padding-right: 0.25rem !important;
    }

    .pe-xl-2 {
        padding-right: 0.5rem !important;
    }

    .pe-xl-3 {
        padding-right: 1rem !important;
    }

    .pe-xl-4 {
        padding-right: 1.5rem !important;
    }

    .pe-xl-5 {
        padding-right: 2rem !important;
    }

    .pe-xl-6 {
        padding-right: 2.5rem !important;
    }

    .pe-xl-7 {
        padding-right: 3rem !important;
    }

    .pe-xl-8 {
        padding-right: 3.5rem !important;
    }

    .pe-xl-9 {
        padding-right: 4rem !important;
    }

    .pe-xl-10 {
        padding-right: 4.5rem !important;
    }

    .pe-xl-11 {
        padding-right: 5rem !important;
    }

    .pe-xl-12 {
        padding-right: 5.5rem !important;
    }

    .pe-xl-13 {
        padding-right: 6rem !important;
    }

    .pe-xl-14 {
        padding-right: 6.5rem !important;
    }

    .pe-xl-15 {
        padding-right: 7rem !important;
    }

    .pe-xl-16 {
        padding-right: 7.5rem !important;
    }

    .pe-xl-17 {
        padding-right: 8rem !important;
    }

    .pe-xl-18 {
        padding-right: 8.5rem !important;
    }

    .pe-xl-19 {
        padding-right: 9rem !important;
    }

    .pe-xl-20 {
        padding-right: 9.5rem !important;
    }

    .pe-xl-21 {
        padding-right: 10rem !important;
    }

    .pe-xl-22 {
        padding-right: 10.5rem !important;
    }

    .pe-xl-23 {
        padding-right: 11rem !important;
    }

    .pe-xl-24 {
        padding-right: 11.5rem !important;
    }

    .pe-xl-25 {
        padding-right: 12rem !important;
    }

    .pe-xl-26 {
        padding-right: 12.5rem !important;
    }

    .pe-xl-27 {
        padding-right: 13rem !important;
    }

    .pe-xl-28 {
        padding-right: 13.5rem !important;
    }

    .pe-xl-29 {
        padding-right: 14rem !important;
    }

    .pe-xl-30 {
        padding-right: 14.5rem !important;
    }

    .pe-xl-31 {
        padding-right: 15rem !important;
    }

    .pe-xl-32 {
        padding-right: 15.5rem !important;
    }

    .pe-xl-33 {
        padding-right: 16rem !important;
    }

    .pe-xl-34 {
        padding-right: 16.5rem !important;
    }

    .pe-xl-35 {
        padding-right: 17rem !important;
    }

    .pe-xl-36 {
        padding-right: 17.5rem !important;
    }

    .pe-xl-37 {
        padding-right: 18rem !important;
    }

    .pe-xl-38 {
        padding-right: 18.5rem !important;
    }

    .pe-xl-39 {
        padding-right: 19rem !important;
    }

    .pe-xl-40 {
        padding-right: 19.5rem !important;
    }

    .pe-xl-41 {
        padding-right: 20rem !important;
    }

    .pe-xl-42 {
        padding-right: 20.5rem !important;
    }

    .pe-xl-43 {
        padding-right: 21rem !important;
    }

    .pe-xl-44 {
        padding-right: 21.5rem !important;
    }

    .pe-xl-45 {
        padding-right: 22rem !important;
    }

    .pe-xl-46 {
        padding-right: 22.5rem !important;
    }

    .pe-xl-47 {
        padding-right: 23rem !important;
    }

    .pe-xl-48 {
        padding-right: 23.5rem !important;
    }

    .pe-xl-49 {
        padding-right: 24rem !important;
    }

    .pe-xl-50 {
        padding-right: 24.5rem !important;
    }

    .pe-xl-51 {
        padding-right: 25rem !important;
    }

    .pe-xl-52 {
        padding-right: 25.5rem !important;
    }

    .pe-xl-53 {
        padding-right: 26rem !important;
    }

    .pe-xl-54 {
        padding-right: 26.5rem !important;
    }

    .pe-xl-55 {
        padding-right: 27rem !important;
    }

    .pe-xl-56 {
        padding-right: 27.5rem !important;
    }

    .pe-xl-57 {
        padding-right: 28rem !important;
    }

    .pe-xl-58 {
        padding-right: 28.5rem !important;
    }

    .pe-xl-59 {
        padding-right: 29rem !important;
    }

    .pe-xl-60 {
        padding-right: 29.5rem !important;
    }

    .pe-xl-61 {
        padding-right: 30rem !important;
    }

    .pe-xl-62 {
        padding-right: 30.5rem !important;
    }

    .pe-xl-63 {
        padding-right: 31rem !important;
    }

    .pe-xl-64 {
        padding-right: 31.5rem !important;
    }

    .pe-xl-65 {
        padding-right: 32rem !important;
    }

    .pe-xl-66 {
        padding-right: 32.5rem !important;
    }

    .pe-xl-67 {
        padding-right: 33rem !important;
    }

    .pe-xl-68 {
        padding-right: 33.5rem !important;
    }

    .pe-xl-69 {
        padding-right: 34rem !important;
    }

    .pe-xl-70 {
        padding-right: 34.5rem !important;
    }

    .pe-xl-71 {
        padding-right: 35rem !important;
    }

    .pe-xl-72 {
        padding-right: 35.5rem !important;
    }

    .pe-xl-73 {
        padding-right: 36rem !important;
    }

    .pe-xl-74 {
        padding-right: 36.5rem !important;
    }

    .pe-xl-75 {
        padding-right: 37rem !important;
    }

    .pe-xl-76 {
        padding-right: 37.5rem !important;
    }

    .pe-xl-77 {
        padding-right: 38rem !important;
    }

    .pe-xl-78 {
        padding-right: 38.5rem !important;
    }

    .pe-xl-79 {
        padding-right: 39rem !important;
    }

    .pe-xl-80 {
        padding-right: 39.5rem !important;
    }

    .pe-xl-81 {
        padding-right: 40rem !important;
    }

    .pe-xl-82 {
        padding-right: 40.5rem !important;
    }

    .pe-xl-83 {
        padding-right: 41rem !important;
    }

    .pe-xl-84 {
        padding-right: 41.5rem !important;
    }

    .pe-xl-85 {
        padding-right: 42rem !important;
    }

    .pe-xl-86 {
        padding-right: 42.5rem !important;
    }

    .pe-xl-87 {
        padding-right: 43rem !important;
    }

    .pe-xl-88 {
        padding-right: 43.5rem !important;
    }

    .pe-xl-89 {
        padding-right: 44rem !important;
    }

    .pe-xl-90 {
        padding-right: 44.5rem !important;
    }

    .pe-xl-91 {
        padding-right: 45rem !important;
    }

    .pe-xl-92 {
        padding-right: 45.5rem !important;
    }

    .pe-xl-93 {
        padding-right: 46rem !important;
    }

    .pe-xl-94 {
        padding-right: 46.5rem !important;
    }

    .pe-xl-95 {
        padding-right: 47rem !important;
    }

    .pe-xl-96 {
        padding-right: 47.5rem !important;
    }

    .pe-xl-97 {
        padding-right: 48rem !important;
    }

    .pe-xl-98 {
        padding-right: 48.5rem !important;
    }

    .pe-xl-99 {
        padding-right: 49rem !important;
    }

    .pe-xl-100 {
        padding-right: 49.5rem !important;
    }

    .pe-xl-101 {
        padding-right: 50rem !important;
    }

    .pe-xl-102 {
        padding-right: 50.5rem !important;
    }

    .pe-xl-103 {
        padding-right: 51rem !important;
    }

    .pe-xl-104 {
        padding-right: 51.5rem !important;
    }

    .pe-xl-105 {
        padding-right: 52rem !important;
    }

    .pe-xl-106 {
        padding-right: 52.5rem !important;
    }

    .pe-xl-107 {
        padding-right: 53rem !important;
    }

    .pe-xl-108 {
        padding-right: 53.5rem !important;
    }

    .pe-xl-109 {
        padding-right: 54rem !important;
    }

    .pe-xl-110 {
        padding-right: 54.5rem !important;
    }

    .pe-xl-111 {
        padding-right: 55rem !important;
    }

    .pe-xl-112 {
        padding-right: 55.5rem !important;
    }

    .pe-xl-113 {
        padding-right: 56rem !important;
    }

    .pe-xl-114 {
        padding-right: 56.5rem !important;
    }

    .pe-xl-115 {
        padding-right: 57rem !important;
    }

    .pe-xl-116 {
        padding-right: 57.5rem !important;
    }

    .pe-xl-117 {
        padding-right: 58rem !important;
    }

    .pe-xl-118 {
        padding-right: 58.5rem !important;
    }

    .pe-xl-119 {
        padding-right: 59rem !important;
    }

    .pe-xl-120 {
        padding-right: 59.5rem !important;
    }

    .pe-xl-121 {
        padding-right: 60rem !important;
    }

    .pe-xl-122 {
        padding-right: 60.5rem !important;
    }

    .pe-xl-123 {
        padding-right: 61rem !important;
    }

    .pe-xl-124 {
        padding-right: 61.5rem !important;
    }

    .pe-xl-125 {
        padding-right: 62rem !important;
    }

    .pe-xl-126 {
        padding-right: 62.5rem !important;
    }

    .pe-xl-127 {
        padding-right: 63rem !important;
    }

    .pe-xl-128 {
        padding-right: 63.5rem !important;
    }

    .pe-xl-129 {
        padding-right: 64rem !important;
    }

    .pe-xl-130 {
        padding-right: 64.5rem !important;
    }

    .pe-xl-131 {
        padding-right: 65rem !important;
    }

    .pe-xl-132 {
        padding-right: 65.5rem !important;
    }

    .pe-xl-133 {
        padding-right: 66rem !important;
    }

    .pe-xl-134 {
        padding-right: 66.5rem !important;
    }

    .pe-xl-135 {
        padding-right: 67rem !important;
    }

    .pe-xl-136 {
        padding-right: 67.5rem !important;
    }

    .pe-xl-137 {
        padding-right: 68rem !important;
    }

    .pe-xl-138 {
        padding-right: 68.5rem !important;
    }

    .pe-xl-139 {
        padding-right: 69rem !important;
    }

    .pe-xl-140 {
        padding-right: 69.5rem !important;
    }

    .pe-xl-141 {
        padding-right: 70rem !important;
    }

    .pe-xl-142 {
        padding-right: 70.5rem !important;
    }

    .pe-xl-143 {
        padding-right: 71rem !important;
    }

    .pe-xl-144 {
        padding-right: 71.5rem !important;
    }

    .pe-xl-145 {
        padding-right: 72rem !important;
    }

    .pe-xl-146 {
        padding-right: 72.5rem !important;
    }

    .pe-xl-147 {
        padding-right: 73rem !important;
    }

    .pe-xl-148 {
        padding-right: 73.5rem !important;
    }

    .pe-xl-149 {
        padding-right: 74rem !important;
    }

    .pe-xl-150 {
        padding-right: 74.5rem !important;
    }

    .pe-xl-151 {
        padding-right: 75rem !important;
    }

    .pe-xl-152 {
        padding-right: 75.5rem !important;
    }

    .pe-xl-153 {
        padding-right: 76rem !important;
    }

    .pe-xl-154 {
        padding-right: 76.5rem !important;
    }

    .pe-xl-155 {
        padding-right: 77rem !important;
    }

    .pe-xl-156 {
        padding-right: 77.5rem !important;
    }

    .pe-xl-157 {
        padding-right: 78rem !important;
    }

    .pe-xl-158 {
        padding-right: 78.5rem !important;
    }

    .pe-xl-159 {
        padding-right: 79rem !important;
    }

    .pe-xl-160 {
        padding-right: 79.5rem !important;
    }

    .px-xl-0 {
        padding-left: 0rem !important;
        padding-right: 0rem !important;
    }

    .px-xl-1 {
        padding-left: 0.25rem !important;
        padding-right: 0.25rem !important;
    }

    .px-xl-2 {
        padding-left: 0.5rem !important;
        padding-right: 0.5rem !important;
    }

    .px-xl-3 {
        padding-left: 1rem !important;
        padding-right: 1rem !important;
    }

    .px-xl-4 {
        padding-left: 1.5rem !important;
        padding-right: 1.5rem !important;
    }

    .px-xl-5 {
        padding-left: 2rem !important;
        padding-right: 2rem !important;
    }

    .px-xl-6 {
        padding-left: 2.5rem !important;
        padding-right: 2.5rem !important;
    }

    .px-xl-7 {
        padding-left: 3rem !important;
        padding-right: 3rem !important;
    }

    .px-xl-8 {
        padding-left: 3.5rem !important;
        padding-right: 3.5rem !important;
    }

    .px-xl-9 {
        padding-left: 4rem !important;
        padding-right: 4rem !important;
    }

    .px-xl-10 {
        padding-left: 4.5rem !important;
        padding-right: 4.5rem !important;
    }

    .px-xl-11 {
        padding-left: 5rem !important;
        padding-right: 5rem !important;
    }

    .px-xl-12 {
        padding-left: 5.5rem !important;
        padding-right: 5.5rem !important;
    }

    .px-xl-13 {
        padding-left: 6rem !important;
        padding-right: 6rem !important;
    }

    .px-xl-14 {
        padding-left: 6.5rem !important;
        padding-right: 6.5rem !important;
    }

    .px-xl-15 {
        padding-left: 7rem !important;
        padding-right: 7rem !important;
    }

    .px-xl-16 {
        padding-left: 7.5rem !important;
        padding-right: 7.5rem !important;
    }

    .px-xl-17 {
        padding-left: 8rem !important;
        padding-right: 8rem !important;
    }

    .px-xl-18 {
        padding-left: 8.5rem !important;
        padding-right: 8.5rem !important;
    }

    .px-xl-19 {
        padding-left: 9rem !important;
        padding-right: 9rem !important;
    }

    .px-xl-20 {
        padding-left: 9.5rem !important;
        padding-right: 9.5rem !important;
    }

    .px-xl-21 {
        padding-left: 10rem !important;
        padding-right: 10rem !important;
    }

    .px-xl-22 {
        padding-left: 10.5rem !important;
        padding-right: 10.5rem !important;
    }

    .px-xl-23 {
        padding-left: 11rem !important;
        padding-right: 11rem !important;
    }

    .px-xl-24 {
        padding-left: 11.5rem !important;
        padding-right: 11.5rem !important;
    }

    .px-xl-25 {
        padding-left: 12rem !important;
        padding-right: 12rem !important;
    }

    .px-xl-26 {
        padding-left: 12.5rem !important;
        padding-right: 12.5rem !important;
    }

    .px-xl-27 {
        padding-left: 13rem !important;
        padding-right: 13rem !important;
    }

    .px-xl-28 {
        padding-left: 13.5rem !important;
        padding-right: 13.5rem !important;
    }

    .px-xl-29 {
        padding-left: 14rem !important;
        padding-right: 14rem !important;
    }

    .px-xl-30 {
        padding-left: 14.5rem !important;
        padding-right: 14.5rem !important;
    }

    .px-xl-31 {
        padding-left: 15rem !important;
        padding-right: 15rem !important;
    }

    .px-xl-32 {
        padding-left: 15.5rem !important;
        padding-right: 15.5rem !important;
    }

    .px-xl-33 {
        padding-left: 16rem !important;
        padding-right: 16rem !important;
    }

    .px-xl-34 {
        padding-left: 16.5rem !important;
        padding-right: 16.5rem !important;
    }

    .px-xl-35 {
        padding-left: 17rem !important;
        padding-right: 17rem !important;
    }

    .px-xl-36 {
        padding-left: 17.5rem !important;
        padding-right: 17.5rem !important;
    }

    .px-xl-37 {
        padding-left: 18rem !important;
        padding-right: 18rem !important;
    }

    .px-xl-38 {
        padding-left: 18.5rem !important;
        padding-right: 18.5rem !important;
    }

    .px-xl-39 {
        padding-left: 19rem !important;
        padding-right: 19rem !important;
    }

    .px-xl-40 {
        padding-left: 19.5rem !important;
        padding-right: 19.5rem !important;
    }

    .px-xl-41 {
        padding-left: 20rem !important;
        padding-right: 20rem !important;
    }

    .px-xl-42 {
        padding-left: 20.5rem !important;
        padding-right: 20.5rem !important;
    }

    .px-xl-43 {
        padding-left: 21rem !important;
        padding-right: 21rem !important;
    }

    .px-xl-44 {
        padding-left: 21.5rem !important;
        padding-right: 21.5rem !important;
    }

    .px-xl-45 {
        padding-left: 22rem !important;
        padding-right: 22rem !important;
    }

    .px-xl-46 {
        padding-left: 22.5rem !important;
        padding-right: 22.5rem !important;
    }

    .px-xl-47 {
        padding-left: 23rem !important;
        padding-right: 23rem !important;
    }

    .px-xl-48 {
        padding-left: 23.5rem !important;
        padding-right: 23.5rem !important;
    }

    .px-xl-49 {
        padding-left: 24rem !important;
        padding-right: 24rem !important;
    }

    .px-xl-50 {
        padding-left: 24.5rem !important;
        padding-right: 24.5rem !important;
    }

    .px-xl-51 {
        padding-left: 25rem !important;
        padding-right: 25rem !important;
    }

    .px-xl-52 {
        padding-left: 25.5rem !important;
        padding-right: 25.5rem !important;
    }

    .px-xl-53 {
        padding-left: 26rem !important;
        padding-right: 26rem !important;
    }

    .px-xl-54 {
        padding-left: 26.5rem !important;
        padding-right: 26.5rem !important;
    }

    .px-xl-55 {
        padding-left: 27rem !important;
        padding-right: 27rem !important;
    }

    .px-xl-56 {
        padding-left: 27.5rem !important;
        padding-right: 27.5rem !important;
    }

    .px-xl-57 {
        padding-left: 28rem !important;
        padding-right: 28rem !important;
    }

    .px-xl-58 {
        padding-left: 28.5rem !important;
        padding-right: 28.5rem !important;
    }

    .px-xl-59 {
        padding-left: 29rem !important;
        padding-right: 29rem !important;
    }

    .px-xl-60 {
        padding-left: 29.5rem !important;
        padding-right: 29.5rem !important;
    }

    .px-xl-61 {
        padding-left: 30rem !important;
        padding-right: 30rem !important;
    }

    .px-xl-62 {
        padding-left: 30.5rem !important;
        padding-right: 30.5rem !important;
    }

    .px-xl-63 {
        padding-left: 31rem !important;
        padding-right: 31rem !important;
    }

    .px-xl-64 {
        padding-left: 31.5rem !important;
        padding-right: 31.5rem !important;
    }

    .px-xl-65 {
        padding-left: 32rem !important;
        padding-right: 32rem !important;
    }

    .px-xl-66 {
        padding-left: 32.5rem !important;
        padding-right: 32.5rem !important;
    }

    .px-xl-67 {
        padding-left: 33rem !important;
        padding-right: 33rem !important;
    }

    .px-xl-68 {
        padding-left: 33.5rem !important;
        padding-right: 33.5rem !important;
    }

    .px-xl-69 {
        padding-left: 34rem !important;
        padding-right: 34rem !important;
    }

    .px-xl-70 {
        padding-left: 34.5rem !important;
        padding-right: 34.5rem !important;
    }

    .px-xl-71 {
        padding-left: 35rem !important;
        padding-right: 35rem !important;
    }

    .px-xl-72 {
        padding-left: 35.5rem !important;
        padding-right: 35.5rem !important;
    }

    .px-xl-73 {
        padding-left: 36rem !important;
        padding-right: 36rem !important;
    }

    .px-xl-74 {
        padding-left: 36.5rem !important;
        padding-right: 36.5rem !important;
    }

    .px-xl-75 {
        padding-left: 37rem !important;
        padding-right: 37rem !important;
    }

    .px-xl-76 {
        padding-left: 37.5rem !important;
        padding-right: 37.5rem !important;
    }

    .px-xl-77 {
        padding-left: 38rem !important;
        padding-right: 38rem !important;
    }

    .px-xl-78 {
        padding-left: 38.5rem !important;
        padding-right: 38.5rem !important;
    }

    .px-xl-79 {
        padding-left: 39rem !important;
        padding-right: 39rem !important;
    }

    .px-xl-80 {
        padding-left: 39.5rem !important;
        padding-right: 39.5rem !important;
    }

    .px-xl-81 {
        padding-left: 40rem !important;
        padding-right: 40rem !important;
    }

    .px-xl-82 {
        padding-left: 40.5rem !important;
        padding-right: 40.5rem !important;
    }

    .px-xl-83 {
        padding-left: 41rem !important;
        padding-right: 41rem !important;
    }

    .px-xl-84 {
        padding-left: 41.5rem !important;
        padding-right: 41.5rem !important;
    }

    .px-xl-85 {
        padding-left: 42rem !important;
        padding-right: 42rem !important;
    }

    .px-xl-86 {
        padding-left: 42.5rem !important;
        padding-right: 42.5rem !important;
    }

    .px-xl-87 {
        padding-left: 43rem !important;
        padding-right: 43rem !important;
    }

    .px-xl-88 {
        padding-left: 43.5rem !important;
        padding-right: 43.5rem !important;
    }

    .px-xl-89 {
        padding-left: 44rem !important;
        padding-right: 44rem !important;
    }

    .px-xl-90 {
        padding-left: 44.5rem !important;
        padding-right: 44.5rem !important;
    }

    .px-xl-91 {
        padding-left: 45rem !important;
        padding-right: 45rem !important;
    }

    .px-xl-92 {
        padding-left: 45.5rem !important;
        padding-right: 45.5rem !important;
    }

    .px-xl-93 {
        padding-left: 46rem !important;
        padding-right: 46rem !important;
    }

    .px-xl-94 {
        padding-left: 46.5rem !important;
        padding-right: 46.5rem !important;
    }

    .px-xl-95 {
        padding-left: 47rem !important;
        padding-right: 47rem !important;
    }

    .px-xl-96 {
        padding-left: 47.5rem !important;
        padding-right: 47.5rem !important;
    }

    .px-xl-97 {
        padding-left: 48rem !important;
        padding-right: 48rem !important;
    }

    .px-xl-98 {
        padding-left: 48.5rem !important;
        padding-right: 48.5rem !important;
    }

    .px-xl-99 {
        padding-left: 49rem !important;
        padding-right: 49rem !important;
    }

    .px-xl-100 {
        padding-left: 49.5rem !important;
        padding-right: 49.5rem !important;
    }

    .px-xl-101 {
        padding-left: 50rem !important;
        padding-right: 50rem !important;
    }

    .px-xl-102 {
        padding-left: 50.5rem !important;
        padding-right: 50.5rem !important;
    }

    .px-xl-103 {
        padding-left: 51rem !important;
        padding-right: 51rem !important;
    }

    .px-xl-104 {
        padding-left: 51.5rem !important;
        padding-right: 51.5rem !important;
    }

    .px-xl-105 {
        padding-left: 52rem !important;
        padding-right: 52rem !important;
    }

    .px-xl-106 {
        padding-left: 52.5rem !important;
        padding-right: 52.5rem !important;
    }

    .px-xl-107 {
        padding-left: 53rem !important;
        padding-right: 53rem !important;
    }

    .px-xl-108 {
        padding-left: 53.5rem !important;
        padding-right: 53.5rem !important;
    }

    .px-xl-109 {
        padding-left: 54rem !important;
        padding-right: 54rem !important;
    }

    .px-xl-110 {
        padding-left: 54.5rem !important;
        padding-right: 54.5rem !important;
    }

    .px-xl-111 {
        padding-left: 55rem !important;
        padding-right: 55rem !important;
    }

    .px-xl-112 {
        padding-left: 55.5rem !important;
        padding-right: 55.5rem !important;
    }

    .px-xl-113 {
        padding-left: 56rem !important;
        padding-right: 56rem !important;
    }

    .px-xl-114 {
        padding-left: 56.5rem !important;
        padding-right: 56.5rem !important;
    }

    .px-xl-115 {
        padding-left: 57rem !important;
        padding-right: 57rem !important;
    }

    .px-xl-116 {
        padding-left: 57.5rem !important;
        padding-right: 57.5rem !important;
    }

    .px-xl-117 {
        padding-left: 58rem !important;
        padding-right: 58rem !important;
    }

    .px-xl-118 {
        padding-left: 58.5rem !important;
        padding-right: 58.5rem !important;
    }

    .px-xl-119 {
        padding-left: 59rem !important;
        padding-right: 59rem !important;
    }

    .px-xl-120 {
        padding-left: 59.5rem !important;
        padding-right: 59.5rem !important;
    }

    .px-xl-121 {
        padding-left: 60rem !important;
        padding-right: 60rem !important;
    }

    .px-xl-122 {
        padding-left: 60.5rem !important;
        padding-right: 60.5rem !important;
    }

    .px-xl-123 {
        padding-left: 61rem !important;
        padding-right: 61rem !important;
    }

    .px-xl-124 {
        padding-left: 61.5rem !important;
        padding-right: 61.5rem !important;
    }

    .px-xl-125 {
        padding-left: 62rem !important;
        padding-right: 62rem !important;
    }

    .px-xl-126 {
        padding-left: 62.5rem !important;
        padding-right: 62.5rem !important;
    }

    .px-xl-127 {
        padding-left: 63rem !important;
        padding-right: 63rem !important;
    }

    .px-xl-128 {
        padding-left: 63.5rem !important;
        padding-right: 63.5rem !important;
    }

    .px-xl-129 {
        padding-left: 64rem !important;
        padding-right: 64rem !important;
    }

    .px-xl-130 {
        padding-left: 64.5rem !important;
        padding-right: 64.5rem !important;
    }

    .px-xl-131 {
        padding-left: 65rem !important;
        padding-right: 65rem !important;
    }

    .px-xl-132 {
        padding-left: 65.5rem !important;
        padding-right: 65.5rem !important;
    }

    .px-xl-133 {
        padding-left: 66rem !important;
        padding-right: 66rem !important;
    }

    .px-xl-134 {
        padding-left: 66.5rem !important;
        padding-right: 66.5rem !important;
    }

    .px-xl-135 {
        padding-left: 67rem !important;
        padding-right: 67rem !important;
    }

    .px-xl-136 {
        padding-left: 67.5rem !important;
        padding-right: 67.5rem !important;
    }

    .px-xl-137 {
        padding-left: 68rem !important;
        padding-right: 68rem !important;
    }

    .px-xl-138 {
        padding-left: 68.5rem !important;
        padding-right: 68.5rem !important;
    }

    .px-xl-139 {
        padding-left: 69rem !important;
        padding-right: 69rem !important;
    }

    .px-xl-140 {
        padding-left: 69.5rem !important;
        padding-right: 69.5rem !important;
    }

    .px-xl-141 {
        padding-left: 70rem !important;
        padding-right: 70rem !important;
    }

    .px-xl-142 {
        padding-left: 70.5rem !important;
        padding-right: 70.5rem !important;
    }

    .px-xl-143 {
        padding-left: 71rem !important;
        padding-right: 71rem !important;
    }

    .px-xl-144 {
        padding-left: 71.5rem !important;
        padding-right: 71.5rem !important;
    }

    .px-xl-145 {
        padding-left: 72rem !important;
        padding-right: 72rem !important;
    }

    .px-xl-146 {
        padding-left: 72.5rem !important;
        padding-right: 72.5rem !important;
    }

    .px-xl-147 {
        padding-left: 73rem !important;
        padding-right: 73rem !important;
    }

    .px-xl-148 {
        padding-left: 73.5rem !important;
        padding-right: 73.5rem !important;
    }

    .px-xl-149 {
        padding-left: 74rem !important;
        padding-right: 74rem !important;
    }

    .px-xl-150 {
        padding-left: 74.5rem !important;
        padding-right: 74.5rem !important;
    }

    .px-xl-151 {
        padding-left: 75rem !important;
        padding-right: 75rem !important;
    }

    .px-xl-152 {
        padding-left: 75.5rem !important;
        padding-right: 75.5rem !important;
    }

    .px-xl-153 {
        padding-left: 76rem !important;
        padding-right: 76rem !important;
    }

    .px-xl-154 {
        padding-left: 76.5rem !important;
        padding-right: 76.5rem !important;
    }

    .px-xl-155 {
        padding-left: 77rem !important;
        padding-right: 77rem !important;
    }

    .px-xl-156 {
        padding-left: 77.5rem !important;
        padding-right: 77.5rem !important;
    }

    .px-xl-157 {
        padding-left: 78rem !important;
        padding-right: 78rem !important;
    }

    .px-xl-158 {
        padding-left: 78.5rem !important;
        padding-right: 78.5rem !important;
    }

    .px-xl-159 {
        padding-left: 79rem !important;
        padding-right: 79rem !important;
    }

    .px-xl-160 {
        padding-left: 79.5rem !important;
        padding-right: 79.5rem !important;
    }

    .py-xl-0 {
        padding-top: 0rem !important;
        padding-bottom: 0rem !important;
    }

    .py-xl-1 {
        padding-top: 0.25rem !important;
        padding-bottom: 0.25rem !important;
    }

    .py-xl-2 {
        padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;
    }

    .py-xl-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
    }

    .py-xl-4 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important;
    }

    .py-xl-5 {
        padding-top: 2rem !important;
        padding-bottom: 2rem !important;
    }

    .py-xl-6 {
        padding-top: 2.5rem !important;
        padding-bottom: 2.5rem !important;
    }

    .py-xl-7 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important;
    }

    .py-xl-8 {
        padding-top: 3.5rem !important;
        padding-bottom: 3.5rem !important;
    }

    .py-xl-9 {
        padding-top: 4rem !important;
        padding-bottom: 4rem !important;
    }

    .py-xl-10 {
        padding-top: 4.5rem !important;
        padding-bottom: 4.5rem !important;
    }

    .py-xl-11 {
        padding-top: 5rem !important;
        padding-bottom: 5rem !important;
    }

    .py-xl-12 {
        padding-top: 5.5rem !important;
        padding-bottom: 5.5rem !important;
    }

    .py-xl-13 {
        padding-top: 6rem !important;
        padding-bottom: 6rem !important;
    }

    .py-xl-14 {
        padding-top: 6.5rem !important;
        padding-bottom: 6.5rem !important;
    }

    .py-xl-15 {
        padding-top: 7rem !important;
        padding-bottom: 7rem !important;
    }

    .py-xl-16 {
        padding-top: 7.5rem !important;
        padding-bottom: 7.5rem !important;
    }

    .py-xl-17 {
        padding-top: 8rem !important;
        padding-bottom: 8rem !important;
    }

    .py-xl-18 {
        padding-top: 8.5rem !important;
        padding-bottom: 8.5rem !important;
    }

    .py-xl-19 {
        padding-top: 9rem !important;
        padding-bottom: 9rem !important;
    }

    .py-xl-20 {
        padding-top: 9.5rem !important;
        padding-bottom: 9.5rem !important;
    }

    .py-xl-21 {
        padding-top: 10rem !important;
        padding-bottom: 10rem !important;
    }

    .py-xl-22 {
        padding-top: 10.5rem !important;
        padding-bottom: 10.5rem !important;
    }

    .py-xl-23 {
        padding-top: 11rem !important;
        padding-bottom: 11rem !important;
    }

    .py-xl-24 {
        padding-top: 11.5rem !important;
        padding-bottom: 11.5rem !important;
    }

    .py-xl-25 {
        padding-top: 12rem !important;
        padding-bottom: 12rem !important;
    }

    .py-xl-26 {
        padding-top: 12.5rem !important;
        padding-bottom: 12.5rem !important;
    }

    .py-xl-27 {
        padding-top: 13rem !important;
        padding-bottom: 13rem !important;
    }

    .py-xl-28 {
        padding-top: 13.5rem !important;
        padding-bottom: 13.5rem !important;
    }

    .py-xl-29 {
        padding-top: 14rem !important;
        padding-bottom: 14rem !important;
    }

    .py-xl-30 {
        padding-top: 14.5rem !important;
        padding-bottom: 14.5rem !important;
    }

    .py-xl-31 {
        padding-top: 15rem !important;
        padding-bottom: 15rem !important;
    }

    .py-xl-32 {
        padding-top: 15.5rem !important;
        padding-bottom: 15.5rem !important;
    }

    .py-xl-33 {
        padding-top: 16rem !important;
        padding-bottom: 16rem !important;
    }

    .py-xl-34 {
        padding-top: 16.5rem !important;
        padding-bottom: 16.5rem !important;
    }

    .py-xl-35 {
        padding-top: 17rem !important;
        padding-bottom: 17rem !important;
    }

    .py-xl-36 {
        padding-top: 17.5rem !important;
        padding-bottom: 17.5rem !important;
    }

    .py-xl-37 {
        padding-top: 18rem !important;
        padding-bottom: 18rem !important;
    }

    .py-xl-38 {
        padding-top: 18.5rem !important;
        padding-bottom: 18.5rem !important;
    }

    .py-xl-39 {
        padding-top: 19rem !important;
        padding-bottom: 19rem !important;
    }

    .py-xl-40 {
        padding-top: 19.5rem !important;
        padding-bottom: 19.5rem !important;
    }

    .py-xl-41 {
        padding-top: 20rem !important;
        padding-bottom: 20rem !important;
    }

    .py-xl-42 {
        padding-top: 20.5rem !important;
        padding-bottom: 20.5rem !important;
    }

    .py-xl-43 {
        padding-top: 21rem !important;
        padding-bottom: 21rem !important;
    }

    .py-xl-44 {
        padding-top: 21.5rem !important;
        padding-bottom: 21.5rem !important;
    }

    .py-xl-45 {
        padding-top: 22rem !important;
        padding-bottom: 22rem !important;
    }

    .py-xl-46 {
        padding-top: 22.5rem !important;
        padding-bottom: 22.5rem !important;
    }

    .py-xl-47 {
        padding-top: 23rem !important;
        padding-bottom: 23rem !important;
    }

    .py-xl-48 {
        padding-top: 23.5rem !important;
        padding-bottom: 23.5rem !important;
    }

    .py-xl-49 {
        padding-top: 24rem !important;
        padding-bottom: 24rem !important;
    }

    .py-xl-50 {
        padding-top: 24.5rem !important;
        padding-bottom: 24.5rem !important;
    }

    .py-xl-51 {
        padding-top: 25rem !important;
        padding-bottom: 25rem !important;
    }

    .py-xl-52 {
        padding-top: 25.5rem !important;
        padding-bottom: 25.5rem !important;
    }

    .py-xl-53 {
        padding-top: 26rem !important;
        padding-bottom: 26rem !important;
    }

    .py-xl-54 {
        padding-top: 26.5rem !important;
        padding-bottom: 26.5rem !important;
    }

    .py-xl-55 {
        padding-top: 27rem !important;
        padding-bottom: 27rem !important;
    }

    .py-xl-56 {
        padding-top: 27.5rem !important;
        padding-bottom: 27.5rem !important;
    }

    .py-xl-57 {
        padding-top: 28rem !important;
        padding-bottom: 28rem !important;
    }

    .py-xl-58 {
        padding-top: 28.5rem !important;
        padding-bottom: 28.5rem !important;
    }

    .py-xl-59 {
        padding-top: 29rem !important;
        padding-bottom: 29rem !important;
    }

    .py-xl-60 {
        padding-top: 29.5rem !important;
        padding-bottom: 29.5rem !important;
    }

    .py-xl-61 {
        padding-top: 30rem !important;
        padding-bottom: 30rem !important;
    }

    .py-xl-62 {
        padding-top: 30.5rem !important;
        padding-bottom: 30.5rem !important;
    }

    .py-xl-63 {
        padding-top: 31rem !important;
        padding-bottom: 31rem !important;
    }

    .py-xl-64 {
        padding-top: 31.5rem !important;
        padding-bottom: 31.5rem !important;
    }

    .py-xl-65 {
        padding-top: 32rem !important;
        padding-bottom: 32rem !important;
    }

    .py-xl-66 {
        padding-top: 32.5rem !important;
        padding-bottom: 32.5rem !important;
    }

    .py-xl-67 {
        padding-top: 33rem !important;
        padding-bottom: 33rem !important;
    }

    .py-xl-68 {
        padding-top: 33.5rem !important;
        padding-bottom: 33.5rem !important;
    }

    .py-xl-69 {
        padding-top: 34rem !important;
        padding-bottom: 34rem !important;
    }

    .py-xl-70 {
        padding-top: 34.5rem !important;
        padding-bottom: 34.5rem !important;
    }

    .py-xl-71 {
        padding-top: 35rem !important;
        padding-bottom: 35rem !important;
    }

    .py-xl-72 {
        padding-top: 35.5rem !important;
        padding-bottom: 35.5rem !important;
    }

    .py-xl-73 {
        padding-top: 36rem !important;
        padding-bottom: 36rem !important;
    }

    .py-xl-74 {
        padding-top: 36.5rem !important;
        padding-bottom: 36.5rem !important;
    }

    .py-xl-75 {
        padding-top: 37rem !important;
        padding-bottom: 37rem !important;
    }

    .py-xl-76 {
        padding-top: 37.5rem !important;
        padding-bottom: 37.5rem !important;
    }

    .py-xl-77 {
        padding-top: 38rem !important;
        padding-bottom: 38rem !important;
    }

    .py-xl-78 {
        padding-top: 38.5rem !important;
        padding-bottom: 38.5rem !important;
    }

    .py-xl-79 {
        padding-top: 39rem !important;
        padding-bottom: 39rem !important;
    }

    .py-xl-80 {
        padding-top: 39.5rem !important;
        padding-bottom: 39.5rem !important;
    }

    .py-xl-81 {
        padding-top: 40rem !important;
        padding-bottom: 40rem !important;
    }

    .py-xl-82 {
        padding-top: 40.5rem !important;
        padding-bottom: 40.5rem !important;
    }

    .py-xl-83 {
        padding-top: 41rem !important;
        padding-bottom: 41rem !important;
    }

    .py-xl-84 {
        padding-top: 41.5rem !important;
        padding-bottom: 41.5rem !important;
    }

    .py-xl-85 {
        padding-top: 42rem !important;
        padding-bottom: 42rem !important;
    }

    .py-xl-86 {
        padding-top: 42.5rem !important;
        padding-bottom: 42.5rem !important;
    }

    .py-xl-87 {
        padding-top: 43rem !important;
        padding-bottom: 43rem !important;
    }

    .py-xl-88 {
        padding-top: 43.5rem !important;
        padding-bottom: 43.5rem !important;
    }

    .py-xl-89 {
        padding-top: 44rem !important;
        padding-bottom: 44rem !important;
    }

    .py-xl-90 {
        padding-top: 44.5rem !important;
        padding-bottom: 44.5rem !important;
    }

    .py-xl-91 {
        padding-top: 45rem !important;
        padding-bottom: 45rem !important;
    }

    .py-xl-92 {
        padding-top: 45.5rem !important;
        padding-bottom: 45.5rem !important;
    }

    .py-xl-93 {
        padding-top: 46rem !important;
        padding-bottom: 46rem !important;
    }

    .py-xl-94 {
        padding-top: 46.5rem !important;
        padding-bottom: 46.5rem !important;
    }

    .py-xl-95 {
        padding-top: 47rem !important;
        padding-bottom: 47rem !important;
    }

    .py-xl-96 {
        padding-top: 47.5rem !important;
        padding-bottom: 47.5rem !important;
    }

    .py-xl-97 {
        padding-top: 48rem !important;
        padding-bottom: 48rem !important;
    }

    .py-xl-98 {
        padding-top: 48.5rem !important;
        padding-bottom: 48.5rem !important;
    }

    .py-xl-99 {
        padding-top: 49rem !important;
        padding-bottom: 49rem !important;
    }

    .py-xl-100 {
        padding-top: 49.5rem !important;
        padding-bottom: 49.5rem !important;
    }

    .py-xl-101 {
        padding-top: 50rem !important;
        padding-bottom: 50rem !important;
    }

    .py-xl-102 {
        padding-top: 50.5rem !important;
        padding-bottom: 50.5rem !important;
    }

    .py-xl-103 {
        padding-top: 51rem !important;
        padding-bottom: 51rem !important;
    }

    .py-xl-104 {
        padding-top: 51.5rem !important;
        padding-bottom: 51.5rem !important;
    }

    .py-xl-105 {
        padding-top: 52rem !important;
        padding-bottom: 52rem !important;
    }

    .py-xl-106 {
        padding-top: 52.5rem !important;
        padding-bottom: 52.5rem !important;
    }

    .py-xl-107 {
        padding-top: 53rem !important;
        padding-bottom: 53rem !important;
    }

    .py-xl-108 {
        padding-top: 53.5rem !important;
        padding-bottom: 53.5rem !important;
    }

    .py-xl-109 {
        padding-top: 54rem !important;
        padding-bottom: 54rem !important;
    }

    .py-xl-110 {
        padding-top: 54.5rem !important;
        padding-bottom: 54.5rem !important;
    }

    .py-xl-111 {
        padding-top: 55rem !important;
        padding-bottom: 55rem !important;
    }

    .py-xl-112 {
        padding-top: 55.5rem !important;
        padding-bottom: 55.5rem !important;
    }

    .py-xl-113 {
        padding-top: 56rem !important;
        padding-bottom: 56rem !important;
    }

    .py-xl-114 {
        padding-top: 56.5rem !important;
        padding-bottom: 56.5rem !important;
    }

    .py-xl-115 {
        padding-top: 57rem !important;
        padding-bottom: 57rem !important;
    }

    .py-xl-116 {
        padding-top: 57.5rem !important;
        padding-bottom: 57.5rem !important;
    }

    .py-xl-117 {
        padding-top: 58rem !important;
        padding-bottom: 58rem !important;
    }

    .py-xl-118 {
        padding-top: 58.5rem !important;
        padding-bottom: 58.5rem !important;
    }

    .py-xl-119 {
        padding-top: 59rem !important;
        padding-bottom: 59rem !important;
    }

    .py-xl-120 {
        padding-top: 59.5rem !important;
        padding-bottom: 59.5rem !important;
    }

    .py-xl-121 {
        padding-top: 60rem !important;
        padding-bottom: 60rem !important;
    }

    .py-xl-122 {
        padding-top: 60.5rem !important;
        padding-bottom: 60.5rem !important;
    }

    .py-xl-123 {
        padding-top: 61rem !important;
        padding-bottom: 61rem !important;
    }

    .py-xl-124 {
        padding-top: 61.5rem !important;
        padding-bottom: 61.5rem !important;
    }

    .py-xl-125 {
        padding-top: 62rem !important;
        padding-bottom: 62rem !important;
    }

    .py-xl-126 {
        padding-top: 62.5rem !important;
        padding-bottom: 62.5rem !important;
    }

    .py-xl-127 {
        padding-top: 63rem !important;
        padding-bottom: 63rem !important;
    }

    .py-xl-128 {
        padding-top: 63.5rem !important;
        padding-bottom: 63.5rem !important;
    }

    .py-xl-129 {
        padding-top: 64rem !important;
        padding-bottom: 64rem !important;
    }

    .py-xl-130 {
        padding-top: 64.5rem !important;
        padding-bottom: 64.5rem !important;
    }

    .py-xl-131 {
        padding-top: 65rem !important;
        padding-bottom: 65rem !important;
    }

    .py-xl-132 {
        padding-top: 65.5rem !important;
        padding-bottom: 65.5rem !important;
    }

    .py-xl-133 {
        padding-top: 66rem !important;
        padding-bottom: 66rem !important;
    }

    .py-xl-134 {
        padding-top: 66.5rem !important;
        padding-bottom: 66.5rem !important;
    }

    .py-xl-135 {
        padding-top: 67rem !important;
        padding-bottom: 67rem !important;
    }

    .py-xl-136 {
        padding-top: 67.5rem !important;
        padding-bottom: 67.5rem !important;
    }

    .py-xl-137 {
        padding-top: 68rem !important;
        padding-bottom: 68rem !important;
    }

    .py-xl-138 {
        padding-top: 68.5rem !important;
        padding-bottom: 68.5rem !important;
    }

    .py-xl-139 {
        padding-top: 69rem !important;
        padding-bottom: 69rem !important;
    }

    .py-xl-140 {
        padding-top: 69.5rem !important;
        padding-bottom: 69.5rem !important;
    }

    .py-xl-141 {
        padding-top: 70rem !important;
        padding-bottom: 70rem !important;
    }

    .py-xl-142 {
        padding-top: 70.5rem !important;
        padding-bottom: 70.5rem !important;
    }

    .py-xl-143 {
        padding-top: 71rem !important;
        padding-bottom: 71rem !important;
    }

    .py-xl-144 {
        padding-top: 71.5rem !important;
        padding-bottom: 71.5rem !important;
    }

    .py-xl-145 {
        padding-top: 72rem !important;
        padding-bottom: 72rem !important;
    }

    .py-xl-146 {
        padding-top: 72.5rem !important;
        padding-bottom: 72.5rem !important;
    }

    .py-xl-147 {
        padding-top: 73rem !important;
        padding-bottom: 73rem !important;
    }

    .py-xl-148 {
        padding-top: 73.5rem !important;
        padding-bottom: 73.5rem !important;
    }

    .py-xl-149 {
        padding-top: 74rem !important;
        padding-bottom: 74rem !important;
    }

    .py-xl-150 {
        padding-top: 74.5rem !important;
        padding-bottom: 74.5rem !important;
    }

    .py-xl-151 {
        padding-top: 75rem !important;
        padding-bottom: 75rem !important;
    }

    .py-xl-152 {
        padding-top: 75.5rem !important;
        padding-bottom: 75.5rem !important;
    }

    .py-xl-153 {
        padding-top: 76rem !important;
        padding-bottom: 76rem !important;
    }

    .py-xl-154 {
        padding-top: 76.5rem !important;
        padding-bottom: 76.5rem !important;
    }

    .py-xl-155 {
        padding-top: 77rem !important;
        padding-bottom: 77rem !important;
    }

    .py-xl-156 {
        padding-top: 77.5rem !important;
        padding-bottom: 77.5rem !important;
    }

    .py-xl-157 {
        padding-top: 78rem !important;
        padding-bottom: 78rem !important;
    }

    .py-xl-158 {
        padding-top: 78.5rem !important;
        padding-bottom: 78.5rem !important;
    }

    .py-xl-159 {
        padding-top: 79rem !important;
        padding-bottom: 79rem !important;
    }

    .py-xl-160 {
        padding-top: 79.5rem !important;
        padding-bottom: 79.5rem !important;
    }
}

@media (min-width: 1400px) {
    .p-xxl-0 {
        padding: 0rem !important;
    }

    .p-xxl-1 {
        padding: 0.25rem !important;
    }

    .p-xxl-2 {
        padding: 0.5rem !important;
    }

    .p-xxl-3 {
        padding: 1rem !important;
    }

    .p-xxl-4 {
        padding: 1.5rem !important;
    }

    .p-xxl-5 {
        padding: 2rem !important;
    }

    .p-xxl-6 {
        padding: 2.5rem !important;
    }

    .p-xxl-7 {
        padding: 3rem !important;
    }

    .p-xxl-8 {
        padding: 3.5rem !important;
    }

    .p-xxl-9 {
        padding: 4rem !important;
    }

    .p-xxl-10 {
        padding: 4.5rem !important;
    }

    .p-xxl-11 {
        padding: 5rem !important;
    }

    .p-xxl-12 {
        padding: 5.5rem !important;
    }

    .p-xxl-13 {
        padding: 6rem !important;
    }

    .p-xxl-14 {
        padding: 6.5rem !important;
    }

    .p-xxl-15 {
        padding: 7rem !important;
    }

    .p-xxl-16 {
        padding: 7.5rem !important;
    }

    .p-xxl-17 {
        padding: 8rem !important;
    }

    .p-xxl-18 {
        padding: 8.5rem !important;
    }

    .p-xxl-19 {
        padding: 9rem !important;
    }

    .p-xxl-20 {
        padding: 9.5rem !important;
    }

    .p-xxl-21 {
        padding: 10rem !important;
    }

    .p-xxl-22 {
        padding: 10.5rem !important;
    }

    .p-xxl-23 {
        padding: 11rem !important;
    }

    .p-xxl-24 {
        padding: 11.5rem !important;
    }

    .p-xxl-25 {
        padding: 12rem !important;
    }

    .p-xxl-26 {
        padding: 12.5rem !important;
    }

    .p-xxl-27 {
        padding: 13rem !important;
    }

    .p-xxl-28 {
        padding: 13.5rem !important;
    }

    .p-xxl-29 {
        padding: 14rem !important;
    }

    .p-xxl-30 {
        padding: 14.5rem !important;
    }

    .p-xxl-31 {
        padding: 15rem !important;
    }

    .p-xxl-32 {
        padding: 15.5rem !important;
    }

    .p-xxl-33 {
        padding: 16rem !important;
    }

    .p-xxl-34 {
        padding: 16.5rem !important;
    }

    .p-xxl-35 {
        padding: 17rem !important;
    }

    .p-xxl-36 {
        padding: 17.5rem !important;
    }

    .p-xxl-37 {
        padding: 18rem !important;
    }

    .p-xxl-38 {
        padding: 18.5rem !important;
    }

    .p-xxl-39 {
        padding: 19rem !important;
    }

    .p-xxl-40 {
        padding: 19.5rem !important;
    }

    .p-xxl-41 {
        padding: 20rem !important;
    }

    .p-xxl-42 {
        padding: 20.5rem !important;
    }

    .p-xxl-43 {
        padding: 21rem !important;
    }

    .p-xxl-44 {
        padding: 21.5rem !important;
    }

    .p-xxl-45 {
        padding: 22rem !important;
    }

    .p-xxl-46 {
        padding: 22.5rem !important;
    }

    .p-xxl-47 {
        padding: 23rem !important;
    }

    .p-xxl-48 {
        padding: 23.5rem !important;
    }

    .p-xxl-49 {
        padding: 24rem !important;
    }

    .p-xxl-50 {
        padding: 24.5rem !important;
    }

    .p-xxl-51 {
        padding: 25rem !important;
    }

    .p-xxl-52 {
        padding: 25.5rem !important;
    }

    .p-xxl-53 {
        padding: 26rem !important;
    }

    .p-xxl-54 {
        padding: 26.5rem !important;
    }

    .p-xxl-55 {
        padding: 27rem !important;
    }

    .p-xxl-56 {
        padding: 27.5rem !important;
    }

    .p-xxl-57 {
        padding: 28rem !important;
    }

    .p-xxl-58 {
        padding: 28.5rem !important;
    }

    .p-xxl-59 {
        padding: 29rem !important;
    }

    .p-xxl-60 {
        padding: 29.5rem !important;
    }

    .p-xxl-61 {
        padding: 30rem !important;
    }

    .p-xxl-62 {
        padding: 30.5rem !important;
    }

    .p-xxl-63 {
        padding: 31rem !important;
    }

    .p-xxl-64 {
        padding: 31.5rem !important;
    }

    .p-xxl-65 {
        padding: 32rem !important;
    }

    .p-xxl-66 {
        padding: 32.5rem !important;
    }

    .p-xxl-67 {
        padding: 33rem !important;
    }

    .p-xxl-68 {
        padding: 33.5rem !important;
    }

    .p-xxl-69 {
        padding: 34rem !important;
    }

    .p-xxl-70 {
        padding: 34.5rem !important;
    }

    .p-xxl-71 {
        padding: 35rem !important;
    }

    .p-xxl-72 {
        padding: 35.5rem !important;
    }

    .p-xxl-73 {
        padding: 36rem !important;
    }

    .p-xxl-74 {
        padding: 36.5rem !important;
    }

    .p-xxl-75 {
        padding: 37rem !important;
    }

    .p-xxl-76 {
        padding: 37.5rem !important;
    }

    .p-xxl-77 {
        padding: 38rem !important;
    }

    .p-xxl-78 {
        padding: 38.5rem !important;
    }

    .p-xxl-79 {
        padding: 39rem !important;
    }

    .p-xxl-80 {
        padding: 39.5rem !important;
    }

    .p-xxl-81 {
        padding: 40rem !important;
    }

    .p-xxl-82 {
        padding: 40.5rem !important;
    }

    .p-xxl-83 {
        padding: 41rem !important;
    }

    .p-xxl-84 {
        padding: 41.5rem !important;
    }

    .p-xxl-85 {
        padding: 42rem !important;
    }

    .p-xxl-86 {
        padding: 42.5rem !important;
    }

    .p-xxl-87 {
        padding: 43rem !important;
    }

    .p-xxl-88 {
        padding: 43.5rem !important;
    }

    .p-xxl-89 {
        padding: 44rem !important;
    }

    .p-xxl-90 {
        padding: 44.5rem !important;
    }

    .p-xxl-91 {
        padding: 45rem !important;
    }

    .p-xxl-92 {
        padding: 45.5rem !important;
    }

    .p-xxl-93 {
        padding: 46rem !important;
    }

    .p-xxl-94 {
        padding: 46.5rem !important;
    }

    .p-xxl-95 {
        padding: 47rem !important;
    }

    .p-xxl-96 {
        padding: 47.5rem !important;
    }

    .p-xxl-97 {
        padding: 48rem !important;
    }

    .p-xxl-98 {
        padding: 48.5rem !important;
    }

    .p-xxl-99 {
        padding: 49rem !important;
    }

    .p-xxl-100 {
        padding: 49.5rem !important;
    }

    .p-xxl-101 {
        padding: 50rem !important;
    }

    .p-xxl-102 {
        padding: 50.5rem !important;
    }

    .p-xxl-103 {
        padding: 51rem !important;
    }

    .p-xxl-104 {
        padding: 51.5rem !important;
    }

    .p-xxl-105 {
        padding: 52rem !important;
    }

    .p-xxl-106 {
        padding: 52.5rem !important;
    }

    .p-xxl-107 {
        padding: 53rem !important;
    }

    .p-xxl-108 {
        padding: 53.5rem !important;
    }

    .p-xxl-109 {
        padding: 54rem !important;
    }

    .p-xxl-110 {
        padding: 54.5rem !important;
    }

    .p-xxl-111 {
        padding: 55rem !important;
    }

    .p-xxl-112 {
        padding: 55.5rem !important;
    }

    .p-xxl-113 {
        padding: 56rem !important;
    }

    .p-xxl-114 {
        padding: 56.5rem !important;
    }

    .p-xxl-115 {
        padding: 57rem !important;
    }

    .p-xxl-116 {
        padding: 57.5rem !important;
    }

    .p-xxl-117 {
        padding: 58rem !important;
    }

    .p-xxl-118 {
        padding: 58.5rem !important;
    }

    .p-xxl-119 {
        padding: 59rem !important;
    }

    .p-xxl-120 {
        padding: 59.5rem !important;
    }

    .p-xxl-121 {
        padding: 60rem !important;
    }

    .p-xxl-122 {
        padding: 60.5rem !important;
    }

    .p-xxl-123 {
        padding: 61rem !important;
    }

    .p-xxl-124 {
        padding: 61.5rem !important;
    }

    .p-xxl-125 {
        padding: 62rem !important;
    }

    .p-xxl-126 {
        padding: 62.5rem !important;
    }

    .p-xxl-127 {
        padding: 63rem !important;
    }

    .p-xxl-128 {
        padding: 63.5rem !important;
    }

    .p-xxl-129 {
        padding: 64rem !important;
    }

    .p-xxl-130 {
        padding: 64.5rem !important;
    }

    .p-xxl-131 {
        padding: 65rem !important;
    }

    .p-xxl-132 {
        padding: 65.5rem !important;
    }

    .p-xxl-133 {
        padding: 66rem !important;
    }

    .p-xxl-134 {
        padding: 66.5rem !important;
    }

    .p-xxl-135 {
        padding: 67rem !important;
    }

    .p-xxl-136 {
        padding: 67.5rem !important;
    }

    .p-xxl-137 {
        padding: 68rem !important;
    }

    .p-xxl-138 {
        padding: 68.5rem !important;
    }

    .p-xxl-139 {
        padding: 69rem !important;
    }

    .p-xxl-140 {
        padding: 69.5rem !important;
    }

    .p-xxl-141 {
        padding: 70rem !important;
    }

    .p-xxl-142 {
        padding: 70.5rem !important;
    }

    .p-xxl-143 {
        padding: 71rem !important;
    }

    .p-xxl-144 {
        padding: 71.5rem !important;
    }

    .p-xxl-145 {
        padding: 72rem !important;
    }

    .p-xxl-146 {
        padding: 72.5rem !important;
    }

    .p-xxl-147 {
        padding: 73rem !important;
    }

    .p-xxl-148 {
        padding: 73.5rem !important;
    }

    .p-xxl-149 {
        padding: 74rem !important;
    }

    .p-xxl-150 {
        padding: 74.5rem !important;
    }

    .p-xxl-151 {
        padding: 75rem !important;
    }

    .p-xxl-152 {
        padding: 75.5rem !important;
    }

    .p-xxl-153 {
        padding: 76rem !important;
    }

    .p-xxl-154 {
        padding: 76.5rem !important;
    }

    .p-xxl-155 {
        padding: 77rem !important;
    }

    .p-xxl-156 {
        padding: 77.5rem !important;
    }

    .p-xxl-157 {
        padding: 78rem !important;
    }

    .p-xxl-158 {
        padding: 78.5rem !important;
    }

    .p-xxl-159 {
        padding: 79rem !important;
    }

    .p-xxl-160 {
        padding: 79.5rem !important;
    }

    .pt-xxl-0 {
        padding-top: 0rem !important;
    }

    .pt-xxl-1 {
        padding-top: 0.25rem !important;
    }

    .pt-xxl-2 {
        padding-top: 0.5rem !important;
    }

    .pt-xxl-3 {
        padding-top: 1rem !important;
    }

    .pt-xxl-4 {
        padding-top: 1.5rem !important;
    }

    .pt-xxl-5 {
        padding-top: 2rem !important;
    }

    .pt-xxl-6 {
        padding-top: 2.5rem !important;
    }

    .pt-xxl-7 {
        padding-top: 3rem !important;
    }

    .pt-xxl-8 {
        padding-top: 3.5rem !important;
    }

    .pt-xxl-9 {
        padding-top: 4rem !important;
    }

    .pt-xxl-10 {
        padding-top: 4.5rem !important;
    }

    .pt-xxl-11 {
        padding-top: 5rem !important;
    }

    .pt-xxl-12 {
        padding-top: 5.5rem !important;
    }

    .pt-xxl-13 {
        padding-top: 6rem !important;
    }

    .pt-xxl-14 {
        padding-top: 6.5rem !important;
    }

    .pt-xxl-15 {
        padding-top: 7rem !important;
    }

    .pt-xxl-16 {
        padding-top: 7.5rem !important;
    }

    .pt-xxl-17 {
        padding-top: 8rem !important;
    }

    .pt-xxl-18 {
        padding-top: 8.5rem !important;
    }

    .pt-xxl-19 {
        padding-top: 9rem !important;
    }

    .pt-xxl-20 {
        padding-top: 9.5rem !important;
    }

    .pt-xxl-21 {
        padding-top: 10rem !important;
    }

    .pt-xxl-22 {
        padding-top: 10.5rem !important;
    }

    .pt-xxl-23 {
        padding-top: 11rem !important;
    }

    .pt-xxl-24 {
        padding-top: 11.5rem !important;
    }

    .pt-xxl-25 {
        padding-top: 12rem !important;
    }

    .pt-xxl-26 {
        padding-top: 12.5rem !important;
    }

    .pt-xxl-27 {
        padding-top: 13rem !important;
    }

    .pt-xxl-28 {
        padding-top: 13.5rem !important;
    }

    .pt-xxl-29 {
        padding-top: 14rem !important;
    }

    .pt-xxl-30 {
        padding-top: 14.5rem !important;
    }

    .pt-xxl-31 {
        padding-top: 15rem !important;
    }

    .pt-xxl-32 {
        padding-top: 15.5rem !important;
    }

    .pt-xxl-33 {
        padding-top: 16rem !important;
    }

    .pt-xxl-34 {
        padding-top: 16.5rem !important;
    }

    .pt-xxl-35 {
        padding-top: 17rem !important;
    }

    .pt-xxl-36 {
        padding-top: 17.5rem !important;
    }

    .pt-xxl-37 {
        padding-top: 18rem !important;
    }

    .pt-xxl-38 {
        padding-top: 18.5rem !important;
    }

    .pt-xxl-39 {
        padding-top: 19rem !important;
    }

    .pt-xxl-40 {
        padding-top: 19.5rem !important;
    }

    .pt-xxl-41 {
        padding-top: 20rem !important;
    }

    .pt-xxl-42 {
        padding-top: 20.5rem !important;
    }

    .pt-xxl-43 {
        padding-top: 21rem !important;
    }

    .pt-xxl-44 {
        padding-top: 21.5rem !important;
    }

    .pt-xxl-45 {
        padding-top: 22rem !important;
    }

    .pt-xxl-46 {
        padding-top: 22.5rem !important;
    }

    .pt-xxl-47 {
        padding-top: 23rem !important;
    }

    .pt-xxl-48 {
        padding-top: 23.5rem !important;
    }

    .pt-xxl-49 {
        padding-top: 24rem !important;
    }

    .pt-xxl-50 {
        padding-top: 24.5rem !important;
    }

    .pt-xxl-51 {
        padding-top: 25rem !important;
    }

    .pt-xxl-52 {
        padding-top: 25.5rem !important;
    }

    .pt-xxl-53 {
        padding-top: 26rem !important;
    }

    .pt-xxl-54 {
        padding-top: 26.5rem !important;
    }

    .pt-xxl-55 {
        padding-top: 27rem !important;
    }

    .pt-xxl-56 {
        padding-top: 27.5rem !important;
    }

    .pt-xxl-57 {
        padding-top: 28rem !important;
    }

    .pt-xxl-58 {
        padding-top: 28.5rem !important;
    }

    .pt-xxl-59 {
        padding-top: 29rem !important;
    }

    .pt-xxl-60 {
        padding-top: 29.5rem !important;
    }

    .pt-xxl-61 {
        padding-top: 30rem !important;
    }

    .pt-xxl-62 {
        padding-top: 30.5rem !important;
    }

    .pt-xxl-63 {
        padding-top: 31rem !important;
    }

    .pt-xxl-64 {
        padding-top: 31.5rem !important;
    }

    .pt-xxl-65 {
        padding-top: 32rem !important;
    }

    .pt-xxl-66 {
        padding-top: 32.5rem !important;
    }

    .pt-xxl-67 {
        padding-top: 33rem !important;
    }

    .pt-xxl-68 {
        padding-top: 33.5rem !important;
    }

    .pt-xxl-69 {
        padding-top: 34rem !important;
    }

    .pt-xxl-70 {
        padding-top: 34.5rem !important;
    }

    .pt-xxl-71 {
        padding-top: 35rem !important;
    }

    .pt-xxl-72 {
        padding-top: 35.5rem !important;
    }

    .pt-xxl-73 {
        padding-top: 36rem !important;
    }

    .pt-xxl-74 {
        padding-top: 36.5rem !important;
    }

    .pt-xxl-75 {
        padding-top: 37rem !important;
    }

    .pt-xxl-76 {
        padding-top: 37.5rem !important;
    }

    .pt-xxl-77 {
        padding-top: 38rem !important;
    }

    .pt-xxl-78 {
        padding-top: 38.5rem !important;
    }

    .pt-xxl-79 {
        padding-top: 39rem !important;
    }

    .pt-xxl-80 {
        padding-top: 39.5rem !important;
    }

    .pt-xxl-81 {
        padding-top: 40rem !important;
    }

    .pt-xxl-82 {
        padding-top: 40.5rem !important;
    }

    .pt-xxl-83 {
        padding-top: 41rem !important;
    }

    .pt-xxl-84 {
        padding-top: 41.5rem !important;
    }

    .pt-xxl-85 {
        padding-top: 42rem !important;
    }

    .pt-xxl-86 {
        padding-top: 42.5rem !important;
    }

    .pt-xxl-87 {
        padding-top: 43rem !important;
    }

    .pt-xxl-88 {
        padding-top: 43.5rem !important;
    }

    .pt-xxl-89 {
        padding-top: 44rem !important;
    }

    .pt-xxl-90 {
        padding-top: 44.5rem !important;
    }

    .pt-xxl-91 {
        padding-top: 45rem !important;
    }

    .pt-xxl-92 {
        padding-top: 45.5rem !important;
    }

    .pt-xxl-93 {
        padding-top: 46rem !important;
    }

    .pt-xxl-94 {
        padding-top: 46.5rem !important;
    }

    .pt-xxl-95 {
        padding-top: 47rem !important;
    }

    .pt-xxl-96 {
        padding-top: 47.5rem !important;
    }

    .pt-xxl-97 {
        padding-top: 48rem !important;
    }

    .pt-xxl-98 {
        padding-top: 48.5rem !important;
    }

    .pt-xxl-99 {
        padding-top: 49rem !important;
    }

    .pt-xxl-100 {
        padding-top: 49.5rem !important;
    }

    .pt-xxl-101 {
        padding-top: 50rem !important;
    }

    .pt-xxl-102 {
        padding-top: 50.5rem !important;
    }

    .pt-xxl-103 {
        padding-top: 51rem !important;
    }

    .pt-xxl-104 {
        padding-top: 51.5rem !important;
    }

    .pt-xxl-105 {
        padding-top: 52rem !important;
    }

    .pt-xxl-106 {
        padding-top: 52.5rem !important;
    }

    .pt-xxl-107 {
        padding-top: 53rem !important;
    }

    .pt-xxl-108 {
        padding-top: 53.5rem !important;
    }

    .pt-xxl-109 {
        padding-top: 54rem !important;
    }

    .pt-xxl-110 {
        padding-top: 54.5rem !important;
    }

    .pt-xxl-111 {
        padding-top: 55rem !important;
    }

    .pt-xxl-112 {
        padding-top: 55.5rem !important;
    }

    .pt-xxl-113 {
        padding-top: 56rem !important;
    }

    .pt-xxl-114 {
        padding-top: 56.5rem !important;
    }

    .pt-xxl-115 {
        padding-top: 57rem !important;
    }

    .pt-xxl-116 {
        padding-top: 57.5rem !important;
    }

    .pt-xxl-117 {
        padding-top: 58rem !important;
    }

    .pt-xxl-118 {
        padding-top: 58.5rem !important;
    }

    .pt-xxl-119 {
        padding-top: 59rem !important;
    }

    .pt-xxl-120 {
        padding-top: 59.5rem !important;
    }

    .pt-xxl-121 {
        padding-top: 60rem !important;
    }

    .pt-xxl-122 {
        padding-top: 60.5rem !important;
    }

    .pt-xxl-123 {
        padding-top: 61rem !important;
    }

    .pt-xxl-124 {
        padding-top: 61.5rem !important;
    }

    .pt-xxl-125 {
        padding-top: 62rem !important;
    }

    .pt-xxl-126 {
        padding-top: 62.5rem !important;
    }

    .pt-xxl-127 {
        padding-top: 63rem !important;
    }

    .pt-xxl-128 {
        padding-top: 63.5rem !important;
    }

    .pt-xxl-129 {
        padding-top: 64rem !important;
    }

    .pt-xxl-130 {
        padding-top: 64.5rem !important;
    }

    .pt-xxl-131 {
        padding-top: 65rem !important;
    }

    .pt-xxl-132 {
        padding-top: 65.5rem !important;
    }

    .pt-xxl-133 {
        padding-top: 66rem !important;
    }

    .pt-xxl-134 {
        padding-top: 66.5rem !important;
    }

    .pt-xxl-135 {
        padding-top: 67rem !important;
    }

    .pt-xxl-136 {
        padding-top: 67.5rem !important;
    }

    .pt-xxl-137 {
        padding-top: 68rem !important;
    }

    .pt-xxl-138 {
        padding-top: 68.5rem !important;
    }

    .pt-xxl-139 {
        padding-top: 69rem !important;
    }

    .pt-xxl-140 {
        padding-top: 69.5rem !important;
    }

    .pt-xxl-141 {
        padding-top: 70rem !important;
    }

    .pt-xxl-142 {
        padding-top: 70.5rem !important;
    }

    .pt-xxl-143 {
        padding-top: 71rem !important;
    }

    .pt-xxl-144 {
        padding-top: 71.5rem !important;
    }

    .pt-xxl-145 {
        padding-top: 72rem !important;
    }

    .pt-xxl-146 {
        padding-top: 72.5rem !important;
    }

    .pt-xxl-147 {
        padding-top: 73rem !important;
    }

    .pt-xxl-148 {
        padding-top: 73.5rem !important;
    }

    .pt-xxl-149 {
        padding-top: 74rem !important;
    }

    .pt-xxl-150 {
        padding-top: 74.5rem !important;
    }

    .pt-xxl-151 {
        padding-top: 75rem !important;
    }

    .pt-xxl-152 {
        padding-top: 75.5rem !important;
    }

    .pt-xxl-153 {
        padding-top: 76rem !important;
    }

    .pt-xxl-154 {
        padding-top: 76.5rem !important;
    }

    .pt-xxl-155 {
        padding-top: 77rem !important;
    }

    .pt-xxl-156 {
        padding-top: 77.5rem !important;
    }

    .pt-xxl-157 {
        padding-top: 78rem !important;
    }

    .pt-xxl-158 {
        padding-top: 78.5rem !important;
    }

    .pt-xxl-159 {
        padding-top: 79rem !important;
    }

    .pt-xxl-160 {
        padding-top: 79.5rem !important;
    }

    .pb-xxl-0 {
        padding-bottom: 0rem !important;
    }

    .pb-xxl-1 {
        padding-bottom: 0.25rem !important;
    }

    .pb-xxl-2 {
        padding-bottom: 0.5rem !important;
    }

    .pb-xxl-3 {
        padding-bottom: 1rem !important;
    }

    .pb-xxl-4 {
        padding-bottom: 1.5rem !important;
    }

    .pb-xxl-5 {
        padding-bottom: 2rem !important;
    }

    .pb-xxl-6 {
        padding-bottom: 2.5rem !important;
    }

    .pb-xxl-7 {
        padding-bottom: 3rem !important;
    }

    .pb-xxl-8 {
        padding-bottom: 3.5rem !important;
    }

    .pb-xxl-9 {
        padding-bottom: 4rem !important;
    }

    .pb-xxl-10 {
        padding-bottom: 4.5rem !important;
    }

    .pb-xxl-11 {
        padding-bottom: 5rem !important;
    }

    .pb-xxl-12 {
        padding-bottom: 5.5rem !important;
    }

    .pb-xxl-13 {
        padding-bottom: 6rem !important;
    }

    .pb-xxl-14 {
        padding-bottom: 6.5rem !important;
    }

    .pb-xxl-15 {
        padding-bottom: 7rem !important;
    }

    .pb-xxl-16 {
        padding-bottom: 7.5rem !important;
    }

    .pb-xxl-17 {
        padding-bottom: 8rem !important;
    }

    .pb-xxl-18 {
        padding-bottom: 8.5rem !important;
    }

    .pb-xxl-19 {
        padding-bottom: 9rem !important;
    }

    .pb-xxl-20 {
        padding-bottom: 9.5rem !important;
    }

    .pb-xxl-21 {
        padding-bottom: 10rem !important;
    }

    .pb-xxl-22 {
        padding-bottom: 10.5rem !important;
    }

    .pb-xxl-23 {
        padding-bottom: 11rem !important;
    }

    .pb-xxl-24 {
        padding-bottom: 11.5rem !important;
    }

    .pb-xxl-25 {
        padding-bottom: 12rem !important;
    }

    .pb-xxl-26 {
        padding-bottom: 12.5rem !important;
    }

    .pb-xxl-27 {
        padding-bottom: 13rem !important;
    }

    .pb-xxl-28 {
        padding-bottom: 13.5rem !important;
    }

    .pb-xxl-29 {
        padding-bottom: 14rem !important;
    }

    .pb-xxl-30 {
        padding-bottom: 14.5rem !important;
    }

    .pb-xxl-31 {
        padding-bottom: 15rem !important;
    }

    .pb-xxl-32 {
        padding-bottom: 15.5rem !important;
    }

    .pb-xxl-33 {
        padding-bottom: 16rem !important;
    }

    .pb-xxl-34 {
        padding-bottom: 16.5rem !important;
    }

    .pb-xxl-35 {
        padding-bottom: 17rem !important;
    }

    .pb-xxl-36 {
        padding-bottom: 17.5rem !important;
    }

    .pb-xxl-37 {
        padding-bottom: 18rem !important;
    }

    .pb-xxl-38 {
        padding-bottom: 18.5rem !important;
    }

    .pb-xxl-39 {
        padding-bottom: 19rem !important;
    }

    .pb-xxl-40 {
        padding-bottom: 19.5rem !important;
    }

    .pb-xxl-41 {
        padding-bottom: 20rem !important;
    }

    .pb-xxl-42 {
        padding-bottom: 20.5rem !important;
    }

    .pb-xxl-43 {
        padding-bottom: 21rem !important;
    }

    .pb-xxl-44 {
        padding-bottom: 21.5rem !important;
    }

    .pb-xxl-45 {
        padding-bottom: 22rem !important;
    }

    .pb-xxl-46 {
        padding-bottom: 22.5rem !important;
    }

    .pb-xxl-47 {
        padding-bottom: 23rem !important;
    }

    .pb-xxl-48 {
        padding-bottom: 23.5rem !important;
    }

    .pb-xxl-49 {
        padding-bottom: 24rem !important;
    }

    .pb-xxl-50 {
        padding-bottom: 24.5rem !important;
    }

    .pb-xxl-51 {
        padding-bottom: 25rem !important;
    }

    .pb-xxl-52 {
        padding-bottom: 25.5rem !important;
    }

    .pb-xxl-53 {
        padding-bottom: 26rem !important;
    }

    .pb-xxl-54 {
        padding-bottom: 26.5rem !important;
    }

    .pb-xxl-55 {
        padding-bottom: 27rem !important;
    }

    .pb-xxl-56 {
        padding-bottom: 27.5rem !important;
    }

    .pb-xxl-57 {
        padding-bottom: 28rem !important;
    }

    .pb-xxl-58 {
        padding-bottom: 28.5rem !important;
    }

    .pb-xxl-59 {
        padding-bottom: 29rem !important;
    }

    .pb-xxl-60 {
        padding-bottom: 29.5rem !important;
    }

    .pb-xxl-61 {
        padding-bottom: 30rem !important;
    }

    .pb-xxl-62 {
        padding-bottom: 30.5rem !important;
    }

    .pb-xxl-63 {
        padding-bottom: 31rem !important;
    }

    .pb-xxl-64 {
        padding-bottom: 31.5rem !important;
    }

    .pb-xxl-65 {
        padding-bottom: 32rem !important;
    }

    .pb-xxl-66 {
        padding-bottom: 32.5rem !important;
    }

    .pb-xxl-67 {
        padding-bottom: 33rem !important;
    }

    .pb-xxl-68 {
        padding-bottom: 33.5rem !important;
    }

    .pb-xxl-69 {
        padding-bottom: 34rem !important;
    }

    .pb-xxl-70 {
        padding-bottom: 34.5rem !important;
    }

    .pb-xxl-71 {
        padding-bottom: 35rem !important;
    }

    .pb-xxl-72 {
        padding-bottom: 35.5rem !important;
    }

    .pb-xxl-73 {
        padding-bottom: 36rem !important;
    }

    .pb-xxl-74 {
        padding-bottom: 36.5rem !important;
    }

    .pb-xxl-75 {
        padding-bottom: 37rem !important;
    }

    .pb-xxl-76 {
        padding-bottom: 37.5rem !important;
    }

    .pb-xxl-77 {
        padding-bottom: 38rem !important;
    }

    .pb-xxl-78 {
        padding-bottom: 38.5rem !important;
    }

    .pb-xxl-79 {
        padding-bottom: 39rem !important;
    }

    .pb-xxl-80 {
        padding-bottom: 39.5rem !important;
    }

    .pb-xxl-81 {
        padding-bottom: 40rem !important;
    }

    .pb-xxl-82 {
        padding-bottom: 40.5rem !important;
    }

    .pb-xxl-83 {
        padding-bottom: 41rem !important;
    }

    .pb-xxl-84 {
        padding-bottom: 41.5rem !important;
    }

    .pb-xxl-85 {
        padding-bottom: 42rem !important;
    }

    .pb-xxl-86 {
        padding-bottom: 42.5rem !important;
    }

    .pb-xxl-87 {
        padding-bottom: 43rem !important;
    }

    .pb-xxl-88 {
        padding-bottom: 43.5rem !important;
    }

    .pb-xxl-89 {
        padding-bottom: 44rem !important;
    }

    .pb-xxl-90 {
        padding-bottom: 44.5rem !important;
    }

    .pb-xxl-91 {
        padding-bottom: 45rem !important;
    }

    .pb-xxl-92 {
        padding-bottom: 45.5rem !important;
    }

    .pb-xxl-93 {
        padding-bottom: 46rem !important;
    }

    .pb-xxl-94 {
        padding-bottom: 46.5rem !important;
    }

    .pb-xxl-95 {
        padding-bottom: 47rem !important;
    }

    .pb-xxl-96 {
        padding-bottom: 47.5rem !important;
    }

    .pb-xxl-97 {
        padding-bottom: 48rem !important;
    }

    .pb-xxl-98 {
        padding-bottom: 48.5rem !important;
    }

    .pb-xxl-99 {
        padding-bottom: 49rem !important;
    }

    .pb-xxl-100 {
        padding-bottom: 49.5rem !important;
    }

    .pb-xxl-101 {
        padding-bottom: 50rem !important;
    }

    .pb-xxl-102 {
        padding-bottom: 50.5rem !important;
    }

    .pb-xxl-103 {
        padding-bottom: 51rem !important;
    }

    .pb-xxl-104 {
        padding-bottom: 51.5rem !important;
    }

    .pb-xxl-105 {
        padding-bottom: 52rem !important;
    }

    .pb-xxl-106 {
        padding-bottom: 52.5rem !important;
    }

    .pb-xxl-107 {
        padding-bottom: 53rem !important;
    }

    .pb-xxl-108 {
        padding-bottom: 53.5rem !important;
    }

    .pb-xxl-109 {
        padding-bottom: 54rem !important;
    }

    .pb-xxl-110 {
        padding-bottom: 54.5rem !important;
    }

    .pb-xxl-111 {
        padding-bottom: 55rem !important;
    }

    .pb-xxl-112 {
        padding-bottom: 55.5rem !important;
    }

    .pb-xxl-113 {
        padding-bottom: 56rem !important;
    }

    .pb-xxl-114 {
        padding-bottom: 56.5rem !important;
    }

    .pb-xxl-115 {
        padding-bottom: 57rem !important;
    }

    .pb-xxl-116 {
        padding-bottom: 57.5rem !important;
    }

    .pb-xxl-117 {
        padding-bottom: 58rem !important;
    }

    .pb-xxl-118 {
        padding-bottom: 58.5rem !important;
    }

    .pb-xxl-119 {
        padding-bottom: 59rem !important;
    }

    .pb-xxl-120 {
        padding-bottom: 59.5rem !important;
    }

    .pb-xxl-121 {
        padding-bottom: 60rem !important;
    }

    .pb-xxl-122 {
        padding-bottom: 60.5rem !important;
    }

    .pb-xxl-123 {
        padding-bottom: 61rem !important;
    }

    .pb-xxl-124 {
        padding-bottom: 61.5rem !important;
    }

    .pb-xxl-125 {
        padding-bottom: 62rem !important;
    }

    .pb-xxl-126 {
        padding-bottom: 62.5rem !important;
    }

    .pb-xxl-127 {
        padding-bottom: 63rem !important;
    }

    .pb-xxl-128 {
        padding-bottom: 63.5rem !important;
    }

    .pb-xxl-129 {
        padding-bottom: 64rem !important;
    }

    .pb-xxl-130 {
        padding-bottom: 64.5rem !important;
    }

    .pb-xxl-131 {
        padding-bottom: 65rem !important;
    }

    .pb-xxl-132 {
        padding-bottom: 65.5rem !important;
    }

    .pb-xxl-133 {
        padding-bottom: 66rem !important;
    }

    .pb-xxl-134 {
        padding-bottom: 66.5rem !important;
    }

    .pb-xxl-135 {
        padding-bottom: 67rem !important;
    }

    .pb-xxl-136 {
        padding-bottom: 67.5rem !important;
    }

    .pb-xxl-137 {
        padding-bottom: 68rem !important;
    }

    .pb-xxl-138 {
        padding-bottom: 68.5rem !important;
    }

    .pb-xxl-139 {
        padding-bottom: 69rem !important;
    }

    .pb-xxl-140 {
        padding-bottom: 69.5rem !important;
    }

    .pb-xxl-141 {
        padding-bottom: 70rem !important;
    }

    .pb-xxl-142 {
        padding-bottom: 70.5rem !important;
    }

    .pb-xxl-143 {
        padding-bottom: 71rem !important;
    }

    .pb-xxl-144 {
        padding-bottom: 71.5rem !important;
    }

    .pb-xxl-145 {
        padding-bottom: 72rem !important;
    }

    .pb-xxl-146 {
        padding-bottom: 72.5rem !important;
    }

    .pb-xxl-147 {
        padding-bottom: 73rem !important;
    }

    .pb-xxl-148 {
        padding-bottom: 73.5rem !important;
    }

    .pb-xxl-149 {
        padding-bottom: 74rem !important;
    }

    .pb-xxl-150 {
        padding-bottom: 74.5rem !important;
    }

    .pb-xxl-151 {
        padding-bottom: 75rem !important;
    }

    .pb-xxl-152 {
        padding-bottom: 75.5rem !important;
    }

    .pb-xxl-153 {
        padding-bottom: 76rem !important;
    }

    .pb-xxl-154 {
        padding-bottom: 76.5rem !important;
    }

    .pb-xxl-155 {
        padding-bottom: 77rem !important;
    }

    .pb-xxl-156 {
        padding-bottom: 77.5rem !important;
    }

    .pb-xxl-157 {
        padding-bottom: 78rem !important;
    }

    .pb-xxl-158 {
        padding-bottom: 78.5rem !important;
    }

    .pb-xxl-159 {
        padding-bottom: 79rem !important;
    }

    .pb-xxl-160 {
        padding-bottom: 79.5rem !important;
    }

    .ps-xxl-0 {
        padding-left: 0rem !important;
    }

    .ps-xxl-1 {
        padding-left: 0.25rem !important;
    }

    .ps-xxl-2 {
        padding-left: 0.5rem !important;
    }

    .ps-xxl-3 {
        padding-left: 1rem !important;
    }

    .ps-xxl-4 {
        padding-left: 1.5rem !important;
    }

    .ps-xxl-5 {
        padding-left: 2rem !important;
    }

    .ps-xxl-6 {
        padding-left: 2.5rem !important;
    }

    .ps-xxl-7 {
        padding-left: 3rem !important;
    }

    .ps-xxl-8 {
        padding-left: 3.5rem !important;
    }

    .ps-xxl-9 {
        padding-left: 4rem !important;
    }

    .ps-xxl-10 {
        padding-left: 4.5rem !important;
    }

    .ps-xxl-11 {
        padding-left: 5rem !important;
    }

    .ps-xxl-12 {
        padding-left: 5.5rem !important;
    }

    .ps-xxl-13 {
        padding-left: 6rem !important;
    }

    .ps-xxl-14 {
        padding-left: 6.5rem !important;
    }

    .ps-xxl-15 {
        padding-left: 7rem !important;
    }

    .ps-xxl-16 {
        padding-left: 7.5rem !important;
    }

    .ps-xxl-17 {
        padding-left: 8rem !important;
    }

    .ps-xxl-18 {
        padding-left: 8.5rem !important;
    }

    .ps-xxl-19 {
        padding-left: 9rem !important;
    }

    .ps-xxl-20 {
        padding-left: 9.5rem !important;
    }

    .ps-xxl-21 {
        padding-left: 10rem !important;
    }

    .ps-xxl-22 {
        padding-left: 10.5rem !important;
    }

    .ps-xxl-23 {
        padding-left: 11rem !important;
    }

    .ps-xxl-24 {
        padding-left: 11.5rem !important;
    }

    .ps-xxl-25 {
        padding-left: 12rem !important;
    }

    .ps-xxl-26 {
        padding-left: 12.5rem !important;
    }

    .ps-xxl-27 {
        padding-left: 13rem !important;
    }

    .ps-xxl-28 {
        padding-left: 13.5rem !important;
    }

    .ps-xxl-29 {
        padding-left: 14rem !important;
    }

    .ps-xxl-30 {
        padding-left: 14.5rem !important;
    }

    .ps-xxl-31 {
        padding-left: 15rem !important;
    }

    .ps-xxl-32 {
        padding-left: 15.5rem !important;
    }

    .ps-xxl-33 {
        padding-left: 16rem !important;
    }

    .ps-xxl-34 {
        padding-left: 16.5rem !important;
    }

    .ps-xxl-35 {
        padding-left: 17rem !important;
    }

    .ps-xxl-36 {
        padding-left: 17.5rem !important;
    }

    .ps-xxl-37 {
        padding-left: 18rem !important;
    }

    .ps-xxl-38 {
        padding-left: 18.5rem !important;
    }

    .ps-xxl-39 {
        padding-left: 19rem !important;
    }

    .ps-xxl-40 {
        padding-left: 19.5rem !important;
    }

    .ps-xxl-41 {
        padding-left: 20rem !important;
    }

    .ps-xxl-42 {
        padding-left: 20.5rem !important;
    }

    .ps-xxl-43 {
        padding-left: 21rem !important;
    }

    .ps-xxl-44 {
        padding-left: 21.5rem !important;
    }

    .ps-xxl-45 {
        padding-left: 22rem !important;
    }

    .ps-xxl-46 {
        padding-left: 22.5rem !important;
    }

    .ps-xxl-47 {
        padding-left: 23rem !important;
    }

    .ps-xxl-48 {
        padding-left: 23.5rem !important;
    }

    .ps-xxl-49 {
        padding-left: 24rem !important;
    }

    .ps-xxl-50 {
        padding-left: 24.5rem !important;
    }

    .ps-xxl-51 {
        padding-left: 25rem !important;
    }

    .ps-xxl-52 {
        padding-left: 25.5rem !important;
    }

    .ps-xxl-53 {
        padding-left: 26rem !important;
    }

    .ps-xxl-54 {
        padding-left: 26.5rem !important;
    }

    .ps-xxl-55 {
        padding-left: 27rem !important;
    }

    .ps-xxl-56 {
        padding-left: 27.5rem !important;
    }

    .ps-xxl-57 {
        padding-left: 28rem !important;
    }

    .ps-xxl-58 {
        padding-left: 28.5rem !important;
    }

    .ps-xxl-59 {
        padding-left: 29rem !important;
    }

    .ps-xxl-60 {
        padding-left: 29.5rem !important;
    }

    .ps-xxl-61 {
        padding-left: 30rem !important;
    }

    .ps-xxl-62 {
        padding-left: 30.5rem !important;
    }

    .ps-xxl-63 {
        padding-left: 31rem !important;
    }

    .ps-xxl-64 {
        padding-left: 31.5rem !important;
    }

    .ps-xxl-65 {
        padding-left: 32rem !important;
    }

    .ps-xxl-66 {
        padding-left: 32.5rem !important;
    }

    .ps-xxl-67 {
        padding-left: 33rem !important;
    }

    .ps-xxl-68 {
        padding-left: 33.5rem !important;
    }

    .ps-xxl-69 {
        padding-left: 34rem !important;
    }

    .ps-xxl-70 {
        padding-left: 34.5rem !important;
    }

    .ps-xxl-71 {
        padding-left: 35rem !important;
    }

    .ps-xxl-72 {
        padding-left: 35.5rem !important;
    }

    .ps-xxl-73 {
        padding-left: 36rem !important;
    }

    .ps-xxl-74 {
        padding-left: 36.5rem !important;
    }

    .ps-xxl-75 {
        padding-left: 37rem !important;
    }

    .ps-xxl-76 {
        padding-left: 37.5rem !important;
    }

    .ps-xxl-77 {
        padding-left: 38rem !important;
    }

    .ps-xxl-78 {
        padding-left: 38.5rem !important;
    }

    .ps-xxl-79 {
        padding-left: 39rem !important;
    }

    .ps-xxl-80 {
        padding-left: 39.5rem !important;
    }

    .ps-xxl-81 {
        padding-left: 40rem !important;
    }

    .ps-xxl-82 {
        padding-left: 40.5rem !important;
    }

    .ps-xxl-83 {
        padding-left: 41rem !important;
    }

    .ps-xxl-84 {
        padding-left: 41.5rem !important;
    }

    .ps-xxl-85 {
        padding-left: 42rem !important;
    }

    .ps-xxl-86 {
        padding-left: 42.5rem !important;
    }

    .ps-xxl-87 {
        padding-left: 43rem !important;
    }

    .ps-xxl-88 {
        padding-left: 43.5rem !important;
    }

    .ps-xxl-89 {
        padding-left: 44rem !important;
    }

    .ps-xxl-90 {
        padding-left: 44.5rem !important;
    }

    .ps-xxl-91 {
        padding-left: 45rem !important;
    }

    .ps-xxl-92 {
        padding-left: 45.5rem !important;
    }

    .ps-xxl-93 {
        padding-left: 46rem !important;
    }

    .ps-xxl-94 {
        padding-left: 46.5rem !important;
    }

    .ps-xxl-95 {
        padding-left: 47rem !important;
    }

    .ps-xxl-96 {
        padding-left: 47.5rem !important;
    }

    .ps-xxl-97 {
        padding-left: 48rem !important;
    }

    .ps-xxl-98 {
        padding-left: 48.5rem !important;
    }

    .ps-xxl-99 {
        padding-left: 49rem !important;
    }

    .ps-xxl-100 {
        padding-left: 49.5rem !important;
    }

    .ps-xxl-101 {
        padding-left: 50rem !important;
    }

    .ps-xxl-102 {
        padding-left: 50.5rem !important;
    }

    .ps-xxl-103 {
        padding-left: 51rem !important;
    }

    .ps-xxl-104 {
        padding-left: 51.5rem !important;
    }

    .ps-xxl-105 {
        padding-left: 52rem !important;
    }

    .ps-xxl-106 {
        padding-left: 52.5rem !important;
    }

    .ps-xxl-107 {
        padding-left: 53rem !important;
    }

    .ps-xxl-108 {
        padding-left: 53.5rem !important;
    }

    .ps-xxl-109 {
        padding-left: 54rem !important;
    }

    .ps-xxl-110 {
        padding-left: 54.5rem !important;
    }

    .ps-xxl-111 {
        padding-left: 55rem !important;
    }

    .ps-xxl-112 {
        padding-left: 55.5rem !important;
    }

    .ps-xxl-113 {
        padding-left: 56rem !important;
    }

    .ps-xxl-114 {
        padding-left: 56.5rem !important;
    }

    .ps-xxl-115 {
        padding-left: 57rem !important;
    }

    .ps-xxl-116 {
        padding-left: 57.5rem !important;
    }

    .ps-xxl-117 {
        padding-left: 58rem !important;
    }

    .ps-xxl-118 {
        padding-left: 58.5rem !important;
    }

    .ps-xxl-119 {
        padding-left: 59rem !important;
    }

    .ps-xxl-120 {
        padding-left: 59.5rem !important;
    }

    .ps-xxl-121 {
        padding-left: 60rem !important;
    }

    .ps-xxl-122 {
        padding-left: 60.5rem !important;
    }

    .ps-xxl-123 {
        padding-left: 61rem !important;
    }

    .ps-xxl-124 {
        padding-left: 61.5rem !important;
    }

    .ps-xxl-125 {
        padding-left: 62rem !important;
    }

    .ps-xxl-126 {
        padding-left: 62.5rem !important;
    }

    .ps-xxl-127 {
        padding-left: 63rem !important;
    }

    .ps-xxl-128 {
        padding-left: 63.5rem !important;
    }

    .ps-xxl-129 {
        padding-left: 64rem !important;
    }

    .ps-xxl-130 {
        padding-left: 64.5rem !important;
    }

    .ps-xxl-131 {
        padding-left: 65rem !important;
    }

    .ps-xxl-132 {
        padding-left: 65.5rem !important;
    }

    .ps-xxl-133 {
        padding-left: 66rem !important;
    }

    .ps-xxl-134 {
        padding-left: 66.5rem !important;
    }

    .ps-xxl-135 {
        padding-left: 67rem !important;
    }

    .ps-xxl-136 {
        padding-left: 67.5rem !important;
    }

    .ps-xxl-137 {
        padding-left: 68rem !important;
    }

    .ps-xxl-138 {
        padding-left: 68.5rem !important;
    }

    .ps-xxl-139 {
        padding-left: 69rem !important;
    }

    .ps-xxl-140 {
        padding-left: 69.5rem !important;
    }

    .ps-xxl-141 {
        padding-left: 70rem !important;
    }

    .ps-xxl-142 {
        padding-left: 70.5rem !important;
    }

    .ps-xxl-143 {
        padding-left: 71rem !important;
    }

    .ps-xxl-144 {
        padding-left: 71.5rem !important;
    }

    .ps-xxl-145 {
        padding-left: 72rem !important;
    }

    .ps-xxl-146 {
        padding-left: 72.5rem !important;
    }

    .ps-xxl-147 {
        padding-left: 73rem !important;
    }

    .ps-xxl-148 {
        padding-left: 73.5rem !important;
    }

    .ps-xxl-149 {
        padding-left: 74rem !important;
    }

    .ps-xxl-150 {
        padding-left: 74.5rem !important;
    }

    .ps-xxl-151 {
        padding-left: 75rem !important;
    }

    .ps-xxl-152 {
        padding-left: 75.5rem !important;
    }

    .ps-xxl-153 {
        padding-left: 76rem !important;
    }

    .ps-xxl-154 {
        padding-left: 76.5rem !important;
    }

    .ps-xxl-155 {
        padding-left: 77rem !important;
    }

    .ps-xxl-156 {
        padding-left: 77.5rem !important;
    }

    .ps-xxl-157 {
        padding-left: 78rem !important;
    }

    .ps-xxl-158 {
        padding-left: 78.5rem !important;
    }

    .ps-xxl-159 {
        padding-left: 79rem !important;
    }

    .ps-xxl-160 {
        padding-left: 79.5rem !important;
    }

    .pe-xxl-0 {
        padding-right: 0rem !important;
    }

    .pe-xxl-1 {
        padding-right: 0.25rem !important;
    }

    .pe-xxl-2 {
        padding-right: 0.5rem !important;
    }

    .pe-xxl-3 {
        padding-right: 1rem !important;
    }

    .pe-xxl-4 {
        padding-right: 1.5rem !important;
    }

    .pe-xxl-5 {
        padding-right: 2rem !important;
    }

    .pe-xxl-6 {
        padding-right: 2.5rem !important;
    }

    .pe-xxl-7 {
        padding-right: 3rem !important;
    }

    .pe-xxl-8 {
        padding-right: 3.5rem !important;
    }

    .pe-xxl-9 {
        padding-right: 4rem !important;
    }

    .pe-xxl-10 {
        padding-right: 4.5rem !important;
    }

    .pe-xxl-11 {
        padding-right: 5rem !important;
    }

    .pe-xxl-12 {
        padding-right: 5.5rem !important;
    }

    .pe-xxl-13 {
        padding-right: 6rem !important;
    }

    .pe-xxl-14 {
        padding-right: 6.5rem !important;
    }

    .pe-xxl-15 {
        padding-right: 7rem !important;
    }

    .pe-xxl-16 {
        padding-right: 7.5rem !important;
    }

    .pe-xxl-17 {
        padding-right: 8rem !important;
    }

    .pe-xxl-18 {
        padding-right: 8.5rem !important;
    }

    .pe-xxl-19 {
        padding-right: 9rem !important;
    }

    .pe-xxl-20 {
        padding-right: 9.5rem !important;
    }

    .pe-xxl-21 {
        padding-right: 10rem !important;
    }

    .pe-xxl-22 {
        padding-right: 10.5rem !important;
    }

    .pe-xxl-23 {
        padding-right: 11rem !important;
    }

    .pe-xxl-24 {
        padding-right: 11.5rem !important;
    }

    .pe-xxl-25 {
        padding-right: 12rem !important;
    }

    .pe-xxl-26 {
        padding-right: 12.5rem !important;
    }

    .pe-xxl-27 {
        padding-right: 13rem !important;
    }

    .pe-xxl-28 {
        padding-right: 13.5rem !important;
    }

    .pe-xxl-29 {
        padding-right: 14rem !important;
    }

    .pe-xxl-30 {
        padding-right: 14.5rem !important;
    }

    .pe-xxl-31 {
        padding-right: 15rem !important;
    }

    .pe-xxl-32 {
        padding-right: 15.5rem !important;
    }

    .pe-xxl-33 {
        padding-right: 16rem !important;
    }

    .pe-xxl-34 {
        padding-right: 16.5rem !important;
    }

    .pe-xxl-35 {
        padding-right: 17rem !important;
    }

    .pe-xxl-36 {
        padding-right: 17.5rem !important;
    }

    .pe-xxl-37 {
        padding-right: 18rem !important;
    }

    .pe-xxl-38 {
        padding-right: 18.5rem !important;
    }

    .pe-xxl-39 {
        padding-right: 19rem !important;
    }

    .pe-xxl-40 {
        padding-right: 19.5rem !important;
    }

    .pe-xxl-41 {
        padding-right: 20rem !important;
    }

    .pe-xxl-42 {
        padding-right: 20.5rem !important;
    }

    .pe-xxl-43 {
        padding-right: 21rem !important;
    }

    .pe-xxl-44 {
        padding-right: 21.5rem !important;
    }

    .pe-xxl-45 {
        padding-right: 22rem !important;
    }

    .pe-xxl-46 {
        padding-right: 22.5rem !important;
    }

    .pe-xxl-47 {
        padding-right: 23rem !important;
    }

    .pe-xxl-48 {
        padding-right: 23.5rem !important;
    }

    .pe-xxl-49 {
        padding-right: 24rem !important;
    }

    .pe-xxl-50 {
        padding-right: 24.5rem !important;
    }

    .pe-xxl-51 {
        padding-right: 25rem !important;
    }

    .pe-xxl-52 {
        padding-right: 25.5rem !important;
    }

    .pe-xxl-53 {
        padding-right: 26rem !important;
    }

    .pe-xxl-54 {
        padding-right: 26.5rem !important;
    }

    .pe-xxl-55 {
        padding-right: 27rem !important;
    }

    .pe-xxl-56 {
        padding-right: 27.5rem !important;
    }

    .pe-xxl-57 {
        padding-right: 28rem !important;
    }

    .pe-xxl-58 {
        padding-right: 28.5rem !important;
    }

    .pe-xxl-59 {
        padding-right: 29rem !important;
    }

    .pe-xxl-60 {
        padding-right: 29.5rem !important;
    }

    .pe-xxl-61 {
        padding-right: 30rem !important;
    }

    .pe-xxl-62 {
        padding-right: 30.5rem !important;
    }

    .pe-xxl-63 {
        padding-right: 31rem !important;
    }

    .pe-xxl-64 {
        padding-right: 31.5rem !important;
    }

    .pe-xxl-65 {
        padding-right: 32rem !important;
    }

    .pe-xxl-66 {
        padding-right: 32.5rem !important;
    }

    .pe-xxl-67 {
        padding-right: 33rem !important;
    }

    .pe-xxl-68 {
        padding-right: 33.5rem !important;
    }

    .pe-xxl-69 {
        padding-right: 34rem !important;
    }

    .pe-xxl-70 {
        padding-right: 34.5rem !important;
    }

    .pe-xxl-71 {
        padding-right: 35rem !important;
    }

    .pe-xxl-72 {
        padding-right: 35.5rem !important;
    }

    .pe-xxl-73 {
        padding-right: 36rem !important;
    }

    .pe-xxl-74 {
        padding-right: 36.5rem !important;
    }

    .pe-xxl-75 {
        padding-right: 37rem !important;
    }

    .pe-xxl-76 {
        padding-right: 37.5rem !important;
    }

    .pe-xxl-77 {
        padding-right: 38rem !important;
    }

    .pe-xxl-78 {
        padding-right: 38.5rem !important;
    }

    .pe-xxl-79 {
        padding-right: 39rem !important;
    }

    .pe-xxl-80 {
        padding-right: 39.5rem !important;
    }

    .pe-xxl-81 {
        padding-right: 40rem !important;
    }

    .pe-xxl-82 {
        padding-right: 40.5rem !important;
    }

    .pe-xxl-83 {
        padding-right: 41rem !important;
    }

    .pe-xxl-84 {
        padding-right: 41.5rem !important;
    }

    .pe-xxl-85 {
        padding-right: 42rem !important;
    }

    .pe-xxl-86 {
        padding-right: 42.5rem !important;
    }

    .pe-xxl-87 {
        padding-right: 43rem !important;
    }

    .pe-xxl-88 {
        padding-right: 43.5rem !important;
    }

    .pe-xxl-89 {
        padding-right: 44rem !important;
    }

    .pe-xxl-90 {
        padding-right: 44.5rem !important;
    }

    .pe-xxl-91 {
        padding-right: 45rem !important;
    }

    .pe-xxl-92 {
        padding-right: 45.5rem !important;
    }

    .pe-xxl-93 {
        padding-right: 46rem !important;
    }

    .pe-xxl-94 {
        padding-right: 46.5rem !important;
    }

    .pe-xxl-95 {
        padding-right: 47rem !important;
    }

    .pe-xxl-96 {
        padding-right: 47.5rem !important;
    }

    .pe-xxl-97 {
        padding-right: 48rem !important;
    }

    .pe-xxl-98 {
        padding-right: 48.5rem !important;
    }

    .pe-xxl-99 {
        padding-right: 49rem !important;
    }

    .pe-xxl-100 {
        padding-right: 49.5rem !important;
    }

    .pe-xxl-101 {
        padding-right: 50rem !important;
    }

    .pe-xxl-102 {
        padding-right: 50.5rem !important;
    }

    .pe-xxl-103 {
        padding-right: 51rem !important;
    }

    .pe-xxl-104 {
        padding-right: 51.5rem !important;
    }

    .pe-xxl-105 {
        padding-right: 52rem !important;
    }

    .pe-xxl-106 {
        padding-right: 52.5rem !important;
    }

    .pe-xxl-107 {
        padding-right: 53rem !important;
    }

    .pe-xxl-108 {
        padding-right: 53.5rem !important;
    }

    .pe-xxl-109 {
        padding-right: 54rem !important;
    }

    .pe-xxl-110 {
        padding-right: 54.5rem !important;
    }

    .pe-xxl-111 {
        padding-right: 55rem !important;
    }

    .pe-xxl-112 {
        padding-right: 55.5rem !important;
    }

    .pe-xxl-113 {
        padding-right: 56rem !important;
    }

    .pe-xxl-114 {
        padding-right: 56.5rem !important;
    }

    .pe-xxl-115 {
        padding-right: 57rem !important;
    }

    .pe-xxl-116 {
        padding-right: 57.5rem !important;
    }

    .pe-xxl-117 {
        padding-right: 58rem !important;
    }

    .pe-xxl-118 {
        padding-right: 58.5rem !important;
    }

    .pe-xxl-119 {
        padding-right: 59rem !important;
    }

    .pe-xxl-120 {
        padding-right: 59.5rem !important;
    }

    .pe-xxl-121 {
        padding-right: 60rem !important;
    }

    .pe-xxl-122 {
        padding-right: 60.5rem !important;
    }

    .pe-xxl-123 {
        padding-right: 61rem !important;
    }

    .pe-xxl-124 {
        padding-right: 61.5rem !important;
    }

    .pe-xxl-125 {
        padding-right: 62rem !important;
    }

    .pe-xxl-126 {
        padding-right: 62.5rem !important;
    }

    .pe-xxl-127 {
        padding-right: 63rem !important;
    }

    .pe-xxl-128 {
        padding-right: 63.5rem !important;
    }

    .pe-xxl-129 {
        padding-right: 64rem !important;
    }

    .pe-xxl-130 {
        padding-right: 64.5rem !important;
    }

    .pe-xxl-131 {
        padding-right: 65rem !important;
    }

    .pe-xxl-132 {
        padding-right: 65.5rem !important;
    }

    .pe-xxl-133 {
        padding-right: 66rem !important;
    }

    .pe-xxl-134 {
        padding-right: 66.5rem !important;
    }

    .pe-xxl-135 {
        padding-right: 67rem !important;
    }

    .pe-xxl-136 {
        padding-right: 67.5rem !important;
    }

    .pe-xxl-137 {
        padding-right: 68rem !important;
    }

    .pe-xxl-138 {
        padding-right: 68.5rem !important;
    }

    .pe-xxl-139 {
        padding-right: 69rem !important;
    }

    .pe-xxl-140 {
        padding-right: 69.5rem !important;
    }

    .pe-xxl-141 {
        padding-right: 70rem !important;
    }

    .pe-xxl-142 {
        padding-right: 70.5rem !important;
    }

    .pe-xxl-143 {
        padding-right: 71rem !important;
    }

    .pe-xxl-144 {
        padding-right: 71.5rem !important;
    }

    .pe-xxl-145 {
        padding-right: 72rem !important;
    }

    .pe-xxl-146 {
        padding-right: 72.5rem !important;
    }

    .pe-xxl-147 {
        padding-right: 73rem !important;
    }

    .pe-xxl-148 {
        padding-right: 73.5rem !important;
    }

    .pe-xxl-149 {
        padding-right: 74rem !important;
    }

    .pe-xxl-150 {
        padding-right: 74.5rem !important;
    }

    .pe-xxl-151 {
        padding-right: 75rem !important;
    }

    .pe-xxl-152 {
        padding-right: 75.5rem !important;
    }

    .pe-xxl-153 {
        padding-right: 76rem !important;
    }

    .pe-xxl-154 {
        padding-right: 76.5rem !important;
    }

    .pe-xxl-155 {
        padding-right: 77rem !important;
    }

    .pe-xxl-156 {
        padding-right: 77.5rem !important;
    }

    .pe-xxl-157 {
        padding-right: 78rem !important;
    }

    .pe-xxl-158 {
        padding-right: 78.5rem !important;
    }

    .pe-xxl-159 {
        padding-right: 79rem !important;
    }

    .pe-xxl-160 {
        padding-right: 79.5rem !important;
    }

    .px-xxl-0 {
        padding-left: 0rem !important;
        padding-right: 0rem !important;
    }

    .px-xxl-1 {
        padding-left: 0.25rem !important;
        padding-right: 0.25rem !important;
    }

    .px-xxl-2 {
        padding-left: 0.5rem !important;
        padding-right: 0.5rem !important;
    }

    .px-xxl-3 {
        padding-left: 1rem !important;
        padding-right: 1rem !important;
    }

    .px-xxl-4 {
        padding-left: 1.5rem !important;
        padding-right: 1.5rem !important;
    }

    .px-xxl-5 {
        padding-left: 2rem !important;
        padding-right: 2rem !important;
    }

    .px-xxl-6 {
        padding-left: 2.5rem !important;
        padding-right: 2.5rem !important;
    }

    .px-xxl-7 {
        padding-left: 3rem !important;
        padding-right: 3rem !important;
    }

    .px-xxl-8 {
        padding-left: 3.5rem !important;
        padding-right: 3.5rem !important;
    }

    .px-xxl-9 {
        padding-left: 4rem !important;
        padding-right: 4rem !important;
    }

    .px-xxl-10 {
        padding-left: 4.5rem !important;
        padding-right: 4.5rem !important;
    }

    .px-xxl-11 {
        padding-left: 5rem !important;
        padding-right: 5rem !important;
    }

    .px-xxl-12 {
        padding-left: 5.5rem !important;
        padding-right: 5.5rem !important;
    }

    .px-xxl-13 {
        padding-left: 6rem !important;
        padding-right: 6rem !important;
    }

    .px-xxl-14 {
        padding-left: 6.5rem !important;
        padding-right: 6.5rem !important;
    }

    .px-xxl-15 {
        padding-left: 7rem !important;
        padding-right: 7rem !important;
    }

    .px-xxl-16 {
        padding-left: 7.5rem !important;
        padding-right: 7.5rem !important;
    }

    .px-xxl-17 {
        padding-left: 8rem !important;
        padding-right: 8rem !important;
    }

    .px-xxl-18 {
        padding-left: 8.5rem !important;
        padding-right: 8.5rem !important;
    }

    .px-xxl-19 {
        padding-left: 9rem !important;
        padding-right: 9rem !important;
    }

    .px-xxl-20 {
        padding-left: 9.5rem !important;
        padding-right: 9.5rem !important;
    }

    .px-xxl-21 {
        padding-left: 10rem !important;
        padding-right: 10rem !important;
    }

    .px-xxl-22 {
        padding-left: 10.5rem !important;
        padding-right: 10.5rem !important;
    }

    .px-xxl-23 {
        padding-left: 11rem !important;
        padding-right: 11rem !important;
    }

    .px-xxl-24 {
        padding-left: 11.5rem !important;
        padding-right: 11.5rem !important;
    }

    .px-xxl-25 {
        padding-left: 12rem !important;
        padding-right: 12rem !important;
    }

    .px-xxl-26 {
        padding-left: 12.5rem !important;
        padding-right: 12.5rem !important;
    }

    .px-xxl-27 {
        padding-left: 13rem !important;
        padding-right: 13rem !important;
    }

    .px-xxl-28 {
        padding-left: 13.5rem !important;
        padding-right: 13.5rem !important;
    }

    .px-xxl-29 {
        padding-left: 14rem !important;
        padding-right: 14rem !important;
    }

    .px-xxl-30 {
        padding-left: 14.5rem !important;
        padding-right: 14.5rem !important;
    }

    .px-xxl-31 {
        padding-left: 15rem !important;
        padding-right: 15rem !important;
    }

    .px-xxl-32 {
        padding-left: 15.5rem !important;
        padding-right: 15.5rem !important;
    }

    .px-xxl-33 {
        padding-left: 16rem !important;
        padding-right: 16rem !important;
    }

    .px-xxl-34 {
        padding-left: 16.5rem !important;
        padding-right: 16.5rem !important;
    }

    .px-xxl-35 {
        padding-left: 17rem !important;
        padding-right: 17rem !important;
    }

    .px-xxl-36 {
        padding-left: 17.5rem !important;
        padding-right: 17.5rem !important;
    }

    .px-xxl-37 {
        padding-left: 18rem !important;
        padding-right: 18rem !important;
    }

    .px-xxl-38 {
        padding-left: 18.5rem !important;
        padding-right: 18.5rem !important;
    }

    .px-xxl-39 {
        padding-left: 19rem !important;
        padding-right: 19rem !important;
    }

    .px-xxl-40 {
        padding-left: 19.5rem !important;
        padding-right: 19.5rem !important;
    }

    .px-xxl-41 {
        padding-left: 20rem !important;
        padding-right: 20rem !important;
    }

    .px-xxl-42 {
        padding-left: 20.5rem !important;
        padding-right: 20.5rem !important;
    }

    .px-xxl-43 {
        padding-left: 21rem !important;
        padding-right: 21rem !important;
    }

    .px-xxl-44 {
        padding-left: 21.5rem !important;
        padding-right: 21.5rem !important;
    }

    .px-xxl-45 {
        padding-left: 22rem !important;
        padding-right: 22rem !important;
    }

    .px-xxl-46 {
        padding-left: 22.5rem !important;
        padding-right: 22.5rem !important;
    }

    .px-xxl-47 {
        padding-left: 23rem !important;
        padding-right: 23rem !important;
    }

    .px-xxl-48 {
        padding-left: 23.5rem !important;
        padding-right: 23.5rem !important;
    }

    .px-xxl-49 {
        padding-left: 24rem !important;
        padding-right: 24rem !important;
    }

    .px-xxl-50 {
        padding-left: 24.5rem !important;
        padding-right: 24.5rem !important;
    }

    .px-xxl-51 {
        padding-left: 25rem !important;
        padding-right: 25rem !important;
    }

    .px-xxl-52 {
        padding-left: 25.5rem !important;
        padding-right: 25.5rem !important;
    }

    .px-xxl-53 {
        padding-left: 26rem !important;
        padding-right: 26rem !important;
    }

    .px-xxl-54 {
        padding-left: 26.5rem !important;
        padding-right: 26.5rem !important;
    }

    .px-xxl-55 {
        padding-left: 27rem !important;
        padding-right: 27rem !important;
    }

    .px-xxl-56 {
        padding-left: 27.5rem !important;
        padding-right: 27.5rem !important;
    }

    .px-xxl-57 {
        padding-left: 28rem !important;
        padding-right: 28rem !important;
    }

    .px-xxl-58 {
        padding-left: 28.5rem !important;
        padding-right: 28.5rem !important;
    }

    .px-xxl-59 {
        padding-left: 29rem !important;
        padding-right: 29rem !important;
    }

    .px-xxl-60 {
        padding-left: 29.5rem !important;
        padding-right: 29.5rem !important;
    }

    .px-xxl-61 {
        padding-left: 30rem !important;
        padding-right: 30rem !important;
    }

    .px-xxl-62 {
        padding-left: 30.5rem !important;
        padding-right: 30.5rem !important;
    }

    .px-xxl-63 {
        padding-left: 31rem !important;
        padding-right: 31rem !important;
    }

    .px-xxl-64 {
        padding-left: 31.5rem !important;
        padding-right: 31.5rem !important;
    }

    .px-xxl-65 {
        padding-left: 32rem !important;
        padding-right: 32rem !important;
    }

    .px-xxl-66 {
        padding-left: 32.5rem !important;
        padding-right: 32.5rem !important;
    }

    .px-xxl-67 {
        padding-left: 33rem !important;
        padding-right: 33rem !important;
    }

    .px-xxl-68 {
        padding-left: 33.5rem !important;
        padding-right: 33.5rem !important;
    }

    .px-xxl-69 {
        padding-left: 34rem !important;
        padding-right: 34rem !important;
    }

    .px-xxl-70 {
        padding-left: 34.5rem !important;
        padding-right: 34.5rem !important;
    }

    .px-xxl-71 {
        padding-left: 35rem !important;
        padding-right: 35rem !important;
    }

    .px-xxl-72 {
        padding-left: 35.5rem !important;
        padding-right: 35.5rem !important;
    }

    .px-xxl-73 {
        padding-left: 36rem !important;
        padding-right: 36rem !important;
    }

    .px-xxl-74 {
        padding-left: 36.5rem !important;
        padding-right: 36.5rem !important;
    }

    .px-xxl-75 {
        padding-left: 37rem !important;
        padding-right: 37rem !important;
    }

    .px-xxl-76 {
        padding-left: 37.5rem !important;
        padding-right: 37.5rem !important;
    }

    .px-xxl-77 {
        padding-left: 38rem !important;
        padding-right: 38rem !important;
    }

    .px-xxl-78 {
        padding-left: 38.5rem !important;
        padding-right: 38.5rem !important;
    }

    .px-xxl-79 {
        padding-left: 39rem !important;
        padding-right: 39rem !important;
    }

    .px-xxl-80 {
        padding-left: 39.5rem !important;
        padding-right: 39.5rem !important;
    }

    .px-xxl-81 {
        padding-left: 40rem !important;
        padding-right: 40rem !important;
    }

    .px-xxl-82 {
        padding-left: 40.5rem !important;
        padding-right: 40.5rem !important;
    }

    .px-xxl-83 {
        padding-left: 41rem !important;
        padding-right: 41rem !important;
    }

    .px-xxl-84 {
        padding-left: 41.5rem !important;
        padding-right: 41.5rem !important;
    }

    .px-xxl-85 {
        padding-left: 42rem !important;
        padding-right: 42rem !important;
    }

    .px-xxl-86 {
        padding-left: 42.5rem !important;
        padding-right: 42.5rem !important;
    }

    .px-xxl-87 {
        padding-left: 43rem !important;
        padding-right: 43rem !important;
    }

    .px-xxl-88 {
        padding-left: 43.5rem !important;
        padding-right: 43.5rem !important;
    }

    .px-xxl-89 {
        padding-left: 44rem !important;
        padding-right: 44rem !important;
    }

    .px-xxl-90 {
        padding-left: 44.5rem !important;
        padding-right: 44.5rem !important;
    }

    .px-xxl-91 {
        padding-left: 45rem !important;
        padding-right: 45rem !important;
    }

    .px-xxl-92 {
        padding-left: 45.5rem !important;
        padding-right: 45.5rem !important;
    }

    .px-xxl-93 {
        padding-left: 46rem !important;
        padding-right: 46rem !important;
    }

    .px-xxl-94 {
        padding-left: 46.5rem !important;
        padding-right: 46.5rem !important;
    }

    .px-xxl-95 {
        padding-left: 47rem !important;
        padding-right: 47rem !important;
    }

    .px-xxl-96 {
        padding-left: 47.5rem !important;
        padding-right: 47.5rem !important;
    }

    .px-xxl-97 {
        padding-left: 48rem !important;
        padding-right: 48rem !important;
    }

    .px-xxl-98 {
        padding-left: 48.5rem !important;
        padding-right: 48.5rem !important;
    }

    .px-xxl-99 {
        padding-left: 49rem !important;
        padding-right: 49rem !important;
    }

    .px-xxl-100 {
        padding-left: 49.5rem !important;
        padding-right: 49.5rem !important;
    }

    .px-xxl-101 {
        padding-left: 50rem !important;
        padding-right: 50rem !important;
    }

    .px-xxl-102 {
        padding-left: 50.5rem !important;
        padding-right: 50.5rem !important;
    }

    .px-xxl-103 {
        padding-left: 51rem !important;
        padding-right: 51rem !important;
    }

    .px-xxl-104 {
        padding-left: 51.5rem !important;
        padding-right: 51.5rem !important;
    }

    .px-xxl-105 {
        padding-left: 52rem !important;
        padding-right: 52rem !important;
    }

    .px-xxl-106 {
        padding-left: 52.5rem !important;
        padding-right: 52.5rem !important;
    }

    .px-xxl-107 {
        padding-left: 53rem !important;
        padding-right: 53rem !important;
    }

    .px-xxl-108 {
        padding-left: 53.5rem !important;
        padding-right: 53.5rem !important;
    }

    .px-xxl-109 {
        padding-left: 54rem !important;
        padding-right: 54rem !important;
    }

    .px-xxl-110 {
        padding-left: 54.5rem !important;
        padding-right: 54.5rem !important;
    }

    .px-xxl-111 {
        padding-left: 55rem !important;
        padding-right: 55rem !important;
    }

    .px-xxl-112 {
        padding-left: 55.5rem !important;
        padding-right: 55.5rem !important;
    }

    .px-xxl-113 {
        padding-left: 56rem !important;
        padding-right: 56rem !important;
    }

    .px-xxl-114 {
        padding-left: 56.5rem !important;
        padding-right: 56.5rem !important;
    }

    .px-xxl-115 {
        padding-left: 57rem !important;
        padding-right: 57rem !important;
    }

    .px-xxl-116 {
        padding-left: 57.5rem !important;
        padding-right: 57.5rem !important;
    }

    .px-xxl-117 {
        padding-left: 58rem !important;
        padding-right: 58rem !important;
    }

    .px-xxl-118 {
        padding-left: 58.5rem !important;
        padding-right: 58.5rem !important;
    }

    .px-xxl-119 {
        padding-left: 59rem !important;
        padding-right: 59rem !important;
    }

    .px-xxl-120 {
        padding-left: 59.5rem !important;
        padding-right: 59.5rem !important;
    }

    .px-xxl-121 {
        padding-left: 60rem !important;
        padding-right: 60rem !important;
    }

    .px-xxl-122 {
        padding-left: 60.5rem !important;
        padding-right: 60.5rem !important;
    }

    .px-xxl-123 {
        padding-left: 61rem !important;
        padding-right: 61rem !important;
    }

    .px-xxl-124 {
        padding-left: 61.5rem !important;
        padding-right: 61.5rem !important;
    }

    .px-xxl-125 {
        padding-left: 62rem !important;
        padding-right: 62rem !important;
    }

    .px-xxl-126 {
        padding-left: 62.5rem !important;
        padding-right: 62.5rem !important;
    }

    .px-xxl-127 {
        padding-left: 63rem !important;
        padding-right: 63rem !important;
    }

    .px-xxl-128 {
        padding-left: 63.5rem !important;
        padding-right: 63.5rem !important;
    }

    .px-xxl-129 {
        padding-left: 64rem !important;
        padding-right: 64rem !important;
    }

    .px-xxl-130 {
        padding-left: 64.5rem !important;
        padding-right: 64.5rem !important;
    }

    .px-xxl-131 {
        padding-left: 65rem !important;
        padding-right: 65rem !important;
    }

    .px-xxl-132 {
        padding-left: 65.5rem !important;
        padding-right: 65.5rem !important;
    }

    .px-xxl-133 {
        padding-left: 66rem !important;
        padding-right: 66rem !important;
    }

    .px-xxl-134 {
        padding-left: 66.5rem !important;
        padding-right: 66.5rem !important;
    }

    .px-xxl-135 {
        padding-left: 67rem !important;
        padding-right: 67rem !important;
    }

    .px-xxl-136 {
        padding-left: 67.5rem !important;
        padding-right: 67.5rem !important;
    }

    .px-xxl-137 {
        padding-left: 68rem !important;
        padding-right: 68rem !important;
    }

    .px-xxl-138 {
        padding-left: 68.5rem !important;
        padding-right: 68.5rem !important;
    }

    .px-xxl-139 {
        padding-left: 69rem !important;
        padding-right: 69rem !important;
    }

    .px-xxl-140 {
        padding-left: 69.5rem !important;
        padding-right: 69.5rem !important;
    }

    .px-xxl-141 {
        padding-left: 70rem !important;
        padding-right: 70rem !important;
    }

    .px-xxl-142 {
        padding-left: 70.5rem !important;
        padding-right: 70.5rem !important;
    }

    .px-xxl-143 {
        padding-left: 71rem !important;
        padding-right: 71rem !important;
    }

    .px-xxl-144 {
        padding-left: 71.5rem !important;
        padding-right: 71.5rem !important;
    }

    .px-xxl-145 {
        padding-left: 72rem !important;
        padding-right: 72rem !important;
    }

    .px-xxl-146 {
        padding-left: 72.5rem !important;
        padding-right: 72.5rem !important;
    }

    .px-xxl-147 {
        padding-left: 73rem !important;
        padding-right: 73rem !important;
    }

    .px-xxl-148 {
        padding-left: 73.5rem !important;
        padding-right: 73.5rem !important;
    }

    .px-xxl-149 {
        padding-left: 74rem !important;
        padding-right: 74rem !important;
    }

    .px-xxl-150 {
        padding-left: 74.5rem !important;
        padding-right: 74.5rem !important;
    }

    .px-xxl-151 {
        padding-left: 75rem !important;
        padding-right: 75rem !important;
    }

    .px-xxl-152 {
        padding-left: 75.5rem !important;
        padding-right: 75.5rem !important;
    }

    .px-xxl-153 {
        padding-left: 76rem !important;
        padding-right: 76rem !important;
    }

    .px-xxl-154 {
        padding-left: 76.5rem !important;
        padding-right: 76.5rem !important;
    }

    .px-xxl-155 {
        padding-left: 77rem !important;
        padding-right: 77rem !important;
    }

    .px-xxl-156 {
        padding-left: 77.5rem !important;
        padding-right: 77.5rem !important;
    }

    .px-xxl-157 {
        padding-left: 78rem !important;
        padding-right: 78rem !important;
    }

    .px-xxl-158 {
        padding-left: 78.5rem !important;
        padding-right: 78.5rem !important;
    }

    .px-xxl-159 {
        padding-left: 79rem !important;
        padding-right: 79rem !important;
    }

    .px-xxl-160 {
        padding-left: 79.5rem !important;
        padding-right: 79.5rem !important;
    }

    .py-xxl-0 {
        padding-top: 0rem !important;
        padding-bottom: 0rem !important;
    }

    .py-xxl-1 {
        padding-top: 0.25rem !important;
        padding-bottom: 0.25rem !important;
    }

    .py-xxl-2 {
        padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;
    }

    .py-xxl-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
    }

    .py-xxl-4 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important;
    }

    .py-xxl-5 {
        padding-top: 2rem !important;
        padding-bottom: 2rem !important;
    }

    .py-xxl-6 {
        padding-top: 2.5rem !important;
        padding-bottom: 2.5rem !important;
    }

    .py-xxl-7 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important;
    }

    .py-xxl-8 {
        padding-top: 3.5rem !important;
        padding-bottom: 3.5rem !important;
    }

    .py-xxl-9 {
        padding-top: 4rem !important;
        padding-bottom: 4rem !important;
    }

    .py-xxl-10 {
        padding-top: 4.5rem !important;
        padding-bottom: 4.5rem !important;
    }

    .py-xxl-11 {
        padding-top: 5rem !important;
        padding-bottom: 5rem !important;
    }

    .py-xxl-12 {
        padding-top: 5.5rem !important;
        padding-bottom: 5.5rem !important;
    }

    .py-xxl-13 {
        padding-top: 6rem !important;
        padding-bottom: 6rem !important;
    }

    .py-xxl-14 {
        padding-top: 6.5rem !important;
        padding-bottom: 6.5rem !important;
    }

    .py-xxl-15 {
        padding-top: 7rem !important;
        padding-bottom: 7rem !important;
    }

    .py-xxl-16 {
        padding-top: 7.5rem !important;
        padding-bottom: 7.5rem !important;
    }

    .py-xxl-17 {
        padding-top: 8rem !important;
        padding-bottom: 8rem !important;
    }

    .py-xxl-18 {
        padding-top: 8.5rem !important;
        padding-bottom: 8.5rem !important;
    }

    .py-xxl-19 {
        padding-top: 9rem !important;
        padding-bottom: 9rem !important;
    }

    .py-xxl-20 {
        padding-top: 9.5rem !important;
        padding-bottom: 9.5rem !important;
    }

    .py-xxl-21 {
        padding-top: 10rem !important;
        padding-bottom: 10rem !important;
    }

    .py-xxl-22 {
        padding-top: 10.5rem !important;
        padding-bottom: 10.5rem !important;
    }

    .py-xxl-23 {
        padding-top: 11rem !important;
        padding-bottom: 11rem !important;
    }

    .py-xxl-24 {
        padding-top: 11.5rem !important;
        padding-bottom: 11.5rem !important;
    }

    .py-xxl-25 {
        padding-top: 12rem !important;
        padding-bottom: 12rem !important;
    }

    .py-xxl-26 {
        padding-top: 12.5rem !important;
        padding-bottom: 12.5rem !important;
    }

    .py-xxl-27 {
        padding-top: 13rem !important;
        padding-bottom: 13rem !important;
    }

    .py-xxl-28 {
        padding-top: 13.5rem !important;
        padding-bottom: 13.5rem !important;
    }

    .py-xxl-29 {
        padding-top: 14rem !important;
        padding-bottom: 14rem !important;
    }

    .py-xxl-30 {
        padding-top: 14.5rem !important;
        padding-bottom: 14.5rem !important;
    }

    .py-xxl-31 {
        padding-top: 15rem !important;
        padding-bottom: 15rem !important;
    }

    .py-xxl-32 {
        padding-top: 15.5rem !important;
        padding-bottom: 15.5rem !important;
    }

    .py-xxl-33 {
        padding-top: 16rem !important;
        padding-bottom: 16rem !important;
    }

    .py-xxl-34 {
        padding-top: 16.5rem !important;
        padding-bottom: 16.5rem !important;
    }

    .py-xxl-35 {
        padding-top: 17rem !important;
        padding-bottom: 17rem !important;
    }

    .py-xxl-36 {
        padding-top: 17.5rem !important;
        padding-bottom: 17.5rem !important;
    }

    .py-xxl-37 {
        padding-top: 18rem !important;
        padding-bottom: 18rem !important;
    }

    .py-xxl-38 {
        padding-top: 18.5rem !important;
        padding-bottom: 18.5rem !important;
    }

    .py-xxl-39 {
        padding-top: 19rem !important;
        padding-bottom: 19rem !important;
    }

    .py-xxl-40 {
        padding-top: 19.5rem !important;
        padding-bottom: 19.5rem !important;
    }

    .py-xxl-41 {
        padding-top: 20rem !important;
        padding-bottom: 20rem !important;
    }

    .py-xxl-42 {
        padding-top: 20.5rem !important;
        padding-bottom: 20.5rem !important;
    }

    .py-xxl-43 {
        padding-top: 21rem !important;
        padding-bottom: 21rem !important;
    }

    .py-xxl-44 {
        padding-top: 21.5rem !important;
        padding-bottom: 21.5rem !important;
    }

    .py-xxl-45 {
        padding-top: 22rem !important;
        padding-bottom: 22rem !important;
    }

    .py-xxl-46 {
        padding-top: 22.5rem !important;
        padding-bottom: 22.5rem !important;
    }

    .py-xxl-47 {
        padding-top: 23rem !important;
        padding-bottom: 23rem !important;
    }

    .py-xxl-48 {
        padding-top: 23.5rem !important;
        padding-bottom: 23.5rem !important;
    }

    .py-xxl-49 {
        padding-top: 24rem !important;
        padding-bottom: 24rem !important;
    }

    .py-xxl-50 {
        padding-top: 24.5rem !important;
        padding-bottom: 24.5rem !important;
    }

    .py-xxl-51 {
        padding-top: 25rem !important;
        padding-bottom: 25rem !important;
    }

    .py-xxl-52 {
        padding-top: 25.5rem !important;
        padding-bottom: 25.5rem !important;
    }

    .py-xxl-53 {
        padding-top: 26rem !important;
        padding-bottom: 26rem !important;
    }

    .py-xxl-54 {
        padding-top: 26.5rem !important;
        padding-bottom: 26.5rem !important;
    }

    .py-xxl-55 {
        padding-top: 27rem !important;
        padding-bottom: 27rem !important;
    }

    .py-xxl-56 {
        padding-top: 27.5rem !important;
        padding-bottom: 27.5rem !important;
    }

    .py-xxl-57 {
        padding-top: 28rem !important;
        padding-bottom: 28rem !important;
    }

    .py-xxl-58 {
        padding-top: 28.5rem !important;
        padding-bottom: 28.5rem !important;
    }

    .py-xxl-59 {
        padding-top: 29rem !important;
        padding-bottom: 29rem !important;
    }

    .py-xxl-60 {
        padding-top: 29.5rem !important;
        padding-bottom: 29.5rem !important;
    }

    .py-xxl-61 {
        padding-top: 30rem !important;
        padding-bottom: 30rem !important;
    }

    .py-xxl-62 {
        padding-top: 30.5rem !important;
        padding-bottom: 30.5rem !important;
    }

    .py-xxl-63 {
        padding-top: 31rem !important;
        padding-bottom: 31rem !important;
    }

    .py-xxl-64 {
        padding-top: 31.5rem !important;
        padding-bottom: 31.5rem !important;
    }

    .py-xxl-65 {
        padding-top: 32rem !important;
        padding-bottom: 32rem !important;
    }

    .py-xxl-66 {
        padding-top: 32.5rem !important;
        padding-bottom: 32.5rem !important;
    }

    .py-xxl-67 {
        padding-top: 33rem !important;
        padding-bottom: 33rem !important;
    }

    .py-xxl-68 {
        padding-top: 33.5rem !important;
        padding-bottom: 33.5rem !important;
    }

    .py-xxl-69 {
        padding-top: 34rem !important;
        padding-bottom: 34rem !important;
    }

    .py-xxl-70 {
        padding-top: 34.5rem !important;
        padding-bottom: 34.5rem !important;
    }

    .py-xxl-71 {
        padding-top: 35rem !important;
        padding-bottom: 35rem !important;
    }

    .py-xxl-72 {
        padding-top: 35.5rem !important;
        padding-bottom: 35.5rem !important;
    }

    .py-xxl-73 {
        padding-top: 36rem !important;
        padding-bottom: 36rem !important;
    }

    .py-xxl-74 {
        padding-top: 36.5rem !important;
        padding-bottom: 36.5rem !important;
    }

    .py-xxl-75 {
        padding-top: 37rem !important;
        padding-bottom: 37rem !important;
    }

    .py-xxl-76 {
        padding-top: 37.5rem !important;
        padding-bottom: 37.5rem !important;
    }

    .py-xxl-77 {
        padding-top: 38rem !important;
        padding-bottom: 38rem !important;
    }

    .py-xxl-78 {
        padding-top: 38.5rem !important;
        padding-bottom: 38.5rem !important;
    }

    .py-xxl-79 {
        padding-top: 39rem !important;
        padding-bottom: 39rem !important;
    }

    .py-xxl-80 {
        padding-top: 39.5rem !important;
        padding-bottom: 39.5rem !important;
    }

    .py-xxl-81 {
        padding-top: 40rem !important;
        padding-bottom: 40rem !important;
    }

    .py-xxl-82 {
        padding-top: 40.5rem !important;
        padding-bottom: 40.5rem !important;
    }

    .py-xxl-83 {
        padding-top: 41rem !important;
        padding-bottom: 41rem !important;
    }

    .py-xxl-84 {
        padding-top: 41.5rem !important;
        padding-bottom: 41.5rem !important;
    }

    .py-xxl-85 {
        padding-top: 42rem !important;
        padding-bottom: 42rem !important;
    }

    .py-xxl-86 {
        padding-top: 42.5rem !important;
        padding-bottom: 42.5rem !important;
    }

    .py-xxl-87 {
        padding-top: 43rem !important;
        padding-bottom: 43rem !important;
    }

    .py-xxl-88 {
        padding-top: 43.5rem !important;
        padding-bottom: 43.5rem !important;
    }

    .py-xxl-89 {
        padding-top: 44rem !important;
        padding-bottom: 44rem !important;
    }

    .py-xxl-90 {
        padding-top: 44.5rem !important;
        padding-bottom: 44.5rem !important;
    }

    .py-xxl-91 {
        padding-top: 45rem !important;
        padding-bottom: 45rem !important;
    }

    .py-xxl-92 {
        padding-top: 45.5rem !important;
        padding-bottom: 45.5rem !important;
    }

    .py-xxl-93 {
        padding-top: 46rem !important;
        padding-bottom: 46rem !important;
    }

    .py-xxl-94 {
        padding-top: 46.5rem !important;
        padding-bottom: 46.5rem !important;
    }

    .py-xxl-95 {
        padding-top: 47rem !important;
        padding-bottom: 47rem !important;
    }

    .py-xxl-96 {
        padding-top: 47.5rem !important;
        padding-bottom: 47.5rem !important;
    }

    .py-xxl-97 {
        padding-top: 48rem !important;
        padding-bottom: 48rem !important;
    }

    .py-xxl-98 {
        padding-top: 48.5rem !important;
        padding-bottom: 48.5rem !important;
    }

    .py-xxl-99 {
        padding-top: 49rem !important;
        padding-bottom: 49rem !important;
    }

    .py-xxl-100 {
        padding-top: 49.5rem !important;
        padding-bottom: 49.5rem !important;
    }

    .py-xxl-101 {
        padding-top: 50rem !important;
        padding-bottom: 50rem !important;
    }

    .py-xxl-102 {
        padding-top: 50.5rem !important;
        padding-bottom: 50.5rem !important;
    }

    .py-xxl-103 {
        padding-top: 51rem !important;
        padding-bottom: 51rem !important;
    }

    .py-xxl-104 {
        padding-top: 51.5rem !important;
        padding-bottom: 51.5rem !important;
    }

    .py-xxl-105 {
        padding-top: 52rem !important;
        padding-bottom: 52rem !important;
    }

    .py-xxl-106 {
        padding-top: 52.5rem !important;
        padding-bottom: 52.5rem !important;
    }

    .py-xxl-107 {
        padding-top: 53rem !important;
        padding-bottom: 53rem !important;
    }

    .py-xxl-108 {
        padding-top: 53.5rem !important;
        padding-bottom: 53.5rem !important;
    }

    .py-xxl-109 {
        padding-top: 54rem !important;
        padding-bottom: 54rem !important;
    }

    .py-xxl-110 {
        padding-top: 54.5rem !important;
        padding-bottom: 54.5rem !important;
    }

    .py-xxl-111 {
        padding-top: 55rem !important;
        padding-bottom: 55rem !important;
    }

    .py-xxl-112 {
        padding-top: 55.5rem !important;
        padding-bottom: 55.5rem !important;
    }

    .py-xxl-113 {
        padding-top: 56rem !important;
        padding-bottom: 56rem !important;
    }

    .py-xxl-114 {
        padding-top: 56.5rem !important;
        padding-bottom: 56.5rem !important;
    }

    .py-xxl-115 {
        padding-top: 57rem !important;
        padding-bottom: 57rem !important;
    }

    .py-xxl-116 {
        padding-top: 57.5rem !important;
        padding-bottom: 57.5rem !important;
    }

    .py-xxl-117 {
        padding-top: 58rem !important;
        padding-bottom: 58rem !important;
    }

    .py-xxl-118 {
        padding-top: 58.5rem !important;
        padding-bottom: 58.5rem !important;
    }

    .py-xxl-119 {
        padding-top: 59rem !important;
        padding-bottom: 59rem !important;
    }

    .py-xxl-120 {
        padding-top: 59.5rem !important;
        padding-bottom: 59.5rem !important;
    }

    .py-xxl-121 {
        padding-top: 60rem !important;
        padding-bottom: 60rem !important;
    }

    .py-xxl-122 {
        padding-top: 60.5rem !important;
        padding-bottom: 60.5rem !important;
    }

    .py-xxl-123 {
        padding-top: 61rem !important;
        padding-bottom: 61rem !important;
    }

    .py-xxl-124 {
        padding-top: 61.5rem !important;
        padding-bottom: 61.5rem !important;
    }

    .py-xxl-125 {
        padding-top: 62rem !important;
        padding-bottom: 62rem !important;
    }

    .py-xxl-126 {
        padding-top: 62.5rem !important;
        padding-bottom: 62.5rem !important;
    }

    .py-xxl-127 {
        padding-top: 63rem !important;
        padding-bottom: 63rem !important;
    }

    .py-xxl-128 {
        padding-top: 63.5rem !important;
        padding-bottom: 63.5rem !important;
    }

    .py-xxl-129 {
        padding-top: 64rem !important;
        padding-bottom: 64rem !important;
    }

    .py-xxl-130 {
        padding-top: 64.5rem !important;
        padding-bottom: 64.5rem !important;
    }

    .py-xxl-131 {
        padding-top: 65rem !important;
        padding-bottom: 65rem !important;
    }

    .py-xxl-132 {
        padding-top: 65.5rem !important;
        padding-bottom: 65.5rem !important;
    }

    .py-xxl-133 {
        padding-top: 66rem !important;
        padding-bottom: 66rem !important;
    }

    .py-xxl-134 {
        padding-top: 66.5rem !important;
        padding-bottom: 66.5rem !important;
    }

    .py-xxl-135 {
        padding-top: 67rem !important;
        padding-bottom: 67rem !important;
    }

    .py-xxl-136 {
        padding-top: 67.5rem !important;
        padding-bottom: 67.5rem !important;
    }

    .py-xxl-137 {
        padding-top: 68rem !important;
        padding-bottom: 68rem !important;
    }

    .py-xxl-138 {
        padding-top: 68.5rem !important;
        padding-bottom: 68.5rem !important;
    }

    .py-xxl-139 {
        padding-top: 69rem !important;
        padding-bottom: 69rem !important;
    }

    .py-xxl-140 {
        padding-top: 69.5rem !important;
        padding-bottom: 69.5rem !important;
    }

    .py-xxl-141 {
        padding-top: 70rem !important;
        padding-bottom: 70rem !important;
    }

    .py-xxl-142 {
        padding-top: 70.5rem !important;
        padding-bottom: 70.5rem !important;
    }

    .py-xxl-143 {
        padding-top: 71rem !important;
        padding-bottom: 71rem !important;
    }

    .py-xxl-144 {
        padding-top: 71.5rem !important;
        padding-bottom: 71.5rem !important;
    }

    .py-xxl-145 {
        padding-top: 72rem !important;
        padding-bottom: 72rem !important;
    }

    .py-xxl-146 {
        padding-top: 72.5rem !important;
        padding-bottom: 72.5rem !important;
    }

    .py-xxl-147 {
        padding-top: 73rem !important;
        padding-bottom: 73rem !important;
    }

    .py-xxl-148 {
        padding-top: 73.5rem !important;
        padding-bottom: 73.5rem !important;
    }

    .py-xxl-149 {
        padding-top: 74rem !important;
        padding-bottom: 74rem !important;
    }

    .py-xxl-150 {
        padding-top: 74.5rem !important;
        padding-bottom: 74.5rem !important;
    }

    .py-xxl-151 {
        padding-top: 75rem !important;
        padding-bottom: 75rem !important;
    }

    .py-xxl-152 {
        padding-top: 75.5rem !important;
        padding-bottom: 75.5rem !important;
    }

    .py-xxl-153 {
        padding-top: 76rem !important;
        padding-bottom: 76rem !important;
    }

    .py-xxl-154 {
        padding-top: 76.5rem !important;
        padding-bottom: 76.5rem !important;
    }

    .py-xxl-155 {
        padding-top: 77rem !important;
        padding-bottom: 77rem !important;
    }

    .py-xxl-156 {
        padding-top: 77.5rem !important;
        padding-bottom: 77.5rem !important;
    }

    .py-xxl-157 {
        padding-top: 78rem !important;
        padding-bottom: 78rem !important;
    }

    .py-xxl-158 {
        padding-top: 78.5rem !important;
        padding-bottom: 78.5rem !important;
    }

    .py-xxl-159 {
        padding-top: 79rem !important;
        padding-bottom: 79rem !important;
    }

    .py-xxl-160 {
        padding-top: 79.5rem !important;
        padding-bottom: 79.5rem !important;
    }
}