.text-limit-1 {
    display: -webkit-box;
    overflow: hidden; 
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
}

.text-limit-2 {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

.align-2 {
    vertical-align: -2px;
}

.btn_orange {
    background: #f63;
    border: none;
    border-radius: 6px;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    padding: 15px 24px;
    text-align: center;        
}
.btn_orange:hover, .btn_orange:focus, .btn_orange2:hover, .btn_orange2:focus {
    background: #340f49;
    color: #fff;
}

.btn_orange2 {
	background: #FF6633;
	color: #fff;
	font-weight: bold;
	border-radius: 6px;
	display: inline-block;
}

.task-list table span {
    font-family: inherit;
}

.task-list table p {
    margin: 0;
}

.task-list table td {
    padding: 6px !important;
}

.caseStudyList {
    height: 200px;
    overflow: hidden;
}

.nav_statics.nav .nav-item .nav-link {
    border: 1px solid #dee2e6;
    background: #f5fafc;
    font-weight: bold;
    padding: 0.75rem 1.5rem;
}

.nav_statics.nav .nav-item+.nav-item {
    margin-left: 1rem;
}

.nav_statics.nav .nav-item .nav-link.active,
.nav_statics.nav .nav-item .nav-link:hover {
    background: #3d9ca5;
    color: #fff;
    border-color: #3d9ca5;
}

.statics_height_box_small {
    overflow-y: scroll;
    height: 180px;
}

.statics_height_box {
    overflow-y: scroll;
    height: 280px;
}

.anaalist_btn {
    font-size: 14px;
    text-align: center;
    border-radius: 4px;
    background: #9f40d5;
    color: #fff;
    border: none;
    padding: 7px 15px;
}

.anaalist_btn:hover,
.anaalist_btn:focus {
    background: #7d39a4;
    color: #fff;
    border-color: #5ccbf5;
}

.banner_tag li {
    display: inline-block;
    font-weight: 600;
    font-size: 14px;
    color: #fff;
    margin-right: 2px;
    margin-left: 2px;
}

.banner_tag li a {
    background: #340f49;
    color: #fff;
    display: block;
    border-radius: 10px;
    font-size: 13px;
    font-weight: 400;
    margin: 0 3px 4px 0;
    padding: 2px 8px 1px;
    cursor: pointer;
}

.bg_servcies_light {
    background: #F5FAFC !important;
}

.brand_background {
    background: url(https://cdn.grandviewresearch.com/horizon/images/brand_gvr_cover.jpg) no-repeat;
    background-size: cover;
    text-align: center;
}

.btn-databook {
    font-size: 18px;
    background: #5bcaf4;
    color: #fff;
    display: inline-block;
    border-radius: 30px;
    border: none;
    padding: 16px 30px;
}

.btn-select-topic-banner,
.btn-select-topic-banner.show {
    background: #340f49;
    color: #fff;
    border-radius: 0;
    font-size: 20px;
    border: none;
    padding: 10px 20px;
}

.cardPivotTable {
    background: var(--surface-card);
    padding: 2rem;
    border-radius: 10px;
    margin-bottom: 1rem;
}

.btn-select-topic-banner:hover,
.btn-select-topic-banner:focus,
.btn-select-topic-banner:focus-visible,
.btn-select-topic-banner:active {
    background: #340f49 !important;
    color: #fff !important;
    border-radius: 0;
}

.btn_banner_servies {
    display: inline-block;
    background: #340f49;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    border-radius: 6px;
    margin: 0 auto;
    padding: 10px 24px;
}

.btn_card_header {
    background: #e7effd;
    color: #2773ff;
    border-radius: 6px;
    padding: 5px 10px;
}

.btn_card_header:hover {
    background: #d6e2f7;
    color: #340f49;
}

.btn_form_submit {
    background: #351149;
    color: #fff;
    border-radius: 6px;
    font-size: 18px;
    border: 2px solid #340f49;
    margin: 0 auto;
    padding: 8px 32px;
}

.btn_form_submit:hover,
.btn_form_submit:focus {
    background: transparent;
    color: #340f49;
    border: 2px solid #340f49;
}

.btn_login_white {
    background: #340f49;
    color: #fff !important;
    border: 1px solid #340f49;
}

.btn_login_white:hover,
.banner_tag li a:hover,
.banner_tag li a:focus,
.btn_overview_purple:hover {
    background: #5ccbf5;
    border-color: #5ccbf5;
}

.btn_overview {
    background: #340f49;
    color: #fff;
    font-weight: 600;
    border-radius: 6px;
    border: 0 none;
    display: block;
    width: 100%;
    font-size: 18px;
    padding: 8px 16px;
}

.btn_overview_blue {
    background: #5ccbf5;
    color: #fff;
    display: inline-block;
    font-weight: 600;
    padding: 8px 32px;
}

.btn_overview_purple {
    background: #340f49;
    color: #fff;
    display: inline-block;
    font-weight: 600;
    padding: 8px 32px;
}

.btn_search_banner {
    background: transparent;
    border: 0 none;
    color: #340f49;
    margin-left: -20px !important;
    z-index: 9;
}

.btn_submit_bottom {
    font-size: 18px;
    background: #5bcaf4;
    color: #fff;
    display: inline-block;
    border-radius: 30px;
    border: 2px solid transparent;
    padding: 14px 30px;
}

.btn_submit_bottom:hover,
.btn_submit_bottom:focus {
    background: transparent;
    color: #fff;
    border: 2px solid #fff;
}

.buy_now_corporate {
    color: #fff;
    background: #5ccbf5;
    font-weight: bold;
    font-size: 15px;
    border-radius: 6px;
    padding: 10px 28px;
    text-align: center;
    min-width: 180px;
    display: inline-block;
    border: 2px solid #5ccbf5;
}

.buy_now_corporate:hover,
.buy_now_corporate:focus {
    color: #fff;
    background: #340f49;
    border-color: #340f49;
}

.carusel_my_access {
    padding-bottom: 80px;
}

.carusel_my_access .active {
    opacity: 1 !important;
}

.carusel_my_access .carousel-indicators [data-bs-target] {
    background-color: #ccc;
    width: 50px;
    height: 4px;
    opacity: 0.3;
}

.carusel_my_access a {
    display: inline-block;
    border: 2px solid #340f49;
    color: #340f49;
    padding: .5rem 2rem;
}

.carusel_my_access a:hover,
.btn_overview_blue:hover,
.btn-databook:hover,
.btn-databook:focus {
    background: #340f49;
    color: #fff;
}

.caseStudyImg {
    width: 340px !important;
    border-radius: 6px 6px 0 0;
}

.case_study_sec ul li.iconCase {
    position: relative;
    color: #340f49;
    font-weight: 600;
    font-size: 14px;
    padding: 0 0 0 25px;
}

.case_study_sec ul li.iconCase:after {
    position: absolute;
    background: url(https://cdn.grandviewresearch.com/horizon/images/check_case.svg) no-repeat;
    width: 25px;
    height: 25px;
    border-radius: 6px;
    content: "";
    left: 0;
    top: 0;
    background-size: 25px;
}

.client_logo_border {
    border: 2px dashed #340f49;
    height: 66px;
    margin-bottom: 20px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
}

.client_logo_border img {
    max-height: 30px;
    width: auto;
}

.comapnies_highlight {
    color: #5bcaf4;
    font-size: 18px;
    text-transform: uppercase;
}

.comapnies_sub_heading,
.hz_track_list {
    font-size: 22px;
    line-height: 1.4;
    color: #423547;
}

.cor_pl_left_space {
    margin-left: 47px;
}

.cor_pl_left_space ul {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.cor_pl_left_space ul li {
    flex: 0 0 auto;
    width: 50%;
}

.cor_pl_left_space ul li:nth-last-child(-n + 2) {
    width: 100% !important;
    flex: 0 0 auto;
}

.corporate_plan_title {
    width: 32px;
    height: 32px;
    text-align: center;
    margin-right: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.count_services_style {
    background: #340f49;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    color: #fff;
    font-weight: 600;
    margin: 0 auto 1.5rem;
    padding: 10px 0;
}

.count_services_style:after {
    position: absolute;
    content: "";
    border: 2px solid #340f49;
    width: 100%;
    top: 19px;
    left: calc(50%+7px);
}

.custom-plan-form {
    background: #eee;
    border-radius: 6px;
    margin-top: 20px;
    padding: 16px 16px 24px;
}

.data_book_col {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 170px;
    border-radius: 0 15px 15px 0;
    box-shadow: 5px 5px 17px 4px rgba(0, 0, 0, 0.05);
    border-left: 5px solid #19bcf0;
    background: #fff;
    padding: 16px;
}

.data_book_content {
    color: #340f49;
    font-size: 20px;
}

.data_book_title {
    color: #756280;
    font-size: 14px;
    text-transform: uppercase;
}

.downlload_btn {
    font-size: 14px;
    text-align: center;
    border-radius: 4px;
    background: #5ccbf5;
    color: #fff;
    border: none;
    padding: 7px 15px;
}

.downlload_btn:hover,
.downlload_btn:focus {
    background: #6cadc6;
    color: #fff;
}

.editer-pick-btn {
    background: #5BCAF4;
    font-weight: 600;
    font-size: 12px;
    border-radius: .25rem;
    color: #fff;
    padding: .25rem .75rem;
}

.fact_box h3 {
    color: #340f49;
    margin: 2rem 0 1rem;
}

.fact_box h5 {
    color: #454545;
    font-weight: 600;
    line-height: 1.7rem;
    margin: 0 0 2rem;
}

.fact_box_heading {
    background: #fff;
    color: #340f49;
    font-size: 20px;
    font-weight: 600;
    border-radius: 6px;
}

.feature_sec h1 {
    position: relative;
    z-index: 9;
}

.feature_sec h6 {
    position: relative;
    line-height: 1.4;
}

.feature_sec h6:after {
    position: absolute;
    left: -14px;
    top: -67px;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: #5ccbf5;
    content: "";
}

.follow_link a {
    background: #fff;
    border-radius: 20px;
    width: 36px;
    height: 36px;
    display: inline-flex;
    justify-content: center;
    color: #666;
    margin-right: 5px;
    align-items: center;
}

.footer-top {
    background-color: #ECF0F2;
    text-align: center;
    border-top: 1px solid #5bcaf4;
    padding: 40px 0;
}

.footer-top p {
    font-size: 17px;
    line-height: 24px;
    text-align: center;
    margin: 0;
}

.footer-top-img {
    height: 78px;
    margin-bottom: 20px;
}

.footer_2 {
    position: relative;
}

.footer_2:after {
    background: linear-gradient(180deg, #340F49CF 0%, rgb(223, 236, 241) 100%);
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.3;
}

.footer_2:before {
    background: url(https://cdn.grandviewresearch.com/horizon/images/mask-footer.png) no-repeat;
    background-size: contain;
    background-position: top center;
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.3;
}

.footer_2_bottom {
    font-size: 14px;
    color: #0f2744;
}

.footer_2_bottom a {
    font-size: 14px;
    color: #0f2744;
    position: relative;
    padding-left: 20px;
    margin-left: 12px;
    display: inline-block;
}

.footer_2_bottom a:after {
    content: "";
    position: absolute;
    left: 0;
    width: 8px;
    height: 8px;
    border-radius: 10px;
    background: #5ccbf5;
    top: 6px;
}

.footer_2_link li a,
.footer_2_link li {
    color: #0f2744;
    font-size: 14px;
}

.footer_bootm_right {
    color: #333;
    font-size: 15px;
    font-weight: 600;
    margin: 0;
}

.footer_copyright {
    background-color: #423547;
    text-align: center;
    color: #fff;
    padding: 8px 15px;
}

.footer_link a,
.footer_2_link li a:hover {
    text-decoration: underline;
}

.footer_links a {
    color: #333;
    font-size: 15px;
    font-weight: 600;
}

.footer_links li {
    display: inline-block;
    margin-right: 10px;
}

.footer_right_menu a {
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
}

.footer_right_menu li {
    margin-bottom: 24px;
}

.footer_right_menu li:last-child {
    margin-bottom: 0;
}

.footer_right_menu,
.table_report_layout table thead tr th:last-child,
.table_report_layout table tr td:last-child {
    text-align: right;
}

.footer_sec {
    background: #351249;
    color: #fff;
    padding: 40px 0;
}

.footer_sec h2 {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 16px;
}

.footer_top {
    border-bottom: 3px solid #340f49;
    padding-bottom: 2rem;
    margin-bottom: 2rem;
}

.free_prop_btn {
    color: #fff;
    background: #5ccbf5;
    font-weight: 600;
    font-size: 15px;
    border-radius: 6px;
    display: inline-block;
    padding: 10px 28px;
}

.free_prop_btn:hover,
.free_prop_btn:focus {
    color: #fff;
    background: #340f49;
}

.bg-logo-color {
    background: #5BCAF4 !important;
}

.get_in_touch_bottom h5,
.get_in_touch_bottom h5 a {
    color: #423547;
    font-size: 20px;
    line-height: 1.4;
}
.get_in_touch_bottom .form-control {
    padding: 0.85rem 0.75rem;
    height: auto !important;
}
.get_in_touch_bottom .react-tel-input .flag-dropdown {
    border: 0;
    background: #fff;
}
.rq_cutom_demo .form-control {
    padding: 0.85rem 0.75rem;
    height: auto !important;
}
.rq_cutom_demo .react-tel-input .flag-dropdown {
    background: #fff;
}

.get_proposal_btn {
    background: #5ccbf5;
    border: 2px solid #5ccbf5;
    border-radius: 6px;
    color: #fff;
    display: inline-block;
    font-size: 15px;
    font-weight: 400;
    padding: 5px 15px;
}

.get_proposal_btn:hover,
.get_proposal_btn:focus,
.get_proposal_btn:not(:disabled):not(.disabled):active,
.get_proposal_outline:hover,
.get_proposal_outline:focus,
.get_proposal_outline:not(:disabled):not(.disabled):active {
    color: #fff;
    background: #340f49;
    border: 2px solid #340f49;
}

.get_proposal_outline {
    color: #340f49;
    background: transparent;
    border: 2px solid #5ccbf5;
    font-weight: 600;
    font-size: 15px;
    border-radius: 6px;
    display: inline-block;
    padding: 10px 28px;
}

.btn.disabled,
.btn.disabled:hover {
    background-color: #c5c5c5 !important;
    border-color: #c5c5c5 !important;
    color: #fff !important;
}

.btn.disabled,
.btn.disabled:hover {
    background-color: #c5c5c5 !important;
    border-color: #c5c5c5 !important;
    color: #fff !important;
}

.get_register_btn {
    color: #fff;
    background: #340f49;
    font-weight: 600;
    font-size: 15px;
    border-radius: 6px;
    display: inline-block;
    border: 2px solid #340f49;
    padding: 10px 28px;
}

.get_register_btn:hover,
.get_register_btn:focus,
.get_register_btn:not(:disabled):not(.disabled):active {
    color: #340f49;
    background: #5ccbf5;
    border: 2px solid #340f49;
}

.get_touch_form .form-control {
    font-size: 16px;
    padding: .5rem 1rem;
}

.gv_brochure_benfit_list {
    margin-bottom: 10px;
    display: grid;
}

.gv_brochure_benfit_list li {
    color: #423547;
    display: inline-block;
    margin-right: 1rem;
    font-size: 15px;
    font-weight: 600;
    padding: 5px 0;
}

.gv_brochure_colum_1 .nav .nav-link {
    color: #340f49;
    font-weight: 600;
    font-size: 16px;
    padding: 10px 20px;
}

.gv_brochure_colum_1 .nav .nav-link.active {
    border-top-color: #5ccbf5;
    border-left-color: #5ccbf5;
    border-right-color: #5ccbf5;
    color: #5ccbf5;
}

.gv_brochure_colum_1 .nav .nav-link:hover {
    color: #5ccbf5;
}

.gv_brochure_colum_1 p {
    color: #423547;
    font-size: 16px;
}

.gv_brochure_img_left {
    box-shadow: 0 0 6px #00000042;
    border-radius: 4px;
    background: #fff;
    padding: 10px;
}

.gv_brochure_use_case h5 {
    color: #30b0e0;
}

.gv_header {
    background: #340f49;
    border-bottom: 3.25px solid #5ccbf5;
    padding: 8px 0;
}

.gv_header .dropdown-menu {
    border-bottom: 2px solid #5ccbf5;
    border-top: 2px solid #5ccbf5;
    background: #fff;
    border-radius: 0 0 2px 2px;
    padding: 10px 15px;
    min-width: 210px;
}

.gv_header .dropdown-menu>li {
    border-bottom: 1px solid #dee2e6;
}

.gv_header .dropdown-menu>li>a {
    display: block;
    color: #212529;
    font-size: 14px;
    padding: 8px 0;
}

.gv_header .dropdown-menu>li>a:hover {
    color: #128ab7;
    font-weight: 600;
}

.gv_header .dropdown:hover ul.dropdown-menu,
.gv_mega_dropdown:hover .gv_mega_menu {
    display: block !important;
}

.gv_header .nav {
    align-items: center;
}

.gv_header .nav>li>a {
    color: #fff;
    display: inline-block;
    border-radius: 6px;
    font-size: 16px;
    padding: 8px 14px;
}

.gv_header .navbar {
    position: static;
    padding: 3px 0;
}

.gv_header li.dropdown:hover>a {
    background: #5ccbf5;
    color: #fff !important;
}

.gv_header.hz_header_white {
    background: #fff;
}

.gv_header.hz_header_white .nav>li>a {
    color: #212549;
}

.gv_header_login_btn {
    position: absolute;
    right: -88px;
}

.gv_header_search {
    width: 250px;
    position: relative;
    overflow: hidden;
    margin: 0 0 0 10px;
}

.gv_header_search button {
    position: absolute;
    right: 0;
    top: 0;
    background: #5ccbf5;
    border: none;
    height: 100%;
    color: #fff;
    border-radius: 0 6px 6px 0;
    padding: 10px;
}

.gv_header_search button svg {
    vertical-align: top;
}

.gv_header_search input.form-control {
    border-radius: 6px;
}

.gv_header .dropdown-menu>li:last-child {
    border-bottom: none;
}

.header-search-box .header-s-btn {
    background: #340f49;
    color: #fff;
    margin-left: -5px;
    border-radius: 0 5px 5px 0;
    padding: 5px 15px;
}

.header-search-box .header-s-btn:hover,
.header-search-box .header-s-btn:focus {
    box-shadow: none !important;
    background: #5ccbf5 !important;
    color: #FFF;
}

.header-search-box .page-s-btn {
    background: #340f49 !important;
    color: #fff;
    border-radius: 0 8px 8px 0;
    padding: 5px 50px;
    position: absolute;
    top: 0;
    right: 0;
    font-weight: bold;
    height: 55px;
}

.header-search-box .page-s-btn:hover,
.header-search-box .page-s-btn:focus {
    box-shadow: none !important;
    background: #5ccbf5 !important;
    color: #FFF;
}

.header-search-box .header-s-input {
    padding: 5px 15px;
}

.header-search-box .page-hd-input {
    height: 55px;
    padding-left: 40px !important;
    border-radius: 8px !important;
}

.header-search-box .header-s-input:hover,
.header-search-box .header-s-input:focus {
    box-shadow: none !important;
    border: 1px solid #5ccbf5 !important;
}

.header-search-box .page-s-icon {
    top: 14px !important;
}

.header-search-box .s-input {
    width: 1000px;
    padding-left: 45px;
}

.header-search-box .search_bar_icn {
    position: absolute;
    left: 10px;
    top: 17px !important;
}

.gv_mega_dropdown {
    position: static;
}

.gv_mega_menu {
    display: none;
    position: absolute;
    border-radius: 0 0 2px 2px;
    left: 20px;
    width: calc(100% - 42px);
    background: #fff;
    color: #000;
    z-index: 999;
    top: 36px;
    border-bottom: 2px solid #5ccbf5;
    border-top: 2px solid #5ccbf5;
    padding: 1rem;
}

.gv_mega_menu .gv_heading_menu a {
    font-weight: 600;
    color: #340f49;
    padding: 10px 0;
    display: block;
    border-bottom: 1px solid transparent;
}

.gv_mega_menu .gv_heading_menu a:hover { 
    border-bottom: 1px solid #5ccbf5;
}

.gv_search_mobile,
.count_services_style.last:after {
    display: none;
}

.header_user_drop .dropdown-toggle {
    background: #fff;
    border: 1px solid #dee2e6;
    border-radius: 6px;
    padding: 10px;
}

.header_user_drop .dropdown-toggle:after {
    color: #728095;
    content: "";
    background: url(https://cdn.grandviewresearch.com/horizon/images/list.svg) no-repeat;
    border: none;
    width: 20px;
    height: 20px;
    vertical-align: middle;
}

.home2_banner {
    background: linear-gradient(180deg, rgba(53, 18, 73, 1) 33%, rgba(91, 202, 244, 1) 100%);
    position: relative;
    padding: 140px 0;
}

.home2_banner button,
.home2_banner .home-search-btn {
    background: #351249;
    font-weight: 600;
    font-size: 18px;
    border-radius: 0 8px 8px 0;
    color: #fff;
    height: 67px;
    position: absolute;
    right: 0;
    top: 0;
    padding: 21px 30px;
}

.home2_banner h1 {
    font-size: 30px;
    line-height: 1.5;
}

.home2_banner h2 {
    font-size: 22px;
    line-height: 1.5;
}

.home2_banner input {
    border: none;
    border-radius: 8px;
    font-size: 18px;
    font-weight: 600;
    padding: 20px 20px 20px 54px;
}

.home2_banner input:hover,
.home2_banner input:focus {
    border: none !important;
}

.home2_banner:after,
.services_banner:after {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    opacity: 0.1;
    left: 0;
    top: 0;
    background: url(https://cdn.grandviewresearch.com/horizon/images/bg-diagram.png) no-repeat;
    background-size: 100%;
    background-position: bottom;
}

.home2_contect_form .form-check-label {
    font-size: 13px;
}

.home2_contect_form .form-control,
.home2_contect_form select {
    font-weight: 600;
    font-size: 13px;
    padding: .5rem 1rem;
}

.home2_ingrated_box {
    margin-top: 100px;
}

.home2_powered_col {
    display: flex;
    height: 100%;
    color: #FFF;
    align-items: center;
    padding: 3rem;
}

.home2_powered_col h4 {
    font-size: 24px;
    line-height: 1.4;
}

.home2_powered_sec,
.home2_testimonial_sec {
    background: #f8f6ef;
}

.home2_services_sec {
    background: #E9E9E9;
}

.home2_testimonal_col {
    display: flex;
    height: 100%;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.horizen_banner h5 {
    font-size: 18px;
    line-height: 1.5;
}

.horizen_search_banner .form-control {
    border: none;
    background: transparent;
    border-bottom: 3px solid #340f49;
    margin-left: 20px !important;
    font-size: 20px;
    border-radius: 0;
}

.hr_company_logo {
    background: #baacc2;
    border-radius: 6px;
    display: flex;
    align-content: center;
    align-items: center;
    padding: 10px;
}

.hr_left_menu_sec {
    background: #fff;
    min-height: 100vh;
    border-right: 1px solid #dee2e6;
}

.hr_left_menu_sec ul li a {
    color: #728095;
    display: block;
    margin: 2px 0;
    padding: 10px 12px;
}

.hr_left_menu_sec ul li a.active {
    background: #f7f8fa;
    color: #212529;
    border-radius: 2px;
    position: relative;
}

.hr_left_menu_sec ul li a.active:after {
    content: "";
    background: url(https://cdn.grandviewresearch.com/horizon/images/chevron-right.svg) no-repeat;
    position: absolute;
    width: 15px;
    height: 15px;
    right: 10px;
    top: 12px;
}

.hr_logo {
    padding: 1.5rem 8px;
}

.hr_report_view_col h1,
.horizen_banner,
.horizen_banner h1 {
    font-size: 44px;
}

.hr_top_search {
    min-width: 250px;
    padding: 11px 11px 11px 40px;
}

.html_view_btn {
    font-size: 14px;
    text-align: center;
    border-radius: 4px;
    background: #619932;
    color: #fff;
    border: none;
    padding: 7px 15px;
}

.html_view_btn:hover,
.html_view_btn:focus {
    background: #3a5a1f;
    color: #fff;
    border-color: #5ccbf5;
}

.hz_fr_search_banner input {
    background-color: #fff !important;
    border: 1px solid #340f49;
    font-size: 15px;
    height: 48px;
    border-radius: 6px;
    background: url(https://cdn.grandviewresearch.com/horizon/images/search.svg) no-repeat;
    background-position: 10px 14px;
    padding: 12px 20px 12px 36px;
}

.hz_fr_search_banner,
.case_banner {
    background: #e5eeee;
}

.hz_header {
    padding: 1rem 0;
}

.hz_header .dropdown-item,
.table_report_layout table {
    font-size: 14px;
}

.hz_header .nav-link {
    color: #212549;
    font-weight: 600;
}

.hz_header_btn {
    background: #340f49;
    color: #fff;
    font-weight: 600;
    display: inline-block;
    border-radius: 6px;
    padding: 8px 16px;
}

.hz_header_btn:hover,
.hz_header_btn:focus,
.home2_banner button:hover,
.home2_banner button:focus,
.home2_banner .home-search-btn:hover,
.home2_banner .home-search-btn:focus {
    background: #5ccbf5;
    color: #fff;
}

.hz_indutries_dark_bg {
    background: #340f49;
}

.hz_indutries_heading,
.comapnies_heading {
    color: #340f49;
    font-size: 32px;
    line-height: 1.2;
}

.hz_indutries_sub_heading {
    font-size: 21px;
    line-height: 1.4;
}

.hz_indutries_title {
    color: #5bcaf4;
    font-size: 18px;
    text-transform: uppercase;
}

.hz_latest_report_sec {
    background: #F3F6FB;
}

.hz_latest_report_sec .recent_public_heading,
.cor_plan_heading {
    font-size: 24px;
}

.hz_plan_check li {
    position: relative;
    padding: 0 0 5px 24px;
}

.hz_plan_check li:after {
    position: absolute;
    background: url(https://cdn.grandviewresearch.com/horizon/images/check_png.png) no-repeat;
    width: 14px;
    height: 14px;
    border-radius: 6px;
    content: "";
    left: 0;
    top: 6px;
    background-size: 14px;
}

.hz_track_list img,
.value_add_list img {
    vertical-align: top;
    margin-top: 2px;
}

.insight_tab button {
    position: relative;
    width: 100%;
    font-weight: 600;
    border: 2px solid transparent;
    margin-top: 1rem;
    font-size: 14px;
    background: #FFF;
    text-align: left;
    box-shadow: 0 0 10px 4px rgba(121.12, 121.12, 121.12, 0.10);
    border-radius: 6px;
    padding: 12px 12px 12px 40px;
}

.insight_tab button.active {
    border: 2px solid #5ccbf5;
}

.insight_tab svg {
    fill: #5ccbf5;
    position: absolute;
    top: 12px;
    left: 12px;
}

.insights_col_box h4 {
    font-weight: 600;
    color: #340f49;
    margin: 1rem 0;
}

.insights_col_box h6 {
    color: #616161;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
}

.insights_heading_tag {
    background: #5BCAF4;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    display: inline-block;
    border-radius: .25rem;
    padding: .25rem 1rem;
}

.insights_sec {
    background: #F3F6FB;
    background-position: center;
    background-size: 100%;
}

.interest_topic_text h6 a {
    cursor: not-allowed;
}

.left_cat_name {
    width: 80px;
    text-align: right;
    margin-right: 20px;
    font-weight: 600;
    color: #340F49;
}

.left_cat_name,
.right_report_show {
    display: inline-block !important;
    vertical-align: top;
}

.list_view_box {
    break-inside: avoid;
    margin-bottom: 1rem;
}

.list_view_box a {
    color: #474747;
}

.list_view_box li {
    margin-bottom: 8px;
}

.list_view_box_heding a {
    display: block;
    color: #340f49;
    font-weight: 600;
    margin-bottom: 10px;
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
}

.list_view_sec {
    column-count: 3;
    counter-reset: grid;
}

.list_view_sec .accordion-item .accordion-button.collapsed {
    border-bottom-left-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
}

.segment-li-main {
    padding: 5px 0;
}

.segment-li-sub {
    margin-left: 25px;
    list-style: disc outside;
    padding: 5px 0;
}

.segment-li-sub .segment-li-sub {
    list-style: square outside;
}

.segment-li-sub .segment-li-sub .segment-li-sub {
    list-style: circle outside;
}

.login-gvr-logo {
    max-width: 200px;
    float: left;
}

.login_bg {
    background: url(../img/login-signup.png) no-repeat;
    background-size: cover;
}

.marginByScroll {
    margin-right: 10px;
}

.mega_menu_stais_heading {
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
}

.menu_icn_statis {
    background: #76518b;
    color: #FFF;
    font-size: 13px;
    text-align: center;
    border-radius: 50%;
    min-width: 30px;
    height: 30px;
    align-self: center;
    max-width: 30px;
    margin-right: 10px;
    padding: 4px 0;
}

.meth_appr_ol li {
    position: relative;
    font-size: 20px;
    color: #340f49;
    padding: 8px 0 0 22px;
}

.meth_appr_ol li li {
    font-size: 16px;
}

.meth_appr_ol li li:after {
    top: 15px;
    content: "";
}

.meth_appr_ol li:after {
    position: absolute;
    left: 0;
    top: 18px;
    content: "";
    width: 8px;
    height: 8px;
    background: #aaa;
    border-radius: 12px;
}

.more_filter_srh_btn {
    background: transparent;
    border: 2px solid #340f49;
    color: #340f49;
    font-weight: 600;
    display: block;
    width: 100%;
    border-radius: 6px;
    font-size: 14px;
    padding: 8px 16px;
}

.more_filter_srh_btn:hover,
.more_filter_srh_btn:focus {
    background: #340f49;
    border: 2px solid #340f49;
    color: #fff;
}

.more_video_btn,
.talk_expert {
    color: #fff;
    background: #340f49;
    font-weight: 600;
    font-size: 15px;
    border-radius: 6px;
    display: inline-block;
    padding: 10px 28px;
}

.my_reports_col {
    background: linear-gradient(tobottom, #d9bb4d 0%, #340f49 100%);
    border-radius: 4px;
    position: relative;
    color: #fff;
    padding: 32px 24px;
}

.my_reports_col h3 {
    color: #fff;
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 16px;
}

.my_reports_col p {
    color: #eee;
    font-size: 16px;
    margin-bottom: 16px;
}

.my_reports_col_2 {
    background: linear-gradient(to bottom, #3c59a8 0%, #340f49 100%);
}

.my_reports_col_3 {
    background: linear-gradient(to bottom, #3ca8a8 0%, #340f49 100%);
}

.my_reports_col_4 {
    background: linear-gradient(tobottom, #84b077 0%, #340f49 100%);
}

.next_btn {
    background: #340f49;
    color: #fff;
    font-weight: 600;
    display: inline-block;
    border-radius: 4px;
    transition: all .1s;
    padding: 8px 16px;
}

.next_btn:hover {
    background: #5ccbf5;
    color: #fff;
    font-weight: 600;
    display: inline-block;
    border-radius: 4px;
    padding: 8px 16px;
}

.ob_bs_ol li {
    position: relative;
    padding: 0 0 5px 22px;
}

.ob_bs_ol li:after {
    position: absolute;
    left: 0;
    top: 6px;
    content: "";
    width: 10px;
    height: 10px;
    background: #5ccbf5;
    border-radius: 10px;
}

.plan_badge {
    position: absolute;
    background: #FB7179;
    color: #fff;
    font-weight: 600;
    right: -28px;
    transform: rotate(40deg);
    top: 9px;
    font-size: 12px;
    padding: 4px 31px;
}

.plan_heading {
    font-size: 35px;
}

.plan_heading small {
    font-size: 18px;
    padding-left: 6px;
}

.precision_list img {
    position: absolute;
    content: "";
    left: 0;
    top: 12px;
}

.precision_list li {
    position: relative;
    font-size: 17px;
    color: #351249;
    padding: 10px 10px 10px 40px;
}

.precision_sec {
    color: #423547;
}

.precission_mark_1 {
    font-size: 28px;
}

.precission_mark_2 {
    font-size: 18px;
}

.premium_case_box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding: 1rem;
}

.premium_case_sec {
    background: #fffffff2;
    position: absolute;
    left: 0;
    width: 100%;
    top: 0;
    height: 100%;
}

.pricing_page_sec {
    background: linear-gradient(180deg, rgb(53, 18, 73) 5%, #5bcaf4 100%);
}

.pricing_page_sec .card {
    border: none;
    padding: 10px 50px;
}

.pricing_page_sec .nav.nav-pills {
    margin-left: auto;
    margin-right: auto;
    width: 261px;
    border: 1px solid #fff;
    border-radius: 50px;
    padding: 1px 0;
}

.pricing_page_sec .nav.nav-pills .nav-link {
    border: none !important;
    border-radius: 50px !important;
    color: #fff !important;
    font-size: 16px;
    font-weight: 600;
    margin: 0;
    padding: 10px 25px !important;
}

.pricing_page_sec .nav.nav-pills .nav-link.active {
    background: #fff !important;
    color: #000 !important;
    border: none !important;
}

.recent_public_media {
    font-size: 20px;
    font-weight: 600;
    color: #340f49;
}

.recent_public_media:hover,
.recent_public_media:focus {
    color: #128ab7;
}

.register_info_tag {
    background: #5ccbf5;
    border-radius: 6px;
    color: #340f49;
    padding: 1.5rem 2rem;
}

.register_info_tag hr {
    border-color: #340f49;
}

.register_info_tag ul li {
    position: relative;
    padding-left: 15px;
    margin-top: 6px;
}

.register_info_tag ul li:after {
    position: absolute;
    border-left: 5px solid #340f49;
    top: 6px;
    content: "";
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    left: 1px;
}

.reports_left_icn {
    background: #5ccbf5;
    width: 28px;
    height: 28px;
    color: #340f49;
    border-radius: 20px;
    text-align: center;
}

.reports_tabs .nav-item {
    width: 100%;
}

.reports_tabs .nav-link {
    text-align: left;
    border-bottom: 1px solid #dde0e4;
    color: #262a2e;
    width: 100%;
    font-size: 14px;
    position: relative;
}

.reports_tabs .nav-link.active,
.more_video_btn:hover,
.more_video_btn:focus,
.btn_banner_servies:hover,
.talk_expert:hover,
.talk_expert:focus {
    color: #fff;
    background: #5ccbf5;
}

.reports_tabs .nav-link.active:after {
    content: "";
    border: solid #fff;
    transform: rotate(-45deg);
    position: absolute;
    right: 10px;
    top: 13px;
    border-width: 0 2px 2px 0;
    padding: 4px;
}

.sarch_icn_header {
    position: absolute;
    left: 15px;
    top: 14px;
}

.search_bar_icn {
    position: absolute;
    left: 15px;
    top: 20px;
    width: 20px;
    color: #757575 !important;
}

.search_data_a {
    color: #262a2e;
    cursor: pointer;
    border-radius: .25rem;
    display: block;
    font-size: 13px;
    padding: .25rem .75rem;
}

.search_data_a:hover {
    background-color: #f7f7f7;
    color: #262a2e;
}

.search_database_sec {
    height: calc(100vh-252px);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.search_database_sec button {
    border-radius: 25px;
    background: #5ccbf5;
    position: absolute;
    border: 0 none;
    right: 8px;
    top: 5px;
    width: 34px;
    height: 34px;
    text-align: center;
    padding: 5px;
}

.search_database_sec input {
    border-radius: 25px;
    padding: 10px 20px;
}

.search_database_tag {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.search_database_tag a,
.search_database_tag span {
    font-size: 14px;
    margin: 10px 5px 0;
}

.serach-show {
    border: 1px solid #ccc;
    width: calc(100%-30px);
    position: absolute;
    background: #fff;
    top: 106%;
    z-index: 99;
    box-shadow: 0 5px 5px 0 #ccc;
    border-top: 2px solid #340f49;
    border-bottom: 2px solid #340f49;
    left: 15px;
    padding: 10px;
}

.serach_show {
    width: 100%;
    border-radius: .5rem;
    position: absolute;
    background: #fff;
    top: 100%;
    z-index: 99;
    left: 0;
    box-shadow: 0 6px 16px rgba(47, 52, 58, 0.1);
}

.services_banner {
    background: linear-gradient(180deg, rgba(53, 18, 73, 1) 60%, rgba(91, 202, 244, 1) 100%);
    position: relative;
    padding: 100px 0;
}

.services_banner_tag li a {
    display: block;
    text-transform: uppercase;
    border-radius: 25px;
    color: #fff;
    font-weight: 600;
    font-size: 15px;
    border: 2px solid #fff;
    margin: 10px;
    padding: 12px 24px;
}

.srh_btn_banner {
    position: absolute;
    border-radius: 6px;
    font-size: 15px;
    height: 40px;
    color: #fff;
    background: #340f49;
    border: 0 none;
    right: 4px;
    top: 4px;
    text-align: center;
    padding: 9px 30px;
}

.srh_category_list li {
    position: relative;
    padding: 0 0 10px 36px;
}

.srh_category_list li a {
    color: #333;
    font-weight: 600;
    font-size: 14px;
}

.srh_category_list li:after {
    background: url(https://cdn.grandviewresearch.com/horizon/images/arrow-right.svg) no-repeat;
    content: "";
    position: absolute;
    width: 32px;
    height: 30px;
    left: 0;
    background-size: 24px;
}

.statis_menu {
    display: flex;
    position: relative;
    line-height: 1.6;
    align-items: center;
    padding: 12px 5px;
}

.statis_menu span {
    font-weight: 600;
    color: #340f49;
}

.success_border {
    border-right: 2px solid #ededed;
    padding-left: 40px;
}

.success_border.last {
    border-right: 0 none;
    padding-left: 40px;
}

.success_sec {
    background: #F9F7F7;
}

.sucess_heading {
    color: #5ccbf5;
    font-size: 40px;
}

.sucess_rate_color {
    color: #0BBC5C;
}

.tab_case_content {
    font-size: 18px;
    color: #495c72;
}

.table_layout table {
    table-layout: fixed;
    font-size: 14px;
}

.table_layout table tbody tr:last-child td {
    border: 0;
}

.table_layout table tbody tr:last-child td:first-child,
.table_report_layout table tbody tr:last-child td:first-child {
    border-radius: 0 0 0 6px;
}

.table_layout table tbody tr:last-child td:last-child,
.table_report_layout table tbody tr:last-child td:last-child {
    border-radius: 0 0 6px 0;
}

.table_layout table thead tr:last-child th,
.table_layout table tbody tr.total_tr td,
.table_report_layout table thead tr:last-child th {
    background: #f3f5f9;
    color: #728095;
    font-weight: 600;
}

.table_report_layout table td,
.table_report_layout table th {
    vertical-align: middle;
    padding: .75rem 1rem;
}

.table_layout tr>th:first-child,
.table_layout tr>td:first-child {
    position: sticky;
    left: 0;
    background: #fff;
    box-shadow: 0 -1px 1px #dee2e6;
}

.table_news table thead th {
    background: #5bcaf447;
    font-weight: 600;
    border-right: 1px solid #5bcaf475 !important;
}

.table_news table tr {
    border-color: #d1f0fc;
}

.table_news table tr td,
.table_news table tr th {
    font-size: 13px;
    border-right: 1px solid #d1f0fc;
    padding: 8px 8px 6px !important;
    white-space: nowrap;
}

.table_news table tr td:last-child {
    border-right: none !important;
}

.table_news table tr th {
    border-right: 1px solid #eff1f2;
}

.table_news table tr:last-child td {
    border-bottom: none;
    border-bottom-left-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
}

.table_news_2 table thead th {
    background: #dee2e6;
    font-weight: bold;
    border-right: 1px solid #cecece !important;
    text-wrap: nowrap;
}

.table_news_2 table tr {
    border-color: #dee2e6;
}

.table_news_2 table tr td {
    padding: 8px 8px 6px !important;
    font-size: 13px;
    border-right: 1px solid #eff1f2;
    text-wrap: nowrap;
}

.table_news_2 table tr td:last-child,
.table_news table tr th:last-child {
    border-right: none !important;
    border-bottom-left-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
}

.table_news_2 table tr:last-child td {
    border-bottom: none;
}

.table_news_2 table tr:nth-child(2n+2) td {
    background: #f7f7f7;
}

.table_company thead th {
    background: #f5f8fb;
}

.table_company table tr th,
.table_company table tr td {
    padding: 10px 15px !important;
}


.tag_sec {
    background: url(https://cdn.grandviewresearch.com/horizon/images/img_bg_2.png) no-repeat;
    background-size: cover;
    text-align: center;
}

.tag_sec ul li a {
    border-radius: 30px;
    border: 2px solid #fff;
    color: #fff;
    font-weight: 600;
    display: block;
    margin: 30px 10px 0;
    padding: 10px 30px;
}

.tag_sec ul li a:hover {
    border: 2px solid #5ccbf5;
    color: #fff;
    background: #5ccbf5;
}

.tag_sec ul li,
.services_banner_tag li {
    display: inline-block;
}

.team_sec {
    margin-top: -70px;
}

.trust_count_sec ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
}

.trust_count_sec ul li {
    text-align: center;
}

.trust_count_sec ul li img {
    max-width: 140px;
}

.trust_count_sec ul li:last-child img {
    max-width: 110px;
}

.use_case_tab .nav-link {
    background: transparent;
    color: #728095;
    border-bottom: 1px solid #e6eaee;
    border-radius: 0;
}

.use_case_tab .nav-link.active {
    background: transparent;
    color: #0f2744;
    border-bottom: 1px solid #2773ff;
    border-radius: 0;
}

.use_case_tab .nav-pills {
    width: 676px;
    margin: 0 auto;
}

.value_add_list {
    font-size: 18px;
    line-height: 1.4;
    color: #423547;
}

.value_add_tabheading {
    font-size: 18px;
    color: #423547;
}

.video_img {
    margin-top: -100px;
}

.view_account_btn {
    background: #340F49;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    border-radius: .25rem;
    display: inline-block;
    padding: .75rem 1.5rem;
}

.view_account_btn:hover,
.view_account_btn:focus {
    background: #340F49;
    color: #fff;
}

footer a,
.hz_indutries_dark_bg .hz_indutries_heading {
    color: #fff;
}

footer a:hover,
footer a:focus {
    color: #5bcaf4;
}

/* Purchase */
#card-errors {
    font-weight: 600;
    font-size: 12px;
    display: block;
    height: 20px;
    color: #F25;
    padding: 4px 0;
}

.Alart {
    background: #ebf3fd;
    border: 1px solid #d4e4fa;
    border-radius: 5px;
    padding: 10px;
}

.Alart ul li {
    list-style: disc;
    margin-left: 25px;
}

.StripeElement {
    background-color: #FFF;
    border-radius: 4px;
    border: 1px solid #E4E4E4;
    padding: 8px 14px;
}

.StripeElement--focus,
.field--focus {
    border-color: #0CF;
}

.StripeElement--invalid {
    border-color: #F03;
}

.StripeElement--webkit-autofill,
.field--webkit-autofill {
    background-color: #F9F9F9 !important;
}

.adders_col a.button {
    position: absolute;
    right: 15px;
}

.adders_col a.button svg {
    fill: #5bcaf4;
    height: 15px;
    width: 15px;
}

.borderbottom-1 {
    width: 80%;
    height: 1px;
    background-color: rgba(157, 179, 187, .25);
    margin: auto;
}

.borderbottom-2 {
    width: 40%;
    height: 1px;
    background-color: #ccc;
    margin-top: 15px;
    margin-bottom: 20px;
}

.borderbottom-3 {
    width: 100%;
    height: 1px;
    background-color: #ccc;
    margin-top: 20px;
    margin-bottom: 20px;
}

.field {
    background: #FFF;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 400;
    border: 1px solid #E4E4E4;
    border-radius: 4px;
    color: #242424;
    outline: none;
    height: 36px;
    line-height: 36px;
    cursor: text;
    width: 553px;
    padding: 8px 14px;
}

.field--invalid {
    border-color: #F25;
}

.field--placeholder {
    color: #D4D4D4;
}

.fit-content {
    width: fit-content;
}

.form-row {
    width: 553px;
    padding-top: 13px;
}

.panelbox {
    background: #fff;
    border-radius: 10px 10px 0 0;
    margin-top: 30px;
    box-shadow: 0 5px 26px 0 rgba(67, 94, 131, .15);
    padding: 25px 25px 15px;
}

.panelbox h5 {
    font-size: 18px;
    line-height: 30px;
}

.panelbox ul li {
    font-size: 15px;
    margin-bottom: 10px;
    position: relative;
    display: block;
    padding-left: 25px;
}

.panelbox ul li svg {
    width: 17px;
    height: 17px;
    fill: #351249;
    position: absolute;
    top: 3px;
    left: 0;
}

.price p {
    color: #455f7c;
}

/* Registration Professional */
.progressbarWrapper {
    margin: 0 0 50px;
}

.progressbar {
    counter-reset: step;
    display: flex;
    list-style: none;
    margin-bottom: 10px;
    padding: 0;
}

.progressbar__item {
    align-items: center;
    color: #9db3bb;
    display: flex;
    flex: 1;
    flex-direction: column;
    font-size: 13px;
    justify-content: center;
    position: relative;
    text-align: center;
}

.progressbar__item:before {
    align-self: center;
    border: 2px solid #9db3bb;
    border-radius: 100%;
    content: counter(step);
    counter-increment: step;
    font-size: 13px;
    font-weight: 600;
    height: 24px;
    line-height: 22px;
    width: 24px;
}

.progressbar__item--active:before {
    border-color: #0666e5;
    color: #0666e5;
}

.progressbar__item:after {
    background: #9db3bb;
    content: "";
    height: 2px;
    left: calc(50% + 12px);
    position: absolute;
    top: 12px;
    width: calc(100% - 24px);
}

.progressbar__item--complete:after {
    background: #0666e5;
}

.progressbar__item:last-child::after {
    content: none;
}

.progressbar__item--complete.progressbar__item:before {
    align-self: center;
    background: #0666e5;
    border: 2px solid #0666e5;
    border-radius: 100%;
    content: "";
    font-size: 13px;
    font-weight: 600;
    height: 24px;
    line-height: 22px;
    width: 24px;
}

/*            .progressbar__itemLink::after{content:"";padding:28px;left:calc(50% - 28px);top:calc(50% - 28px);position:absolute;}*/
.progressbar__iconWrapper {
    height: 28px;
    margin-top: -28px;
    position: relative;
    text-align: center;
    width: 28px;
}

.progressbar__checkmark {
    fill: none;
    height: 12px;
    margin-top: 11px;
    width: 12px;
}

.progressbar__checkmark--active {
    fill: #fff;
}

.progressbarDescription {
    display: flex;
    text-align: center;
}

.progressbarDescription__item {
    flex-basis: 0%;
    flex-grow: 1;
    flex-shrink: 1;
    font-size: 13px;
}

.progressbarDescription__item--active {
    color: #0666e5;
    font-weight: 600;
}


.right-box {
    background: #fff;
    box-shadow: 0 5px 26px 0 rgba(67, 94, 131, .15);
    border-radius: 10px;
}

.stripe_container button {
    color: #fff;
    background-color: #09769F;
    outline: none;
    font-size: 1rem;
    border-radius: .375rem;
    margin-top: 15px;
    border: 1px solid #09769F;
    border-width: 1px;
    padding: .375rem 1rem;
}

.total-price {
    background-color: #f5f9fe;
    font-weight: 500;
    color: #455f7c;
    border-radius: 0 0 10px 10px;
    padding: 25px;
}

.total-price p {
    font-weight: 600;
    color: #455f7c;
}

/* Purchase end*/

#headingTwoDataset button {
    color: black !important;
}

.com-sr-input {
    border: none;
    border-bottom: 1px solid #d8d8d8;
    border-radius: 0px !important;
}

.pipline_Benefits_sec ul li {
    font-size: 20px;
    color: #423547;
    padding: .5rem 0;
    display: flex;
}

.astra_bg_grad {
    background: linear-gradient(45deg, rgb(0 0 0) 0%, #175E29 100%)
}

.signal_bg_grad {
    background: linear-gradient(45deg, rgb(0 0 0) 0%, #09769F 100%);
}

.pipine_bg_grad {
    background: rgba(24, 239, 48, 0.15);
}

.astra_more_btn {
    color: #fff;
    background: linear-gradient(90deg, rgb(64, 153, 196) 0%, rgb(0, 120, 131) 100%);
    font-weight: 600;
    font-size: 15px;
    border-radius: 6px;
    display: inline-block;
    border: none;
    padding: 12px 28px;
}

.astra_more_btn:hover,
.astra_more_btn:focus {
    background: linear-gradient(90deg, rgb(61, 116, 143) 0%, rgb(5, 82, 89) 100%);
    color: #fff;

}

.astra_solution_list_heading {
    font-size: 20px;
    line-height: 1.2;
}

.astra_solution_list a {
    color: #212529;
}

.astra_solution_list a:hover {
    color: #41b6e3;
    text-decoration: underline;
}

.astra_solution_sec {
    background: rgba(24, 239, 48, 0.15);
}


.reading_full_box {
    overflow: hidden;
    position: relative;
    transition: all 0.1s;
    height: auto;
}

.reading_full_box ul li {
    position: relative;
    padding: 5px 20px 5px;
    margin-left: 30px;
    color: #444444;
}

.reading_full_box ul li::after {
    /* list-style: inside; */
    top: 13px;
    left: 0;
    position: absolute;
    content: '';
    width: 6px;
    height: 6px;
    background-color: #444444;
    border-radius: 20px;
}

.reading_full_box h2, .reading_full_box strong {
    font-size: 20px;
    font-weight: bold;
    color: #340f49;
    margin: 20px 0;
}

.reading_full_box h3 {
    font-size: 16px;
    font-weight: bold;
    color: #423547;
    margin: 20px 0;
}

.reading_full_box h4 {
    font-size: 16px;
    font-weight: bold;
    color: #423547;
    margin: 20px 0;
}

.reading_full_box h5,
.reading_full_box h6 {
    font-size: 15px;
    font-weight: bold;
    color: #756280;
    margin: 20px 0;
}

.reading_full_box p {
    font-size: 15px;
    line-height: 1.6rem;
    margin-bottom: 10px;
}
.read_less_more_btn {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 9;
}

.reading_full_box.active {
    height: auto;
    transition: all .1s;
}

.reading_full_box:after {
    bottom: 0;
    height: 80px;
    opacity: 1;
    pointer-events: none;
    position: absolute;
    transition: .1s;
    width: 100%;
    /* background: linear-gradient(hsla(0, 0%, 100%, 0), #fff 50%); */
    content: "";
}

.reading_full_box.active:after {
    display: none;
}

.reading_full_box.active .read_less_more_btn {
    position: relative;
    left: auto;
    bottom: auto;
}

.read_less,
.reading_full_box.active .read_more {
    display: none !important;
}

.reading_full_box.active .read_less {
    display: block !important;
    ;
}


/* Horizon new Layout  */
.hz_layout_2 {
    background: #F5FAFC;
}

.hz_layout_2 .hk-pg-wrapper {
    padding: 87px 0 0 !important;
}

.hz_menu_2 {
    box-shadow: 0px 8px 24px 0px rgba(21, 21, 22, 0.04);
}

.hz_menu_2 .menu-nav-link[data-bs-toggle="collapse"]:after {
    position: absolute;
    right: 1rem;
    content: '\f2f9';
    font: normal normal normal 1.125rem/1 'Material-Design-Iconic-Font';
    font-size: 1.125rem;
    color: #423547 !important;
    text-rendering: auto;
    margin-left: auto;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: all 0.1s ease;
    -moz-transition: all 0.1s ease;
    transition: all 0.1s ease;
    z-index: 9 !important;
}

.hz_menu_2 .menu-nav-link[data-bs-toggle="collapse"][aria-expanded="true"]::after {
    transform: rotate(-180deg);
}

.hz_menu_2 ul::after {
    display: none;
}

.hz_menu_2 .menu-nav-link {
    color: #423547 !important;
    font-size: 14px;
    border-radius: 6px;
    position: relative;
    margin-top: 8px !important;
}

.hz_menu_2 .nav .menu-nav-link {
    width: 100%;
    padding: 0.4rem 0.6rem !important;
    margin: 0 0 0 16px !important;
    font-size: 13px;
    color: #756280 !important;
}

.hz_menu_2 .nav .menu-nav-link.more_link {
    color: #5BCAF4 !important;
}

.hz_menu_2 .nav .menu-nav-link.more_link:hover,
.hz_menu_2 .nav .menu-nav-link.more_link:focus {
    background: transparent;
    text-decoration: underline;
    box-shadow: none;
}

.hz_menu_2 .menu-nav-link:hover,
.hz_menu_2 .menu-nav-link.active {
    background: #eed4ff;
    color: #351249 !important;
    box-shadow: 0 3px 4px #aeaeae3d;
}

.hz_menu_2 .menu-nav-link:hover>* {
    transform: translate(0) !important;
}

.more_link {
    color: #5BCAF4 !important;
}

.more_link:hover {
    color: #351249 !important;
}

.left_menu_promation_box {
    background: linear-gradient(327deg, #A751D9 -81.71%, #EED4FF 52.94%);
    position: relative;
    border-radius: 4px;
    box-shadow: none;

}

.left_menu_promation_box .btn-close {
    position: absolute;
    right: 1rem;
    top: 1rem;
    background-size: 10px;
}

.left_menu_promation_box .btn-close-2 {
    right: 0rem;
    top: 0rem;
}

.hk-wrapper.hz_layout_2 .hk-navbar {
    height: 87px;
    background: #F5FAFC;
}

.search_header_2 {
    position: relative;
    width: 510px;
} 

.icn_search {
    position: absolute;
    left: 10px;
    top: 14px;
}

.search_header_2 .header-s-input {
    padding: 1rem 1rem 1rem 35px;
    font-size: 16px;
    border-radius: 8px;
}



/*.hk-navbar.fixed-top-small {
    height: 65px !important;
}*/

.btn_req_small {
    display: none;
}

/*.fixed-top-small .btn_req_small {
    display: block;
}

.fixed-top-small .search_header_2 {
    width: 400px;
}

.fixed-top-small .search_header_2 .header-s-input {
    font-size: 13px;
    padding: 0.5rem 1rem 0.5rem 28px;
}

.fixed-top-small .search_header_2 .icn_search {
    top: 7px;
}

.fixed-top-small .search_header_2 .icn_search svg {
    width: 16px;
}

.fixed-top-small .btn {
    padding: 0.5rem 0.8rem !important;
    font-size: 13px !important;
}*/

.horizon_bt_create_free_acc{
    background: #f63 !important;
}

.industry-menu-col {
    box-shadow: 0 8px 24px 0 rgba(21, 21, 22, .04);
    background: #f6f6f6;
    border-radius: 8px 0 0 8px;
    border-bottom: 1px solid #e9ecef;
}


.industry_menu_height {
    overflow: hidden;
    max-height: 500px;
}

.industry-menu-subheading a {
    font-size: 14px;
    color: #423547;
    display: block;
    padding: 10px 16px;
    position: relative;
}

.industry-menu-col .industry-menu-subheading a {
    border-bottom: 1px solid #e9ecef;
    border-left: 1px solid #e9ecef;
    border-right: 1px solid #e9ecef;
}

.industry-menu-col .industry-menu-subheading:first-child a {
    border-top: 1px solid #ecf0f2;
    border-radius: 8px 0 0 0;
}

.industry-menu-subheading a.active {
    background: #fff;
    color: #340f49;
    font-weight: 600;
    border-right: 1px solid #fff;
}

.industry-menu-subheading a.active_sub {
    font-weight: 600;
    background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="bi bi-chevron-right" viewBox="0 0 16 16" fill="%23340f49"><path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/></svg>');
    background-repeat: no-repeat;
    background-position: right 12px;
}

.result_layout_body {
    height: calc(100vh - 266px);
    overflow-y: auto;
    overflow-x: hidden;

}

.search_industry_zoom {
    transition: all .1s;
}

.search_industry_zoom .form-control {
    padding-left: 36px;
    padding-right: 0;
    transition: all .1s;
    max-width: 36px;
    position: absolute;
    right: 0;
    top: -17px;
}

.search_industry_zoom .form-control:focus {
    max-width: 100%;
    padding-right: 10px;
}

.icn_search_industry {
    top: -12px;
    right: 10px;
    position: absolute;
    pointer-events: none;
    z-index: 9;
    transition: all .1s;
}

.search_industry_zoom .form-control:focus+.icn_search_industry {
    right: calc(100% - 28px);
}

.all_category_sel_col a {
    font-size: 13px;
}

.all_category_sel_col a.active,
.all_category_sel_col a:hover {
    background: #eed4ff;
    color: #351249;
    box-shadow: 0 3px 4px #aeaeae3d;
}

.srch_result_col {
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.1s;
}

.srch_result_col:hover {
    border: 2px solid #5bcaf4;
    background: #fff;
    box-shadow: 0 5px 8px #534c4c3d;
}

.srch_result_col:hover h5 {
    text-decoration: underline;
}

.srch_result_col:hover svg {
    fill: #5bcaf4 !important;
}

.srch_result_col_top_heading {
    color: #340F49;
}

.srch_result_col:hover .srch_result_col_top_heading {
    color: #5bcaf4;
}

.srch_result_col h5 {
    font-size: 20px;
}

.srch_result_col .listing-sub-heading {
    /* width: 100%; */
    display: block;
    font-size: 14px;
    font-weight: normal;
    /* margin-left: -30px; */
    margin-top: 6px;
}

.btn_unlock {
    border: 1px solid #351249;
    color: #351249;
}

.btn_unlock:hover {
    background: #351249;
    color: #fff;
}

.jexcel_content {
    padding-right: 0px !important;
    padding-bottom: 0px !important;
}

.jexcel_content table {
    border-right: 0px !important;
    border-left: 0px !important;
}

.table_layout2 table {
    border-top: 1px solid rgba(233, 236, 239);
    width: 100% !important;
    margin-bottom: 0;
}

.table_layout2 table th {
    background: none;
    font-size: 14px;
    color: #351249;
    font-weight: 600;
    padding: 0.75rem 0.5rem !important;
}

.table_layout2 table th:first-child,
/* .table_layout2 table td:first-child {
    white-space: nowrap;
} */

.table_layout2 table td {
    padding: 0.75rem 0.5rem !important;
    font-size: 14px;
    color: #423547 !important;
    border-color: rgba(233, 236, 239) !important;
}

.table_layout2 table tr:last-child td:first-child {
    border-bottom-left-radius: 6px;
}

.table_layout2 table tr:last-child td:last-child {
    border-bottom-right-radius: 6px;
}
.table_esg table td {
  vertical-align: middle;

}
/* // Horizon new Layout  */

/* subscription page  */

.subscribe_sec .nav.nav-pills {
    margin-left: auto;
    margin-right: auto;
    width: 261px;
    border: 1px solid #340f49;
    border-radius: 50px;
    padding: 1px 0;
}

.subscribe_sec .nav.nav-pills .nav-link {
    border: none !important;
    border-radius: 50px !important;
    color: #340f49 !important;
    font-size: 16px;
    font-weight: 600;
    margin: 0;
    padding: 10px 25px !important;
}

.subscribe_sec .nav.nav-pills .nav-link.active {
    background: #340f49 !important;
    border: none !important;
    color: #fff !important;
}

/* // subscription page  */


/* /Elastic search */
.sui-layout .sui-layout-sidebar {
    padding: 16px 0px 0 0 !important;
    width: 28%;
}

.sui-layout .sui-layout-main {
    padding: 16px 0 32px 32px !important;
    width: 72%;
}
.sui-layout  .sui-layout-main-header, .sui-layout-main-header__inner {
    display:block !important;
}

.sui-layout .sui-facet+.sui-facet,
.sui-layout .sui-sorting+.sui-facet {
    margin-top: 16px;
}

.sui-layout .sui-facet-search {
    padding-top: 4px;
}

.sui-layout-sidebar .sui-multi-checkbox-facet__option-input-wrapper {
    display: flex;
}

.sui-layout-sidebar .sui-multi-checkbox-facet__checkbox {
    height: 21px;
}

.sui-layout-body:after {
    background: none;
}

.sui-layout-header {
    padding: 0px !important;
    border-bottom: none !important;
}

.sui-layout-body {
    background: transparent !important;
}

.sui-search-box__text-input.header-s-input {
    border-radius: 8px !important;
    border: 1px solid #BAACC2 !important;
}

  .sui-search-box__text-input:focus {
    border-top: 2px solid #5bcaf4 !important;
    border-left: 2px solid #5bcaf4 !important;
    border-right: 2px solid #5bcaf4 !important;
    border-bottom: 2px solid #5bcaf4 !important;
  }

  .sui-search-box__text-input.common-s-input {
      border-radius: 30px;
      padding: 16px 16px 16px 46px;
      font-size: 18px;
      height: 60px;
  }
  
  .sui-layout-body__inner {
      max-width: 100% !important;
      padding: 0 !important;
  }
  
  .sui-layout-body:after {
      height:auto !important;
  }
  
  .common_search-quick {
      position: relative;
      width: 100%;
  }
  
  .common_search-quick .common-s-btn {
      position: absolute;
      right: 0;
      border-radius: 30px;
      background: #5ccbf5 !important;
      padding: 14px 36px;
      font-size: 18px;
      height: 60px;
      border: 0px !important;
  }
  
  .common_search-quick .common-s-btn:hover,
  .common_search-quick .common-s-btn:focus {
      border: 0px !important;
      box-shadow: none;
  }
  
  .common_icn_search {
      position: absolute;
      left: 18px;
      top: 20px;
  }
  
  .btn-soft-blue {
      background-color: #EBFAFF;
      color: #09769F;
      font-weight: 600;
  }
  
  .btn-soft-blue:hover,
  .btn-soft-blue:focus {
      background-color: #09769F;
      color: #FFF;
      font-weight: 600;
  }

.canvas-dropdown {
    top: 100% !important;
    max-width: 100% !important;
    width: 100%;
    height: 350px !important;
    max-height: 500px !important;
    margin-top: 10px;
    border-radius: 6px 
}

.create_modal {
    position: absolute;
    right: 20px;
    top: 78px;
    z-index: 99;
    width: 500px;
    max-width: 100%;
}
.create_modal_arrow {
    width: 100%;
    height: 20px;
    z-index: 9;
    position: relative;
    margin-top: -20px;
}
.create_modal_arrow:after,
.create_modal_arrow:before {
    content: '';
    display: block;
    position: absolute;
    top: 0px;
    width: 0;
    height: 0;
    border-style: solid;
}


/* this border color controlls the color of the triangle (what looks like the fill of the triangle) */
.create_modal_arrow:after {
    right:30px;
    border-color: transparent transparent #dee2e9 transparent;
    border-width: 10px;
    
}

/* this border color controlls the outside, thin border */
.create_modal_arrow:before {
    right: 29px;
    border-color:transparent transparent #fff transparent ;
    border-width: 11px;
    z-index: 9;
}

.create_modal li {
    position: relative;
    padding-left: 26px;
}

.create_modal li img {
    position: absolute;
    left: 0;
    top: 2px;
    opacity: 0.5;
}
.create_modal .btn-close {
    position: absolute;
    right: 10px;
    font-size: 10px;
    top: 10px;
}

.dt-upto-upgrade {
    max-width: 360px;
    margin-left: auto;
}

.access-data-btn {
    position: absolute;
    left: calc(50% - 100px);
    width: 200px;
    background: #340f49;
    top: calc(50% - 25px);
    padding: 15px 20px;
    text-align: center;
    z-index: 9;
    box-shadow: 0px 0px 10px #aaa;
    border-radius: 6px;
    color: #fff;
    border: none !important;
}

.access-data-btn:hover {
    background: #5ccbf5;
    color: #340f49;
    border: none !important;
}

.export-button-container {
    position: absolute;
    text-align: right;
    margin-top: -30px;
    margin-right: 3rem;
    right: 50px;
}


/* /Elastic search End */




.sign-up{
    background: linear-gradient(180deg, hsla(196, 88%, 93%, 1) 0%, hsla(0, 0%, 100%, 1) 100%);
    border-bottom-left-radius: 8px;
}
.sign-up-information {
    background: linear-gradient(180deg, hsla(277, 14%, 89%, 1) 0%, hsla(0, 0%, 100%, 1) 100%);
    border-bottom-right-radius: 8px;
}
.sign-up .form-check-input{
    border-color: #999;
}
.sign-up-information ul li {
    color: #262626;
    line-height: 35px;
    font-weight: 600;
}


/* checkout start */
.checkout_new_1::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 50%;
    height: 100%;
    background: #f5faff;
    /*opacity: 0.2;*/
    z-index: -1;
}
.checkout_new_1 .bg-one{
    background: #fffbea;
    border-radius: 0 6px 6px 0px;
    border-left: 4px solid #baacc2;
}
/* checkout End */

/* brainshare start */
.brainshare-banner-bg-color{
    background-color: #e7f2f6;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
}

.brainshare-listing ul li{
    font-size: 16px;
    font-weight: 600;
}

.brainshare-card .icon{
    background: #eaeaea;
    width: fit-content;
    margin: auto;
    border: 1px solid #bcbcbc;
    border-radius: 50px;
    padding: 18px;
}

/* brainshare End */


.horizen_free_sec {
    background: #5BCAF454;
	border-top: 1px solid #f3f3f3;
}

.horizen_free_sec h4 {
    font-size: 36px;
    color: #340f49;
}

.sign-up1-sec {
    background: #EBEEF9;
}

.sign_wized_box {
    background: #F2F2F2;
}

.btn_sign_free {
    padding: 15px 30px;
    border: 2px solid #09769f;
    border-radius: 30px;
    font-size: 18px;
    font-weight: 600;
    display: inline-block;
}
.btn_sign_free:hover, .btn_sign_free:focus {
    border-color: #340f49;
    color: #340f49;
}

.custom_req_box {
    background: #a59e9e42;
    padding: 15px;
    border-radius: 6px;
    padding-bottom: 18px;
}

.custom_req_box_top  {
	display: flex;
	align-items: center;
}
.custom_req_box_right {
	margin-left: auto;
	margin-top: 10px;
}



 /*-------------dashboard start--------------*/


.homesearch {
    background: #fff;
    padding: 8px 10px;
    position: relative;
    border-radius: 50px;
    border: 1px solid #D9D9D9;
}
.homesearch input[type=text] {
    border: 0;
    padding: 7px;
    width: calc(100% - 110px);
    font-size: 16px;
}
.homesearch input[type=submit] {
    position: absolute;
    top: 0;
    right: -2px;
    bottom: 0;
    border: 0;
    font-size: 16px;
    padding: 0 30px;
    background: #340f49 !important;
    color: #fff;
    transition: 0.3s;
    /* border-radius: 0 0px 0px 0; */
    border-radius: 50px;
}


.news_sec .news_hadding_1{
    border-radius: 5px 5px 0px 0px;
    background: #A0509B;
}

.news_sec .news_hadding_2{
    border-radius: 5px 5px 0px 0px;
    background: #4F6FC0;
}

.news_sec .bg_color_1{
    background: #DCF0F3;
}



 /*-------------dashboard end--------------*/






  /*-------------companies start--------------*/



.company_filter_button {
    font-size: 16px;
    font-weight: 600;
    background: #0A0A0A;
    color: #fff;
    padding: 2px 20px;
    border-radius: 5px;
    margin-top: 27px;
}

.companies_monitors_button {
    font-size: 16px;
    font-weight: 400;
    background: #233B7D;
    color: #fff;
    padding: 2px 0px;
    border-radius: 5px;
}

.companies_report_button{
    font-size: 16px;
    font-weight: 400;
    background: #233B7D;
    color: #fff;
    padding: 3px 20px;
    border-radius: 5px;
    border: 2px solid #233B7D;
}


.companies_report_button_outline{
    font-size: 16px;
    font-weight: 500;
    background: none;
    color: #233B7D;
    padding: 2px 20px;
    border: 2px solid #233B7D;
}

.companies_sec .accordion .accordion-button:not(.collapsed) {
    background: none;
    border-bottom: 0px;
}

.companies_sec .accordion .accordion-item:last-of-type .accordion-button.collapsed {
    background: #f5fafc;
}

.companies_sec .accordion-item {
    background-color: unset;
    border: 0px;
}

.companies_sec .accordion-item .accordion-body ul{
    max-height: 150px;
    overflow-y: auto;
}

.companies_sec .accordion-item .accordion-body ul li{
    line-height: 30px;
    padding: 5px 10px;
}

.companies_sec .accordion-item .accordion-body ul li.active{
    background: #C5EAF8;
}


.companies_sec .accordion-item .accordion-body ul li:hover{
    background: #C5EAF8;
    transition: 0.5s all;
}

.companies_sec .accordion-item .accordion-body ul li a{
    color: #0A0A0A;
}

.companies_sec ul.esg_risk_rating li div{
    padding: 5px 10px;
    font-weight: 500;
}

.esg_risk_rating .bg_alart_1{
    background: #D9D9D9;
}

.esg_risk_rating .bg_alart_2{
    background: #F6E176;
}
.esg_risk_rating .bg_alart_3{
    background: #D8BE36;
}

.esg_risk_rating .bg_alart_4{
    background: #F4F1F1;
}

.esg_risk_rating .bg_alart_5{
    background: #F4F1F1;
}

.companies_sec_border_1{
    border: 1px solid #d8d8d8;
    border-radius: 5px 0px 0px 5px;
}

.companies_sec_border_2{
    border-top: 1px solid #d8d8d8;
    border-bottom: 1px solid #d8d8d8;
    border-right: 1px solid #d8d8d8;
    border-radius: 0px 5px 5px 0px;
}




.industry .style_1{
    margin-bottom: 2rem;
}


.industry .style_1 .icon{
    background: #eeee;
    padding: 12px;
    border-radius: 50px;
    width: 50px;
    height: 50px;
    margin: auto;
}

.industry .style_1 .icon svg{
    width: 25px;
    height: 25px;
    fill: #35c0ed;
}

table.list-view td {
    font-size: 14px;
    padding: 10px !important;
    vertical-align: top;
    line-height: 25px;
}

table.list-view tr th{
    font-size: 15px;
    font-weight: 600;
    vertical-align: top;
}


.banner_1{
    background: linear-gradient(45deg, #000, #35c0ed);
}



.btn_one{
    padding: 10px 28px;
    font-size: 16px;
    background: #35c0ed;
    color: #fff;
    border-radius: 5px;
}

.btn_one:hover{
    background: #35c0ed;
    color: #fff;
}

.btn_one_outline{
    padding: 10px 28px;
    font-size: 16px;
    font-weight: 500;
    border: 2px solid #35c0ed;
    color: #35c0ed;
    border-radius: 5px;
    transition: 0.6s all;
}

.btn_one_outline:hover{
    background: #35c0ed;
    color: #fff;
}

.btn_two{
    padding: 10px 28px;
    font-size: 16px;
    background: #351049;
    color: #fff;
    border-radius: 5px;
}

.btn_two:hover{
    background: #351049;
    color: #fff;
}

.btn_two_outline{
    padding: 10px 28px;
    font-size: 16px;
    font-weight: 500;
    border: 2px solid #351049;
    color: #351049;
    border-radius: 5px;
    transition: 0.6s all;
}

.btn_two_outline:hover{
    background: #351049;
    color: #fff;
}

.btn_three_outline{
    padding: 8px 20px;
    font-size: 16px;
    font-weight: 500;
    border: 1px solid #09769F;
    color: #09769F;
    border-radius: 5px;
    transition: 0.6s all;
}

.btn_three_outline:hover{
    background: #09769F;
    color: #fff;
}

.text_color_1{
    color: #351049;
}

.text_color_2{
    color: #35c0ed;
}

.text_color_3{
    color: #09769F;
}


.companies_filter_ind{
    min-width: 100px;
}

.my_portfolio .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: #fff;
    background-color: #35c0ed;
    position: relative;
}

.my_portfolio .nav-pills .nav-link.active:after {
    content: "";
    border: solid #fff;
    border-width: 0 2px 2px 0;
    padding: 4px;
    transform: rotate(-45deg);
    position: absolute;
    right: 15px;
    top: 18px;
}

.my_portfolio .nav-link {
    font-size: 14px;
    font-weight: 500;
    color: #262a2e;
    padding: 10px 15px;
    text-align: start;
    border-bottom: 1px solid #dde0e4;
    border-radius: 0px;
    display: flex;
    align-items: center;
}

.my_portfolio .nav-link.active .compeny_logo{
    width: 25px;
    height: 25px;
    background: #fff;
    color: #35c0ed;
    text-align: center;
    border-radius: 100px;
    padding: 1px;
    font-size: 14px;
    font-weight: 500;
    margin-right: 10px;
    transition: 0.6s all;
}

.my_portfolio .compeny_logo {
    width: 25px;
    height: 25px;
    background: #eed4ff;
    color: #3c3c3c;
    text-align: center;
    border-radius: 100px;
    padding: 1px;
    font-size: 14px;
    font-weight: 500;
    margin-right: 10px;
    transition: 0.6s all;
}

a.dashboard_arow_1 svg {
    width: 30px;
    height: auto;
    fill: #35c0ed;
    transition: 0.6s all;
    transform: rotate(-45deg);
}

a.dashboard_arow_1 svg:hover{
    fill: #09769F;
}

.border_bottom_custom_1{
    background: #ededed;
    height: 2px;
    width: 100%;
    text-align: center;
    margin: 8px auto;
}

.my_portfolio .style_1 p{
    font-size: 16px;
}

.my_portfolio .style_2 table tr th{
    padding-left: 18px;
    vertical-align: middle;
    font-size: 15px;
    font-weight: 700;
    color: #09769F;
}

/*.my_portfolio .style_2 table tr{
    padding-left: 18px;
    vertical-align: middle;
}*/

.my_portfolio .style_2 table td {
    padding: 8px 18px !important;
    font-size: 14px;
    font-weight: 500;
}


a.dashboard_arow_2 svg{
    width: 22px;
    height: auto;
    fill: #606060;
    transition: 0.6s all;
}

a.dashboard_arow_2 svg:hover{
    fill: #09769F;
}

.tab-content .feature_style_1_icon {
    /* border: 2px solid #e7dfed; */
    padding: 6px;
    border-radius: 5px;
}

.tab-content .feature_style_1_icon svg {
    width: 35px;
    height: auto;
    fill: #e7dfed;
}






.tag_1{
    background: #def8fa;
    color: #09769F;
    padding: 5px 20px;
    border-radius: 5px;
    font-size: 13px;
    font-weight: 600;
    transition: 0.6s all;
}

.tag_1:hover{
    background: #09769F;
    color: #ffF;
}

.search-box {
    width: 100%;
    position: relative;
    display: flex;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.search-box .search-input {
    font-size: 15px;
    font-weight: 500;
    width: 100%;
    padding: 10px 45px 10px 15px;
    background-color: #fff;
    color: #351049;
    border: 1px solid #e2e2e2;
    transition: all .4s;
    border-radius: 5px 0 0 0;
}


textarea:focus, input:focus{
    outline: none;
}
.search-btn{
    padding: 10px 15px;
    background: #e1e1e1;
    border: 0px;
}

.search-btn svg{
    width: 20px;
    height: auto;
    fill: #010101;
    border-radius: 0 5px 0 0;
}








.btn-outline-primary {
    color: #09769F;
    background-color: transparent;
    background-image: none;
    border-color: #09769F;
}

.btn-outline-primary:hover, .btn-outline-primary:focus {
    color: #fff;
    background-color: #09769F;
    border-color: #09769F;
}


.btn-soft-danger {
    background-color: #ffebeb;
    border-color: #ffebeb;
    color: red;
}

.btn-soft-danger:focus, .btn-soft-danger:hover, .btn-soft-danger:not(:disabled):not(.disabled).active, .btn-soft-danger:not(:disabled):not(.disabled):active {
    background-color: red;
    border-color: red;
    color: #fff;
}

.btn.btn-rounded{
    border-radius: 50rem!important;
}
















@media only screen and (max-width: 767px) {
    .companies_sec ul.esg_risk_rating li div {
    padding: 5px 6px;
}


}














/* font face */
/* @media (min-width:768px) {
    @font-face {
        font-family: 'ITCFranklinGothicStd-Book';
        src: url('../fonts/ITCFranklinGothicStd-Book.eot?#iefix') format('embedded-opentype'), url('../fonts/ITCFranklinGothicStd-Book.woff') format('woff'), url('../fonts/ITCFranklinGothicStd-Book.ttf') format('truetype'), url('../fonts/ITCFranklinGothicStd-Book.svg#ITCFranklinGothicStd-Book') format('svg');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'ITCFranklinGothicStd-Demi';
        src: url('../fonts/ITCFranklinGothicStd-Demi.eot?#iefix') format('embedded-opentype'), url('../fonts/ITCFranklinGothicStd-Demi.woff') format('woff'), url('../fonts/ITCFranklinGothicStd-Demi.ttf') format('truetype'), url('../fonts/ITCFranklinGothicStd-Demi.svg#ITCFranklinGothicStd-Demi') format('svg');
        font-weight: normal;
        font-style: normal;
    }
}

@media(max-width:767px) {
    body {
        font-family: arial !important;
    }

    .text_demi {
        font-family: arial !important;
        font-weight: bold;
    }
} */

/* // font face */


@media (min-width: 1400px) {
    .container {
        max-width: 1200px;
    }
}

@media (min-width: 1280px) {
    .search_header_2 {
        width: 575px;
    }
}


@media (min-width: 1200px) {
    .hk-wrapper.hz_layout_2[data-layout="vertical"][data-layout-style="collapsed"] .menu-nav-link[data-bs-toggle="collapse"]:after {
        display: none;
    }

    .hk-wrapper.hz_layout_2[data-layout="vertical"][data-layout-style="collapsed"] .hk-menu:hover .menu-nav-link[data-bs-toggle="collapse"]:after {
        display: block;
    }

    .hk-wrapper[data-layout="vertical"][data-layout-style="collapsed"] .hk-menu .menu-content-wrap {
        padding: 1.5rem 1.7rem;
    }

    .hk-wrapper[data-layout="vertical"][data-layout-style="collapsed"] .hk-menu:hover .menu-content-wrap {
        padding: 1.5rem 1.92rem;
        ;
    }

    .hk-wrapper.hz_layout_2[data-layout="vertical"][data-layout-style="collapsed"] .menu-nav-link {
        display: block !important;
        padding: 8px 5px !important;
        text-align: center;
        font-size: 13px;
    }

    .hk-wrapper.hz_layout_2[data-layout="vertical"][data-layout-style="collapsed"] .hk-menu:hover .menu-nav-link {
        display: flex !important;
        padding: .5rem 1rem !important;
        text-align: center;
        font-size: 14px;
    }

    .hk-wrapper.hz_layout_2[data-layout="vertical"][data-layout-style="collapsed"] .hk-menu:hover .nav .menu-nav-link {
        font-size: 13px;
        padding: 0.4rem 0.6rem !important;
    }

    .hk-wrapper.hz_layout_2[data-layout="vertical"][data-layout-style="collapsed"] .menu-nav-link .nav-icon-wrap {
        margin-right: 0 !important;
        margin-bottom: 0.25rem;
        display: block !important;
    }

    .hk-wrapper.hz_layout_2[data-layout="vertical"][data-layout-style="collapsed"] .hk-menu:hover .nav-icon-wrap {
        display: flex !important;
        margin-right: .875rem !important;
        margin-bottom: 0rem;
    }

    .hk-wrapper.hz_layout_2[data-layout="vertical"][data-layout-style="collapsed"] .menu-nav-link .nav-link-text {
        visibility: visible !important;
        white-space: wrap;
    }

    /* .hk-wrapper.hz_layout_2[data-layout="vertical"][data-layout-style="collapsed"] .navbar-toggle {
        visibility: hidden;
    }
    .hk-wrapper.hz_layout_2[data-layout="vertical"][data-layout-style="collapsed"]  .hk-menu:hover .navbar-toggle {
        visibility: visible;
    } */
    /* .hk-wrapper.hz_layout_2[data-layout="vertical"][data-layout-style="collapsed"] .hz_menu_2 .nav-link-text {
        font-size: 11px;
        width: 100%;
        flex-direction: column;
        text-align: center;
  }
    .hk-wrapper.hz_layout_2[data-layout="vertical"][data-layout-style="collapsed"] .hz_menu_2 .nav-link-text .info_icn {
        display: none;
    }

    .hk-wrapper.hz_layout_2[data-layout="vertical"][data-layout-style="collapsed"] .hz_menu_2 .nav-link-text .nav-icon-wrap  {
        margin-right: 0px !important;
        margin-bottom: 6px;
    }

    .hk-wrapper.hz_layout_2[data-layout="vertical"][data-layout-style="collapsed"] .hz_menu_2 .collapse .nav-link-text {
        font-size: 0px;
    }
    .hk-wrapper.hz_layout_2[data-layout="vertical"][data-layout-style="collapsed"] .hz_menu_2 .left_menu_promation_box {
        display: none;
    }
    .hk-wrapper.hz_layout_2[data-layout="vertical"][data-layout-style="collapsed"] .hz_menu_2 .nav-link-text[data-bs-toggle="collapse"]:after {
        display: none;
    }
    .hk-wrapper.hz_layout_2[data-layout="vertical"][data-layout-style="collapsed"] .recent-menu {
        display: none;
    } */

    /* .hk-wrapper.hz_layout_2[data-layout="vertical"][data-layout-style="collapsed"] .hz_menu_2:hover .nav-link-text {
        font-size: 14px;
    }

    .hk-wrapper.hz_layout_2[data-layout="vertical"][data-layout-style="collapsed"] .hz_menu_2:hover .collapse .nav-link-text {
        font-size: 13px;
    }

    

    .hk-wrapper.hz_layout_2[data-layout="vertical"][data-layout-style="collapsed"] .hz_menu_2:hover .left_menu_promation_box {
        display: block;
    }

    .hk-wrapper.hz_layout_2[data-layout="vertical"][data-layout-style="collapsed"] .hz_menu_2 .nav-link-text[data-bs-toggle="collapse"]:after {
        display: none;
    }

    .hk-wrapper.hz_layout_2[data-layout="vertical"][data-layout-style="collapsed"] .hz_menu_2:hover .nav-link-text[data-bs-toggle="collapse"]:after {
        display: block;
    } */


}

@media (min-width: 768px) {
    .btn-more-md {
        flex: 0 0 120px;
        ;
    }
}

@media (max-width:1199px) {

    .gv_header .nav>li>a,
    .gv_mega_menu li>a {
        font-size: 14px;
    }

    .gv_header_search {
        width: 185px;
    }

    .hr_report_view_col h1 {
        font-size: 24px;
    }

    .hr_report_view_col p,
    .related_card h5 {
        font-size: 16px;
    }

    .hz_comp_ceo_img>* {
        max-width: 50px;
    }

    .gv_brochure_colum_1 .nav .nav-link {
        font-size: 13px;
        padding: 8px 16px;
    }
}

@media (min-width:768px) and (max-width:991px) {
    
}

@media (max-width:991px) {
    .cor_pl_left_space ul li {
        width: 100%;
    }

    .count_services_style::after {
        display: none;
    }

    .footer-top-img {
        height: auto;
    }

    .footer_right_menu {
        text-align: left;
        margin-top: 24px;
    }

    .gv_header .dropdown-menu {
        width: 100%;
        border: 0;
        margin: 0;
    }

    .gv_header .nav {
        align-items: baseline;
        padding-top: 16px;
    }

    .gv_header .nav>li {
        display: block;
        width: 100%;
        margin-top: 5px;
    }

    .gv_header .nav>li>a {
        display: block;
        border-bottom: 1px solid #ddd;
        border-radius: 0 2px 0 0;
        padding: 10px;
    }
    .gv_header .nav li .btn-primary-horizon-outline, .gv_header .nav li .btn_login_white {
        text-align: center;
        border: 1px solid #340f49 !important;
        border-radius: 6px !important;
    }

    .gv_header .navbar {
        padding: 0;
    }

    .gv_header .navbar-toggler {
        color: #fff;
        z-index: 99;
        border-color: #ddd;
        margin: -36px 0 0 auto;
        padding: 6px 10px;
    }

    .gv_header .navbar-toggler-icon {
        width: auto;
        height: auto;
    }

    .gv_header .navbar-toggler:focus {
        box-shadow: none;
    }

    .gv_header .serach_header_list {
        display: none !important;
    }

    .gv_header li.dropdown:hover>a {
        border-bottom-color: transparent;
    }

    .gv_header.hz_header_white .navbar-toggler {
        color: #212549;
    }

    .gv_header_login_btn {
        margin-left: 0;
    }

    .gv_mega_menu {
        position: static;
        width: 100%;
        border: 0 none;
    }

    .gv_search_mobile {
        left: 35%;
        width: 44%;
        position: relative;
        display: block;
        margin: -40px 0 0 10px;
    }

    .home2_banner {
        padding: 80px 0;
    }

    .success_border {
        border: 0 none;
        padding-left: 24px;
        text-align: center;
    }

    .table_report_layout h5 {
        font-size: 14px;
        font-weight: 600;
    }

    .table_report_layout table {
        font-size: 13px;
    }
    .search_header_2 {
        width: 330px;
    }

    /*.fixed-top-small .search_header_2 {
        width: 215px;
    }*/
    .team_sec {
        margin-top: -32px;
    }
    .home2_banner h1 {
        font-size: 28PX;
    }
    .gv_brochure_colum_1  .nav-item {
        width: 100%;
    }
    .gv_brochure_colum_1  .nav-link {
        width: 100%;
        font-size: 14px;
        padding: 8px 10px;
    }
    .gv_brochure_colum_1 .nav .nav-link.active {
        border-bottom: 1px solid #5ccbf5;
        border-radius: 6px;
    }
}

@media (max-width:767px) {

    .btn-databook,
    .hz_track_list,
    .value_add_list,
    .recent_public_media {
        font-size: 16px;
    }

    .home2_banner .home-search-btn {
        font-size: 0;
        height: 40px;
        padding: 10px 20px;
    }

    .home2_banner h4,
    .services_banner h5 {
        font-size: 1rem;
        line-height: 1.5;
    }

    .home2_banner input {
        font-size: 13px;
        padding: 10px 20px 10px 14px;
    }

    .home2_banner,
    .services_banner {
        padding: 3rem 0;
    }

    .horizen_banner h1,
    .hz_indutries_heading,
    .comapnies_heading,
    .home2_banner h1,
    .services_banner h1 {
        font-size: 24px;
    }

    .horizen_banner h5,
    .hz_indutries_sub_heading,
    .comapnies_sub_heading,
    .tab_case_content,
    .value_add_tabheading {
        font-size: 16px;
        line-height: 1.5;
    }

    .hr_company_detail h5 {
        font-size: 14px;
        line-height: 1.4;
    }

    .hr_company_logo {
        width: 90px;
        padding: 5px;
    }

    .hz_track_list img {
        width: 18px;
    }

    .nav_mobile .navbar-toggler:focus {
        box-shadow: none;
    }

    .nav_mobile ul li a {
        color: #728095;
        display: block;
        margin: 2px 0;
        padding: 10px 12px;
    }

    .nav_mobile ul li a.active {
        background: #f0f0ef;
        color: #212529;
        border-radius: 2px;
        position: relative;
    }

    .nav_mobile ul li a.active:after {
        content: "";
        background: url(https://cdn.grandviewresearch.com/horizon/images/chevron-right.svg) no-repeat;
        position: absolute;
        width: 15px;
        height: 15px;
        right: 10px;
        top: 12px;
    }

    .precision_sec h4 {
        text-align: center;
        font-size: 17px;
        line-height: 1.5;
    }

    .search_bar_icn {
        right: 10px;
        left: auto;
        z-index: 9;
        top: 8px;
        width: 18px;
        pointer-events: none;
    }
    .home2_banner .search_bar_icn svg {
        fill: #fff; 
    }

    .services_banner_tag {
        display: none;
    }

    .tag_sec ul li a {
        font-size: 13px;
        font-weight: 400;
        margin: 16px 4px 0;
        padding: 5px 24px;
    }

    .use_case_tab .nav-pills,
    .use_case_tab .nav-item,
    .use_case_tab li .nav-link {
        width: 100%;
    }

    .value_add_list img {
        width: 20px;
    }
    .search_header_2 {
        display: none;
    }
    .checkout_new_1::before {
        background: #ffff;
    }
    .gv_brochure_colum_1 p {
        font-size: 14px;
    }
    .get_in_touch_bottom h5, .get_in_touch_bottom h5 a {
        font-size: 16px;
    }
    .sui-layout .sui-layout-main {
        width: 100% !important;
        padding: 0 0 1rem !important;
    }
    .srch_result_col h5 {
        font-size: 16px;
    }
    .header-search-box .page-s-btn {
        padding: 5px 24px;
    }
    .common_search-quick .sui-search-box__text-input.common-s-input {
        padding: 14px;
        font-size:14px;
        height: 44px;
    }
    .common_search-quick .common_icn_search {
        display: none;
    }
    .common_search-quick .common-s-btn {
        padding: 6px 24px;
        font-size:16px;
        height: 44px;
    }    
    .hz_latest_report_sec .recent_public_heading,
    .cor_plan_heading {
        font-size: 20px;
    }
}

@media (max-width:575px) {
    .comapnies_heading, .home2_banner h1, .horizen_banner h1, .hz_indutries_heading, .services_banner h1 {
        font-size: 20px;
    }
    .gv_brochure_use_case h5 {
        font-size: 18px !important;
        margin-top: 0.25rem;;
    }
    .success_sec h2 {
        font-size: 18px;
    }
    .home2_banner h2 {
        font-size: 18px;
    }
    .feature_sec {
        text-align: center;
    }

    .feature_sec h6::after {
        left: calc(50%-18px);
    }

    .gv_search_mobile {
        left: 39%;
    }

    .header_user_drop span {
        display: none;
    }

    .hr_top_search {
        min-width: 223px;
    }

    .nav_mobile ul li a {
        color: #728095;
        display: block;
        font-size: 14px;
        margin: 2px 0;
        padding: 10px 12px;
    }

    .nav_mobile ul li a.active {
        background: #f7f8fa;
        color: #212529;
        border-radius: 2px;
        position: relative;
    }

    .nav_mobile ul li a.active:after {
        content: "";
        background: url(https://cdn.grandviewresearch.com/horizon/images/chevron-right.svg) no-repeat;
        position: absolute;
        width: 15px;
        height: 15px;
        right: 10px;
        top: 12px;
    }

    .overview_text {
        font-size: 14px;
        line-height: 1.5;
    }

    .overview_text a {
        display: block;
    }

    .statics_report h4 {
        font-size: 16px;
    }

    .statics_report h5 {
        font-size: 14px;
    }

    .statics_report img {
        width: 60px;
    }

    .carusel_my_access a {
        font-size: 14px;
        padding: .25rem 1rem;
    }

    .carusel_my_access h5 {
        font-size: 16px;
    }

    .carusel_my_access img {
        max-width: 60px;
    }

    .my_reports_col h3 {
        font-size: 24px;
    }

    .nav_user_drop {
        width: 50px !important;
        overflow: hidden;
        font-size: 0 !important;
    }

    .nav_user_drop:first-letter {
        font-size: 16px !important;
    }

    .nav_user_drop:after {
        font-size: 16px !important;
    }
    .banner_tag li a {
        font-size: 12px;
    }
    .nav-outlook-ul a.nav-link {
        font-size: 13px;
        padding: 5px;
    }
}

@media (max-width :398px) {
    .footer_copyright {
        font-size: 13px;
    }

    .gv_search_mobile {
        left: 44%;
        width: 39%;
    }
}

.password-view {
    position: absolute;
    top: 1.2rem;
    right: 1rem;
}

.statics_height_box h6:hover {
    background: #eed4ff;
    border-radius: 4px
}
/* filter buttons hiding css starts here */
/* .filters-control-item button:not([title="Filter Records"]) {
    display: none;
 }

.filters-item button[title="Remove Filter"] {
    display: none;
 } */
/* filter buttons hiding css ends here */

.rdrDefinedRangesWrapper{
    display: none;
}

.dropdown-select {
    list-style-type: none;
    margin: 0;
    padding: 0;
    border: 1px solid #ccc;
    max-height: 200px;
    overflow-y: auto;
    font-size: 16px;
}
  
.dropdown-item {
    padding: 10px;
    border-bottom: 1px solid #ddd;
}
  
.dropdown-item:hover {
    background-color: #f0f0f0;
}

.dropdown-item:hover {
    background-color: #eed4ff; 
    color: #000; 
  }
  
  