

/*Form*/
.form-group {
    margin-bottom: 1rem;
}

.form-control,
.form-select,
.wizard>.content>.body input,
.dd-handle {
    color: #262a2e;
    border-color: #BAACC2;
    border-radius: 0.375rem;
}

.form-control.square-input,
.square-input.form-select,
.wizard>.content>.body input.square-input,
.square-input.dd-handle {
    border-radius: 0;
}

.form-control:focus,
.form-select:focus,
.wizard>.content>.body input:focus,
.dd-handle:focus {
    border-color: #09769F;
    box-shadow: 0 0 0 0.125rem rgba(0, 125, 136, 0.16);
}

.form-control:disabled,
.form-select:disabled,
.wizard>.content>.body input:disabled,
.dd-handle:disabled {
    color: #b5b5b5;
    background-color: #f7f7f7;
    cursor: not-allowed;
}

.form-control[readonly],
[readonly].form-select,
.wizard>.content>.body input[readonly],
[readonly].dd-handle {
    border-color: #c7c7c7;
    box-shadow: none;
    color: #6f6f6f;
    background-color: #f7f7f7;
}

.form-control:hover:not(:disabled):not([readonly]),
.form-select:hover:not(:disabled):not([readonly]),
.wizard>.content>.body input:hover:not(:disabled):not([readonly]),
.dd-handle:hover:not(:disabled):not([readonly]) {
    border-color: #09769F;
    z-index: 4;
}

.form-control.rounded-input,
.rounded-input.form-select,
.wizard>.content>.body input.rounded-input,
.rounded-input.dd-handle {
    border-radius: 50rem !important;
}

.form-control::placeholder,
.form-select::placeholder,
.wizard>.content>.body input::placeholder,
.dd-handle::placeholder {
    color: #9e9e9e;
}

.form-control::-webkit-input-placeholder,
.form-select::-webkit-input-placeholder,
.wizard>.content>.body input::-webkit-input-placeholder,
.dd-handle::-webkit-input-placeholder {
    color: #9e9e9e;
}

.form-control:-moz-placeholder,
.form-select:-moz-placeholder,
.wizard>.content>.body input:-moz-placeholder,
.dd-handle:-moz-placeholder {
    color: #9e9e9e;
}

.form-control:-ms-input-placeholder,
.form-select:-ms-input-placeholder,
.wizard>.content>.body input:-ms-input-placeholder,
.dd-handle:-ms-input-placeholder {
    color: #9e9e9e;
}

.form-control.form-control-line,
.form-control-line.form-select,
.wizard>.content>.body input.form-control-line,
.form-control-line.dd-handle {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-radius: 0;
    padding-left: 0;
    background: transparent !important;
}

.form-control.form-control-line:focus,
.form-control-line.form-select:focus,
.wizard>.content>.body input.form-control-line:focus,
.form-control-line.dd-handle:focus {
    box-shadow: none;
}

.form-control::file-selector-button,
.form-select::file-selector-button,
.wizard>.content>.body input::file-selector-button,
.dd-handle::file-selector-button,
.form-control::-webkit-file-upload-button,
.form-select::-webkit-file-upload-button,
.wizard>.content>.body input::-webkit-file-upload-button,
.dd-handle::-webkit-file-upload-button {
    background: #fff !important;
    color: #262a2e;
}

textarea.form-control,
textarea.form-select,
textarea.dd-handle {
    height: auto;
}

.form-select {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 20 20' fill='none' stroke='rgba(47, 52, 58, 0.3)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E") !important;
}

.form-select:hover:not(:disabled) {
    border-color: #09769F;
    z-index: 4;
}

.form-select[multiple],
.form-select[size]:not([size="1"]) {
    height: auto;
}

.input-group-lg>.form-select,
.form-select-lg {
    padding-right: 1.75rem;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 20 20' fill='none' stroke='rgba(47, 52, 58, 0.3)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E") !important;
}

.input-group-sm>.form-select,
.form-select-sm {
    padding-right: 1.75rem;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 20 20' fill='none' stroke='rgba(47, 52, 58, 0.3)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E") !important;
}

.form-control-lg,
.form-select-lg {
    font-size: 1.125rem;
}

/*Checkbox*/
.form-check-input[type=checkbox] {
    border-radius: 0.125em;
}

.form-check-input:checked {
    background-color: #09769F;
    border-color: #09769F;
}

.form-check-input:focus {
    box-shadow: 0 0 0 0.125rem rgba(0, 125, 136, 0.16);
}

.form-check-input:disabled~.form-check-label {
    color: #5e7d8a;
}

.form-switch .form-check-input[type=checkbox] {
    border-radius: 2em;
}

.form-check-lg {
    min-height: 2rem;
    padding-left: 2em;
}

.form-check-lg .form-check-input {
    height: 1.25rem;
    width: 1.25rem;
    margin-left: -2em;
    margin-top: 0.3rem;
}

.form-check-lg .form-check-label {
    font-size: 1.025rem;
    margin-top: .2rem;
}

.form-check-sm .form-check-input {
    height: 0.875rem;
    width: 0.875rem;
    margin-top: 0.275em;
}

.form-check-sm .form-check-label {
    font-size: .875rem;
}

.checkbox-circle.form-check-input {
    border-radius: 50%;
}

/*Form Switch*/
.form-switch.form-switch-lg {
    padding-left: 3.5rem;
}

.form-switch.form-switch-lg .form-check-input {
    height: 1.3em;
    width: 2.8em;
    margin-left: -3.5em;
}

.form-switch.form-switch-lg .form-check-label {
    font-size: 1.025rem;
    margin-top: 0.1rem;
}

.form-switch.form-switch-xl {
    padding-left: 4.5rem;
}

.form-switch.form-switch-xl .form-check-input {
    height: 1.8em;
    width: 3.5em;
    margin-left: -4.5em;
}

.form-switch.form-switch-xl .form-check-label {
    font-size: 1.2rem;
    margin-top: 0.2rem;
}

/*Form Range*/
.form-range:focus::-webkit-slider-thumb {
    box-shadow: 0 0 0 0.125rem rgba(0, 125, 136, 0.25);
}

.form-range:focus::-moz-range-thumb {
    box-shadow: 0 0 0 0.125rem rgba(0, 125, 136, 0.25);
}

.form-range::-webkit-slider-thumb {
    background-color: #09769F;
}

.form-range::-webkit-slider-thumb:active {
    background-color: #99cbcf;
}

/*Form validation*/
.form-control.is-valid,
.is-valid.form-select,
.wizard>.content>.body input.is-valid,
.is-valid.dd-handle {
    border-color: #00D67F;
    background-image: none;
    padding-right: inherit;
    z-index: 4;
}

.form-control.is-valid:hover,
.is-valid.form-select:hover,
.wizard>.content>.body input.is-valid:hover,
.is-valid.dd-handle:hover {
    border-color: #00D67F !important;
}

.form-control.is-valid:focus,
.is-valid.form-select:focus,
.wizard>.content>.body input.is-valid:focus,
.is-valid.dd-handle:focus {
    border-color: #00D67F;
    box-shadow: 0 0 0 0.125rem rgba(0, 214, 127, 0.25);
}

.form-control.is-invalid,
.is-invalid.form-select,
.wizard>.content>.body input.is-invalid,
.is-invalid.dd-handle {
    border-color: #FF0000 !important;
    background-image: none;
    padding-right: inherit;
    z-index: 4;
}

.form-control.is-invalid:focus,
.is-invalid.form-select:focus,
.wizard>.content>.body input.is-invalid:focus,
.is-invalid.dd-handle:focus {
    border-color: #FF0000;
    box-shadow: 0 0 0 0.125rem rgba(255, 0, 0, 0.25);
}

.form-select.is-valid {
    border-color: #00D67F;
    background-image: none;
    padding-right: inherit;
    z-index: 4;
}

.form-select.is-valid:hover {
    border-color: #00D67F !important;
}

.form-select.is-valid:focus {
    border-color: #00D67F;
    box-shadow: 0 0 0 0.125rem rgba(0, 214, 127, 0.25);
}

.form-select.is-invalid {
    border-color: #FF0000;
    background-image: none;
    padding-right: inherit;
    z-index: 4;
}

.form-select.is-invalid:hover {
    border-color: #FF0000 !important;
}

.form-select.is-invalid:focus {
    border-color: #FF0000;
    box-shadow: 0 0 0 0.125rem rgba(255, 0, 0, 0.25);
}

.form-check-input.is-valid {
    border-color: #00D67F;
}

.form-check-input.is-valid:checked {
    background-color: #00D67F;
}

.form-check-input.is-valid:focus {
    box-shadow: 0 0 0 0.125rem rgba(0, 214, 127, 0.25);
}

.form-check-input.is-valid~.form-check-label {
    color: #00D67F;
}

.form-check-input.is-invalid {
    border-color: #FF0000;
}

.form-check-input.is-invalid:checked {
    background-color: #FF0000;
}

.form-check-input.is-invalid:focus {
    box-shadow: 0 0 0 0.125rem rgba(255, 0, 0, 0.25);
}

.form-check-input.is-invalid~.form-check-label {
    color: #FF0000;
}

.was-validated .form-select:valid {
    border-color: #00D67F;
    background-image: none;
    padding-right: inherit;
    z-index: 4;
}

.was-validated .form-select:valid:hover {
    border-color: #00D67F !important;
}

.was-validated .form-select:valid:focus {
    border-color: #00D67F;
    box-shadow: 0 0 0 0.125rem rgba(0, 214, 127, 0.25);
}

.was-validated .form-select:invalid {
    border-color: #FF0000;
    background-image: none;
    padding-right: inherit;
    z-index: 4;
}

.was-validated .form-select:invalid:hover {
    border-color: #FF0000 !important;
}

.was-validated .form-select:invalid:focus {
    border-color: #FF0000;
    box-shadow: 0 0 0 0.125rem rgba(255, 0, 0, 0.25);
}

.was-validated .form-control:valid,
.was-validated .form-select:valid,
.was-validated .wizard>.content>.body input:valid,
.wizard>.content>.body .was-validated input:valid,
.was-validated .dd-handle:valid {
    border-color: #00D67F;
    background-image: none;
    padding-right: inherit;
    z-index: 4;
}

.was-validated .form-control:valid:hover,
.was-validated .form-select:valid:hover,
.was-validated .wizard>.content>.body input:valid:hover,
.wizard>.content>.body .was-validated input:valid:hover,
.was-validated .dd-handle:valid:hover {
    border-color: #00D67F !important;
}

.was-validated .form-control:valid:focus,
.was-validated .form-select:valid:focus,
.was-validated .wizard>.content>.body input:valid:focus,
.wizard>.content>.body .was-validated input:valid:focus,
.was-validated .dd-handle:valid:focus {
    border-color: #00D67F;
    box-shadow: 0 0 0 0.125rem rgba(0, 214, 127, 0.25);
}

.was-validated .form-control:invalid,
.was-validated .form-select:invalid,
.was-validated .wizard>.content>.body input:invalid,
.wizard>.content>.body .was-validated input:invalid,
.was-validated .dd-handle:invalid {
    border-color: #FF0000;
    background-image: none;
    padding-right: inherit;
    z-index: 4;
}

.was-validated .form-control:invalid:hover,
.was-validated .form-select:invalid:hover,
.was-validated .wizard>.content>.body input:invalid:hover,
.wizard>.content>.body .was-validated input:invalid:hover,
.was-validated .dd-handle:invalid:hover {
    border-color: #FF0000 !important;
}

.was-validated .form-control:invalid:focus,
.was-validated .form-select:invalid:focus,
.was-validated .wizard>.content>.body input:invalid:focus,
.wizard>.content>.body .was-validated input:invalid:focus,
.was-validated .dd-handle:invalid:focus {
    border-color: #FF0000;
    box-shadow: 0 0 0 0.125rem rgba(255, 0, 0, 0.25);
}

.was-validated .form-check-input:valid {
    border-color: #00D67F;
}

.was-validated .form-check-input:valid:checked {
    background-color: #00D67F;
}

.was-validated .form-check-input:valid:focus {
    box-shadow: 0 0 0 0.125rem rgba(0, 214, 127, 0.25);
}

.was-validated .form-check-input:valid~.form-check-label {
    color: #00D67F;
}

.was-validated .form-check-input:invalid {
    border-color: #FF0000;
}

.was-validated .form-check-input:invalid:checked {
    background-color: #FF0000;
}

.was-validated .form-check-input:invalid:focus {
    box-shadow: 0 0 0 0.125rem rgba(255, 0, 0, 0.25);
}

.was-validated .form-check-input:invalid~.form-check-label {
    color: #FF0000;
}

.valid-feedback {
    color: #00D67F;
}

.invalid-feedback {
    color: #FF0000;
}

.valid-tooltip {
    background: #00D67F;
}

.invalid-tooltip {
    background: #FF0000;
}

/*Input group*/
.input-affix-wrapper {
    display: flex;
    position: relative;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.input-affix-wrapper .input-prefix,
.input-affix-wrapper .input-suffix {
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    position: absolute;
    z-index: 5;
    padding: 0.375rem 0.75rem;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    min-width: 1.8rem;
}

.input-affix-wrapper .input-prefix {
    left: 0;
}

.input-affix-wrapper .input-suffix {
    right: 0;
}

.input-affix-wrapper .form-control:not(:first-child),
.input-affix-wrapper .form-select:not(:first-child),
.input-affix-wrapper .wizard>.content>.body input:not(:first-child),
.wizard>.content>.body .input-affix-wrapper input:not(:first-child),
.input-affix-wrapper .dd-handle:not(:first-child),
.input-affix-wrapper .form-select:not(:first-child) {
    padding-left: 2.5rem;
}

.input-affix-wrapper .form-control:not(:last-child),
.input-affix-wrapper .form-select:not(:last-child),
.input-affix-wrapper .wizard>.content>.body input:not(:last-child),
.wizard>.content>.body .input-affix-wrapper input:not(:last-child),
.input-affix-wrapper .dd-handle:not(:last-child),
.input-affix-wrapper .form-select:not(:last-child) {
    padding-right: 2.5rem;
}

.input-affix-wrapper.input-affix-wrapper-sm .input-prefix,
.input-affix-wrapper.input-affix-wrapper-sm .input-suffix {
    font-size: 0.875rem;
    padding: 0.25rem 0.5rem;
    min-width: 1.5rem;
}

.input-affix-wrapper.input-affix-wrapper-sm .form-control,
.input-affix-wrapper.input-affix-wrapper-sm .form-select,
.input-affix-wrapper.input-affix-wrapper-sm .wizard>.content>.body input,
.wizard>.content>.body .input-affix-wrapper.input-affix-wrapper-sm input,
.input-affix-wrapper.input-affix-wrapper-sm .dd-handle,
.input-affix-wrapper.input-affix-wrapper-sm .form-select {
    padding: .25rem .5rem;
    font-size: .875rem;
}

.input-affix-wrapper.input-affix-wrapper-sm .form-control:not(:first-child),
.input-affix-wrapper.input-affix-wrapper-sm .form-select:not(:first-child),
.input-affix-wrapper.input-affix-wrapper-sm .wizard>.content>.body input:not(:first-child),
.wizard>.content>.body .input-affix-wrapper.input-affix-wrapper-sm input:not(:first-child),
.input-affix-wrapper.input-affix-wrapper-sm .dd-handle:not(:first-child),
.input-affix-wrapper.input-affix-wrapper-sm .form-select:not(:first-child) {
    padding-left: 2rem;
}

.input-affix-wrapper.input-affix-wrapper-sm .form-control:not(:last-child),
.input-affix-wrapper.input-affix-wrapper-sm .form-select:not(:last-child),
.input-affix-wrapper.input-affix-wrapper-sm .wizard>.content>.body input:not(:last-child),
.wizard>.content>.body .input-affix-wrapper.input-affix-wrapper-sm input:not(:last-child),
.input-affix-wrapper.input-affix-wrapper-sm .dd-handle:not(:last-child),
.input-affix-wrapper.input-affix-wrapper-sm .form-select:not(:last-child) {
    padding-right: 2rem;
}

.input-affix-wrapper.input-affix-wrapper-lg .input-prefix,
.input-affix-wrapper.input-affix-wrapper-lg .input-suffix {
    font-size: 1.25rem;
    padding: 0.5rem 1rem;
    min-width: 2rem;
}

.input-affix-wrapper.input-affix-wrapper-lg .form-control,
.input-affix-wrapper.input-affix-wrapper-lg .form-select,
.input-affix-wrapper.input-affix-wrapper-lg .wizard>.content>.body input,
.wizard>.content>.body .input-affix-wrapper.input-affix-wrapper-lg input,
.input-affix-wrapper.input-affix-wrapper-lg .dd-handle,
.input-affix-wrapper.input-affix-wrapper-lg .form-select {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
}

.input-affix-wrapper.input-affix-wrapper-lg .form-control:not(:first-child),
.input-affix-wrapper.input-affix-wrapper-lg .form-select:not(:first-child),
.input-affix-wrapper.input-affix-wrapper-lg .wizard>.content>.body input:not(:first-child),
.wizard>.content>.body .input-affix-wrapper.input-affix-wrapper-lg input:not(:first-child),
.input-affix-wrapper.input-affix-wrapper-lg .dd-handle:not(:first-child),
.input-affix-wrapper.input-affix-wrapper-lg .form-select:not(:first-child) {
    padding-left: 3rem;
}

.input-affix-wrapper.input-affix-wrapper-lg .form-control:not(:last-child),
.input-affix-wrapper.input-affix-wrapper-lg .form-select:not(:last-child),
.input-affix-wrapper.input-affix-wrapper-lg .wizard>.content>.body input:not(:last-child),
.wizard>.content>.body .input-affix-wrapper.input-affix-wrapper-lg input:not(:last-child),
.input-affix-wrapper.input-affix-wrapper-lg .dd-handle:not(:last-child),
.input-affix-wrapper.input-affix-wrapper-lg .form-select:not(:last-child) {
    padding-right: 3rem;
}

.input-affix-wrapper.affix-border .input-prefix,
.input-affix-wrapper.affix-border .input-suffix {
    border: 1px solid #d8d8d8;
    border-radius: 0.375rem;
    padding: 0;
}

.input-affix-wrapper.affix-border .input-suffix {
    margin-right: 0.5rem;
}

.input-affix-wrapper.affix-border .input-prefix {
    margin-left: 0.5rem;
}

.input-affix-wrapper.affix-wth-text .form-control:not(:first-child),
.input-affix-wrapper.affix-wth-text .form-select:not(:first-child),
.input-affix-wrapper.affix-wth-text .wizard>.content>.body input:not(:first-child),
.wizard>.content>.body .input-affix-wrapper.affix-wth-text input:not(:first-child),
.input-affix-wrapper.affix-wth-text .dd-handle:not(:first-child),
.input-affix-wrapper.affix-wth-text .form-select:not(:first-child) {
    padding-left: 3.5rem;
}

.input-affix-wrapper.affix-wth-text .form-control:not(:last-child),
.input-affix-wrapper.affix-wth-text .form-select:not(:last-child),
.input-affix-wrapper.affix-wth-text .wizard>.content>.body input:not(:last-child),
.wizard>.content>.body .input-affix-wrapper.affix-wth-text input:not(:last-child),
.input-affix-wrapper.affix-wth-text .dd-handle:not(:last-child),
.input-affix-wrapper.affix-wth-text .form-select:not(:last-child) {
    padding-right: 3.5rem;
}

.input-affix-wrapper.affix-wth-text.input-affix-wrapper-sm .form-control:not(:first-child),
.input-affix-wrapper.affix-wth-text.input-affix-wrapper-sm .form-select:not(:first-child),
.input-affix-wrapper.affix-wth-text.input-affix-wrapper-sm .wizard>.content>.body input:not(:first-child),
.wizard>.content>.body .input-affix-wrapper.affix-wth-text.input-affix-wrapper-sm input:not(:first-child),
.input-affix-wrapper.affix-wth-text.input-affix-wrapper-sm .dd-handle:not(:first-child),
.input-affix-wrapper.affix-wth-text.input-affix-wrapper-sm .form-select:not(:first-child) {
    padding-left: 3rem;
}

.input-affix-wrapper.affix-wth-text.input-affix-wrapper-sm .form-control:not(:last-child),
.input-affix-wrapper.affix-wth-text.input-affix-wrapper-sm .form-select:not(:last-child),
.input-affix-wrapper.affix-wth-text.input-affix-wrapper-sm .wizard>.content>.body input:not(:last-child),
.wizard>.content>.body .input-affix-wrapper.affix-wth-text.input-affix-wrapper-sm input:not(:last-child),
.input-affix-wrapper.affix-wth-text.input-affix-wrapper-sm .dd-handle:not(:last-child),
.input-affix-wrapper.affix-wth-text.input-affix-wrapper-sm .form-select:not(:last-child) {
    padding-right: 3rem;
}

.input-affix-wrapper.affix-wth-text.input-affix-wrapper-lg .form-control:not(:first-child),
.input-affix-wrapper.affix-wth-text.input-affix-wrapper-lg .form-select:not(:first-child),
.input-affix-wrapper.affix-wth-text.input-affix-wrapper-lg .wizard>.content>.body input:not(:first-child),
.wizard>.content>.body .input-affix-wrapper.affix-wth-text.input-affix-wrapper-lg input:not(:first-child),
.input-affix-wrapper.affix-wth-text.input-affix-wrapper-lg .dd-handle:not(:first-child),
.input-affix-wrapper.affix-wth-text.input-affix-wrapper-lg .form-select:not(:first-child) {
    padding-left: 4.5rem;
}

.input-affix-wrapper.affix-wth-text.input-affix-wrapper-lg .form-control:not(:last-child),
.input-affix-wrapper.affix-wth-text.input-affix-wrapper-lg .form-select:not(:last-child),
.input-affix-wrapper.affix-wth-text.input-affix-wrapper-lg .wizard>.content>.body input:not(:last-child),
.wizard>.content>.body .input-affix-wrapper.affix-wth-text.input-affix-wrapper-lg input:not(:last-child),
.input-affix-wrapper.affix-wth-text.input-affix-wrapper-lg .dd-handle:not(:last-child),
.input-affix-wrapper.affix-wth-text.input-affix-wrapper-lg .form-select:not(:last-child) {
    padding-right: 4.5rem;
}

.input-group-lg>.form-select,
.input-group-lg>.form-control,
.input-group-lg>.form-select,
.wizard>.content>.body .input-group-lg>input,
.input-group-lg>.dd-handle,
.input-group-sm>.form-select,
.input-group-sm>.form-control,
.input-group-sm>.form-select,
.wizard>.content>.body .input-group-sm>input,
.input-group-sm>.dd-handle {
    border-radius: 0.375rem;
}

.input-group>.btn,
div.hopscotch-bubble .hopscotch-bubble-container .hopscotch-actions .input-group>.hopscotch-nav-button,
.input-group>.form-control,
.input-group>.form-select,
.wizard>.content>.body .input-group>input,
.input-group>.dd-handle,
.input-group>.form-select,
.input-group>.input-group-text,
.input-group-sm>.btn,
div.hopscotch-bubble .hopscotch-bubble-container .hopscotch-actions .input-group-sm>.hopscotch-nav-button,
.input-group-sm>.form-control,
.input-group-sm>.form-select,
.wizard>.content>.body .input-group-sm>input,
.input-group-sm>.dd-handle,
.input-group-sm>.form-select,
.input-group-sm>.input-group-text,
.input-group-lg>.btn,
div.hopscotch-bubble .hopscotch-bubble-container .hopscotch-actions .input-group-lg>.hopscotch-nav-button,
.input-group-lg>.form-control,
.input-group-lg>.form-select,
.wizard>.content>.body .input-group-lg>input,
.input-group-lg>.dd-handle,
.input-group-lg>.form-select,
.input-group-lg>.input-group-text {
    border-radius: 0.375rem;
}

.input-group-text {
    border-color: #d8d8d8;
    background-color: #fff;
    border-width: 1px;
    border-radius: 0.375rem;
    color: #6f6f6f;
    overflow: hidden;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    min-width: 45px;
}

.input-group-lg .input-group-text {
    min-width: 55px;
}

.input-group-sm .input-group-text {
    min-width: 40px;
}

.input-group.input-group-wth-icon {
    position: relative;
    align-items: center;
}

.input-group.input-group-wth-icon .input-group-text {
    position: absolute;
    height: calc(100% - 2px);
    z-index: 5;
    border: 0;
    background: transparent;
    pointer-events: none;
}

.input-group.input-group-wth-icon .input-group-text:not(:last-child) {
    left: 0;
}

.input-group.input-group-wth-icon .input-group-text:not(:first-child) {
    right: 0;
}

.input-group.input-group-wth-icon .form-control,
.input-group.input-group-wth-icon .form-select,
.input-group.input-group-wth-icon .wizard>.content>.body input,
.wizard>.content>.body .input-group.input-group-wth-icon input,
.input-group.input-group-wth-icon .dd-handle {
    border-radius: 0.5rem !important;
}

.input-group.input-group-wth-icon .form-control:not(:first-child),
.input-group.input-group-wth-icon .form-select:not(:first-child),
.input-group.input-group-wth-icon .wizard>.content>.body input:not(:first-child),
.wizard>.content>.body .input-group.input-group-wth-icon input:not(:first-child),
.input-group.input-group-wth-icon .dd-handle:not(:first-child) {
    padding-left: 2.6rem;
}

.input-group.input-group-wth-icon .form-control:not(:last-child),
.input-group.input-group-wth-icon .form-select:not(:last-child),
.input-group.input-group-wth-icon .wizard>.content>.body input:not(:last-child),
.wizard>.content>.body .input-group.input-group-wth-icon input:not(:last-child),
.input-group.input-group-wth-icon .dd-handle:not(:last-child) {
    padding-right: 2.6rem;
}

.input-group.input-group-sm.input-group-wth-icon .form-control:not(:first-child),
.input-group.input-group-sm.input-group-wth-icon .form-select:not(:first-child),
.input-group.input-group-sm.input-group-wth-icon .wizard>.content>.body input:not(:first-child),
.wizard>.content>.body .input-group.input-group-sm.input-group-wth-icon input:not(:first-child),
.input-group.input-group-sm.input-group-wth-icon .dd-handle:not(:first-child) {
    padding-left: 2.3rem;
}

.input-group.input-group-sm.input-group-wth-icon .form-control:not(:last-child),
.input-group.input-group-sm.input-group-wth-icon .form-select:not(:last-child),
.input-group.input-group-sm.input-group-wth-icon .wizard>.content>.body input:not(:last-child),
.wizard>.content>.body .input-group.input-group-sm.input-group-wth-icon input:not(:last-child),
.input-group.input-group-sm.input-group-wth-icon .dd-handle:not(:last-child) {
    padding-right: 2.3rem;
}

.input-group.input-group-lg.input-group-wth-icon .form-control:not(:first-child),
.input-group.input-group-lg.input-group-wth-icon .form-select:not(:first-child),
.input-group.input-group-lg.input-group-wth-icon .wizard>.content>.body input:not(:first-child),
.wizard>.content>.body .input-group.input-group-lg.input-group-wth-icon input:not(:first-child),
.input-group.input-group-lg.input-group-wth-icon .dd-handle:not(:first-child) {
    padding-left: 3.1rem;
}

.input-group.input-group-lg.input-group-wth-icon .form-control:not(:last-child),
.input-group.input-group-lg.input-group-wth-icon .form-select:not(:last-child),
.input-group.input-group-lg.input-group-wth-icon .wizard>.content>.body input:not(:last-child),
.wizard>.content>.body .input-group.input-group-lg.input-group-wth-icon input:not(:last-child),
.input-group.input-group-lg.input-group-wth-icon .dd-handle:not(:last-child) {
    padding-right: 3.1rem;
}

.input-group .form-select.is-valid,
.input-group .form-select.is-invalid,
.input-group .form-control.is-valid,
.input-group .is-valid.form-select,
.input-group .wizard>.content>.body input.is-valid,
.wizard>.content>.body .input-group input.is-valid,
.input-group .is-valid.dd-handle,
.input-group .form-control.is-invalid,
.input-group .is-invalid.form-select,
.input-group .wizard>.content>.body input.is-invalid,
.wizard>.content>.body .input-group input.is-invalid,
.input-group .is-invalid.dd-handle {
    z-index: 4;
}

.input-group>.input-affix-wrapper {
    flex: 1 1 auto;
    min-width: 0;
    width: 1%;
}

.input-group>.input-affix-wrapper:not(:first-child) .form-control,
.input-group>.input-affix-wrapper:not(:first-child) .form-select,
.input-group>.input-affix-wrapper:not(:first-child) .wizard>.content>.body input,
.wizard>.content>.body .input-group>.input-affix-wrapper:not(:first-child) input,
.input-group>.input-affix-wrapper:not(:first-child) .dd-handle,
.input-group>.input-affix-wrapper:not(:first-child) .form-select {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.input-group>.input-affix-wrapper:not(:last-child) .form-control,
.input-group>.input-affix-wrapper:not(:last-child) .form-select,
.input-group>.input-affix-wrapper:not(:last-child) .wizard>.content>.body input,
.wizard>.content>.body .input-group>.input-affix-wrapper:not(:last-child) input,
.input-group>.input-affix-wrapper:not(:last-child) .dd-handle,
.input-group>.input-affix-wrapper:not(:last-child) .form-select {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

label {
    color: #262a2e;
}

.custom-file-label {
    color: #262a2e;
    border: 1px solid #d8d8d8;
    height: calc(2.25rem + 2px);
}

.custom-file-label:after {
    border-left: 0;
    background: #f7f7f7;
}

.form-label-group {
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
    margin-bottom: .5rem;
}

.form-label-group label {
    margin-bottom: 0;
}

/*Input Search*/
.input-search .btn-input-clear {
    cursor: pointer;
    line-height: 0;
}

.input-search .btn-input-clear,
.input-search .input-loader {
    display: none;
}

.input-search.loading .btn-input-clear {
    display: none !important;
}

.input-search.loading .input-loader {
    display: block;
}

/*Spinner*/
.spinner-border {
    border-width: 0.125em;
}

.spinner-border-sm {
    border-width: 0.1em;
}

/*Input Spinner*/
.input-spinner-wrap .btn,
.input-spinner-wrap div.hopscotch-bubble .hopscotch-bubble-container .hopscotch-actions .hopscotch-nav-button,
div.hopscotch-bubble .hopscotch-bubble-container .hopscotch-actions .input-spinner-wrap .hopscotch-nav-button {
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    border-color: #d8d8d8 !important;
    background: #fff !important;
    color: #9e9e9e !important;
}

.input-spinner-wrap .btn strong,
.input-spinner-wrap div.hopscotch-bubble .hopscotch-bubble-container .hopscotch-actions .hopscotch-nav-button strong,
div.hopscotch-bubble .hopscotch-bubble-container .hopscotch-actions .input-spinner-wrap .hopscotch-nav-button strong {
    display: none;
}

.input-spinner-wrap .btn:after,
.input-spinner-wrap div.hopscotch-bubble .hopscotch-bubble-container .hopscotch-actions .hopscotch-nav-button:after,
div.hopscotch-bubble .hopscotch-bubble-container .hopscotch-actions .input-spinner-wrap .hopscotch-nav-button:after {
    font: normal normal normal 20px/1 'Material-Design-Iconic-Font';
    text-rendering: auto;
}

.input-spinner-wrap .btn.btn-decrement:after,
.input-spinner-wrap div.hopscotch-bubble .hopscotch-bubble-container .hopscotch-actions .btn-decrement.hopscotch-nav-button:after,
div.hopscotch-bubble .hopscotch-bubble-container .hopscotch-actions .input-spinner-wrap .btn-decrement.hopscotch-nav-button:after {
    content: '\f273';
}

.input-spinner-wrap .btn.btn-increment:after,
.input-spinner-wrap div.hopscotch-bubble .hopscotch-bubble-container .hopscotch-actions .btn-increment.hopscotch-nav-button:after,
div.hopscotch-bubble .hopscotch-bubble-container .hopscotch-actions .input-spinner-wrap .btn-increment.hopscotch-nav-button:after {
    content: '\f278';
}

.input-spinner-wrap .input-group.input-group-sm .btn.btn-outline-light:after,
.input-spinner-wrap .input-group.input-group-sm div.hopscotch-bubble .hopscotch-bubble-container .hopscotch-actions .btn-outline-light.hopscotch-nav-button:after,
div.hopscotch-bubble .hopscotch-bubble-container .hopscotch-actions .input-spinner-wrap .input-group.input-group-sm .btn-outline-light.hopscotch-nav-button:after,
.input-spinner-wrap .input-group.input-group-sm div.hopscotch-bubble .hopscotch-bubble-container .hopscotch-actions .calendarapp-wrap .calendarapp-content .fc .fc-toolbar .fc-toolbar-chunk:nth-child(3) .hopscotch-nav-button.btn-primary:after,
div.hopscotch-bubble .hopscotch-bubble-container .hopscotch-actions .calendarapp-wrap .calendarapp-content .fc .fc-toolbar .fc-toolbar-chunk:nth-child(3) .input-spinner-wrap .input-group.input-group-sm .hopscotch-nav-button.btn-primary:after,
.input-spinner-wrap .input-group.input-group-sm .calendarapp-wrap .calendarapp-content .fc .fc-toolbar .fc-toolbar-chunk:nth-child(3) div.hopscotch-bubble .hopscotch-bubble-container .hopscotch-actions .hopscotch-nav-button.btn-primary:after,
.calendarapp-wrap .calendarapp-content .fc .fc-toolbar .fc-toolbar-chunk:nth-child(3) div.hopscotch-bubble .hopscotch-bubble-container .hopscotch-actions .input-spinner-wrap .input-group.input-group-sm .hopscotch-nav-button.btn-primary:after,
.input-spinner-wrap .input-group.input-group-sm .calendarapp-wrap .calendarapp-content .fc .fc-toolbar .fc-toolbar-chunk:nth-child(3) div.hopscotch-bubble .hopscotch-bubble-container .hopscotch-actions .hopscotch-nav-button.next:after,
.calendarapp-wrap .calendarapp-content .fc .fc-toolbar .fc-toolbar-chunk:nth-child(3) div.hopscotch-bubble .hopscotch-bubble-container .hopscotch-actions .input-spinner-wrap .input-group.input-group-sm .hopscotch-nav-button.next:after,
.input-spinner-wrap .input-group.input-group-sm div.hopscotch-bubble .hopscotch-bubble-container .hopscotch-actions .calendarapp-wrap .calendarapp-content .fc .fc-toolbar .fc-toolbar-chunk:nth-child(3) .hopscotch-nav-button.next:after,
div.hopscotch-bubble .hopscotch-bubble-container .hopscotch-actions .calendarapp-wrap .calendarapp-content .fc .fc-toolbar .fc-toolbar-chunk:nth-child(3) .input-spinner-wrap .input-group.input-group-sm .hopscotch-nav-button.next:after,
.input-spinner-wrap .input-group.input-group-sm .calendarapp-wrap .calendarapp-content .fc .fc-toolbar .fc-toolbar-chunk:nth-child(3) .btn.btn-primary:after,
.calendarapp-wrap .calendarapp-content .fc .fc-toolbar .fc-toolbar-chunk:nth-child(3) .input-spinner-wrap .input-group.input-group-sm .btn.btn-primary:after {
    font-size: 1rem;
}

.input-spinner-wrap .input-group.input-group-lg .btn.btn-outline-light:after,
.input-spinner-wrap .input-group.input-group-lg div.hopscotch-bubble .hopscotch-bubble-container .hopscotch-actions .btn-outline-light.hopscotch-nav-button:after,
div.hopscotch-bubble .hopscotch-bubble-container .hopscotch-actions .input-spinner-wrap .input-group.input-group-lg .btn-outline-light.hopscotch-nav-button:after,
.input-spinner-wrap .input-group.input-group-lg div.hopscotch-bubble .hopscotch-bubble-container .hopscotch-actions .calendarapp-wrap .calendarapp-content .fc .fc-toolbar .fc-toolbar-chunk:nth-child(3) .hopscotch-nav-button.btn-primary:after,
div.hopscotch-bubble .hopscotch-bubble-container .hopscotch-actions .calendarapp-wrap .calendarapp-content .fc .fc-toolbar .fc-toolbar-chunk:nth-child(3) .input-spinner-wrap .input-group.input-group-lg .hopscotch-nav-button.btn-primary:after,
.input-spinner-wrap .input-group.input-group-lg .calendarapp-wrap .calendarapp-content .fc .fc-toolbar .fc-toolbar-chunk:nth-child(3) div.hopscotch-bubble .hopscotch-bubble-container .hopscotch-actions .hopscotch-nav-button.btn-primary:after,
.calendarapp-wrap .calendarapp-content .fc .fc-toolbar .fc-toolbar-chunk:nth-child(3) div.hopscotch-bubble .hopscotch-bubble-container .hopscotch-actions .input-spinner-wrap .input-group.input-group-lg .hopscotch-nav-button.btn-primary:after,
.input-spinner-wrap .input-group.input-group-lg .calendarapp-wrap .calendarapp-content .fc .fc-toolbar .fc-toolbar-chunk:nth-child(3) div.hopscotch-bubble .hopscotch-bubble-container .hopscotch-actions .hopscotch-nav-button.next:after,
.calendarapp-wrap .calendarapp-content .fc .fc-toolbar .fc-toolbar-chunk:nth-child(3) div.hopscotch-bubble .hopscotch-bubble-container .hopscotch-actions .input-spinner-wrap .input-group.input-group-lg .hopscotch-nav-button.next:after,
.input-spinner-wrap .input-group.input-group-lg div.hopscotch-bubble .hopscotch-bubble-container .hopscotch-actions .calendarapp-wrap .calendarapp-content .fc .fc-toolbar .fc-toolbar-chunk:nth-child(3) .hopscotch-nav-button.next:after,
div.hopscotch-bubble .hopscotch-bubble-container .hopscotch-actions .calendarapp-wrap .calendarapp-content .fc .fc-toolbar .fc-toolbar-chunk:nth-child(3) .input-spinner-wrap .input-group.input-group-lg .hopscotch-nav-button.next:after,
.input-spinner-wrap .input-group.input-group-lg .calendarapp-wrap .calendarapp-content .fc .fc-toolbar .fc-toolbar-chunk:nth-child(3) .btn.btn-primary:after,
.calendarapp-wrap .calendarapp-content .fc .fc-toolbar .fc-toolbar-chunk:nth-child(3) .input-spinner-wrap .input-group.input-group-lg .btn.btn-primary:after {
    font-size: 1.5rem;
}

/*Select2*/
.select2-container--default.select2-container--open.select2-container--below .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.select2-dropdown {
    border-color: #d8d8d8;
}

.select2-container {
    width: 100% !important;
}

.select2-container.select2-container--default.select2-container--disabled {
    cursor: not-allowed;
}

.select2-container.select2-container--default.select2-container--disabled .select2-selection--single {
    background-color: #f7f7f7;
    pointer-events: none;
}

.select2-container--default .select2-selection--single,
.select2-container--default .select2-selection--multiple {
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: .375rem 1rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #262a2e;
    background-color: #fff;
    border: 1px solid #d8d8d8;
    border-radius: 0.5rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.select2-container--default .select2-selection--single:hover,
.select2-container--default .select2-selection--multiple:hover {
    border-color: #09769F;
}

.select2-container--default .select2-selection--single:focus,
.select2-container--default .select2-selection--multiple:focus {
    border-color: #09769F;
    box-shadow: 0 0 0 0.125rem rgba(0, 125, 136, 0.25);
}

.select2-container--default .select2-selection--single .select2-selection__arrow,
.select2-container--default .select2-selection--multiple .select2-selection__arrow {
    height: calc(2.25rem + 2px);
    top: 0;
    right: 6px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b,
.select2-container--default .select2-selection--multiple .select2-selection__arrow b {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 20 20' fill='none' stroke='rgba(47, 52, 58, 0.3)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E") !important;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 16px 12px;
    height: 100%;
    width: 20px;
    display: block;
    border: none;
    margin: auto;
    position: static;
}

.select2-container--default .select2-selection--single .select2-selection__rendered,
.select2-container--default .select2-selection--multiple .select2-selection__rendered {
    color: #262a2e;
    line-height: inherit;
    padding-left: 0;
    padding-right: 0;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
    border-color: #d8d8d8;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
    border: solid #09769F 1px;
    box-shadow: 0 0 0 0.125rem rgba(0, 125, 136, 0.25);
}

.select2-container--default.select2-container--open .select2-selection--single {
    border-color: #09769F;
}

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
    border-top-right-radius: 0.5rem;
    border-top-left-radius: 0.5rem;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: #09769F;
}

.select2-container--default .select2-results__option[aria-selected="true"] {
    background-color: #f7f7f7;
    color: #262a2e;
}

.select2-container--default .select2-selection--multiple {
    height: auto;
    min-height: calc(2.25rem + 2px);
    padding: 0;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
    background-color: #f7f7f7;
    border: none;
    font-size: 75%;
    border-radius: .25rem;
    color: #262a2e;
    margin-right: .375rem;
    margin-top: .375rem;
    padding: .25rem .5rem;
    font-weight: 500;
    text-transform: capitalize;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice .select2-selection__choice__remove {
    color: #262a2e;
    font-weight: 600;
    margin-left: .375rem;
    float: right;
    font-size: 0.875rem;
    opacity: 0.5;
    padding-top: 1px;
    height: 0;
    position: relative;
    top: -2px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice .select2-selection__choice__remove:hover {
    opacity: 1;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
    padding: 0 1rem;
}

.select2-container--default .select2-results__group,
.select2-results__option {
    padding: .375rem 1rem;
}
