/*Avatar*/
.avatar {
    position: relative;
    display: inline-block;
    width: 4rem;
    height: 4rem;
    vertical-align: middle;
    background-color: transparent;
}

.avatar .avatar-img,
.avatar .initial-wrap {
    width: 100%;
    height: 100%;
    border-radius: 0.5rem;
    -o-object-fit: cover;
    object-fit: cover;
}

.avatar>.initial-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    font-size: 1.1rem;
    font-weight: 600;
}

.avatar.avatar-rounded .avatar-img,
.avatar.avatar-rounded .initial-wrap {
    border-radius: 50%;
}

.avatar.avatar-square .avatar-img,
.avatar.avatar-square .initial-wrap {
    border-radius: 0;
}

.avatar.avatar-xxxl {
    width: 9rem;
    height: 9rem;
}

.avatar.avatar-xxxl>.initial-wrap {
    font-size: 2.25rem;
}

.avatar.avatar-xxl {
    width: 8rem;
    height: 8rem;
}

.avatar.avatar-xxl>.initial-wrap {
    font-size: 2rem;
}

.avatar.avatar-xl {
    width: 7rem;
    height: 7rem;
}

.avatar.avatar-xl>.initial-wrap {
    font-size: 1.75rem;
}

.avatar.avatar-lg {
    width: 6rem;
    height: 6rem;
}

.avatar.avatar-lg>.initial-wrap {
    font-size: 1.5rem;
}

.avatar.avatar-md {
    width: 5rem;
    height: 5rem;
}

.avatar.avatar-md>.initial-wrap {
    font-size: 1.25rem;
}

.avatar.avatar-sm {
    width: 3rem;
    height: 3rem;
}

.avatar.avatar-sm>.initial-wrap {
    font-size: 1rem;
}

.avatar.avatar-xs {
    width: 2rem;
    height: 2rem;
}

.avatar.avatar-xs>.initial-wrap {
    font-size: 0.75rem;
}

.avatar.avatar-xxs {
    width: 1.5rem;
    height: 1.5rem;
}

.avatar.avatar-xxs>.initial-wrap {
    font-size: 0.6rem;
}

.avatar.avatar-icon>.initial-wrap {
    font-size: 1.5rem;
    font-weight: inherit;
}

.avatar.avatar-icon.avatar-xxxl>.initial-wrap {
    font-size: 2.75rem;
}

.avatar.avatar-icon.avatar-xxl>.initial-wrap {
    font-size: 2.5rem;
}

.avatar.avatar-icon.avatar-xl>.initial-wrap {
    font-size: 2.25rem;
}

.avatar.avatar-icon.avatar-lg>.initial-wrap {
    font-size: 2rem;
}

.avatar.avatar-icon.avatar-md>.initial-wrap {
    font-size: 1.75rem;
}

.avatar.avatar-icon.avatar-sm>.initial-wrap {
    font-size: 1.25rem;
}

.avatar.avatar-icon.avatar-xs>.initial-wrap {
    font-size: 1.25rem;
}

.avatar.avatar-icon.avatar-xxs>.initial-wrap {
    font-size: 1rem;
}

.avatar.avatar-logo>.initial-wrap {
    background: #f7f7f7;
}

.avatar.avatar-logo>.initial-wrap>img {
    height: 50%;
}

.avatar.avatar-cyan>.initial-wrap {
    background-color: #18DDEF !important;
    color: #fff;
}

.avatar.avatar-info>.initial-wrap {
    background-color: #18DDEF !important;
    color: #fff;
}

.avatar.avatar-sky>.initial-wrap {
    background-color: #00B0FF !important;
    color: #fff;
}

.avatar.avatar-green>.initial-wrap {
    background-color: #00D67F !important;
    color: #fff;
}

.avatar.avatar-success>.initial-wrap {
    background-color: #00D67F !important;
    color: #fff;
}

.avatar.avatar-red>.initial-wrap {
    background-color: #FF0000 !important;
    color: #fff;
}

.avatar.avatar-danger>.initial-wrap {
    background-color: #FF0000 !important;
    color: #fff;
}

.avatar.avatar-yellow>.initial-wrap {
    background-color: #FFC400 !important;
    color: #fff;
}

.avatar.avatar-warning>.initial-wrap {
    background-color: #FFC400 !important;
    color: #fff;
}

.avatar.avatar-pink>.initial-wrap {
    background-color: #E92990 !important;
    color: #fff;
}

.avatar.avatar-purple>.initial-wrap {
    background-color: #C02FF3 !important;
    color: #fff;
}

.avatar.avatar-violet>.initial-wrap {
    background-color: #7429F8 !important;
    color: #fff;
}

.avatar.avatar-indigo>.initial-wrap {
    background-color: #0069F7 !important;
    color: #fff;
}

.avatar.avatar-blue>.initial-wrap {
    background-color: #298DFF !important;
    color: #fff;
}

.avatar.avatar-primary>.initial-wrap {
    background-color: #09769F !important;
    color: #fff;
}

.avatar.avatar-teal>.initial-wrap {
    background-color: #09769F !important;
    color: #fff;
}

.avatar.avatar-neon>.initial-wrap {
    background-color: #81E230 !important;
    color: #fff;
}

.avatar.avatar-lime>.initial-wrap {
    background-color: #C2E939 !important;
    color: #fff;
}

.avatar.avatar-sun>.initial-wrap {
    background-color: #FFEA00 !important;
    color: #262a2e;
}

.avatar.avatar-orange>.initial-wrap {
    background-color: #FF9100 !important;
    color: #fff;
}

.avatar.avatar-pumpkin>.initial-wrap {
    background-color: #FF5700 !important;
    color: #fff;
}

.avatar.avatar-brown>.initial-wrap {
    background-color: #954D15 !important;
    color: #fff;
}

.avatar.avatar-gold>.initial-wrap {
    background-color: #C39749 !important;
    color: #fff;
}

.avatar.avatar-dark>.initial-wrap {
    background-color: #262a2e !important;
    color: #fff;
}

.avatar.avatar-light>.initial-wrap {
    background-color: #eaeaea !important;
    color: #262a2e;
}

.avatar.avatar-grey>.initial-wrap {
    background-color: #9e9e9e !important;
    color: #fff;
}

.avatar.avatar-secondary>.initial-wrap {
    background-color: #6f6f6f !important;
    color: #fff;
}

.avatar.avatar-soft-cyan>.initial-wrap {
    background-color: #edfcfe !important;
    color: #18DDEF;
}

.avatar.avatar-soft-info>.initial-wrap {
    background-color: #edfcfe !important;
    color: #18DDEF;
}

.avatar.avatar-soft-sky>.initial-wrap {
    background-color: #ebf9ff !important;
    color: #00B0FF;
}

.avatar.avatar-soft-green>.initial-wrap {
    background-color: #ebfcf5 !important;
    color: #00D67F;
}

.avatar.avatar-soft-success>.initial-wrap {
    background-color: #ebfcf5 !important;
    color: #00D67F;
}

.avatar.avatar-soft-red>.initial-wrap {
    background-color: #ffebeb !important;
    color: #FF0000;
}

.avatar.avatar-soft-danger>.initial-wrap {
    background-color: #ffebeb !important;
    color: #FF0000;
}

.avatar.avatar-soft-yellow>.initial-wrap {
    background-color: #fffaeb !important;
    color: #FFC400;
}

.avatar.avatar-soft-warning>.initial-wrap {
    background-color: #fffaeb !important;
    color: #FFC400;
}

.avatar.avatar-soft-pink>.initial-wrap {
    background-color: #fdeef6 !important;
    color: #E92990;
}

.avatar.avatar-soft-purple>.initial-wrap {
    background-color: #faeefe !important;
    color: #C02FF3;
}

.avatar.avatar-soft-violet>.initial-wrap {
    background-color: #f4eefe !important;
    color: #7429F8;
}

.avatar.avatar-soft-indigo>.initial-wrap {
    background-color: #ebf3fe !important;
    color: #0069F7;
}

.avatar.avatar-soft-blue>.initial-wrap {
    background-color: #eef6ff !important;
    color: #298DFF;
}

.avatar.avatar-soft-primary>.initial-wrap {
    background-color: #EBFAFF !important;
    color: #09769F;
}

.avatar.avatar-soft-teal>.initial-wrap {
    background-color: #EBFAFF !important;
    color: #09769F;
}

.avatar.avatar-soft-neon>.initial-wrap {
    background-color: #f5fdee !important;
    color: #81E230;
}

.avatar.avatar-soft-lime>.initial-wrap {
    background-color: #fafdef !important;
    color: #C2E939;
}

.avatar.avatar-soft-sun>.initial-wrap {
    background-color: #fffdeb !important;
    color: #FFEA00;
}

.avatar.avatar-soft-orange>.initial-wrap {
    background-color: #fff6eb !important;
    color: #FF9100;
}

.avatar.avatar-soft-pumpkin>.initial-wrap {
    background-color: #fff2eb !important;
    color: #FF5700;
}

.avatar.avatar-soft-brown>.initial-wrap {
    background-color: #f7f1ec !important;
    color: #954D15;
}

.avatar.avatar-soft-gold>.initial-wrap {
    background-color: #faf7f0 !important;
    color: #C39749;
}

.avatar.avatar-soft-dark>.initial-wrap {
    background-color: #d8d8d8 !important;
    color: #262a2e;
}

.avatar.avatar-soft-light>.initial-wrap {
    background-color: #f7f7f7 !important;
    color: #6f6f6f;
}

.avatar.avatar-soft-grey>.initial-wrap {
    background-color: #f7f7f7 !important;
    color: #9e9e9e;
}

.avatar.avatar-soft-secondary>.initial-wrap {
    background-color: #f3f3f3 !important;
    color: #6f6f6f;
}

.avatar-group {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
}

.avatar-group .avatar {
    width: 2.625rem;
    height: 2.625rem;
}

.avatar-group .avatar .avatar-img,
.avatar-group .avatar>.initial-wrap {
    border: 2px solid #fff;
    font-size: .9rem;
}

.avatar-group.avatar-group-overlapped .avatar {
    margin-right: -.875rem;
}

.avatar-group.avatar-group-overlapped .avatar:hover {
    z-index: 1;
}

.avatar-group.avatar-group-lg .avatar {
    width: 3.25rem;
    height: 3.25rem;
}

.avatar-group.avatar-group-lg .avatar>.initial-wrap {
    font-size: .95rem;
}

.avatar-group.avatar-group-lg.avatar-group-overlapped .avatar {
    margin-right: -1rem;
}

.avatar-group.avatar-group-sm .avatar {
    width: 2rem;
    height: 2rem;
}

.avatar-group.avatar-group-sm .avatar>.initial-wrap {
    font-size: .6rem;
}

.avatar-group.avatar-group-sm.avatar-group-overlapped .avatar {
    margin-right: -0.625rem;
}